import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentLikeButton: {
    columnGap: 0,
  },
  footer: {
    marginTop: "auto",
  },
  gradient: {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
  },
  header: {
    left: 0,
    paddingHorizontal: 20,
    right: 0,
    top: 0,
  },
  image: {
    height: "100%",
  },
});
