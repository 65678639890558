import { StyleSheet } from "react-native";

import { contestListItemImageSize } from "@app/common/constants/contests.const";

export const styles = StyleSheet.create({
  listContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 1,
    paddingVertical: 8,
    rowGap: 1,
    width: "100%",
  },
  listItem: {
    paddingRight: 20,
  },
  listItemTextContainer: {
    minHeight: contestListItemImageSize,
  },
});
