import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  divider: {
    width: "100%",
  },
  productInfosRow: {
    alignItems: "flex-start",
  },
  root: {
    flex: 1,
    rowGap: 8,
  },
  variantContainer: {
    borderRadius: 8,
    padding: 8,
    width: "100%",
  },
});
