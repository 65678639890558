import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, FeaturedCollabStage } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Variables {
  stage?: FeaturedCollabStage;
}

interface Response {
  collab: Collab;
}

export const featuredCollabQuery = gql`
  query FeaturedCollab($stage: FeaturedCollabStage!) {
    collab: featuredCollab(stage: $stage) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useFeaturedCollab(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "collab"> {
  const { data, loading, error } = useQuery<Response, Variables>(featuredCollabQuery, {
    skip: !options.variables?.stage,
    ...options,
  });

  return { data: data?.collab, loading, error };
}
