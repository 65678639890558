import { FetchResult, gql, MutationFunctionOptions, useLazyQuery } from "@apollo/client";

import { AdditionalCharges, ComputeAdditionalChargesInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  computeAdditionalCharges: AdditionalCharges;
}

interface Variables {
  input: ComputeAdditionalChargesInput;
}

interface ComputeAdditionalChargesResult extends MutationResult<Response, "computeAdditionalCharges"> {
  computeAdditionalCharges: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const computeAdditionalChargesQuery = gql`
  query ComputeAdditionalCharges($input: ComputeAdditionalChargesInput!) {
    computeAdditionalCharges(input: $input) {
      salesTax {
        tax
      }
      shippingRate {
        price
        shippingRateId
        title
      }
    }
  }
`;

export function useComputeAdditionalCharges(): ComputeAdditionalChargesResult {
  const [computeAdditionalCharges, { loading, error, data }] = useLazyQuery<Response, Variables>(computeAdditionalChargesQuery);

  return { computeAdditionalCharges, loading, error, data: data?.computeAdditionalCharges };
}
