import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    rowGap: 8,
  },
  embeddedAlert: {
    width: "100%",
  },
  embeddedContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    rowGap: 24,
  },
  iconButtonsContainer: {
    columnGap: 8,
    flexDirection: "row",
    justifyContent: "center",
  },
  reversedButton: {
    flexDirection: "row-reverse",
  },
  ssoIconContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
});
