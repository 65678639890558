import React, { FC, useEffect, useMemo, useState } from "react";

import { useColorScheme } from "react-native";

import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { ColorSchemeContext } from "./color-scheme.context";
import { ColorSchemeContextInterface, ColorSchemeSetting } from "./color-scheme.types";

export const ColorSchemeContextProvider: FC<ChildrenProp> = ({ children }) => {
  const currentColorSystem = useColorScheme();

  const [systemColor, setSystemColor] = useState(currentColorSystem);
  const [colorSchemeSetting, setColorSchemeSetting] = useState<ColorSchemeSetting>("system");

  const colorScheme = colorSchemeSetting === "system" ? systemColor : colorSchemeSetting;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentColorSystem !== systemColor) {
      timeout = setTimeout(() => setSystemColor(currentColorSystem), 1000);
    }

    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [currentColorSystem, systemColor]);

  const context = useMemo<ColorSchemeContextInterface>(
    () => ({ colorSchemeSetting, setColorSchemeSetting, colorScheme }),
    [colorSchemeSetting, setColorSchemeSetting, colorScheme],
  );

  return <ColorSchemeContext.Provider value={context}>{children}</ColorSchemeContext.Provider>;
};
