import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabUpdateFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedUpdates } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  collabUpdates: PaginatedUpdates;
}

interface Variables {
  collabId: string;
  first?: number;
  offset?: number;
}

const collabsQuery = gql`
  query CollabUpdates($collabId: CollabId!, $first: Int, $offset: Int) {
    collabUpdates(collabId: $collabId, first: $first, offset: $offset) {
      nodes {
        ...CollabUpdateFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabUpdateFields}
`;

export function useCollabUpdates(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "collabUpdates"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(collabsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !options.variables?.collabId,
    ...options,
  });

  return { data: data?.collabUpdates, loading, error, networkStatus, fetchMore, refetch };
}
