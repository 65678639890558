import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationPromoteCollabToInDevelopmentArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  promoteCollabToInDevelopment: Collab;
}

interface PromoteCollabToInDevelopmentResult extends MutationResult<Response, "promoteCollabToInDevelopment"> {
  promoteCollabToInDevelopment: (options?: MutationFunctionOptions<Response, MutationPromoteCollabToInDevelopmentArgs>) => Promise<FetchResult>;
}

const promoteCollabToInDevelopmentMutation = gql`
  mutation PromoteCollabToInDevelopment($collabId: CollabId!) {
    promoteCollabToInDevelopment(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function usePromoteCollabToInDevelopment(): PromoteCollabToInDevelopmentResult {
  const [promoteCollabToInDevelopment, { loading, error, data }] = useMutation<Response, MutationPromoteCollabToInDevelopmentArgs>(
    promoteCollabToInDevelopmentMutation,
  );

  return { promoteCollabToInDevelopment, loading, error, data: data?.promoteCollabToInDevelopment };
}
