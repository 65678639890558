import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const styles = StyleSheet.create({
  gradient: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  image: {
    aspectRatio: imageRatio,
  },
  imageContentWrapper: {
    alignSelf: "flex-start",
    height: "100%",
    justifyContent: "flex-end",
    position: "absolute",
    width: "100%",
  },
  imageOverlay: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  imageWrapper: {
    overflow: "hidden",
  },
  promptText: {
    margin: 8,
  },
  root: {
    flex: 1,
  },
  selectedContainer: {
    borderRadius: 12,
    height: 24,
    left: 8,
    position: "absolute",
    top: 8,
    width: 24,
  },
  skeletonsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  videoIndicator: {
    position: "absolute",
    right: "45%",
    top: "45%",
  },
});
