import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useRequestDeleteProfile } from "@app/hooks/api/profile/use-request-delete-profile.hook";

export const DeleteAccountScreen: FC<ProfileStackScreenProps<Routes.deleteAccount>> = () => {
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { logout } = useAuthContext();

  const { requestDeleteProfile, loading } = useRequestDeleteProfile();

  const handleOnPress = (): void => {
    if (!profile) return;
    Alert.alert(t("settings.deleteAccount.areYouSure"), "", [
      { style: "cancel", text: t("cta.cancel") },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () => {
          void requestDeleteProfile({
            variables: { userId: profile.userId },
            onError: error => showErrorSnackbar({ error }),
            onCompleted: () => void logout(),
          });
        },
      },
    ]);
  };

  return (
    <ScreenWrapper>
      <Box padding={20} rowGap={16}>
        <Text variant="h7">{t("settings.deleteAccount.permanentlyDelete")}</Text>
        <Text variant="helperText">{t("settings.deleteAccount.description")}</Text>
        <Text variant="helperText" color="tertiary">
          {t("settings.deleteAccount.requestTakesTime")}
        </Text>
        <Button mode="contained" size="large" color="error" onPress={handleOnPress} loading={loading} disabled={loading}>
          {t("settings.deleteAccount.action")}
        </Button>
      </Box>
    </ScreenWrapper>
  );
};
