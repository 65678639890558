import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  ctaWrapper: {
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  header: {
    alignItems: "center",
    justifyContent: "flex-end",
    left: 0,
    padding: 8,
    position: "absolute",
    right: 0,
    top: 0,
  },
});
