import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { contestFields } from "@app/common/graphql/fragments.graphql";
import { ContestFilter, ContestSortingMethod, PaginatedContests } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  contests: PaginatedContests;
}

interface Variables {
  first?: number;
  offset?: number;
  where?: ContestFilter;
  sortBy?: ContestSortingMethod;
}

const contestsQuery = gql`
  query Contests($first: Int, $offset: Int, $sortBy: ContestSortingMethod, $where: ContestFilter) {
    contests(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...ContestFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${contestFields}
`;

export function useContests({ variables, ...options }: QueryHookOptions<Response, Variables>): QueryResult<Response, "contests"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(contestsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { sortBy: ContestSortingMethod.createdAtDesc, ...variables },
    ...options,
  });

  return { data: data?.contests, loading, error, networkStatus, fetchMore, refetch };
}
