import React, { FC, ReactNode } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { Handshake } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { darkTheme, transparent } from "@app/common/style/theme";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";

import { styles } from "./generic-onboarding-card.style";

interface Props {
  title: string;
  subtitle: string;
  icon: ReactNode;
  onClose: () => void;
}

export const GenericOnboardingCard: FC<Props> = ({ title, subtitle, icon, onClose }) => {
  const { t } = useTranslation();

  const { cardHeight } = useFeedEntityCardHeight();

  return (
    <View style={[{ backgroundColor: transparent }, styles.root]}>
      <LinearGradient locations={[0, 1]} colors={["rgba(0,0,0,0)", "rgba(0,0,0,1)"]} style={[{ height: cardHeight }, styles.gradient]} />

      <View style={styles.cardContent}>
        {icon}
        <Text variant="title" textAlign="center" color="common.white">
          {title}
        </Text>
        <Text variant="body1" textAlign="center" color="common.white">
          {subtitle}
        </Text>
      </View>

      <Button
        mode="contained"
        size="large"
        icon={iconProps => <Handshake {...iconProps} weight="fill" />}
        containerStyle={[styles.buttonContainer]}
        onPress={onClose}
        theme={darkTheme}>
        {t("cta.gotIt")}
      </Button>
    </View>
  );
};
