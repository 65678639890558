import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields, contestFields } from "@app/common/graphql/fragments.graphql";
import { CollabContestsFilteringArgs, PaginatedContestResults } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  collabs: PaginatedContestResults;
}

export interface CollabsInContestVariables {
  first?: number;
  offset?: number;
  where?: CollabContestsFilteringArgs;
}

const collabsQuery = gql`
  query CollabsInContest($first: Int, $offset: Int, $where: CollabContestsFilteringArgs!) {
    collabs: collabsInContest(first: $first, offset: $offset, where: $where) {
      nodes {
        collab {
          ...CollabFields
        }
        contest {
          ...ContestFields
        }
        winner
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
  ${contestFields}
`;

export function useCollabsInContest(options: QueryHookOptions<Response, CollabsInContestVariables>): QueryResult<Response, "collabs"> {
  const shouldSkip = !options.variables?.where?.contestId;

  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, CollabsInContestVariables>(collabsQuery, {
    skip: shouldSkip,
    fetchPolicy: shouldSkip ? "standby" : "cache-and-network",
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.collabs, loading, error, networkStatus, fetchMore, refetch };
}
