import { FetchResult, gql, MutationFunctionOptions } from "@apollo/client";

import { genericPromoFields } from "@app/common/graphql/fragments.graphql";
import { GenericPromo } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { useMutationWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { GenericPromoFromHook, mapGenericPromoToHookType, mapGenericPromoToRealType } from "@app/utils/promo.util";

interface InternalResponse {
  deleteGenericPromo: GenericPromoFromHook;
}

interface Response {
  deleteGenericPromo: GenericPromo;
}

interface Variables {
  promoId: string;
}

interface DeleteGenericPromoResult extends MutationResult<Response, "deleteGenericPromo"> {
  deleteGenericPromo: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteGenericPromoMutation = gql`
  mutation DeleteGenericPromo($promoId: PromoId!) {
    deleteGenericPromo: newDeleteGenericPromo(promoId: $promoId) {
      ...GenericPromoFields
    }
  }
  ${genericPromoFields}
`;

export function useDeleteGenericPromo(): DeleteGenericPromoResult {
  const [deleteGenericPromo, { loading, error, data }] = useMutationWithTransformation<Response, InternalResponse, Variables, "deleteGenericPromo">(
    deleteGenericPromoMutation,
    "deleteGenericPromo",
    mapGenericPromoToHookType,
    {
      update: (cache, result) => {
        cache.evict({ id: result.data?.deleteGenericPromo.promoId });
      },
    },
  );

  return { deleteGenericPromo, loading, error, data: data ? mapGenericPromoToRealType(data.deleteGenericPromo) : undefined };
}
