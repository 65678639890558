import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { Profile, SetCreatorStatusInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setCreatorStatus: Profile;
}

interface Variables {
  input: SetCreatorStatusInput;
  userId: string;
}

interface SetCreatorStatusResult extends MutationResult<Response, "setCreatorStatus"> {
  setCreatorStatus: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const setCreatorStatusMutation = gql`
  mutation SetCreatorStatus($input: SetCreatorStatusInput!, $userId: UserId!) {
    setCreatorStatus(input: $input, userId: $userId) {
      ... on Profile {
        creatorStatus
        userId
      }
    }
  }
`;

export function useSetCreatorStatus(): SetCreatorStatusResult {
  const [setCreatorStatus, { loading, error, data }] = useMutation<Response, Variables>(setCreatorStatusMutation);

  return { setCreatorStatus, loading, error, data: data?.setCreatorStatus };
}
