import { ApolloCache, ApolloClient, gql } from "@apollo/client";

import { Collab, PaginatedCollabs } from "@app/common/graphql/generated/schema.graphql";

interface Props {
  cache: ApolloCache<unknown>;
  client: ApolloClient<object>;
  collabId?: string;
  numberOfLikes?: number;
}

export function updateCacheAfterLikeCollab({ cache, client, collabId, numberOfLikes }: Props): void {
  cache.modify({
    id: collabId,
    fields: {
      liked() {
        return true;
      },
      numberOfLikes() {
        return numberOfLikes ?? null;
      },
    },
  });
  cache.modify({
    fields: {
      likes(existingLikes: PaginatedCollabs, { storeFieldName, readField, toReference }) {
        const newLike = collabId;

        const collabCached = client.readFragment({
          id: newLike,
          fragment: gql`
            # eslint-disable-next-line @graphql-eslint/no-unused-fragments
            fragment StageFieldsFromCollab on Collab {
              collabId
              progress {
                stage
              }
            }
          `,
        }) as Pick<Collab, "collabId" | "progress"> | undefined;
        if (!storeFieldName.includes(collabCached?.progress.stage ?? "")) return existingLikes;

        const alreadyExist = existingLikes.nodes?.findIndex(collab => readField("collabId", collab) === newLike) !== -1;
        if (!newLike || alreadyExist) return existingLikes;

        return {
          ...existingLikes,
          nodes: [toReference(newLike), ...(existingLikes.nodes ?? [])],
          totalCount: existingLikes.totalCount + 1,
        };
      },
    },
  });
}
