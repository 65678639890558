import { APPLE_APP_ID, ADMIN_SHOPIFY_CONSOLE, APP_ENV } from "@env";

export const links = {
  email: {
    community: "mailto:community@offscriptmtl.com",
  },
  external: {
    adminShopify: ADMIN_SHOPIFY_CONSOLE,
    appStore: `https://apps.apple.com/app/id${APPLE_APP_ID}`,
    playStore: `https://play.google.com/store/apps/details?id=${APP_ENV === "production" ? "art.letsbloom" : "art.letsbloom.dev"}`,
    appStoreReview: `https://apps.apple.com/app/apple-store/id${APPLE_APP_ID}?action=write-review`,
    playStoreReview: `https://play.google.com/store/apps/details?id=${APP_ENV === "production" ? "art.letsbloom" : "art.letsbloom.dev"}`,
    collabTransitionsDoc: "https://www.notion.so/lighthouse-world/Collab-Transitions-97be04d39faf4d649e6670381e569be9",
    creatorHandbook: "https://lighthouse-world.notion.site/c46e066b4b5b4460aaa9e6668d0464f2?v=882a8ad779664f91b685f62aba607e6a",
    googlePrivacyPolicy: "https://policies.google.com/privacy",
    googleTos: "https://policies.google.com/terms",
    privacyPolicy: "https://lighthouse-world.notion.site/Privacy-Policy-9fcf21e197fd435fba0bc5118a9a5531",
    termsOfUse: "https://lighthouse-world.notion.site/Terms-9ff9242bf594486fb076397e61e4e760",
  },
};
