import { StyleSheet } from "react-native";

const rootRowGap = 4;

export const goalsProgressHeight = 24;
export const goalsProgressInRowHeight = goalsProgressHeight * 2 + rootRowGap;

export const styles = StyleSheet.create({
  chipWithLabelContainer: {
    justifyContent: "space-between",
  },
  goalContainer: {
    flex: 1,
    height: goalsProgressHeight,
    rowGap: 2,
    width: "100%",
  },
  labelContainer: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
    paddingLeft: 4,
  },
  root: {
    columnGap: 8,
    flexDirection: "row",
    rowGap: rootRowGap,
    width: "100%",
  },
});
