import React, { FC, useEffect, useState } from "react";

import { ImageBackground } from "expo-image";
import { useTranslation } from "react-i18next";
import { Keyboard, View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";

import backgroundImages from "@app/assets/images/onboarding-bg-images.png";
import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { Text } from "@app/components/common/text/text.component";
import { OnboardingGradient } from "@app/components/initial-states/onboarding-gradient/onboarding-gradient.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useColorSchemeContext } from "@app/context/color-scheme/color-scheme.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { headerHeight } from "@app/router/router.style";

import { styles } from "./login-screen.style";

export const LoginScreen: FC<ViewProps> = ({ style, ...props }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { top } = useSafeAreaInsets();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  const { setColorSchemeSetting } = useColorSchemeContext();
  const { openLogin, closeLogin } = useLoginContext();

  const [titleVisible, setTitleVisible] = useState(true);

  const imageWidth = screenWidth;
  const middle = screenHeight / 2;
  const titleMarginBottom = imageWidth > middle ? imageWidth - middle + 30 : 0;

  useEffect(() => {
    setColorSchemeSetting("dark");
    openLogin({
      enablePanDownToClose: false,
      backdropComponent: null,
      title: t("onboarding.app.subtitle"),
      rightHeader: null,
      handleComponent: null,
      buttonMode: "outlined",
      backgroundColor: "transparent",
    });

    return () => {
      setColorSchemeSetting("system");
      closeLogin();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide title when keyboard is open
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener("keyboardWillShow", () => {
      setTitleVisible(false);
    });
    const keyboardDidHideListener = Keyboard.addListener("keyboardWillHide", () => {
      setTitleVisible(true);
    });

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return (
    <SafeAreaView edges={["right", "left"]} style={style} {...props}>
      <OnboardingGradient style={styles.gradient} />
      <View style={styles.root}>
        <View style={[{ height: headerHeight, top }, styles.header]}>
          <OffscriptLogo height={30} width={89} color={colors.common.white} />
        </View>
        <ImageBackground source={backgroundImages} style={styles.mainContainer}>
          {titleVisible && (
            <Animated.View entering={FadeIn.duration(250)} exiting={FadeOut.duration(250)}>
              <Text variant="title" textAlign="center" color="common.white" style={[styles.title, { marginBottom: titleMarginBottom }]}>
                {t("onboarding.app.title")}
              </Text>
            </Animated.View>
          )}
        </ImageBackground>
      </View>
    </SafeAreaView>
  );
};
