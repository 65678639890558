/* eslint-disable max-lines-per-function */
import React, { JSX, useImperativeHandle, type ForwardedRef, forwardRef, useRef, useMemo } from "react";

import { StyleProp, View, ViewStyle } from "react-native";
import { runOnJS, useAnimatedReaction, useDerivedValue } from "react-native-reanimated";

import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import SwiperCard from "./swipeable-card.component";
import styles from "./swiper.style";
import type { SwiperCardRefProps, SwiperProps } from "./swiper.types";
import { useSwipeControls } from "./use-swipe-controls.hook";

const SwiperInner = <T,>(
  {
    cardBackgroundColor,
    cardHeight,
    cardHorizontalMargin = 20,
    cards = [],
    cardStyle,
    cardVerticalMargin = 60,
    containerStyle,
    disableLeftSwipe,
    disableRightSwipe,
    disableTopSwipe,
    globalTranslationX,
    horizontalThreshold,
    infinite = false,
    inputOverlayLabelLeftOpacityRange,
    inputOverlayLabelRightOpacityRange,
    inputOverlayLabelTopOpacityRange,
    keyExtractor,
    marginBottom = 0,
    marginTop = 0,
    onCardIndexChange,
    onSwiped,
    onSwipedAll,
    onSwipedLeft,
    onSwipedRight,
    onSwipedTop,
    onSwipeEnd,
    onSwipeStart,
    outputOverlayLabelLeftOpacityRange = [0, 1],
    outputOverlayLabelRightOpacityRange = [0, 1],
    outputOverlayLabelTopOpacityRange = [0, 1],
    overlayBackgroundColors,
    overlayLabels,
    pointerEvents = "auto",
    renderCard,
    rotateInputRange,
    rotateOutputRange = [Math.PI / 22, 0, -Math.PI / 22],
    showSecondCard,
    stackBackgroundColor = "#4FD0E9",
    stackScale = 3,
    stackSize = 1,
    swipeAnimationDuration,
    swipeDisabled = false,
    verticalThreshold,
  }: SwiperProps<T>,
  ref: ForwardedRef<SwiperCardRefProps>,
): JSX.Element => {
  const currentCardRef = useRef<SwiperCardRefProps>(null);
  const prevCardRef = useRef<SwiperCardRefProps>(null);

  const { activeIndex, swipeRight, swipeLeft, swipeBack, swipeTop, press } = useSwipeControls(currentCardRef, prevCardRef);

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const definedHorizontalThreshold = useMemo(() => horizontalThreshold ?? windowWidth / 4, [horizontalThreshold, windowWidth]);
  const definedInputOverlayLabelLeftOpacityRange = useMemo(
    () => inputOverlayLabelLeftOpacityRange ?? [0, -(windowWidth / 3)],
    [inputOverlayLabelLeftOpacityRange, windowWidth],
  );
  const definedInputOverlayLabelRightOpacityRange = useMemo(
    () => inputOverlayLabelRightOpacityRange ?? [0, windowWidth / 3],
    [inputOverlayLabelRightOpacityRange, windowWidth],
  );
  const definedInputOverlayLabelTopOpacityRange = useMemo(
    () => inputOverlayLabelTopOpacityRange ?? [0, -(windowHeight / 3)],
    [inputOverlayLabelTopOpacityRange, windowHeight],
  );
  const definedRotateInputRange = useMemo(() => rotateInputRange ?? [-windowWidth / 3, 0, windowWidth / 3], [rotateInputRange, windowWidth]);
  const definedVerticalThreshold = useMemo(() => verticalThreshold ?? windowHeight / 5, [verticalThreshold, windowHeight]);

  const swipedAllCards = useDerivedValue(() => activeIndex.value >= cards.length, [activeIndex.value, cards.length]);

  useImperativeHandle(
    ref,
    () => {
      return {
        swipeLeft,
        swipeRight,
        swipeBack,
        swipeTop,
        press,
      };
    },
    [swipeLeft, swipeRight, swipeBack, swipeTop, press],
  );

  useAnimatedReaction(
    () => swipedAllCards.value,
    (isSwipingFinished: boolean) => {
      if (isSwipingFinished && onSwipedAll) {
        runOnJS(onSwipedAll)();
      }
    },
    [cards],
  );

  useAnimatedReaction(
    () => activeIndex.value,
    (newIndex: number) => {
      runOnJS(onCardIndexChange)(newIndex);
    },
  );

  const getCardKey = (cardContent: T, index: number): string => {
    if (keyExtractor) {
      return keyExtractor(cardContent);
    }

    return `card-${index}`;
  };

  const getDefaultCardStyle = (): StyleProp<ViewStyle> => {
    const cardWidth = windowWidth - cardHorizontalMargin * 2;

    return {
      top: cardVerticalMargin,
      left: cardHorizontalMargin,
      width: cardWidth,
      height: cardHeight,
      backgroundColor: cardBackgroundColor,
    };
  };

  const pushCardToStack = (
    renderedCards: JSX.Element[],
    index: number,
    position: number,
    key: string,
    isFirstCard: boolean,
    isPrevCard: boolean,
  ): void => {
    renderedCards.push(
      <SwiperCard
        ref={isFirstCard ? currentCardRef : isPrevCard ? prevCardRef : null}
        key={key}
        cardStyle={[getDefaultCardStyle(), cardStyle]}
        cardHeight={cardHeight}
        index={index}
        globalTranslationX={globalTranslationX}
        disableRightSwipe={disableRightSwipe}
        disableLeftSwipe={disableLeftSwipe}
        disableTopSwipe={disableTopSwipe}
        rotateOutputRange={rotateOutputRange}
        rotateInputRange={definedRotateInputRange}
        inputOverlayLabelRightOpacityRange={definedInputOverlayLabelRightOpacityRange}
        outputOverlayLabelRightOpacityRange={outputOverlayLabelRightOpacityRange}
        inputOverlayLabelLeftOpacityRange={definedInputOverlayLabelLeftOpacityRange}
        outputOverlayLabelLeftOpacityRange={outputOverlayLabelLeftOpacityRange}
        inputOverlayLabelTopOpacityRange={definedInputOverlayLabelTopOpacityRange}
        outputOverlayLabelTopOpacityRange={outputOverlayLabelTopOpacityRange}
        activeIndex={activeIndex}
        overlayBackgroundColors={overlayBackgroundColors}
        overlayLabels={overlayLabels}
        onSwipedRight={cardIndex => {
          onSwipedRight?.(cardIndex);
        }}
        onSwipedLeft={cardIndex => {
          onSwipedLeft?.(cardIndex);
        }}
        onSwipedTop={cardIndex => {
          onSwipedTop?.(cardIndex);
        }}
        onSwiped={onSwiped}
        onSwipeStart={onSwipeStart}
        onSwipeEnd={onSwipeEnd}
        position={position}
        stackScale={stackScale}
        verticalThreshold={definedVerticalThreshold}
        swipeDisabled={swipeDisabled}
        horizontalThreshold={definedHorizontalThreshold}
        swipeAnimationDuration={swipeAnimationDuration}>
        {renderCard(cards[index], index)}
      </SwiperCard>,
    );
  };

  const renderStack = (): JSX.Element[] => {
    const renderedCards: JSX.Element[] = [];

    let index = activeIndex.value;
    const prevIndex = index - 1;
    let firstCard = true;
    let cardPosition = 0;

    while (stackSize-- > 0 && (firstCard || showSecondCard) && !swipedAllCards.value) {
      const key = getCardKey(cards[index], index);
      pushCardToStack(renderedCards, index, cardPosition, key, firstCard, false);

      firstCard = false;

      if (index === cards.length - 1) {
        if (!infinite) break;
        index = 0;
      } else {
        index++;
      }
      cardPosition++;
    }

    if (prevIndex >= 0) {
      const key = getCardKey(cards[prevIndex], prevIndex);
      pushCardToStack(renderedCards, prevIndex, cardPosition, key, false, true);
      cardPosition++;
    }

    return renderedCards;
  };

  return (
    <View
      pointerEvents={pointerEvents}
      style={[
        styles.container,
        {
          backgroundColor: stackBackgroundColor,
          marginTop,
          marginBottom,
        },
        containerStyle,
      ]}>
      {renderStack()}
    </View>
  );
};

export const Swiper = forwardRef(SwiperInner);
