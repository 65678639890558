import React, { FC } from "react";

import { SafeAreaView } from "react-native";
import { useTheme } from "react-native-paper";

import { Routes, NavigationProps, Tabs } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioImageListDetails } from "@app/components/studio-mini/studio-image-list-details/studio-image-list-details.component";
import { StudioImageMainActionButtons } from "@app/components/studio-mini/studio-image-main-action-buttons/studio-image-main-action-buttons.component";
import { useStudioExploreImage } from "@app/hooks/api/studio/use-studio-explore-image.hook";

import { styles } from "./explore-details.style";

export const ExploreDetailsScreen: FC<NavigationProps<Routes.studioMiniExploreDetail>> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { imageId } = route.params;

  const { data: image, loading } = useStudioExploreImage({
    variables: { studioImageId: imageId },
    onError: () => navigation.navigate(Routes.root, { screen: Tabs.studio, params: { screen: Routes.studio, initial: false } }),
  });

  const actionButtons = <StudioImageMainActionButtons image={image} loading={loading} />;

  return (
    <>
      <ScreenWrapper withScrollView contentContainerStyle={styles.root} staticContent={actionButtons}>
        <StudioImageListDetails media={image} context="explore" />
      </ScreenWrapper>
      <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />
    </>
  );
};
