import { gql, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { genericPromoFields } from "@app/common/graphql/fragments.graphql";
import { CreatePromoInput, PaginatedAnyPromo, GenericPromo } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { useMutationWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { GenericPromoFromHook, mapGenericPromoToHookType, mapGenericPromoToRealType } from "@app/utils/promo.util";

interface InternalResponse {
  createGenericPromo: GenericPromoFromHook;
}

interface Response {
  createGenericPromo: GenericPromo;
}

interface Variables {
  input: CreatePromoInput;
}

interface CreateGenericPromoResult extends MutationResult<Response, "createGenericPromo"> {
  createGenericPromo: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createGenericPromoMutation = gql`
  mutation CreateGenericPromo($input: CreatePromoInput!) {
    createGenericPromo: newCreateGenericPromo(input: $input) {
      ...GenericPromoFields
    }
  }
  ${genericPromoFields}
`;

export function useCreateGenericPromo(): CreateGenericPromoResult {
  const [createGenericPromo, { loading, error, data }] = useMutationWithTransformation<Response, InternalResponse, Variables, "createGenericPromo">(
    createGenericPromoMutation,
    "createGenericPromo",
    mapGenericPromoToHookType,
    {
      update: (cache, result) => {
        cache.modify({
          fields: {
            newPromos: (existingPromos: PaginatedAnyPromo, { readField, toReference }) => {
              const newPromoId = result.data?.createGenericPromo.promoId;

              const alreadyExists = existingPromos.nodes?.findIndex(promo => readField("promoId", promo) === newPromoId) !== -1;
              if (!newPromoId || alreadyExists) return existingPromos;

              return {
                ...existingPromos,
                nodes: [...(existingPromos.nodes ?? []), toReference(newPromoId)],
                totalCount: existingPromos.totalCount + 1,
              };
            },
          },
        });
      },
    },
  );

  return { createGenericPromo, loading, error, data: data ? mapGenericPromoToRealType(data.createGenericPromo) : undefined };
}
