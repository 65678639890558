import React, { FC } from "react";

import { View } from "react-native";

import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";

import { styles } from "./shared-item-preview-card.style";

export interface Props {
  imageUrl?: string;
  title?: string;
  subtitle?: string;
  badgeLocation?: "title" | "subtitle";
  profileBadge?: Badge;
}

export const SharedItemPreviewCard: FC<Props> = ({ imageUrl, title, subtitle, badgeLocation, profileBadge }) => {
  const { roundness, colors } = darkTheme;

  return (
    <View style={styles.root}>
      <Text fontSize={32} style={styles.iconLeft}>
        🔥
      </Text>
      <View style={[{ borderRadius: roundness }, styles.sharedItemContainer]}>
        <Image source={imageUrl} style={[{ borderRadius: roundness }, styles.thumbnailImage]} />
        {title && (
          <CreatorName
            name={title}
            badge={badgeLocation === "title" ? profileBadge ?? Badge.none : Badge.none}
            iconSize={15}
            textProps={{ variant: "caption", fontFamily: "PPNeueMontreal_600SemiBold", style: { color: colors.common.white } }}
          />
        )}
        {subtitle && (
          <CreatorName
            name={subtitle}
            badge={badgeLocation === "subtitle" ? profileBadge ?? Badge.none : Badge.none}
            iconSize={15}
            textProps={{ variant: "caption", style: { color: colors.tertiary } }}
          />
        )}
      </View>
      <Text fontSize={32} style={styles.iconRight}>
        🔥
      </Text>
    </View>
  );
};
