import React, { FC, ReactNode } from "react";

import { IconProps, Info, WarningCircle, WarningOctagon } from "phosphor-react-native";
import { StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { AlertCta } from "@app/common/types/alert-cta.interface";
import { ColorKey, getColor } from "@app/utils/color-theme.util";

import { Button } from "../button/button.component";
import { ListItem } from "../list-item/list-item.component";

import { styles } from "./embedded-alert.style";

export enum AlertVariant {
  error = "error",
  info = "info",
  primary = "primary",
}

interface Props {
  variant: AlertVariant;
  text: string;
  icon?: (iconProps: IconProps) => ReactNode;
  cta?: AlertCta;
  style?: StyleProp<ViewStyle>;
}

export const EmbeddedAlert: FC<Props> = ({ variant, text, icon, cta, style }) => {
  const { colors, roundness } = useTheme();

  const iconMap: Record<AlertVariant, (iconProps: IconProps) => ReactNode> = {
    [AlertVariant.info]: iconProps => <Info {...iconProps} color={colors.info} />,
    [AlertVariant.primary]: iconProps => <WarningCircle {...iconProps} color={colors.onPrimary} />,
    [AlertVariant.error]: iconProps => <WarningOctagon {...iconProps} color={colors.error} />,
  };

  const textColorMap: Record<AlertVariant, ColorKey> = {
    [AlertVariant.info]: "info160p",
    [AlertVariant.primary]: "onPrimary",
    [AlertVariant.error]: "error160p",
  };

  const backgroundColorMap: Record<AlertVariant, string> = {
    [AlertVariant.info]: colors.info12p,
    [AlertVariant.primary]: colors.primary,
    [AlertVariant.error]: colors.error8p,
  };

  return (
    <ListItem
      title={text}
      titleProps={{ variant: "body2", numberOfLines: 0, color: textColorMap[variant] }}
      left={icon ? icon({ weight: "thin", size: 24 }) : iconMap[variant]({ weight: "thin", size: 24 })}
      right={
        cta ? (
          <Button textColor={getColor(colors, textColorMap[variant])} size="small" compact labelStyle={styles.button} onPress={cta.onPress}>
            {cta.label}
          </Button>
        ) : undefined
      }
      style={[styles.alert, { backgroundColor: backgroundColorMap[variant], borderRadius: roundness }, style]}
    />
  );
};
