import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreCartItemFields } from "@app/common/graphql/fragments.graphql";
import { AddToCartInput, CartItemWithIdentity } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { updateCacheAfterAddToCart } from "@app/utils/cache-updates/update-cache-after-add-to-cart.util";

export interface Response {
  addToCart: CartItemWithIdentity;
}

interface Variables {
  input: AddToCartInput;
  anonymousCartId?: string;
  userId?: string;
}

interface AddToCartResult extends MutationResult<Response, "addToCart"> {
  addToCart: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const addToCartMutation = gql`
  mutation AddToCart($anonymousCartId: AnonymousCartId, $input: AddToCartInput!, $userId: UserId) {
    addToCart(anonymousCartId: $anonymousCartId, input: $input, userId: $userId) {
      cartItem {
        ...CoreCartItemFields
      }
      identity {
        anonymousCartId
        userId
      }
    }
  }
  ${coreCartItemFields}
`;

export function useAddToCart(): AddToCartResult {
  const [addToCart, { loading, error, data, client }] = useMutation<Response, Variables>(addToCartMutation, {
    update(cache, result) {
      updateCacheAfterAddToCart({ cache, client, cartItem: result.data?.addToCart.cartItem, cartIdentity: result.data?.addToCart?.identity });
    },
  });

  return { addToCart, loading, error, data: data?.addToCart };
}
