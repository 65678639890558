import React, { FC, useState } from "react";

import { AuthError, OAuthProvider, UserCredential, browserPopupRedirectResolver, signInWithPopup } from "firebase/auth";
import { ActivityIndicator, IconButton, IconButtonProps, useTheme } from "react-native-paper";

import AppleIcon from "@app/assets/logos/apple-logo.svg";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";

interface Props extends Omit<IconButtonProps, "children" | "icon"> {
  onSignInSuccess?: (credential: UserCredential) => void;
}

export const AppleButton: FC<Props> = ({ onSignInSuccess, iconColor, ...props }) => {
  const { colors } = useTheme();

  const [loading, setLoading] = useState(false);

  const { handleAuthError } = useHandleAuthError();

  const handleOnPress = (): void => {
    setLoading(true);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    getAuthWhenReady()
      .then(auth =>
        signInWithPopup(auth, provider, browserPopupRedirectResolver)
          .then(userCredential => onSignInSuccess?.(userCredential))
          .catch((error: AuthError) => {
            handleAuthError(error, LoginAction.loginWithApple);
          }),
      )
      .catch((error: AuthError) => handleAuthError(error, LoginAction.getAuth))
      .finally(() => setLoading(false));
  };

  return (
    <IconButton
      icon={({ color, size }) =>
        loading ? <ActivityIndicator size={size} color={colors.secondary} /> : <AppleIcon width={size} height={size} color={color} />
      }
      disabled={loading}
      iconColor={iconColor ?? colors.onPrimary}
      onPress={handleOnPress}
      {...props}
    />
  );
};
