import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
  },
  loadingContainer: {
    justifyContent: "center",
  },
});
