import React, { FC, ReactNode } from "react";

import { IconProps } from "phosphor-react-native";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { headerHeight } from "@app/router/router.style";

import { Button } from "../button/button.component";
import { GenericCard } from "../generic-card/generic-card.component";

import { styles } from "./full-screen-error.style";

interface Props {
  title: string;
  image: string | ((props: IconProps) => ReactNode);
  message: string;
  ctaProps?: {
    label: string;
    onPress: () => void;
  };
}

export const FullScreenError: FC<Props> = ({ title, message, ctaProps, image }) => {
  const { colors } = useTheme();
  const { bottom } = useSafeAreaInsets();
  const { height: screenHeight } = useWindowDimensions();

  return (
    <View>
      <GenericCard
        image={image}
        title={title}
        content={message}
        cta={
          ctaProps ? (
            <Button mode="contained" size="large" onPress={ctaProps.onPress} containerStyle={styles.ctaWrapper} fullWidth>
              {ctaProps.label}
            </Button>
          ) : (
            <></>
          )
        }
        containerStyle={{ paddingBottom: bottom }}
        height={screenHeight}
      />
      <View style={[{ height: headerHeight }, styles.header]}>
        <OffscriptLogo height={40} width={120} color={colors.onBackground} />
      </View>
    </View>
  );
};
