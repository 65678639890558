import React, { JSX, FC, useCallback } from "react";

import { LinearGradient, LinearGradientProps } from "expo-linear-gradient";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import { Theme, useTheme } from "react-native-paper";

import { Button, ButtonProps } from "../button/button.component";

import { styles } from "./segmented-buttons.style";

export interface SegmentedButtonProps extends Omit<ButtonProps, "children" | "onPress"> {
  value: string;
  label: string | ((color: string) => JSX.Element);
  rightSeparator?: JSX.Element;
}

interface CommonSegmentedButtonProps {
  buttons: SegmentedButtonProps[];
  disabledFadingEdged?: boolean;
  style?: StyleProp<ViewStyle>;
  theme?: Theme;
}

interface MultiSelectSegmentedButtonsProps extends CommonSegmentedButtonProps {
  multiSelect: true;
  value: string[];
  onValueChange: (value: string[]) => void;
}

interface SingleSelectSegmentedButtonsProps extends CommonSegmentedButtonProps {
  multiSelect?: false;
  value: string;
  onValueChange: (value: string) => void;
}

export type SegmentedButtonsProps = MultiSelectSegmentedButtonsProps | SingleSelectSegmentedButtonsProps;

export const SegmentedButtons: FC<SegmentedButtonsProps> = ({ buttons, value, onValueChange, disabledFadingEdged, style, theme, multiSelect }) => {
  const { dark: currentDark, colors: currentColors } = useTheme();

  const colors = theme?.colors ?? currentColors;
  const dark = theme?.dark || currentDark;

  const leftGradient = dark ? ["rgba(0,0,0,0.9)", "rgba(0,0,0,0)"] : ["rgba(255,255,255,0.9)", "rgba(255,255,255,0)"];
  const rightGradient = dark ? ["rgba(0,0,0,0)", "rgba(0,0,0,0.9)"] : ["rgba(255,255,255,0)", "rgba(255,255,255,0.9)"];

  const commonGradientProps: Omit<LinearGradientProps, "colors"> = {
    locations: [0, 1],
    start: { x: 0, y: 0 },
    end: { x: 1, y: 0 },
    style: styles.gradient,
  };

  const renderButton = useCallback(
    ({ label, value: buttonValue, rightSeparator, ...buttonProps }: SegmentedButtonProps) => {
      const textColor = (!multiSelect && value === buttonValue) || value.includes(buttonValue) ? colors.onBackground : colors.action.active;

      return (
        <View key={`segmented-button-${buttonValue}`} style={styles.buttonWrapper}>
          <Button
            key={`segmented-button-${buttonValue}`}
            mode="text"
            textColor={textColor}
            onPress={() => onValueChange((multiSelect ? [...value, buttonValue] : buttonValue) as string[] & string)}
            theme={theme}
            {...buttonProps}>
            {typeof label === "string" ? label : label(textColor)}
          </Button>
          {rightSeparator}
        </View>
      );
    },
    [colors.action.active, colors.onBackground, multiSelect, onValueChange, theme, value],
  );

  return (
    <View style={style}>
      <ScrollView horizontal bounces={false} showsHorizontalScrollIndicator={false} contentContainerStyle={styles.contentContainer}>
        {buttons.map(button => renderButton(button))}
      </ScrollView>
      {!disabledFadingEdged && (
        <>
          <LinearGradient colors={leftGradient} {...commonGradientProps} style={[commonGradientProps.style, styles.leftGradient]} />
          <LinearGradient colors={rightGradient} {...commonGradientProps} style={[commonGradientProps.style, styles.rightGradient]} />
        </>
      )}
    </View>
  );
};
