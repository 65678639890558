import { StyleSheet } from "react-native";

const borderColor = "rgba(255,255,255,0.23)";
const shadowColor = "rgba(255,255,255,0.15)";
const backgroundColor = "rgba(0,0,0,0.10)";

export const styles = StyleSheet.create({
  iconLeft: {
    height: 48,
    top: 20,
    transform: [{ rotate: "-15deg" }],
    width: 32,
  },
  iconRight: {
    height: 48,
    top: 120,
    transform: [{ rotate: "15deg" }],
    width: 32,
  },
  root: {
    columnGap: 12,
    flexDirection: "row",
  },
  sharedItemContainer: {
    backgroundColor,
    borderColor,
    borderRadius: 16,
    borderWidth: 1,
    elevation: 10,
    padding: 8,
    rowGap: 4,
    shadowColor,
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  thumbnailImage: {
    height: 159,
    width: 159,
  },
});
