import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreStudioAnimationFields, coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioMedia, StudioMediaFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface StudioMediaResponse {
  studioMedia: PaginatedStudioMedia;
}

interface Variables {
  first?: number;
  offset?: number;
  where: StudioMediaFilteringArgs;
}

const studioMediaQuery = gql`
  query StudioMedia($first: Int, $offset: Int, $where: StudioMediaFilteringArgs!) {
    studioMedia(first: $first, offset: $offset, where: $where) {
      nodes {
        ... on StudioImage {
          ...CoreStudioImageFields
        }
        ... on StudioAnimation {
          ...CoreStudioAnimationFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
  ${coreStudioAnimationFields}
`;

export function useStudioMedia(options: QueryHookOptions<StudioMediaResponse, Variables>): QueryResult<StudioMediaResponse, "studioMedia"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<StudioMediaResponse, Variables>(studioMediaQuery, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.studioMedia, loading, error, networkStatus, fetchMore, refetch };
}
