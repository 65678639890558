import React, { FC, useState } from "react";

import { X } from "phosphor-react-native";
import { IconButton, useTheme } from "react-native-paper";
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import { ColorVariant } from "@app/common/style/theme";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { getOnColor } from "@app/utils/color-theme.util";

import { styles } from "./dismissable-embedded-banner.style";

interface Props {
  message: string;
  color: ColorVariant;
  alreadyDismissed?: boolean;
  onDismiss: () => void;
}

export const DismissableEmbeddedBanner: FC<Props> = ({ message, color, alreadyDismissed, onDismiss }) => {
  const { colors } = useTheme();

  const contentColor = getOnColor(colors, color);

  const opacity = useSharedValue(1);
  const [visible, setVisible] = useState(!alreadyDismissed);

  const handleDismiss = (): void => {
    opacity.value = withTiming(0, { duration: 250 }, finished => {
      if (finished) {
        runOnJS(setVisible)(false);
      }
    });
    onDismiss();
  };

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  if (!visible) return null;

  return (
    <Animated.View style={animatedStyle}>
      <ListItem
        title={message}
        titleProps={{ variant: "body2", numberOfLines: 0, style: { color: contentColor } }}
        right={<IconButton icon={X} iconColor={contentColor} style={styles.closeIcon} onPress={handleDismiss} />}
        textContainerStyle={styles.textContainer}
        style={[styles.contentContainer, { backgroundColor: colors[color] }]}
      />
    </Animated.View>
  );
};
