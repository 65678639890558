import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Check } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, TextInput as RNTextInput } from "react-native";
import { ActivityIndicator, IconButton, RadioButton, useTheme } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { BackButton } from "@app/components/common/navigation/back-button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { ContestPasswordValidationDialog } from "@app/components/contests/contest-password-validation-dialog/contest-password-validation-dialog.component";
import { AutogenerateDescriptionButton } from "@app/components/studio-mini/autogenerate-description-button/autogenerate-description-button.component";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { Suggestion, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { CollabFieldTypeEnum, CollabFieldValidation, useCreateCollabInputValidation } from "@app/hooks/utils/use-create-collab-input-validation.hook";
import { isWeb } from "@app/utils/device.util";
import { isContestId } from "@app/utils/id.util";

import { styles } from "./edit-studio-field.style";

const editableFields: (keyof CollabFieldValidation)[] = ["contest", "productCategory", "productDescription", "productName"];

export const EditStudioFieldScreen: FC<NavigationProps<Routes.studioMiniEditField>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const inputRef = useRef<RNTextInput>(null);

  const { state: userState, loading: loadingUserState } = useProfileContext();
  const { contestPasswords } = useLocalStorageContext();
  const fieldName = route.params?.fieldName;
  const collabFieldValidation = useCreateCollabInputValidation(fieldName);
  const collabInputValidation = collabFieldValidation?.type === CollabFieldTypeEnum.input ? collabFieldValidation : null;
  const collabSuggestionValidation = collabFieldValidation?.type === CollabFieldTypeEnum.suggestion ? collabFieldValidation : null;
  const hasAutogenerateDescriptionPopperToShowOnWeb =
    isWeb && fieldName === "productDescription" && !loadingUserState && !userState?.[UserStateEnum.autogenerateDescriptionPopperDismissed];

  const { isCurrentlyGeneratingDescription } = useStudioMiniContext();

  useEffect(() => {
    if (!fieldName || !editableFields.includes(fieldName)) {
      navigation.replace(Routes.studioMiniCreateCollab);
    }
  }, [fieldName, navigation]);

  const [enterPasswordDialogVisible, setEnterPasswordDialogVisible] = useState(false);
  const [value, setValue] = useState(collabInputValidation?.value);
  const [selectedSuggestion, setSelectedSuggestion] = useState(collabSuggestionValidation?.value);

  const handleSubmit = useCallback(
    (v?: string | Suggestion | null) => {
      if (!v) return;
      if (typeof v === "string") {
        collabInputValidation?.update(v.trim());
      } else {
        if (v.passwordRequired && !contestPasswords[v.id] && !enterPasswordDialogVisible) {
          setEnterPasswordDialogVisible(true);
          return;
        }
        collabSuggestionValidation?.update(v);
      }

      navigation.goBack();
    },
    [collabInputValidation, collabSuggestionValidation, contestPasswords, enterPasswordDialogVisible, navigation],
  );

  const submitButton = useCallback(
    () => <IconButton icon={Check} disabled={!value && !selectedSuggestion} onPress={() => handleSubmit(value ?? selectedSuggestion)} />,
    [handleSubmit, value, selectedSuggestion],
  );

  useEffect(() => {
    if (isWeb) return undefined;

    const unsubscribe = navigation.addListener("transitionEnd", () => {
      inputRef.current?.focus();
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    navigation.setOptions({
      title: fieldName ? t(`studioMini.fields.${fieldName}.label`) : "",
      headerRight: submitButton,
      headerLeft: () => <BackButton onPress={() => navigation.goBack()} />,
    });
  }, [fieldName, handleSubmit, navigation, submitButton, t]);

  return (
    <ScreenWrapper withScrollView withKeyboardAvoidingView contentContainerStyle={styles.root}>
      {fieldName === "productDescription" && (
        <>
          <Text variant="body2">{t("studioMini.fields.productDescription.instructions")}</Text>
          <AutogenerateDescriptionButton setCurrentDescription={setValue} alreadyHasDescription={!!value} />
        </>
      )}
      {collabSuggestionValidation && (
        <FlatList
          data={collabSuggestionValidation.suggestions}
          keyExtractor={item => item.id}
          ListEmptyComponent={collabSuggestionValidation.loading ? <ActivityIndicator animating /> : collabSuggestionValidation.emptyState}
          renderItem={({ item }) => (
            <ListItem
              title={item.name}
              imageProps={{ image: item.image, size: 64, style: { borderRadius: roundness } }}
              titleProps={{ variant: "subtitle2", textTransform: "capitalize" }}
              onPress={() => setSelectedSuggestion(item)}
              style={styles.listItem}
              right={
                <RadioButton.Android
                  status={selectedSuggestion?.id === item.id ? "checked" : "unchecked"}
                  uncheckedColor={colors.primary}
                  color={colors.secondary}
                  value={item.id}
                  onPress={() => setSelectedSuggestion(item)}
                />
              }
            />
          )}
          scrollEnabled={isWeb}
        />
      )}
      {collabInputValidation && (
        <TextInput
          ref={inputRef}
          autoFocus={isWeb && !hasAutogenerateDescriptionPopperToShowOnWeb}
          value={value as string}
          onChangeText={text => setValue(text)}
          disabled={isCurrentlyGeneratingDescription}
          {...collabInputValidation.inputProps}
        />
      )}

      {selectedSuggestion && isContestId(selectedSuggestion.id) && (
        <ContestPasswordValidationDialog
          visible={enterPasswordDialogVisible}
          contestId={selectedSuggestion.id}
          onDismiss={() => setEnterPasswordDialogVisible(false)}
          onValidPassword={() => handleSubmit(selectedSuggestion)}
        />
      )}
    </ScreenWrapper>
  );
};
