import { StyleSheet } from "react-native";

import { contestListItemImageSize } from "@app/common/constants/contests.const";

export const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
  },
  buttonContent: {
    columnGap: 0,
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  buttonsContainer: {
    rowGap: 8,
  },
  header: {
    paddingRight: 20,
  },
  headerTextContainer: {
    minHeight: contestListItemImageSize,
  },
  root: {
    paddingBottom: 16,
    paddingHorizontal: 1,
  },
  section: {
    paddingHorizontal: 20,
    rowGap: 8,
  },
});
