import React, { FC, useCallback, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-paper";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { ShippingAddress } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component.web";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useGenerateAddPaymentMethodDataForAnonymous } from "@app/hooks/api/preorder/use-generate-add-payment-method-data-for-anonymous.hook";
import { useGenerateAddPaymentMethodData } from "@app/hooks/api/preorder/use-generate-add-payment-method-data.hook";
import { paymentMethodForAnonymousQuery } from "@app/hooks/api/preorder/use-payment-method-for-anonymous.hook";
import { paymentMethodsQuery } from "@app/hooks/api/preorder/use-payment-methods.hook";
import { getBillingDetailsFromShippingAddress } from "@app/utils/address-converter/address-converter.util";

import { styles } from "./add-payment-method-button.style";

interface Props extends Omit<ButtonProps, "children" | "icon" | "onPress"> {
  setAnonymousCustomerId?: (id: string) => void;
  buttonComponent?: FC<{ onPress: () => void; loading: boolean }>;
  shippingAddress?: ShippingAddress;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AddPaymentMethodButton: FC<Props> = ({ setAnonymousCustomerId, buttonComponent: ButtonComponent, shippingAddress, ...props }) => {
  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { profile, loading: loadingProfile } = useProfileContext();
  const client = useApolloClient();
  const stripe = useStripe();
  const elements = useElements();

  const { generateAddPaymentMethodData } = useGenerateAddPaymentMethodData();
  const { generateAddPaymentMethodDataForAnonymous } = useGenerateAddPaymentMethodDataForAnonymous();

  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [loadingOpenSheet, setLoadingOpenSheet] = useState(false);
  const [loadingPaymentElement, setLoadingPaymentElement] = useState(true);
  const [loadingAddressElement, setLoadingAddressElement] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isPaymentMethodComplete, setIsPaymentMethodComplete] = useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [tempAnonymousCustomerId, setTempAnonymousCustomerId] = useState<string | undefined>(undefined);

  const onSheetDismiss = useCallback(() => {
    setClientSecret(undefined);
    setIsPaymentMethodComplete(false);
    setIsAddressComplete(false);
    setLoadingSubmit(false);
    setErrorMessage(undefined);
    const addressComponent = elements?.getElement("address");
    addressComponent?.clear();
  }, [elements]);

  const openSheet = useCallback(() => {
    if (loadingProfile) return;

    setLoadingOpenSheet(true);

    if (profile?.userId) {
      void generateAddPaymentMethodData({
        variables: { userId: profile.userId },
        onCompleted: data => {
          setClientSecret(data.generateAddPaymentMethodData.clientSecret);
          sheetRef.current?.open();
          setLoadingOpenSheet(false);
        },
        onError: error => {
          setLoadingOpenSheet(false);
          showErrorSnackbar({
            error,
            message: isErrorCode(error, "TOO_MANY_PAYMENT_METHODS") ? t("preorder.error.tooManyPaymentMethods") : undefined,
          });
        },
      });
    } else {
      void generateAddPaymentMethodDataForAnonymous({
        onCompleted: ({ generateAddPaymentMethodDataForAnonymous: { clientSecret: clientSecretReturned, anonymousCustomerId } }) => {
          setClientSecret(clientSecretReturned);
          setTempAnonymousCustomerId(anonymousCustomerId);
          sheetRef.current?.open();
          setLoadingOpenSheet(false);
        },
        onError: error => {
          setLoadingOpenSheet(false);
          showErrorSnackbar({ error });
        },
      });
    }
  }, [generateAddPaymentMethodData, generateAddPaymentMethodDataForAnonymous, loadingProfile, profile?.userId, showErrorSnackbar, t]);

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements || !clientSecret) {
      setErrorMessage(t("error.generic"));
      return;
    }

    setErrorMessage(undefined);
    setLoadingSubmit(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message || t("error.generic"));
      return;
    }

    stripe
      .confirmSetup({
        elements,
        clientSecret,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        confirmParams: { return_url: window.location.href },
        redirect: "if_required",
      })
      .then(({ error }) => {
        if (error) {
          setErrorMessage(error.message || t("error.generic"));
          setLoadingSubmit(false);
        } else {
          const isAnonymous = !profile && !loadingProfile;
          if (isAnonymous && tempAnonymousCustomerId) setAnonymousCustomerId?.(tempAnonymousCustomerId);
          void client.refetchQueries({ include: [isAnonymous ? paymentMethodForAnonymousQuery : paymentMethodsQuery] });
          sheetRef.current?.close();
        }
      })
      .catch(() => {
        setErrorMessage(t("error.generic"));
        setLoadingSubmit(false);
      });
  }, [client, clientSecret, elements, loadingProfile, profile, setAnonymousCustomerId, stripe, t, tempAnonymousCustomerId]);

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onPress={openSheet} loading={props.loading || loadingOpenSheet} />
      ) : (
        <Button {...props} icon={iconProps => <Plus {...iconProps} />} loading={props.loading || loadingOpenSheet} onPress={openSheet}>
          {t("preorder.addPaymentMethod")}
        </Button>
      )}

      <BottomSheet
        ref={sheetRef}
        scrollable
        onDismiss={onSheetDismiss}
        scrollViewProps={{ contentContainerStyle: styles.contentContainer }}
        title={t("preorder.paymentSheetTitle")}
        footer={
          <Button
            mode="contained"
            size="large"
            disabled={!isPaymentMethodComplete || !isAddressComplete}
            loading={loadingSubmit || !elements || !stripe}
            onPress={() => void handleSubmit()}
            containerStyle={styles.buttonContainer}>
            {t("cta.save")}
          </Button>
        }>
        <Text variant="h9">{t("preorder.billingAddress")}</Text>

        {loadingAddressElement && <ActivityIndicator size="large" />}

        <AddressElement
          options={{ mode: "billing" }}
          onReady={() => setLoadingAddressElement(false)}
          onChange={event => setIsAddressComplete(event.complete)}
          onLoadError={event => showErrorSnackbar({ error: event.error })}
        />

        <Text variant="h9">{t("preorder.cardDetails")}</Text>

        {loadingPaymentElement && <ActivityIndicator size="large" />}

        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: getBillingDetailsFromShippingAddress(shippingAddress),
            },
          }}
          onReady={() => setLoadingPaymentElement(false)}
          onChange={event => setIsPaymentMethodComplete(event.complete)}
          onLoadError={event => showErrorSnackbar({ error: event.error })}
        />

        {errorMessage && <EmbeddedAlert variant={AlertVariant.error} text={errorMessage} />}
      </BottomSheet>
    </>
  );
};
