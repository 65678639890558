import React, { ForwardedRef, forwardRef, JSX, useCallback } from "react";

import { FlashListProps } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import plusAnimatedDark from "@app/assets/lotties/plus-square-dark.json";
import plusAnimatedLight from "@app/assets/lotties/plus-square-light.json";
import { Collab, CollabSortingMethod, ContestResult } from "@app/common/graphql/generated/schema.graphql";
import { OpenStartCampaignFlowButton } from "@app/components/campaign/open-start-campaign-flow-button/open-start-campaign-flow-button.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Text } from "@app/components/common/text/text.component";
import { ProductList, ProductListRefProps } from "@app/components/products/product-list/product-list.component";
import { useCollabs } from "@app/hooks/api/use-collabs.hook";
import { useOpenStudio } from "@app/hooks/utils/use-open-studio.hook";
import { useStartCampaignButton } from "@app/hooks/utils/use-start-campaign-button.hook";

import { styles } from "./creation-list.style";

interface Props extends Omit<FlashListProps<Collab | ContestResult>, "renderItem" | "data"> {
  userId: string;
  isCurrentUser: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent?: JSX.Element;
}

/**
 * @description List of collabs created by a user, displayed on this profile.
 * For the current user, this list is under "Creations" tab.
 */
const CreationListInner = ({ userId, isCurrentUser, ...flatListProps }: Props, ref: ForwardedRef<ProductListRefProps>): JSX.Element => {
  const { t } = useTranslation();

  const { colors } = useTheme();

  const { openStudio } = useOpenStudio();

  const { isStartCampaignButtonVisible } = useStartCampaignButton();
  const includeStartCampaignButtonAndFixItemHeight = useCallback(
    (product: Collab) => isStartCampaignButtonVisible({ creatorId: product.creator.userId, progress: product.progress }),
    [isStartCampaignButtonVisible],
  );

  return (
    <ProductList
      ref={ref}
      useProducts={useCollabs}
      variables={{ where: { creatorId: userId }, sortBy: CollabSortingMethod.createdAtDesc }}
      listItemProps={product => ({
        hideCreatorName: true,
        actionButtons: includeStartCampaignButtonAndFixItemHeight(product)
          ? [<OpenStartCampaignFlowButton key="start-campaign-button" collab={product} />]
          : undefined,
      })}
      contentContainerStyle={styles.root}
      emptyState={
        isCurrentUser ? (
          <View style={styles.emptyStateContainer}>
            <EmptyState
              animatedIcon={{ dark: plusAnimatedDark, light: plusAnimatedLight }}
              message={t("emptyState.collabs.message")}
              cta={[{ label: t("emptyState.collabs.cta"), onPress: openStudio }]}
              style={[styles.emptyState, { backgroundColor: colors.tertiaryContainer }]}
            />
          </View>
        ) : (
          <View style={styles.center}>
            <Text variant="body2" color="tertiary" textAlign="center">
              {t("emptyState.creations")}
            </Text>
          </View>
        )
      }
      {...flatListProps}
    />
  );
};

export const CreationList = forwardRef(CreationListInner);
