import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { shippingAddressFields } from "@app/common/graphql/fragments.graphql";
import { ShippingAddress } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  shippingAddress: ShippingAddress;
}

interface ShippingAddressVariables {
  userId?: string;
}

const shippingAddressQuery = gql`
  query ShippingAddress($userId: UserId!) {
    shippingAddress(userId: $userId) {
      ...ShippingAddressFields
    }
  }
  ${shippingAddressFields}
`;

export function useShippingAddress(options: QueryHookOptions<Response, ShippingAddressVariables>): QueryResult<Response, "shippingAddress"> {
  const { data, loading, error, refetch } = useQuery<Response, ShippingAddressVariables>(shippingAddressQuery, {
    skip: !options.variables?.userId,
    ...options,
  });

  return { data: data?.shippingAddress, loading, error, refetch };
}
