import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  alert: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  button: {
    marginHorizontal: 8,
  },
});
