import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadImage: {
    imageId: string;
    imageUrl: string;
    userId: string;
  };
}

interface Variables {
  input: FormData;
  userId: string;
}

interface UploadStudioImageResult extends MutationResult<Response, "uploadImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadImage($input: FormData!, $userId: String!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadImage(input: $input, userId: $userId)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/upload/{args.userId}/image", type: "File") {
      imageId
      imageUrl
      userId
    }
  }
`;

export function useUploadImage(): UploadStudioImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadImageMutation, {
    context: { clientName: ClientName.bloomRest },
  });

  return { uploadImage, loading, error, data: data?.uploadImage };
}
