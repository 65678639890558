import React, { FC, useEffect, useState } from "react";

import { AuthError, EmailAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Keyboard, View } from "react-native";

import { BottomSheetTextInput } from "@app/components/common/bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { Button } from "@app/components/common/button/button.component";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";
import { isEmailValid } from "@app/utils/user.util";

import { styles } from "./enter-email.style";

interface Props {
  goToEnterPassword: (email: string) => void;
  goToCreateAccount: (email: string) => void;
}

export const EnterEmail: FC<Props> = ({ goToEnterPassword, goToCreateAccount }) => {
  const { t } = useTranslation();
  const { handleAuthError } = useHandleAuthError();
  const { emailForSignIn: emailFromLocalStorage, loading: loadingEmailFromLocalStorage, setLocalStorage } = useLocalStorageContext();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loadingEmailFromLocalStorage && emailFromLocalStorage) {
      setEmail(emailFromLocalStorage);
    }
  }, [emailFromLocalStorage, loadingEmailFromLocalStorage]);

  const handleNext = (): void => {
    if (!isEmailValid(email)) return;

    setLoading(true);
    Keyboard.dismiss();
    setLocalStorage(LocalStorageKeys.emailForSignIn, email);

    getAuthWhenReady()
      .then(auth =>
        fetchSignInMethodsForEmail(auth, email)
          .then(signInMethods => {
            signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) ? goToEnterPassword(email) : goToCreateAccount(email);

            setLoading(false);
          })
          .catch((error: AuthError) => {
            setLoading(false);
            handleAuthError(error, LoginAction.fetchSignInMethodsForEmailAfterEnterEmail);
          }),
      )
      .catch((error: AuthError) => {
        setLoading(false);
        handleAuthError(error, LoginAction.getAuth);
      });
  };

  return (
    <View style={styles.wrapperView}>
      <View style={styles.formView}>
        <BottomSheetTextInput
          label={t("login.email.emailAddress")}
          value={email}
          onChangeText={setEmail}
          autoCapitalize="none"
          inputMode="email"
          disabled={loading}
          autoComplete="email"
          onSubmitEditing={handleNext}
          returnKeyType="next"
        />
        <Button mode="contained" size="large" loading={loading} disabled={!isEmailValid} onPress={handleNext}>
          {t("cta.next")}
        </Button>
      </View>
    </View>
  );
};
