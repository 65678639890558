import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { SupercoinBalance } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  supercoinBalance: SupercoinBalance;
}

interface Variables {
  userId?: string;
}

export const supercoinBalanceQuery = gql`
  query SupercoinBalance($userId: UserId!) {
    supercoinBalance(userId: $userId) {
      amount
      formattedAmount @client
    }
  }
`;

export function useSupercoinBalance(options?: QueryHookOptions<Response, Variables>): QueryResult<Response, "supercoinBalance"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(supercoinBalanceQuery, {
    skip: !options?.variables?.userId,
    ...options,
  });

  return { data: data?.supercoinBalance, loading, error, refetch };
}
