import { useEffect, useMemo, useRef } from "react";

import { gql, useSubscription } from "@apollo/client";

import { Health } from "@app/common/graphql/generated/schema.graphql";
import { BaseResult } from "@app/common/types/apollo-result.type";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { logToSentry } from "@app/utils/log-sentry.util";

interface Response {
  healthReported: Health;
}

const healthCheckSubscription = gql`
  subscription HealthCheck {
    healthReported {
      status
    }
  }
`;

const logSentryAfter12Seconds = 12;

export function useHealthCheck(): BaseResult<Response> {
  const { isFeatureEnabled } = useLaunchDarklyContext();
  const websocketIsEnabled = useMemo(() => isFeatureEnabled(FeatureFlag.enableHealthCheckWebsocket), [isFeatureEnabled]);

  const seconds = useRef(0);
  const sentryInterval = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearSentryInterval = (): void => {
    if (sentryInterval.current) {
      clearInterval(sentryInterval.current);
      sentryInterval.current = undefined;
    }
  };

  useEffect(() => {
    if (!websocketIsEnabled) return () => undefined;

    sentryInterval.current = setInterval(() => {
      seconds.current = seconds.current + 1;

      if (seconds.current >= logSentryAfter12Seconds) {
        logToSentry("Websocket didn't receive health status for >10 seconds");
        clearSentryInterval();
      }
    }, 1000);

    return clearSentryInterval;
  }, [seconds, sentryInterval, websocketIsEnabled]);

  return useSubscription<Response>(healthCheckSubscription, {
    skip: !websocketIsEnabled,
    onData: () => {
      seconds.current = 0;
    },
  });
}
