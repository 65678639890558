import { CampaignVideo, CollabImage, CollabMediaContent, StudioAnimation } from "@app/common/graphql/generated/schema.graphql";
import { AnyCollab } from "@app/common/types/collab.type";

export function isImage(media?: CollabMediaContent): media is CollabImage {
  return media?.__typename === "CollabImage";
}

export function isVideo(entity?: CollabMediaContent): entity is CampaignVideo {
  return entity?.__typename === "CampaignVideo";
}

export function isAnimation(media?: CollabMediaContent): media is StudioAnimation {
  return media?.__typename === "StudioAnimation";
}

export function getProductMediaId(media?: CollabMediaContent): string | undefined {
  if (isImage(media)) return media.collabImageId;
  if (isVideo(media)) return media?.campaignVideoId;
  if (isAnimation(media)) return media?.studioAnimationId;

  return undefined;
}

export function getMedia(collab?: AnyCollab): CollabMediaContent[] | undefined {
  if (collab?.__typename === "Collab") return collab.images;
  else if (collab?.__typename === "CollabWithMedia") return collab.media;

  return undefined;
}
