import { StyleSheet } from "react-native";

import { customFonts } from "@app/common/style/fonts";

export const styles = StyleSheet.create({
  fullWidth: {
    width: "100%",
  },
  root: {
    borderRadius: 4,
  },
  rootContentWithIcon: {
    columnGap: 8,
  },
  rootLabel: {
    ...customFonts.buttonMedium,
    marginHorizontal: 16,
    marginVertical: 8,
  },
  sizeLargeLabel: {
    ...customFonts.buttonLarge,
    marginHorizontal: 22,
    marginVertical: 12,
  },
  sizeSmall: {},
  sizeSmallLabel: {
    ...customFonts.buttonSmall,
    marginHorizontal: 10,
    marginVertical: 4,
  },
  sizeXSmallLabel: {
    fontSize: 12,
    fontWeight: "400",
    letterSpacing: 0.4,
    lineHeight: 16,
    marginHorizontal: 2,
    marginRight: 6,
    marginVertical: 0,
  },
});
