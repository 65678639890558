import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";

import { styles } from "./badge-legend.style";

export const BadgeLegend: FC = () => {
  const { t } = useTranslation();

  const iconSize = 20;
  const textProps: Omit<TextProps, "children"> = { variant: "caption" };

  const badges: (Badge.staff | Badge.supercreator | Badge.supercreatorAndSuperseller | Badge.superseller | Badge.vettedCreator)[] = [
    Badge.vettedCreator,
    Badge.supercreator,
    Badge.superseller,
    Badge.supercreatorAndSuperseller,
    Badge.staff,
  ];

  return (
    <View style={styles.container}>
      <Text variant="overline" textTransform="uppercase">
        {t("profile.more.badgeLegend.title")}
      </Text>

      {badges.map(badge => (
        <View style={styles.row} key={badge}>
          <UserBadge type={badge} size={iconSize} />
          <Text {...textProps}>{t(`enum.badge.${badge}`)}</Text>
          {badge === Badge.supercreatorAndSuperseller && (
            <Text {...textProps} color="tertiary">
              {t("profile.more.badgeLegend.superstarExplanation")}
            </Text>
          )}
        </View>
      ))}
    </View>
  );
};
