import { useCallback } from "react";

import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";

interface Response {
  navigateToMainScreen: (action?: "navigate" | "replace" | "push") => void;
  navigateToNotificationsScreen: () => void;
}

export const useNewHomeNavigation = (): Response => {
  const navigation = useNavigation();

  const navigateToMainScreen = useCallback(
    (action: "navigate" | "replace" | "push" = "navigate"): void => {
      if (action === "replace") {
        navigation.replace(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
      } else if (action === "push") {
        navigation.push(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
      } else {
        navigation.navigate(Routes.root, { screen: Tabs.feed, params: { screen: Routes.feed } });
      }
    },
    [navigation],
  );

  const navigateToNotificationsScreen = useCallback(() => {
    navigation.navigate(Routes.root, { screen: Tabs.feed, params: { screen: Routes.notifications } });
  }, [navigation]);

  return { navigateToMainScreen, navigateToNotificationsScreen };
};
