import { LazyQueryExecFunction, QueryHookOptions, gql, useLazyQuery, useQuery } from "@apollo/client";

import { productVariantFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedCollabVariants, VariantsFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult, QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  variants: PaginatedCollabVariants;
}

interface Variables {
  where?: VariantsFilteringArgs;
}

interface LazyCollabVariantsResult extends BaseQueryResult<Response, "variants"> {
  getVariants: LazyQueryExecFunction<Response, Variables>;
}

const collabVariantsQuery = gql`
  query Variants($where: VariantsFilteringArgs!) {
    variants(where: $where) {
      nodes {
        collabId
        options {
          name
          values
        }
        variants {
          ...ProductVariantFields
        }
      }
    }
  }
  ${productVariantFields}
`;

export function useCollabVariants(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "variants"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(collabVariantsQuery, {
    skip: !options?.variables?.where?.collabIds?.length,
    ...options,
  });

  return { data: data?.variants, loading, error, refetch };
}

export function useLazyCollabVariants(): LazyCollabVariantsResult {
  const [getVariants, { loading, error, data }] = useLazyQuery<Response>(collabVariantsQuery);

  return { getVariants, loading, error, data: data?.variants };
}
