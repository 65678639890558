import React, { useEffect, useMemo } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { useProfileContext } from "../profile/profile.context";

import { FeedContext, FeedContextInterface } from "./feed.context";
import { useFeedProps } from "./use-feed-props.hook";

export const FeedContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const { profile } = useProfileContext();
  const userId = profile?.userId;

  const feed = useFeedProps();

  useEffect(() => {
    if (userId) {
      feed.resetFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const context = useMemo<FeedContextInterface>(
    () => ({
      feed,
    }),
    [feed],
  );

  return <FeedContext.Provider value={context}>{children}</FeedContext.Provider>;
};
