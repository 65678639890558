import { createContext, useContext } from "react";

export interface IncomingPushNotificationsContextInterface {
  clearNotifications: () => void;
}

export const IncomingPushNotificationsContext = createContext<IncomingPushNotificationsContextInterface>({
  clearNotifications: () => undefined,
});

export const useIncomingPushNotificationsContext = (): IncomingPushNotificationsContextInterface => useContext(IncomingPushNotificationsContext);
