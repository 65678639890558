import React, { FC, useCallback, useMemo, useRef } from "react";

import { DotsThreeVertical, PencilSimpleLine } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RadioButton, useTheme } from "react-native-paper";

import { CardPaymentMethod } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { TextProps } from "@app/components/common/text/text.component";
import { cardPaymentBrandIconMap } from "@app/utils/card-payment-brand-icon.util";

import { AddPaymentMethodButton } from "../../add-payment-method-button/add-payment-method-button.component";
import { DeletePaymentMethodButton } from "../delete-payment-method-button/delete-payment-method-button.component";
import { PaymentMethodListMode, PaymentMethodListModeProps } from "../payment-method-list.types";

import { styles } from "./payment-method-list-item.style";

interface CommonProps {
  paymentMethod: CardPaymentMethod;
}

type Props = PaymentMethodListModeProps<CommonProps>;

export const PaymentMethodListItem: FC<Props> = ({
  mode,
  paymentMethod,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  setAnonymousCustomerId,
}) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const moreMenuSheetRef = useRef<BottomSheetRefProps>(null);

  const isSelectableMode = mode === PaymentMethodListMode.selectable;
  const isNoSelectableMode = mode === PaymentMethodListMode.nonSelectable;
  const isUniqueSelectedMode = mode === PaymentMethodListMode.uniqueSelected;

  const disabled = isSelectableMode && paymentMethod.expiredOrExpiringSoon;
  const isSelected = (isSelectableMode && paymentMethod.paymentMethodId === selectedPaymentMethodId) || isUniqueSelectedMode;

  const radioButtonColor = isSelected ? colors.onSecondary : colors.onBackground;
  const brandIcon =
    paymentMethod.brand in cardPaymentBrandIconMap ? cardPaymentBrandIconMap[paymentMethod.brand] : cardPaymentBrandIconMap["unknown"];

  const textProps: Omit<TextProps, "children"> = { variant: "body2", color: isSelected ? "onSecondary" : "onBackground" };

  const handleListItemPress = useCallback((): void => {
    if (mode === PaymentMethodListMode.selectable) {
      setSelectedPaymentMethodId(paymentMethod.paymentMethodId);
    } else if (isUniqueSelectedMode) {
      moreMenuSheetRef.current?.open();
    }
  }, [isUniqueSelectedMode, mode, paymentMethod.paymentMethodId, setSelectedPaymentMethodId]);

  const leftItem = useMemo(() => {
    if (isSelectableMode) {
      return (
        <RadioButton.Android
          value={paymentMethod.paymentMethodId}
          status={isSelected ? "checked" : "unchecked"}
          uncheckedColor={radioButtonColor}
          disabled={disabled}
          color={radioButtonColor}
          onPress={handleListItemPress}
        />
      );
    }

    return brandIcon;
  }, [isSelectableMode, brandIcon, paymentMethod.paymentMethodId, isSelected, radioButtonColor, disabled, handleListItemPress]);

  const rightItem = useMemo(() => {
    if (isSelectableMode) {
      return brandIcon;
    } else if (isUniqueSelectedMode) {
      return <DotsThreeVertical color={colors.onSecondary} size={32} />;
    }

    return <DeletePaymentMethodButton paymentMethodId={paymentMethod.paymentMethodId} color={radioButtonColor} />;
  }, [isSelectableMode, isUniqueSelectedMode, paymentMethod.paymentMethodId, radioButtonColor, brandIcon, colors.onSecondary]);

  return (
    <>
      <ListItem
        title={t("preorder.hiddenCardNumber", { last4Digits: paymentMethod.last4Digits })}
        titleProps={{ ...textProps, color: disabled ? "action.disabled" : textProps.color }}
        subtitle={t("preorder.cardExpiryDate", { month: paymentMethod.expirationMonth, year: paymentMethod.expirationYear })}
        subtitleProps={{ ...textProps, color: paymentMethod.expiredOrExpiringSoon ? "error" : textProps.color }}
        left={leftItem}
        right={rightItem}
        style={[
          {
            backgroundColor: isSelected ? colors.secondary : undefined,
            borderColor: isSelected ? colors.secondary : disabled ? colors.action.disabled : colors.outline,
            borderRadius: roundness,
          },
          styles.listItemCommon,
          isSelectableMode ? styles.listItemSelectable : isUniqueSelectedMode ? styles.listItemUniqueSelected : styles.listItemNonSelectable,
        ]}
        onPress={isNoSelectableMode || disabled ? undefined : handleListItemPress}
      />

      <BottomSheet ref={moreMenuSheetRef} contentContainerStyle={styles.editBottomSheetContentContainer}>
        <AddPaymentMethodButton
          setAnonymousCustomerId={setAnonymousCustomerId}
          buttonComponent={({ onPress: handleAddPayment, loading }) => (
            <ListItem
              title={t("cta.edit")}
              titleProps={{ variant: "subtitle1" }}
              left={
                loading ? (
                  <ActivityIndicator size="small" color={colors.onBackground} style={styles.editIcon} />
                ) : (
                  <PencilSimpleLine size={24} color={colors.onBackground} style={styles.editIcon} />
                )
              }
              onPress={() => {
                moreMenuSheetRef.current?.close();
                handleAddPayment();
              }}
            />
          )}
        />
      </BottomSheet>
    </>
  );
};
