import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const paddingHorizontal = 20;

export const styles = StyleSheet.create({
  gradient: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  header: {
    alignSelf: "center",
    justifyContent: isWeb ? "center" : undefined,
  },
  mainContainer: {
    aspectRatio: 1,
    justifyContent: "center",
    width: "100%",
  },
  root: {
    flex: 1,
  },
  title: {
    paddingHorizontal,
  },
});
