import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  emptyState: {
    borderRadius: 8,
    overflow: "hidden",
    paddingHorizontal: 32,
    paddingVertical: 16,
    width: "100%",
  },
  emptyStateContainer: {
    marginHorizontal: 8,
    marginTop: 4,
  },
  iconButton: {
    marginHorizontal: 0,
  },
  root: {
    paddingVertical: 8,
  },
  row: {
    flexDirection: "row",
  },
  screenTitle: {
    marginRight: 2,
  },
});
