import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { log } from "@app/utils/logger/logger.util";

import { LocalStorageContext, LocalStorageContextInterface } from "./local-storage.context";
import { LocalStorageInterface, LocalStorageKeys, defaultLocalStorageValues } from "./local-storage.type";

export const LocalStorageContextProvider: FC<ChildrenProp> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [localStorageState, setLocalStorageState] = useState<LocalStorageInterface>(defaultLocalStorageValues);

  const getLocalStorage = useCallback(async <Key extends LocalStorageKeys>(key: Key): Promise<LocalStorageInterface[Key]> => {
    try {
      const value: string | null = await AsyncStorage.getItem(key);

      return value ? (JSON.parse(value) as LocalStorageInterface[Key]) : defaultLocalStorageValues[key];
    } catch (e) {
      return defaultLocalStorageValues[key];
    }
  }, []);

  const getAllLocalStorage = useCallback(async (): Promise<LocalStorageInterface> => {
    return {
      [LocalStorageKeys.anonymousCartId]: await getLocalStorage(LocalStorageKeys.anonymousCartId),
      [LocalStorageKeys.contestPasswords]: await getLocalStorage(LocalStorageKeys.contestPasswords),
      [LocalStorageKeys.earlyBirdAlertDismissed]: await getLocalStorage(LocalStorageKeys.earlyBirdAlertDismissed),
      [LocalStorageKeys.emailForSignIn]: await getLocalStorage(LocalStorageKeys.emailForSignIn),
      [LocalStorageKeys.featureAnnouncementsViewed]: await getLocalStorage(LocalStorageKeys.featureAnnouncementsViewed),
      [LocalStorageKeys.firstOpenEventDate]: await getLocalStorage(LocalStorageKeys.firstOpenEventDate),
      [LocalStorageKeys.pushNotifsPermissionSeenOnce]: await getLocalStorage(LocalStorageKeys.pushNotifsPermissionSeenOnce),
    };
  }, [getLocalStorage]);

  const initializeLocalStorage = useCallback((): void => {
    getAllLocalStorage()
      .then(localStorageInitialValues => setLocalStorageState(localStorageInitialValues))
      .catch(() => undefined)
      .finally(() => setLoading(false));
  }, [getAllLocalStorage]);

  const setLocalStorage = useCallback(async <Key extends LocalStorageKeys>(key: Key, value: LocalStorageInterface[Key]): Promise<void> => {
    try {
      if (value === null || value === undefined) {
        await AsyncStorage.removeItem(key);
      } else {
        await AsyncStorage.setItem(key, JSON.stringify(value));
      }
    } catch (e) {
      log.error(e);
    }
  }, []);

  useEffect(() => {
    initializeLocalStorage();
  }, [initializeLocalStorage]);

  const wrappedSetLocalStorage = useCallback(
    <Key extends LocalStorageKeys>(key: Key, value: LocalStorageInterface[Key]): void => {
      void setLocalStorage(key, value);
      setLocalStorageState(previousState => ({ ...previousState, [key]: value }));
    },
    [setLocalStorage],
  );

  const clearAllLocalStorageValues = useCallback(() => {
    AsyncStorage.multiRemove(Object.values(LocalStorageKeys))
      .then(() => initializeLocalStorage())
      .catch(() => undefined);
  }, [initializeLocalStorage]);

  const context = useMemo<LocalStorageContextInterface>(() => {
    return { ...localStorageState, loading, setLocalStorage: wrappedSetLocalStorage, clearAllLocalStorageValues };
  }, [clearAllLocalStorageValues, loading, localStorageState, wrappedSetLocalStorage]);

  return <LocalStorageContext.Provider value={context}>{children}</LocalStorageContext.Provider>;
};
