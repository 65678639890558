import React, { FC, memo } from "react";

import { useTranslation } from "react-i18next";
import { TouchableHighlight, View } from "react-native";
import { useTheme } from "react-native-paper";

import { Comment } from "@app/common/graphql/generated/schema.graphql";
import { AnimatedLikeIcon } from "@app/components/common/animated-like-icon/animated-like-icon.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useLikeComment } from "@app/hooks/api/comments/use-like-comment.hook";
import { useUnlikeComment } from "@app/hooks/api/comments/use-unlike-comment.hook";
import { formattedDistanceToNow } from "@app/utils/format.util";

import { CommentMoreButton } from "../comment-more-button/comment-more-button.component";

import { styles } from "./comment-list-item.style";

interface Props {
  comment: Comment;
  parentId: string;
  onCreatorPress: () => void;
}

const CommentListItem: FC<Props> = ({ comment, parentId, onCreatorPress }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { profile } = useProfileContext();

  const { likeComment, loading: loadingLike } = useLikeComment();
  const { unlikeComment, loading: loadingUnlike } = useUnlikeComment();

  const handleOnPressLike = (): void => {
    if (!profile || loadingLike || loadingUnlike) return;

    if (comment.liked) {
      void unlikeComment({ variables: { userId: profile.userId, input: { commentId: comment.commentId } } });
    } else {
      void likeComment({ variables: { userId: profile.userId, input: { commentId: comment.commentId } } });
    }
  };

  return (
    <ListItem
      imageProps={{ image: comment.user.imageUrl, size: 40 }}
      title={
        <View style={styles.rowSpaceBetween}>
          <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={onCreatorPress}>
            <CreatorName name={comment.user.displayName} badge={comment.user.badge} textProps={{ variant: "h8" }} />
          </TouchableHighlight>
          <CommentMoreButton commentId={comment.commentId} commentCreatorId={comment.user.userId} parentId={parentId} />
        </View>
      }
      subtitle={comment.content}
      subtitleProps={{ variant: "body2", numberOfLines: 0 }}
      description={
        <View style={styles.rowSpaceBetween}>
          <Text variant="caption" color="tertiary">
            {formattedDistanceToNow(comment.createdAt, t)}
          </Text>
          <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} disabled={!profile} onPress={handleOnPressLike}>
            <View style={styles.likeCountRow}>
              <Text variant="body2" color="tertiary">
                {comment.formattedLikeCount}
              </Text>

              <AnimatedLikeIcon liked={!!comment.liked} size={20} floating />
            </View>
          </TouchableHighlight>
        </View>
      }
      textContainerStyle={styles.textContainer}
      contentContainerStyle={styles.commentContentContainer}
    />
  );
};

export default memo(CommentListItem);
