import React, { useCallback, FC } from "react";

import { NotificationFeedbackType, notificationAsync } from "expo-haptics";
import { DotsSix } from "phosphor-react-native";
import { GestureDetector, Gesture } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";
import Animated, { Easing, SharedValue, runOnJS, useAnimatedReaction, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import { getMediaToBeUsedImageUrl, MediaToBeUsed } from "@app/common/types/image.type";
import { isWeb } from "@app/utils/device.util";
import { clamp, moveObject } from "@app/utils/drag-drop.util";

import { ImagePickerItem } from "../image-picker-item/image-picker-item.component";
import { itemSize } from "../images-picker.style";

import { styles } from "./draggable-image-item.style";

const animationConfig = {
  easing: Easing.inOut(Easing.ease),
  duration: 350,
};

interface Props {
  media: MediaToBeUsed;
  onRemove: (imageUrl: string) => void;
  positions: SharedValue<Record<string, number>>;
  itemsCount: number;
  onDropFinish?: () => void;
}

export const DraggableImageItem: FC<Props> = ({ media, onRemove, positions, itemsCount, onDropFinish }) => {
  const { colors } = useTheme();

  const imageUrl = getMediaToBeUsedImageUrl(media) ?? "";
  const translateX = useSharedValue<number>((positions.value[imageUrl] ?? itemsCount) * itemSize);
  const translateY = useSharedValue<number>(0);
  const isDragging = useSharedValue(false);
  const tempPosition = useSharedValue({ x: 0, y: 0 });

  const sendHaptic = useCallback(() => {
    if (isWeb) return;
    void notificationAsync(NotificationFeedbackType.Warning);
  }, []);

  useAnimatedReaction(
    () => positions.value[imageUrl],
    (currentPosition, previousPosition) => {
      if (currentPosition !== previousPosition && !isDragging.value) {
        runOnJS(sendHaptic)();
        translateX.value = withTiming(currentPosition * itemSize);
      }
    },
  );

  const onGestureEvent = Gesture.Pan()
    .onStart(() => {
      isDragging.value = true;

      tempPosition.value.x = translateX.value;
      tempPosition.value.y = translateY.value;
    })
    .onUpdate(e => {
      const positionX = tempPosition.value.x + e.translationX;
      translateX.value = positionX;
      translateY.value = tempPosition.value.y + e.translationY;

      const newPosition = clamp(Math.floor(positionX / itemSize), 1, itemsCount);

      if (newPosition !== positions.value[imageUrl]) {
        positions.value = moveObject(positions.value, positions.value[imageUrl], newPosition);
      }
    })
    .onEnd(() => {
      translateX.value = withTiming(positions.value[imageUrl] * itemSize, animationConfig, () => {
        isDragging.value = false;
      });
      translateY.value = withTiming(tempPosition.value.y, animationConfig);
    })
    .onFinalize((_, success) => {
      if (success && onDropFinish) runOnJS(onDropFinish)();
    });

  const animatedStyle = useAnimatedStyle(() => ({
    zIndex: isDragging.value ? 100 : 0,
    transform: [{ translateX: translateX.value }, { translateY: translateY.value }, { scale: isDragging.value ? 1.1 : 1 }],
  }));

  return (
    <Animated.View style={[animatedStyle, styles.rowItem]}>
      <ImagePickerItem
        imageUrl={imageUrl}
        type={"imageUrl" in media ? "image" : "campaignVideoId" in media ? "campaignVideo" : "animation"}
        onRemove={onRemove}
      />
      {itemsCount > 1 ? (
        <GestureDetector gesture={onGestureEvent}>
          <Animated.View style={styles.icon}>
            <DotsSix weight="regular" color={colors.onBackground} />
          </Animated.View>
        </GestureDetector>
      ) : null}
    </Animated.View>
  );
};
