import React, { FC, useEffect } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { ArrowLeft } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { RequestAQuoteButton } from "@app/components/campaign/request-a-quote-button/request-a-quote-button.component";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useCollabWithMedia } from "@app/hooks/api/products/use-collab-with-media.hook";

import { styles } from "./ask-for-a-quote.style";

export const AskForAQuoteScreen: FC<NavigationProps<Routes.askForAQuote>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { colors, dark } = useTheme();
  const { config } = useConfigContext();

  const collabHandle = route.params?.collabHandle;
  const likeCountObjective = config?.likeCountObjective;
  const { data: collab, loading: loadingCollab } = useCollabWithMedia({ variables: { handle: collabHandle ?? "" } });

  const backgroundColorIconButton = "rgba(0,0,0,0.25)";
  const gradients = dark
    ? ["rgba(0,0,0,0.0)", "rgba(0,0,0,0.0)", "rgba(0,0,0,1)"]
    : ["rgba(255,255,255,0.0)", "rgba(255,255,255,0.0)", "rgba(255,255,255,1)"];

  useEffect(() => {
    const cannotRequestQuote = collab && !collab.progress.canRequestQuote;
    const collabNotFound = !collab && !loadingCollab;
    if (cannotRequestQuote || collabNotFound) {
      navigation.goBack();
    }
  }, [collab, loadingCollab, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <IconButton
          icon={ArrowLeft}
          iconColor={colors.common.white}
          onPress={() => navigation.goBack()}
          style={{ backgroundColor: backgroundColorIconButton }}
        />
      ),
    });
  }, [colors.common.white, navigation]);

  return (
    <ScreenWrapper
      safeAreaEdges={["right", "left", "bottom"]}
      withScrollView
      staticContent={
        collab && (
          <RequestAQuoteButton
            mode="contained"
            size="large"
            collabId={collab.collabId}
            progress={collab.progress}
            containerStyle={styles.buttonContainer}
          />
        )
      }>
      <View>
        <Image source={collab?.thumbnailImage.imageUrl} style={styles.imageContainer} />
        <LinearGradient locations={[0, 0.67, 1]} colors={gradients} style={styles.linearGradient} />
      </View>

      <View style={styles.textContainer}>
        <Text variant="title" textAlign="center">
          {t("startCampaign.dialog.title.canRequestQuote", { likeCountObjective })}
        </Text>
        <Text variant="body2" textAlign="center">
          {t("startCampaign.dialog.explanation.canRequestQuote")}
        </Text>
      </View>
    </ScreenWrapper>
  );
};
