import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { GenerateAnimationFromImageInput, StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

import { imageGenerationCreditQuery } from "../use-image-generation-credits.hook";

interface Response {
  startGeneratingAnimationFromImage: StudioTask;
}

interface Variables {
  input: GenerateAnimationFromImageInput;
  userId: string;
}

interface StartGeneratingImagesFromImageResult extends MutationResult<Response, "startGeneratingAnimationFromImage"> {
  startGeneratingAnimationFromImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startGeneratingImagesFromImageMutation = gql`
  mutation StartGeneratingAnimationFromImage($input: GenerateAnimationFromImageInput!, $userId: UserId!) {
    startGeneratingAnimationFromImage(input: $input, userId: $userId) {
      studioTaskId
    }
  }
`;

export function useStartGeneratingAnimationFromImage(): StartGeneratingImagesFromImageResult {
  const [startGeneratingAnimationFromImage, { loading, error, data, reset, client }] = useMutation<Response, Variables>(
    startGeneratingImagesFromImageMutation,
    {
      update: () => {
        void client.refetchQueries({ include: [imageGenerationCreditQuery] });
      },
    },
  );

  return { startGeneratingAnimationFromImage, loading, error, data: data?.startGeneratingAnimationFromImage, reset };
}
