import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  centeredRow: {
    alignItems: "center",
    flexDirection: "row",
  },
  rowGap: {
    rowGap: 16,
  },
});
