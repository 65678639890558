import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { DetailedProgress } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  detailedProgress: DetailedProgress;
}

interface Variables {
  collabId?: string;
}

const detailedProgressQuery = gql`
  query DetailedProgress($collabId: CollabId!) {
    detailedProgress(collabId: $collabId) {
      detailedAllowedTransitions {
        detailedStage
        stage
      }
      detailedCurrentStage {
        detailedStage
        stage
      }
    }
  }
`;

export function useDetailedProgress(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "detailedProgress"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(detailedProgressQuery, {
    skip: !options.variables?.collabId,
    ...options,
  });

  return { data: data?.detailedProgress, loading, error, refetch };
}
