import { createContext, useContext } from "react";

import { Channel, GeneratedLinkData } from "@app/hooks/utils/deeplink/branch.common";
import { mockContextPromise } from "@app/utils/context.util";

export interface BranchContextInterface {
  isReady: boolean;
  channel: Channel | undefined;
  generateDeepLink: (path: string, data?: GeneratedLinkData) => Promise<string>;
  openDeepLink: (path: string, data?: GeneratedLinkData, feature?: string, campaign?: string, tags?: string[]) => Promise<void>;
}

const branchDefaultState: BranchContextInterface = {
  isReady: false,
  generateDeepLink: () => mockContextPromise<string>("BranchContext", "generateDeepLink"),
  openDeepLink: () => mockContextPromise<void>("BranchContext", "openDeepLink"),
  channel: undefined,
};

export const BranchContext = createContext<BranchContextInterface>(branchDefaultState);

export const useBranchContext = (): BranchContextInterface => useContext(BranchContext);
