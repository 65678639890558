import React, { JSX } from "react";

import { ActivityVerb, Contest, NotificationActor, NotificationObject } from "@app/common/graphql/generated/schema.graphql";
import { Button } from "@app/components/common/button/button.component";
import { reportError } from "@app/utils/logger/logger.util";

export function getIdFromNotifiableEntity(entity: NotificationActor | NotificationObject): string {
  switch (entity.__typename) {
    case "Collab":
      return entity.collabId;
    case "Contest":
      return entity.contestId;
    case "Profile":
      return entity.userId;
    case "DeletedObject":
      return entity.objectId;
    case "CollabUpdate":
      return entity.collabUpdateId;
    case "Order":
      return entity.orderId;
    default:
      reportError(new Error("[InAppNotif]: getIdFromNotifiableEntity case not handled"));
      return "";
  }
}

export function getContestFromNotifiableEntity(entity: NotificationActor | NotificationObject, verb: ActivityVerb): Contest | undefined {
  if (entity.__typename === "Contest") return entity;
  if (entity.__typename === "DeletedObject") return undefined;

  reportError(new Error(`Received ${entity.__typename ?? ""} as object when contest was expected for notification verb ${verb}`));
  return undefined;
}

export function getImageFromNotifiableEntity(entity: NotificationActor | NotificationObject | undefined): string | undefined {
  if (!entity) return undefined;

  switch (entity.__typename) {
    case "Collab":
      return entity.images[0]?.imageUrl;
    case "Contest":
      return entity.imageUrl;
    case "Profile":
      return entity.imageUrl;
    case "CollabUpdate":
      return entity.images[0]?.imageUrl;
    case "Order":
      return entity.lineItems[0]?.collab?.images[0]?.imageUrl;
    default:
      return undefined;
  }
}

export function getButton(label: string, onPress?: () => void): JSX.Element {
  const buttonStyle = { flexGrow: 1 };

  return (
    <Button mode="outlined" size="small" fullWidth onPress={onPress} style={buttonStyle}>
      {label}
    </Button>
  );
}
