import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { orderFields } from "@app/common/graphql/fragments.graphql";
import { CancelPreorderInput, Order } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  cancelPreorder: Order;
}

interface Variables {
  orderId: string;
  input?: CancelPreorderInput;
}

interface CancelPreorderResult extends MutationResult<Response, "cancelPreorder"> {
  cancelPreorder: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const cancelPreorderMutation = gql`
  mutation CancelPreorder($input: CancelPreorderInput, $orderId: String!) {
    cancelPreorder(input: $input, orderId: $orderId) {
      ...OrderFields
    }
  }
  ${orderFields}
`;

export function useCancelPreorder(): CancelPreorderResult {
  const [cancelPreorder, { loading, error, data }] = useMutation<Response, Variables>(cancelPreorderMutation);

  return { cancelPreorder, loading, error, data: data?.cancelPreorder };
}
