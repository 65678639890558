import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationArchiveCollabArgs, MutationDenyCollabArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  denyCollab: Collab;
}

interface DenyCollabResult extends MutationResult<Response, "denyCollab"> {
  denyCollab: (options?: MutationFunctionOptions<Response, MutationDenyCollabArgs>) => Promise<FetchResult>;
}

const denyCollabMutation = gql`
  mutation DenyCollab($collabId: CollabId!) {
    denyCollab(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useDenyCollab(): DenyCollabResult {
  const [denyCollab, { loading, error, data }] = useMutation<Response, MutationArchiveCollabArgs>(denyCollabMutation);

  return { denyCollab, loading, error, data: data?.denyCollab };
}
