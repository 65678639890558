import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@app/components/common/button/button.component";

import { Box } from "../../box/box.component";
import { DateTimePicker } from "../date-time-picker.component";

export interface Props {
  fieldLabel: string;
  value: Date | null;
  setValue: (newValue: Date | null) => void;
}

export const OptionalDatePicker: FC<Props> = ({ fieldLabel, value, setValue }) => {
  const { t } = useTranslation();

  return value ? (
    <Box alignItems="center">
      <DateTimePicker date={value} setDate={setValue} />
      <Button size="small" onPress={() => setValue(null)} color="error">
        {t("dateTimePicker.resetDateTime", { fieldLabel })}
      </Button>
    </Box>
  ) : (
    <Button size="small" mode="outlined" onPress={() => setValue(new Date())}>
      {t("dateTimePicker.addDateTime", { fieldLabel })}
    </Button>
  );
};
