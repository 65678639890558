import * as Device from "expo-device";
import { Platform } from "react-native";

export const isWeb = Platform.OS === "web";

/**
 * Whether or not you're on an iOS, iPadOS or Mac devices; does not indicate app vs web
 */
export const isIos = Device.osName && ["iOS", "iPadOS", "Mac OS"].includes(Device.osName);
export const isIosApp = Platform.OS === "ios";
/**
 * Whether or not you're on an Android device; does not indicate app vs web
 */
export const isAndroid = Device.osName === "Android";
export const isAndroidApp = Platform.OS === "android";

export const isTablet = Device.deviceType === Device.DeviceType.TABLET;
export const isPhone = Device.deviceType === Device.DeviceType.PHONE;
export const isDesktop = Device.deviceType === Device.DeviceType.DESKTOP;
/**
 * This will always return false for web
 */
export const isSimulator = !Device.isDevice;
