import { ImagePickerAsset, ImagePickerOptions, MediaTypeOptions, launchImageLibraryAsync } from "expo-image-picker";

enum ImageErrorCode {
  cancelled = "cancelled",
}

interface ImageResultError {
  error: true;
  code: ImageErrorCode;
}

interface ImageResultSuccess {
  error: false;
  asset: ImagePickerAsset;
}

interface ImageResultsSuccess {
  error: false;
  assets: ImagePickerAsset[];
}

export const ImageAspectRatio: Record<string, [number, number]> = {
  square: [1, 1],
  television: [4, 3],
  phone: [5, 3],
};

type ImageResult = ImageResultSuccess | ImageResultError;
type ImagesResult = ImageResultsSuccess | ImageResultError;

const defaultImageValues: ImagePickerOptions = {
  allowsEditing: true,
  aspect: ImageAspectRatio.square,
  quality: 0.8,
  base64: true,
  mediaTypes: MediaTypeOptions.Images,
};

export async function pickImage(options?: ImagePickerOptions): Promise<ImageResult> {
  const result = await launchImageLibraryAsync({
    selectionLimit: 1,
    allowsMultipleSelection: false,
    ...defaultImageValues,
    ...options,
  });

  if (result.canceled || !result.assets[0]) {
    return { error: true, code: ImageErrorCode.cancelled };
  }

  return { error: false, asset: result.assets[0] };
}

export async function pickImages(options?: ImagePickerOptions): Promise<ImagesResult> {
  const result = await launchImageLibraryAsync({
    ...defaultImageValues,
    selectionLimit: 0,
    allowsMultipleSelection: true,
    allowsEditing: false,
    orderedSelection: true,
    ...options,
  });

  if (result.canceled || !result.assets[0]) {
    return { error: true, code: ImageErrorCode.cancelled };
  }

  return { error: false, assets: result.assets };
}
