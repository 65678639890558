import React, { FC } from "react";

import { HeartStraight } from "phosphor-react-native";
import { IconButton, IconButtonProps } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useLikeCollabUpdate } from "@app/hooks/api/updates/use-like-collab-update.hook";
import { useUnlikeCollabUpdate } from "@app/hooks/api/updates/use-unlike-collab-update.hook";

interface Props extends Omit<IconButtonProps, "icon"> {
  collabUpdateId: string;
  liked?: boolean;
  icon?: IconSource;
  buttonComponent?: FC<{ onPress: () => void }>;
}

export const LikeUpdateButton: FC<Props> = ({
  collabUpdateId,
  liked = false,
  icon,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buttonComponent: ButtonComponent,
  ...props
}) => {
  const { profile, loading: profileLoading } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { openLogin } = useLoginContext();

  const { likeCollabUpdate, loading: likeLoading } = useLikeCollabUpdate();
  const { unlikeCollabUpdate, loading: dislikeLoading } = useUnlikeCollabUpdate();

  const loading = likeLoading || dislikeLoading || profileLoading;

  const handleLike = (): void => {
    if (!profile && !profileLoading) {
      openLogin();
      return;
    }

    if (!profile || loading) return;

    void likeCollabUpdate({
      variables: { userId: profile.userId, input: { collabUpdateId } },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  const handleDislike = (): void => {
    if (!profile || loading) return;

    void unlikeCollabUpdate({
      variables: { userId: profile.userId, input: { collabUpdateId } },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return ButtonComponent ? (
    <ButtonComponent onPress={liked ? handleDislike : handleLike} />
  ) : (
    <IconButton
      icon={icon ?? (iconProps => <HeartStraight {...iconProps} weight={props.mode === "contained" ? "fill" : "thin"} />)}
      disabled={loading}
      onPress={liked ? handleDislike : handleLike}
      {...props}
    />
  );
};
