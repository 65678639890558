/* eslint-disable complexity */
import React, { useState, useEffect, ReactNode, forwardRef, JSX, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { View, TouchableOpacity, TextInput as RNTextInput, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, HelperText, TextInput as RNPTextInput, TextInputProps as RNTextInputProps, useTheme } from "react-native-paper";

import { isWeb } from "@app/utils/device.util";

import { styles } from "./text-input.style";

export interface TextInputProps extends Omit<RNTextInputProps, "left"> {
  required?: boolean;
  error?: boolean;
  errorText?: string;
  loading?: boolean;
  helperText?: string | JSX.Element;
  noUnderline?: boolean;
  includeCharacterCount?: boolean;
  rightContent?: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  left?: (color: string) => ReactNode;
}

export const TextInput = forwardRef<RNTextInput, TextInputProps>(
  (
    {
      required = false,
      error,
      errorText,
      loading,
      helperText,
      includeCharacterCount,
      noUnderline,
      editable,
      underlineStyle,
      style,
      containerStyle,
      rightContent,
      right,
      left,
      onPressIn,
      onChangeText,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { colors, roundness } = useTheme();
    const lineHeight = 27;

    const [requiredError, setRequiredError] = useState(false);
    const [characterCount, setCharacterCount] = useState<number>(0);

    const hasError = error || requiredError;
    const textColor = hasError ? colors.error : props.disabled ? colors.action.active : colors.onBackground;

    const handleTextChange = (text: string): void => {
      setRequiredError(required && !text);
      if (onChangeText) onChangeText(text);
    };

    useEffect(() => {
      if (includeCharacterCount) setCharacterCount(props.value?.length ?? 0);
    }, [includeCharacterCount, props.value]);

    const rightItem = useMemo(() => {
      if (loading) return <RNPTextInput.Icon icon={() => <ActivityIndicator size="small" />} />;
      if (right) return <RNPTextInput.Icon icon={() => right} />;
      return undefined;
    }, [loading, right]);

    const heightStyle =
      props.numberOfLines && props.multiline && !isWeb
        ? { minHeight: lineHeight * props.numberOfLines, maxHeight: lineHeight * props.numberOfLines }
        : undefined;

    return (
      <View style={[styles.fullWidth, containerStyle]}>
        <View style={styles.textInputWrapper}>
          <TouchableOpacity style={styles.touchableWrapper} onPress={onPressIn} activeOpacity={onPressIn ? 0.2 : 1}>
            <View
              pointerEvents={onPressIn ? "none" : undefined}
              style={[styles.textInput, { backgroundColor: colors.surfaceVariant, borderRadius: roundness }]}>
              <RNPTextInput
                ref={ref}
                error={hasError}
                editable={!loading && editable}
                textColor={textColor}
                right={rightItem}
                left={left ? left(textColor) : undefined}
                style={[styles.textInput, heightStyle, style]}
                underlineStyle={[
                  noUnderline ? styles.noTextInputUnderline : { ...styles.textInputUnderline, backgroundColor: colors.outline },
                  underlineStyle,
                ]}
                onChangeText={handleTextChange}
                {...props}
              />
            </View>

            {includeCharacterCount && props.maxLength && !hasError && (
              <HelperText type="info" style={styles.characterCount}>
                {t("form.characterCount", { count: characterCount, total: props.maxLength })}
              </HelperText>
            )}
          </TouchableOpacity>
          {rightContent && rightContent}
        </View>

        {hasError ? (
          <HelperText type="error">{requiredError ? t("error.required") : errorText}</HelperText>
        ) : (
          !!helperText && (
            <HelperText type="info">
              {helperText}
              {required && "*"}
            </HelperText>
          )
        )}
      </View>
    );
  },
);

TextInput.displayName = "TextInput";
