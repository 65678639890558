import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  cartItemWrapper: {
    borderWidth: 1,
    flex: 1,
    padding: 12,
  },
  center: {
    flex: 1,
    justifyContent: "center",
  },
  listContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 20,
    paddingVertical: 8,
    rowGap: 16,
    width: "100%",
  },
  root: {
    flex: 1,
  },
});
