import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  bottomSheetContainer: {
    paddingHorizontal: 0,
  },
  slideFullScreen: {
    paddingBottom: isWeb ? 0 : 16,
  },
  slideWrapper: {
    paddingBottom: isWeb ? 0 : 16,
    paddingHorizontal: 20,
  },
});
