import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import { useAssets } from "expo-asset";

import { AnimatedSplashScreen, AnimatedSplashScreenProps } from "../animated-splash-screen/animated-splash-screen.component";

export const AnimatedAppLoader: FC<PropsWithChildren<AnimatedSplashScreenProps>> = ({
  children,
  image,
  isAppReady,
  splashScreenDismissed,
  setSplashScreenDismissed,
}) => {
  const [isSplashReady, setIsSplashReady] = useState(true);
  const [assets] = useAssets([image]);

  useEffect(() => {
    const prepare = async (): Promise<void> => {
      if (assets?.[0]) {
        await assets?.[0].downloadAsync();
      }
      setIsSplashReady(true);
    };

    void prepare();
  }, [assets, image]);

  if (!isSplashReady) {
    return null;
  }

  return (
    <AnimatedSplashScreen
      image={image}
      isAppReady={isAppReady}
      splashScreenDismissed={splashScreenDismissed}
      setSplashScreenDismissed={setSplashScreenDismissed}>
      {children}
    </AnimatedSplashScreen>
  );
};
