import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setFeaturedCreator: Profile;
}

interface Variables {
  userId: string;
}

interface SetFeaturedCreatorResult extends MutationResult<Response, "setFeaturedCreator"> {
  setFeaturedCreator: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const setFeaturedCreatorMutation = gql`
  mutation SetFeaturedCreator($userId: UserId!) {
    setFeaturedCreator(userId: $userId) {
      ... on Profile {
        userId
      }
    }
  }
`;

export function useSetFeaturedCreator(): SetFeaturedCreatorResult {
  const [setFeaturedCreator, { loading, error, data }] = useMutation<Response, Variables>(setFeaturedCreatorMutation);

  return { setFeaturedCreator, loading, error, data: data?.setFeaturedCreator };
}
