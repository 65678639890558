import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { ContestSubmissionCount } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  contestSubmissionCount: ContestSubmissionCount;
}

interface Variables {
  contestId: string;
  userId: string;
}

export const contestSubmissionCountQuery = gql`
  query ContestSubmissionCount($contestId: ContestId!, $userId: UserId!) {
    contestSubmissionCount(contestId: $contestId, userId: $userId) {
      contestId
      count
      userId
    }
  }
`;

export function useContestSubmissionCount(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "contestSubmissionCount"> {
  const { data, loading, error } = useQuery<Response, Variables>(contestSubmissionCountQuery, {
    skip: !options.variables?.contestId || !options.variables.userId,
    ...options,
  });

  return { data: data?.contestSubmissionCount, loading, error };
}
