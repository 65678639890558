import Constants from "expo-constants";
import { Platform, StatusBar, StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";
import { isAndroidApp } from "@app/utils/device.util";

export const headerHeight = 56 + (isAndroidApp && StatusBar.currentHeight ? StatusBar.currentHeight : Constants.statusBarHeight);

export const maxDesktopWidth = 630;

export const styles = StyleSheet.create({
  activeIndicatorStyle: {
    backgroundColor: transparent,
  },
  desktop: {
    maxWidth: maxDesktopWidth,
  },
  desktopWrapper: {
    alignItems: "center",
  },
  fullScreenOnTopOfEverything: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  header: {
    height: headerHeight,
  },
  headerLeft: {
    paddingLeft: Platform.OS === "web" ? 8 : 20,
  },
  headerRight: {
    paddingRight: 8,
  },
  imageContainer: {
    height: 312,
    width: 390,
  },
  rootViewStyle: {
    flex: 1,
    width: "100%",
  },
  rootWrapperStyle: {
    flex: 1,
  },
  studioButtonContainer: {
    alignItems: "center",
    height: 48,
    justifyContent: "center",
    top: -12,
    width: 48,
  },
  tabBarStyle: {
    borderTopWidth: 1,
    overflow: "hidden",
  },
  tabNavigator: {
    borderTopWidth: 1,
    display: "flex",
    overflow: "hidden",
  },
});
