import React, { JSX, useCallback, useEffect, useMemo, useRef } from "react";

import { SortAscending } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, TouchableOpacity } from "react-native";
import { IconButton, IconButtonProps, RadioButton, useTheme } from "react-native-paper";

import { SortingMethod } from "@app/common/types/sorting.type";
import { changeArrayOrder } from "@app/utils/change-array-order.util";

import { BottomSheet } from "../bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "../bottom-sheet/bottom-sheet.types";
import { Text } from "../text/text.component";

import { styles } from "./sort-button.style";

interface Props<TSortingMethod extends SortingMethod> extends Omit<IconButtonProps, "icon"> {
  sortingOptions: string[];
  excludedSort?: string[];
  sortingMethod?: TSortingMethod;
  setSortingMethod: (sort: TSortingMethod) => void;
}

export const SortButton = <TSortingMethod extends SortingMethod>({
  sortingOptions,
  excludedSort,
  sortingMethod,
  setSortingMethod,
  ...buttonProps
}: Props<TSortingMethod>): JSX.Element => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const handleSortingMethodPress = useCallback(
    (item: TSortingMethod): void => {
      setSortingMethod(item);
      sheetRef?.current?.close();
    },
    [setSortingMethod],
  );

  const renderRadioButton = useCallback(
    ({ item }: { item: string }) => (
      <TouchableOpacity onPress={() => handleSortingMethodPress(item as TSortingMethod)} activeOpacity={1} style={styles.radioButtonContainer}>
        <RadioButton.Android value={item} />
        <Text variant="body1">{t(`enum.sort.${item}`)}</Text>
      </TouchableOpacity>
    ),
    [handleSortingMethodPress, t],
  );

  const filteredOptions = useMemo(() => sortingOptions.filter(sort => !excludedSort?.includes(sort)), [sortingOptions, excludedSort]);

  useEffect(() => changeArrayOrder(filteredOptions, ["popular", "createdAtDesc", "createdAtAsc", "priceAsc", "priceDesc"]), [filteredOptions]);

  const { style: buttonStyle, ...iconButtonProps } = buttonProps;

  return (
    <>
      <IconButton
        onPress={handleOpenBottomSheet}
        icon={iconProps => <SortAscending {...iconProps} color={colors.action.active} weight="thin" />}
        style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, buttonStyle]}
        {...iconButtonProps}
      />

      <BottomSheet ref={sheetRef} title={t("sort.sortItemsBy")}>
        <RadioButton.Group onValueChange={newValue => handleSortingMethodPress(newValue as TSortingMethod)} value={sortingMethod as TSortingMethod}>
          <FlatList
            data={filteredOptions}
            renderItem={renderRadioButton}
            keyExtractor={item => `radio-button-sort-option-${item}`}
            scrollEnabled={false}
          />
        </RadioButton.Group>
      </BottomSheet>
    </>
  );
};
