import { StyleSheet } from "react-native";

export const iconSize = 40;

export const styles = StyleSheet.create({
  horizontalDivider: {
    height: 1,
    marginTop: iconSize / 2,
    width: 46,
  },
  item: {
    width: iconSize,
  },
  verticalDivider: {
    height: 16,
    width: 1,
  },
  wrapperColumn: {
    alignItems: "center",
  },
  wrapperRow: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
});
