import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    marginVertical: 16,
    rowGap: 12,
  },
  row: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
});
