import React, { FC } from "react";

import { MegaphoneSimple } from "phosphor-react-native";
import { useTheme } from "react-native-paper";

import { productStackMargin } from "@app/common/constants/products.const";
import { AnyPromo } from "@app/common/graphql/generated/schema.graphql";
import { GenericCard } from "@app/components/common/generic-card/generic-card.component";
import { usePromoProps } from "@app/hooks/utils/use-promo-props.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

interface Props {
  promo: AnyPromo;
  feedIndex: number;
  onBack: () => void;
  onClose: () => void;
  preview?: boolean;
}

export const PromoCard: FC<Props> = ({ promo, feedIndex, onBack, onClose, preview }) => {
  const { colors } = useTheme();
  const { width: windowWidth } = useWindowDimensions();

  const promoProps = usePromoProps(promo, preview);

  return (
    <GenericCard
      containerStyle={{ backgroundColor: colors.tertiaryContainer }}
      backButton={{ onPress: onBack, disabled: feedIndex === 0 }}
      leftIcon={<MegaphoneSimple color={colors.onBackground} weight="fill" mirrored />}
      onClose={onClose}
      width={windowWidth - 2 * productStackMargin}
      {...promoProps}
    />
  );
};
