import { FetchResult, MutationFunctionOptions, gql, useMutation } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteProfileImage: Profile;
}

interface Variables {
  userId: string;
}

interface DeleteProfileImageResult extends MutationResult<Response, "deleteProfileImage"> {
  deleteProfileImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteProfileImageQuery = gql`
  mutation DeleteProfileImage($userId: UserId!) {
    deleteProfileImage(userId: $userId) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useDeleteProfileImage(): DeleteProfileImageResult {
  const [deleteProfileImage, { loading, error, data }] = useMutation<Response, Variables>(deleteProfileImageQuery);

  return { deleteProfileImage, data: data?.deleteProfileImage, loading, error };
}
