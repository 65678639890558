import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { progressFields } from "@app/common/graphql/fragments.graphql";
import { QuoteRequest } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  requestQuote: QuoteRequest;
}

interface Variables {
  collabId: string;
}

interface RequestQuoteForCollabResult extends MutationResult<Response, "requestQuote"> {
  requestQuoteForCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const requestQuoteForCollabMutation = gql`
  mutation RequestQuote($collabId: CollabId!) {
    requestQuote(collabId: $collabId) {
      collab {
        collabId
        handle
        progress {
          ...ProgressFields
        }
      }
      quoteRequested
    }
  }
  ${progressFields}
`;

export function useRequestQuoteForCollab(): RequestQuoteForCollabResult {
  const [requestQuoteForCollab, { loading, error, data }] = useMutation<Response, Variables>(requestQuoteForCollabMutation);

  return { requestQuoteForCollab, loading, error, data: data?.requestQuote };
}
