import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    bottom: 8,
    justifyContent: "flex-end",
    position: "absolute",
    right: 8,
  },
});
