import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  radioButtonContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    padding: 8,
  },
});
