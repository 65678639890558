import { StyleSheet } from "react-native";

const rowGap = 16;
export const paddingVertical = 16;

export const styles = StyleSheet.create({
  arrowDownContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  feedInfo: {
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  feedInfoContent: {
    alignItems: "flex-start",
  },
  form: {
    flex: 1,
    rowGap,
  },
  inputSeparator: {
    height: rowGap,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical,
    rowGap,
  },
  stageOption: {
    padding: 8,
  },
});
