import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { DismissableEmbeddedBanner } from "@app/components/common/dismissable-embedded-banner/dismissable-embedded-banner.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";

export const SupercoinsBanner: FC = () => {
  const { t } = useTranslation();

  const { state: userState, updateState } = useProfileContext();

  const handleDismiss = (): void => {
    updateState({ input: { [UserStateEnum.supercoinsBannerDismissed]: true } });
  };

  return (
    <DismissableEmbeddedBanner
      message={t("supercoins.banner")}
      color="primary"
      alreadyDismissed={userState?.[UserStateEnum.supercoinsBannerDismissed]}
      onDismiss={handleDismiss}
    />
  );
};
