import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  divider: {
    marginHorizontal: 20,
  },
  versionContainer: {
    marginHorizontal: 20,
    marginVertical: 16,
  },
});
