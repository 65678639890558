import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";

import Constants from "expo-constants";
import * as SplashScreen from "expo-splash-screen";
import { View, StyleSheet, ImageRequireSource } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import Animated, { FadeIn, runOnJS, useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";

import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./animated-splash-screen.style";

export interface AnimatedSplashScreenProps {
  isAppReady: boolean;
  image: ImageRequireSource;
  splashScreenDismissed: boolean;
  setSplashScreenDismissed: (splashScreenDismissed: boolean) => void;
}

export const AnimatedSplashScreen: FC<PropsWithChildren<AnimatedSplashScreenProps>> = ({
  children,
  image,
  isAppReady,
  splashScreenDismissed,
  setSplashScreenDismissed,
}) => {
  const { width: wWidth } = useWindowDimensions();
  const { colors } = useTheme();

  const opacity = useSharedValue(1);

  const logoWidth = wWidth * 0.5;
  const logoHeight = logoWidth / 3;

  const [isSplashAnimationComplete, setIsSplashAnimationComplete] = useState(false);
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoadingVisible(true), 1100);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (isAppReady) {
      opacity.value = withDelay(
        500,
        withTiming(0, { duration: 300 }, finished => {
          if (finished) {
            runOnJS(setIsSplashAnimationComplete)(true);
            runOnJS(setSplashScreenDismissed)(true);
          }
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppReady]);

  const onImageLoaded = useCallback(async () => {
    await SplashScreen.hideAsync();
  }, []);

  const animatedBackgroundStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  return (
    <View style={styles.root}>
      {isAppReady && children}
      {!isSplashAnimationComplete && !isWeb && !splashScreenDismissed && (
        <Animated.View
          pointerEvents="none"
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: Constants.expoConfig?.splash?.backgroundColor,
            },
            animatedBackgroundStyle,
          ]}>
          <Animated.Image
            style={[
              styles.splashImage,
              {
                resizeMode: Constants.expoConfig?.splash?.resizeMode || "contain",
              },
            ]}
            source={image}
            onLoadEnd={() => void onImageLoaded()}
            fadeDuration={0}
          />

          <View style={[StyleSheet.absoluteFill, styles.logoAndLoadingContainer]}>
            <Animated.View entering={FadeIn.duration(250)}>
              <OffscriptLogo width={logoWidth} height={logoHeight} color={colors.common.white} />
            </Animated.View>

            {isLoadingVisible && (
              <View style={[styles.loadingContainer, { paddingTop: logoWidth }]}>
                <ActivityIndicator color={colors.common.white} />
              </View>
            )}
          </View>
        </Animated.View>
      )}
    </View>
  );
};
