/* eslint-disable complexity */
import React, { FC, ReactNode, JSX } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { ArrowUDownLeft, IconProps, X } from "phosphor-react-native";
import { StyleProp, View, ViewStyle } from "react-native";
import { IconButton, Theme, useTheme } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { Box } from "@app/components/common/box/box.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { getRgbaValue } from "@app/utils/gradient.util";

import { styles } from "./generic-card.style";

export interface GenericCardProps {
  containerStyle?: StyleProp<ViewStyle>;
  content?: string | JSX.Element;
  cta: JSX.Element;
  secondaryCta?: JSX.Element;
  image: string | ((props: IconProps) => ReactNode);
  imageMaxHeight?: number;
  background?: FC<{ style: StyleProp<ViewStyle> }>;
  leftIcon?: JSX.Element;
  backButton?: {
    icon?: IconSource;
    onPress: () => void;
    disabled?: boolean;
  };
  onClose?: () => void;
  subtitle?: string;
  title?: string;
  width?: number;
  height?: number;
  theme?: Theme;
}

export const GenericCard: FC<GenericCardProps> = ({
  containerStyle,
  content,
  cta,
  secondaryCta,
  image,
  imageMaxHeight,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  background: BackgroundComponent,
  leftIcon,
  backButton,
  onClose,
  subtitle,
  title,
  width,
  height,
  theme,
}) => {
  const { colors: currentColors, dark, roundness } = useTheme();
  const { cardHeight } = useFeedEntityCardHeight();
  const { width: windowWidth } = useWindowDimensions();

  width ??= windowWidth;
  height ??= cardHeight;
  const colors = theme?.colors ?? currentColors;

  const defaultBackground = (
    dark ? require("@app/assets/images/default-background-dark.png") : require("@app/assets/images/default-background-light.png")
  ) as number;
  const colorValue = dark ? 29 : 247;
  const gradient = [getRgbaValue(colorValue, 1), getRgbaValue(colorValue, 0), getRgbaValue(colorValue, 0), getRgbaValue(colorValue, 1)];

  const isUsingIconWithDefaultBackground = typeof image === "function";
  const centerStyleValue = isUsingIconWithDefaultBackground ? "center" : undefined;

  return (
    <Box height={height} rowGap={24} padding={12} width={width} overflow="hidden" style={containerStyle}>
      <Box flex={1} rowGap={24} zIndex={2} alignItems={centerStyleValue} justifyContent={isUsingIconWithDefaultBackground ? "center" : "flex-end"}>
        {isUsingIconWithDefaultBackground && <View>{image({ weight: "fill", color: colors.onBackground, size: 64 })}</View>}
        {title && (
          <Text variant="h3" textAlign={centerStyleValue} theme={theme}>
            {title}
          </Text>
        )}
        <Box rowGap={8} alignItems={centerStyleValue}>
          {subtitle && (
            <Text variant="h5" textAlign={centerStyleValue} theme={theme}>
              {subtitle}
            </Text>
          )}
          {typeof content === "string" ? (
            <Text variant="body2" textAlign={centerStyleValue} theme={theme}>
              {content}
            </Text>
          ) : (
            content
          )}
        </Box>
      </Box>
      <Box rowGap={8} zIndex={2}>
        {!!secondaryCta && cta}
        <Box flexDirection="row" columnGap={8} width="100%">
          {backButton && (
            <IconButton
              mode="contained"
              icon={backButton.icon ?? (({ color }) => <ArrowUDownLeft color={color} weight="thin" />)}
              size={32}
              disabled={backButton.disabled}
              onPress={backButton.onPress}
              style={[styles.iconButton, { borderRadius: roundness }]}
            />
          )}
          {(cta || secondaryCta) && <Box flex={1}>{secondaryCta ?? cta}</Box>}
        </Box>
      </Box>

      {isUsingIconWithDefaultBackground ? (
        BackgroundComponent ? (
          <BackgroundComponent style={[styles.image, { width, height }]} />
        ) : (
          <Image source={defaultBackground} width={width} style={[styles.image, { width, height }]} />
        )
      ) : (
        <>
          <Image
            source={image}
            width={width}
            height={width}
            style={[styles.image, { backgroundColor: colors.skeleton, width, height: width, maxHeight: imageMaxHeight }]}
          />
          <LinearGradient locations={[0, 0.33, 0.66, 1]} colors={gradient} style={[styles.image, { height: width, width }]} />
        </>
      )}

      {leftIcon && <View style={styles.leftIcon}>{leftIcon}</View>}
      {onClose && (
        <View style={styles.closeIcon}>
          <IconButton icon={() => <X color={colors.onBackground} />} onPress={onClose} />
        </View>
      )}
    </Box>
  );
};
