import React, { FC, useState } from "react";

import { ArrowCircleUp } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View, Keyboard } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { BottomSheetTextInput } from "@app/components/common/bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { Button } from "@app/components/common/button/button.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCreateComment } from "@app/hooks/api/comments/use-create-comment.hook";
import { useCreateCollabUpdateComment } from "@app/hooks/api/updates/use-create-collab-update-comment.hook";
import { isWeb } from "@app/utils/device.util";
import { isCollabId } from "@app/utils/id.util";

import { styles } from "./comment-input.style";

interface Props {
  parentId: string;
  openLogin: () => void;
  closeBottomSheet?: () => void;
}

export const CommentInput: FC<Props> = ({ parentId, openLogin, closeBottomSheet }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [newComment, setNewComment] = useState("");
  const { showErrorSnackbar } = useSnackbarContext();
  const { profile } = useProfileContext();

  const { createComment, loading: createCommentLoading } = useCreateComment();
  const { createCollabUpdateComment, loading: createCollabUpdateCommentLoading } = useCreateCollabUpdateComment();

  const isCollabComments = isCollabId(parentId);
  const loading = isCollabComments ? createCommentLoading : createCollabUpdateCommentLoading;
  const formattedNewComment = newComment.trim();

  const handleOnPostComment = (): void => {
    if (!profile?.userId || !formattedNewComment) return;

    Keyboard.dismiss();

    if (isCollabComments) {
      void createComment({
        variables: { collabId: parentId, userId: profile?.userId, input: { content: formattedNewComment } },
        onCompleted: () => setNewComment(""),
        onError: error => showErrorSnackbar({ error }),
      });
    } else {
      void createCollabUpdateComment({
        variables: { collabUpdateId: parentId, userId: profile?.userId, input: { content: formattedNewComment } },
        onCompleted: () => setNewComment(""),
        onError: error => showErrorSnackbar({ error }),
      });
    }
  };

  const handleOnOpenLogin = (): void => {
    if (isWeb) {
      closeBottomSheet?.();
    }
    openLogin();
  };

  return (
    <View style={styles.root}>
      {profile ? (
        <>
          <AvatarImage image={profile.imageUrl} size={40} fallbackText={profile.username.charAt(0)} />
          <BottomSheetTextInput
            multiline
            value={newComment}
            placeholder={t("comments.placeholder")}
            noUnderline
            onChangeText={setNewComment}
            contentStyle={styles.inputContent}
            containerStyle={styles.inputContainer}
            enablesReturnKeyAutomatically
            returnKeyType="send"
            blurOnSubmit
            onSubmitEditing={handleOnPostComment}
            inputMode="text"
            loading={loading}
            right={
              !!formattedNewComment && (
                <IconButton
                  icon={() => <ArrowCircleUp weight="fill" color={colors.secondary} />}
                  onPress={handleOnPostComment}
                  style={styles.buttonAccessory}
                />
              )
            }
          />
        </>
      ) : (
        <Button mode="contained" size="large" fullWidth onPress={handleOnOpenLogin}>
          {t("comments.signIn")}
        </Button>
      )}
    </View>
  );
};
