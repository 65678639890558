import { StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";

export const styles = StyleSheet.create({
  loadingContainer: {
    position: "absolute",
  },
  logoAndLoadingContainer: {
    alignItems: "center",
    backgroundColor: transparent,
    flex: 1,
    justifyContent: "center",
  },
  root: {
    flex: 1,
  },
  splashImage: {
    height: "100%",
    width: "100%",
  },
});
