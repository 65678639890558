import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  startCampaign: Collab;
}

interface Variables {
  collabId: string;
}

interface StartCampaignResult extends MutationResult<Response, "startCampaign"> {
  startCampaign: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startCampaignMutation = gql`
  mutation StartCampaign($collabId: CollabId!) {
    startCampaign(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useStartCampaign(): StartCampaignResult {
  const [startCampaign, { loading, error, data }] = useMutation<Response, Variables>(startCampaignMutation);

  return { startCampaign, loading, error, data: data?.startCampaign };
}
