/* eslint-disable @typescript-eslint/naming-convention */
interface UserLocation {
  ip: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
  hostname: string;
}

export const getUserLocation = (): Promise<UserLocation> => {
  return fetch("https://ipapi.co/json/").then(async res => {
    if (res.ok) {
      return (await res.json()) as UserLocation;
    }
    throw new Error(res.statusText);
  });
};
