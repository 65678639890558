import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  image: {
    height: "100%",
    width: "100%",
  },
  imageIcon: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  loadingContainer: {
    alignSelf: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: -1,
  },
  root: {
    alignItems: "center",
    flex: 1,
  },
});
