import React from "react";

import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ContestList } from "@app/components/contests/contest-list/contest-list.component";

export const ActiveContestsScreen: React.FC<NavigationProps<Routes.activeContests>> = () => {
  return (
    <ScreenWrapper>
      <ContestList status={ContestStatus.inProgress} />
    </ScreenWrapper>
  );
};
