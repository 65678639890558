import React, { FC, useCallback, useMemo } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useCart } from "@app/hooks/api/use-cart.hook";

import { useLocalStorageContext } from "../local-storage/local-storage.context";
import { useProfileContext } from "../profile/profile.context";
import { useSnackbarContext } from "../snackbar/snackbar.context";

import { CartContext, CartContextType } from "./cart.context";

export const CartContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { showErrorSnackbar } = useSnackbarContext();
  const { profile, loading: loadingProfile } = useProfileContext();
  const { anonymousCartId, loading: loadingAnonymousCartId } = useLocalStorageContext();

  const cartIdentity = useMemo(
    () => (profile?.userId ? { userId: profile.userId } : anonymousCartId ? { anonymousCartId } : undefined),
    [anonymousCartId, profile?.userId],
  );

  const {
    data: cart,
    loading: loadingCart,
    refetch,
  } = useCart({
    variables: cartIdentity,
    onError: error => showErrorSnackbar({ refetch, error }),
  });

  const loadingCartIdentity = loadingProfile || loadingAnonymousCartId;
  const loading = loadingCart || loadingCartIdentity;

  const handleRefetch = useCallback(async (): Promise<void> => {
    const newCartIdentity = cartIdentity?.userId ? { userId: cartIdentity.userId } : anonymousCartId ? { anonymousCartId } : undefined;

    if (!newCartIdentity) return;

    await refetch?.(newCartIdentity);
  }, [anonymousCartId, cartIdentity, refetch]);

  const context = useMemo<CartContextType>(
    () => ({
      cartItems: cart?.items,
      cartIdentity,
      loading,
      loadingCartIdentity,
      refetch: handleRefetch,
    }),
    [cart?.items, cartIdentity, handleRefetch, loading, loadingCartIdentity],
  );

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>;
};
