import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  radioButtonContainer: {
    padding: 8,
  },
  root: {
    flex: 1,
    width: "100%",
  },
  row: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
    width: "100%",
  },
  underlineVariantPickerInput: {
    marginHorizontal: 0,
  },
  variantPicker: {
    paddingBottom: 16,
    rowGap: 16,
  },
  variantPickerInput: {
    height: 32,
    paddingHorizontal: 0,
  },
});
