import React, { FC, useState } from "react";

import { Trans } from "react-i18next";

import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { formatPriceUSD } from "@app/utils/price.util";

import { PickVariantBanner } from "../pick-variant-banner/pick-variant-banner.component";

interface Props extends Omit<ButtonProps, "children"> {
  collabId: string;
  variantId?: string;
  inventoryQuantity?: number;
  price?: number;
  quantity: number;
  allVariantsSoldOut?: boolean;
  onPressStart?: () => void;
}

export const ReserveButton: FC<Props> = ({
  collabId,
  variantId,
  inventoryQuantity,
  price,
  quantity,
  allVariantsSoldOut,
  loading,
  disabled,
  onPressStart,
  ...props
}) => {
  const navigation = useNavigation();

  const [pickVariantBannerVisible, setPickVariantBannerVisible] = useState(false);

  const soldOut = (!loading && !!variantId && inventoryQuantity !== undefined && inventoryQuantity <= 0) || allVariantsSoldOut;
  const isDisabled = soldOut || (inventoryQuantity !== undefined && quantity > 0 && quantity > inventoryQuantity) || disabled;

  const handleReserve = (): void => {
    if (!variantId || !quantity) {
      setPickVariantBannerVisible(true);
      return;
    }

    onPressStart?.();
    navigation.navigate(Routes.preorderStack, { screen: Routes.preorder, params: { collabId, variantId, quantity: quantity.toString() } });
  };

  return (
    <>
      <Button mode="contained" size="large" fullWidth loading={loading} disabled={isDisabled} onPress={handleReserve} {...props}>
        {soldOut ? (
          <Trans i18nKey="cart.soldOut" />
        ) : (
          <Trans i18nKey="cta.confirmWithPrice" values={{ price: price != null ? formatPriceUSD(price * quantity) : undefined }} />
        )}
      </Button>

      <PickVariantBanner bannerVisible={pickVariantBannerVisible} setBannerVisible={setPickVariantBannerVisible} />
    </>
  );
};
