import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { usePreorderContext } from "@app/context/preorder/preorder.context";

import { styles } from "./preorder-bottom-action-button.style";

interface Props {
  shareButtonComponent: FC<Pick<ButtonProps, "containerStyle" | "mode">>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PreorderBottomActionButton: FC<Props> = ({ shareButtonComponent: ShareCollabButton }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { openLogin } = useLoginContext();

  const {
    input: { shippingAddress, paymentMethodId, email },
    isAnonymous,
    loadingAdditionalCharges,
    shippingRate,
    preorderCompleted,
    loadingPreorder,
    preorder,
  } = usePreorderContext();

  if (preorderCompleted) {
    if (isAnonymous)
      return (
        <View style={[{ backgroundColor: colors.tertiaryContainer }, styles.buttonContainer]}>
          <Text variant="h8" textAlign="center">
            {t("preorder.signInToSeeOrders")}
          </Text>
          <Button mode="contained" size="large" fullWidth onPress={openLogin}>
            {t("cta.signIn")}
          </Button>
        </View>
      );

    return <ShareCollabButton mode="contained" containerStyle={[{ backgroundColor: colors.tertiaryContainer }, styles.buttonContainer]} />;
  }

  return (
    <Button
      mode="contained"
      size="large"
      disabled={!shippingAddress || !shippingRate || loadingAdditionalCharges || !paymentMethodId || loadingPreorder || (isAnonymous && !email)}
      containerStyle={[{ backgroundColor: colors.tertiaryContainer }, styles.buttonContainer]}
      onPress={preorder}>
      {t("cta.reserve")}
    </Button>
  );
};
