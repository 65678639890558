import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
  },
  orderItemWrapper: {
    borderWidth: 1,
    flex: 1,
    padding: 12,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
  rootWrapper: {
    flex: 1,
  },
  rowGap: {
    height: 16,
  },
});
