import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bottomContainer: {
    paddingBottom: 8,
    paddingHorizontal: 20,
    rowGap: 8,
  },
  contentContainer: {
    rowGap: 8,
  },
  headerContainer: {
    alignItems: "center",
    rowGap: 24,
  },
  input: {
    width: "100%",
  },
  root: {
    justifyContent: "center",
    padding: 20,
  },
});
