import { LazyQueryExecFunction, QueryHookOptions, gql, useLazyQuery, useQuery } from "@apollo/client";

import { contestFields } from "@app/common/graphql/fragments.graphql";
import { Contest } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  contest: Contest;
}

interface ContestIdVariable {
  contestId?: string;
}

interface HandleVariable {
  handle: string;
}

type Variables = ContestIdVariable | HandleVariable;
interface LazyResult extends QueryResult<Response, "contest"> {
  getContest: LazyQueryExecFunction<Response, Variables>;
}

/**
 * This query is also used to query contest for the thumbnail preview. Update it accordingly:
 * server/services/contest.service.js
 */
const contestQuery = gql`
  query Contest($contestId: ContestId, $handle: String) {
    contest(contestId: $contestId, handle: $handle) {
      ...ContestFields
    }
  }
  ${contestFields}
`;

export function useContest(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "contest"> {
  const variables = options.variables;
  const { data, loading, error, refetch } = useQuery<Response, Variables>(contestQuery, {
    skip: !variables || ("contestId" in variables && !variables.contestId) || ("handle" in variables && !variables.handle),
    ...options,
  });

  return { data: data?.contest, loading, error, refetch };
}

export function useLazyContest(): LazyResult {
  const [getContest, { loading, error, data }] = useLazyQuery<Response, Variables>(contestQuery);

  return { getContest, loading, error, data: data?.contest };
}
