import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  dialog: {
    bottom: 10,
    marginHorizontal: 8,
    top: -12,
  },
  listItem: {
    paddingLeft: 8,
    paddingVertical: 8,
  },
  menu: {
    marginTop: -64,
  },
});
