import { gql, LazyQueryExecFunction, useLazyQuery } from "@apollo/client";

import { GeneratedDescriptionFilteringArgs, GeneratedDescription } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  generatedDescription: GeneratedDescription | undefined;
}

interface Variables {
  userId: string;
  where: GeneratedDescriptionFilteringArgs;
}

interface GeneratedDescriptionResult extends QueryResult<Response, "generatedDescription"> {
  getGeneratedDescription: LazyQueryExecFunction<Response, Variables>;
}

const generatedDescriptionQuery = gql`
  query GeneratedDescription($userId: UserId!, $where: GeneratedDescriptionFilteringArgs!) {
    generatedDescription(userId: $userId, where: $where) {
      description
    }
  }
`;

export function useGeneratedDescription(): GeneratedDescriptionResult {
  const [getGeneratedDescription, { loading, error, data, startPolling, stopPolling }] = useLazyQuery<Response, Variables>(
    generatedDescriptionQuery,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return { getGeneratedDescription, loading, error, data: data?.generatedDescription, startPolling, stopPolling };
}
