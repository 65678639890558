import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  icon: {
    borderRadius: 16,
    height: 40,
    margin: 0,
    width: 44,
  },
  iconWithCountContainer: {
    alignItems: "center",
  },
  root: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 8,
  },
});
