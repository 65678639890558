import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  bottomSheetContainer: {
    marginBottom: 16,
  },
  content: {
    justifyContent: "center",
    marginTop: 8,
    rowGap: 8,
  },
  header: {
    height: 20,
    marginTop: isWeb ? 16 : undefined,
  },
  headlineContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  leftHeader: {
    marginLeft: 8,
  },
  superplanAlertWrapper: {
    marginTop: 8,
  },
});
