import React, { FC } from "react";

import { MotiSkeletonProps } from "moti/build/skeleton/types";
import { Skeleton as MotiSkeleton } from "moti/skeleton";
import { useTheme } from "react-native-paper";

export type SkeletonProps = MotiSkeletonProps;

export const Skeleton: FC<Omit<MotiSkeletonProps, "Gradient">> = props => {
  const { dark } = useTheme();

  return <MotiSkeleton colorMode={dark ? "dark" : "light"} {...props} />;
};
