import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { commentFields, coreCollabFields, coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile, ReportInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  report: Profile;
}

interface Variables {
  entityId: string;
  input?: ReportInput;
}

interface ReportResult extends MutationResult<Response, "report"> {
  report: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const reportMutation = gql`
  mutation Report($entityId: ReportableEntityId!, $input: ReportInput) {
    report(entityId: $entityId, input: $input) {
      ... on Collab {
        ...CoreCollabFields
      }
      ... on Profile {
        ...CoreProfileFields
      }
      ... on Comment {
        ...CommentFields
      }
    }
  }
  ${commentFields}
  ${coreCollabFields}
  ${coreProfileFields}
`;

export function useReport(): ReportResult {
  const [report, { loading, error, data }] = useMutation<Response, Variables>(reportMutation);

  return { report, loading, error, data: data?.report };
}
