import React, { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { FlatList, ScrollView, View } from "react-native";
import { useTheme } from "react-native-paper";

import SuperplanBadge from "@app/assets/icons/superplan-badge.svg";
import { links } from "@app/common/constants/links.const";
import { supersellerFutureMonthlyPrice } from "@app/common/constants/subscription.const";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { OpenStoreButtons } from "@app/components/common/open-store-buttons/open-store-buttons.component";
import { Text } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useInAppPurchasesContext } from "@app/context/in-app-purchases/in-app-purchases.context";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { isWeb } from "@app/utils/device.util";
import { formatPriceUSD } from "@app/utils/price.util";

import { SupercreatorSubscribeButton } from "../supercreator-subscribe-button/supercreator-subscribe-button.component";
import { SupersellerSubscribeButton } from "../superseller-subscribe-button/superseller-subscribe-button.component";

import { styles } from "./superplan-details.style";

interface Props {
  superplanType: SuperplanType;
}

export const SuperplanDetails: FC<Props> = ({ superplanType }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { navigateToWebview } = useNavigateToWebview();

  const { tokenCosts } = useConfigContext();
  const { loading: loadingInAppPurchase, supersellerPrice, supercreatorPrice } = useInAppPurchasesContext();

  const isSuperseller = superplanType === SuperplanType.superseller;
  const formattedPrice = isSuperseller ? supersellerPrice : `${supercreatorPrice}/mo`;

  return (
    <View style={styles.root}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.contentContainer}>
        <View style={styles.header}>
          <UserBadge type={superplanType} size={40} />
          <Text variant="title" textAlign="center">
            {t(`${superplanType}.label`)}
          </Text>
          <Text variant="body1" color="tertiary" textAlign="center">
            {t(`${superplanType}.slogan`)}
          </Text>
        </View>

        {!isWeb && (
          <View style={styles.priceContainer}>
            <Text variant="h3" textAlign="center">{`${formattedPrice}${superplanType === SuperplanType.superseller ? "*" : ""}`}</Text>
          </View>
        )}

        <FlatList
          data={t(`${superplanType}.advantages`, { returnObjects: true, numberOfTokensPerDay: tokenCosts?.tokensPerDay })}
          renderItem={({ item }) => (
            <ListItem
              title={
                <Trans
                  parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
                  components={{
                    b: (
                      <Text variant="h8">
                        <></>
                      </Text>
                    ),
                  }}>
                  {item}
                </Trans>
              }
              left={<SuperplanBadge width={32} height={32} color={colors.onBackground} />}
            />
          )}
          scrollEnabled={isWeb}
          contentContainerStyle={styles.advantagesList}
        />

        {superplanType === SuperplanType.superseller && (
          <Trans
            i18nKey="superseller.disclaimer"
            parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
            components={{
              b: (
                <Text variant="h8">
                  <></>
                </Text>
              ),
            }}
            values={{ futureMonthlyPrice: formatPriceUSD(supersellerFutureMonthlyPrice), price: formattedPrice }}
          />
        )}
      </ScrollView>

      {!loadingInAppPurchase && (
        <View style={styles.buttonContainer}>
          {isWeb ? (
            <>
              <Text variant="body2" style={styles.textFooter}>
                {t("superplan.needToDownloadApp")}
              </Text>
              <OpenStoreButtons commonButtonProps={{ size: "large", containerStyle: styles.openStoreButton }} />
            </>
          ) : isSuperseller ? (
            <SupersellerSubscribeButton />
          ) : (
            <SupercreatorSubscribeButton />
          )}

          <Trans
            i18nKey="supercreator.termsAndPrivacy"
            parent={({ children }: ChildrenProp) => (
              <Text variant="caption" textAlign="center" color="tertiary" style={styles.textFooter}>
                {children}
              </Text>
            )}
            components={{
              tlink: (
                <Text variant="caption" onPress={() => navigateToWebview(links.external.termsOfUse)}>
                  <></>
                </Text>
              ),
              plink: (
                <Text variant="caption" onPress={() => navigateToWebview(links.external.privacyPolicy)}>
                  <></>
                </Text>
              ),
            }}
          />
        </View>
      )}
    </View>
  );
};
