import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { LikeFilteringArgs, LikeSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

import { CollabsResponse } from "../use-collabs.hook";

export interface LikesVariables {
  first?: number;
  offset?: number;
  userId: string;
  sortBy?: LikeSortingMethod;
  where?: LikeFilteringArgs;
}

const likesQuery = gql`
  query Likes($first: Int, $offset: Int, $sortBy: LikeSortingMethod, $userId: UserId!, $where: LikeFilteringArgs) {
    collabs: likes(first: $first, offset: $offset, sortBy: $sortBy, userId: $userId, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useLikes(options: QueryHookOptions<CollabsResponse, LikesVariables>): QueryResult<CollabsResponse, "collabs"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<CollabsResponse, LikesVariables>(likesQuery, {
    skip: !options.variables?.userId,
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.collabs, loading, error, networkStatus, fetchMore, refetch };
}
