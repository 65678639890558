import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  commentContentContainer: {
    alignItems: "flex-start",
  },
  likeCountRow: {
    alignItems: "center",
    flexDirection: "row",
  },
  root: {
    paddingVertical: 8,
    rowGap: 24,
  },
  rowSpaceBetween: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textContainer: {
    flex: 1,
  },
});
