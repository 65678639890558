import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  divider: {
    width: "100%",
  },
  lineItemsContainer: {
    width: "100%",
  },
  orderLineItemContainer: {
    marginBottom: 8,
    rowGap: 16,
  },
  root: {
    flex: 1,
    rowGap: 8,
  },
  statusRow: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    width: "100%",
  },
  viewOrderSummaryContainer: {
    paddingVertical: 8,
  },
});
