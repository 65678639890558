import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  chip: {
    borderRadius: 16,
    columnGap: 4,
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  pricesContainer: {
    columnGap: 4,
    flexDirection: "row",
  },
});
