import React, { JSX } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Filter, FilteringArgs } from "@app/common/types/filtering.type";
import { FilterButton } from "@app/components/common/filter-button/filter-button.component";
import { Text } from "@app/components/common/text/text.component";

import { styles } from "./profile-list-filter-button.style";

interface Props<TFilteringArgs extends FilteringArgs> {
  titleVisible?: boolean;
  filters: Filter[];
  filteringArgs?: TFilteringArgs;
  setFilteringArgs: (args?: TFilteringArgs) => void;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent?: JSX.Element;
}

export const ProfileListFilterButton = <TFilteringArgs extends FilteringArgs>({
  titleVisible,
  filters,
  filteringArgs,
  setFilteringArgs,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent,
}: Props<TFilteringArgs>): JSX.Element | null => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const filterButtonWithTitle = (
    <View style={[styles.header, { backgroundColor: colors.background }]}>
      <FilterButton
        filters={filters}
        filteringArgs={filteringArgs}
        setFilteringArgs={setFilteringArgs}
        title={t("filter.filterUsersBy")}
        style={styles.filterButtonContainer}
      />
      {titleVisible && (
        <Text variant="h8" textAlign="center">
          {t("browseOption.users.title")}
        </Text>
      )}
    </View>
  );

  return ListHeaderComponent ? (
    <View style={{ backgroundColor: colors.background }}>
      {ListHeaderComponent}
      {filterButtonWithTitle}
    </View>
  ) : (
    filterButtonWithTitle
  );
};
