import { registerRootComponent } from "expo";
import "setimmediate";

import App from "./App";

if (typeof window !== "undefined") {
  window._frameTimestamp = null;
}
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
