import React, { FC, useState, useCallback, useMemo } from "react";

import { useApolloClient } from "@apollo/client";
import {
  KeyboardAvoidingView,
  SafeAreaView as NativeSafeAreaView,
  Platform,
  RefreshControl,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { ActivityIndicator, Portal } from "react-native-paper";
import { Edge, SafeAreaView } from "react-native-safe-area-context";

import { useProfileContext } from "@app/context/profile/profile.context";
import { headerHeight } from "@app/router/router.style";
import { isObjectWithProperties } from "@app/utils/object.util";

import { styles } from "./screen-wrapper.style";

interface SharedProps extends ViewProps {
  safeAreaEdges?: Edge[];
  staticContent?: React.ReactNode;
  withScrollView?: boolean;
  withKeyboardAvoidingView?: boolean;
}

interface NoScrollingViewProps {
  scrollViewProps?: never;
  contentContainerStyle?: never;
  withKeyboardAvoidingView?: never;
  queryNamesToRefetchOnRefreshing?: never;
}

interface ScrollProps {
  withScrollView: true;
  scrollViewProps?: Omit<ScrollViewProps, "contentContainerStyle">;
  contentContainerStyle?: StyleProp<ViewStyle>;
  queryNamesToRefetchOnRefreshing?: string[];
}

type ConditionalProps = ScrollProps | NoScrollingViewProps;

export type ScreenWrapperProps = SharedProps & ConditionalProps;

export const ScreenWrapper: FC<ScreenWrapperProps> = ({
  children,
  withScrollView = false,
  withKeyboardAvoidingView = false,
  safeAreaEdges,
  style,
  staticContent,
  contentContainerStyle,
  queryNamesToRefetchOnRefreshing,
  ...props
}) => {
  const client = useApolloClient();
  const { loading: loadingProfile } = useProfileContext();
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    client
      .refetchQueries({
        include: "active",
        onQueryUpdated: observableQuery =>
          observableQuery.queryName &&
          !["UserState", "ProfileFromToken", "FrontendConfig"].includes(observableQuery.queryName) &&
          queryNamesToRefetchOnRefreshing?.includes(observableQuery.queryName),
      })
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  }, [client, queryNamesToRefetchOnRefreshing]);

  const content = useMemo(() => {
    return loadingProfile ? (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" />
      </View>
    ) : (
      children
    );
  }, [loadingProfile, children]);

  const rootPaddingVertical = isObjectWithProperties(contentContainerStyle, "paddingVertical")
    ? (contentContainerStyle.paddingVertical as number)
    : 0;

  const view =
    withScrollView === true ? (
      <ScrollView
        contentContainerStyle={[styles.scrollContent, contentContainerStyle]}
        showsVerticalScrollIndicator={false}
        refreshControl={queryNamesToRefetchOnRefreshing?.length ? <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} /> : undefined}
        {...props.scrollViewProps}>
        {content}
      </ScrollView>
    ) : (
      content
    );

  const contentContainer =
    withKeyboardAvoidingView === true ? (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={headerHeight + rootPaddingVertical}
        style={styles.root}>
        {view}
      </KeyboardAvoidingView>
    ) : (
      view
    );

  return (
    <Portal.Host>
      {safeAreaEdges ? (
        <SafeAreaView edges={safeAreaEdges} style={[styles.root, style]} {...props}>
          {contentContainer}
          {staticContent}
        </SafeAreaView>
      ) : (
        <NativeSafeAreaView style={[styles.root, style]} {...props}>
          {contentContainer}
          {staticContent}
        </NativeSafeAreaView>
      )}
    </Portal.Host>
  );
};
