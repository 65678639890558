import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { GeneratePosesInput, StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

import { imageGenerationCreditQuery } from "../use-image-generation-credits.hook";

interface Response {
  startGeneratingPoses: StudioTask;
}

interface Variables {
  input: GeneratePosesInput;
  userId: string;
}

interface StartGeneratingPosesResult extends MutationResult<Response, "startGeneratingPoses"> {
  startGeneratingPoses: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startGeneratingPosesMutation = gql`
  mutation StartGeneratingPoses($input: GeneratePosesInput!, $userId: UserId!) {
    startGeneratingPoses(input: $input, userId: $userId) {
      studioTaskId
    }
  }
`;

export function useStartGeneratingPoses(): StartGeneratingPosesResult {
  const [startGeneratingPoses, { loading, error, data, reset, client }] = useMutation<Response, Variables>(startGeneratingPosesMutation, {
    update: () => {
      void client.refetchQueries({ include: [imageGenerationCreditQuery] });
    },
  });

  return { startGeneratingPoses, loading, error, data: data?.startGeneratingPoses, reset };
}
