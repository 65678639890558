import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    rowGap: 16,
  },
});
