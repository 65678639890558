import React from "react";

import { Warning } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { isImageToBeUsed, MediaToBeUsed } from "@app/common/types/image.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { useUploadImage } from "../api/use-upload-image.hook";

interface UploadNewImageParams {
  media: MediaToBeUsed;
  userId?: string;
  setMedia: (callback: (prevImages: MediaToBeUsed[]) => MediaToBeUsed[]) => void;
}

interface UploadNewImageUtilities {
  uploadNewImage: (params: UploadNewImageParams) => Promise<void>;
  loading: boolean;
}

export function useUploadNewImage(): UploadNewImageUtilities {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const { uploadImage, loading } = useUploadImage();

  const uploadNewImage = async ({ media, userId, setMedia }: UploadNewImageParams): Promise<void> => {
    if (!userId) return;

    // TODO(kait): support if isVideoToBeUsed(media)

    if (isImageToBeUsed(media)) {
      const form = await generateImageToFormData(media.imageUrl, media.imageName);
      await uploadImage({
        variables: { input: form, userId },
        onCompleted: data => {
          if (!data.uploadImage) return;

          setMedia(prevImages => [...prevImages, { ...data.uploadImage, imageSource: "upload" }]);
        },
        onError: e => {
          if (e.message.includes("400")) {
            showErrorSnackbar({ message: t("error.invalidImageFileType"), icon: <Warning weight="thin" color={colors.onPrimary} size={24} /> });
          } else {
            showErrorSnackbar({ error: e });
          }
        },
      });
    }
  };

  return { loading, uploadNewImage };
}
