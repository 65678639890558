import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabUpdateFields } from "@app/common/graphql/fragments.graphql";
import { CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  collabUpdate: CollabUpdate;
}

interface Variables {
  collabUpdateId: string;
}

/**
 * This query is also used to query collab update for the thumbnail preview. Update it accordingly:
 * server/services/collab-update.service.js
 */
const collabUpdateQuery = gql`
  query CollabUpdate($collabUpdateId: CollabUpdateId!) {
    collabUpdate(collabUpdateId: $collabUpdateId) {
      ...CollabUpdateFields
    }
  }
  ${collabUpdateFields}
`;

export function useCollabUpdate(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "collabUpdate"> {
  const variables = options.variables;
  const { data, loading, error } = useQuery<Response, Variables>(collabUpdateQuery, {
    skip: !variables?.collabUpdateId,
    ...options,
  });

  return { data: data?.collabUpdate, loading, error };
}
