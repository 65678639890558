import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonIcon: {
    transform: [{ rotate: "90deg" }],
  },
  root: {
    marginTop: "auto",
    paddingHorizontal: 20,
    paddingVertical: 16,
    rowGap: 16,
  },
});
