import React, { FC } from "react";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioSelectImageSource } from "@app/components/studio-mini/studio-select-image-source/studio-select-image-source.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

export const SelectImageSourceScreen: FC<NavigationProps<Routes.studioMiniSelectImageSource>> = ({ navigation }) => {
  const { setInputMedia } = useStudioMiniContext();

  return (
    <ScreenWrapper>
      <StudioSelectImageSource
        allowMultipleSelection={false}
        setMedia={setInputMedia}
        onImageSelected={() => navigation.goBack()}
        onNavigateToSaved={() => navigation.navigate(Routes.studioMiniSaved, { selectionMode: "always" })}
        withHelperFooter
      />
    </ScreenWrapper>
  );
};
