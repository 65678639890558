import { isWeb } from "../device.util";

export { getShareUrl } from "./share.common.util";

export function shareToFacebookMessenger(_url: string): void {
  return;
}

export function shareToInstagramStory(_imageUrl: string, _imageId: string): void {
  return;
}

export function shareToInstagramPost(_imageUrl: string, _imageId: string): void {
  return;
}

export function getReferrerShareId(shouldSkip: boolean = false): string | null {
  if (!isWeb || shouldSkip) return null;
  return new URL(window.location.href).searchParams.get("utm_campaign") ?? null;
}

export function checkIfInstagramOnAndroidInstalled(setInstalled: (installed: boolean) => void): void {
  setInstalled(false);
}
