import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationMoveCollabToDevelopmentDeniedArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  moveCollabToDevelopmentDecision: Collab;
}

interface MoveCollabToDevelopmentDecisionResult extends MutationResult<Response, "moveCollabToDevelopmentDecision"> {
  moveCollabToDevelopmentDecision: (options?: MutationFunctionOptions<Response, MutationMoveCollabToDevelopmentDeniedArgs>) => Promise<FetchResult>;
}

const moveCollabToDevelopmentDecisionMutation = gql`
  mutation MoveCollabToDevelopmentDecision($collabId: CollabId!) {
    moveCollabToDevelopmentDecision(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useMoveCollabToDevelopmentDecision(): MoveCollabToDevelopmentDecisionResult {
  const [moveCollabToDevelopmentDecision, { loading, error, data }] = useMutation<Response, MutationMoveCollabToDevelopmentDeniedArgs>(
    moveCollabToDevelopmentDecisionMutation,
  );

  return { moveCollabToDevelopmentDecision, loading, error, data: data?.moveCollabToDevelopmentDecision };
}
