import React, { FC, useEffect } from "react";

import { Plus } from "phosphor-react-native";
import { IconButton } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { UpdateList } from "@app/components/updates/update-list/update-list.component";
import { useCollab } from "@app/hooks/api/products/use-collab.hook";

export const ProductUpdatesScreen: FC<NavigationProps<Routes.productUpdates>> = ({ navigation, route }) => {
  const { collabId } = route.params;

  const { data: collab } = useCollab({ variables: { collabId } });

  useEffect(() => {
    navigation.setOptions({
      headerRight: collab?.progress.canCreateCollabUpdate
        ? () => (
            <IconButton
              icon={Plus}
              onPress={() => navigation.navigate(Routes.productUpdateStack, { screen: Routes.createProductUpdate, params: { collabId } })}
            />
          )
        : undefined,
    });
  }, [collab?.progress.canCreateCollabUpdate, collabId, navigation]);

  return (
    <ScreenWrapper>
      <UpdateList collabId={collabId} canCreateCollabUpdate={collab?.progress.canCreateCollabUpdate} />
    </ScreenWrapper>
  );
};
