import React, { FC } from "react";

import { NODE_ENV, APP_ENV, GITHUB_SHA } from "@env";
import * as Application from "expo-application";
import * as Clipboard from "expo-clipboard";
import Constants from "expo-constants";
import { ClockCounterClockwise } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton, List, Switch, Text, useTheme } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { Alert } from "@app/components/common/alert/alert.component";
import { Button } from "@app/components/common/button/button.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { facebookConfig, firebaseConfig, googleConfig } from "@app/context/auth/firebase.common";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useProfileContext, userStateValues } from "@app/context/profile/profile.context";
import { UserState, UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUpdateUserState } from "@app/hooks/api/use-update-user-state.hook";
import { getAppScheme } from "@app/utils/app.util";

export const DevView: FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const { profile, state: userState, updateState } = useProfileContext();
  const { state } = useAuthContext();
  const localStorageContext = useLocalStorageContext();

  const { updateUserState } = useUpdateUserState();

  const isUserLogged = state.connected && !!profile;

  const handleCopy = (value: string) => (): void => {
    void Clipboard.setStringAsync(value ?? "")
      .then(() => showSuccessSnackbar())
      .catch((error: Error) => showErrorSnackbar({ error }));
  };

  const handleStateChange =
    (property: string) =>
    (value: boolean): void => {
      updateState({
        input: userStateValues.reduce<UserState>((acc, key) => {
          acc[key] = property === key ? value : userState ? userState[key] : undefined;
          return acc;
        }, {}),
      });
    };

  const resetState = (): void => {
    Alert.alert("Are you sure", "All you state (tutorial, poppers) will be reset", [
      { style: "cancel", text: "Cancel" },
      {
        style: "destructive",
        text: "Yes",
        onPress: () => {
          if (!profile?.userId) return;

          void updateUserState({
            variables: { input: { frontendState: {} }, userId: profile?.userId },
            onError: error => showErrorSnackbar({ error }),
          });
        },
      },
    ]);
  };

  return (
    <List.Section>
      <List.Subheader>
        <Text variant="labelLarge">Dev tools</Text>
      </List.Subheader>

      <List.AccordionGroup>
        {isUserLogged && (
          <List.Accordion title="Info" id="1">
            <List.Item title="Email" description={state.email ?? "undefined"} />
            <List.Item title="Name" description={profile?.displayName} />
            <List.Item title="Username" description={profile?.username} />
            <List.Item title="UserId" description={profile.userId} onPress={handleCopy(profile.userId)} />
            <List.Item title="Token" description={state.token} onPress={handleCopy(state.token)} />
          </List.Accordion>
        )}
        {isUserLogged && (
          <List.Accordion title="User State" id="2">
            <List.Item
              title="Reset userstate"
              description="This will reset your state to a new user state. It will not reset the localStorage values."
              right={() => <IconButton icon={ClockCounterClockwise} iconColor={colors.warning} onPress={resetState} />}
            />
            {userStateValues.map(key => (
              <List.Item
                key={key}
                title={key}
                right={() =>
                  key === UserStateEnum.userReviewAskedCount ? (
                    <Text>{userState?.[UserStateEnum.userReviewAskedCount] ?? 0}</Text>
                  ) : (
                    <Switch color={colors.tertiary} value={userState ? userState[key] : undefined} onValueChange={handleStateChange(key)} />
                  )
                }
              />
            ))}
          </List.Accordion>
        )}
        <List.Accordion title="Local Storage" id="3">
          {Object.values(LocalStorageKeys).map(key => (
            <List.Item
              key={key}
              title={key}
              description={localStorageContext[key] ? JSON.stringify(localStorageContext[key]) : "undefined"}
              descriptionNumberOfLines={0}
            />
          ))}
          <Button mode="outlined" color="error" onPress={localStorageContext.clearAllLocalStorageValues}>
            {t("godMode.clearLocalStorage")}
          </Button>
        </List.Accordion>
        <List.Accordion title="Env Vars" id="4">
          <List.Item title="NODE_ENV" description={NODE_ENV ?? "undefined"} />
          <List.Item title="APP_ENV" description={APP_ENV ?? "undefined"} />
          <List.Item title="App Slug" description={Constants.expoConfig?.slug} />
          <List.Item title="AppStore url" description={links.external.appStore} />
          <List.Item title="PlayStore url" description={links.external.playStore} />
          <List.Item title="App Scheme" description={getAppScheme()} />
          <List.Item title="Commit Hash" description={GITHUB_SHA ?? undefined} />
          <List.Item title="version" description={Constants.expoConfig?.version} />
          <List.Item title="nativeApplicationVersion" description={Application.nativeApplicationVersion} />
          <List.Item title="nativeBuildVersion" description={Application.nativeBuildVersion} />
        </List.Accordion>
        <List.Accordion title="firebase" id="5">
          <List.Item title="google iosClientId" description={googleConfig.iosClientId} />
          <List.Item title="google webClientId" description={googleConfig.webClientId} />
          <List.Item title="facebookConfig" description={facebookConfig.clientId} />
          <List.Item title="firebaseConfig apiKey" description={firebaseConfig.apiKey} />
          <List.Item title="firebaseConfig authDomain" description={firebaseConfig.authDomain} />
          <List.Item title="firebaseConfig appId" description={firebaseConfig.appId} />
        </List.Accordion>
      </List.AccordionGroup>
    </List.Section>
  );
};
