import "core-js/stable/atob";

import { jwtDecode } from "jwt-decode";

import { GrantedRole } from "@app/common/graphql/generated/schema.graphql";

export function isTokenEmailVerified(token: string): boolean {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return !!jwtDecode<{ email_verified?: boolean }>(token).email_verified;
}

export function getRolesFromToken(token: string): GrantedRole[] {
  const test = jwtDecode<{ roles: GrantedRole[] }>(token);
  return test.roles ?? [];
}
