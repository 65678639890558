import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { Checkout, CheckoutInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  buyNowCheckout: Checkout;
}

interface Variables {
  input: CheckoutInput;
}

interface BuyNowCheckoutResult extends MutationResult<Response, "buyNowCheckout"> {
  buyNow: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const buyNowCheckoutMutation = gql`
  mutation BuyNowCheckout($input: CheckoutInput!) {
    buyNowCheckout(input: $input) {
      checkoutUrl
    }
  }
`;

export function useBuyNowCheckout(): BuyNowCheckoutResult {
  const [buyNow, { loading, error, data }] = useMutation<Response, Variables>(buyNowCheckoutMutation);

  return { buyNow, loading, error, data: data?.buyNowCheckout };
}
