import { LazyQueryExecFunction, gql, useLazyQuery } from "@apollo/client";

import { NotificationsToken } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  notificationsToken: NotificationsToken;
}

interface Variables {
  userId: string;
}

interface NotificationsTokenResult extends BaseQueryResult<Response, "notificationsToken"> {
  getNotificationsToken: LazyQueryExecFunction<Response, Variables>;
}

const notificationsTokenQuery = gql`
  query NotificationsToken($userId: UserId!) {
    notificationsToken(userId: $userId) {
      token
    }
  }
`;

export function useNotificationsToken(): NotificationsTokenResult {
  const [getNotificationsToken, { data, loading, error }] = useLazyQuery<Response, Variables>(notificationsTokenQuery);

  return { getNotificationsToken, data: data?.notificationsToken, loading, error };
}
