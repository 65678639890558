import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  listItem: {
    paddingLeft: 8,
    paddingVertical: 8,
  },
  menu: {
    marginTop: -64,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
});
