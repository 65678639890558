import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { contestFields } from "@app/common/graphql/fragments.graphql";
import { Contest, UpdateContestInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateContest: Contest;
}

interface Variables {
  input: UpdateContestInput;
  contestId: string;
}

interface UpdateCollabResult extends MutationResult<Response, "updateContest"> {
  updateContest: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateContestMutation = gql`
  mutation UpdateContest($contestId: ContestId!, $input: UpdateContestInput!) {
    updateContest(contestId: $contestId, input: $input) {
      ...ContestFields
    }
  }
  ${contestFields}
`;

export function useUpdateContest(): UpdateCollabResult {
  const [updateContest, { loading, error, data }] = useMutation<Response, Variables>(updateContestMutation);

  return { updateContest, loading, error, data: data?.updateContest };
}
