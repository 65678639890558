import React, { FC, useCallback } from "react";

import * as Linking from "expo-linking";
import { useTranslation } from "react-i18next";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { useInAppPurchasesContext } from "@app/context/in-app-purchases/in-app-purchases.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { isWeb } from "@app/utils/device.util";

export const SupercreatorSubscribeButton: FC<Omit<ButtonProps, "children">> = buttonProps => {
  const { t } = useTranslation();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const { profile } = useProfileContext();
  const { trackInMixpanel } = useMixpanelContext();

  const { supercreatorPrice, buySupercreatorPlan, userHasActiveSupercreatorSubscription, loading, manageSubscriptionsUrl } =
    useInAppPurchasesContext();

  const handleSubscribeToSupercreatorPlan = useCallback(() => {
    if (isWeb || !profile?.userId) return;

    trackInMixpanel(TrackEvent.supercreatorSubscribe);

    void buySupercreatorPlan(() => {
      showSuccessSnackbar({ message: t("supercreator.success") });
    });
  }, [buySupercreatorPlan, showSuccessSnackbar, t, trackInMixpanel, profile?.userId]);

  const handleOpenManagementUrl = useCallback(() => {
    if (!manageSubscriptionsUrl) return;

    Linking.openURL(manageSubscriptionsUrl).catch(error => showErrorSnackbar({ error: error as Error }));
  }, [manageSubscriptionsUrl, showErrorSnackbar]);

  return (
    <>
      <Button
        size="large"
        mode="contained"
        fullWidth
        onPress={handleSubscribeToSupercreatorPlan}
        {...buttonProps}
        loading={buttonProps.loading || loading || !supercreatorPrice}
        disabled={buttonProps.disabled || isWeb || userHasActiveSupercreatorSubscription}>
        {userHasActiveSupercreatorSubscription
          ? t("supercreator.subscribed")
          : supercreatorPrice
          ? t("supercreator.subscribeWithPrice", { price: supercreatorPrice })
          : t("supercreator.subscribe")}
      </Button>

      {userHasActiveSupercreatorSubscription && manageSubscriptionsUrl && (
        <Button size="large" mode="outlined" fullWidth disabled={loading} onPress={handleOpenManagementUrl}>
          {t("superplan.manageSubscriptions")}
        </Button>
      )}
    </>
  );
};
