/* eslint-disable max-lines */
import React, { ReactNode, useCallback, useMemo, useState, JSX, useEffect } from "react";

import { OperationVariables } from "@apollo/client";
import * as Clipboard from "expo-clipboard";
import * as Linking from "expo-linking";
import { Check, Link, DotsThree, Export } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, Share, View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import AndroidMessagesLogo from "@app/assets/logos/android-messages-logo.svg";
import AppleMessagesLogo from "@app/assets/logos/apple-messages-logo.svg";
import FacebookLogo from "@app/assets/logos/facebook-logo.svg";
import InstagramLogo from "@app/assets/logos/instagram-logo.svg";
import MessengerLogo from "@app/assets/logos/messenger-logo.svg";
import RedditLogo from "@app/assets/logos/reddit-logo.svg";
import TwitterLogo from "@app/assets/logos/twitter-logo.svg";
import WhatsappLogo from "@app/assets/logos/whatsapp-logo.svg";
import { CollabImage, TargetSocialMedia } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useGenerateCollabImageDownloadLink } from "@app/hooks/api/use-generate-collab-image-download-link.hook";
import { ShareLinkResult } from "@app/hooks/api/use-share-link.hook";
import { TrackSharingResult } from "@app/hooks/api/use-track-sharing.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isAndroidApp, isIos, isWeb } from "@app/utils/device.util";
import { downloadFile } from "@app/utils/file.util";
import { isObjectWithProperties } from "@app/utils/object.util";
import {
  checkIfInstagramOnAndroidInstalled,
  shareToFacebookMessenger,
  shareToInstagramPost,
  shareToInstagramStory,
} from "@app/utils/share/share.util";

import { Alert } from "../alert/alert.component";
import { bottomSheetHorizontalPadding } from "../bottom-sheet/bottom-sheet.style";

import { styles } from "./share-bottom-sheet.style";

enum ShareOptionDestination {
  copyLink = "copyLink",
  facebook = "facebook",
  facebookMessenger = "facebookMessenger",
  genericImageShare = "genericImageShare",
  instagramDm = "instagramDM",
  instagramPost = "instagramPost",
  instagramStory = "instagramStory",
  reddit = "reddit",
  sms = "sms",
  urlShareSheet = "urlShareSheet",
  whatsApp = "whatsApp",
  x = "x",
}

interface ShareOption {
  label: string;
  icon: ReactNode;
  onPress: () => void;
  noBackground?: boolean;
  loading?: boolean;
}

interface Props<TShareLinkVariables extends OperationVariables, TTrackSharingVariables extends OperationVariables> {
  sheetRef: React.RefObject<BottomSheetRefProps>;
  url: string;
  message: string;
  useSocialShareLink: () => ShareLinkResult<TShareLinkVariables>;
  useTrackSharing: () => TrackSharingResult<TTrackSharingVariables>;
  buildShareLinkVariables: (targetSocialMedia: TargetSocialMedia) => TShareLinkVariables;
  buildTrackSharingVariables: (destination: string) => TTrackSharingVariables;
  title?: string;
  image?: CollabImage;
}

// eslint-disable-next-line max-lines-per-function
export const ShareBottomSheet = <TShareLinkVariables extends OperationVariables, TTrackSharingVariables extends OperationVariables>({
  sheetRef,
  url,
  message,
  useSocialShareLink,
  useTrackSharing,
  buildShareLinkVariables,
  buildTrackSharingVariables,
  title,
  image,
}: Props<TShareLinkVariables, TTrackSharingVariables>): JSX.Element => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const { width: windowWidth } = useWindowDimensions();

  const { getLink, loading: loadingLink } = useSocialShareLink();
  const { trackSharing } = useTrackSharing();
  const { generateCollabImageDownloadLink, loading: loadingImageDownloadLink } = useGenerateCollabImageDownloadLink();

  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [shareOptionLoading, setShareOptionLoading] = useState<ShareOptionDestination | undefined>();
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | undefined>();
  const loading = shareOptionLoading || loadingLink || loadingImageDownloadLink;

  const [instagramInstalled, setInstagramInstalled] = useState<boolean | undefined>(undefined);
  const [messengerInstalled, setMessengerInstalled] = useState<boolean | undefined>(undefined);
  const isReady = instagramInstalled !== undefined && messengerInstalled !== undefined;

  const canShareMoreWeb = navigator && "share" in navigator;

  const shareOptionWidth = useMemo(() => {
    const listWidth = windowWidth - bottomSheetHorizontalPadding * 2;
    return Math.floor(listWidth / 4);
  }, [windowWidth]);

  const CopyLinkIcon = shareLinkCopied ? Check : Link;
  const MessagesIcon = isIos ? AppleMessagesLogo : AndroidMessagesLogo;

  const messageWithUrl = useMemo(() => encodeURI(`${url}\n\n${message}`), [message, url]);

  const checkIfAppInstalled = (scheme: string, setInstalled: (installed: boolean) => void): void => {
    Linking.canOpenURL(scheme)
      .then(canOpenURL => {
        setInstalled(canOpenURL);
      })
      .catch(() => setInstalled(false));
  };

  useEffect(() => {
    isAndroidApp ? checkIfInstagramOnAndroidInstalled(setInstagramInstalled) : checkIfAppInstalled("instagram://", setInstagramInstalled);
    checkIfAppInstalled("fb-messenger://", setMessengerInstalled);
  }, []);

  const generateImageDownloadLink = useCallback(async () => {
    if (image && !generatedImageUrl) {
      const result = await generateCollabImageDownloadLink({
        variables: { collabImageId: image.collabImageId },
      }).catch(() => undefined);

      const newGeneratedImageUrl = result?.data?.generateCollabImageDownloadLink.imageUrl;
      setGeneratedImageUrl(newGeneratedImageUrl);

      return newGeneratedImageUrl ?? image.imageUrl;
    }

    return generatedImageUrl;
  }, [generateCollabImageDownloadLink, generatedImageUrl, image]);

  useEffect(() => {
    setGeneratedImageUrl(undefined);
  }, [image]);

  const wrapShareHandler = useCallback(
    (shareHandler: () => Promise<unknown>, destination: ShareOptionDestination): (() => void) => {
      return () => {
        setShareOptionLoading(destination);
        shareHandler()
          .then(() => {
            void trackSharing({ variables: buildTrackSharingVariables(destination) });
          })
          .catch(error => {
            const isPhotoPermissionError =
              isObjectWithProperties(error, "code", "domain") && error.code === "com.rnshare" && error.domain === "PHPhotosErrorDomain";

            if (isPhotoPermissionError) {
              Alert.alert(t("error.instagramShare.alertTitle"), t("error.instagramShare.alertDescription"), [
                { text: t("cta.openSettings"), onPress: () => void Linking.openSettings() },
              ]);
            } else {
              showErrorSnackbar({ error: error instanceof Error ? error : new Error(`Unknown error sharing to ${destination}`) });
            }
          })
          .finally(() => setShareOptionLoading(undefined));
      };
    },
    [buildTrackSharingVariables, showErrorSnackbar, t, trackSharing],
  );

  const handleShareSocial = useCallback(
    (targetSocialMedia: TargetSocialMedia): void => {
      setShareOptionLoading(targetSocialMedia as unknown as ShareOptionDestination);
      void getLink({
        variables: buildShareLinkVariables(targetSocialMedia),
        onCompleted: data => {
          if (isWeb) {
            setTimeout(() => window.open(data.link.url, "_blank")); // Safari blocks window.open in async threads
          } else {
            Linking.openURL(data.link.url).catch(() => showErrorSnackbar());
          }
          setShareOptionLoading(undefined);
        },
        onError: error => {
          showErrorSnackbar({ error });
          setShareOptionLoading(undefined);
        },
      });
    },
    [buildShareLinkVariables, getLink, showErrorSnackbar],
  );

  const handleCopyLink = useMemo(
    () => wrapShareHandler(() => Clipboard.setStringAsync(url).then(response => setShareLinkCopied(response)), ShareOptionDestination.copyLink),
    [url, wrapShareHandler],
  );

  const handleInstagramDM = useMemo(
    () =>
      wrapShareHandler(
        () => Linking.openURL(`instagram://sharesheet?text=${messageWithUrl.replace("&", "%26")}`).catch(() => undefined),
        ShareOptionDestination.instagramDm,
      ),
    [messageWithUrl, wrapShareHandler],
  );

  const handleInstagramStory = useMemo(
    () =>
      wrapShareHandler(async () => {
        const imageDownloadLink = await generateImageDownloadLink();

        if (!image || !imageDownloadLink) return;

        await shareToInstagramStory(imageDownloadLink, image.collabImageId);
      }, ShareOptionDestination.instagramStory),
    [generateImageDownloadLink, image, wrapShareHandler],
  );

  const handleInstagramPost = useMemo(
    () =>
      wrapShareHandler(async () => {
        const imageDownloadLink = await generateImageDownloadLink();

        if (!image || !imageDownloadLink) return;

        await shareToInstagramPost(imageDownloadLink, image.collabImageId);
      }, ShareOptionDestination.instagramPost),
    [generateImageDownloadLink, image, wrapShareHandler],
  );

  const handleFacebookDM = useMemo(
    () => wrapShareHandler(() => shareToFacebookMessenger(url), ShareOptionDestination.facebookMessenger),
    [url, wrapShareHandler],
  );

  const handleSms = useMemo(
    () => wrapShareHandler(() => Linking.openURL(`sms://?&body=${messageWithUrl.replace("&", "%26")}`), ShareOptionDestination.sms),
    [messageWithUrl, wrapShareHandler],
  );

  const handleMore = useCallback(() => {
    setShareOptionLoading(ShareOptionDestination.urlShareSheet);
    if (isWeb) {
      navigator
        .share({ url, text: message })
        .then(() => {
          void trackSharing({ variables: buildTrackSharingVariables("unknown - web url share") });
        })
        .catch(error => {
          if (!isObjectWithProperties(error, "name") || error.name !== "AbortError") {
            showErrorSnackbar();
          }
        })
        .finally(() => setShareOptionLoading(undefined));
    } else {
      Share.share({ url })
        .then(response => {
          if (response.action === "sharedAction") {
            void trackSharing({ variables: buildTrackSharingVariables(response.activityType ?? "unknown - app url share") });
          }
        })
        .catch(() => showErrorSnackbar())
        .finally(() => setShareOptionLoading(undefined));
    }
  }, [buildTrackSharingVariables, message, showErrorSnackbar, trackSharing, url]);

  const handleGenericImageShare = useCallback(() => {
    setShareOptionLoading(ShareOptionDestination.genericImageShare);

    generateImageDownloadLink()
      .then(imageDownloadLink => {
        if (!image || !imageDownloadLink) {
          setShareOptionLoading(undefined);
          return;
        }

        downloadFile(imageDownloadLink, image.collabImageId, title)
          .then(() => {
            void trackSharing({ variables: buildTrackSharingVariables(`unknown - ${isWeb ? "web" : "app"} image share`) });
          })
          .catch((error: Error) => showErrorSnackbar({ error }))
          .finally(() => setShareOptionLoading(undefined));
      })
      .catch(() => setShareOptionLoading(undefined));
  }, [buildTrackSharingVariables, generateImageDownloadLink, image, showErrorSnackbar, title, trackSharing]);

  const shareOptions = useMemo<ShareOption[]>(
    () => [
      {
        label: t(shareLinkCopied ? "success.copied" : "cta.copyLink"),
        icon: <CopyLinkIcon color={colors.onBackground} size={32} weight="thin" />,
        onPress: handleCopyLink,
      },
      {
        label: t("names.messages"),
        icon: <MessagesIcon width={40} height={40} />,
        onPress: handleSms,
        noBackground: !isIos,
        loading: shareOptionLoading === ShareOptionDestination.sms,
      },
      {
        label: t("names.whatsApp"),
        icon: <WhatsappLogo width={40} height={40} />,
        onPress: () => handleShareSocial(TargetSocialMedia.whatsApp),
        noBackground: true,
        loading: shareOptionLoading === ShareOptionDestination.whatsApp,
      },
      ...conditionalItem<ShareOption>(!!instagramInstalled && !isWeb, {
        label: t("names.instagramDM"),
        icon: <InstagramLogo width={41} height={41} />,
        onPress: handleInstagramDM,
        loading: shareOptionLoading === ShareOptionDestination.instagramDm,
      }),
      ...conditionalItem<ShareOption>(!!instagramInstalled && !isWeb && !!image, {
        label: t("names.instagramStory"),
        icon: <InstagramLogo width={41} height={41} />,
        onPress: () => void handleInstagramStory(),
        loading: shareOptionLoading === ShareOptionDestination.instagramStory,
      }),
      ...conditionalItem<ShareOption>(!!instagramInstalled && !isWeb && !!image, {
        label: t("names.instagramPost"),
        icon: <InstagramLogo width={41} height={41} />,
        onPress: () => void handleInstagramPost(),
        loading: shareOptionLoading === ShareOptionDestination.instagramPost,
      }),
      {
        label: t("names.facebook"),
        icon: <FacebookLogo width={40} height={40} />,
        onPress: () => handleShareSocial(TargetSocialMedia.facebook),
        noBackground: true,
        loading: shareOptionLoading === ShareOptionDestination.facebook,
      },
      ...conditionalItem<ShareOption>(!!messengerInstalled && !isWeb, {
        label: t("names.messenger"),
        icon: <MessengerLogo width={40} height={40} />,
        onPress: handleFacebookDM,
        noBackground: true,
        loading: shareOptionLoading === ShareOptionDestination.facebookMessenger,
      }),
      {
        label: t("names.x"),
        icon: <TwitterLogo width={24} height={24} color={colors.onBackground} />,
        onPress: () => handleShareSocial(TargetSocialMedia.x),
        loading: shareOptionLoading === ShareOptionDestination.x,
      },
      {
        label: t("names.reddit"),
        icon: <RedditLogo width={40} height={40} />,
        onPress: () => handleShareSocial(TargetSocialMedia.reddit),
        loading: shareOptionLoading === ShareOptionDestination.reddit,
      },
      ...conditionalItem<ShareOption>(!!image, {
        label: t("cta.shareImage"),
        icon: <Export size={32} color={colors.onBackground} />,
        onPress: () => void handleGenericImageShare(),
        loading: shareOptionLoading === ShareOptionDestination.genericImageShare,
      }),
      ...conditionalItem<ShareOption>(!isWeb || canShareMoreWeb, {
        label: t("cta.more"),
        icon: <DotsThree size={40} color={colors.onBackground} weight="bold" />,
        onPress: handleMore,
        loading: shareOptionLoading === ShareOptionDestination.urlShareSheet,
      }),
    ],
    [
      CopyLinkIcon,
      MessagesIcon,
      canShareMoreWeb,
      colors.onBackground,
      handleCopyLink,
      handleFacebookDM,
      handleGenericImageShare,
      handleInstagramDM,
      handleInstagramPost,
      handleInstagramStory,
      handleMore,
      handleShareSocial,
      handleSms,
      image,
      instagramInstalled,
      messengerInstalled,
      shareLinkCopied,
      shareOptionLoading,
      t,
    ],
  );

  const renderPromptButton = useCallback(
    ({ item }: { item: ShareOption }): JSX.Element => (
      <View style={[styles.promptButton, { width: shareOptionWidth }]}>
        <IconButton
          icon={({ color }) => (item.loading ? <ActivityIndicator color={color} /> : item.icon)}
          onPress={loading ? undefined : item.onPress}
          mode={item.noBackground && !item.loading ? undefined : "contained"}
          style={item.noBackground && !item.loading ? styles.noBackgroundButton : undefined}
        />
        <Text variant="caption" color="tertiary">
          {item.label}
        </Text>
      </View>
    ),
    [loading, shareOptionWidth],
  );

  return (
    <BottomSheet ref={sheetRef} title={t("cta.share")} onDismiss={() => setShareLinkCopied(false)}>
      {isReady && (
        <FlatList
          data={shareOptions}
          renderItem={renderPromptButton}
          keyExtractor={item => `share-prompt-option-${item.label}`}
          scrollEnabled={false}
          numColumns={4}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.promptContent}
        />
      )}
    </BottomSheet>
  );
};
