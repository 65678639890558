import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  chip: {
    borderRadius: 100,
  },
  chipText: {
    paddingHorizontal: 3,
    paddingVertical: 6,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
