import { ApolloClient, defaultDataIdFromObject, from, InMemoryCache } from "@apollo/client";

import {
  CardPaymentMethod,
  Cart,
  CartItem,
  CatalogProduct,
  Category,
  CategoryWithCount,
  Collab,
  Contest,
  Order,
  Profile,
  Comment,
  Progress,
  StudioImage,
  UserState,
  Stage,
  CollabUpdate,
  SupercoinBalance,
  GenericPromo,
  StandardPromo,
  CollabWithMedia,
  StudioMedia,
  StudioAnimation,
} from "@app/common/graphql/generated/schema.graphql";
import { formattedNumber } from "@app/utils/format.util";
import { formatPriceUSD } from "@app/utils/price.util";
import { getStudioMediaId, isStudioImage } from "@app/utils/studio-media.util";

import { setMixpanelInContext, setTokenInContext } from "./apollo-auth-link";
import { errorLink, retryLink } from "./apollo-error-link";
import { bloomApiLink } from "./apollo-requests-link";

const cache = new InMemoryCache({
  // eslint-disable-next-line complexity
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "Profile":
        return (responseObject as Profile).userId;
      case "Collab":
        return (responseObject as Collab).collabId;
      case "CollabWithMedia":
        return `collabWithMedia-${(responseObject as CollabWithMedia).collabId}`;
      case "CardPaymentMethod":
        return (responseObject as CardPaymentMethod).paymentMethodId;
      case "CatalogProduct":
        return (responseObject as CatalogProduct).catalogProductId;
      case "GenericPromo":
        return (responseObject as GenericPromo).promoId;
      case "StandardPromo":
        return (responseObject as StandardPromo).promoId;
      case "Order":
        return (responseObject as Order).orderId;
      case "Contest":
        return (responseObject as Contest).contestId;
      case "UserState":
        return `userState-${(responseObject as UserState).userId}`;
      case "Cart":
        return `cart-${(responseObject as Cart).userId ?? (responseObject as Cart).anonymousCartId ?? ""}`;
      case "CartItem":
        return (responseObject as CartItem).cartItemId;
      case "StudioImage":
        return `studioImage-${(responseObject as StudioImage).imageId}`;
      case "StudioAnimation":
        return `studioAnimation-${(responseObject as StudioAnimation).studioAnimationId}`;
      case "StudioMedia":
        return `${isStudioImage(responseObject as StudioMedia) ? "studioImage" : "studioAnimation"}-${getStudioMediaId(
          responseObject as StudioMedia,
        )}`;
      case "Category":
        return `category-${(responseObject as Category).categoryId}`;
      case "CategoryWithCount":
        return `categoryWithCount-${(responseObject as CategoryWithCount).categoryId}`;
      case "PushNotificationsUserSettings":
        return `PushNotificationsUserSettings`;
      case "Comment":
        return (responseObject as Comment).commentId;
      case "CollabUpdate":
        return (responseObject as CollabUpdate).collabUpdateId;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Collab: {
      fields: {
        formattedPrice(_, { readField }) {
          const price = readField("price") as number;
          return formatPriceUSD(price);
        },
        formattedCompareAtPrice(_, { readField }) {
          const price = readField("compareAtPrice") as number;
          return formatPriceUSD(price);
        },
        formattedNumberOfLikes(_, { readField }) {
          const numberOfLikes = readField("numberOfLikes") as number;
          return formattedNumber(numberOfLikes);
        },
        formattedNumberOfComments(_, { readField }) {
          const numberOfComments = readField("numberOfComments") as number;
          return formattedNumber(numberOfComments);
        },
        formattedNumberOfShares(_, { readField }) {
          const numberOfShares = readField("numberOfShares") as number;
          return formattedNumber(numberOfShares);
        },
        progress(currentProgress: Progress, { readField }) {
          const campaign = readField("campaign");
          const price = readField("price");
          const stage = currentProgress.stage;
          const canStartCampaign = currentProgress.canStartCampaign;

          return { ...currentProgress, canStartCampaign: canStartCampaign && !!campaign && !!price && stage === Stage.concept };
        },
      },
    },
    CollabWithMedia: {
      fields: {
        formattedPrice(_, { readField }) {
          const price = readField("price") as number;
          return formatPriceUSD(price);
        },
        formattedCompareAtPrice(_, { readField }) {
          const price = readField("compareAtPrice") as number;
          return formatPriceUSD(price);
        },
        formattedNumberOfLikes(_, { readField }) {
          const numberOfLikes = readField("numberOfLikes") as number;
          return formattedNumber(numberOfLikes);
        },
        formattedNumberOfComments(_, { readField }) {
          const numberOfComments = readField("numberOfComments") as number;
          return formattedNumber(numberOfComments);
        },
        formattedNumberOfShares(_, { readField }) {
          const numberOfShares = readField("numberOfShares") as number;
          return formattedNumber(numberOfShares);
        },
        progress(currentProgress: Progress, { readField }) {
          const campaign = readField("campaign");
          const price = readField("price");
          const stage = currentProgress.stage;
          const canStartCampaign = currentProgress.canStartCampaign;

          return { ...currentProgress, canStartCampaign: canStartCampaign && !!campaign && !!price && stage === Stage.concept };
        },
      },
    },
    Comment: {
      fields: {
        formattedLikeCount(_, { readField }) {
          const numberOfLikes = readField("likeCount") as number;
          return formattedNumber(numberOfLikes);
        },
      },
    },
    CollabUpdate: {
      fields: {
        formattedLikeCount(_, { readField }) {
          const numberOfLikes = readField("likeCount") as number;
          return formattedNumber(numberOfLikes);
        },
        formattedNumberOfComments(_, { readField }) {
          const numberOfComments = readField("numberOfComments") as number;
          return formattedNumber(numberOfComments);
        },
        formattedShareCount(_, { readField }) {
          const numberOfShares = readField("shareCount") as number;
          return formattedNumber(numberOfShares);
        },
      },
    },
    ProductVariant: {
      fields: {
        formattedPrice(_, { readField }) {
          const price = readField("price") as number;
          return formatPriceUSD(price);
        },
        formattedCompareAtPrice(_, { readField }) {
          const price = readField("compareAtPrice") as number;
          return formatPriceUSD(price);
        },
      },
    },
    Order: {
      fields: {
        formattedTotalPrice(_, { readField }) {
          const price = readField("totalPrice") as number;
          return formatPriceUSD(price);
        },
      },
    },
    OrderLineItem: {
      fields: {
        formattedPrice(_, { readField }) {
          const price = readField("price") as number;
          return formatPriceUSD(price);
        },
      },
    },
    SupercoinBalance: {
      fields: {
        formattedAmount(_, { readField }) {
          const amount = readField("amount") as number;
          return formatPriceUSD(amount, { withoutUSD: true });
        },
      },
    },
    Query: {
      fields: {
        supercoinBalance: {
          keyArgs: false,
          merge: (_existing: SupercoinBalance, incoming: SupercoinBalance) => {
            return incoming;
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: from([retryLink, errorLink, setTokenInContext, setMixpanelInContext, bloomApiLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});
