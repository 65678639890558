import React, { FC, ReactNode } from "react";

import { ChipProps as RNChipProps, Chip as RNChip, useTheme, Theme } from "react-native-paper";

import { CustomVariants, customFonts } from "@app/common/style/fonts";
import { ColorVariant } from "@app/common/style/theme";
import { ColorKey, getColor, getOnColor } from "@app/utils/color-theme.util";

import { styles } from "./chip.style";

export interface ChipProps extends Omit<RNChipProps, "children"> {
  label: ReactNode;
  color?: ColorVariant;
  bgColor?: ColorKey;
  textColor?: ColorKey;
  textVariant?: CustomVariants;
  theme?: Theme;
}

export const Chip: FC<ChipProps> = ({ label, color, bgColor, textColor, textVariant, textStyle, style, theme: overriddenTheme, ...props }) => {
  const { colors: currentColors, roundness } = useTheme();
  const colors = overriddenTheme?.colors ?? currentColors;

  const backgroundColor: string | undefined = props.mode !== "outlined" && color ? colors[color] : bgColor ? getColor(colors, bgColor) : undefined;
  const textColorSelected = props.mode !== "outlined" && color ? getOnColor(colors, color) : textColor ? getColor(colors, textColor) : undefined;

  return (
    <RNChip
      style={[backgroundColor ? { backgroundColor } : {}, { borderRadius: roundness }, style]}
      textStyle={[styles.text, textColorSelected ? { color: textColorSelected } : {}, textVariant ? customFonts[textVariant] : {}, textStyle]}
      {...props}>
      {label}
    </RNChip>
  );
};
