import React, { FC } from "react";

import { ViewProps, FlexStyle, View } from "react-native";

export interface BoxProps extends ViewProps, FlexStyle {}

export const Box: FC<BoxProps> = ({ children, ...props }) => {
  const { id, onLayout, pointerEvents, removeClippedSubviews, style, nativeID, ...flexStyleProps } = props;
  const viewProps = { id, onLayout, pointerEvents, removeClippedSubviews, style, nativeID };

  return (
    <View {...viewProps} style={[style, flexStyleProps]}>
      {children}
    </View>
  );
};
