import { StyleSheet } from "react-native";

import { spacing } from "@app/common/style/theme";

export const styles = StyleSheet.create({
  content: {
    paddingLeft: spacing(2),
    paddingVertical: spacing(2),
    rowGap: spacing(3),
  },
  contentContainer: {
    justifyContent: "center",
  },
  iconButton: {
    height: 20,
    margin: 0,
    width: 20,
  },
  nextButton: {
    marginHorizontal: spacing(2),
    marginVertical: spacing(0.5),
  },
  rowContent: {
    alignItems: "flex-start",
    columnGap: spacing(1),
    flexDirection: "row",
  },
  rowStep: {
    alignItems: "center",
    columnGap: spacing(1),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text: {
    flexShrink: 1,
  },
});
