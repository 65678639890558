import React, { forwardRef, RefObject, useMemo } from "react";

import { Coins, X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { ImageGenerationStatus } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetProps, BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { SuperplanAlert } from "@app/components/superplans/superplan-alert/superplan-alert.component";
import { useConfigContext } from "@app/context/config/config.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useProfileContext } from "@app/context/profile/profile.context";

import { styles } from "./bottom-sheet-credit-info.style";

interface Props extends Omit<BottomSheetProps, "children"> {
  onClose: () => void;
  imageCredits: ImageGenerationStatus | undefined;
}

interface CreditStrings {
  title: string;
  description: string;
}

export const BottomSheetCreditInfo = forwardRef<BottomSheetRefProps, Props>(({ onClose, imageCredits, ...props }, sheetRef) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { profile } = useProfileContext();
  const { tokenCosts } = useConfigContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const isUserOutOfTokens = imageCredits && !imageCredits.unlimited && imageCredits.tokensLeft <= 0;

  const { title, description } = useMemo<CreditStrings>(() => {
    if (!profile || !imageCredits)
      return {
        title: t("studioMini.prompt.noCreditLeft.anonymousTitle"),
        description: t("studioMini.prompt.noCreditLeft.anonymousDetails", { tokens: tokenCosts?.tokensPerDay }),
      };

    if (isUserOutOfTokens)
      return {
        title: t("studioMini.prompt.noCreditLeft.title"),
        description: t("studioMini.prompt.noCreditLeft.details", { tokens: tokenCosts?.tokensPerDay }),
      };

    if (imageCredits.unlimited && isFeatureEnabled(FeatureFlag.infiniteStudioTokens))
      return {
        title: t("studioMini.prompt.creditLeft.title", { tokens: "unlimited" }),
        description: t("studioMini.prompt.creditLeft.unlimitedForEveryoneDetails"),
      };

    if (imageCredits.unlimited)
      return {
        title: t("studioMini.prompt.creditLeft.title", { tokens: "unlimited" }),
        description: t("studioMini.prompt.creditLeft.unlimitedDetails"),
      };

    return {
      title: t("studioMini.prompt.creditLeft.title", { tokens: imageCredits.tokensLeft }),
      description: t("studioMini.prompt.creditLeft.limitedDetails", { tokens: tokenCosts?.tokensPerDay }),
    };
  }, [imageCredits, isFeatureEnabled, isUserOutOfTokens, profile, t, tokenCosts?.tokensPerDay]);

  const handleLearnMoreAboutSuperplans = (tab: SuperplanType | undefined): void => {
    (sheetRef as RefObject<BottomSheetRefProps>).current?.close();
    navigation.navigate(Routes.superplans, { tab });
  };

  return (
    <BottomSheet
      ref={sheetRef}
      leftHeader={<Coins weight="fill" color={isUserOutOfTokens ? colors.error : colors.primary} style={styles.leftHeader} />}
      rightHeader={<IconButton icon={X} onPress={onClose} />}
      headerStyle={styles.header}
      contentContainerStyle={styles.bottomSheetContainer}
      {...props}>
      <View style={styles.content}>
        <Text variant="h5">{title}</Text>
        <Text>{description}</Text>

        {!imageCredits?.unlimited && (
          <View style={styles.superplanAlertWrapper}>
            <SuperplanAlert
              feature="infiniteTokens"
              origin={ViewSuperplanScreenOrigin.studioTokensBottomSheet}
              onLearnMorePress={handleLearnMoreAboutSuperplans}
            />
          </View>
        )}
      </View>
    </BottomSheet>
  );
});

BottomSheetCreditInfo.displayName = "BottomSheetCreditInfo";
