import React, { FC, useState } from "react";

import { NetworkStatus } from "@apollo/client";
import { FlatList } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { PromoListItem } from "@app/components/god-mode/promo-list-item/promo-list-item.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { usePromos } from "@app/hooks/api/promos/use-promos.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";

import { styles } from "./manage-promos.style";

export const ManagePromosScreen: FC<ProfileStackScreenProps<Routes.managePromos>> = () => {
  const { showErrorSnackbar } = useSnackbarContext();

  const { data, loading, networkStatus, fetchMore, refetch } = usePromos({
    onError: error => {
      showErrorSnackbar({ error, refetch });
    },
  });

  const [refreshing, setRefreshing] = useState(false);

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const { fetchMoreItems } = useFetchMoreItems("promos", fetchMore);

  const handleFetchMore = (): void => {
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

    if (fetchMore && !isFetchingMore && !refreshing && data?.nodes && data.nodes.length > 0 && data?.pageInfo.hasNextPage) {
      void fetchMoreItems({ offset: data.nodes.length });
    }
  };

  return (
    <ScreenWrapper>
      {loading && <ActivityIndicator size="large" />}

      <FlatList
        data={data?.nodes}
        renderItem={({ item }) => <PromoListItem promo={item} />}
        keyExtractor={item => item.promoId}
        ListFooterComponent={networkStatus === NetworkStatus.fetchMore ? <ActivityIndicator size="small" /> : undefined}
        onEndReached={handleFetchMore}
        onEndReachedThreshold={0.3}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.root}
        refreshing={refreshing}
        onRefresh={() => void handleRefreshing()}
      />
    </ScreenWrapper>
  );
};
