import React from "react";

import { getHeaderTitle, Header, HeaderTitle } from "@react-navigation/elements";
import { NativeStackNavigationOptions, createNativeStackNavigator } from "@react-navigation/native-stack";
import Constants from "expo-constants";
import { StatusBar } from "react-native";

import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { RootStackParamList } from "@app/common/types/navigation.type";
import { BackButton } from "@app/components/common/navigation/back-button.component";
import { isAndroidApp } from "@app/utils/device.util";

import { styles } from "./router.style";

export const Stack = createNativeStackNavigator<RootStackParamList>();

export const logoAsHeaderTitle = (tintColor: string): NativeStackNavigationOptions => ({
  headerTitle: props => (
    <HeaderTitle {...props}>
      <OffscriptLogo height={32} width={89} color={tintColor} />
    </HeaderTitle>
  ),
});

export const commonScreenOptions = (): NativeStackNavigationOptions => ({
  headerShadowVisible: false,
  headerTitleAlign: "center",
  header: ({ navigation, route, options, back }) => {
    const { headerLeft, headerRight, ...headerProps } = options;
    const title = getHeaderTitle(options, route.name);

    return (
      <Header
        title={title}
        headerStyle={[options.headerStyle, styles.header]}
        headerLeft={props =>
          headerLeft ? (
            headerLeft({ tintColor: props.tintColor, canGoBack: navigation.canGoBack() })
          ) : back ? (
            <BackButton onPress={() => navigation.goBack()} {...props} />
          ) : undefined
        }
        headerRight={headerRight ? props => headerRight({ tintColor: props.tintColor, canGoBack: navigation.canGoBack() }) : undefined}
        headerLeftContainerStyle={styles.headerLeft}
        headerRightContainerStyle={styles.headerRight}
        headerStatusBarHeight={isAndroidApp && StatusBar.currentHeight ? StatusBar.currentHeight : Constants.statusBarHeight}
        {...headerProps}
      />
    );
  },
});
