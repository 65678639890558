import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { pushNotificationSettingsFields } from "@app/common/graphql/fragments.graphql";
import { Profile, PushNotificationsSettingsInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setPushNotificationSettings: Profile;
}

interface Variables {
  userId: string;
  input: PushNotificationsSettingsInput;
}

interface SetPushNotificationSettingsResult extends MutationResult<Response, "setPushNotificationSettings"> {
  setPushNotificationSettings: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const setPushNotificationSettingsMutation = gql`
  mutation SetPushNotificationSettings($input: PushNotificationsSettingsInput!, $userId: UserId!) {
    setPushNotificationSettings(input: $input, userId: $userId) {
      ...PushNotificationSettingsFields
    }
  }
  ${pushNotificationSettingsFields}
`;

export function useSetPushNotificationSettings(): SetPushNotificationSettingsResult {
  const [setPushNotificationSettings, { loading, error, data, reset }] = useMutation<Response, Variables>(setPushNotificationSettingsMutation);

  return { setPushNotificationSettings, loading, error, data: data?.setPushNotificationSettings, reset };
}
