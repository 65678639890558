import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  chip: {
    marginBottom: 8,
  },
  creatorSectionSkeleton: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    flex: 1,
    padding: 20,
    width: "100%",
  },
  imageContentContainer: {
    alignItems: "flex-end",
    columnGap: 8,
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 8,
    paddingVertical: 8,
    rowGap: 8,
  },
});
