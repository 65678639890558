import React, { FC, JSX } from "react";

import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import Animated, { interpolate, useAnimatedStyle, type SharedValue, AnimatedStyle } from "react-native-reanimated";

interface Props {
  opacityValue: SharedValue<number>;
  inputRange?: number[];
  labelComponent?: () => JSX.Element;
  outputRangeLabel?: number[];
  backgroundColor?: string;
  backgroundStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
}

export const SwipeableCardOverlay: FC<Props> = ({
  opacityValue,
  inputRange,
  outputRangeLabel,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  labelComponent: LabelComponent,
  backgroundColor,
  backgroundStyle,
}) => {
  const animatedLabelStyle = useAnimatedStyle(() => ({
    opacity: interpolate(opacityValue.value, inputRange ?? [], outputRangeLabel ?? [], "clamp"),
    zIndex: 2,
  }));

  const animatedBackgroundStyle = useAnimatedStyle(() => {
    return {
      zIndex: 2,
      backgroundColor,
      opacity: interpolate(opacityValue?.value ?? 0, inputRange ?? [], [0, 0.3], "clamp"),
    };
  });

  if (!backgroundColor && !LabelComponent) return null;

  return (
    <>
      {backgroundColor && <Animated.View style={[StyleSheet.absoluteFillObject, animatedBackgroundStyle, backgroundStyle]} pointerEvents="none" />}
      {LabelComponent && (
        <Animated.View style={[StyleSheet.absoluteFillObject, animatedLabelStyle]} pointerEvents="none">
          <LabelComponent />
        </Animated.View>
      )}
    </>
  );
};
