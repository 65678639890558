import { StripeAddressElementChangeEvent, DefaultValuesOption } from "@stripe/stripe-js";

import { SetShippingAddressInput, ShippingAddress } from "@app/common/graphql/generated/schema.graphql";

import { removeEmptyStringFromObject } from "../object.util";

import { getShippingAddressInputFromContextAddress, getComputeAdditionalChargesInputFromContextAddress } from "./address-converter.util.common";

export function getShippingAddressInputFromAddressSheet(address: StripeAddressElementChangeEvent["value"]): SetShippingAddressInput {
  let input: SetShippingAddressInput = {
    city: address.address.city,
    country: address.address.country,
    line1: address.address.line1,
    line2: address.address.line2,
    name: address.name,
    phoneNumber: address.phone,
    postalCode: address.address.postal_code,
    state: address.address.state,
  };
  input = removeEmptyStringFromObject(input);

  return input;
}

export function getContextShippingAddressFromAddressSheet(address: StripeAddressElementChangeEvent["value"]): ShippingAddress {
  const input: ShippingAddress = {
    city: address.address.city,
    country: address.address.country,
    line1: address.address.line1,
    line2: address.address.line2 ?? undefined,
    name: address.name,
    phoneNumber: address.phone,
    postalCode: address.address.postal_code,
    state: address.address.state,
    __typename: "ShippingAddress",
  };

  return input;
}

export function getBillingDetailsFromShippingAddress(
  shippingAddress: ShippingAddress | undefined,
): DefaultValuesOption["billingDetails"] | undefined {
  if (!shippingAddress) return undefined;
  return {
    name: shippingAddress.name,
    address: {
      city: shippingAddress.city,
      country: shippingAddress.country,
      line1: shippingAddress.line1,
      line2: shippingAddress.line2,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      postal_code: shippingAddress.postalCode,
      state: shippingAddress.state,
    },
  };
}

export { getShippingAddressInputFromContextAddress, getComputeAdditionalChargesInputFromContextAddress };
