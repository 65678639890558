import { AnyPromo, CollabWithMedia, FeedEntity, StandardPromo } from "@app/common/graphql/generated/schema.graphql";

export function isCollab(entity?: FeedEntity): entity is CollabWithMedia {
  return entity?.__typename === "CollabWithMedia";
}

export function isStandardPromo(entity?: FeedEntity): entity is StandardPromo {
  return entity?.__typename === "StandardPromo";
}

export function isPromo(entity?: FeedEntity): entity is AnyPromo {
  return isStandardPromo(entity) || entity?.__typename === "GenericPromo";
}

export function getFeedEntityId(entity?: FeedEntity): string | undefined {
  if (isPromo(entity)) return entity.promoId;
  if (isCollab(entity)) return entity.collabId;

  return undefined;
}
