import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { catalogProductFields } from "@app/common/graphql/fragments.graphql";
import { CatalogProduct, CreateCatalogProductInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  createCatalogProduct: CatalogProduct;
}

interface Variables {
  input: CreateCatalogProductInput;
}

interface CreateCatalogProductResult extends MutationResult<Response, "createCatalogProduct"> {
  createCatalogProduct: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createCatalogProductMutation = gql`
  mutation CreateCatalogProduct($input: CreateCatalogProductInput!) {
    createCatalogProduct(input: $input) {
      ...CatalogProductFields
    }
  }
  ${catalogProductFields}
`;

export function useCreateCatalogProduct(): CreateCatalogProductResult {
  const [createCatalogProduct, { loading, error, data }] = useMutation<Response, Variables>(createCatalogProductMutation);

  return { createCatalogProduct, loading, error, data: data?.createCatalogProduct };
}
