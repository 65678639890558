import React, { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useContestPasswordValidation } from "@app/hooks/api/contests/use-contest-password-validation.hook";

interface Props {
  contestId: string;
  visible: boolean;
  onDismiss: () => void;
  onValidPassword?: () => void;
}

export const ContestPasswordValidationDialog: FC<Props> = ({ contestId, visible, onDismiss, onValidPassword }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { setLocalStorage, contestPasswords } = useLocalStorageContext();

  const { validatePassword, loading } = useContestPasswordValidation();

  const [wrongPasswordError, setWrongPasswordError] = useState(false);
  const [password, setPassword] = useState("");

  const handleDismiss = useCallback(() => {
    setPassword("");
    setWrongPasswordError(false);
    onDismiss();
  }, [onDismiss]);

  const handleSubmit = useCallback((): void => {
    if (!password) return;

    void validatePassword({
      variables: { contestId, password },
      onCompleted: data => {
        if (data.contestPasswordValidation.valid) {
          contestPasswords[contestId] = password;
          setLocalStorage(LocalStorageKeys.contestPasswords, contestPasswords);
          onValidPassword?.();
          handleDismiss();
        } else {
          if (contestPasswords[contestId]) {
            delete contestPasswords[contestId];
            setLocalStorage(LocalStorageKeys.contestPasswords, contestPasswords);
          }

          setWrongPasswordError(true);
        }
      },
      onError: error => {
        if (isErrorCode(error, "NO_PASSWORD_REQUIRED")) {
          onValidPassword?.();
          handleDismiss();
          return;
        }

        showErrorSnackbar({ error });
      },
    });
  }, [contestId, contestPasswords, handleDismiss, onValidPassword, password, setLocalStorage, showErrorSnackbar, validatePassword]);

  const handleChangeText = (text: string): void => {
    setWrongPasswordError(false);
    setPassword(text);
  };

  return (
    <ConfirmationDialog
      visible={visible}
      title={t("confirmation.enterContestPassword.title")}
      message={t("confirmation.enterContestPassword.message")}
      additionalContent={
        <TextInput
          placeholder={t("confirmation.enterContestPassword.password")}
          value={password}
          onChangeText={handleChangeText}
          error={wrongPasswordError}
          errorText={t("error.wrongPassword")}
          autoCorrect={false}
          returnKeyType="send"
          blurOnSubmit
          onEndEditing={handleSubmit}
        />
      }
      confirmProps={{
        label: t("cta.submit"),
        loading,
        color: "primary",
        onPress: handleSubmit,
      }}
      onDismiss={handleDismiss}
    />
  );
};
