import React, { FC } from "react";

import { ResizeMode } from "expo-av";
import { useTheme } from "react-native-paper";

import { Video } from "@app/components/common/video/video.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

export const TipsPitchVideo: FC = () => {
  const { colors } = useTheme();
  const { width: widthScreen } = useWindowDimensions();

  const videoSize = widthScreen - 40; // paddingHorizontal = 40 = 20 * 2

  return (
    <Video
      source={{ uri: "https://customer-u7pxwd239bwgmyto.cloudflarestream.com/985d4159f03ce07ef0588cce29bd5e81/manifest/video.m3u8" }}
      posterSource={{
        uri: "https://customer-u7pxwd239bwgmyto.cloudflarestream.com/985d4159f03ce07ef0588cce29bd5e81/thumbnails/thumbnail.jpg",
      }}
      cloudflareId="985d4159f03ce07ef0588cce29bd5e81"
      useNativeControls
      shouldPlay
      displayLoadingIndicator
      width={videoSize}
      height={videoSize}
      resizeMode={ResizeMode.CONTAIN}
      style={{ backgroundColor: colors.background }}
    />
  );
};
