import React, { FC } from "react";

import { View, ViewProps } from "react-native";
import { Divider, useTheme } from "react-native-paper";

import { StepItem, StepItemProps } from "./step-item/step-item.component";
import { styles } from "./stepper.style";

interface Props extends ViewProps {
  steps: StepItemProps[];
  flexDirection?: "row" | "column";
}

export const Stepper: FC<Props> = ({ steps, flexDirection = "column", style, ...viewProps }) => {
  const { colors } = useTheme();

  const wrapperStyle = flexDirection === "row" ? styles.wrapperRow : styles.wrapperColumn;

  return (
    <View style={[wrapperStyle, style]} {...viewProps}>
      {steps.map((step, index) => (
        <View key={`step-${step.label}`} style={wrapperStyle}>
          <StepItem {...step} style={flexDirection === "row" ? styles.item : undefined} />
          {index !== steps.length - 1 && (
            <Divider
              style={[{ backgroundColor: colors.action.active }, flexDirection === "row" ? styles.horizontalDivider : styles.verticalDivider]}
            />
          )}
        </View>
      ))}
    </View>
  );
};
