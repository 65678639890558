import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { FlatList, ListRenderItem, StyleProp, ViewStyle } from "react-native";

import { GenerationTaskType, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useContentDimensions } from "@app/hooks/utils/use-content-dimension.hook";

import { StudioPromptSet } from "../studio-prompt-set/studio-prompt-set.component";

interface Props {
  style: StyleProp<ViewStyle>;
  onGenerate: () => Promise<void>;
  onReset: () => void;
}

export const StudioGeneratedImages: FC<Props> = ({ onGenerate, onReset, style }) => {
  const { generation } = useStudioMiniContext();
  const pages = generation.taskIds ? Object.entries(generation.taskIds) : [];
  const pageRef = useRef<FlatList>(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const { width, height } = useContentDimensions();

  const handleOnNext = (): void => setSelectedPage(prev => prev + 1);

  const handleOnPrevious = (): void => setSelectedPage(prev => prev - 1);

  useEffect(() => {
    pageRef.current?.scrollToOffset({ offset: selectedPage * width });
  }, [selectedPage, width]);

  useEffect(() => {
    setSelectedPage(pages.length === 0 ? 0 : pages.length - 1);
  }, [pages.length]);

  const renderItem = useCallback<ListRenderItem<[string, GenerationTaskType]>>(
    ({ item, index }) => (
      <StudioPromptSet
        style={{ width, height }}
        indexSet={index}
        taskId={item[0]}
        task={item[1]}
        taskCount={pages.length}
        onNext={handleOnNext}
        onPrevious={handleOnPrevious}
        onGenerate={onGenerate}
        onReset={onReset}
      />
    ),
    [width, height, pages.length, onGenerate, onReset],
  );

  return (
    <FlatList
      style={style}
      ref={pageRef}
      getItemLayout={(_data, index) => ({ length: width, offset: index * width, index })}
      horizontal
      ListEmptyComponent={null}
      pagingEnabled
      data={pages}
      renderItem={renderItem}
      extraData={pages}
      keyExtractor={item => item[0]}
    />
  );
};
