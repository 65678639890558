import React, { FC } from "react";

import { HeaderBackButton, HeaderBackButtonProps } from "@react-navigation/elements";
import { ArrowLeft } from "phosphor-react-native";

export const BackButton: FC<HeaderBackButtonProps> = props => (
  <HeaderBackButton
    backImage={({ tintColor }) => <ArrowLeft color={tintColor} weight="thin" />}
    testID="back-button"
    {...props}
    labelVisible={false}
  />
);
