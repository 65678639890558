import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    borderRadius: 8,
    padding: 8,
    width: "100%",
  },
  row: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  text: {
    flexShrink: 1,
  },
});
