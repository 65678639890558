import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  iconButton: {
    height: 24,
    margin: 0,
    width: 24,
  },
  itemIcon: {
    marginHorizontal: 8,
    marginVertical: 12,
  },
  listContainer: {
    paddingTop: isWeb ? 16 : undefined,
  },
});
