import React, { FC, useCallback, useState } from "react";

import { TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton } from "react-native-paper";

import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeletePaymentMethod } from "@app/hooks/api/preorder/use-delete-payment-method.hook";

interface Props {
  paymentMethodId: string;
  color: string;
}

export const DeletePaymentMethodButton: FC<Props> = ({ paymentMethodId, color }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();

  const [dialogVisible, setDialogVisible] = useState(false);

  const { deletePaymentMethod, loading } = useDeletePaymentMethod();

  const handleDelete = useCallback(() => {
    void deletePaymentMethod({
      variables: { paymentMethodId },
      onCompleted: () => setDialogVisible(false),
      onError: error => {
        setDialogVisible(false);
        showErrorSnackbar({ error });
      },
    });
  }, [deletePaymentMethod, paymentMethodId, showErrorSnackbar]);

  return (
    <>
      <IconButton icon={props => <TrashSimple {...props} color={color} size={24} />} onPress={() => setDialogVisible(true)} disabled={loading} />

      <ConfirmationDialog
        visible={dialogVisible}
        title={t("preorder.deletePaymentMethod.title")}
        message={t("preorder.deletePaymentMethod.message")}
        confirmProps={{
          label: t("cta.delete"),
          loading,
          onPress: handleDelete,
        }}
        onDismiss={() => setDialogVisible(false)}
      />
    </>
  );
};
