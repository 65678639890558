/* eslint-disable complexity */
import React, { FC, useState } from "react";

import { ShoppingCartSimple, CheckCircle, PlusCircle } from "phosphor-react-native";
import { Trans } from "react-i18next";
import { IconButton, IconButtonProps as RNIconButtonProps } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { Button, ButtonProps as RNButtonProps } from "@app/components/common/button/button.component";
import { useCartContext } from "@app/context/cart/cart.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useAddToCart } from "@app/hooks/api/use-add-to-cart.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { PickVariantBanner } from "../pick-variant-banner/pick-variant-banner.component";

interface TextButtonProps extends Omit<RNButtonProps, "theme" | "hitSlop" | "delayLongPress"> {
  type?: "text";
}

interface IconButtonProps extends Omit<RNIconButtonProps, "mode" | "size" | "theme" | "style"> {
  type?: "icon";
  icon: IconSource;
  loading?: boolean;
}

type ButtonProps = TextButtonProps | IconButtonProps;

interface Props extends Omit<ButtonProps, "children"> {
  collabId: string;
  variantId?: string;
  inventoryQuantity?: number;
  price?: number;
  quantity: number;
  allVariantsSoldOut?: boolean;
  onPressStart?: () => void;
}

export const AddToCartButton: FC<Props> = ({
  type = "text",
  collabId,
  variantId,
  inventoryQuantity,
  price,
  quantity,
  allVariantsSoldOut,
  loading,
  icon,
  disabled,
  onPressStart,
  ...props
}) => {
  const { addToCart, loading: loadingAddToCart } = useAddToCart();
  const { showErrorSnackbar } = useSnackbarContext();
  const { cartItems, cartIdentity, loadingCartIdentity, refetch } = useCartContext();
  const { setLocalStorage } = useLocalStorageContext();

  const [pickVariantBannerVisible, setPickVariantBannerVisible] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const isLoading = loadingAddToCart || loadingCartIdentity || loading;
  const soldOut = (!isLoading && !!variantId && inventoryQuantity !== undefined && inventoryQuantity <= 0) || allVariantsSoldOut;

  const existingCartItem = cartItems?.find(item => item.variant?.variantId === variantId);
  const newQuantity = existingCartItem ? quantity + existingCartItem.quantity : quantity;
  const isDisabled =
    soldOut || loadingCartIdentity || (inventoryQuantity !== undefined && quantity > 0 && newQuantity > inventoryQuantity) || disabled;

  const handleAddToCart = (): void => {
    if (!variantId) {
      setPickVariantBannerVisible(true);
      return;
    }

    const cartIdentifierArgs = cartIdentity ?? {};

    onPressStart?.();
    void addToCart({
      variables: { input: { collabId, variantId, quantity: newQuantity }, ...cartIdentifierArgs },
      onCompleted: data => {
        setIsAdded(true);
        if (!cartIdentity) {
          setLocalStorage(LocalStorageKeys.anonymousCartId, data.addToCart.identity.anonymousCartId);
          void refetch?.();
        }
      },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <>
      {type === "text" ? (
        <Button
          mode="contained"
          size="large"
          color={isAdded ? "success" : "primary"}
          icon={iconProps => (isAdded ? <CheckCircle {...iconProps} weight="fill" /> : <ShoppingCartSimple {...iconProps} weight="fill" />)}
          fullWidth
          loading={isLoading}
          disabled={isDisabled}
          onPress={handleAddToCart}
          {...props}>
          {isAdded ? (
            <Trans i18nKey="cart.addedToCart" />
          ) : soldOut ? (
            <Trans i18nKey="cart.soldOut" />
          ) : (
            <Trans i18nKey="cta.confirmWithPrice" values={{ price: price != null ? formatPriceUSD(price * quantity) : undefined }} />
          )}
        </Button>
      ) : (
        <IconButton
          icon={icon ?? (iconProps => <PlusCircle weight="fill" {...iconProps} />)}
          disabled={isDisabled}
          onPress={!isLoading ? handleAddToCart : undefined}
          {...props}
        />
      )}

      <PickVariantBanner bannerVisible={pickVariantBannerVisible} setBannerVisible={setPickVariantBannerVisible} />
    </>
  );
};
