import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonAccessory: {
    margin: 0,
  },
  inputContainer: {
    flex: 1,
    width: undefined,
  },
  inputContent: {
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
  },
  root: {
    alignItems: "stretch",
    columnGap: 8,
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
});
