import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  row: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
    width: "100%",
  },
});
