import { LazyQueryExecFunction, QueryHookOptions, gql, useLazyQuery, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  collab: Collab;
}

interface CollabIdVariable {
  collabId: string;
}

interface HandleVariable {
  handle: string;
}

type Variables = CollabIdVariable | HandleVariable;

interface LazyResult extends QueryResult<Response, "collab"> {
  getCollab: LazyQueryExecFunction<Response, Variables>;
}

/**
 * This query is also used to query collab for the thumbnail preview. Update it accordingly:
 * server/services/collab.service.js
 */
const collabQuery = gql`
  query Collab($collabId: CollabId, $handle: String) {
    collab(collabId: $collabId, handle: $handle) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useCollab(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "collab"> {
  const variables = options.variables;
  const { data, loading, error } = useQuery<Response, Variables>(collabQuery, {
    skip: !variables || ("collabId" in variables && !variables.collabId) || ("handle" in variables && !variables.handle),
    ...options,
  });

  return { data: data?.collab, loading, error };
}

export function useLazyCollab(): LazyResult {
  const [getCollab, { loading, error, data }] = useLazyQuery<Response, Variables>(collabQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return { getCollab, loading, error, data: data?.collab };
}
