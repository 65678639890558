import { StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";

export const styles = StyleSheet.create({
  tab: {
    elevation: 0,
    marginBottom: 8,
    marginHorizontal: 20,
    shadowColor: transparent,
  },
  tabLabel: {
    textTransform: "none",
  },
});
