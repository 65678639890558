import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  alert: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  productImage: {
    borderRadius: 0,
  },
  rootContainer: {
    rowGap: 16,
  },
  spaceBetweenText: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subsectionContainer: {
    rowGap: 8,
  },
  title: {
    rowGap: 4,
  },
});
