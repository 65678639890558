import { useCallback } from "react";

import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { getPermissionsAsync, PermissionStatus } from "expo-notifications";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";
import { isSimulator, isWeb } from "@app/utils/device.util";

interface Response {
  profileFromToken: Profile;
}

interface AuthProfileResult extends BaseQueryResult<Response, "profileFromToken"> {
  getAuthProfile: LazyQueryExecFunction<Response, OperationVariables>;
}

export const authProfileQuery = gql`
  query ProfileFromToken {
    profileFromToken {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useAuthProfile(): AuthProfileResult {
  const pushNotificationsAvailable = !isSimulator && !isWeb;

  const [getAuthProfile, { loading, error, data }] = useLazyQuery<Response>(authProfileQuery);

  const wrappedGetAuthProfile = useCallback<LazyQueryExecFunction<Response, OperationVariables>>(
    async args => {
      let userPermissionStatus: PermissionStatus | "unavailable" = "unavailable";

      if (pushNotificationsAvailable) {
        const permissions = await getPermissionsAsync();
        userPermissionStatus = permissions.status;
      }

      return getAuthProfile({
        ...args,
        context: {
          ...args?.context,
          headers: { ...(args?.context?.headers as Record<string, string>), "Bloom-Push-Notif-Permission-Status": userPermissionStatus },
        },
      });
    },
    [getAuthProfile, pushNotificationsAvailable],
  );

  return { getAuthProfile: wrappedGetAuthProfile, loading, error, data: data?.profileFromToken };
}
