import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { progressFields } from "@app/common/graphql/fragments.graphql";
import { QuoteRequestStatus } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  quoteRequestStatus: QuoteRequestStatus;
}

interface Variables {
  collabId: string;
}

export const quoteRequestStatusQuery = gql`
  query QuoteRequestStatus($collabId: CollabId!) {
    quoteRequestStatus(collabId: $collabId) {
      canRequestQuote
      collab {
        collabId
        handle
        progress {
          ...ProgressFields
        }
      }
      nextQuoteRequestAvailableAt
      quoteRequested
      quoteRequestsLeft
      quoteRequestsPerWeek
    }
  }
  ${progressFields}
`;

export function useQuoteRequestStatus(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "quoteRequestStatus"> {
  const { data, loading, error } = useQuery<Response, Variables>(quoteRequestStatusQuery, {
    skip: !options.variables?.collabId,
    ...options,
  });

  return { data: data?.quoteRequestStatus, loading, error };
}
