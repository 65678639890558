import React, { FC, useEffect, useState } from "react";

import * as Clipboard from "expo-clipboard";
import { ArrowRight, CopySimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Divider, IconButtonProps, useTheme } from "react-native-paper";

import { Button } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Text } from "@app/components/common/text/text.component";
import { AppleButton } from "@app/components/login/apple-button/apple-button.component";
import { EmailButton } from "@app/components/login/email-button/email-button.component";
import { FacebookButton } from "@app/components/login/facebook-button/facebook-button.component";
import { GoogleButton } from "@app/components/login/google-button/google-button.component";
import { LinkLoginProvidersProps } from "@app/context/auth/login/login.context";
import { CustomableLoginBottomSheetProps } from "@app/context/auth/login/login.type";
import { useBranchContext } from "@app/context/branch/branch.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { isAndroid, isIos, isWeb } from "@app/utils/device.util";
import { reportError } from "@app/utils/logger/logger.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { DownloadApp } from "../download-app/download-app.component";

import { styles } from "./login.style";

interface Props extends Pick<CustomableLoginBottomSheetProps, "buttonMode" | "anonymousLogin"> {
  showDownloadApp: boolean;
  hideDownloadApp: () => void;
  goToEnterEmail: () => void;
  goToCheckEmail: (email: string) => void;
  openLinkProvidersPrompt: (props: LinkLoginProvidersProps) => void;
}

export const Login: FC<Props> = ({
  showDownloadApp,
  hideDownloadApp,
  goToEnterEmail,
  goToCheckEmail,
  openLinkProvidersPrompt,
  anonymousLogin,
  buttonMode = "contained",
}) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const [loading, setLoading] = useState(false);
  const [deepLink, setDeepLink] = useState<string | undefined>(isWeb ? window.location.href : undefined);

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const { isReady: isBranchReady, generateDeepLink } = useBranchContext();

  const isEmbedded = isLikelyInEmbeddedWebview();

  const shouldGenerateDeeplink = isEmbedded && isWeb && isIos && isBranchReady;

  useEffect(() => {
    if (shouldGenerateDeeplink) {
      // TODO: Check if we could also pass the location search
      const currentPath = window.location.pathname;
      generateDeepLink(currentPath)
        .then(generatedLink => setDeepLink(generatedLink))
        .catch(error => reportError(error as Error));
    }
  }, [generateDeepLink, shouldGenerateDeeplink]);

  const handleOpenInExternalBrowser = (): void => {
    if (!isWeb) return;

    const currentUrl = window.location.href;
    window.open(`intent:${currentUrl}#Intent;end`, "_blank");
  };

  const handleDeepLinkError = (error: Error): void => {
    setLoading(false);
    showErrorSnackbar({ error, message: error.message });
  };

  const handleCopyLink = (): void => {
    if (!isWeb || !deepLink) return;

    setLoading(true);

    Clipboard.setStringAsync(deepLink)
      .then(() => {
        setLoading(false);
        showSuccessSnackbar({ message: t("success.linkCopied") });
      })
      .catch(handleDeepLinkError);
  };

  if (showDownloadApp) return <DownloadApp onSignIn={hideDownloadApp} />;

  const commonSSOLoginProviderProps: Pick<IconButtonProps, "style" | "iconColor"> = {
    iconColor: buttonMode === "outlined" ? colors.onBackground : undefined,
    style: [
      styles.ssoShareButton,
      buttonMode === "outlined"
        ? { borderWidth: 1, borderColor: colors.action.active, borderRadius: roundness }
        : { backgroundColor: colors.primary, borderRadius: roundness },
    ],
  };

  return isEmbedded ? (
    <View style={[styles.container, styles.fullWidth]}>
      <View style={styles.mainLoginProvider}>
        <EmailButton goToEnterEmail={goToEnterEmail} fullWidth size="large" mode="contained" />
      </View>

      <View style={[styles.orContainer, styles.fullWidth]}>
        <Divider style={styles.orDivider} />
        <Text style={styles.orText}>{t("login.or")}</Text>
        <Divider style={styles.orDivider} />
      </View>

      <View style={styles.embeddedContainer}>
        <EmbeddedAlert variant={AlertVariant.info} text={t("inAppBrowser.alert")} />

        {isAndroid && (
          <Button
            mode="contained"
            size="large"
            icon={ArrowRight}
            onPress={handleOpenInExternalBrowser}
            contentStyle={styles.reversedButton}
            fullWidth>
            {t("inAppBrowser.cta", { context: "android" })}
          </Button>
        )}
        {isIos && (
          <Button mode="outlined" size="large" icon={CopySimple} loading={loading} onPress={handleCopyLink} fullWidth>
            {t("inAppBrowser.cta", { context: "ios" })}
          </Button>
        )}
      </View>
    </View>
  ) : (
    <View style={[styles.container, styles.fullWidth]}>
      <View style={styles.mainLoginProvider}>
        <GoogleButton {...commonSSOLoginProviderProps} />
        <AppleButton {...commonSSOLoginProviderProps} />
        <FacebookButton {...commonSSOLoginProviderProps} goToCheckEmail={goToCheckEmail} openLinkProvidersPrompt={openLinkProvidersPrompt} />
      </View>

      <EmailButton goToEnterEmail={goToEnterEmail} fullWidth size="large" mode={buttonMode} testID="signin-email-button" />

      {anonymousLogin?.visible && (
        <>
          <View style={[styles.orContainer, styles.fullWidth]}>
            <Divider style={styles.orDivider} />
            <Text style={styles.orText}>{t("login.or")}</Text>
            <Divider style={styles.orDivider} />
          </View>

          <Button fullWidth size="large" mode="outlined" onPress={anonymousLogin.onPress}>
            {t("login.anonymous")}
          </Button>
        </>
      )}
    </View>
  );
};
