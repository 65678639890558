import React, { FC, useState } from "react";

import { Tote } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";

import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useBuyNowCheckout } from "@app/hooks/api/use-buy-now-checkout.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { PickVariantBanner } from "../pick-variant-banner/pick-variant-banner.component";

interface Props extends Omit<ButtonProps, "children"> {
  variantId?: string;
  inventoryQuantity?: number;
  price?: number;
  quantity: number;
  allVariantsSoldOut?: boolean;
  onPressStart?: () => void;
}

export const BuyNowButton: FC<Props> = ({ variantId, inventoryQuantity, price, quantity, allVariantsSoldOut, onPressStart, loading, ...props }) => {
  const { buyNow, loading: loadingBuyNow } = useBuyNowCheckout();
  const { showErrorSnackbar } = useSnackbarContext();
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();

  const [pickVariantBannerVisible, setPickVariantBannerVisible] = useState(false);

  const isLoading = loadingBuyNow || loading;
  const soldOut = (!isLoading && !!variantId && inventoryQuantity !== undefined && inventoryQuantity <= 0) || allVariantsSoldOut;

  const handleBuyNow = (): void => {
    if (!variantId) {
      setPickVariantBannerVisible(true);
      return;
    }

    onPressStart?.();
    void buyNow({
      variables: { input: { variantId, quantity } },
      onCompleted: data => navigateToWebview(data.buyNowCheckout.checkoutUrl, t("cta.checkout")),
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <>
      {!soldOut && (
        <Button
          mode="outlined"
          size="large"
          icon={iconProps => <Tote {...iconProps} weight="fill" />}
          fullWidth
          loading={isLoading}
          onPress={handleBuyNow}
          {...props}>
          <Trans i18nKey="cta.confirmWithPrice" values={{ price: price != null ? formatPriceUSD(price * quantity) : undefined }} />
        </Button>
      )}

      <PickVariantBanner bannerVisible={pickVariantBannerVisible} setBannerVisible={setPickVariantBannerVisible} />
    </>
  );
};
