import { Progress, Stage } from "@app/common/graphql/generated/schema.graphql";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { isAdmin } from "@app/utils/user.util";

export interface StartCampaignProps {
  creatorId: string;
  progress: Progress;
}

interface StartCampaignButtonUtils {
  isStartCampaignButtonVisible: (props?: StartCampaignProps) => boolean;
}

export function useStartCampaignButton(): StartCampaignButtonUtils {
  const { profile: currentProfile } = useProfileContext();
  const { state: authState } = useAuthContext();

  const isAllowedUser = (creatorId: string): boolean => {
    const ownedByCurrentUser = currentProfile?.userId === creatorId;
    return ownedByCurrentUser || isAdmin(authState);
  };

  const isStartCampaignButtonVisible = (props?: StartCampaignProps): boolean => {
    if (!props) return false;

    const isAllowedConceptProgress = props.progress.canRequestQuote || props.progress.waitingForQuote || props.progress.canStartCampaign;

    return isAllowedUser(props.creatorId) && props.progress.stage === Stage.concept && isAllowedConceptProgress;
  };

  return { isStartCampaignButtonVisible };
}
