import { createContext, useContext } from "react";

import { mockContextFn } from "@app/utils/context.util";

import { OnboardingContextInterface } from "./onboarding.type";

export const initialContextValue: OnboardingContextInterface = {
  onboardingStepsState: [],
  showOnboarding: false,
  initialStep: undefined,
  showLoginScreen: false,
  numberOfStepsToShow: 0,
  currentStepIndex: 0,
  onOnboardingStepCompleted: _route => mockContextFn("OnboardingContext", "onOnboardingStepCompleted"),
  refreshOnboardingStepsState: () => mockContextFn("OnboardingContext", "refreshOnboardingStepsState"),
  setHideLoginScreen: () => mockContextFn("OnboardingContext", "setHideLoginScreen"),
};

export const OnboardingContext = createContext<OnboardingContextInterface>(initialContextValue);

export const useOnboardingContext = (): OnboardingContextInterface => useContext(OnboardingContext);
