import { FetchResult, gql, MutationFunctionOptions, OperationVariables, useMutation } from "@apollo/client";

import { coreCollabFields, coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { ShareableEntity, TrackSharingInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface TrackSharingResponse {
  trackSharing: ShareableEntity;
}

interface Variables {
  input: TrackSharingInput;
}

export interface TrackSharingResult<TVariables extends OperationVariables> extends MutationResult<TrackSharingResponse, "trackSharing"> {
  trackSharing: (options?: MutationFunctionOptions<TrackSharingResponse, TVariables>) => Promise<FetchResult>;
}

const trackSharingMutation = gql`
  mutation TrackSharing($input: TrackSharingInput!) {
    trackSharing(input: $input) {
      ... on Collab {
        ...CoreCollabFields
      }
      ... on Profile {
        ...CoreProfileFields
      }
    }
  }
  ${coreCollabFields}
  ${coreProfileFields}
`;

export function useTrackSharing(): TrackSharingResult<Variables> {
  const [trackSharing, { loading, error, data }] = useMutation<TrackSharingResponse, Variables>(trackSharingMutation);

  return { trackSharing, loading, error, data: data?.trackSharing };
}
