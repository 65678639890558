import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { CollabMediaContent } from "@app/common/graphql/generated/schema.graphql";
import { AnyCollab } from "@app/common/types/collab.type";
import { useNavigation } from "@app/common/types/navigation.type";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { ProductDetails } from "@app/components/products/product-details/product-details.component";
import { ProductImages } from "@app/components/products/product-images/product-images.component";
import { getMedia } from "@app/utils/product-media.util";

import { ProductSideIconButtons } from "../product-side-icon-buttons/product-side-icon-buttons.component";

import { styles } from "./product.style";

interface Props {
  product?: AnyCollab;
}

export const Product: FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [currentMedia, setCurrentMedia] = useState<CollabMediaContent | undefined>(getMedia(product)?.[0]);

  useEffect(() => {
    if (!product) return;
    navigation.setOptions({
      title: t(`enum.stage.${product.progress.stage}`),
    });
  }, [navigation, product, t]);

  const contentOnImage = product && (
    <View style={styles.imageContentContainer}>
      <ProductSideIconButtons product={product} currentMedia={currentMedia} context="fullScreen" />
    </View>
  );

  return (
    <View>
      <ProductImages
        collabId={product?.collabId ?? ""}
        media={getMedia(product) ?? []}
        loading={!product}
        setCurrentMedia={setCurrentMedia}
        contentOnImage={contentOnImage}
      />

      {product ? (
        <ProductDetails product={product} />
      ) : (
        <View style={styles.creatorSectionSkeleton}>
          <Skeleton radius="round" height={40} width={40} />
          <Skeleton radius="round" height={20} width="90%" />
        </View>
      )}
    </View>
  );
};
