import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  animationContainer: {
    height: 120,
    width: 120,
  },
  centeredContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    rowGap: 16,
  },
  flexBox: {
    flex: 1,
  },
  legendContainer: {
    justifyContent: "flex-end",
    rowGap: 24,
  },
  loadingContainer: {
    flex: 2,
  },
});
