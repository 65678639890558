import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  checkboxContainer: {
    padding: 8,
  },
  contentContainerListItem: {
    columnGap: 12,
  },
  counter: {
    borderRadius: 64,
  },
  rowIconText: {
    alignItems: "center",
    columnGap: 12,
    flexDirection: "row",
    flex: 1,
  },
  textCounter: {
    marginLeft: 7,
    marginRight: 7,
    marginVertical: 0,
  },
});
