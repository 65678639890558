import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  priceContainer: {
    rowGap: 6,
  },
  spaceBetweenText: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
