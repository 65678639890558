import React, { FC, ReactElement, useState } from "react";

import { REACT_APP_STRIPE_PUBLISHABLE_KEY } from "@env";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTheme } from "react-native-paper";

interface Props {
  children: ReactElement;
}

export const WrappedStripeProvider: FC<Props> = ({ children }) => {
  const { dark } = useTheme();

  const [stripePromise] = useState(loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY));

  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: "setup", currency: "usd", loader: "always", appearance: { theme: dark ? "night" : undefined } }}>
      {children}
    </Elements>
  );
};
