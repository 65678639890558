import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  listItem: {
    paddingVertical: 8,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    rowGap: 16,
  },
});
