import React, { forwardRef } from "react";

import { StyleProp, ViewStyle } from "react-native";

import { StudioAnimation } from "@app/common/graphql/generated/schema.graphql";
import { StudioImageWithIndex } from "@app/common/types/studio.type";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { GenerationTaskType } from "@app/context/studio-mini/studio-mini.context";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./bottom-sheet-prompt-set.style";
import { StudioImageActions } from "./studio-image-actions/studio-image-actions.component";

interface Props {
  generationType: GenerationTaskType;
  selectedImages: StudioImageWithIndex[];
  selectedVideo?: StudioAnimation;
  onGeneratePoses?: () => Promise<void>;
  onGenerateVariations?: () => Promise<void>;
  onSave: () => Promise<void>;
  onDismiss: () => void;
  onClose: () => void;
  handleStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

export const BottomSheetPromptSet = forwardRef<BottomSheetRefProps, Props>(
  (
    {
      generationType,
      selectedImages,
      selectedVideo,
      onGenerateVariations,
      onGeneratePoses,
      onSave,
      onDismiss,
      onClose,
      handleStyle,
      contentContainerStyle,
    },
    ref,
  ) => {
    return (
      <BottomSheet
        ref={ref}
        onDismiss={onDismiss}
        backdropComponent={null}
        handleStyle={handleStyle}
        contentContainerStyle={contentContainerStyle}
        style={isWeb ? styles.bottomSheetBg : undefined}>
        <StudioImageActions
          generationType={generationType}
          onClose={onClose}
          selectedImages={selectedImages}
          selectedVideo={selectedVideo}
          onGenerateVariations={onGenerateVariations}
          onSave={onSave}
          onGeneratePoses={onGeneratePoses}
        />
      </BottomSheet>
    );
  },
);

BottomSheetPromptSet.displayName = "BottomSheetPromptSet";
