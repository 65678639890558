import { ImageSource, StudioAnimation, StudioImage, StudioImageStatus, StudioMedia } from "@app/common/graphql/generated/schema.graphql";
import { ImageToBeUsed } from "@app/common/types/image.type";

export function isStudioImage(media?: StudioMedia): media is StudioImage {
  return media?.__typename === "StudioImage";
}

export function isStudioVideo(media?: StudioMedia): media is StudioAnimation {
  return media?.__typename === "StudioAnimation";
}

export function getStudioMediaId(media: StudioMedia): string {
  if (isStudioImage(media)) return media.imageId;
  return media.studioAnimationId;
}

export function getStudioMediaUrl(media: StudioMedia): string | undefined {
  if (isStudioImage(media)) return media.imageUrl;
  if (isStudioVideo(media)) return media.thumbnailUrl;

  return undefined;
}

export function getStudioImageFromImageToBeUsed(image: ImageToBeUsed): StudioImage {
  return {
    ...image,
    __typename: "StudioImage",
    isNsfw: false,
    isExploreImage: false,
    source: image.imageSource === "upload" ? ImageSource.upload : ImageSource.studioPrompt,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    status: StudioImageStatus.permanent,
  };
}
