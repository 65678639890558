import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { commentFields } from "@app/common/graphql/fragments.graphql";
import { Comment, CreateCommentInput, PaginatedComments } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  createComment: Comment;
}

interface Variables {
  collabId: string;
  input: CreateCommentInput;
  userId: string;
}

interface CreateCommentResult extends MutationResult<Response, "createComment"> {
  createComment: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createCommentMutation = gql`
  mutation CreateComment($collabId: CollabId!, $input: CreateCommentInput!, $userId: UserId!) {
    createComment(collabId: $collabId, input: $input, userId: $userId) {
      ...CommentFields
    }
  }
  ${commentFields}
`;

export function useCreateComment(): CreateCommentResult {
  const [createComment, { loading, error, data }] = useMutation<Response, Variables>(createCommentMutation, {
    update(cache, results, options) {
      const collabId = options.variables?.collabId ?? "";

      cache.modify({
        fields: {
          comments: (existingComments: PaginatedComments, { storeFieldName, readField, toReference }) => {
            const newCommentId = results.data?.createComment.commentId;

            if (!storeFieldName.includes(collabId)) return existingComments;

            const alreadyExist = existingComments.nodes?.findIndex(comment => readField("commentId", comment) === newCommentId) !== -1;
            if (!newCommentId || alreadyExist) return existingComments;

            return {
              ...existingComments,
              nodes: [toReference(newCommentId), ...(existingComments.nodes ?? [])],
              totalCount: existingComments.totalCount + 1,
            };
          },
        },
      });

      cache.modify({
        id: collabId,
        fields: {
          numberOfComments: (currentCount: number) => currentCount + 1,
        },
      });
    },
  });

  return { createComment, loading, error, data: data?.createComment };
}
