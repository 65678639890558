import { useHeaderHeight } from "@react-navigation/elements";

import { bottomTabsHeight } from "@app/common/style/theme";

import { useWindowDimensions } from "./use-window-dimensions.hook";

export function useContentDimensions(withTabBar = false): { height: number; width: number } {
  const headerHeight = useHeaderHeight();
  const { height: windowHeight, width } = useWindowDimensions();

  const computedHeight = windowHeight - headerHeight - (withTabBar ? bottomTabsHeight : 0);

  return { height: computedHeight, width };
}
