import React, { FC } from "react";

import { formatDistanceToNowStrict } from "date-fns";
import { Clock } from "phosphor-react-native";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Notification } from "@app/common/graphql/generated/schema.graphql";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Box } from "@app/components/common/box/box.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useNotificationProps } from "@app/hooks/utils/notifications/use-notification-props.hook";

import { styles } from "./notification-list-item.style";

interface Props {
  notification: Notification;
}

export const NotificationListItem: FC<Props> = ({ notification }) => {
  const { colors } = useTheme();

  const notificationProps = useNotificationProps(notification);

  return notificationProps ? (
    <ListItem
      title={notificationProps.title}
      titleProps={{ variant: "body2", numberOfLines: undefined, ...notificationProps.titleProps }}
      subtitle={notificationProps.subtitle}
      subtitleProps={{ variant: "caption", color: "tertiary", numberOfLines: undefined }}
      description={
        <>
          <View style={styles.timeContainer}>
            <Clock weight="thin" size={16} color={colors.onBackground} />
            <Text variant="caption" color="tertiary">
              {formatDistanceToNowStrict(new Date(notificationProps.timestamp), { addSuffix: true })}
            </Text>
          </View>
          {notificationProps.button}
        </>
      }
      left={<View style={styles.leftIcon}>{notificationProps.leftIcon}</View>}
      right={
        notificationProps.rightImage ? (
          <AvatarImage key={notification.notificationId} image={notificationProps.rightImage} size={40} fallbackText="" style={styles.rightIcon} />
        ) : (
          <Box width={40} />
        )
      }
      onPress={notificationProps.onPress}
      contentContainerStyle={styles.contentContainer}
      style={styles.root}
    />
  ) : null;
};
