import React, { FC } from "react";

import { MagnifyingGlass, XCircle } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Searchbar as RNSearchbar, SearchbarProps, useTheme } from "react-native-paper";

import { styles } from "./search-bar.style";

export const SearchBar: FC<SearchbarProps> = ({ style, inputStyle, ...searchBarProps }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <RNSearchbar
      testID="search-bar"
      placeholder={t("cta.search")}
      icon={props => <MagnifyingGlass {...props} />}
      placeholderTextColor={colors.tertiary}
      autoCapitalize="none"
      autoCorrect={false}
      clearIcon={props => <XCircle {...props} weight="fill" />}
      iconColor={colors.primary}
      style={[{ backgroundColor: colors.background }, styles.defaultHeight, style]}
      inputStyle={[styles.defaultHeight, { color: colors.primary }, inputStyle]}
      {...searchBarProps}
    />
  );
};
