import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationMoveCollabToDevelopmentFailedArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  moveCollabToDevelopmentFailed: Collab;
}

interface MoveCollabToDevelopmentFailedResult extends MutationResult<Response, "moveCollabToDevelopmentFailed"> {
  moveCollabToDevelopmentFailed: (options?: MutationFunctionOptions<Response, MutationMoveCollabToDevelopmentFailedArgs>) => Promise<FetchResult>;
}

const moveCollabToDevelopmentFailedMutation = gql`
  mutation MoveCollabToDevelopmentFailed($collabId: CollabId!) {
    moveCollabToDevelopmentFailed(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useMoveCollabToDevelopmentFailed(): MoveCollabToDevelopmentFailedResult {
  const [moveCollabToDevelopmentFailed, { loading, error, data }] = useMutation<Response, MutationMoveCollabToDevelopmentFailedArgs>(
    moveCollabToDevelopmentFailedMutation,
  );

  return { moveCollabToDevelopmentFailed, loading, error, data: data?.moveCollabToDevelopmentFailed };
}
