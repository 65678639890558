import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { SetReferrerUserInput, SuccessfulReferral } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setReferrerUser: SuccessfulReferral;
}

interface Variables {
  input: SetReferrerUserInput;
  userId: string;
}

interface SetReferrerUserResult extends MutationResult<Response, "setReferrerUser"> {
  setReferrerUser: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const setReferrerUserMutation = gql`
  mutation SetReferrerUser($input: SetReferrerUserInput!, $userId: UserId!) {
    setReferrerUser(input: $input, userId: $userId) {
      referrerUserId
    }
  }
`;

export function useSetReferrerUser(): SetReferrerUserResult {
  const [setReferrerUser, { loading, error, data }] = useMutation<Response, Variables>(setReferrerUserMutation);

  return { setReferrerUser, loading, error, data: data?.setReferrerUser };
}
