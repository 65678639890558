import React, { FC, useCallback } from "react";

import { FlatList, ListRenderItem } from "react-native";

import { CardPaymentMethod } from "@app/common/graphql/generated/schema.graphql";
import { isWeb } from "@app/utils/device.util";

import { PaymentMethodListItem } from "./payment-method-list-item/payment-method-list-item.component";
import { styles } from "./payment-method-list.style";
import { PaymentMethodListMode, PaymentMethodListModeProps } from "./payment-method-list.types";

interface CommonProps {
  mode: PaymentMethodListMode;
  paymentMethods: CardPaymentMethod[];
}

export type PaymentMethodList = PaymentMethodListModeProps<CommonProps>;

export const PaymentMethodList: FC<PaymentMethodList> = ({ paymentMethods, ...itemProps }) => {
  const renderPaymentMethod = useCallback<ListRenderItem<CardPaymentMethod>>(
    ({ item: paymentMethod }) => {
      return <PaymentMethodListItem paymentMethod={paymentMethod} {...itemProps} />;
    },
    [itemProps],
  );

  return (
    <FlatList
      data={paymentMethods}
      renderItem={renderPaymentMethod}
      contentContainerStyle={styles.flatListContentContainer}
      showsVerticalScrollIndicator={false}
      scrollEnabled={isWeb}
    />
  );
};
