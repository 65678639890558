import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabWithMediaFields } from "@app/common/graphql/fragments.graphql";
import { CollabWithMedia, UpdateCollabWithMediaInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCollab: CollabWithMedia;
}

interface Variables {
  input: UpdateCollabWithMediaInput;
  collabId: string;
}

interface UpdateCollabResult extends MutationResult<Response, "updateCollab"> {
  updateCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCollabWithMediaMutation = gql`
  mutation UpdateCollabWithMedia($collabId: CollabId!, $input: UpdateCollabWithMediaInput!) {
    updateCollabWithMedia(collabId: $collabId, input: $input) {
      ...CollabWithMediaFields
    }
  }
  ${collabWithMediaFields}
`;

export function useUpdateCollabWithMedia(): UpdateCollabResult {
  const [updateCollab, { loading, error, data }] = useMutation<Response, Variables>(updateCollabWithMediaMutation);

  return { updateCollab, loading, error, data: data?.updateCollab };
}
