import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { CommentLike, UnlikeCommentInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  unlikeComment: CommentLike;
}

interface Variables {
  input: UnlikeCommentInput;
  userId: string;
}

interface UnlikeCommentResult extends MutationResult<Response, "unlikeComment"> {
  unlikeComment: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const unlikeCommentMutation = gql`
  mutation UnlikeComment($input: UnlikeCommentInput!, $userId: UserId!) {
    unlikeComment(input: $input, userId: $userId) {
      commentId
      userId
    }
  }
`;

export function useUnlikeComment(): UnlikeCommentResult {
  const [unlikeComment, { loading, error, data }] = useMutation<Response, Variables>(unlikeCommentMutation, {
    update: (cache, _results, options) => {
      cache.modify({
        id: options.variables?.input.commentId,
        fields: {
          liked: () => false,
          likeCount: (existingCount: number) => existingCount - 1,
        },
      });
    },
  });

  return { unlikeComment, loading, error, data: data?.unlikeComment };
}
