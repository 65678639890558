import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { catalogProductFields } from "@app/common/graphql/fragments.graphql";
import { CatalogProductsFilteringArgs, PaginatedCatalogProduct } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface Response {
  catalogProducts: PaginatedCatalogProduct;
}

export interface Variables {
  first?: number;
  offset?: number;
  where?: CatalogProductsFilteringArgs;
}

const catalogProductsQuery = gql`
  query CatalogProducts($first: Int, $offset: Int, $where: CatalogProductsFilteringArgs) {
    catalogProducts(first: $first, offset: $offset, where: $where) {
      nodes {
        ...CatalogProductFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${catalogProductFields}
`;

export function useCatalogProducts(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "catalogProducts"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(catalogProductsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.catalogProducts, loading, error, networkStatus, fetchMore, refetch };
}
