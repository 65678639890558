import React, { FC, useState } from "react";

import { Trans } from "react-i18next";

import { Button } from "@app/components/common/button/button.component";
import { useCartContext } from "@app/context/cart/cart.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUpdateCartItem } from "@app/hooks/api/products/use-update-cart-item.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { PickVariantBanner } from "../../../products/pick-variant-banner/pick-variant-banner.component";

interface Props {
  cartItemId: string;
  variantId?: string;
  inventoryQuantity?: number;
  price?: number;
  quantity: number;
}

export const AssignVariantToCartItemButton: FC<Props> = ({ cartItemId, variantId, inventoryQuantity, price, quantity }) => {
  const { showErrorSnackbar } = useSnackbarContext();
  const [pickVariantBannerVisible, setPickVariantBannerVisible] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const { loadingCartIdentity } = useCartContext();
  const { updateCartItem } = useUpdateCartItem();

  const isLoading = loadingCartIdentity;
  const soldOut = !isLoading && !!variantId && inventoryQuantity !== undefined && inventoryQuantity <= 0;

  const isDisabled = soldOut || isLoading || (inventoryQuantity !== undefined && quantity > 0 && quantity > inventoryQuantity);

  const handleSubmit = (): void => {
    if (!variantId) {
      setPickVariantBannerVisible(true);
      return;
    }

    void updateCartItem({
      variables: { cartItemId, input: { variantId, quantity } },
      onError: error => showErrorSnackbar({ error }),
    });
    setIsUpdated(true);
  };

  return (
    <>
      <Button
        mode="contained"
        size="large"
        color={isUpdated ? "success" : "primary"}
        fullWidth
        loading={isLoading}
        disabled={isDisabled}
        onPress={handleSubmit}>
        {isUpdated ? (
          <Trans i18nKey="cart.cartItemUpdated" />
        ) : soldOut ? (
          <Trans i18nKey="cart.soldOut" />
        ) : (
          <Trans i18nKey="cta.confirmWithPrice" values={{ price: price != null ? formatPriceUSD(price * quantity) : undefined }} />
        )}
      </Button>

      <PickVariantBanner bannerVisible={pickVariantBannerVisible} setBannerVisible={setPickVariantBannerVisible} />
    </>
  );
};
