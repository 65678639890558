import { useCallback, useState } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { useIsFocused } from "@react-navigation/native";

import {
  Collab,
  ContestResult,
  Promo,
  Notification,
  Profile,
  StudioImage,
  Comment,
  CollabUpdate,
  MilestoneProgress,
  BehindTheScenesVideo,
  FeedEntity,
  StudioAnimation,
} from "@app/common/graphql/generated/schema.graphql";
import { PaginatedResponse } from "@app/common/types/apollo-result.type";
import { FetchMoreArgs, FetchVariables } from "@app/common/types/fetch-more-args.type";

interface FetchMoreItemsUtils<TResponse> {
  fetchMoreItems: (fetchVars: FetchVariables) => Promise<ApolloQueryResult<TResponse> | undefined>;
  resetOffset: () => void;
}

export function useFetchMoreItems<
  TResponse extends PaginatedResponse<
    keyof TResponse,
    | ContestResult
    | Collab
    | FeedEntity
    | Promo
    | Notification
    | Profile
    | StudioImage
    | Comment
    | CollabUpdate
    | MilestoneProgress
    | BehindTheScenesVideo
    | StudioAnimation
  >,
  TKey extends keyof TResponse,
>(
  itemsFieldName: TKey,
  fetchMore?: (args: FetchMoreArgs<TResponse>) => Promise<ApolloQueryResult<TResponse>>,
  batchSize?: number,
): FetchMoreItemsUtils<TResponse> {
  const isFocused = useIsFocused();
  const [offset, setOffset] = useState(batchSize ?? 0);

  const fetchMoreItems = useCallback(
    async (fetchVars: FetchVariables): Promise<ApolloQueryResult<TResponse> | undefined> => {
      if (!fetchMore || !isFocused) return undefined;

      return await fetchMore({
        variables: batchSize ? { ...fetchVars, first: batchSize, offset } : fetchVars,
        updateQuery: (previousResult: TResponse, options: { fetchMoreResult: TResponse }) => {
          if (batchSize) {
            setOffset(previousOffset => previousOffset + batchSize);
          }

          const newNodes = options.fetchMoreResult[itemsFieldName].nodes;

          return {
            [itemsFieldName]: {
              ...options.fetchMoreResult[itemsFieldName],
              pageInfo:
                !newNodes?.length && !batchSize && "offset" in fetchVars
                  ? { ...options.fetchMoreResult[itemsFieldName].pageInfo, hasNextPage: false }
                  : options.fetchMoreResult[itemsFieldName].pageInfo,
              nodes: [...(previousResult?.[itemsFieldName].nodes ?? []), ...(newNodes ?? [])],
            },
          } as unknown as TResponse;
        },
      });
    },
    [batchSize, fetchMore, isFocused, itemsFieldName, offset],
  );

  const resetOffset = useCallback((): void => setOffset(batchSize ?? 0), [batchSize]);

  return { fetchMoreItems, resetOffset };
}
