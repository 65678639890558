import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFacetsFields, collabFields } from "@app/common/graphql/fragments.graphql";
import { SearchCollabsFilteringArgs, SearchCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

import { CollabsResponse } from "./use-collabs.hook";

export interface SearchCollabsVariables {
  first?: number;
  offset?: number;
  query: string;
  sortBy?: SearchCollabsSortingMethod;
  where?: SearchCollabsFilteringArgs;
}

const searchCollabsQuery = gql`
  query SearchCollabs($first: Int, $offset: Int, $query: String!, $sortBy: SearchCollabsSortingMethod, $where: SearchCollabsFilteringArgs) {
    collabs: searchCollabs(first: $first, offset: $offset, query: $query, sortBy: $sortBy, where: $where) {
      facets {
        ...CollabFacetsFields
      }
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFacetsFields}
  ${collabFields}
`;

export function useSearchCollabs(options: QueryHookOptions<CollabsResponse, SearchCollabsVariables>): QueryResult<CollabsResponse, "collabs"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<CollabsResponse, SearchCollabsVariables>(searchCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.collabs, loading, error, networkStatus, fetchMore, refetch };
}
