import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  footer: {
    marginHorizontal: 20,
    marginTop: 12,
  },
  nested: {
    marginHorizontal: 20,
  },
  sheetContainer: {
    paddingHorizontal: 0,
  },
});
