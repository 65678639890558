import React, { FC } from "react";

import { AppStoreLogo, GooglePlayLogo } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Linking, StyleProp, View, ViewStyle } from "react-native";

import { links } from "@app/common/constants/links.const";
import { isAndroid, isIos } from "@app/utils/device.util";
import { assertNever } from "@app/utils/runtime.util";

import { Button, ButtonProps } from "../button/button.component";

import { styles } from "./open-store-buttons.style";

interface Props {
  commonButtonProps?: Omit<ButtonProps, "children">;
  style?: StyleProp<ViewStyle>;
}

export const OpenStoreButtons: FC<Props> = ({ commonButtonProps, style }) => {
  const { t } = useTranslation();

  const handleOpenStore = (platform: "ios" | "android") => () => {
    switch (platform) {
      case "ios":
        void Linking.openURL(links.external.appStore);
        break;
      case "android":
        void Linking.openURL(links.external.playStore);
        break;
      default:
        assertNever(platform);
    }
  };

  const OpenAppleStoreButton: FC = () => (
    <Button mode="contained" onPress={handleOpenStore("ios")} icon={AppStoreLogo} contentStyle={styles.button} {...commonButtonProps}>
      {t("share.open")}
    </Button>
  );

  const OpenGooglePlayButton: FC = () => (
    <Button mode="contained" onPress={handleOpenStore("android")} icon={GooglePlayLogo} contentStyle={styles.button} {...commonButtonProps}>
      {t("share.open")}
    </Button>
  );

  if (isIos) return <OpenAppleStoreButton />;

  if (isAndroid) return <OpenGooglePlayButton />;

  return (
    <View style={[styles.root, style]}>
      <OpenAppleStoreButton />
      <OpenGooglePlayButton />
    </View>
  );
};
