import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { CollabUpdateLike, UnlikeCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  unlikeCollabUpdate: CollabUpdateLike;
}

interface Variables {
  input: UnlikeCollabUpdateInput;
  userId: string;
}

interface UnlikeCollabUpdateResult extends MutationResult<Response, "unlikeCollabUpdate"> {
  unlikeCollabUpdate: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const unlikeCollabUpdateMutation = gql`
  mutation UnlikeCollabUpdate($input: UnlikeCollabUpdateInput!, $userId: UserId!) {
    unlikeCollabUpdate(input: $input, userId: $userId) {
      collabUpdateId
      userId
    }
  }
`;

export function useUnlikeCollabUpdate(): UnlikeCollabUpdateResult {
  const [unlikeCollabUpdate, { loading, error, data }] = useMutation<Response, Variables>(unlikeCollabUpdateMutation, {
    update: (cache, _results, options) => {
      cache.modify({
        id: options.variables?.input.collabUpdateId,
        fields: {
          liked: () => false,
          likeCount: (existingCount: number) => existingCount - 1,
        },
      });
    },
  });

  return { unlikeCollabUpdate, loading, error, data: data?.unlikeCollabUpdate };
}
