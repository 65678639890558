import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { commentFields } from "@app/common/graphql/fragments.graphql";
import { Comment, PaginatedComments } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { isCollabId } from "@app/utils/id.util";

interface Response {
  deleteComment: Comment;
}

interface Variables {
  commentId: string;
}

interface DeleteCommentResult extends MutationResult<Response, "deleteComment"> {
  deleteComment: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCommentMutation = gql`
  mutation DeleteComment($commentId: CommentId!) {
    deleteComment(commentId: $commentId) {
      ...CommentFields
    }
  }
  ${commentFields}
`;

export function useDeleteComment(parentId: string): DeleteCommentResult {
  const collabId = isCollabId(parentId) ? parentId : undefined;

  const [deleteComment, { loading, error, data }] = useMutation<Response, Variables>(deleteCommentMutation, {
    update: (cache, _results, options) => {
      cache.evict({ id: options.variables?.commentId });

      if (collabId) {
        cache.modify({
          fields: {
            comments: (existingComments: PaginatedComments, { storeFieldName }) => {
              if (!storeFieldName.includes(collabId)) return existingComments;

              return {
                ...existingComments,
                totalCount: existingComments.totalCount - 1,
              };
            },
          },
        });

        cache.modify({
          id: collabId,
          fields: {
            numberOfComments: (currentCount: number) => currentCount - 1,
          },
        });
      } else {
        cache.modify({
          fields: {
            collabUpdateComments: (existingComments: PaginatedComments, { storeFieldName }) => {
              if (!storeFieldName.includes(parentId)) return existingComments;

              return {
                ...existingComments,
                totalCount: existingComments.totalCount - 1,
              };
            },
          },
        });

        cache.modify({
          id: parentId,
          fields: {
            numberOfComments: (currentCount: number) => currentCount - 1,
          },
        });
      }
    },
  });

  return { deleteComment, loading, error, data: data?.deleteComment };
}
