import React, { FC, useEffect } from "react";

import { NetworkStatus } from "@apollo/client";
import { useIsFocused } from "@react-navigation/native";
import { View } from "react-native";
import { ActivityIndicator, Portal, useTheme } from "react-native-paper";

import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { FeedStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { NotificationButton } from "@app/components/common/notification-button/notification-button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { SupercoinButton } from "@app/components/common/supercoin-button/supercoin-button.component";
import { ProductStack } from "@app/components/products/product-stack/product-stack.component";
import { SwipeOnboardingCard } from "@app/components/products/swipe-onboarding-card/swipe-onboarding-card.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useFeedContext } from "@app/context/feed/feed.context";
import { usePopperContext } from "@app/context/popper/popper.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useHandleReferral } from "@app/hooks/utils/use-handle-referral.hook";

import { styles } from "./feed.style";

export const FeedScreen: FC<FeedStackScreenProps<Routes.feed>> = ({ navigation }) => {
  const { colors } = useTheme();
  const isFocused = useIsFocused();

  const { state } = useAuthContext();
  const { profile, state: userState, loading: loadingUserState } = useProfileContext();

  const { feed } = useFeedContext();
  const {
    feedResult: { data, loading, networkStatus },
  } = feed;

  const {
    goalsPopperState: [_goalsPopperOpen, setGoalsPopperOpen],
  } = usePopperContext();

  useHandleReferral();

  const swipeOnboardingVisible = !!profile && !loadingUserState && !userState?.[UserStateEnum.swipeOnboardingCompleted] && state.connected;

  useEffect(() => {
    if (!isFocused || loadingUserState) return undefined;

    let timeout: NodeJS.Timeout;
    if (userState?.[UserStateEnum.swipeOnboardingCompleted] === true && !userState?.[UserStateEnum.goalPoppersDismissed]) {
      timeout = setTimeout(() => {
        setGoalsPopperOpen(true);
      }, 1000);
    }

    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [isFocused, loadingUserState, setGoalsPopperOpen, userState]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <OffscriptLogo height={40} width={120} color={colors.onBackground} />,
      headerRight: () => (
        <View style={styles.row}>
          <NotificationButton onButtonPress={() => navigation.navigate(Routes.notifications)} />
          <SupercoinButton />
        </View>
      ),
    });
  }, [colors.onBackground, navigation]);

  return (
    <>
      <ScreenWrapper>
        {swipeOnboardingVisible && (
          <Portal>
            <SwipeOnboardingCard />
          </Portal>
        )}

        <View style={styles.feedRoot}>
          {!data?.nodes || (loading && networkStatus !== NetworkStatus.fetchMore) || loadingUserState ? (
            <ActivityIndicator animating size="large" />
          ) : (
            <ProductStack products={data.nodes} connected={!!profile} feedProps={feed} />
          )}
        </View>
      </ScreenWrapper>
    </>
  );
};
