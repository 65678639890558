import { useCallback, useState } from "react";

import { MediaTypeOptions } from "expo-image-picker";

import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { pickImage } from "@app/utils/image-picker.util";

import { useUploadVideoFile } from "./use-upload-video-file.hook";
import { useUploadVideoInitialContainer } from "./use-upload-video-initial-container.hook";

interface Args {
  maximumVideoDurationInSeconds: number;
  onCompleted: (uploadVideoId: string) => void;
}

interface UploadVideoFlow {
  uploadVideo: (args: Args) => Promise<void>;
  loading: boolean;
}

export function useUploadVideo(): UploadVideoFlow {
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { getUploadVideoInitialContainer } = useUploadVideoInitialContainer();
  const { uploadVideoFile } = useUploadVideoFile();

  const [loading, setLoading] = useState(false);

  const uploadVideo = useCallback(
    async (args: Args) => {
      setLoading(true);

      try {
        const video = await pickImage({ mediaTypes: MediaTypeOptions.Videos, videoMaxDuration: args.maximumVideoDurationInSeconds });
        if (video.error || !profile || !video.asset) return;

        const blob = await fetch(video.asset.uri).then(response => response.blob());

        void getUploadVideoInitialContainer({
          variables: { userId: profile.userId },
          onCompleted: data => {
            void uploadVideoFile({
              variables: { input: blob, url: data.uploadVideoInitialContainer.url },
              onCompleted: () => {
                args.onCompleted(data.uploadVideoInitialContainer.uploadVideoId);
                setLoading(false);
              },
              onError: error => {
                showErrorSnackbar({ error });
                setLoading(false);
              },
            });
          },
          onError: error => {
            showErrorSnackbar({ error });
            setLoading(false);
          },
        });
      } catch (e) {
        reportError(e as Error);
        setLoading(false);
      }
    },
    [getUploadVideoInitialContainer, profile, showErrorSnackbar, uploadVideoFile],
  );

  return { uploadVideo, loading };
}
