import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  loading: {
    left: "45%",
    position: "absolute",
    top: "50%",
  },
  poster: {
    objectFit: "cover",
  },
});
