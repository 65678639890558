import React, { FC, useCallback, useEffect, useMemo } from "react";

import { NetworkStatus } from "@apollo/client";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { videoRatio } from "@app/common/constants/video.const";
import { BehindTheScenesVideo } from "@app/common/graphql/generated/schema.graphql";
import { HomeStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { VideoListLoading } from "@app/components/home/home-section/video-section/video-list-loading/video-list-loading.component";
import { VideoListItem } from "@app/components/videos/video-list-item/video-list-item.component";
import { useBehindTheScenesVideos } from "@app/hooks/api/home/use-behind-the-scenes.hook";
import { useHomeSections } from "@app/hooks/utils/home/use-home-sections.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";

export const SeeAllVideosScreen: FC<HomeStackScreenProps<Routes.seeAllVideos>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { itemWidth, itemGap, listPadding, nbColumns } = useResponsiveWidthListItem();

  const type = route.params?.type;

  const { homeSections } = useHomeSections();
  // When more than one video section type, create a hook useHomeVideoSectionData
  const { data, loading, networkStatus, error, fetchMore } = useBehindTheScenesVideos();

  const { fetchMoreItems } = useFetchMoreItems("videos", fetchMore);

  const handleFetchMore = (): void => {
    if (data?.nodes?.length && fetchMoreItems && !error && data?.pageInfo.hasNextPage) {
      void fetchMoreItems({ offset: data.nodes.length });
    }
  };

  useEffect(() => {
    navigation.setOptions({ title: homeSections.find(section => section.type === type)?.title });
  }, [homeSections, navigation, type]);

  const emptyList = useMemo(() => {
    if (!loading)
      return (
        <Text variant="body2" color="tertiary" textAlign="center">
          {t("noResults.videos")}
        </Text>
      );

    return <VideoListLoading itemWidth={itemWidth} quantity={10} numColumns={nbColumns} />;
  }, [itemWidth, loading, nbColumns, t]);

  const renderVideo = useCallback<ListRenderItem<BehindTheScenesVideo>>(
    ({ item }) => (
      <View style={{ marginBottom: itemGap }}>
        <VideoListItem
          videoUrl={item.videoManifestUrl ?? item.videoUrl}
          thumbnailUrl={item.thumbnailUrl}
          cloudflareId={item.cloudflareId}
          width={itemWidth}
        />
      </View>
    ),
    [itemGap, itemWidth],
  );

  return (
    <ScreenWrapper>
      <FlashList
        data={data?.nodes}
        renderItem={renderVideo}
        numColumns={nbColumns}
        estimatedItemSize={itemWidth / videoRatio}
        contentContainerStyle={{ padding: listPadding }}
        onEndReached={handleFetchMore}
        onEndReachedThreshold={0.1}
        showsVerticalScrollIndicator={false}
        scrollEnabled
        ListFooterComponent={networkStatus === NetworkStatus.fetchMore ? <ActivityIndicator size="small" /> : undefined}
        ListEmptyComponent={emptyList}
      />
    </ScreenWrapper>
  );
};
