/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FloppyDisk, Trash } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList } from "react-native";
import { useTheme } from "react-native-paper";

import { CreateCatalogProductInput } from "@app/common/graphql/generated/schema.graphql";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { ImagePicker } from "@app/components/common/image-picker/image-picker.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput, TextInputProps } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCreateCatalogProduct } from "@app/hooks/api/god-mode/use-create-catalog-product.hook";
import { useCatalogProduct } from "@app/hooks/api/use-catalog-product.hook";
import { useCategories } from "@app/hooks/api/use-categories.hook";
import { useDeleteCatalogProduct } from "@app/hooks/api/use-delete-catalog-product.hook";
import { useUpdateCatalogProduct } from "@app/hooks/api/use-update-catalog-product.hook";
import { useUploadCatalogProductImage } from "@app/hooks/api/use-upload-catalog-product-image.hook";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { bottomSheetRowHeight, styles } from "./create-catalog-product.style";

type InputWithSuggestions = "categoryId" | null;

export const CreateCatalogProductScreen: FC<NavigationProps<Routes.createCatalogProduct>> = ({ navigation, route }) => {
  const catalogProductId = route.params?.catalogProductId;
  const { roundness } = useTheme();
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const suggestionsSheetRef = useRef<BottomSheetRefProps>(null);

  const [selectedInputWithSuggestions, setSelectedInputWithSuggestions] = useState<InputWithSuggestions>(null);
  const [input, setInput] = useState<Partial<CreateCatalogProductInput>>({});
  const updateInput = (key: keyof CreateCatalogProductInput) => (value?: string | boolean) => setInput(prev => ({ ...prev, [key]: value }));
  const [imageToUpload, setImageToUpload] = useState<string | undefined>(undefined);

  const { createCatalogProduct, loading: loadingCreate } = useCreateCatalogProduct();
  const { deleteCatalogProduct, loading: loadingDelete } = useDeleteCatalogProduct();
  const { updateCatalogProduct, loading: loadingUpdate } = useUpdateCatalogProduct();
  const { uploadImage, loading: loadingUploadImage } = useUploadCatalogProductImage();
  const { data: catalogProduct, loading: loadingCatalogProduct } = useCatalogProduct({
    variables: { catalogProductId: catalogProductId ?? "" },
    skip: !catalogProductId,
    onCompleted: response => {
      setInput({ ...response.catalogProduct });
      setImageToUpload(response.catalogProduct.imageUrl);
    },
    onError: error => {
      showErrorSnackbar({ message: error.message });
      navigation.goBack();
    },
  });

  const { data: categories } = useCategories({ variables: { first: 25 } });

  const isUpdating = !!catalogProductId;
  const isLoading = loadingCreate || loadingUpdate || loadingUploadImage;

  const suggestions = useMemo(() => {
    switch (selectedInputWithSuggestions) {
      case "categoryId":
        return categories?.nodes?.map(c => ({ id: c.categoryId, name: c.name, imageUrl: c.imageUrl })) ?? [];
      default:
        return [];
    }
  }, [selectedInputWithSuggestions, categories?.nodes]);

  const handleOpenBottomSheet = (field: InputWithSuggestions) => (): void => {
    setSelectedInputWithSuggestions(field);
    suggestionsSheetRef?.current?.open();
  };
  const handleCloseBottomSheet = (): void => {
    setSelectedInputWithSuggestions(null);
    suggestionsSheetRef?.current?.close();
  };

  useEffect(() => {
    if (isUpdating) {
      navigation.setOptions({
        title: t("godMode.catalogProduct.edit"),
      });
    }
  }, [isUpdating, navigation, t]);

  const handleSuccess = useCallback(
    (message: string): void => Alert.alert(t("godMode.success"), message, [{ text: t("cta.ok"), onPress: () => navigation.goBack() }]),
    [navigation, t],
  );

  const handleUploadImage = useCallback(
    (id: string, successMessage: string, errorMessage: string): void => {
      if (!imageToUpload) return;

      generateImageToFormData(imageToUpload)
        .then(form =>
          uploadImage({ variables: { input: form, catalogProductId: id } })
            .then(() => handleSuccess(successMessage))
            .catch(() => showErrorSnackbar({ message: errorMessage })),
        )
        .catch(() => showErrorSnackbar({ message: errorMessage }));
    },
    [handleSuccess, imageToUpload, showErrorSnackbar, uploadImage],
  );

  const handleCreateCatalogProduct = useCallback((): void => {
    const { categoryId, name, externalModelId } = input;

    if (!categoryId || !name || !externalModelId) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.image.imageRequired"));
      return;
    }

    void createCatalogProduct({
      variables: {
        input: {
          categoryId,
          externalModelId,
          name,
        },
      },
      onCompleted: data => {
        handleUploadImage(
          data.createCatalogProduct.catalogProductId,
          t("godMode.catalogProduct.created"),
          t("godMode.catalogProduct.createdButImageFailed"),
        );
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [input, imageToUpload, createCatalogProduct, t, handleUploadImage, showErrorSnackbar]);

  const handleUpdateCatalogProduct = useCallback((): void => {
    if (!catalogProduct) return;

    const { categoryId, name, externalModelId } = input;

    if (!categoryId || !name || !externalModelId) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.image.imageRequired"));
      return;
    }

    void updateCatalogProduct({
      variables: {
        input: {
          categoryId,
          externalModelId,
          name,
        },
        catalogProductId: catalogProduct.catalogProductId,
      },
      onCompleted: data => {
        if (imageToUpload && imageToUpload !== catalogProduct.imageUrl) {
          handleUploadImage(
            data.updateCatalogProduct.catalogProductId,
            t("godMode.catalogProduct.edited"),
            t("godMode.catalogProduct.editedButImageFailed"),
          );
        } else {
          handleSuccess(t("godMode.catalogProduct.edited"));
        }
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [catalogProduct, input, updateCatalogProduct, t, imageToUpload, handleUploadImage, handleSuccess, showErrorSnackbar]);

  const handleDelete = useCallback(() => {
    if (!catalogProduct) return;
    Alert.alert(t("error.warning"), t("godMode.confirmDelete"), [
      { style: "cancel", text: t("cta.cancel") },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () =>
          void deleteCatalogProduct({
            variables: { catalogProductId: catalogProduct.catalogProductId },
            onCompleted: () => handleSuccess(t("godMode.catalogProduct.deleted")),
          }),
      },
    ]);
  }, [catalogProduct, deleteCatalogProduct, handleSuccess, t]);

  const handleSelectRow = (value?: string) => () => {
    if (!selectedInputWithSuggestions) return;
    updateInput(selectedInputWithSuggestions)(value);
    handleCloseBottomSheet();
  };

  const commonProps = (name: keyof CreateCatalogProductInput): Partial<TextInputProps> => ({
    label: name,
    loading: !!catalogProductId && loadingCatalogProduct,
    value: input[name],
  });

  const commonPropsWithSuggestion = (name: InputWithSuggestions): Partial<TextInputProps> => ({
    disabled: isUpdating,
    multiline: true,
    editable: false,
    onPressIn: !isUpdating ? handleOpenBottomSheet(name) : undefined,
  });

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <TextInput {...commonProps("name")} required onChangeText={updateInput("name")} />
      <TextInput
        {...commonProps("externalModelId")}
        required
        onChangeText={updateInput("externalModelId")}
        multiline
        autoCapitalize="none"
        placeholder="adidas_x_dime_super_jacket.safetensors"
      />
      <TextInput {...commonProps("categoryId")} required {...commonPropsWithSuggestion("categoryId")} />

      <ImagePicker image={imageToUpload} setImage={setImageToUpload} style={styles.editImageContainer} aspectRatio={1} />

      <Button
        icon={FloppyDisk}
        loading={isLoading}
        fullWidth
        mode="contained"
        size="large"
        onPress={isUpdating ? handleUpdateCatalogProduct : handleCreateCatalogProduct}>
        {isUpdating ? t("godMode.catalogProduct.edit") : t("godMode.catalogProduct.create")}
      </Button>

      {isUpdating && (
        <Button icon={Trash} color="error" fullWidth mode="contained" size="large" onPress={handleDelete} loading={loadingDelete}>
          {t("godMode.catalogProduct.delete")}
        </Button>
      )}

      <BottomSheet ref={suggestionsSheetRef}>
        <FlatList
          data={suggestions}
          contentContainerStyle={styles.tableRow}
          renderItem={({ item: { name, id, imageUrl } }) => (
            <ListItem
              imageProps={{ image: imageUrl, size: bottomSheetRowHeight, style: { borderRadius: roundness } }}
              title={name}
              onPress={handleSelectRow(id)}
              style={styles.bottomSheetRow}
            />
          )}
          ListEmptyComponent={<Text>{t("godMode.noSuggestion")}</Text>}
        />
      </BottomSheet>
    </ScreenWrapper>
  );
};
