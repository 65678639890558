import { createContext, useContext } from "react";

export interface StreamContextInterface {
  userHasNewNotifs?: boolean;
  setNotifsAsSeen?: () => void;
}

export const StreamContext = createContext<StreamContextInterface>({});

export const useStreamContext = (): StreamContextInterface => useContext(StreamContext);
