import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationMoveCollabToUnderReviewDeniedArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  moveCollabToUnderReviewDenied: Collab;
}

interface MoveCollabToUnderReviewDeniedResult extends MutationResult<Response, "moveCollabToUnderReviewDenied"> {
  moveCollabToUnderReviewDenied: (options?: MutationFunctionOptions<Response, MutationMoveCollabToUnderReviewDeniedArgs>) => Promise<FetchResult>;
}

const moveCollabToUnderReviewDeniedMutation = gql`
  mutation MoveCollabToUnderReviewDenied($collabId: CollabId!) {
    moveCollabToUnderReviewDenied(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useMoveCollabToUnderReviewDenied(): MoveCollabToUnderReviewDeniedResult {
  const [moveCollabToUnderReviewDenied, { loading, error, data }] = useMutation<Response, MutationMoveCollabToUnderReviewDeniedArgs>(
    moveCollabToUnderReviewDeniedMutation,
  );

  return { moveCollabToUnderReviewDenied, loading, error, data: data?.moveCollabToUnderReviewDenied };
}
