import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  avatar: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  banner: {
    alignItems: "center",
  },
  root: {
    alignItems: "center",
    columnGap: 8,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: "100%",
  },
});
