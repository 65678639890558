import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreCartItemFields } from "@app/common/graphql/fragments.graphql";
import { CartItemWithIdentity } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  deleteCartItem: CartItemWithIdentity;
}

interface Variables {
  cartItemId: string;
}

interface DeleteCartItemResult extends MutationResult<Response, "deleteCartItem"> {
  deleteCartItem: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCartItemMutation = gql`
  mutation DeleteCartItem($cartItemId: CartItemId) {
    deleteCartItem(cartItemId: $cartItemId) {
      cartItem {
        ...CoreCartItemFields
      }
      identity {
        anonymousCartId
        userId
      }
    }
  }
  ${coreCartItemFields}
`;

export function useDeleteCartItem(): DeleteCartItemResult {
  const [deleteCartItem, { loading, error, data }] = useMutation<Response, Variables>(deleteCartItemMutation, {
    update(cache, _result, options) {
      cache.evict({ id: options.variables?.cartItemId });
    },
  });

  return { deleteCartItem, loading, error, data: data?.deleteCartItem };
}
