import { createContext, useContext } from "react";

import { AuthCredential } from "firebase/auth";

import { mockContextFn } from "@app/utils/context.util";

import { CustomableLoginBottomSheetProps } from "./login.type";

export interface LinkLoginProvidersProps {
  email: string;
  newSignInMethod: string;
  existingSignInMethods: string[];
  pendingCredential: AuthCredential;
}

export interface LoginContextInterface {
  openLogin: (props?: CustomableLoginBottomSheetProps) => void;
  closeLogin: () => void;
  openLinkProvidersPrompt: (props: LinkLoginProvidersProps) => void;
}

export const LoginContext = createContext<LoginContextInterface>({
  openLogin: () => mockContextFn("LoginContext", "openLogin"),
  closeLogin: () => mockContextFn("LoginContext", "closeLogin"),
  openLinkProvidersPrompt: () => mockContextFn("LoginContext", "openLinkProvidersPrompt"),
});

export const useLoginContext = (): LoginContextInterface => useContext(LoginContext);
