import { FC, useEffect } from "react";

const ErrorComponent: FC = () => {
  useEffect(() => {
    throw new Error("This is a test error thrown by ErrorComponent in the god mode.");
  }, []);

  return null;
};

export default ErrorComponent;
