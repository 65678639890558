import React, { useEffect, JSX, FC } from "react";

import { useSharedValue } from "react-native-reanimated";

import { getMediaToBeUsedImageUrl, ImageToBeUsed, isAnimationToBeUsed, isCampaignVideoToBeUsed, MediaToBeUsed } from "@app/common/types/image.type";
import { arrToHash } from "@app/utils/drag-drop.util";

import { DraggableImageItem } from "../draggable-image-item/draggable-image-item.component";

interface Props {
  media: MediaToBeUsed[];
  onRemove: (imageUrl: string) => void;
  onImageOrderChange: (images: MediaToBeUsed[]) => void;
}

export const DraggableImages: FC<Props> = ({ onImageOrderChange, media, onRemove }): JSX.Element[] => {
  const mediaWithUrl: (MediaToBeUsed & { url: string })[] = media.map(m => ({
    ...m,
    url: isAnimationToBeUsed(m) || isCampaignVideoToBeUsed(m) ? m.thumbnailUrl ?? "" : m.imageUrl,
  }));
  const positions = useSharedValue(arrToHash(mediaWithUrl, "url"));

  useEffect(() => {
    positions.value = arrToHash(mediaWithUrl, "url");
  }, [mediaWithUrl, positions]);

  const handleOnDropEnd = (): void => {
    const newOrders = Array<MediaToBeUsed | undefined>(media.length - 1).fill(undefined);
    const positionEntries = Object.entries(positions.value);
    positionEntries.forEach(([url, index]) => {
      newOrders[index - 1] = media.find(i => getMediaToBeUsedImageUrl(i) === url);
    });

    onImageOrderChange(newOrders.filter(Boolean) as ImageToBeUsed[]);
  };

  return media.map(m => (
    <DraggableImageItem
      key={getMediaToBeUsedImageUrl(m)}
      media={m}
      onRemove={onRemove}
      positions={positions}
      itemsCount={media.length}
      onDropFinish={handleOnDropEnd}
    />
  ));
};
