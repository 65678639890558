import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { CardPaymentMethod, SalesTax, ShippingAddress, ShippingRate } from "@app/common/graphql/generated/schema.graphql";

export interface PreorderInput {
  email?: string;
  paymentMethodId: string;
  quantity: number;
  shippingAddress?: ShippingAddress;
  collabId: string;
  variantId: string;
  anonymousCustomerId: string;
  orderId?: string;
  supercoinsUsed: number | null;
}

export const defaultInputsValue: PreorderInput = {
  quantity: 1,
  collabId: "",
  variantId: "",
  paymentMethodId: "",
  anonymousCustomerId: "",
  supercoinsUsed: null,
};

export interface PreorderContextInterface {
  isAnonymous: boolean;
  input: PreorderInput;
  loadingShippingAddress: boolean;
  loadingPreorder: boolean;
  salesTax?: SalesTax;
  shippingRate?: ShippingRate;
  loadingAdditionalCharges: boolean;
  preorderCompleted: boolean;
  loadingPaymentMethods: boolean;
  paymentMethods?: CardPaymentMethod[];
  preorder: () => void;
  setInput: Dispatch<SetStateAction<PreorderInput>>;
}

export const PreorderContext = createContext<PreorderContextInterface>({
  isAnonymous: false,
  input: defaultInputsValue,
  loadingShippingAddress: false,
  loadingPreorder: false,
  preorderCompleted: false,
  loadingAdditionalCharges: false,
  loadingPaymentMethods: false,
  preorder: () => undefined,
  setInput: () => undefined,
});

export const usePreorderContext = (): PreorderContextInterface => useContext(PreorderContext);
