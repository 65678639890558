import { useCallback, type RefObject } from "react";

import { SharedValue, useSharedValue } from "react-native-reanimated";

import type { SwiperCardRefProps } from "./swiper.types";

type SwipeControleUtils = SwiperCardRefProps & {
  activeIndex: SharedValue<number>;
};

export function useSwipeControls(currentCardRef: RefObject<SwiperCardRefProps>, prevCardRef: RefObject<SwiperCardRefProps>): SwipeControleUtils {
  const activeIndex = useSharedValue(0);

  const swipeRight = useCallback(() => {
    if (!currentCardRef?.current) {
      return;
    }

    currentCardRef?.current?.swipeRight();
  }, [currentCardRef]);

  const swipeTop = useCallback(() => {
    if (!currentCardRef?.current) {
      return;
    }

    currentCardRef.current?.swipeTop();
  }, [currentCardRef]);

  const swipeLeft = useCallback(() => {
    if (!currentCardRef?.current) {
      return;
    }

    currentCardRef.current?.swipeLeft();
  }, [currentCardRef]);

  const swipeBack = useCallback(
    (cb?: (prevIndex: number) => void) => {
      const prevIndex = activeIndex.value - 1;
      if (!prevCardRef?.current) {
        return;
      }

      prevCardRef.current?.swipeBack(() => cb?.(prevIndex));
      activeIndex.value--;
    },
    [activeIndex.value, prevCardRef],
  );

  const press = useCallback(
    (cb?: (currentIndex: number) => void) => {
      if (!currentCardRef?.current) {
        return;
      }
      currentCardRef.current?.press(() => cb?.(activeIndex.value));
    },
    [activeIndex.value, currentCardRef],
  );

  return {
    activeIndex,
    swipeRight,
    swipeLeft,
    swipeBack,
    swipeTop,
    press,
  };
}
