import { ApolloCache, ApolloClient } from "@apollo/client";

import { cartFields } from "@app/common/graphql/fragments.graphql";
import { Cart, CartIdentity, CartIdentityForUser, CartItem } from "@app/common/graphql/generated/schema.graphql";

interface Props {
  cache: ApolloCache<unknown>;
  client: ApolloClient<object>;
  cartItem?: CartItem;
  cartIdentity?: CartIdentity | CartIdentityForUser;
}

export function updateCacheAfterAddToCart({ cache, client, cartItem, cartIdentity }: Props): void {
  if (!cartItem) return;

  const newCartItemId = cartItem.cartItemId;
  const cartId = `cart-${cartIdentity?.userId ?? (cartIdentity && "anonymousCartId" in cartIdentity ? cartIdentity.anonymousCartId : "") ?? ""}`;

  const existingCart = client.readFragment({
    id: cartId,
    fragment: cartFields,
    fragmentName: "CartFields",
  }) as Cart;
  if (!existingCart) return;

  const indexCartItem = existingCart.items.findIndex(item => item.cartItemId === newCartItemId);
  if (indexCartItem !== -1) {
    cache.modify({
      id: newCartItemId,
      fields: {
        quantity: () => cartItem.quantity,
      },
    });
  } else {
    cache.modify({
      id: cartId,
      fields: {
        items: (existingItems: CartItem[], { toReference }) => {
          return [...(existingItems ?? []), toReference(newCartItemId)];
        },
      },
    });
  }
}
