import { gql, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { genericPromoFields } from "@app/common/graphql/fragments.graphql";
import { GenericPromo, UpdatePromoInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { useMutationWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { GenericPromoFromHook, mapGenericPromoToHookType, mapGenericPromoToRealType } from "@app/utils/promo.util";

interface InternalResponse {
  updateGenericPromo: GenericPromoFromHook;
}

interface Response {
  updateGenericPromo: GenericPromo;
}

interface Variables {
  input: UpdatePromoInput;
  promoId: string;
}

interface UpdateGenericPromoResult extends MutationResult<Response, "updateGenericPromo"> {
  updateGenericPromo: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateGenericPromoMutation = gql`
  mutation UpdateGenericPromo($input: UpdatePromoInput!, $promoId: PromoId!) {
    updateGenericPromo: newUpdateGenericPromo(input: $input, promoId: $promoId) {
      ...GenericPromoFields
    }
  }
  ${genericPromoFields}
`;

export function useUpdateGenericPromo(): UpdateGenericPromoResult {
  const [updateGenericPromo, { loading, error, data }] = useMutationWithTransformation<Response, InternalResponse, Variables, "updateGenericPromo">(
    updateGenericPromoMutation,
    "updateGenericPromo",
    mapGenericPromoToHookType,
  );

  return { updateGenericPromo, loading, error, data: data ? mapGenericPromoToRealType(data.updateGenericPromo) : undefined };
}
