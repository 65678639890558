import React, { FC } from "react";

import { EnvelopeSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { Button, ButtonProps } from "@app/components/common/button/button.component";

interface Props extends Omit<ButtonProps, "children"> {
  goToEnterEmail: () => void;
}

export const EmailButton: FC<Props> = ({ goToEnterEmail, ...props }) => {
  const { t } = useTranslation();

  const handlePress = (): void => {
    goToEnterEmail();
  };

  return (
    <Button icon={EnvelopeSimple} fullWidth mode="contained" size="large" onPress={handlePress} {...props}>
      {t("login.email.signInButton")}
    </Button>
  );
};
