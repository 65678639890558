import React, { FC } from "react";

import { CheckSquare, Square } from "phosphor-react-native";
import { CheckboxProps, useTheme } from "react-native-paper";

import { styles } from "./checkbox.style";

interface Props extends Omit<CheckboxProps, "onPress"> {
  onPress?: (newValue: boolean) => void;
}

export const Checkbox: FC<Props> = ({ status, onPress }) => {
  const { colors } = useTheme();
  const isChecked = status === "checked";

  return (
    <label style={styles.webLabel}>
      <input
        type="checkbox"
        checked={status === "checked"}
        style={styles.webInput}
        onChange={() => {
          onPress?.(!isChecked);
        }}
      />

      {isChecked ? <CheckSquare color={colors.onBackground} weight="fill" /> : <Square color={colors.onBackground} />}
    </label>
  );
};
