import React, { FC, useCallback, useEffect } from "react";

import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";

import { EmailType } from "@app/common/enums/email-type.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { reportError } from "@app/utils/logger/logger.util";

import { styles } from "./check-your-email.style";

interface Props {
  email: string;
  emailType: EmailType;
}

export const CheckYourEmail: FC<Props> = ({ email, emailType }) => {
  const { t } = useTranslation();

  const sendVerificationEmail = useCallback(async (): Promise<void> => {
    const user = (await getAuthWhenReady()).currentUser;

    if (!user) {
      reportError(new Error("Unable to get current user on 'check your email' screen"));
      return;
    }

    sendEmailVerification(user).catch(() => undefined);
  }, []);

  const sendPasswordEmail = useCallback(async (): Promise<void> => {
    sendPasswordResetEmail(await getAuthWhenReady(), email).catch(() => undefined);
  }, [email]);

  const sendEmail = useCallback((): void => {
    switch (emailType) {
      case EmailType.resetPassword:
        void sendPasswordEmail();
        break;
      case EmailType.verifyEmail:
        void sendVerificationEmail();
        break;
      default:
        break;
    }
  }, [emailType, sendPasswordEmail, sendVerificationEmail]);

  useEffect(() => {
    sendEmail();
  }, [sendEmail]);

  return (
    <View style={styles.root}>
      <View style={styles.textWrapper}>
        <Trans
          i18nKey={emailType === EmailType.verifyEmail ? "login.email.verificationEmailSent" : "login.email.resetPasswordEmailSent"}
          values={{ email }}
          parent={({ children }: ChildrenProp) => (
            <Text variant="body2" textAlign="center">
              {children}
            </Text>
          )}
          components={{
            bold: (
              <Text variant="subtitle2" textAlign="center">
                <></>
              </Text>
            ),
          }}
        />
      </View>
      <Button fullWidth mode="outlined" size="large" onPress={sendEmail}>
        {t("login.email.resendEmail")}
      </Button>
    </View>
  );
};
