import { ComputeAdditionalChargesAddressInput, SetShippingAddressInput, ShippingAddress } from "@app/common/graphql/generated/schema.graphql";

import { removeEmptyStringFromObject } from "../object.util";

export function getShippingAddressInputFromContextAddress(address: ShippingAddress): SetShippingAddressInput {
  let input: SetShippingAddressInput = {
    city: address.city,
    country: address.country,
    line1: address.line1,
    line2: address.line2,
    name: address.name,
    phoneNumber: address.phoneNumber,
    postalCode: address.postalCode,
    state: address.state,
  };
  input = removeEmptyStringFromObject(input);

  return input;
}

export function getComputeAdditionalChargesInputFromContextAddress(address: ShippingAddress): ComputeAdditionalChargesAddressInput {
  return {
    city: address.city || undefined,
    country: address.country ?? "",
    line1: address.line1 || undefined,
    line2: address.line2 || undefined,
    postalCode: address.postalCode || undefined,
    state: address.state || undefined,
  };
}
