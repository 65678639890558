import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadCatalogProductImage: {
    imageUrl: string;
  };
}

interface Variables {
  input: FormData;
  catalogProductId: string;
}

interface UploadCatalogProductImageResult extends MutationResult<Response, "uploadCatalogProductImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadCatalogProductImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadCatalogProductImage($catalogProductId: CatalogProductId!, $input: UploadImageInput!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadCatalogProductImage(catalogProductId: $catalogProductId, input: $input)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/catalog_product/{args.catalogProductId}/image", type: "File") {
      imageUrl
    }
  }
`;

export function useUploadCatalogProductImage(): UploadCatalogProductImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadCatalogProductImageMutation, {
    context: { clientName: ClientName.bloomRest },
    update(cache, results, options) {
      cache.modify({
        id: options.variables?.catalogProductId as string,
        fields: {
          imageUrl: () => results.data?.uploadCatalogProductImage?.imageUrl,
        },
      });
    },
  });

  return { uploadImage, loading, error, data: data?.uploadCatalogProductImage };
}
