import React, { FC, useEffect } from "react";

import { ArrowsClockwise } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ViewProps } from "react-native";

import { FullScreenError } from "@app/components/common/full-screen-error/full-screen-error.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { isWeb } from "@app/utils/device.util";
import { openAppStore } from "@app/utils/share/share.common.util";

export const UpdateAvailable: FC<ViewProps> = props => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isWeb) window.location.reload();
  }, []);

  if (isWeb) return null;

  return (
    <ScreenWrapper safeAreaEdges={["left", "right"]} {...props}>
      <FullScreenError
        title={t("error.updateAvailable.title")}
        message={t("error.updateAvailable.message")}
        image={ArrowsClockwise}
        ctaProps={{
          label: t("error.updateAvailable.cta"),
          onPress: () => void openAppStore(),
        }}
      />
    </ScreenWrapper>
  );
};
