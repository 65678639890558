import { FetchResult, useMutation, useQuery, QueryResult as ApolloQueryResult } from "@apollo/client";
import type { OperationVariables } from "@apollo/client/core";
import type { MutationHookOptions, MutationTuple, NoInfer, QueryHookOptions } from "@apollo/client/react/types/types";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { DocumentNode } from "graphql/index";

function optimisticResponseIsObject<TData, TVariables>(input: MutationHookOptions<TData, TVariables>["optimisticResponse"]): input is TData {
  return typeof input !== "function";
}

type MutationTupleWithTransformation<TData, TDataBeforeTransformation, TVariables> = [
  MutationTuple<TData, TVariables>[0],
  MutationTuple<TDataBeforeTransformation, TVariables>[1],
];

export function useMutationWithTransformation<
  TData,
  TDataBeforeTransformation extends TData,
  TVariables = OperationVariables,
  TKey extends keyof (TData | TDataBeforeTransformation) = keyof (TData | TDataBeforeTransformation),
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  key: TKey,
  transformation: (input: TData[TKey]) => TDataBeforeTransformation[TKey],
  options?: MutationHookOptions<TData, TVariables>,
): MutationTupleWithTransformation<TData, TDataBeforeTransformation, TVariables> {
  const [mutate, result] = useMutation<TDataBeforeTransformation, TVariables>(mutation);

  const internalMutate: (options?: MutationHookOptions<TData, TVariables>) => Promise<FetchResult<TData>> = scopedOptions => {
    const internalOptimisticResponse = options?.optimisticResponse;
    let internalOptimisticResponseObject: TData | undefined = undefined;

    if (internalOptimisticResponse && optimisticResponseIsObject(internalOptimisticResponse)) {
      internalOptimisticResponseObject = internalOptimisticResponse;
    }

    const internalOptions: MutationHookOptions<TDataBeforeTransformation, TVariables> = {
      ...options,
      ...scopedOptions,
      optimisticResponse: internalOptimisticResponseObject
        ? ({ ...internalOptimisticResponseObject, [key]: transformation(internalOptimisticResponseObject[key]) } as TDataBeforeTransformation)
        : undefined,
    };

    return mutate(internalOptions);
  };

  return [internalMutate, result];
}

export function useQueryWithTransformation<
  TData,
  TDataBeforeTransformation extends TData,
  TVariables extends OperationVariables = OperationVariables,
  TKey extends keyof (TData | TDataBeforeTransformation) = keyof (TData | TDataBeforeTransformation),
>(
  query: DocumentNode | TypedDocumentNode<TDataBeforeTransformation, TVariables>,
  key: TKey,
  transformation: (input: TDataBeforeTransformation[TKey]) => TData[TKey],
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): ApolloQueryResult<TDataBeforeTransformation, TVariables> {
  let internalOptions: QueryHookOptions<NoInfer<TDataBeforeTransformation>, NoInfer<TVariables>> | undefined = undefined;

  if (options) {
    const onCompletedFromOptions = options.onCompleted;

    internalOptions = {
      ...options,
      onCompleted: onCompletedFromOptions ? response => onCompletedFromOptions({ ...response, [key]: transformation(response[key]) }) : undefined,
      nextFetchPolicy: "cache-and-network",
      defaultOptions: { ...options.defaultOptions, nextFetchPolicy: "cache-and-network" },
    };
  }

  return useQuery<TDataBeforeTransformation, TVariables>(query, internalOptions);
}
