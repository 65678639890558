import React, { FC, useCallback, useEffect, useRef } from "react";

import { View, Animated, Platform, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { styles } from "./page-control.style";

interface Props {
  style?: ViewStyle;
  numberOfPages: number;
  currentPage: number;
  animationDuration?: number;
  radius?: number;
  margin?: number;
  inactiveTransparency?: number;
  inactiveBorderColor?: string;
  inactiveTintColor?: string;
  activeTintColor?: string;
  hidesForSinglePage?: boolean;
  vertical?: boolean;
}

export const PageControl: FC<Props> = ({
  style,
  numberOfPages = 0,
  currentPage = 0,
  animationDuration = 50,
  radius = 4,
  margin = 8,
  inactiveTransparency = 0.4,
  inactiveBorderColor,
  inactiveTintColor,
  activeTintColor,
  hidesForSinglePage = true,
  vertical = false,
}) => {
  const { colors } = useTheme();
  const translateValue = useRef(new Animated.Value(0)).current;
  const animationDurationV = Platform.OS === "ios" ? animationDuration : 0;

  const getActiveDotTranslateX = useCallback(() => {
    const width = numberOfPages * (radius * 2 + margin);

    if (currentPage <= 0) {
      return 0;
    } else if (currentPage >= numberOfPages) {
      return width * numberOfPages - 1 / numberOfPages;
    } else {
      return width * (currentPage / numberOfPages);
    }
  }, [margin, numberOfPages, currentPage, radius]);

  const animateActiveDotTranslateX = useCallback(
    (value: number, duration: number) => {
      if (isNaN(value)) return;

      Animated.timing(translateValue, {
        toValue: value,
        duration,
        useNativeDriver: true,
      }).start();
    },
    [translateValue],
  );

  const updatePageControl = useCallback(
    (duration = animationDurationV): void => {
      const newTranslateX = getActiveDotTranslateX();
      animateActiveDotTranslateX(newTranslateX, duration);
    },
    [animateActiveDotTranslateX, animationDurationV, getActiveDotTranslateX],
  );

  useEffect(() => {
    updatePageControl();
  }, [updatePageControl]);

  if (numberOfPages <= 1 && hidesForSinglePage) return null;
  const pages = Array.from(Array(numberOfPages).keys());

  return (
    <View style={style}>
      <View style={[vertical ? styles.verticalContainer : styles.horizontalContainer, { gap: margin }]}>
        {pages.map(index => (
          <View
            key={index}
            style={[
              {
                width: radius * 2,
                height: radius * 2,
                borderRadius: radius,
                opacity: inactiveTransparency,
                backgroundColor: inactiveTintColor ?? colors.onBackground,
                borderColor: (inactiveBorderColor || inactiveTintColor) ?? colors.outline,
              },
              styles.dot,
            ]}
          />
        ))}

        <Animated.View
          style={[
            {
              width: radius * 2,
              height: radius * 2,
              borderRadius: radius,
              backgroundColor: activeTintColor ?? colors.onBackground,
              transform: [vertical ? { translateY: translateValue } : { translateX: translateValue }],
            },
            styles.movingDot,
          ]}
        />
      </View>
    </View>
  );
};
