import { StudioBottomSheetStep, StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { GenerationInputs } from "@app/context/studio-mini/studio-mini.context";

export const getInitialFlow = (generation: GenerationInputs): StudioFlowType | null => {
  if (generation.catalogProduct) {
    return StudioFlowType.catalog;
  }
  if (generation.image) {
    return StudioFlowType.image;
  }
  if (generation.prompt) {
    return StudioFlowType.blank;
  }
  return null;
};

export const getInitialStep = (generation: GenerationInputs): StudioBottomSheetStep =>
  generation.catalogProduct || generation.prompt || generation.image ? StudioBottomSheetStep.prompt : StudioBottomSheetStep.selectFlow;
