import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { useNavigation } from "@app/common/types/navigation.type";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteCollab } from "@app/hooks/api/use-delete-collab.hook";

interface Props {
  productId: string;
  visible: boolean;
  onDismiss: () => void;
}

export const DeleteProductConfirmationDialog: FC<Props> = ({ productId, visible, onDismiss }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { showErrorSnackbar } = useSnackbarContext();

  const { deleteCollab, loading: deleteCollabLoading } = useDeleteCollab();

  const handleDelete = (): void => {
    void deleteCollab({
      variables: { collabId: productId },
      onCompleted: () => navigation.popToTop(),
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <ConfirmationDialog
      visible={visible}
      title={t("confirmation.deleteCollab.title")}
      message={t("confirmation.deleteCollab.message")}
      confirmProps={{
        label: t("cta.delete"),
        loading: deleteCollabLoading,
        onPress: handleDelete,
      }}
      onDismiss={onDismiss}
    />
  );
};
