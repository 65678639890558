import { Operation, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLErrorExtensions } from "graphql/error";

import { invalidAddressErrorCodes, videoErrorCodes } from "@app/hooks/utils/use-error-message.hook";
import { geUserAccessToken } from "@app/utils/auth.util";
import { reportError, log } from "@app/utils/logger/logger.util";

import { BloomHeader } from "./apollo-auth-link";

const ignoreCodes = [
  "FORBIDDEN",
  "IMAGE_IS_ALREADY_SAVED",
  "NO_SHIPPING_RATE_AVAILABLE",
  "MAXIMUM_OF_SUBMISSION_REACHED",
  "MISSING_VARIANT",
  "USER_CANNOT_BE_REFERRED",
  "USER_HAS_LINKED_COLLABS",
  "USER_WAS_ALREADY_REFERRED",
  "USERNAME_ALREADY_TAKEN",
  "TOO_MANY_COLLAB_IMAGES",
  "AMOUNT_EXCEEDS_HALF_OF_ORDER_PRICE",
  "ANIMATION_CANNOT_BE_FIRST_MEDIA",
  "TOO_MANY_ANIMATION_VIDEOS",
  ...invalidAddressErrorCodes,
  ...videoErrorCodes,
];
const ignoreMessagesRegex = [/^input.mediaIds must contain no more than/];
const ignoreMessages = [
  "input.'twitterLink' should be a valid X Url",
  "input.'instagramLink' should be a valid Instagram Url",
  "input.'externalLink' must be an URL address with http or https protocol",
  "Forbidden resource",
  "input.'endOfVoteDate' must be later than 'goLiveDate'",
  "input.username must match /^[\\w\\d_.]{1,30}$/ regular expression",
];
const ignoreCodesForContestCollabProfileQuery = ["INVALID_SCALAR", "ADMIN_ROLE_REQUIRED", "NOT_FOUND"];

const maxRetryCount = 3;

const errorCodesToRetry: string[] = ["UNAUTHORIZED"];

const sendErrorToSentry = (operation: Operation, message: string, extensions: GraphQLErrorExtensions): void => {
  const prevHeaders = operation.getContext().headers as Record<string, string>;
  const retryAttempt = prevHeaders[BloomHeader.retryCount];
  const queryName = operation.operationName;
  const variables = operation.variables;

  const tags = {
    queryName,
    variables: JSON.stringify(variables),
    retryAttempt,
  };

  const tagsMaxLength = 199;
  const variablesDetails = tags.variables.length > tagsMaxLength ? `, Variables: ${tags.variables}` : "";
  const errorMessage = `[GraphQL error] - ${queryName}: Message: ${message}, Code: ${extensions?.code as string}, Details: ${JSON.stringify(
    extensions?.response,
  )}${variablesDetails}`;

  const captureContext = {
    tags,
    extra: {
      message,
      code: extensions?.code,
      details: extensions?.response,
      queryName,
      variables,
      retryAttempt,
    },
    fingerprint: ["{{ default }}", operation.operationName],
  };

  reportError(new Error(errorMessage), captureContext);
};

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    log.error(`[Network error]: ${JSON.stringify(networkError)}`);
  }

  if (!graphQLErrors?.length) {
    return;
  }

  for (const { message, extensions } of graphQLErrors) {
    const errorCode = extensions.code as string;

    if (errorCodesToRetry.includes(errorCode)) {
      const prevHeaders = operation.getContext().headers as Record<string, string>;

      // good example: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
      // eslint-disable-next-line consistent-return
      return fromPromise(geUserAccessToken(true).catch(() => sendErrorToSentry(operation, "[Retry Auth]: failed", extensions))) // add promises needed for a retry query
        .filter(Boolean)
        .flatMap(authToken => {
          operation.setContext({
            headers: {
              ...prevHeaders,
              ...(authToken ? { [BloomHeader.authorization]: `Bearer ${authToken}` } : {}),
              [BloomHeader.retryCount]: prevHeaders[BloomHeader.retryCount] ? (parseInt(prevHeaders[BloomHeader.retryCount]) + 1).toString() : "1",
            },
          });
          return forward(operation);
        });
    }

    const notFoundErrorOnQueries = errorCode === "NOT_FOUND" && ["FeaturedCollab", "FeaturedCreator"].includes(operation.operationName);
    const isErrorMessageIgnored = ignoreMessages.includes(message) || ignoreMessagesRegex.findIndex(regex => message.match(regex)) !== -1;
    const shouldIgnoreErrorForSentry =
      isErrorMessageIgnored ||
      ignoreCodes.includes(errorCode) ||
      (ignoreCodesForContestCollabProfileQuery.includes(errorCode) && ["Contest", "Collab", "Profile"].includes(operation.operationName)) ||
      notFoundErrorOnQueries;

    if (__DEV__ && !notFoundErrorOnQueries) {
      log.error({ code: extensions?.code, message, queryName: operation.operationName, shouldIgnoreErrorForSentry });
    }

    if (!shouldIgnoreErrorForSentry) {
      sendErrorToSentry(operation, message, extensions);
    }
  }
});

export const retryLink = new RetryLink({
  attempts: (count, operation, _error) => {
    operation.setContext(({ headers = {} }) => ({ headers: { ...headers, [BloomHeader.retryCount]: count } }));
    if (count <= maxRetryCount) return true;
    return false;
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
});
