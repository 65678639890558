import { LazyQueryExecFunction, gql, useLazyQuery } from "@apollo/client";

import { ContestPasswordValidation } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  contestPasswordValidation: ContestPasswordValidation;
}

interface Variables {
  contestId: string;
  password: string;
}

interface ContestPasswordValidationResult extends BaseQueryResult<Response, "contestPasswordValidation"> {
  validatePassword: LazyQueryExecFunction<Response, Variables>;
}

export const contestPasswordValidationQuery = gql`
  query ContestPasswordValidation($contestId: ContestId!, $password: String!) {
    contestPasswordValidation(contestId: $contestId, password: $password) {
      valid
    }
  }
`;

export function useContestPasswordValidation(): ContestPasswordValidationResult {
  const [validatePassword, { data, loading, error }] = useLazyQuery<Response, Variables>(contestPasswordValidationQuery);

  return { validatePassword, data: data?.contestPasswordValidation, loading, error };
}
