import { LazyQueryExecFunction, QueryHookOptions, gql, useLazyQuery, useQuery } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile, ProfileFilter } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  profile: Profile;
}

interface Variables {
  where: ProfileFilter;
}

interface LazyResult extends QueryResult<Response, "profile"> {
  getProfile: LazyQueryExecFunction<Response, Variables>;
}

/**
 * This query is also used to query user for the thumbnail preview. Update it accordingly:
 * server/services/user.service.js
 */
const profileQuery = gql`
  query Profile($where: ProfileFilter) {
    profile(where: $where) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useProfile(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "profile"> {
  const variables = options.variables?.where;
  const params = variables ? Object.values(variables) : [];

  const { data, loading, error, refetch } = useQuery<Response, Variables>(profileQuery, {
    ...options,
    skip: !variables || params.length !== 1 || params.some(value => value == null) || options.skip,
  });

  return { data: data?.profile, loading, error, refetch };
}

export function useLazyProfile(): LazyResult {
  const [getProfile, { loading, error, data }] = useLazyQuery<Response, Variables>(profileQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return { getProfile, loading, error, data: data?.profile };
}
