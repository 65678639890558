import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Linking } from "react-native";

import { links } from "@app/common/constants/links.const";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";

interface Response {
  contactUs: () => void;
}

export function useContactUs(): Response {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();

  const contactUs = useCallback(() => {
    const subject = t("settings.help.subject");
    const body = t("settings.help.body");

    const url = `${links.email.community}?subject=${subject}&body=${body}`;

    Linking.openURL(encodeURI(url)).catch(() =>
      showErrorSnackbar({ duration: 8000, message: t("settings.help.error", { context: __DEV__ ? "dev" : undefined }) }),
    );
  }, [showErrorSnackbar, t]);

  return { contactUs };
}
