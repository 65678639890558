import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationPromoteCollabToForSaleArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  promoteCollabToForSale: Collab;
}

interface PromoteCollabToForSaleResult extends MutationResult<Response, "promoteCollabToForSale"> {
  promoteCollabToForSale: (options?: MutationFunctionOptions<Response, MutationPromoteCollabToForSaleArgs>) => Promise<FetchResult>;
}

const promoteCollabToForSaleMutation = gql`
  mutation PromoteCollabToForSale($collabId: CollabId!, $input: PromoteCollabToForSaleInput!) {
    promoteCollabToForSale(collabId: $collabId, input: $input) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function usePromoteCollabToForSale(): PromoteCollabToForSaleResult {
  const [promoteCollabToForSale, { loading, error, data }] = useMutation<Response, MutationPromoteCollabToForSaleArgs>(
    promoteCollabToForSaleMutation,
  );

  return { promoteCollabToForSale, loading, error, data: data?.promoteCollabToForSale };
}
