import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioImages, PaginatedStudioMedia, SaveTemporaryImagesInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  saveTemporaryImages: PaginatedStudioImages;
}

interface Variables {
  input: SaveTemporaryImagesInput;
}

interface SaveTemporaryImageResult extends MutationResult<Response, "saveTemporaryImages"> {
  saveTemporaryImages: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const saveTemporaryImagesMutation = gql`
  mutation SaveTemporaryImages($input: SaveTemporaryImagesInput!) {
    saveTemporaryImages(input: $input) {
      nodes {
        ...CoreStudioImageFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
`;

export function useSaveTemporaryImages(): SaveTemporaryImageResult {
  const [saveTemporaryImages, { loading, error, data }] = useMutation<Response, Variables>(saveTemporaryImagesMutation, {
    update: (cache, results) => {
      cache.modify({
        fields: {
          studioMedia(existingStudioMedia: PaginatedStudioMedia, { readField, toReference }) {
            const newImagesIds = results.data?.saveTemporaryImages.nodes?.map(image => image.imageId);

            const filteredImageIds = newImagesIds?.filter(
              id => existingStudioMedia.nodes?.findIndex(image => readField("imageId", image) === id) === -1,
            );
            if (!filteredImageIds?.length) return existingStudioMedia;

            return {
              ...existingStudioMedia,
              nodes: [...filteredImageIds.map(id => toReference(`studioMedia-${id}`)), ...(existingStudioMedia.nodes ?? [])],
              totalCount: existingStudioMedia.totalCount + filteredImageIds.length,
            };
          },
        },
      });
    },
  });

  return { saveTemporaryImages, loading, error, data: data?.saveTemporaryImages };
}
