import { StyleSheet } from "react-native";

import { supercoinsGradient } from "@app/common/style/common.style";

export const styles = StyleSheet.create({
  buttonsContainer: {
    paddingTop: 8,
    rowGap: 8,
    width: "100%",
  },
  continueOnAppButton: {
    borderColor: supercoinsGradient[0],
    borderWidth: 1,
    width: "100%",
  },
  footer: {
    alignItems: "center",
    marginTop: "auto",
    rowGap: 16,
    width: "100%",
  },
  gradient: {
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    width: "100%",
  },
  mainContent: {
    flex: 1,
  },
  mainContentContainer: {
    flex: 1,
    padding: 20,
  },
  orContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  orDivider: {
    flex: 1,
  },
  orText: {
    marginHorizontal: 8,
  },

  rowGap: {
    rowGap: 16,
  },
  scrollingContent: {
    paddingBottom: 24,
    rowGap: 24,
  },
  verticalAlign: {
    alignItems: "center",
  },
});
