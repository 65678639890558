import { createContext, useContext } from "react";

export interface PushNotificationPermissionContextInterface {
  shouldPromptForPermission: boolean;
  canRequestPermission: boolean;
  skipPrompt: () => void;
  requestPermission: () => void;
  canSendNotification: boolean;
}

export const PushNotificationPermissionContext = createContext<PushNotificationPermissionContextInterface>({
  shouldPromptForPermission: false,
  canRequestPermission: false,
  skipPrompt: () => undefined,
  requestPermission: () => undefined,
  canSendNotification: false,
});

export const usePushNotificationPermissionContext = (): PushNotificationPermissionContextInterface => useContext(PushNotificationPermissionContext);
