import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { GenerateImagesFromImageInput, StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

import { imageGenerationCreditQuery } from "../use-image-generation-credits.hook";

interface Response {
  startGeneratingImagesFromImage: StudioTask;
}

interface Variables {
  input: GenerateImagesFromImageInput;
  userId: string;
}

interface StartGeneratingImagesFromImageResult extends MutationResult<Response, "startGeneratingImagesFromImage"> {
  startGeneratingImagesFromImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startGeneratingImagesFromImageMutation = gql`
  mutation StartGeneratingImagesFromImage($input: GenerateImagesFromImageInput!, $userId: UserId!) {
    startGeneratingImagesFromImage(input: $input, userId: $userId) {
      studioTaskId
    }
  }
`;

export function useStartGeneratingImagesFromImage(): StartGeneratingImagesFromImageResult {
  const [startGeneratingImagesFromImage, { loading, error, data, reset, client }] = useMutation<Response, Variables>(
    startGeneratingImagesFromImageMutation,
    {
      update: () => {
        void client.refetchQueries({ include: [imageGenerationCreditQuery] });
      },
    },
  );

  return { startGeneratingImagesFromImage, loading, error, data: data?.startGeneratingImagesFromImage, reset };
}
