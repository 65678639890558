import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { milestoneProgressFields } from "@app/common/graphql/fragments.graphql";
import { MilestoneProgressFilteringArgs, PaginatedMilestoneProgress } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  milestonesProgress: PaginatedMilestoneProgress;
}

export interface Variables {
  first?: number;
  offset?: number;
  userId?: string;
  where?: MilestoneProgressFilteringArgs;
}

const milestonesProgressQuery = gql`
  query MilestoneProgresses($first: Int, $offset: Int, $userId: UserId!, $where: MilestoneProgressFilteringArgs) {
    milestonesProgress: milestoneProgresses(first: $first, offset: $offset, userId: $userId, where: $where) {
      nodes {
        ...MilestoneProgressFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${milestoneProgressFields}
`;

export function useMilestonesProgress(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "milestonesProgress"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(milestonesProgressQuery, {
    skip: !options.variables?.userId,
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.milestonesProgress, loading, error, networkStatus, fetchMore, refetch };
}
