import React, { FC, useRef, useCallback, ReactNode } from "react";

import { useApolloClient, gql } from "@apollo/client";
import LottieView, { AnimationObject } from "lottie-react-native";
import { HeartStraight, IconProps } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDislikeCollab } from "@app/hooks/api/likes/use-dislike-collab.hook";
import { useLikeCollab } from "@app/hooks/api/likes/use-like-collab.hook";

import { styles } from "./like-button.style";

interface Props extends Omit<ButtonProps, "children"> {
  collabId: string;
  liked?: boolean;
  numberOfLikes?: number;
  animatedIconSource?: AnimationObject;
  buttonComponent?: FC<{ onPress: () => void; animatedIcon?: (iconProps: IconProps) => ReactNode }>;
  children?: ReactNode;
}

export const LikeButton: FC<Props> = ({
  collabId,
  liked = false,
  numberOfLikes,
  animatedIconSource,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buttonComponent: ButtonComponent,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const iconAnimation = useRef<LottieView>(null);

  const { profile, loading: loadingProfile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { openLogin } = useLoginContext();

  const { likeCollab, loading: likeLoading } = useLikeCollab();
  const { dislikeCollab, loading: dislikeLoading } = useDislikeCollab();

  const loading = likeLoading || dislikeLoading || loadingProfile;

  const updateNumberOfLikes = useCallback(
    (value: number): void => {
      client.writeFragment({
        id: collabId,
        fragment: gql`
          # eslint-disable-next-line @graphql-eslint/no-unused-fragments
          fragment LikeCountFieldsFromCollab on Collab {
            collabId
            numberOfLikes
          }
        `,
        data: {
          collabId,
          numberOfLikes: value,
        },
      });
    },
    [client, collabId],
  );

  const updateLiked = useCallback(
    (value: boolean): void => {
      client.writeFragment({
        id: collabId,
        fragment: gql`
          # eslint-disable-next-line @graphql-eslint/no-unused-fragments
          fragment LikeFieldsFromCollab on Collab {
            collabId
            liked
          }
        `,
        data: {
          collabId,
          liked: value,
        },
      });
    },
    [client, collabId],
  );

  const handleLike = useCallback((): void => {
    if (!profile && !loadingProfile) {
      openLogin();
      return;
    }

    if (!profile || loading) return;

    iconAnimation.current?.play();
    updateLiked(true);
    if (numberOfLikes) updateNumberOfLikes(numberOfLikes + 1);

    void likeCollab({
      variables: { userId: profile.userId, input: { collabId } },
      onError: error => {
        updateLiked(false);
        if (numberOfLikes) updateNumberOfLikes(numberOfLikes);
        showErrorSnackbar({ error });
      },
    });
  }, [collabId, likeCollab, loading, loadingProfile, numberOfLikes, openLogin, profile, showErrorSnackbar, updateLiked, updateNumberOfLikes]);

  const handleDislike = (): void => {
    if (!profile || loading) return;

    iconAnimation.current?.reset();
    updateLiked(false);
    if (numberOfLikes) updateNumberOfLikes(numberOfLikes - 1);

    void dislikeCollab({
      variables: { userId: profile.userId, input: { collabId } },
      onError: error => {
        updateLiked(true);
        if (numberOfLikes) updateNumberOfLikes(numberOfLikes);
        showErrorSnackbar({ error });
      },
    });
  };

  const likeIcon = useCallback(
    ({ size = 16 }: IconProps) => {
      const iconSize = (size as number) + 4;
      return animatedIconSource ? (
        <LottieView ref={iconAnimation} autoPlay={liked} loop={false} style={{ height: iconSize, width: iconSize }} source={animatedIconSource} />
      ) : undefined;
    },
    [animatedIconSource, liked],
  );

  return ButtonComponent ? (
    <ButtonComponent onPress={liked ? handleDislike : handleLike} animatedIcon={animatedIconSource ? likeIcon : undefined} />
  ) : (
    <Button
      testID="like-button"
      color={liked ? "secondary" : undefined}
      icon={
        !liked || props.disabled || !animatedIconSource
          ? iconProps => <HeartStraight {...iconProps} weight={props.mode === "contained" ? "fill" : "thin"} />
          : likeIcon
      }
      loading={loading}
      disabled={loading}
      onPress={liked ? handleDislike : handleLike}
      labelStyle={!children ? styles.label : undefined}
      {...props}>
      {children ?? t("cta.like", { context: liked ? "true" : "false" })}
    </Button>
  );
};
