import { StyleSheet } from "react-native";

const imageContentPadding = 8;
export const paddingBottom = 80;

export const styles = StyleSheet.create({
  background: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  imageContent: {
    alignItems: "flex-start",
    flexShrink: 1,
    rowGap: 8,
  },
  imageContentContainer: {
    alignItems: "flex-end",
    columnGap: 8,
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    padding: imageContentPadding,
    rowGap: 8,
  },
  stageChip: {
    alignItems: "center",
    borderRadius: 0,
    columnGap: 8,
    flexDirection: "row",
    marginBottom: -imageContentPadding,
    marginLeft: -imageContentPadding,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});
