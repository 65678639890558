import React, { FC } from "react";

import { SafeAreaView } from "react-native";
import { useTheme } from "react-native-paper";

import { Routes, NavigationProps } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioImageListDetails } from "@app/components/studio-mini/studio-image-list-details/studio-image-list-details.component";
import { StudioImageMainActionButtons } from "@app/components/studio-mini/studio-image-main-action-buttons/studio-image-main-action-buttons.component";
import { useStudioAnimation } from "@app/hooks/api/studio/use-studio-animation.hook";
import { useStudioImage } from "@app/hooks/api/studio/use-studio-image.hook";

import { styles } from "./saved-image-details.style";

export const SavedImageDetailsScreen: FC<NavigationProps<Routes.studioMiniSavedDetails>> = ({ navigation, route }) => {
  const { colors } = useTheme();

  const imageId = "imageId" in route.params ? route.params.imageId : undefined;
  const animationId = "animationId" in route.params ? route.params.animationId : undefined;

  const { data: image, loading: loadingImage } = useStudioImage({
    variables: { imageId: imageId ?? "" },
    skip: !imageId,
    onError: () => navigation.replace(Routes.studioMiniSaved),
  });

  const { data: video, loading: loadingVideo } = useStudioAnimation({
    variables: { studioAnimationId: animationId ?? "" },
    skip: !animationId,
    onError: () => navigation.replace(Routes.studioMiniSaved),
  });

  const media = imageId ? image : video;
  const loading = imageId ? loadingImage : loadingVideo;

  const actionButtons = imageId ? <StudioImageMainActionButtons image={image} loading={loading} /> : undefined;

  return (
    <>
      <ScreenWrapper withScrollView contentContainerStyle={styles.root} staticContent={actionButtons}>
        <StudioImageListDetails media={media} context="saved" />
      </ScreenWrapper>
      {imageId && <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />}
    </>
  );
};
