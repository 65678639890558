import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationPromoteCollabToUnderReviewReadyArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  promoteCollabToUnderReviewReady: Collab;
}

interface PromoteCollabToUnderReviewReadyResult extends MutationResult<Response, "promoteCollabToUnderReviewReady"> {
  promoteCollabToUnderReviewReady: (options?: MutationFunctionOptions<Response, MutationPromoteCollabToUnderReviewReadyArgs>) => Promise<FetchResult>;
}

const promoteCollabToUnderReviewReadyMutation = gql`
  mutation PromoteCollabToUnderReviewReady($collabId: CollabId!, $input: PromoteCollabToUnderReviewReadyInput!) {
    promoteCollabToUnderReviewReady(collabId: $collabId, input: $input) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function usePromoteCollabToUnderReviewReady(): PromoteCollabToUnderReviewReadyResult {
  const [promoteCollabToUnderReviewReady, { loading, error, data }] = useMutation<Response, MutationPromoteCollabToUnderReviewReadyArgs>(
    promoteCollabToUnderReviewReadyMutation,
  );

  return { promoteCollabToUnderReviewReady, loading, error, data: data?.promoteCollabToUnderReviewReady };
}
