import { JSX } from "react";

import { ActivityVerb } from "@app/common/graphql/generated/schema.graphql";
import { ListItemProps } from "@app/components/common/list-item/list-item.component";

export interface NotificationProps extends Pick<ListItemProps, "title" | "subtitle" | "titleProps" | "onPress"> {
  leftIcon: JSX.Element;
  timestamp: string;
  rightImage?: string;
  button?: JSX.Element;
}

export enum CtaType {
  shareConcept = "shareConcept",
  startCampaign = "startCampaign",
  viewContestDetails = "viewContestDetails",
  viewItemDetails = "viewItemDetails",
  viewOrders = "viewOrders",
  viewUpdateDetails = "viewUpdateDetails",
}

export interface NotifTrackingData {
  notifType: ActivityVerb;
  entityId: string;
  title?: string;
  subtitle?: string;
  ctaType: CtaType;
}
