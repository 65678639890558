import { StyleSheet } from "react-native";

import { supercoinsGradient } from "@app/common/style/common.style";

export const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "flex-start",
    columnGap: 12,
    rowGap: 4,
  },
  iconButton: {
    margin: 0,
    marginLeft: 16,
  },
  root: {
    borderColor: supercoinsGradient[0],
    borderWidth: 1,
    padding: 16,
  },
  textContainer: {
    paddingVertical: 8,
  },
});
