import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const iconSize = 24;

export const styles = StyleSheet.create({
  listContainer: {
    paddingTop: isWeb ? 16 : undefined,
  },
  listItem: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    rowGap: 8,
  },
});
