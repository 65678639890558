import React, { FC, ReactNode } from "react";

import { Keyboard, TouchableWithoutFeedback, View } from "react-native";

import { ButtonProps } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";

import { styles } from "./onboarding-screen-layout.style";
import { OnboardingStepper } from "./onboarding-stepper/onboarding-stepper.component";

interface Props {
  title: string;
  subtitle: string;
  description?: string;
  content?: ReactNode;
  bottomButton: FC<Omit<ButtonProps, "children">>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OnboardingScreenLayout: FC<Props> = ({ title, subtitle, description, content, bottomButton: BottomButton }) => {
  const { numberOfStepsToShow } = useOnboardingContext();

  const hasMultipleStepsToShow = numberOfStepsToShow > 1;
  const paddingVertical = hasMultipleStepsToShow ? 8 : 24;

  return (
    <ScreenWrapper
      withScrollView
      withKeyboardAvoidingView
      contentContainerStyle={[styles.root, { paddingVertical }]}
      staticContent={
        <BottomButton mode="contained" size="large" fullWidth containerStyle={styles.buttonContainer} contentStyle={styles.buttonContent} />
      }>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={styles.container}>
          <OnboardingStepper hasMultipleSteps={hasMultipleStepsToShow} />

          <View style={styles.textContainer}>
            <Text variant="title">{title}</Text>
            <Text variant="body2">{subtitle}</Text>
            {description && (
              <Text variant="body2" color="tertiary">
                {description}
              </Text>
            )}
          </View>
          {content}
        </View>
      </TouchableWithoutFeedback>
    </ScreenWrapper>
  );
};
