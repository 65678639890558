import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  dismissButton: {
    height: 20,
    margin: 0,
    position: "absolute",
    right: 4,
    top: 4,
    width: 20,
  },
  fullScreen: {
    flex: 1,
  },
  root: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    rowGap: 8,
    width: 189,
  },
});
