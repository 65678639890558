import { createContext, useContext } from "react";

import { FrontendConfig, TokenCosts } from "@app/common/graphql/generated/schema.graphql";

export interface ConfigContextInterface {
  config?: FrontendConfig;
  maxImagesPerCollab?: number;
  loading: boolean;
  tokenCosts?: TokenCosts;
}

export const ConfigContext = createContext<ConfigContextInterface>({
  loading: false,
});

export const useConfigContext = (): ConfigContextInterface => useContext(ConfigContext);
