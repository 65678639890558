import { gql, useMutation } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { TrackShareReferralLinkInput } from "@app/common/graphql/generated/schema.graphql";

import { TrackSharingResponse, TrackSharingResult } from "./use-track-sharing.hook";

interface Variables {
  input: TrackShareReferralLinkInput;
  userId: string;
}

const trackShareReferralLinkMutation = gql`
  mutation TrackShareReferralLink($input: TrackShareReferralLinkInput!, $userId: UserId!) {
    trackSharing: trackShareReferralLink(input: $input, userId: $userId) {
      ... on Profile {
        ...CoreProfileFields
      }
    }
  }
  ${coreProfileFields}
`;

export function useTrackShareReferralLink(): TrackSharingResult<Variables> {
  const [trackSharing, { loading, error, data }] = useMutation<TrackSharingResponse, Variables>(trackShareReferralLinkMutation);

  return { trackSharing, loading, error, data: data?.trackSharing };
}
