import React, { useCallback, FC, useState, useMemo } from "react";

import { NetworkStatus } from "@apollo/client";
import { CaretRight, Note } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatListProps, ListRenderItem, TouchableHighlight, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Text } from "@app/components/common/text/text.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCollabUpdates } from "@app/hooks/api/updates/use-collab-updates.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";

import { UpdateListItem } from "../update-list-item/update-list-item.component";

import { styles } from "./update-list.style";

interface Props extends Omit<FlatListProps<CollabUpdate>, "data" | "renderItem"> {
  collabId: string;
  canCreateCollabUpdate?: boolean;
  displayOnlyLatestUpdate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UpdateList: FC<Props> = ({ collabId, canCreateCollabUpdate, displayOnlyLatestUpdate = false, ListEmptyComponent, ...flatListProps }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation();
  const { showErrorSnackbar } = useSnackbarContext();

  const {
    data,
    loading: loadingUpdates,
    networkStatus,
    refetch,
    fetchMore,
  } = useCollabUpdates({
    variables: { collabId, first: displayOnlyLatestUpdate ? 1 : 20 },
    onError: error => {
      showErrorSnackbar({ error });
      if (!displayOnlyLatestUpdate) {
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate(Routes.product, { id: collabId });
      }
    },
  });
  const { fetchMoreItems } = useFetchMoreItems("collabUpdates", fetchMore);

  const [refreshing, setRefreshing] = useState(false);

  const updates = data?.nodes;
  const noUpdates = !loadingUpdates && (!updates || data?.totalCount === 0);
  const oneOrNoUpdates = noUpdates || data?.totalCount === 1;
  const hasMoreThanOneUpdates = data?.nodes && data?.totalCount > 1;
  const lastIndex = (data?.totalCount ?? 1) - 1;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const handleSeeMoreUpdates = useCallback((): void => navigation.navigate(Routes.productUpdates, { collabId }), [collabId, navigation]);

  const handleFetchMore = useCallback((): void => {
    if (!displayOnlyLatestUpdate && fetchMore && !isFetchingMore && updates && data?.pageInfo.hasNextPage) {
      void fetchMoreItems({ offset: updates?.length });
    }
  }, [data?.pageInfo.hasNextPage, displayOnlyLatestUpdate, fetchMore, fetchMoreItems, isFetchingMore, updates]);

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch || displayOnlyLatestUpdate) return;

    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const header = useMemo(
    () =>
      displayOnlyLatestUpdate ? (
        <View style={styles.headerContainer}>
          <Text variant="body1" textTransform="uppercase">
            {t("productDetails.updates.label")}
          </Text>
          <TouchableHighlight
            activeOpacity={1}
            underlayColor={colors.action.selected}
            disabled={!canCreateCollabUpdate && oneOrNoUpdates}
            onPress={handleSeeMoreUpdates}>
            <View style={styles.seeMoreWithIcon}>
              {hasMoreThanOneUpdates && (
                <Text variant="overline" textTransform="uppercase">
                  {t("productDetails.seeMore")}
                </Text>
              )}
              {(canCreateCollabUpdate || hasMoreThanOneUpdates) && <CaretRight size={24} color={colors.onBackground} />}
            </View>
          </TouchableHighlight>
        </View>
      ) : undefined,
    [
      canCreateCollabUpdate,
      colors.action.selected,
      colors.onBackground,
      displayOnlyLatestUpdate,
      handleSeeMoreUpdates,
      hasMoreThanOneUpdates,
      oneOrNoUpdates,
      t,
    ],
  );

  const renderUpdate = useCallback<ListRenderItem<CollabUpdate>>(
    ({ item, index }) => <UpdateListItem update={item} index={displayOnlyLatestUpdate ? lastIndex : lastIndex - index} />,
    [displayOnlyLatestUpdate, lastIndex],
  );

  const emptyState = (
    <EmptyState
      icon={Note}
      message={t("emptyState.updates.message", { context: canCreateCollabUpdate ? "asOwner" : undefined })}
      cta={
        canCreateCollabUpdate
          ? [
              {
                label: t("emptyState.updates.cta"),
                onPress: () => navigation.navigate(Routes.productUpdateStack, { screen: Routes.createProductUpdate, params: { collabId } }),
              },
            ]
          : undefined
      }
      fullScreen
    />
  );

  return (
    <FlatList
      data={updates}
      keyExtractor={item => item.collabUpdateId}
      renderItem={renderUpdate}
      ListHeaderComponent={header}
      ListEmptyComponent={loadingUpdates ? <ActivityIndicator size="large" /> : ListEmptyComponent ?? emptyState}
      showsVerticalScrollIndicator={false}
      numColumns={1}
      scrollEnabled={!displayOnlyLatestUpdate}
      onEndReached={handleFetchMore}
      onEndReachedThreshold={0.3}
      refreshing={refreshing}
      onRefresh={() => void handleRefreshing()}
      contentContainerStyle={!displayOnlyLatestUpdate && (noUpdates || (loadingUpdates && !data)) ? styles.emptyStateFullScreen : styles.root}
      {...flatListProps}
    />
  );
};
