import React, { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { FlatList, ScrollView } from "react-native";
import { useTheme } from "react-native-paper";

import { CollabSuccessType } from "@app/common/enums/collab-success-type.enum";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStartCampaign } from "@app/hooks/api/products/use-start-campaign.hook";
import { isWeb } from "@app/utils/device.util";

import { SuperplanAlert } from "../../superplans/superplan-alert/superplan-alert.component";

import { styles } from "./start-campaign-confirmation-dialog.style";

interface Props {
  collabId: string;
  visible: boolean;
  onDismiss: () => void;
}

export const StartCampaignConfirmationDialog: FC<Props> = ({ collabId, visible, onDismiss }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();
  const { showErrorSnackbar } = useSnackbarContext();

  const { startCampaign, loading: loadingStartCampaign } = useStartCampaign();

  const handleStartCampaign = (): void => {
    void startCampaign({
      variables: { collabId },
      onCompleted: () => {
        onDismiss();
        navigation.navigate(Routes.collabSuccess, { collabId, type: CollabSuccessType.campaignStarted });
      },
      onError: err => showErrorSnackbar({ error: err }),
    });
  };

  return (
    <ConfirmationDialog
      visible={visible}
      dismissable
      title={t("startCampaign.confirmation.title")}
      message={t("startCampaign.confirmation.message")}
      additionalContent={
        <>
          <ScrollView
            contentContainerStyle={styles.disclaimerContentContainer}
            showsVerticalScrollIndicator={false}
            style={{ backgroundColor: colors.surfaceVariant, borderRadius: roundness }}>
            <Text variant="body2">{t("startCampaign.confirmation.disclaimer.title")}</Text>
            <FlatList
              data={t("startCampaign.confirmation.disclaimer.points", { returnObjects: true })}
              renderItem={({ item }) => (
                <ListItem
                  title={
                    <Trans
                      parent={({ children }: ChildrenProp) => <Text variant="body2">{children}</Text>}
                      components={{
                        bold: (
                          <Text variant="h9">
                            <></>
                          </Text>
                        ),
                      }}>
                      {item}
                    </Trans>
                  }
                  titleProps={{ variant: "body2", numberOfLines: 0 }}
                  left={<Text variant="body2">{"\u2022"}</Text>}
                  contentContainerStyle={styles.bulletPointContainer}
                />
              )}
              contentContainerStyle={styles.bulletPointsContainer}
              scrollEnabled={isWeb}
            />
          </ScrollView>

          <SuperplanAlert
            superplanType={SuperplanType.superseller}
            origin={ViewSuperplanScreenOrigin.startCampaignDialog}
            onLearnMorePress={tab => navigation.navigate(Routes.superplans, { tab })}
          />
        </>
      }
      confirmProps={{
        label: t("startCampaign.confirmation.startCta"),
        loading: loadingStartCampaign,
        onPress: handleStartCampaign,
        color: "primary",
      }}
      cancelProps={{
        label: t("startCampaign.confirmation.dismissCta"),
      }}
      onDismiss={onDismiss}
    />
  );
};
