import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    paddingVertical: 12,
  },
  wouldBuyContainer: {
    rowGap: 4,
  },
});
