import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { REACT_APP_BLOOM_WEBSITE } from "@env";
import branch, { DeepLinkData } from "branch-sdk";
import * as Linking from "expo-linking";

import { links } from "@app/common/constants/links.const";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { branchDeepViewName, GeneratedLinkData, logBranch } from "@app/hooks/utils/deeplink/branch.common";
import { useBranchEventHandler } from "@app/hooks/utils/deeplink/use-branch-event-handler.hook";
import { logToSentry } from "@app/utils/log-sentry.util";

import { BranchContext, BranchContextInterface } from "./branch.context";

export const BranchProvider: FC<ChildrenProp> = ({ children }) => {
  const { setupBranch, channel } = useBranchEventHandler();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    return setupBranch(() => setIsReady(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDeepLink = useCallback(
    (
      path: string,
      previewThumbnail?: GeneratedLinkData,
      feature?: string,
      campaign?: string,
      tags?: string[],
      data?: DeepLinkData["data"],
    ): Promise<string> => {
      if (!isReady) {
        logBranch.debug("generateDeepLink called but branch not ready");
      }

      return new Promise((resolve, reject) => {
        branch.link(
          {
            feature,
            campaign,
            tags,
            data: {
              ...data,
              /* eslint-disable @typescript-eslint/naming-convention */
              $android_deepview: branchDeepViewName,
              $android_passive_deepview: branchDeepViewName,
              $android_url: links.external.playStore,
              $canonical_identifier: path,
              $deeplink_path: path,
              $fallback_url: REACT_APP_BLOOM_WEBSITE,
              $ios_deepview: branchDeepViewName,
              $ios_passive_deepview: branchDeepViewName,
              $ios_url: links.external.appStore,

              $og_description: previewThumbnail?.description,
              $og_image_url: previewThumbnail?.imageUrls?.[0],
              $og_title: previewThumbnail?.title,
              $og_url: previewThumbnail?.title ? window.location.href : undefined,
              $og_video: previewThumbnail?.video,
              $twitter_description: previewThumbnail?.description,
              $twitter_title: previewThumbnail?.title,
              twitter_image_url: previewThumbnail?.imageUrls?.[0],
              /* eslint-enable @typescript-eslint/naming-convention */
            },
          },
          (error, link) => {
            if (error || link == null) {
              logBranch.error(error);
              const fallbackErrorMessage = `Missing link when trying to open Branch deep link: ${path}`;
              const linkError = new Error(error ?? fallbackErrorMessage);
              logToSentry(linkError, { extra: { message: fallbackErrorMessage, path, link, error } });

              return reject(linkError);
            }

            return resolve(link);
          },
        );
      });
    },
    [isReady],
  );

  const openDeepLink = useCallback(
    async (path: string, data?: GeneratedLinkData, feature?: string, campaign?: string): Promise<void> => {
      if (!isReady) {
        logBranch.debug("openDeepLink called but branch not ready");
      }
      const link = await generateDeepLink(path, data, feature, campaign);
      if (link) {
        await Linking.openURL(link);
      }
    },
    [generateDeepLink, isReady],
  );

  const contextValue = useMemo<BranchContextInterface>(
    () => ({ isReady, openDeepLink, generateDeepLink, channel }),
    [channel, generateDeepLink, isReady, openDeepLink],
  );

  return <BranchContext.Provider value={contextValue}>{children}</BranchContext.Provider>;
};
