import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { orderFields } from "@app/common/graphql/fragments.graphql";
import { Order } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  chargeCustomerForPreorder: Order;
}

interface Variables {
  orderId: string;
}

interface ChargeCustomerForPreorderResult extends MutationResult<Response, "chargeCustomerForPreorder"> {
  chargeCustomerForPreorder: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const chargeCustomerForPreorderMutation = gql`
  mutation ChargeCustomerForPreorder($orderId: OrderId!) {
    chargeCustomerForPreorder(orderId: $orderId) {
      ...OrderFields
    }
  }
  ${orderFields}
`;

export function useChargeCustomerForPreorder(): ChargeCustomerForPreorderResult {
  const [chargeCustomerForPreorder, { data, loading, error }] = useMutation<Response, Variables>(chargeCustomerForPreorderMutation);

  return { chargeCustomerForPreorder, data: data?.chargeCustomerForPreorder, loading, error };
}
