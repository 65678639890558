import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  aiChip: {
    borderRadius: 64,
    marginTop: 8,
  },
  imageContent: {
    justifyContent: "flex-end",
  },
  imageTabsContainer: {
    alignSelf: "center",
    columnGap: 8,
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  loading: {
    alignSelf: "center",
    flex: 1,
  },
  previewImage: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  topImageContent: {
    alignItems: "flex-start",
    left: 0,
    marginHorizontal: 20,
    position: "absolute",
    right: 0,
    top: 8,
  },
  touchableZone: {
    alignSelf: "flex-start",
    position: "absolute",
  },
});
