import React, { FC, useEffect, useRef } from "react";

import LottieView from "lottie-react-native";
import { useTheme } from "react-native-paper";

import floatingHeartIconAnimatedDark from "@app/assets/lotties/floating-heart-filled-dark.json";
import floatingHeartIconAnimatedLight from "@app/assets/lotties/floating-heart-filled-light.json";
import heartIconAnimatedDark from "@app/assets/lotties/heart-filled-dark.json";
import heartIconAnimatedLight from "@app/assets/lotties/heart-filled-light.json";

interface Props {
  liked: boolean;
  size?: number;
  floating?: boolean;
}

export const AnimatedLikeIcon: FC<Props> = ({ size = 20, liked, floating = false }) => {
  const { dark } = useTheme();
  const iconAnimation = useRef<LottieView>(null);

  const darkIcon = floating ? floatingHeartIconAnimatedDark : heartIconAnimatedDark;
  const lightIcon = floating ? floatingHeartIconAnimatedLight : heartIconAnimatedLight;

  useEffect(() => {
    if (liked) {
      iconAnimation.current?.play();
    } else {
      iconAnimation.current?.reset();
    }
  }, [liked]);

  const iconSize = size + 4;
  return (
    <LottieView
      ref={iconAnimation}
      autoPlay={liked}
      loop={false}
      style={{ height: iconSize, width: iconSize }}
      source={dark ? darkIcon : lightIcon}
    />
  );
};
