import Constants from "expo-constants";
import * as Device from "expo-device";

import { getAppVersion } from "@app/utils/app.util";
import { isAndroidApp, isIosApp, isWeb } from "@app/utils/device.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

type PlatformType = "inApp" | "web" | "ios" | "android" | "app";

const getCurrentPlatform = (): PlatformType => {
  if (isLikelyInEmbeddedWebview()) return "inApp";

  if (isWeb) return "web";

  if (isIosApp) return "ios";
  if (isAndroidApp) return "android";
  return "app";
};

export const platformHeaderValue = getCurrentPlatform();

export const defaultMixPanelValues = {
  appVersion: getAppVersion(),
  osName: Device.osName,
  deviceType: Device.deviceType,
  platform: platformHeaderValue,
  modelName: Device.modelName,
  osVersion: Device.osVersion,
  totalMemory: Device.totalMemory,
  // Allow to find matching sentry
  sentryDeviceId: Constants.sessionId,
};
