import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { commentFields } from "@app/common/graphql/fragments.graphql";
import { Comment, CreateCommentInput, PaginatedComments } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  createCollabUpdateComment: Comment;
}

interface Variables {
  collabUpdateId: string;
  input: CreateCommentInput;
  userId: string;
}

interface CreateCollabUpdateCommentResult extends MutationResult<Response, "createCollabUpdateComment"> {
  createCollabUpdateComment: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createCollabUpdateCommentMutation = gql`
  mutation CreateCollabUpdateComment($collabUpdateId: CollabUpdateId!, $input: CreateCommentInput!, $userId: UserId!) {
    createCollabUpdateComment(collabUpdateId: $collabUpdateId, input: $input, userId: $userId) {
      ...CommentFields
    }
  }
  ${commentFields}
`;

export function useCreateCollabUpdateComment(): CreateCollabUpdateCommentResult {
  const [createCollabUpdateComment, { loading, error, data }] = useMutation<Response, Variables>(createCollabUpdateCommentMutation, {
    update(cache, results, options) {
      const collabUpdateId = options.variables?.collabUpdateId ?? "";

      cache.modify({
        fields: {
          collabUpdateComments: (existingComments: PaginatedComments, { storeFieldName, readField, toReference }) => {
            const newCommentId = results.data?.createCollabUpdateComment.commentId;

            if (!storeFieldName.includes(collabUpdateId)) return existingComments;

            const alreadyExist = existingComments.nodes?.findIndex(comment => readField("commentId", comment) === newCommentId) !== -1;
            if (!newCommentId || alreadyExist) return existingComments;

            return {
              ...existingComments,
              nodes: [toReference(newCommentId), ...(existingComments.nodes ?? [])],
              totalCount: existingComments.totalCount + 1,
            };
          },
        },
      });

      cache.modify({
        id: collabUpdateId,
        fields: {
          numberOfComments: (currentCount: number) => currentCount + 1,
        },
      });
    },
  });

  return { createCollabUpdateComment, loading, error, data: data?.createCollabUpdateComment };
}
