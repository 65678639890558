/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { add } from "date-fns";
import { FloppyDisk } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, TouchableOpacity } from "react-native";
import { List, Switch, useTheme } from "react-native-paper";

import { ContestRewardType, ContestStatus, CreateContestInput } from "@app/common/graphql/generated/schema.graphql";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { DateTimePicker } from "@app/components/common/date-time-picker/date-time-picker.component";
import { ImagePicker } from "@app/components/common/image-picker/image-picker.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput, TextInputProps } from "@app/components/common/text-input/text-input.component";
import { ManageContestPasswordButtons } from "@app/components/god-mode/manage-contest-password-buttons/manage-contest-password-buttons.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useContest } from "@app/hooks/api/contests/use-contest.hook";
import { useUpdateContest } from "@app/hooks/api/contests/use-update-contest.hook";
import { useUploadContestImage } from "@app/hooks/api/contests/use-upload-contest-image.hook";
import { usePromoteCollabsToConceptOnContestEnd } from "@app/hooks/api/god-mode/use-promote-collab-to-concept-end.hook";
import { useCategories } from "@app/hooks/api/use-categories.hook";
import { useCreateContest } from "@app/hooks/api/use-create-contest.hook";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { styles } from "./create-contest.style";

interface ContestInput extends Omit<CreateContestInput, "numberOfWinners"> {
  numberOfWinners?: string;
}

export const CreateContestScreen: FC<NavigationProps<Routes.createContest>> = ({ navigation, route }) => {
  const contestId = route.params && route.params.contestId !== "undefined" ? route.params.contestId : undefined;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const rewardTypesOptionsSheetRef = useRef<BottomSheetRefProps>(null);
  const categoriesOptionsSheetRef = useRef<BottomSheetRefProps>(null);

  const [input, setInput] = useState<Partial<ContestInput>>({});
  const updateInput = (key: keyof ContestInput) => (value?: string | boolean) => setInput(prev => ({ ...prev, [key]: value }));
  const [startAt, setStartAt] = useState<Date>(add(new Date(), { hours: 1 }));
  const [endAt, setEndAt] = useState<Date>(add(new Date(), { days: 7 }));
  const [imageToUpload, setImageToUpload] = useState<string | undefined>(undefined);

  const { data: categories } = useCategories({ variables: {} });
  const { createContest, loading: loadingCreate } = useCreateContest();
  const { updateContest, loading: loadingUpdate } = useUpdateContest();
  const { uploadImage, loading: loadingUploadImage } = useUploadContestImage();
  const { data: contest, loading: loadingContest } = useContest({
    variables: { contestId },
    onCompleted: response => {
      setInput({ ...response.contest, categoryId: response.contest.category?.categoryId });
      setImageToUpload(response.contest.imageUrl);
      setStartAt(new Date(response.contest.goLiveDate));
      setEndAt(new Date(response.contest.endOfVoteDate));
    },
    onError: error => {
      showErrorSnackbar({ message: error.message });
      navigation.goBack();
    },
  });
  const { promoteCollabsToConceptOnContestEnd, loading: loadingPromote } = usePromoteCollabsToConceptOnContestEnd();

  const isUpdating = !!contestId;
  const isLoading = loadingCreate || loadingUpdate || loadingUploadImage || loadingPromote;

  useEffect(() => {
    if (isUpdating) {
      navigation.setOptions({
        title: t("godMode.contest.edit"),
      });
    }
  }, [isUpdating, navigation, t]);

  const handleSuccess = useCallback(
    (message: string): void => Alert.alert(t("godMode.success"), message, [{ text: t("cta.ok"), onPress: () => navigation.goBack() }]),
    [navigation, t],
  );

  const handleUploadImage = useCallback(
    (id: string, successMessage: string, errorMessage: string): void => {
      if (!imageToUpload) return;

      generateImageToFormData(imageToUpload)
        .then(form =>
          uploadImage({ variables: { input: form, contestId: id } })
            .then(() => {
              handleSuccess(successMessage);
            })
            .catch(() => showErrorSnackbar({ message: errorMessage })),
        )
        .catch(() => showErrorSnackbar({ message: errorMessage }));
    },
    [handleSuccess, imageToUpload, showErrorSnackbar, uploadImage],
  );

  const handleCreateContest = useCallback((): void => {
    const { description, name, categoryId, rewardType, rewardValue, isHidden, password, numberOfWinners } = input;

    if (!description || !name || !rewardType || !startAt || !endAt) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.contest.imageRequired"));
      return;
    }

    void createContest({
      variables: {
        input: {
          description,
          isHidden: !!isHidden,
          name,
          categoryId,
          rewardType,
          rewardValue,
          goLiveDate: startAt?.toISOString(),
          endOfVoteDate: endAt?.toISOString(),
          password,
          numberOfWinners: numberOfWinners ? parseInt(numberOfWinners) : undefined,
        },
      },
      onCompleted: data => {
        handleUploadImage(data.createContest.contestId, t("godMode.contest.created"), t("godMode.contest.createdButImageFailed"));
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [input, startAt, endAt, imageToUpload, createContest, t, handleUploadImage, showErrorSnackbar]);

  const handleUpdateContest = useCallback((): void => {
    if (!contest) return;

    const { description, name, categoryId, rewardType, rewardValue, isHidden, numberOfWinners } = input;

    if (!description || !name || !rewardType || !startAt || !endAt) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.contest.imageRequired"));
      return;
    }

    void updateContest({
      variables: {
        input: {
          description,
          isHidden: !!isHidden,
          name,
          categoryId,
          rewardType,
          rewardValue,
          goLiveDate: startAt?.toISOString() ?? null,
          endOfVoteDate: endAt?.toISOString() ?? null,
          numberOfWinners: numberOfWinners ? parseInt(numberOfWinners) : undefined,
        },
        contestId: contest.contestId,
      },
      onCompleted: data => {
        if (imageToUpload && imageToUpload !== contest.imageUrl) {
          handleUploadImage(data.updateContest.contestId, t("godMode.contest.updated"), t("godMode.contest.updatedButImageFailed"));
        } else {
          handleSuccess(t("godMode.contest.updated"));
        }
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [contest, input, startAt, endAt, updateContest, t, imageToUpload, handleUploadImage, handleSuccess, showErrorSnackbar]);

  const handleSelectRewardType = (value: ContestRewardType): void => {
    updateInput("rewardType")(value);
    rewardTypesOptionsSheetRef?.current?.close();
  };

  const handleSelectCategory = (value: string): void => {
    updateInput("categoryId")(value);
    categoriesOptionsSheetRef?.current?.close();
  };

  const handlePromoteClick = (): void => {
    if (!contestId) return;
    void promoteCollabsToConceptOnContestEnd({
      variables: { contestId },
      onCompleted: () => handleSuccess(t("godMode.contest.updated")),
      onError: error => showErrorSnackbar({ error, message: error.message }),
    });
  };

  const commonProps = (name: keyof ContestInput): Partial<TextInputProps> => ({
    label: name,
    loading: !!contestId && loadingContest,
    value: input[name] as string | undefined,
  });

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <TextInput {...commonProps("name")} required onChangeText={updateInput("name")} />
      <TextInput {...commonProps("categoryId")} required editable={false} onPressIn={() => categoriesOptionsSheetRef?.current?.open()} multiline />
      <TextInput {...commonProps("description")} required onChangeText={updateInput("description")} multiline numberOfLines={12} />
      <TextInput {...commonProps("rewardType")} required editable={false} onPressIn={() => rewardTypesOptionsSheetRef?.current?.open()} />
      <TextInput {...commonProps("rewardValue")} onChangeText={updateInput("rewardValue")} />
      {contest?.status !== ContestStatus.completed && (
        <TextInput {...commonProps("numberOfWinners")} onChangeText={updateInput("numberOfWinners")} keyboardType="numeric" />
      )}

      {isUpdating ? (
        <ManageContestPasswordButtons contestId={contestId} passwordRequired={contest?.passwordRequired} />
      ) : (
        <TextInput {...commonProps("password")} onChangeText={updateInput("password")} />
      )}

      <List.Item title={"isHidden"} right={() => <Switch color={colors.tertiary} value={input.isHidden} onValueChange={updateInput("isHidden")} />} />

      {isUpdating && contest?.status === ContestStatus.completed && (
        <Button fullWidth loading={isLoading} mode="contained" onPress={handlePromoteClick}>
          {t("godMode.contest.moveEntriesToConcept")}
        </Button>
      )}

      <Box rowGap={8} paddingVertical={16}>
        <List.Item title={"goLiveAt"} right={() => <DateTimePicker date={startAt} setDate={setStartAt} />} />
        <List.Item title={"endOfVoteAt"} right={() => <DateTimePicker date={endAt} setDate={setEndAt} />} />
      </Box>

      <ImagePicker image={imageToUpload} setImage={setImageToUpload} style={styles.editImageContainer} />

      <Button
        icon={FloppyDisk}
        loading={isLoading}
        fullWidth
        mode="contained"
        size="large"
        onPress={isUpdating ? handleUpdateContest : handleCreateContest}>
        {isUpdating ? t("godMode.contest.update") : t("godMode.contest.create")}
      </Button>

      <BottomSheet ref={rewardTypesOptionsSheetRef}>
        <FlatList
          data={Object.keys(ContestRewardType) as ContestRewardType[]}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => handleSelectRewardType(item)} style={styles.bottomSheetRow}>
              <Text>{t(`enum.contestRewardType.${item}`)}</Text>
            </TouchableOpacity>
          )}
        />
      </BottomSheet>

      <BottomSheet ref={categoriesOptionsSheetRef}>
        <FlatList
          data={categories?.nodes?.map(c => ({ id: c.categoryId, label: c.name }))}
          renderItem={({ item: { label, id } }) => (
            <TouchableOpacity onPress={() => handleSelectCategory(id)} style={styles.bottomSheetRow}>
              <Text>{label}</Text>
            </TouchableOpacity>
          )}
        />
      </BottomSheet>
    </ScreenWrapper>
  );
};
