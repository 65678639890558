import { createContext, useContext } from "react";

import { ApolloError } from "@apollo/client";

import { FeatureAccesses, Profile } from "@app/common/graphql/generated/schema.graphql";
import type { Response as UserStateResponse } from "@app/hooks/api/use-update-user-state.hook";

import { UserState, UserStateEnum } from "./user-state.enum";

export const userStateValues = Object.values(UserStateEnum);

export interface UpdateUserState {
  onCompleted?: (data: UserStateResponse) => void;
  onError?: (e: ApolloError) => void;
  input: Partial<UserState>;
}

export interface ProfileContextType {
  profile?: Profile;
  loading: boolean;
  loadingUpdateState: boolean;
  state?: UserState;
  updateState: (options: UpdateUserState) => void;
  setReferrerId: (referrerId: string) => void;
  featureAccesses?: FeatureAccesses;
}

export const ProfileContext = createContext<ProfileContextType>({
  loading: true,
  loadingUpdateState: false,
  updateState: () => undefined,
  setReferrerId: () => undefined,
});

export const useProfileContext = (): ProfileContextType => useContext(ProfileContext);
