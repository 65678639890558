import React from "react";

import { Trophy } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { TextInputProps } from "@app/components/common/text-input/text-input.component";
import { Suggestion, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

import { useContests } from "../api/contests/use-contests.hook";
import { useCategories } from "../api/use-categories.hook";

export enum CollabFieldTypeEnum {
  input = "input",
  suggestion = "suggestion",
}

interface CollabInputSuggestion {
  type: CollabFieldTypeEnum.suggestion;
  suggestions?: Suggestion[];
  loading: boolean;
  update: (value: Suggestion) => void;
  value?: Suggestion | null;
  emptyState?: React.ReactElement;
}

interface CollabInputFieldValidation {
  type: CollabFieldTypeEnum.input;
  inputProps: TextInputProps;
  update: (value: string) => void;
  value?: string;
}

export type CollabFieldValidation = {
  contest: CollabInputSuggestion;
  productCategory: CollabInputSuggestion;
  productName: CollabInputFieldValidation;
  productDescription: CollabInputFieldValidation;
};

export function useCreateCollabInputValidation<T extends keyof CollabFieldValidation>(field: T | undefined): CollabFieldValidation[T] | undefined {
  const { t } = useTranslation();
  const {
    inputs: { productName, productDescription, productCategory, contest },
    setInputs,
  } = useStudioMiniContext();

  const { data: categories, loading: loadingCategories } = useCategories({ variables: { first: 25 } });
  const { data: contests, loading: loadingContests } = useContests({ variables: { where: { status: ContestStatus.inProgress } } });

  const validations: CollabFieldValidation = {
    productCategory: {
      loading: loadingCategories,
      suggestions: categories?.nodes?.map(c => ({ id: c.categoryId, name: c.name, image: c.imageUrl })),
      type: CollabFieldTypeEnum.suggestion,
      update: (value: Suggestion) => setInputs(prev => ({ ...prev, productCategory: value })),
      value: productCategory,
    },
    productName: {
      inputProps: {
        label: t("studioMini.fields.productName.label"),
        required: true,
        maxLength: 50,
        includeCharacterCount: true,
      },
      type: CollabFieldTypeEnum.input,
      update: (value: string) => setInputs(prev => ({ ...prev, productName: value })),
      value: productName,
    },
    productDescription: {
      inputProps: {
        label: t("studioMini.fields.productDescription.placeholder"),
        required: true,
        maxLength: 2000,
        multiline: true,
        numberOfLines: 12,
        includeCharacterCount: true,
      },
      type: CollabFieldTypeEnum.input,
      update: (value: string) => setInputs(prev => ({ ...prev, productDescription: value })),
      value: productDescription,
    },
    contest: {
      loading: loadingContests,
      suggestions: contests?.nodes?.map(c => ({
        id: c.contestId,
        name: c.name,
        image: c.imageUrl,
        handle: c.handle,
        passwordRequired: c.passwordRequired,
        category: c.category,
      })),
      type: CollabFieldTypeEnum.suggestion,
      update: (value: Suggestion) => setInputs(prev => ({ ...prev, contest: value })),
      value: contest,
      emptyState: <EmptyState icon={Trophy} message={t("emptyState.contests", { context: ContestStatus.inProgress })} />,
    },
  };

  return field ? validations[field] : undefined;
}
