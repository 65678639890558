import { ApolloError } from "@apollo/client";

export function isErrorCode(error: ApolloError, errorCode: string | string[]): boolean {
  if (typeof errorCode === "string") return !!error.graphQLErrors?.some(({ extensions }) => extensions.code === errorCode);

  return !!error.graphQLErrors?.some(({ extensions }) => extensions.code && errorCode.includes(extensions.code as string));
}

export function isErrorMessage(error: ApolloError, errorMessage: string): boolean {
  return !!error.graphQLErrors?.find(({ message }) => message === errorMessage);
}

export function isNetworkError(error: ApolloError): boolean {
  return !!error.networkError || error.message === "Failed to fetch";
}

export function isNotFound(error: ApolloError): boolean {
  return (
    isErrorCode(error, "FORBIDDEN") ||
    isErrorCode(error, "NOT_FOUND") ||
    isErrorCode(error, "INVALID_SCALAR") ||
    isErrorCode(error, "ADMIN_ROLE_REQUIRED")
  );
}
