import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { preorderFields } from "@app/common/graphql/fragments.graphql";
import { CollabCampaign, CreatePreorderForAnonymousInput, Preorder } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  createPreorderForAnonymous: Preorder;
}

interface Variables {
  input: CreatePreorderForAnonymousInput;
  anonymousCustomerId: string;
}

interface CreatePreorderForAnonymousResult extends MutationResult<Response, "createPreorderForAnonymous"> {
  createPreorderForAnonymous: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createPreorderForAnonymousMutation = gql`
  mutation CreatePreorderForAnonymous($anonymousCustomerId: AnonymousCustomerId!, $input: CreatePreorderForAnonymousInput!) {
    createPreorderForAnonymous(anonymousCustomerId: $anonymousCustomerId, input: $input) {
      ...PreorderFields
    }
  }
  ${preorderFields}
`;

export function useCreatePreorderForAnonymous(collabId: string): CreatePreorderForAnonymousResult {
  const [createPreorderForAnonymous, { loading, error, data }] = useMutation<Response, Variables>(createPreorderForAnonymousMutation, {
    update(cache, _results, options) {
      cache.modify({
        id: collabId,
        fields: {
          campaign: (currentCampaign: CollabCampaign) => {
            return {
              ...currentCampaign,
              orderCount: currentCampaign.orderCount + (options.variables?.input.quantity ?? 1),
            };
          },
        },
      });
    },
  });

  return { createPreorderForAnonymous, loading, error, data: data?.createPreorderForAnonymous };
}
