import React, { FC, ReactNode } from "react";

import { CheckSquare, IconProps, Square } from "phosphor-react-native";
import { View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";

import { Text } from "../../text/text.component";

import { styles } from "./step-item.style";

export interface StepItemProps extends ViewProps {
  isChecked: boolean;
  label: string;
  icon?: (props: IconProps) => ReactNode;
}

export const StepItem: FC<StepItemProps> = ({ isChecked, label, icon, style, ...viewProps }) => {
  const { colors } = useTheme();

  const iconProps = { size: 24, color: colors.onBackground };

  return (
    <View style={[styles.root, style]} {...viewProps}>
      <View style={styles.iconWrapper}>
        {icon ? icon({ weight: "fill", ...iconProps }) : isChecked ? <CheckSquare {...iconProps} weight="fill" /> : <Square {...iconProps} />}
      </View>
      <Text variant="body2" textAlign="center" style={styles.label}>
        {label}
      </Text>
    </View>
  );
};
