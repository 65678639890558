export enum LocalStorageKeys {
  anonymousCartId = "anonymousCartId",
  contestPasswords = "contestPasswords",
  earlyBirdAlertDismissed = "earlyBirdAlertDismissed",
  emailForSignIn = "emailForSignIn",
  featureAnnouncementsViewed = "featureAnnouncementsViewed",
  firstOpenEventDate = "firstOpenEventDate",
  pushNotifsPermissionSeenOnce = "pushNotifsPermissionSeenOnce",
}

export interface LocalStorageInterface {
  [LocalStorageKeys.anonymousCartId]: string | undefined;
  [LocalStorageKeys.contestPasswords]: Record<string, string>;
  [LocalStorageKeys.earlyBirdAlertDismissed]: boolean;
  [LocalStorageKeys.emailForSignIn]: string;
  [LocalStorageKeys.featureAnnouncementsViewed]: string[];
  [LocalStorageKeys.firstOpenEventDate]: number | undefined;
  [LocalStorageKeys.pushNotifsPermissionSeenOnce]: boolean;
}

export const defaultLocalStorageValues: LocalStorageInterface = {
  [LocalStorageKeys.anonymousCartId]: undefined,
  [LocalStorageKeys.contestPasswords]: {},
  [LocalStorageKeys.earlyBirdAlertDismissed]: false,
  [LocalStorageKeys.emailForSignIn]: "",
  [LocalStorageKeys.featureAnnouncementsViewed]: [],
  [LocalStorageKeys.firstOpenEventDate]: undefined,
  [LocalStorageKeys.pushNotifsPermissionSeenOnce]: false,
};
