import React, { FC, ReactNode } from "react";

import { StyleProp, TouchableHighlight, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Text, TextProps } from "@app/components/common/text/text.component";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { AvatarImage, AvatarImageProps } from "../avatar-image/avatar-image.component";

import { styles } from "./list-item.style";

type ImageProps = Omit<AvatarImageProps, "fallbackText"> & { fallbackText?: string };

export interface ListItemProps {
  title: ReactNode;
  subtitle?: ReactNode;
  description?: ReactNode;
  imageProps?: ImageProps;
  titleProps?: Omit<TextProps, "children">;
  descriptionProps?: Omit<TextProps, "children">;
  subtitleProps?: Omit<TextProps, "children">;
  left?: ReactNode;
  right?: ReactNode;
  contentContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  testId?: string;
}

export const ListItem: FC<ListItemProps> = ({
  title,
  subtitle,
  description,
  imageProps,
  titleProps,
  descriptionProps,
  subtitleProps,
  right,
  left,
  contentContainerStyle,
  textContainerStyle,
  style,
  onPress,
  testId,
}) => {
  const { colors } = useTheme();

  const formatFallbackText = (definedImageProps: ImageProps): string => {
    const originalFallback = definedImageProps.fallbackText ?? (typeof title === "string" ? title : "");
    return originalFallback.slice(0, definedImageProps.size && definedImageProps.size > 40 ? 2 : 1).toUpperCase();
  };

  const leftIconWithTexts = (
    <View style={[styles.rightIconAndText, contentContainerStyle, ...conditionalItem(!right, style)]}>
      {imageProps ? <AvatarImage {...imageProps} fallbackText={formatFallbackText(imageProps)} /> : left ? <View>{left}</View> : null}
      <View style={[styles.textContainer, contentContainerStyle, textContainerStyle]}>
        {typeof title === "string" ? (
          <Text numberOfLines={2} {...titleProps}>
            {title}
          </Text>
        ) : (
          title
        )}
        {!!subtitle &&
          (typeof subtitle === "string" ? (
            <Text numberOfLines={2} {...subtitleProps}>
              {subtitle}
            </Text>
          ) : (
            subtitle
          ))}
        {!!description &&
          (typeof description === "string" ? (
            <Text numberOfLines={2} {...descriptionProps}>
              {description}
            </Text>
          ) : (
            description
          ))}
      </View>
    </View>
  );

  return (
    <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={onPress} testID={testId}>
      {right ? (
        <View style={[styles.root, contentContainerStyle, style]}>
          {leftIconWithTexts}
          <View>{right}</View>
        </View>
      ) : (
        leftIconWithTexts
      )}
    </TouchableHighlight>
  );
};
