import React, { FC, useCallback, useEffect, useState } from "react";

import * as Clipboard from "expo-clipboard";
import { LinearGradient } from "expo-linear-gradient";
import * as Linking from "expo-linking";
import { Link, InstagramLogo } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import { CollabImage } from "@app/common/graphql/generated/schema.graphql";
import { instagramGradient } from "@app/common/style/common.style";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useGenerateCollabImageDownloadLink } from "@app/hooks/api/use-generate-collab-image-download-link.hook";
import { useTrackSharing } from "@app/hooks/api/use-track-sharing.hook";
import { isAndroidApp, isIosApp } from "@app/utils/device.util";
import { checkIfInstagramOnAndroidInstalled, getShareUrl, shareToInstagramStory } from "@app/utils/share/share.util";

import { Text } from "../text/text.component";

import { styles } from "./share-to-instagram-story-button.style";

interface Props {
  image: CollabImage | undefined;
  entityId: string;
  shareId?: string;
  handle?: string;
}

export const ShareToInstagramStoryButton: FC<Props> = ({ image, entityId, handle, shareId }) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();

  const [loading, setLoading] = useState(false);
  const [instagramInstalled, setInstagramInstalled] = useState<boolean>(false);
  const [link, setLink] = useState("");

  const { generateCollabImageDownloadLink, loading: loadingImageDownloadLink } = useGenerateCollabImageDownloadLink();
  const { trackSharing } = useTrackSharing();

  useEffect(() => {
    if (isAndroidApp) {
      checkIfInstagramOnAndroidInstalled(setInstagramInstalled);
    } else if (isIosApp) {
      Linking.canOpenURL("instagram://")
        .then(canOpenURL => setInstagramInstalled(canOpenURL))
        .catch(() => setInstagramInstalled(false));
    }
  }, []);

  const generateImageDownloadLink = useCallback(async () => {
    if (image) {
      const result = await generateCollabImageDownloadLink({
        variables: { collabImageId: image.collabImageId },
      }).catch(() => undefined);

      const newGeneratedImageUrl = result?.data?.generateCollabImageDownloadLink.imageUrl;

      return newGeneratedImageUrl ?? image.imageUrl;
    }
    return undefined;
  }, [generateCollabImageDownloadLink, image]);

  const handleOnPress = async (): Promise<void> => {
    setLoading(true);

    try {
      const imageDownloadLink = await generateImageDownloadLink();

      if (!image || !imageDownloadLink) return;

      setLink(imageDownloadLink);

      await shareToInstagramStory(imageDownloadLink, image.collabImageId);

      void trackSharing({ variables: { input: { destination: "instagramStory", entityId } } });
    } catch (e: unknown) {
      showErrorSnackbar({ error: e as Error });
    }

    setLoading(false);
  };

  const handleCopy = (): void => {
    Clipboard.setUrlAsync(getShareUrl(entityId, handle, "collab", shareId))
      .then(() => showSuccessSnackbar({ message: t("success.linkCopied") }))
      .catch(() => undefined);
  };

  if (!instagramInstalled) return null;

  return (
    <View style={styles.root}>
      <View style={styles.buttonsContainer}>
        <LinearGradient
          colors={instagramGradient}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          style={[{ borderRadius: roundness }, styles.instagramButton]}>
          <Pressable style={[styles.button, { borderRadius: roundness }]} onPress={() => void handleOnPress()}>
            {({ pressed }) => (
              <View style={[pressed ? { backgroundColor: colors.inverseOnSurface } : {}, styles.buttonContent]}>
                <Text variant="buttonLarge" color="common.white">
                  {t("share.shareStory")}
                </Text>
                {loading || loadingImageDownloadLink ? (
                  <ActivityIndicator color={colors.common.white} size={24} />
                ) : (
                  <InstagramLogo size={24} weight="fill" color={colors.common.white} />
                )}
              </View>
            )}
          </Pressable>
        </LinearGradient>

        <IconButton
          icon={props => <Link weight="fill" {...props} />}
          style={[styles.button, styles.copyButton, { borderRadius: roundness, backgroundColor: !link ? colors.surfaceDisabled : colors.onPrimary }]}
          iconColor={colors.primary}
          mode="contained"
          disabled={!link}
          onPress={handleCopy}
        />
      </View>
      <Text textAlign="center" variant="caption" color="common.gray">
        {t("share.shareHelperText")}
      </Text>
    </View>
  );
};
