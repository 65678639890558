import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";

import { useHeaderHeight } from "@react-navigation/elements";
import { LinearGradient } from "expo-linear-gradient";
import { X } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";
import { Route, TabBar, TabView } from "react-native-tab-view";

import gradient from "@app/assets/images/full-screen-superseller-gradient.png";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";
import { SuperplanDetails } from "@app/components/superplans/superplan-details/superplan-details.component";
import { useInAppPurchasesContext } from "@app/context/in-app-purchases/in-app-purchases.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./superplans.style";

const indexMap: Record<SuperplanType, number> = {
  [SuperplanType.supercreator]: 0,
  [SuperplanType.superseller]: 1,
};

export const SuperplansScreen: FC<NavigationProps<Routes.superplans | Routes.superseller>> = ({ navigation, route }) => {
  const { dark, roundness, colors } = useTheme();
  const { t } = useTranslation();
  const { width: screenWidth } = useWindowDimensions();
  const navHeight = useHeaderHeight();

  const { loading: loadingInAppPurchase } = useInAppPurchasesContext();

  const overlayGradientColors = dark ? ["rgba(0,0,0,0.60)", "rgba(0,0,0,0.50)"] : ["rgba(255,255,255,0.60)", "rgba(255,255,255,0.50)"];

  const currentTab = route.params?.tab;
  const [index, setIndex] = useState(currentTab && currentTab in indexMap ? indexMap[currentTab] : 0);
  const [routes] = useState<Route[]>([
    { key: SuperplanType.supercreator, title: t("supercreator.label") },
    { key: SuperplanType.superseller, title: t("superseller.label") },
  ]);

  useEffect(() => {
    if (route.name === Routes.superseller) {
      navigation.replace(Routes.superplans, { tab: SuperplanType.superseller });
    }
  }, [navigation, route]);

  const renderScene = ({ route: contestRoute }: { route: Route }): ReactNode => {
    switch (contestRoute.key) {
      case SuperplanType.supercreator:
        return <SuperplanDetails superplanType={SuperplanType.supercreator} />;
      case SuperplanType.superseller:
        return <SuperplanDetails superplanType={SuperplanType.superseller} />;
      default:
        return null;
    }
  };

  const handleIndexChange = useCallback(
    (tabIndex: number) => {
      setIndex(tabIndex);
      const tab = Object.keys(indexMap)[tabIndex] as SuperplanType;
      navigation.setParams({ tab });
    },
    [navigation],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon={X} onPress={() => navigation.goBack()} disabled={loadingInAppPurchase} aria-label="close-superplan" />,
      headerTransparent: true,
    });
  }, [loadingInAppPurchase, navigation]);

  const thickBackdrop = colors.backdrop.replace(",0.75)", ",0.9)");

  return (
    <>
      <View style={styles.background}>
        <Image source={gradient} width={screenWidth} style={styles.background} />
        <LinearGradient locations={[0, 1]} colors={overlayGradientColors} style={styles.background} />
      </View>

      <ScreenWrapper style={[styles.root, { marginTop: navHeight }]}>
        <Trans
          i18nKey="superplan.pick"
          parent={({ children }: ChildrenProp) => (
            <Text variant="body1" color="tertiary" textAlign="center">
              {children}
            </Text>
          )}
          components={{
            b: (
              <Text variant="body1" textAlign="center">
                <></>
              </Text>
            ),
          }}
        />
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={handleIndexChange}
          initialLayout={{ width: screenWidth }}
          renderTabBar={props => (
            <TabBar
              {...props}
              gap={4}
              renderIndicator={() => undefined}
              tabStyle={styles.tabContainer}
              style={styles.tabs}
              contentContainerStyle={styles.contentContainerTabs}
              renderLabel={({ route: routeFromLabel, focused }) => (
                <View
                  style={[
                    styles.tab,
                    { borderRadius: roundness, borderColor: colors.outline },
                    focused ? { backgroundColor: colors.onBackground } : undefined,
                  ]}>
                  <UserBadge type={routeFromLabel.key as SuperplanType} size={18} />
                  <Text variant="body1" textAlign="center" color={focused ? "background" : "onBackground"}>
                    {routeFromLabel.title}
                  </Text>
                </View>
              )}
            />
          )}
        />
      </ScreenWrapper>

      {loadingInAppPurchase && (
        <View style={[styles.fullScreen, styles.loading, { backgroundColor: thickBackdrop }]}>
          <ActivityIndicator size="large" color={colors.onBackground} />
          <Text variant="bodyLarge" textAlign="center" style={styles.loadingText}>
            {t("superseller.loadingMessage")}
          </Text>
        </View>
      )}
    </>
  );
};
