import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { ImageGenerationStatus } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useAuthContext } from "@app/context/auth/auth.context";

interface Response {
  imageGenerationStatus?: ImageGenerationStatus;
}

export const imageGenerationCreditQuery = gql`
  query ImageGenerationStatus {
    imageGenerationStatus {
      tokensLeft
      unlimited
    }
  }
`;

export function useImageGenerationCredit(options?: QueryHookOptions<Response>): QueryResult<Response, "imageGenerationStatus"> {
  const { state } = useAuthContext();

  const { data, loading, error } = useQuery<Response>(imageGenerationCreditQuery, {
    ...options,
    skip: options?.skip || !state.connected || !state.verified,
  });

  return { data: data?.imageGenerationStatus, loading, error };
}
