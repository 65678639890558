import React, { useCallback, useEffect } from "react";

import { useTheme } from "react-native-paper";
import { WebView } from "react-native-webview";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { useNewHomeNavigation } from "@app/hooks/utils/use-new-home-navigation.hook";
import { logoAsHeaderTitle } from "@app/router/router.const";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./webview.style";

export const WebViewScreen: React.FC<NavigationProps<Routes.webview>> = ({ navigation, route }) => {
  const { colors } = useTheme();

  const { navigateToMainScreen } = useNewHomeNavigation();

  const handleClose = useCallback((): void => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigateToMainScreen();
    }
  }, [navigateToMainScreen, navigation]);

  useEffect(() => {
    if (!route.params?.url) {
      handleClose();
    }
  }, [handleClose, navigation, route.params?.url]);

  useEffect(() => {
    navigation.setOptions({
      ...(route.params?.title ? { title: route.params.title } : logoAsHeaderTitle(colors.onBackground)),
      headerTintColor: colors.common.black,
      headerStyle: { backgroundColor: colors.common.white },
    });
  }, [colors, navigation, route.params]);

  return !isWeb ? <WebView style={styles.container} source={{ uri: route.params?.url ?? "" }} /> : null;
};
