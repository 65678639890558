import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  actionBackContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 8,
    width: "100%",
  },
  actionContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginLeft: "auto",
  },
});
