import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 24,
  },
  container: {
    flex: 1,
  },
  webContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    rowGap: 16,
  },
});
