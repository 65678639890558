import React, { FC } from "react";

import { Warning } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { Banner } from "@app/components/common/banner/banner.component";
import { Box } from "@app/components/common/box/box.component";
import { Text } from "@app/components/common/text/text.component";

interface Props {
  bannerVisible: boolean;
  setBannerVisible: (visible: boolean) => void;
}

export const PickVariantBanner: FC<Props> = ({ bannerVisible, setBannerVisible }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Banner visible={bannerVisible} setVisible={setBannerVisible} closeDelay={3000} style={{ backgroundColor: colors.error }}>
      <Box flexDirection="row" columnGap={8} justifyContent="center" width="100%" paddingHorizontal={20} paddingVertical={8}>
        <Warning weight="fill" color={colors.onError} />
        <Text variant="body2" color="onError">
          {t("error.noVariantSelected")}
        </Text>
      </Box>
    </Banner>
  );
};
