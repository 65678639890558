import { StyleSheet } from "react-native";

export const imageGap = 8;
export const screenPadding = 20;

export const styles = StyleSheet.create({
  dot: {
    borderWidth: 1,
  },
  horizontalContainer: {
    flexDirection: "row",
  },
  movingDot: {
    opacity: 1,
    position: "absolute",
  },
  verticalContainer: {
    flexDirection: "column",
  },
});
