import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { orderFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedOrders } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  orders: PaginatedOrders;
}

export const ordersQuery = gql`
  query Orders {
    orders {
      nodes {
        ...OrderFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${orderFields}
`;

export function useOrders(options: QueryHookOptions<Response>): QueryResult<Response, "orders"> {
  const { data, loading, error, refetch } = useQuery<Response>(ordersQuery, options);

  return { data: data?.orders, loading, error, refetch };
}
