import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { catalogProductFields } from "@app/common/graphql/fragments.graphql";
import { CatalogProduct } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteCatalogProduct: CatalogProduct;
}

interface Variables {
  catalogProductId: string;
}

interface DeleteCatalogProductResult extends MutationResult<Response, "deleteCatalogProduct"> {
  deleteCatalogProduct: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCatalogProductMutation = gql`
  mutation DeleteCatalogProductResult($catalogProductId: CatalogProductId!) {
    deleteCatalogProduct(catalogProductId: $catalogProductId) {
      ...CatalogProductFields
    }
  }
  ${catalogProductFields}
`;

export function useDeleteCatalogProduct(): DeleteCatalogProductResult {
  const [deleteCatalogProduct, { loading, error, data }] = useMutation<Response, Variables>(deleteCatalogProductMutation, {
    update: (cache, _result, options) => {
      cache.evict({ id: options.variables?.catalogProductId });
    },
  });

  return { deleteCatalogProduct, loading, error, data: data?.deleteCatalogProduct };
}
