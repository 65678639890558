import React, { PropsWithChildren, useMemo, FC } from "react";

import { StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";

import { styles } from "./onboarding-step.style";

interface Props {
  isActive: boolean;
  hasMultipleSteps: boolean;
}

export const OnboardingStep: FC<PropsWithChildren<Props>> = ({ isActive, hasMultipleSteps }) => {
  const { colors } = useTheme();

  const animatedWidthStyle = useAnimatedStyle(
    () => ({
      width: withTiming(isActive ? 12 : 3),
    }),
    [isActive],
  );

  const colorStyle = useMemo((): StyleProp<ViewStyle> => {
    if (!hasMultipleSteps) return { opacity: 0 };
    if (!isActive) return { backgroundColor: colors.onSecondaryContainer };

    return { backgroundColor: colors.onBackground };
  }, [colors.onBackground, colors.onSecondaryContainer, hasMultipleSteps, isActive]);

  return <Animated.View style={[styles.tab, colorStyle, animatedWidthStyle]} />;
};
