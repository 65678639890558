export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A Bloom Ad id beginning with 'bl_ad'. */
  AdId: { input: string; output: string };
  /** A Bloom AnonymousCart id beginning with 'bl_acart'. */
  AnonymousCartId: { input: string; output: string };
  /** A Bloom AnonymousCustomer id beginning with 'bl_acust'. */
  AnonymousCustomerId: { input: string; output: string };
  /** A Bloom CollabImage, StudioImage or UploadImage id. */
  AnyImageScalarId: { input: string; output: string };
  /** A Bloom CollabImage, StudioImage, UploadImage, StudioAnimation, CampaignVideo or UploadVideo id. */
  AnyMediaScalarId: { input: string; output: string };
  /** A Bloom BehindTheScenesVideo id beginning with 'bl_btsvid'. */
  BehindTheScenesVideoId: { input: string; output: string };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: number; output: number };
  /** A Bloom CampaignVideo id beginning with 'bl_camvid'. */
  CampaignVideoId: { input: string; output: string };
  /** A Bloom CartItem id beginning with 'bl_cartit'. */
  CartItemId: { input: string; output: string };
  /** A Bloom CatalogProduct id beginning with 'bl_cprd'. */
  CatalogProductId: { input: string; output: string };
  /** A Bloom Category id beginning with 'bl_cat'. */
  CategoryId: { input: string; output: string };
  /** A Bloom Collab id beginning with 'bl_coll'. */
  CollabId: { input: string; output: string };
  /** A Bloom CollabImage id beginning with 'bl_collimg'. */
  CollabImageId: { input: string; output: string };
  /** A Bloom CollabUpdate id beginning with 'bl_collup'. */
  CollabUpdateId: { input: string; output: string };
  /** A Bloom CollabUpdateImage id beginning with 'bl_collupimg'. */
  CollabUpdateImageId: { input: string; output: string };
  /** A Bloom CollabUpdateImage or UploadImage id. */
  CollabUpdateOrUploadImageId: { input: string; output: string };
  /** A Bloom Comment id beginning with 'bl_com'. */
  CommentId: { input: string; output: string };
  /** A Bloom Contest id beginning with 'bl_contest'. */
  ContestId: { input: string; output: string };
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: string; output: string };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string };
  /** An arbitrary-precision Decimal type */
  Decimal: { input: number; output: number };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string };
  /** A Bloom FeatureAnnouncement id beginning with 'bl_featan'. */
  FeatureAnnouncementId: { input: string; output: string };
  /** A Bloom Collab, HeadToHead or Promo id. */
  FeedEntityId: { input: string; output: string };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown> };
  /** A Bloom MilestoneObjective id beginning with 'bl_milo'. */
  MilestoneObjectiveId: { input: string; output: string };
  /** A Bloom Collab, Contest or User id. */
  NotifiableEntityId: { input: string; output: string };
  /** A string id representing a notification activity */
  NotificationActivityId: { input: string; output: string };
  /** A string id representing a notification */
  NotificationId: { input: string; output: string };
  /** A Bloom NullableUploadVideo id beginning with 'bl_uplvid'. */
  NullableUploadVideoId: { input: string; output: string };
  /** A Bloom Order id beginning with 'bl_or'. */
  OrderId: { input: string; output: string };
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: string; output: string };
  /** A Bloom ProfileVideo id beginning with 'bl_pfvid'. */
  ProfileVideoId: { input: string; output: string };
  /** A Bloom Promo id beginning with 'bl_promo'. */
  PromoId: { input: string; output: string };
  /** A Bloom Collab, User or Comment id. */
  ReportableEntityId: { input: string; output: string };
  /** A Bloom Collab, Contest, User or CollabUpdate id. */
  ShareableEntityId: { input: string; output: string };
  /** A Bloom StudioAnimation id beginning with 'bl_stanim'. */
  StudioAnimationId: { input: string; output: string };
  /** A Bloom StudioImage id beginning with 'bl_stimg'. */
  StudioImageId: { input: string; output: string };
  /** A Bloom StudioImage or StudioAnimation id. */
  StudioMediaScalarId: { input: string; output: string };
  /** A Bloom UploadImage or StudioImage id. */
  StudioOrUploadImageId: { input: string; output: string };
  /** A Bloom StudioTask id beginning with 'bl_stask'. */
  StudioTaskId: { input: string; output: string };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: string; output: string };
  /** A Bloom UploadImage id beginning with 'bl_uplimg'. */
  UploadImageId: { input: string; output: string };
  /** A Bloom UploadVideo id beginning with 'bl_uplvid'. */
  UploadVideoId: { input: string; output: string };
  /** A Bloom User id beginning with 'bl_us'. */
  UserId: { input: string; output: string };
};

export enum AccessRole {
  admin = "admin",
  staff = "staff",
  supercreator = "supercreator",
  superseller = "superseller",
}

export type AchievedMilestone = {
  __typename: "AchievedMilestone";
  achievedMilestoneId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  milestoneObjectiveId: Scalars["String"]["output"];
  requiredCount?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export type AchievedMilestoneFilteringArgs = {
  milestoneObjectiveId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export enum ActivityVerb {
  becomeCreator = "becomeCreator",
  becomeSellable = "becomeSellable",
  collabComment = "collabComment",
  collabUpdate = "collabUpdate",
  collabUpdateComment = "collabUpdateComment",
  contestEndingSoon = "contestEndingSoon",
  contestGoLive = "contestGoLive",
  contestTopUser = "contestTopUser",
  deniedCollabToDevelopment = "deniedCollabToDevelopment",
  deniedCollabToUnderReview = "deniedCollabToUnderReview",
  fullLikesMilestone = "fullLikesMilestone",
  halfLikesMilestone = "halfLikesMilestone",
  like = "like",
  paymentFailed = "paymentFailed",
  paymentIncoming = "paymentIncoming",
  paymentSucceeded = "paymentSucceeded",
  preorderPlaced = "preorderPlaced",
  promotedCollabToDevelopment = "promotedCollabToDevelopment",
  promotedCollabToUnderReview = "promotedCollabToUnderReview",
  promotedCollabToUnderReviewReady = "promotedCollabToUnderReviewReady",
  underReviewEndingInTenDays = "underReviewEndingInTenDays",
  underReviewEndingInThreeDays = "underReviewEndingInThreeDays",
}

export type Ad = {
  __typename: "Ad";
  adId: Scalars["AdId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  icon: Scalars["String"]["output"];
  link: AppLink;
  linkText: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type AddPaymentMethodData = {
  __typename: "AddPaymentMethodData";
  clientSecret: Scalars["String"]["output"];
};

export type AddPaymentMethodDataForAnonymous = {
  __typename: "AddPaymentMethodDataForAnonymous";
  anonymousCustomerId: Scalars["AnonymousCustomerId"]["output"];
  clientSecret: Scalars["String"]["output"];
};

export type AddToCartFromFeedInput = {
  collabId: Scalars["CollabId"]["input"];
  quantity: Scalars["Int"]["input"];
  variantId?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddToCartInput = {
  collabId: Scalars["CollabId"]["input"];
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["String"]["input"];
};

export type AdditionalCharges = {
  __typename: "AdditionalCharges";
  salesTax: SalesTax;
  shippingRate: ShippingRate;
};

export type AnyPromo = GenericPromo | StandardPromo;

export type AppLink = ExternalLink | InternalLink;

export enum AvailablePurchaseMethod {
  checkout = "checkout",
  preorder = "preorder",
  unavailable = "unavailable",
}

export enum Badge {
  none = "none",
  staff = "staff",
  supercreator = "supercreator",
  supercreatorAndSuperseller = "supercreatorAndSuperseller",
  superseller = "superseller",
  vettedCreator = "vettedCreator",
}

export type BehindTheScenesVideo = {
  __typename: "BehindTheScenesVideo";
  behindTheScenesVideoId: Scalars["BehindTheScenesVideoId"]["output"];
  cloudflareId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  status: VideoStatus;
  thumbnailUrl?: Maybe<Scalars["URL"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  videoManifestUrl?: Maybe<Scalars["URL"]["output"]>;
  videoUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type BloomSubscription = {
  __typename: "BloomSubscription";
  cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  purchasedAt: Scalars["DateTime"]["output"];
  type: SubscriptionType;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type CampaignVideo = {
  __typename: "CampaignVideo";
  campaignVideoId: Scalars["CampaignVideoId"]["output"];
  cloudflareId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  status: VideoStatus;
  thumbnailUrl?: Maybe<Scalars["URL"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  videoManifestUrl?: Maybe<Scalars["URL"]["output"]>;
  videoUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type CancelPreorderInput = {
  reason: Scalars["String"]["input"];
};

export type CardPaymentMethod = {
  __typename: "CardPaymentMethod";
  brand: Scalars["String"]["output"];
  expirationMonth: Scalars["Int"]["output"];
  expirationYear: Scalars["Int"]["output"];
  expiredOrExpiringSoon: Scalars["Boolean"]["output"];
  last4Digits: Scalars["String"]["output"];
  paymentMethodId: Scalars["String"]["output"];
};

export type Cart = {
  __typename: "Cart";
  anonymousCartId?: Maybe<Scalars["AnonymousCartId"]["output"]>;
  items: Array<CartItem>;
  userId?: Maybe<Scalars["UserId"]["output"]>;
};

export type CartIdentity = {
  __typename: "CartIdentity";
  anonymousCartId?: Maybe<Scalars["AnonymousCartId"]["output"]>;
  userId?: Maybe<Scalars["UserId"]["output"]>;
};

export type CartIdentityForUser = {
  __typename: "CartIdentityForUser";
  userId: Scalars["UserId"]["output"];
};

export type CartItem = {
  __typename: "CartItem";
  cartItemId: Scalars["CartItemId"]["output"];
  collab: Collab;
  quantity: Scalars["Int"]["output"];
  variant?: Maybe<ProductVariant>;
};

export type CartItemWithIdentity = {
  __typename: "CartItemWithIdentity";
  cartItem: CartItem;
  identity: CartIdentity;
};

export type CartItemWithUser = {
  __typename: "CartItemWithUser";
  cartItem: CartItem;
  identity: CartIdentityForUser;
};

export type CatalogProduct = {
  __typename: "CatalogProduct";
  catalogProductId: Scalars["CatalogProductId"]["output"];
  categoryId: Scalars["CategoryId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CatalogProductsFilteringArgs = {
  categoryId: Scalars["CategoryId"]["input"];
};

export type Category = {
  __typename: "Category";
  categoryId: Scalars["CategoryId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  imageUrl: Scalars["URL"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CategoryFacetValue = {
  __typename: "CategoryFacetValue";
  category: Category;
  resultCount: Scalars["Int"]["output"];
};

export type CategoryWithCount = {
  __typename: "CategoryWithCount";
  catalogProductsCount: Scalars["Int"]["output"];
  categoryId: Scalars["CategoryId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  imageUrl: Scalars["URL"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Checkout = {
  __typename: "Checkout";
  checkoutUrl: Scalars["URL"]["output"];
};

export type CheckoutInput = {
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["String"]["input"];
};

export type Collab = {
  __typename: "Collab";
  availablePurchaseMethod: AvailablePurchaseMethod;
  campaign?: Maybe<CollabCampaign>;
  catalogProductId?: Maybe<Scalars["CatalogProductId"]["output"]>;
  category: Category;
  collabId: Scalars["CollabId"]["output"];
  compareAtPrice?: Maybe<Scalars["Decimal"]["output"]>;
  contest?: Maybe<Contest>;
  /** @deprecated removed in favor of the full contest object */
  contestId?: Maybe<Scalars["ContestId"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creator: Profile;
  description: Scalars["String"]["output"];
  formattedCompareAtPrice?: Maybe<Scalars["String"]["output"]>;
  formattedNumberOfComments: Scalars["String"]["output"];
  formattedNumberOfLikes: Scalars["String"]["output"];
  formattedNumberOfShares: Scalars["String"]["output"];
  formattedPrice?: Maybe<Scalars["String"]["output"]>;
  handle: Scalars["String"]["output"];
  images: Array<CollabImage>;
  inventoryQuantity?: Maybe<Scalars["Int"]["output"]>;
  liked?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  numberOfComments: Scalars["Int"]["output"];
  numberOfLikes: Scalars["Int"]["output"];
  numberOfShares: Scalars["Int"]["output"];
  price?: Maybe<Scalars["Decimal"]["output"]>;
  progress: Progress;
  sizeGuideUrl?: Maybe<Scalars["URL"]["output"]>;
  thumbnailImage: CollabImage;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CollabCampaign = {
  __typename: "CollabCampaign";
  createdAt: Scalars["DateTime"]["output"];
  orderCount: Scalars["Int"]["output"];
  orderCountNeeded: Scalars["Int"]["output"];
  percentage: Scalars["Int"]["output"];
  status: CollabCampaignStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum CollabCampaignStatus {
  cancelled = "cancelled",
  inProgress = "inProgress",
  successful = "successful",
}

export type CollabContestsFilteringArgs = {
  contestId: Scalars["ContestId"]["input"];
};

export type CollabFacets = {
  __typename: "CollabFacets";
  categories: Array<CategoryFacetValue>;
  creators: Array<CreatorFacetValue>;
  stages: Array<StageFacetValue>;
};

export enum CollabForUserCampaignStatus {
  inProgress = "inProgress",
  notStarted = "notStarted",
  quoteRequested = "quoteRequested",
}

export type CollabImage = {
  __typename: "CollabImage";
  collabImageId: Scalars["CollabImageId"]["output"];
  imageUrl: Scalars["URL"]["output"];
  /** @deprecated will be removed */
  index: Scalars["Int"]["output"];
  source: ImageSource;
};

export type CollabMediaContent = CampaignVideo | CollabImage | StudioAnimation;

export enum CollabSortingMethod {
  createdAtAsc = "createdAtAsc",
  createdAtDesc = "createdAtDesc",
}

export type CollabUpdate = {
  __typename: "CollabUpdate";
  collabId: Scalars["CollabId"]["output"];
  collabUpdateId: Scalars["CollabUpdateId"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  formattedLikeCount: Scalars["String"]["output"];
  formattedNumberOfComments?: Maybe<Scalars["String"]["output"]>;
  formattedShareCount: Scalars["String"]["output"];
  images: Array<CollabUpdateImage>;
  likeCount: Scalars["Int"]["output"];
  liked?: Maybe<Scalars["Boolean"]["output"]>;
  numberOfComments: Scalars["Int"]["output"];
  shareCount: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: Profile;
};

export type CollabUpdateImage = {
  __typename: "CollabUpdateImage";
  collabUpdateImageId: Scalars["CollabUpdateImageId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  imageUrl: Scalars["URL"]["output"];
  index: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CollabUpdateLike = {
  __typename: "CollabUpdateLike";
  collabUpdateId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export type CollabVariants = {
  __typename: "CollabVariants";
  collabId: Scalars["CollabId"]["output"];
  options: Array<ProductOption>;
  variants: Array<ProductVariant>;
};

export type CollabWithMedia = {
  __typename: "CollabWithMedia";
  availablePurchaseMethod: AvailablePurchaseMethod;
  campaign?: Maybe<CollabCampaign>;
  catalogProductId?: Maybe<Scalars["CatalogProductId"]["output"]>;
  category: Category;
  collabId: Scalars["CollabId"]["output"];
  compareAtPrice?: Maybe<Scalars["Decimal"]["output"]>;
  contest?: Maybe<Contest>;
  createdAt: Scalars["DateTime"]["output"];
  creator: Profile;
  description: Scalars["String"]["output"];
  formattedCompareAtPrice?: Maybe<Scalars["String"]["output"]>;
  formattedNumberOfComments: Scalars["String"]["output"];
  formattedNumberOfLikes: Scalars["String"]["output"];
  formattedNumberOfShares: Scalars["String"]["output"];
  formattedPrice?: Maybe<Scalars["String"]["output"]>;
  handle: Scalars["String"]["output"];
  inventoryQuantity?: Maybe<Scalars["Int"]["output"]>;
  liked?: Maybe<Scalars["Boolean"]["output"]>;
  media: Array<CollabMediaContent>;
  name: Scalars["String"]["output"];
  numberOfComments: Scalars["Int"]["output"];
  numberOfLikes: Scalars["Int"]["output"];
  numberOfShares: Scalars["Int"]["output"];
  price?: Maybe<Scalars["Decimal"]["output"]>;
  progress: Progress;
  sizeGuideUrl?: Maybe<Scalars["URL"]["output"]>;
  thumbnailImage: CollabImage;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CollabsForUserFilteringArgs = {
  campaignStatus?: InputMaybe<CollabForUserCampaignStatus>;
  creatorId: Scalars["UserId"]["input"];
};

export type Comment = {
  __typename: "Comment";
  /** @deprecated Stop using it! */
  collabId?: Maybe<Scalars["CollabId"]["output"]>;
  commentId: Scalars["CommentId"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  formattedLikeCount: Scalars["String"]["output"];
  likeCount: Scalars["Int"]["output"];
  liked?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: Profile;
};

export type CommentLike = {
  __typename: "CommentLike";
  commentId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export type ComputeAdditionalChargesAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["CountryCode"]["input"];
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComputeAdditionalChargesInput = {
  address: ComputeAdditionalChargesAddressInput;
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["String"]["input"];
};

export type ComputeShippingRatesAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["CountryCode"]["input"];
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComputeShippingRatesInput = {
  address: ComputeShippingRatesAddressInput;
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["String"]["input"];
};

export type ConceptsFilteringArgs = {
  categoryIds?: InputMaybe<Array<Scalars["CategoryId"]["input"]>>;
  notAlreadySeen?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum ConceptsSortingMethod {
  mostPopular = "mostPopular",
  newest = "newest",
  trending = "trending",
}

export type Contest = {
  __typename: "Contest";
  category?: Maybe<Category>;
  contestId: Scalars["ContestId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  endOfVoteDate: Scalars["DateTime"]["output"];
  goLiveDate: Scalars["DateTime"]["output"];
  handle: Scalars["String"]["output"];
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  isHidden: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  numberOfSubmissions: Scalars["Int"]["output"];
  passwordRequired: Scalars["Boolean"]["output"];
  rewardType: ContestRewardType;
  rewardValue?: Maybe<Scalars["String"]["output"]>;
  status: ContestStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContestFilter = {
  includeHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ContestStatus>;
};

export type ContestPasswordValidation = {
  __typename: "ContestPasswordValidation";
  valid: Scalars["Boolean"]["output"];
};

export type ContestResult = {
  __typename: "ContestResult";
  collab: Collab;
  contest: Contest;
  voteCount: VoteCount;
  winner: Scalars["Boolean"]["output"];
};

export enum ContestRewardType {
  becomeCreator = "becomeCreator",
  custom = "custom",
  money = "money",
  supercoin = "supercoin",
  token = "token",
}

export enum ContestSortingMethod {
  createdAtAsc = "createdAtAsc",
  createdAtDesc = "createdAtDesc",
}

export enum ContestStatus {
  completed = "completed",
  inProgress = "inProgress",
  notStarted = "notStarted",
}

export type ContestSubmissionCount = {
  __typename: "ContestSubmissionCount";
  contestId: Scalars["ContestId"]["output"];
  count: Scalars["Int"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type CreateAdInput = {
  externalLink?: InputMaybe<Scalars["URL"]["input"]>;
  icon: Scalars["String"]["input"];
  internalLinkPath?: InputMaybe<Scalars["String"]["input"]>;
  linkText: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateBehindTheScenesVideoInput = {
  uploadVideoId: Scalars["UploadVideoId"]["input"];
};

export type CreateCatalogProductInput = {
  categoryId: Scalars["CategoryId"]["input"];
  externalModelId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateCategoryInput = {
  imageUrl: Scalars["URL"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateCollabUpdateInput = {
  content: Scalars["String"]["input"];
  imageIds?: InputMaybe<Array<Scalars["UploadImageId"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type CreateCommentInput = {
  content: Scalars["String"]["input"];
};

export type CreateContestCollabInput = {
  categoryId: Scalars["CategoryId"]["input"];
  contestId: Scalars["ContestId"]["input"];
  creatorId: Scalars["UserId"]["input"];
  description: Scalars["String"]["input"];
  mediaIds?: InputMaybe<Array<Scalars["AnyMediaScalarId"]["input"]>>;
  name: Scalars["String"]["input"];
  studioImageIds?: InputMaybe<Array<Scalars["StudioOrUploadImageId"]["input"]>>;
};

export type CreateContestInput = {
  categoryId?: InputMaybe<Scalars["CategoryId"]["input"]>;
  description: Scalars["String"]["input"];
  endOfVoteDate: Scalars["DateTime"]["input"];
  goLiveDate: Scalars["DateTime"]["input"];
  isHidden: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  numberOfWinners?: Scalars["Int"]["input"];
  password?: InputMaybe<Scalars["String"]["input"]>;
  rewardType: ContestRewardType;
  rewardValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFeatureAnnouncementInput = {
  externalLink?: InputMaybe<Scalars["URL"]["input"]>;
  icon: Scalars["String"]["input"];
  internalLinkPath?: InputMaybe<Scalars["String"]["input"]>;
  linkText: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateMilestoneObjectiveInput = {
  isRecurring: Scalars["Boolean"]["input"];
  milestoneType: MilestoneType;
  requiredCount: Scalars["Int"]["input"];
  reward: Scalars["Int"]["input"];
};

export type CreatePreorderForAnonymousInput = {
  email: Scalars["EmailAddress"]["input"];
  paymentMethodId: Scalars["String"]["input"];
  quantity: Scalars["Int"]["input"];
  shippingAddress: SetShippingAddressInput;
  supercoinAmount?: Scalars["BigInt"]["input"];
  variantId: Scalars["String"]["input"];
};

export type CreatePreorderInput = {
  paymentMethodId: Scalars["String"]["input"];
  quantity: Scalars["Int"]["input"];
  supercoinAmount?: Scalars["BigInt"]["input"];
  variantId: Scalars["String"]["input"];
};

export type CreatePromoInput = {
  ctaLabel: Scalars["String"]["input"];
  ctaUrl: Scalars["URL"]["input"];
  endAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  imageUrl?: InputMaybe<Scalars["URL"]["input"]>;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateStudioCollabInput = {
  categoryId: Scalars["CategoryId"]["input"];
  creatorId: Scalars["UserId"]["input"];
  description: Scalars["String"]["input"];
  mediaIds?: InputMaybe<Array<Scalars["AnyMediaScalarId"]["input"]>>;
  name: Scalars["String"]["input"];
  studioImageIds?: InputMaybe<Array<Scalars["StudioOrUploadImageId"]["input"]>>;
};

export type CreatorFacetValue = {
  __typename: "CreatorFacetValue";
  creator: Profile;
  resultCount: Scalars["Int"]["output"];
};

export enum CreatorStatus {
  creator = "creator",
  pending = "pending",
  user = "user",
}

export type CreditSupercoinsInput = {
  amount: Scalars["BigInt"]["input"];
  comment: Scalars["String"]["input"];
};

export type DeleteCartItemInput = {
  collabId?: InputMaybe<Scalars["CollabId"]["input"]>;
  variantId?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteStudioMediaInput = {
  mediaIds: Array<Scalars["StudioMediaScalarId"]["input"]>;
};

export type DeletedCollabUpdateLikes = {
  __typename: "DeletedCollabUpdateLikes";
  deletedCollabUpdateLikes: Scalars["Int"]["output"];
};

export type DeletedCollabs = {
  __typename: "DeletedCollabs";
  deletedCollabs: Scalars["Int"]["output"];
};

export type DeletedCommentLikes = {
  __typename: "DeletedCommentLikes";
  deletedCommentLikes: Scalars["Int"]["output"];
};

export type DeletedLikes = {
  __typename: "DeletedLikes";
  deletedLikes: Scalars["Int"]["output"];
};

export type DeletedObject = {
  __typename: "DeletedObject";
  objectId: Scalars["NotifiableEntityId"]["output"];
};

export type DeletedPaymentMethod = {
  __typename: "DeletedPaymentMethod";
  paymentMethodId: Scalars["String"]["output"];
};

export type DetailedProgress = {
  __typename: "DetailedProgress";
  /** @deprecated moving under detailedAllowedTransitions */
  allowedTransitions: Array<DetailedStage>;
  /** @deprecated moving under detailedCurrentStage */
  currentDetailedStage: DetailedStage;
  /** @deprecated moving under detailedCurrentStage */
  currentStage: Stage;
  detailedAllowedTransitions: Array<StageDetails>;
  detailedCurrentStage: StageDetails;
};

export enum DetailedStage {
  archived = "archived",
  concept = "concept",
  contest = "contest",
  denied = "denied",
  development = "development",
  developmentDecision = "developmentDecision",
  developmentDenied = "developmentDenied",
  developmentFailed = "developmentFailed",
  forSale = "forSale",
  softDeleted = "softDeleted",
  underReview = "underReview",
  underReviewDecision = "underReviewDecision",
  underReviewDecisionQuoteRequested = "underReviewDecisionQuoteRequested",
  underReviewDenied = "underReviewDenied",
  underReviewReady = "underReviewReady",
}

export type DislikeCollabInFeedInput = {
  collabId: Scalars["CollabId"]["input"];
};

export type DislikeCollabInput = {
  collabId: Scalars["CollabId"]["input"];
};

export type DownloadImageLink = {
  __typename: "DownloadImageLink";
  imageUrl: Scalars["URL"]["output"];
};

export type ExploreStudioImage = {
  __typename: "ExploreStudioImage";
  catalogProduct?: Maybe<CatalogProduct>;
  createdAt: Scalars["DateTime"]["output"];
  imageId: Scalars["StudioImageId"]["output"];
  imageUrl: Scalars["String"]["output"];
  isExploreImage: Scalars["Boolean"]["output"];
  isNsfw: Scalars["Boolean"]["output"];
  prompt?: Maybe<Scalars["String"]["output"]>;
  source: ImageSource;
  status: StudioImageStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExternalLink = {
  __typename: "ExternalLink";
  externalLink: Scalars["URL"]["output"];
};

export type FeatureAccess = {
  __typename: "FeatureAccess";
  allowed: Scalars["Boolean"]["output"];
  requiredRole?: Maybe<AccessRole>;
};

export type FeatureAccesses = {
  __typename: "FeatureAccesses";
  infiniteTokens: FeatureAccess;
  moreQuoteRequests: FeatureAccess;
  priorityInGenerationQueue: FeatureAccess;
};

export type FeatureAnnouncement = {
  __typename: "FeatureAnnouncement";
  createdAt: Scalars["DateTime"]["output"];
  featureAnnouncementId: Scalars["FeatureAnnouncementId"]["output"];
  icon: Scalars["String"]["output"];
  link: AppLink;
  linkText: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type FeaturedCollabInput = {
  sortingMethod: FeaturedCollabSortingMethod;
  stage: FeaturedCollabStage;
};

export enum FeaturedCollabSortingMethod {
  bestselling = "bestselling",
  closestToCampaignEnd = "closestToCampaignEnd",
  closestToGoal = "closestToGoal",
  fewestLeft = "fewestLeft",
  mostPopular = "mostPopular",
  newest = "newest",
  random = "random",
  trending = "trending",
}

export enum FeaturedCollabStage {
  concept = "concept",
  contest = "contest",
  development = "development",
  forSale = "forSale",
  underReview = "underReview",
}

export type FeaturedCreatorInput = {
  sortingMethod: FeaturedCreatorSortingMethod;
};

export enum FeaturedCreatorSortingMethod {
  mostPopular = "mostPopular",
}

export type FeedCollabOrPromo = Collab | GenericPromo | StandardPromo;

export type FeedEntity = CollabWithMedia | GenericPromo | StandardPromo;

export type Feedback = {
  __typename: "Feedback";
  message: Scalars["String"]["output"];
};

export type ForSaleCollabsFilteringArgs = {
  categoryIds?: InputMaybe<Array<Scalars["CategoryId"]["input"]>>;
};

export enum ForSaleCollabsSortingMethod {
  bestselling = "bestselling",
  fewestLeft = "fewestLeft",
  newest = "newest",
  random = "random",
}

export type FrontendConfig = {
  __typename: "FrontendConfig";
  allowedShippingCountries: Array<Scalars["CountryCode"]["output"]>;
  imageResizeProxy: Scalars["URL"]["output"];
  likeCountObjective: Scalars["Int"]["output"];
  maximumNumberOfImagesPerCollab: Scalars["Int"]["output"];
  maximumNumberOfImagesPerCollabForAdmins: Scalars["Int"]["output"];
  maximumVideoDurationForCampaignInSeconds: Scalars["Int"]["output"];
  maximumVideoDurationForProfileInSeconds: Scalars["Int"]["output"];
  maximumVideoDurationInSeconds: Scalars["Int"]["output"];
  offScriptPercentageOfRevenue: Scalars["Int"]["output"];
  tokensNeededForDescriptionGeneration: Scalars["Int"]["output"];
  tokensNeededForImageGeneration: Scalars["Int"]["output"];
  tokensNeededForVideoGeneration: Scalars["Int"]["output"];
};

export type GenerateAnimationFromImageInput = {
  studioImageId?: InputMaybe<Scalars["StudioImageId"]["input"]>;
  uploadImageId?: InputMaybe<Scalars["UploadImageId"]["input"]>;
};

export type GenerateImagesFromImageInput = {
  prompt?: InputMaybe<Scalars["String"]["input"]>;
  studioImageId?: InputMaybe<Scalars["StudioOrUploadImageId"]["input"]>;
  uploadImageId?: InputMaybe<Scalars["UploadImageId"]["input"]>;
};

export type GenerateImagesInput = {
  catalogProductId?: InputMaybe<Scalars["CatalogProductId"]["input"]>;
  contestId?: InputMaybe<Scalars["ContestId"]["input"]>;
  prompt: Scalars["String"]["input"];
};

export type GeneratePosesInput = {
  contestId?: InputMaybe<Scalars["ContestId"]["input"]>;
  studioImageId: Scalars["StudioImageId"]["input"];
};

export type GenerateVariationInput = {
  contestId?: InputMaybe<Scalars["ContestId"]["input"]>;
  studioImageId: Scalars["StudioImageId"]["input"];
};

export type GeneratedDescription = {
  __typename: "GeneratedDescription";
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  studioTaskId: Scalars["StudioTaskId"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type GeneratedDescriptionFilteringArgs = {
  studioTaskId: Scalars["StudioTaskId"]["input"];
};

export type GenericPromo = {
  __typename: "GenericPromo";
  createdAt: Scalars["DateTime"]["output"];
  ctaLabel: Scalars["String"]["output"];
  ctaUrl: Scalars["URL"]["output"];
  endAt?: Maybe<Scalars["DateTime"]["output"]>;
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  promoId: Scalars["PromoId"]["output"];
  promoType: PromoType;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum GrantedRole {
  admin = "admin",
}

export type Health = {
  __typename: "Health";
  status: Scalars["String"]["output"];
};

export type ImageGenerationStatus = {
  __typename: "ImageGenerationStatus";
  tokensLeft: Scalars["Int"]["output"];
  unlimited: Scalars["Boolean"]["output"];
};

export enum ImageSource {
  studioImageFromImage = "studioImageFromImage",
  studioPose = "studioPose",
  studioPrompt = "studioPrompt",
  studioVariation = "studioVariation",
  upload = "upload",
}

export type InDevelopmentCollabsFilteringArgs = {
  categoryIds?: InputMaybe<Array<Scalars["CategoryId"]["input"]>>;
};

export enum InDevelopmentCollabsSortingMethod {
  newest = "newest",
  trending = "trending",
}

export type IncreaseTokenBankInput = {
  quantity: Scalars["Int"]["input"];
};

export type InstagramLink = {
  __typename: "InstagramLink";
  instagramLink: Scalars["URL"]["output"];
};

export type InstagramLoginUrl = {
  __typename: "InstagramLoginUrl";
  url: Scalars["URL"]["output"];
};

export type InstagramPicture = {
  __typename: "InstagramPicture";
  imageUrl: Scalars["URL"]["output"];
};

export type InstagramPictureFilteringArgs = {
  userId: Scalars["UserId"]["input"];
};

export type InternalLink = {
  __typename: "InternalLink";
  internalLinkPath: Scalars["String"]["output"];
};

export type IsCreatorFacetValue = {
  __typename: "IsCreatorFacetValue";
  isCreator: Scalars["Boolean"]["output"];
  resultCount: Scalars["Int"]["output"];
};

export type Like = {
  __typename: "Like";
  collabId: Scalars["CollabId"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  numberOfLikes: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type LikeCollabInFeedInput = {
  collabId: Scalars["CollabId"]["input"];
};

export type LikeCollabInput = {
  collabId: Scalars["CollabId"]["input"];
};

export type LikeCollabUpdateInput = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
};

export type LikeCommentInput = {
  commentId: Scalars["CommentId"]["input"];
};

export type LikeFilteringArgs = {
  stages?: InputMaybe<Array<Stage>>;
};

export enum LikeSortingMethod {
  createdAtAsc = "createdAtAsc",
  createdAtDesc = "createdAtDesc",
  likeCountDesc = "likeCountDesc",
  priceAsc = "priceAsc",
  priceDesc = "priceDesc",
}

export type MarkAdAsSeenInput = {
  adId: Scalars["AdId"]["input"];
};

export type MarkFeatureAnnouncementAsSeenInput = {
  featureAnnouncementId: Scalars["FeatureAnnouncementId"]["input"];
};

export type MilestoneObjective = {
  __typename: "MilestoneObjective";
  createdAt: Scalars["DateTime"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  isRecurring: Scalars["Boolean"]["output"];
  milestoneObjectiveId: Scalars["String"]["output"];
  milestoneType: MilestoneType;
  requiredCount: Scalars["Int"]["output"];
  reward: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type MilestoneProgress = {
  __typename: "MilestoneProgress";
  achievementCount?: Maybe<Scalars["Int"]["output"]>;
  currentCount: Scalars["Int"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  isRecurring: Scalars["Boolean"]["output"];
  milestoneObjectiveId: Scalars["MilestoneObjectiveId"]["output"];
  milestoneType: MilestoneType;
  progression: Scalars["Float"]["output"];
  requiredCount: Scalars["Int"]["output"];
  reward: Scalars["Int"]["output"];
};

export type MilestoneProgressFilteringArgs = {
  includeRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  milestoneTypes?: InputMaybe<Array<MilestoneType>>;
};

export enum MilestoneType {
  collabLikeMilestone = "collabLikeMilestone",
  collabMilestone = "collabMilestone",
  commentMilestone = "commentMilestone",
  launchCampaignMilestone = "launchCampaignMilestone",
  preorderPuchaseMilestone = "preorderPuchaseMilestone",
  referralMilestone = "referralMilestone",
  shareMilestone = "shareMilestone",
  swipeMilestone = "swipeMilestone",
}

export type Mutation = {
  __typename: "Mutation";
  addToCart: CartItemWithIdentity;
  addToCartFromFeed: CartItemWithUser;
  archiveCollab: Collab;
  banUser: Profile;
  buyNowCheckout: Checkout;
  /** @deprecated use moveCollabToDevelopmentDenied or moveCollabToDevelopmentFailed */
  cancelCollabCampaign: CollabCampaign;
  cancelPreorder: Order;
  cartCheckout: Checkout;
  chargeCustomerForPreorder: Order;
  /** @deprecated replaced by computeAdditionalCharges */
  computeShippingRate: ShippingRate;
  createAd: Ad;
  createBehindTheScenesVideo: BehindTheScenesVideo;
  createCatalogProduct: CatalogProduct;
  createCategory: Category;
  createCollabUpdate: CollabUpdate;
  createCollabUpdateComment: Comment;
  createComment: Comment;
  createContest: Contest;
  createContestCollab: Collab;
  createFeatureAnnouncement: FeatureAnnouncement;
  createGenericPromo: GenericPromo;
  createMilestoneObjective: MilestoneObjective;
  createPreorder: Preorder;
  createPreorderForAnonymous: Preorder;
  createPreorderFromExistingPreorder: Preorder;
  createStudioCollab: Collab;
  creditSupercoins: SupercoinBalance;
  deleteAd: Ad;
  deleteAllCollabUpdateLikes: DeletedCollabUpdateLikes;
  deleteAllCommentLikes: DeletedCommentLikes;
  deleteAllLikes: DeletedLikes;
  deleteBehindTheScenesVideo: BehindTheScenesVideo;
  deleteCampaignVideo: CampaignVideo;
  deleteCartItem: CartItemWithIdentity;
  deleteCatalogProduct: CatalogProduct;
  deleteCategory: Category;
  deleteCollab: Collab;
  deleteCollabUpdate: CollabUpdate;
  deleteCollabsForUser: DeletedCollabs;
  deleteComment: Comment;
  deleteFeatureAnnouncement: FeatureAnnouncement;
  deleteGenericPromo: GenericPromo;
  deleteInstagramAccountData: Profile;
  deletePaymentMethod: DeletedPaymentMethod;
  deleteProfile: Profile;
  deleteProfileImage: Profile;
  /** @deprecated moved to deleteStudioMedia */
  deleteStudioImages: PaginatedStudioImages;
  deleteStudioMedia: PaginatedStudioMedia;
  denyCollab: Collab;
  deprioritizeUserForGenerations: StudioUser;
  dislikeCollab: Like;
  dislikeCollabInFeed: Like;
  forceMarkPreorderAsPaid: Order;
  /** @deprecated use moveCollabToDevelopmentDenied or promoteToInDevelopment */
  fulfillCollabCampaign: CollabCampaign;
  generateAddPaymentMethodData: AddPaymentMethodData;
  generateAddPaymentMethodDataForAnonymous: AddPaymentMethodDataForAnonymous;
  generateCollabImageDownloadLink: DownloadImageLink;
  generateInstagramLoginUrl: InstagramLoginUrl;
  generateStudioImageDownloadLink: DownloadImageLink;
  grantRoles: Roles;
  increaseTokenBank: StudioUser;
  likeCollab: Like;
  likeCollabInFeed: Like;
  likeCollabUpdate: CollabUpdateLike;
  likeComment: CommentLike;
  markAdAsSeen: Ad;
  markFeatureAnnouncementAsSeen: FeatureAnnouncement;
  markPromoAsSeen: AnyPromo;
  moveCollabToContest: Collab;
  moveCollabToDevelopmentDecision: Collab;
  moveCollabToDevelopmentDenied: Collab;
  moveCollabToDevelopmentFailed: Collab;
  moveCollabToUnderReviewDenied: Collab;
  /** @deprecated renamed to createGenericPromo */
  newCreateGenericPromo: GenericPromo;
  /** @deprecated renamed to deleteGenericPromo */
  newDeleteGenericPromo: GenericPromo;
  /** @deprecated renamed to markPromoAsSeen */
  newMarkPromoAsSeen: AnyPromo;
  /** @deprecated renamed to updateGenericPromo */
  newUpdateGenericPromo: GenericPromo;
  promoteCollabToConcept: Collab;
  promoteCollabToForSale: Collab;
  promoteCollabToInDevelopment: Collab;
  /** @deprecated Replaced by combination of promoteCollabToUnderReviewReady and startCampaign */
  promoteCollabToUnderReview: Collab;
  promoteCollabToUnderReviewReady: Collab;
  promoteCollabsToConceptOnContestEnd: PromotedCollabs;
  reorderAds: PaginatedAds;
  report: ReportableEntity;
  requestDeleteProfile: Profile;
  requestQuote: QuoteRequest;
  /** @deprecated Replaced by requestQuote */
  requestQuoteForCollab: Collab;
  requestToBecomeCreator: Profile;
  resetFeed: PaginatedFeedCollabOrPromo;
  resetGenerationPriorityForUser: StudioUser;
  resetUser: Profile;
  revokePushNotificationsToken: PushNotificationsToken;
  revokeRoles: Roles;
  saveTemporaryAnimation: StudioAnimation;
  saveTemporaryImages: PaginatedStudioImages;
  sendFeedback: Feedback;
  sendPushNotification: SentCustomPushNotification;
  sendPushNotificationToAllUsers: SentCustomPushNotification;
  setCreatorStatus: Profile;
  setFeaturedCollab: Collab;
  setFeaturedCreator: Profile;
  /** @deprecated Replaced by setStudioImageShownInExplore */
  setImageShownInExplore: StudioImage;
  setProfileStaffStatus: Profile;
  setPushNotificationSettings: PushNotificationsUserSettings;
  setPushNotificationsToken: PushNotificationsToken;
  setReferrerUser: SuccessfulReferral;
  setShippingAddress: ShippingAddress;
  setStudioImageShownInExplore: ExploreStudioImage;
  softDeleteCollab: Collab;
  startCampaign: Collab;
  startGeneratingAnimationFromImage: StudioTask;
  startGeneratingDescription: StudioTask;
  startGeneratingImages: StudioTask;
  startGeneratingImagesFromImage: StudioTask;
  startGeneratingPoses: StudioTask;
  startGeneratingVariations: StudioTask;
  subscribeToNewsletter: NewsletterSubscription;
  supersellerCheckout: Checkout;
  takeOwnershipOfCollabsFromUser: DeletedCollabs;
  trackShareReferralLink: Profile;
  trackSharing: ShareableEntity;
  unbanUser: Profile;
  unlikeCollabUpdate: CollabUpdateLike;
  unlikeComment: CommentLike;
  updateAd: Ad;
  updateCartItem: CartItemWithIdentity;
  updateCatalogProduct: CatalogProduct;
  updateCategory: Category;
  /** @deprecated Replaced by updateCollabWithMedia */
  updateCollab: Collab;
  updateCollabCampaign: CollabCampaign;
  updateCollabUpdate: CollabUpdate;
  updateCollabWithMedia: CollabWithMedia;
  updateContest: Contest;
  updateFeatureAnnouncement: FeatureAnnouncement;
  updateGenericPromo: GenericPromo;
  updateMilestoneObjective: MilestoneObjective;
  updateOwnershipOfCollab: Collab;
  updatePreorderPaymentMethod: Order;
  updateProfile: Profile;
  updateUserState: UserState;
};

export type MutationAddToCartArgs = {
  anonymousCartId?: InputMaybe<Scalars["AnonymousCartId"]["input"]>;
  input: AddToCartInput;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

export type MutationAddToCartFromFeedArgs = {
  input: AddToCartFromFeedInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationArchiveCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationBanUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationBuyNowCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationCancelCollabCampaignArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationCancelPreorderArgs = {
  input?: InputMaybe<CancelPreorderInput>;
  orderId: Scalars["String"]["input"];
};

export type MutationCartCheckoutArgs = {
  anonymousCartId?: InputMaybe<Scalars["AnonymousCartId"]["input"]>;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

export type MutationChargeCustomerForPreorderArgs = {
  orderId: Scalars["OrderId"]["input"];
};

export type MutationComputeShippingRateArgs = {
  input: ComputeShippingRatesInput;
};

export type MutationCreateAdArgs = {
  input: CreateAdInput;
};

export type MutationCreateBehindTheScenesVideoArgs = {
  input: CreateBehindTheScenesVideoInput;
};

export type MutationCreateCatalogProductArgs = {
  input: CreateCatalogProductInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateCollabUpdateArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: CreateCollabUpdateInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationCreateCollabUpdateCommentArgs = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
  input: CreateCommentInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationCreateCommentArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: CreateCommentInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationCreateContestArgs = {
  input: CreateContestInput;
};

export type MutationCreateContestCollabArgs = {
  input: CreateContestCollabInput;
};

export type MutationCreateFeatureAnnouncementArgs = {
  input: CreateFeatureAnnouncementInput;
};

export type MutationCreateGenericPromoArgs = {
  input: CreatePromoInput;
};

export type MutationCreateMilestoneObjectiveArgs = {
  input: CreateMilestoneObjectiveInput;
};

export type MutationCreatePreorderArgs = {
  input: CreatePreorderInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationCreatePreorderForAnonymousArgs = {
  anonymousCustomerId: Scalars["AnonymousCustomerId"]["input"];
  input: CreatePreorderForAnonymousInput;
};

export type MutationCreatePreorderFromExistingPreorderArgs = {
  orderId: Scalars["OrderId"]["input"];
};

export type MutationCreateStudioCollabArgs = {
  input: CreateStudioCollabInput;
};

export type MutationCreditSupercoinsArgs = {
  input: CreditSupercoinsInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteAdArgs = {
  adId: Scalars["AdId"]["input"];
};

export type MutationDeleteAllCollabUpdateLikesArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteAllCommentLikesArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteAllLikesArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteBehindTheScenesVideoArgs = {
  behindTheScenesVideoId: Scalars["BehindTheScenesVideoId"]["input"];
};

export type MutationDeleteCampaignVideoArgs = {
  campaignVideoId: Scalars["CampaignVideoId"]["input"];
};

export type MutationDeleteCartItemArgs = {
  cartItemId?: InputMaybe<Scalars["CartItemId"]["input"]>;
};

export type MutationDeleteCatalogProductArgs = {
  catalogProductId: Scalars["CatalogProductId"]["input"];
};

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars["CategoryId"]["input"];
};

export type MutationDeleteCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationDeleteCollabUpdateArgs = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
};

export type MutationDeleteCollabsForUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["CommentId"]["input"];
};

export type MutationDeleteFeatureAnnouncementArgs = {
  featureAnnouncementId: Scalars["FeatureAnnouncementId"]["input"];
};

export type MutationDeleteGenericPromoArgs = {
  promoId: Scalars["PromoId"]["input"];
};

export type MutationDeleteInstagramAccountDataArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars["String"]["input"];
};

export type MutationDeleteProfileArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteProfileImageArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDeleteStudioImagesArgs = {
  imageIds: Array<Scalars["StudioImageId"]["input"]>;
};

export type MutationDeleteStudioMediaArgs = {
  where: DeleteStudioMediaInput;
};

export type MutationDenyCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationDeprioritizeUserForGenerationsArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationDislikeCollabArgs = {
  input: DislikeCollabInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationDislikeCollabInFeedArgs = {
  input: DislikeCollabInFeedInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationForceMarkPreorderAsPaidArgs = {
  orderId: Scalars["OrderId"]["input"];
};

export type MutationFulfillCollabCampaignArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationGenerateAddPaymentMethodDataArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationGenerateCollabImageDownloadLinkArgs = {
  collabImageId: Scalars["CollabImageId"]["input"];
};

export type MutationGenerateInstagramLoginUrlArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationGenerateStudioImageDownloadLinkArgs = {
  input: SaveTemporaryImageInput;
};

export type MutationGrantRolesArgs = {
  input: UpdateRoleInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationIncreaseTokenBankArgs = {
  input: IncreaseTokenBankInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationLikeCollabArgs = {
  input: LikeCollabInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationLikeCollabInFeedArgs = {
  input: LikeCollabInFeedInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationLikeCollabUpdateArgs = {
  input: LikeCollabUpdateInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationLikeCommentArgs = {
  input: LikeCommentInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationMarkAdAsSeenArgs = {
  input: MarkAdAsSeenInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationMarkFeatureAnnouncementAsSeenArgs = {
  input: MarkFeatureAnnouncementAsSeenInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationMarkPromoAsSeenArgs = {
  promoId: Scalars["PromoId"]["input"];
  userId: Scalars["UserId"]["input"];
};

export type MutationMoveCollabToContestArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationMoveCollabToDevelopmentDecisionArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationMoveCollabToDevelopmentDeniedArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationMoveCollabToDevelopmentFailedArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationMoveCollabToUnderReviewDeniedArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationNewCreateGenericPromoArgs = {
  input: CreatePromoInput;
};

export type MutationNewDeleteGenericPromoArgs = {
  promoId: Scalars["PromoId"]["input"];
};

export type MutationNewMarkPromoAsSeenArgs = {
  promoId: Scalars["PromoId"]["input"];
  userId: Scalars["UserId"]["input"];
};

export type MutationNewUpdateGenericPromoArgs = {
  input: UpdatePromoInput;
  promoId: Scalars["PromoId"]["input"];
};

export type MutationPromoteCollabToConceptArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationPromoteCollabToForSaleArgs = {
  collabId: Scalars["CollabId"]["input"];
  input?: InputMaybe<PromoteCollabToForSaleInput>;
};

export type MutationPromoteCollabToInDevelopmentArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationPromoteCollabToUnderReviewArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: PromoteCollabToUnderReviewInput;
};

export type MutationPromoteCollabToUnderReviewReadyArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: PromoteCollabToUnderReviewReadyInput;
};

export type MutationPromoteCollabsToConceptOnContestEndArgs = {
  contestId: Scalars["ContestId"]["input"];
};

export type MutationReorderAdsArgs = {
  input: ReorderAdsInput;
};

export type MutationReportArgs = {
  entityId: Scalars["ReportableEntityId"]["input"];
  input?: InputMaybe<ReportInput>;
};

export type MutationRequestDeleteProfileArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationRequestQuoteArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationRequestQuoteForCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationRequestToBecomeCreatorArgs = {
  input: RequestToBecomeCreatorInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationResetFeedArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationResetGenerationPriorityForUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationResetUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationRevokePushNotificationsTokenArgs = {
  input: PushNotificationsTokenInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationRevokeRolesArgs = {
  input: UpdateRoleInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSaveTemporaryAnimationArgs = {
  input: SaveTemporaryAnimationInput;
};

export type MutationSaveTemporaryImagesArgs = {
  input: SaveTemporaryImagesInput;
};

export type MutationSendFeedbackArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
};

export type MutationSendPushNotificationArgs = {
  input: SendPushNotificationsInput;
  userIds: Array<Scalars["UserId"]["input"]>;
};

export type MutationSendPushNotificationToAllUsersArgs = {
  input: SendPushNotificationsInput;
};

export type MutationSetCreatorStatusArgs = {
  input: SetCreatorStatusInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetFeaturedCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
  input?: InputMaybe<FeaturedCollabInput>;
};

export type MutationSetFeaturedCreatorArgs = {
  input?: InputMaybe<FeaturedCreatorInput>;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetImageShownInExploreArgs = {
  imageId: Scalars["StudioImageId"]["input"];
  input: SetImageInExploreInput;
};

export type MutationSetProfileStaffStatusArgs = {
  input: SetProfileStaffStatusInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetPushNotificationSettingsArgs = {
  input: PushNotificationsSettingsInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetPushNotificationsTokenArgs = {
  input: PushNotificationsTokenInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetReferrerUserArgs = {
  input: SetReferrerUserInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetShippingAddressArgs = {
  input: SetShippingAddressInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSetStudioImageShownInExploreArgs = {
  imageId: Scalars["StudioImageId"]["input"];
  input: SetImageInExploreInput;
};

export type MutationSoftDeleteCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationStartCampaignArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type MutationStartGeneratingAnimationFromImageArgs = {
  input: GenerateAnimationFromImageInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationStartGeneratingDescriptionArgs = {
  input: StartGeneratingDescriptionInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationStartGeneratingImagesArgs = {
  input: GenerateImagesInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationStartGeneratingImagesFromImageArgs = {
  input: GenerateImagesFromImageInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationStartGeneratingPosesArgs = {
  input: GeneratePosesInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationStartGeneratingVariationsArgs = {
  input: GenerateVariationInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationSubscribeToNewsletterArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationSupersellerCheckoutArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationTakeOwnershipOfCollabsFromUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationTrackShareReferralLinkArgs = {
  input: TrackShareReferralLinkInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationTrackSharingArgs = {
  input: TrackSharingInput;
};

export type MutationUnbanUserArgs = {
  userId: Scalars["UserId"]["input"];
};

export type MutationUnlikeCollabUpdateArgs = {
  input: UnlikeCollabUpdateInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationUnlikeCommentArgs = {
  input: UnlikeCommentInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationUpdateAdArgs = {
  adId: Scalars["AdId"]["input"];
  input: UpdateAdInput;
};

export type MutationUpdateCartItemArgs = {
  cartItemId: Scalars["CartItemId"]["input"];
  input: UpdateCartItemInput;
};

export type MutationUpdateCatalogProductArgs = {
  catalogProductId: Scalars["CatalogProductId"]["input"];
  input: UpdateCatalogProductInput;
};

export type MutationUpdateCategoryArgs = {
  categoryId: Scalars["CategoryId"]["input"];
  input: UpdateCategoryInput;
};

export type MutationUpdateCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: UpdateCollabInput;
};

export type MutationUpdateCollabCampaignArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: UpdateCollabCampaignInput;
};

export type MutationUpdateCollabUpdateArgs = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
  input: UpdateCollabUpdateInput;
};

export type MutationUpdateCollabWithMediaArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: UpdateCollabWithMediaInput;
};

export type MutationUpdateContestArgs = {
  contestId: Scalars["ContestId"]["input"];
  input: UpdateContestInput;
};

export type MutationUpdateFeatureAnnouncementArgs = {
  featureAnnouncementId: Scalars["FeatureAnnouncementId"]["input"];
  input: UpdateFeatureAnnouncementInput;
};

export type MutationUpdateGenericPromoArgs = {
  input: UpdatePromoInput;
  promoId: Scalars["PromoId"]["input"];
};

export type MutationUpdateMilestoneObjectiveArgs = {
  input: UpdateMilestoneObjectiveInput;
  milestoneObjectiveId: Scalars["MilestoneObjectiveId"]["input"];
};

export type MutationUpdateOwnershipOfCollabArgs = {
  collabId: Scalars["CollabId"]["input"];
  input: UpdateCollabOwnershipInput;
};

export type MutationUpdatePreorderPaymentMethodArgs = {
  input: UpdatePreorderPaymentMethodInput;
  orderId: Scalars["OrderId"]["input"];
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
  userId: Scalars["UserId"]["input"];
};

export type MutationUpdateUserStateArgs = {
  input: UpdateUserStateInput;
  userId: Scalars["UserId"]["input"];
};

export type NewsletterSubscription = {
  __typename: "NewsletterSubscription";
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type Notification = {
  __typename: "Notification";
  activities: Array<NotificationActivity>;
  actorCount: Scalars["Int"]["output"];
  isSeen: Scalars["Boolean"]["output"];
  notificationId: Scalars["NotificationId"]["output"];
  verb: ActivityVerb;
};

export type NotificationActivity = {
  __typename: "NotificationActivity";
  activityId: Scalars["NotificationActivityId"]["output"];
  actor: NotificationActor;
  happenedAt: Scalars["DateTime"]["output"];
  object: NotificationObject;
};

export type NotificationActor = Collab | CollabUpdate | Contest | DeletedObject | Order | Profile;

export type NotificationObject = Collab | CollabUpdate | Contest | DeletedObject | Order | Profile;

export type NotificationsPageInfo = {
  __typename: "NotificationsPageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
  next?: Maybe<Scalars["NotificationId"]["output"]>;
};

export type NotificationsToken = {
  __typename: "NotificationsToken";
  token: Scalars["String"]["output"];
};

export enum OptionType {
  color = "color",
  material = "material",
  size = "size",
  style = "style",
}

export type Order = {
  __typename: "Order";
  createdAt: Scalars["DateTime"]["output"];
  formattedTotalPrice: Scalars["String"]["output"];
  isPreorder: Scalars["Boolean"]["output"];
  lineItems: Array<OrderLineItem>;
  orderId: Scalars["OrderId"]["output"];
  orderPageUrl: Scalars["URL"]["output"];
  paymentFailedAt?: Maybe<Scalars["DateTime"]["output"]>;
  paymentInError: Scalars["Boolean"]["output"];
  paymentWillBeRetriedAt?: Maybe<Scalars["DateTime"]["output"]>;
  salesTaxPrice: Scalars["Decimal"]["output"];
  shippingPrice: Scalars["Decimal"]["output"];
  status: OrderStatus;
  subtotalPrice: Scalars["Decimal"]["output"];
  supercoinAmount: Scalars["BigInt"]["output"];
  totalPrice: Scalars["Decimal"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type OrderLineItem = {
  __typename: "OrderLineItem";
  collab?: Maybe<Collab>;
  formattedPrice: Scalars["String"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Int"]["output"];
  variant?: Maybe<ProductVariant>;
};

export enum OrderStatus {
  cancelled = "cancelled",
  confirmed = "confirmed",
  inProgress = "inProgress",
  refunded = "refunded",
  shipped = "shipped",
}

export type PageInfo = {
  __typename: "PageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaginatedAds = {
  __typename: "PaginatedAds";
  nodes?: Maybe<Array<Ad>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedAnyPromo = {
  __typename: "PaginatedAnyPromo";
  nodes?: Maybe<Array<AnyPromo>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedBehindTheScenesVideos = {
  __typename: "PaginatedBehindTheScenesVideos";
  nodes?: Maybe<Array<BehindTheScenesVideo>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedCatalogProduct = {
  __typename: "PaginatedCatalogProduct";
  nodes?: Maybe<Array<CatalogProduct>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedCategoriesWithCount = {
  __typename: "PaginatedCategoriesWithCount";
  nodes?: Maybe<Array<CategoryWithCount>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedCollabVariants = {
  __typename: "PaginatedCollabVariants";
  nodes?: Maybe<Array<CollabVariants>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedCollabs = {
  __typename: "PaginatedCollabs";
  nodes?: Maybe<Array<Collab>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedCollabsWithFacets = {
  __typename: "PaginatedCollabsWithFacets";
  facets: CollabFacets;
  nodes?: Maybe<Array<Collab>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedComments = {
  __typename: "PaginatedComments";
  nodes?: Maybe<Array<Comment>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedContestResults = {
  __typename: "PaginatedContestResults";
  nodes?: Maybe<Array<ContestResult>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedContests = {
  __typename: "PaginatedContests";
  nodes?: Maybe<Array<Contest>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedExploreStudioImages = {
  __typename: "PaginatedExploreStudioImages";
  nodes?: Maybe<Array<ExploreStudioImage>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedFeatureAnnouncements = {
  __typename: "PaginatedFeatureAnnouncements";
  nodes?: Maybe<Array<FeatureAnnouncement>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedFeedCollabOrPromo = {
  __typename: "PaginatedFeedCollabOrPromo";
  nodes?: Maybe<Array<FeedCollabOrPromo>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedFeedEntitiesWithPromo = {
  __typename: "PaginatedFeedEntitiesWithPromo";
  nodes?: Maybe<Array<FeedEntity>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedInstagramPictures = {
  __typename: "PaginatedInstagramPictures";
  nodes?: Maybe<Array<InstagramPicture>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedMilestoneProgress = {
  __typename: "PaginatedMilestoneProgress";
  nodes?: Maybe<Array<MilestoneProgress>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedNotifications = {
  __typename: "PaginatedNotifications";
  nodes: Array<Notification>;
  pageInfo: NotificationsPageInfo;
};

export type PaginatedOrders = {
  __typename: "PaginatedOrders";
  nodes?: Maybe<Array<Order>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedProfiles = {
  __typename: "PaginatedProfiles";
  nodes?: Maybe<Array<Profile>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedProfilesWithFacets = {
  __typename: "PaginatedProfilesWithFacets";
  facets: UserFacets;
  nodes?: Maybe<Array<Profile>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedStudioImages = {
  __typename: "PaginatedStudioImages";
  nodes?: Maybe<Array<StudioImage>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedStudioMedia = {
  __typename: "PaginatedStudioMedia";
  nodes?: Maybe<Array<StudioMedia>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedUpdates = {
  __typename: "PaginatedUpdates";
  nodes?: Maybe<Array<CollabUpdate>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Preorder = {
  __typename: "Preorder";
  orderId: Scalars["OrderId"]["output"];
  paymentMethodId: Scalars["String"]["output"];
  quantity: Scalars["Int"]["output"];
  supercoinAmount: Scalars["BigInt"]["output"];
  variantId: Scalars["String"]["output"];
};

export type ProductOption = {
  __typename: "ProductOption";
  name: OptionType;
  values: Array<Scalars["String"]["output"]>;
};

export type ProductVariant = {
  __typename: "ProductVariant";
  color?: Maybe<Scalars["String"]["output"]>;
  compareAtPrice?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  formattedCompareAtPrice?: Maybe<Scalars["String"]["output"]>;
  formattedPrice: Scalars["String"]["output"];
  inventoryQuantity: Scalars["Int"]["output"];
  material?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["Decimal"]["output"];
  size?: Maybe<Scalars["String"]["output"]>;
  style?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  variantId: Scalars["String"]["output"];
};

export type Profile = {
  __typename: "Profile";
  badge: Badge;
  bio?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creatorStatus: CreatorStatus;
  displayName: Scalars["String"]["output"];
  externalLink?: Maybe<Scalars["URL"]["output"]>;
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  instagramLink?: Maybe<Scalars["URL"]["output"]>;
  instagramUsername?: Maybe<Scalars["String"]["output"]>;
  occupation?: Maybe<Scalars["String"]["output"]>;
  pronouns: Array<Scalars["String"]["output"]>;
  shareId: Scalars["String"]["output"];
  superseller: Scalars["Boolean"]["output"];
  tags: Array<Scalars["String"]["output"]>;
  twitterLink?: Maybe<Scalars["URL"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["UserId"]["output"];
  username: Scalars["String"]["output"];
  video?: Maybe<ProfileVideo>;
};

export type ProfileFilter = {
  shareId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfileVideo = {
  __typename: "ProfileVideo";
  cloudflareId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  profileVideoId: Scalars["ProfileVideoId"]["output"];
  status: VideoStatus;
  thumbnailUrl?: Maybe<Scalars["URL"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  videoManifestUrl?: Maybe<Scalars["URL"]["output"]>;
  videoUrl?: Maybe<Scalars["URL"]["output"]>;
};

export enum ProfilesSortingMethod {
  mostPopular = "mostPopular",
}

export type Progress = {
  __typename: "Progress";
  canCreateCollabUpdate: Scalars["Boolean"]["output"];
  canRequestQuote: Scalars["Boolean"]["output"];
  canStartCampaign: Scalars["Boolean"]["output"];
  canUpdateCollab: Scalars["Boolean"]["output"];
  inDevelopment: Scalars["Boolean"]["output"];
  reviewEndAt?: Maybe<Scalars["DateTime"]["output"]>;
  stage: Stage;
  waitingForQuote: Scalars["Boolean"]["output"];
};

export type Promo = {
  __typename: "Promo";
  collab?: Maybe<Collab>;
  contest?: Maybe<Contest>;
  createdAt: Scalars["DateTime"]["output"];
  ctaLabel: Scalars["String"]["output"];
  ctaUrl: Scalars["URL"]["output"];
  endAt?: Maybe<Scalars["DateTime"]["output"]>;
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  promoId: Scalars["PromoId"]["output"];
  promoType: PromoType;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PromoType {
  generic = "generic",
  leaveReview = "leaveReview",
  newArrival = "newArrival",
  newContest = "newContest",
  tokenGift = "tokenGift",
}

export type PromoteCollabToForSaleInput = {
  externalProductId?: InputMaybe<Scalars["String"]["input"]>;
};

export type PromoteCollabToUnderReviewInput = {
  externalProductId: Scalars["String"]["input"];
  orderCountNeeded: Scalars["Int"]["input"];
  reviewEndAt: Scalars["DateTime"]["input"];
};

export type PromoteCollabToUnderReviewReadyInput = {
  externalProductId: Scalars["String"]["input"];
  orderCountNeeded: Scalars["Int"]["input"];
  reviewEndAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromotedCollabs = {
  __typename: "PromotedCollabs";
  promotedCollabs: Scalars["Int"]["output"];
};

export type PushNotificationsDataInput = {
  redirectId: Scalars["String"]["input"];
};

export type PushNotificationsSettingsInput = {
  accountUpdates?: InputMaybe<Scalars["Boolean"]["input"]>;
  collabProgress?: InputMaybe<Scalars["Boolean"]["input"]>;
  commentsAndReplies?: InputMaybe<Scalars["Boolean"]["input"]>;
  contestUpdates?: InputMaybe<Scalars["Boolean"]["input"]>;
  likes?: InputMaybe<Scalars["Boolean"]["input"]>;
  privateMessages?: InputMaybe<Scalars["Boolean"]["input"]>;
  productUpdates?: InputMaybe<Scalars["Boolean"]["input"]>;
  promotions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PushNotificationsToken = {
  __typename: "PushNotificationsToken";
  token: Scalars["String"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type PushNotificationsTokenInput = {
  token: Scalars["String"]["input"];
};

export type PushNotificationsUserSettings = {
  __typename: "PushNotificationsUserSettings";
  accountUpdates: Scalars["Boolean"]["output"];
  collabProgress: Scalars["Boolean"]["output"];
  commentsAndReplies: Scalars["Boolean"]["output"];
  contestUpdates: Scalars["Boolean"]["output"];
  likes: Scalars["Boolean"]["output"];
  privateMessages: Scalars["Boolean"]["output"];
  productUpdates: Scalars["Boolean"]["output"];
  promotions: Scalars["Boolean"]["output"];
};

export type Query = {
  __typename: "Query";
  achievedMilestonesForTesting: Array<AchievedMilestone>;
  ad: Ad;
  ads: PaginatedAds;
  behindTheScenesVideos: PaginatedBehindTheScenesVideos;
  browseCollabs: PaginatedCollabsWithFacets;
  browseUsers: PaginatedProfilesWithFacets;
  cart: Cart;
  catalogProduct: CatalogProduct;
  catalogProducts: PaginatedCatalogProduct;
  categories: PaginatedCategoriesWithCount;
  /** @deprecated Replaced by collabWithMedia */
  collab: Collab;
  collabCampaign: CollabCampaign;
  collabUpdate: CollabUpdate;
  collabUpdateComments: PaginatedComments;
  collabUpdates: PaginatedUpdates;
  collabWithMedia: CollabWithMedia;
  collabsForUser: PaginatedCollabs;
  collabsInContest: PaginatedContestResults;
  comments: PaginatedComments;
  computeAdditionalCharges: AdditionalCharges;
  concepts: PaginatedCollabs;
  contest: Contest;
  contestPasswordValidation: ContestPasswordValidation;
  contestSubmissionCount: ContestSubmissionCount;
  contests: PaginatedContests;
  currentFeatureAnnouncement?: Maybe<FeatureAnnouncement>;
  detailedProgress: DetailedProgress;
  /** @deprecated Replaced by exploreStudioImage */
  exploreImage: StudioImage;
  /** @deprecated Replaced by exploreStudioImages */
  exploreImages: PaginatedStudioImages;
  exploreStudioImage: ExploreStudioImage;
  exploreStudioImages: PaginatedExploreStudioImages;
  featureAccesses: FeatureAccesses;
  featureAnnouncement: FeatureAnnouncement;
  featureAnnouncements: PaginatedFeatureAnnouncements;
  featuredCollab: Collab;
  featuredCreator: Profile;
  /** @deprecated Replaced by feedWithMedia */
  feed: PaginatedFeedCollabOrPromo;
  feedWithMedia: PaginatedFeedEntitiesWithPromo;
  forSaleCollabs: PaginatedCollabs;
  frontendConfig: FrontendConfig;
  generatedDescription?: Maybe<GeneratedDescription>;
  health: Health;
  imageGenerationStatus: ImageGenerationStatus;
  inDevelopmentCollabs: PaginatedCollabs;
  instagramLink: InstagramLink;
  instagramPictures: PaginatedInstagramPictures;
  latestCollabUpdatesForUser: PaginatedUpdates;
  likes: PaginatedCollabs;
  milestoneProgresses: PaginatedMilestoneProgress;
  /** @deprecated renamed to promo */
  newPromo: AnyPromo;
  /** @deprecated renamed to promos */
  newPromos: PaginatedAnyPromo;
  notifications: PaginatedNotifications;
  notificationsToken: NotificationsToken;
  orders: PaginatedOrders;
  paymentMethodForAnonymous: CardPaymentMethod;
  paymentMethods: Array<CardPaymentMethod>;
  profile: Profile;
  profileFromToken: Profile;
  profiles: PaginatedProfiles;
  promo: AnyPromo;
  promos: PaginatedAnyPromo;
  purchaseableCollabs: PaginatedCollabs;
  pushNotificationSettings: PushNotificationsUserSettings;
  quoteRequestStatus: QuoteRequestStatus;
  /** @deprecated Use searchCollabs instead */
  recommendCollabs: PaginatedCollabs;
  referralLink: ShareLink;
  searchCollabs: PaginatedCollabsWithFacets;
  searchUsers: PaginatedProfilesWithFacets;
  shareLink: ShareLink;
  shippingAddress?: Maybe<ShippingAddress>;
  smallestMilestoneObjective: MilestoneObjective;
  /** @deprecated no longer needed with desktop version */
  storefrontLocation: StorefrontLocation;
  studioAnimation: StudioAnimation;
  studioImage: StudioImage;
  /** @deprecated moved to studioMedia */
  studioImages: PaginatedStudioImages;
  studioMedia: PaginatedStudioMedia;
  subscriptionForTesting?: Maybe<BloomSubscription>;
  supercoinBalance: SupercoinBalance;
  supercoinLimitForBuyNowCheckout: SupercoinLimit;
  supercoinLimitForCartCheckout: SupercoinLimit;
  supercoinLimitForCreatePreorder: SupercoinLimit;
  temporaryStudioAnimation?: Maybe<StudioAnimation>;
  temporaryStudioImages: PaginatedStudioImages;
  tokenCosts: TokenCosts;
  underReviewCollabs: PaginatedCollabs;
  uploadVideoInitialContainer: UploadVideoInitialContainer;
  userState: UserState;
  variants: PaginatedCollabVariants;
  /** @deprecated Replaced by `numberOfLikes` property on `Collab` type */
  voteCount: VoteCount;
};

export type QueryAchievedMilestonesForTestingArgs = {
  where: AchievedMilestoneFilteringArgs;
};

export type QueryAdArgs = {
  adId: Scalars["AdId"]["input"];
};

export type QueryBehindTheScenesVideosArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryBrowseCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<SearchCollabsSortingMethod>;
  where?: InputMaybe<SearchCollabsFilteringArgs>;
};

export type QueryBrowseUsersArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SearchUsersFilteringArgs>;
};

export type QueryCartArgs = {
  anonymousCartId?: InputMaybe<Scalars["AnonymousCartId"]["input"]>;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

export type QueryCatalogProductArgs = {
  catalogProductId: Scalars["CatalogProductId"]["input"];
};

export type QueryCatalogProductsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CatalogProductsFilteringArgs>;
};

export type QueryCategoriesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCollabArgs = {
  collabId?: InputMaybe<Scalars["CollabId"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCollabCampaignArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type QueryCollabUpdateArgs = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
};

export type QueryCollabUpdateCommentsArgs = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCollabUpdatesArgs = {
  collabId: Scalars["CollabId"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCollabWithMediaArgs = {
  collabId?: InputMaybe<Scalars["CollabId"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCollabsForUserArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<CollabSortingMethod>;
  where: CollabsForUserFilteringArgs;
};

export type QueryCollabsInContestArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where: CollabContestsFilteringArgs;
};

export type QueryCommentsArgs = {
  collabId: Scalars["CollabId"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryComputeAdditionalChargesArgs = {
  input: ComputeAdditionalChargesInput;
};

export type QueryConceptsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: ConceptsSortingMethod;
  where?: InputMaybe<ConceptsFilteringArgs>;
};

export type QueryContestArgs = {
  contestId?: InputMaybe<Scalars["ContestId"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContestPasswordValidationArgs = {
  contestId: Scalars["ContestId"]["input"];
  password: Scalars["String"]["input"];
};

export type QueryContestSubmissionCountArgs = {
  contestId: Scalars["ContestId"]["input"];
  userId: Scalars["UserId"]["input"];
};

export type QueryContestsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ContestSortingMethod>;
  where?: InputMaybe<ContestFilter>;
};

export type QueryDetailedProgressArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type QueryExploreImageArgs = {
  studioImageId: Scalars["StudioImageId"]["input"];
};

export type QueryExploreImagesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryExploreStudioImageArgs = {
  studioImageId: Scalars["StudioImageId"]["input"];
};

export type QueryExploreStudioImagesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFeatureAccessesArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryFeatureAnnouncementArgs = {
  featureAnnouncementId: Scalars["FeatureAnnouncementId"]["input"];
};

export type QueryFeatureAnnouncementsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFeaturedCollabArgs = {
  sortingMethod?: InputMaybe<FeaturedCollabSortingMethod>;
  stage: FeaturedCollabStage;
};

export type QueryFeaturedCreatorArgs = {
  sortingMethod?: InputMaybe<FeaturedCreatorSortingMethod>;
};

export type QueryFeedArgs = {
  after?: InputMaybe<Scalars["FeedEntityId"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

export type QueryFeedWithMediaArgs = {
  after?: InputMaybe<Scalars["FeedEntityId"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

export type QueryForSaleCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: ForSaleCollabsSortingMethod;
  where?: InputMaybe<ForSaleCollabsFilteringArgs>;
};

export type QueryGeneratedDescriptionArgs = {
  userId: Scalars["UserId"]["input"];
  where: GeneratedDescriptionFilteringArgs;
};

export type QueryInDevelopmentCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InDevelopmentCollabsSortingMethod;
  where?: InputMaybe<InDevelopmentCollabsFilteringArgs>;
};

export type QueryInstagramLinkArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryInstagramPicturesArgs = {
  where: InstagramPictureFilteringArgs;
};

export type QueryLatestCollabUpdatesForUserArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["UserId"]["input"];
};

export type QueryLikesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<LikeSortingMethod>;
  userId: Scalars["UserId"]["input"];
  where?: InputMaybe<LikeFilteringArgs>;
};

export type QueryMilestoneProgressesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["UserId"]["input"];
  where?: InputMaybe<MilestoneProgressFilteringArgs>;
};

export type QueryNewPromoArgs = {
  promoId: Scalars["PromoId"]["input"];
};

export type QueryNewPromosArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars["NotificationId"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  userId: Scalars["UserId"]["input"];
};

export type QueryNotificationsTokenArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryPaymentMethodForAnonymousArgs = {
  anonymousCustomerId: Scalars["AnonymousCustomerId"]["input"];
};

export type QueryPaymentMethodsArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryProfileArgs = {
  where?: InputMaybe<ProfileFilter>;
};

export type QueryProfilesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: ProfilesSortingMethod;
};

export type QueryPromoArgs = {
  promoId: Scalars["PromoId"]["input"];
};

export type QueryPromosArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPurchaseableCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPushNotificationSettingsArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryQuoteRequestStatusArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type QueryRecommendCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SearchCollabsFilteringArgs>;
};

export type QueryReferralLinkArgs = {
  input: ReferralLinkInput;
  userId: Scalars["UserId"]["input"];
};

export type QuerySearchCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  query: Scalars["String"]["input"];
  sortBy?: InputMaybe<SearchCollabsSortingMethod>;
  where?: InputMaybe<SearchCollabsFilteringArgs>;
};

export type QuerySearchUsersArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  query: Scalars["String"]["input"];
  where?: InputMaybe<SearchUsersFilteringArgs>;
};

export type QueryShareLinkArgs = {
  input: ShareLinkInput;
};

export type QueryShippingAddressArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QuerySmallestMilestoneObjectiveArgs = {
  where: SmallestMilestoneFilteringArgs;
};

export type QueryStorefrontLocationArgs = {
  collabId?: InputMaybe<Scalars["CollabId"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStudioAnimationArgs = {
  studioAnimationId: Scalars["StudioAnimationId"]["input"];
};

export type QueryStudioImageArgs = {
  imageId: Scalars["StudioImageId"]["input"];
};

export type QueryStudioImagesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where: StudioImagesFilteringArgs;
};

export type QueryStudioMediaArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where: StudioMediaFilteringArgs;
};

export type QuerySubscriptionForTestingArgs = {
  userId: Scalars["UserId"]["input"];
  where: SubscriptionFilteringArgs;
};

export type QuerySupercoinBalanceArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QuerySupercoinLimitForBuyNowCheckoutArgs = {
  input: CheckoutInput;
};

export type QuerySupercoinLimitForCartCheckoutArgs = {
  input: SupercoinLimitCartInput;
};

export type QuerySupercoinLimitForCreatePreorderArgs = {
  input: SupercoinLimitPreorderInput;
};

export type QueryTemporaryStudioAnimationArgs = {
  userId: Scalars["UserId"]["input"];
  where: TemporaryStudioAnimationFilteringArgs;
};

export type QueryTemporaryStudioImagesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  where: TemporaryStudioImagesFilteringArgs;
};

export type QueryUnderReviewCollabsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: UnderReviewCollabsSortingMethod;
  where?: InputMaybe<UnderReviewCollabsFilteringArgs>;
};

export type QueryUploadVideoInitialContainerArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryUserStateArgs = {
  userId: Scalars["UserId"]["input"];
};

export type QueryVariantsArgs = {
  where?: InputMaybe<VariantsFilteringArgs>;
};

export type QueryVoteCountArgs = {
  collabId: Scalars["CollabId"]["input"];
};

export type QuoteRequest = {
  __typename: "QuoteRequest";
  collab: Collab;
  quoteRequested: Scalars["Boolean"]["output"];
};

export type QuoteRequestStatus = {
  __typename: "QuoteRequestStatus";
  canRequestQuote: Scalars["Boolean"]["output"];
  collab: Collab;
  nextQuoteRequestAvailableAt?: Maybe<Scalars["DateTime"]["output"]>;
  quoteRequested: Scalars["Boolean"]["output"];
  quoteRequestsLeft: Scalars["Int"]["output"];
  quoteRequestsPerWeek: Scalars["Int"]["output"];
};

export type ReferralLinkInput = {
  targetSocialMedia: TargetSocialMedia;
};

export type ReorderAdsInput = {
  orderedAdIds: Array<Scalars["AdId"]["input"]>;
};

export type ReportInput = {
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReportableEntity = Collab | Comment | Profile;

export type RequestToBecomeCreatorInput = {
  email: Scalars["String"]["input"];
};

export type Roles = {
  __typename: "Roles";
  roles: Array<GrantedRole>;
  userId: Scalars["UserId"]["output"];
};

export type SalesTax = {
  __typename: "SalesTax";
  tax: Scalars["Decimal"]["output"];
};

export type SaveTemporaryAnimationInput = {
  studioAnimationId: Scalars["StudioAnimationId"]["input"];
};

export type SaveTemporaryImageInput = {
  imageId: Scalars["StudioImageId"]["input"];
};

export type SaveTemporaryImagesInput = {
  imageIds: Array<Scalars["StudioImageId"]["input"]>;
};

export type SearchCollabsFilteringArgs = {
  categoryIds?: InputMaybe<Array<Scalars["CategoryId"]["input"]>>;
  creatorIds?: InputMaybe<Array<Scalars["UserId"]["input"]>>;
  stages?: InputMaybe<Array<Stage>>;
};

export enum SearchCollabsSortingMethod {
  createdAtAsc = "createdAtAsc",
  createdAtDesc = "createdAtDesc",
  popular = "popular",
  priceAsc = "priceAsc",
  priceDesc = "priceDesc",
}

export type SearchUsersFilteringArgs = {
  isCreator?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SendPushNotificationsInput = {
  data?: InputMaybe<PushNotificationsDataInput>;
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type SentCustomPushNotification = {
  __typename: "SentCustomPushNotification";
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  userIds: Array<Scalars["String"]["output"]>;
};

export type SetCreatorStatusInput = {
  creatorStatus: CreatorStatus;
};

export type SetImageInExploreInput = {
  isExploreImage: Scalars["Boolean"]["input"];
};

export type SetProfileStaffStatusInput = {
  staff: Scalars["Boolean"]["input"];
};

export type SetReferrerUserInput = {
  referrerShareId: Scalars["String"]["input"];
};

export type SetShippingAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["CountryCode"]["input"];
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type ShareLink = {
  __typename: "ShareLink";
  url: Scalars["URL"]["output"];
};

export type ShareLinkInput = {
  entityId: Scalars["ShareableEntityId"]["input"];
  targetSocialMedia: TargetSocialMedia;
};

export type ShareableEntity = Collab | CollabUpdate | Contest | Profile;

export type ShippingAddress = {
  __typename: "ShippingAddress";
  city?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["CountryCode"]["output"];
  line1?: Maybe<Scalars["String"]["output"]>;
  line2?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type ShippingRate = {
  __typename: "ShippingRate";
  price: Scalars["Decimal"]["output"];
  shippingRateId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SmallestMilestoneFilteringArgs = {
  milestoneType: MilestoneType;
};

export enum Stage {
  concept = "concept",
  contest = "contest",
  forSale = "forSale",
  hidden = "hidden",
  underReview = "underReview",
}

export type StageDetails = {
  __typename: "StageDetails";
  detailedStage: DetailedStage;
  stage: Stage;
};

export type StageFacetValue = {
  __typename: "StageFacetValue";
  resultCount: Scalars["Int"]["output"];
  stage: Stage;
};

export type StandardPromo = {
  __typename: "StandardPromo";
  collab?: Maybe<Collab>;
  contest?: Maybe<Contest>;
  createdAt: Scalars["DateTime"]["output"];
  ctaLabel: Scalars["String"]["output"];
  ctaUrl: Scalars["URL"]["output"];
  imageUrl?: Maybe<Scalars["URL"]["output"]>;
  promoId: Scalars["PromoId"]["output"];
  promoType: PromoType;
  subtitle: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type StartGeneratingDescriptionInput = {
  imageId: Scalars["AnyImageScalarId"]["input"];
};

export type StorefrontLocation = {
  __typename: "StorefrontLocation";
  url: Scalars["URL"]["output"];
};

export type StudioAnimation = {
  __typename: "StudioAnimation";
  cloudflareId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  status: VideoStatus;
  studioAnimationId: Scalars["StudioAnimationId"]["output"];
  studioAnimationStatus: StudioAnimationStatus;
  thumbnailUrl?: Maybe<Scalars["URL"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["UserId"]["output"];
  videoManifestUrl?: Maybe<Scalars["URL"]["output"]>;
  videoStatus: VideoStatus;
  videoUrl?: Maybe<Scalars["URL"]["output"]>;
};

export enum StudioAnimationStatus {
  permanent = "permanent",
  temporary = "temporary",
}

export type StudioImage = {
  __typename: "StudioImage";
  catalogProduct?: Maybe<CatalogProduct>;
  createdAt: Scalars["DateTime"]["output"];
  imageId: Scalars["StudioImageId"]["output"];
  imageUrl: Scalars["String"]["output"];
  isExploreImage: Scalars["Boolean"]["output"];
  isNsfw: Scalars["Boolean"]["output"];
  prompt?: Maybe<Scalars["String"]["output"]>;
  source: ImageSource;
  status: StudioImageStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum StudioImageStatus {
  permanent = "permanent",
  temporary = "temporary",
}

export type StudioImagesFilteringArgs = {
  userId: Scalars["UserId"]["input"];
};

export type StudioMedia = StudioAnimation | StudioImage;

export type StudioMediaFilteringArgs = {
  userId: Scalars["UserId"]["input"];
};

export type StudioTask = {
  __typename: "StudioTask";
  createdAt: Scalars["DateTime"]["output"];
  studioTaskId: Scalars["StudioTaskId"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type StudioUser = {
  __typename: "StudioUser";
  deprioritized: Scalars["Boolean"]["output"];
  extraTokensAvailable: Scalars["Int"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type Subscription = {
  __typename: "Subscription";
  healthReported: Health;
  healthReportedFiltered: Health;
  uploadCompleted: UploadCompleted;
};

export type SubscriptionHealthReportedFilteredArgs = {
  testId: Scalars["String"]["input"];
};

export type SubscriptionUploadCompletedArgs = {
  uploadVideoId: Scalars["UploadVideoId"]["input"];
};

export type SubscriptionFilteringArgs = {
  type: SubscriptionType;
};

export enum SubscriptionType {
  supercreator = "supercreator",
}

export type SuccessfulReferral = {
  __typename: "SuccessfulReferral";
  referrerUserId: Scalars["UserId"]["output"];
};

export type SupercoinBalance = {
  __typename: "SupercoinBalance";
  amount: Scalars["BigInt"]["output"];
  formattedAmount: Scalars["String"]["output"];
};

export type SupercoinLimit = {
  __typename: "SupercoinLimit";
  balance: Scalars["BigInt"]["output"];
  /** @deprecated replaced by limitWithCurrentBalance */
  limit: Scalars["BigInt"]["output"];
  limitWithCurrentBalance: Scalars["BigInt"]["output"];
  limitWithInfiniteBalance: Scalars["BigInt"]["output"];
};

export type SupercoinLimitCartInput = {
  userId: Scalars["UserId"]["input"];
};

export type SupercoinLimitPreorderInput = {
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["String"]["input"];
};

export enum TargetSocialMedia {
  facebook = "facebook",
  reddit = "reddit",
  whatsApp = "whatsApp",
  x = "x",
}

export type TemporaryStudioAnimationFilteringArgs = {
  studioTaskId: Scalars["StudioTaskId"]["input"];
};

export type TemporaryStudioImagesFilteringArgs = {
  studioTaskId: Scalars["StudioTaskId"]["input"];
  userId: Scalars["UserId"]["input"];
};

export type TokenCosts = {
  __typename: "TokenCosts";
  tokensPerDay: Scalars["Int"]["output"];
};

export type TrackShareReferralLinkInput = {
  destination: Scalars["String"]["input"];
};

export type TrackSharingInput = {
  destination: Scalars["String"]["input"];
  entityId: Scalars["ShareableEntityId"]["input"];
};

export type UnderReviewCollabsFilteringArgs = {
  categoryIds?: InputMaybe<Array<Scalars["CategoryId"]["input"]>>;
  goalNotAchieved?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasAtLeastOneOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  notPastEndDate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum UnderReviewCollabsSortingMethod {
  closestToCampaignEnd = "closestToCampaignEnd",
  closestToGoal = "closestToGoal",
  newest = "newest",
  trending = "trending",
}

export type UnlikeCollabUpdateInput = {
  collabUpdateId: Scalars["CollabUpdateId"]["input"];
};

export type UnlikeCommentInput = {
  commentId: Scalars["CommentId"]["input"];
};

export type UpdateAdInput = {
  externalLink?: InputMaybe<Scalars["URL"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  internalLinkPath?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCartItemInput = {
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  variantId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCatalogProductInput = {
  categoryId?: InputMaybe<Scalars["CategoryId"]["input"]>;
  externalModelId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCategoryInput = {
  imageUrl?: InputMaybe<Scalars["URL"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCollabCampaignInput = {
  orderCountNeeded: Scalars["Int"]["input"];
};

export type UpdateCollabInput = {
  categoryId?: InputMaybe<Scalars["CategoryId"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageIds?: InputMaybe<Array<Scalars["AnyImageScalarId"]["input"]>>;
  mediaIds?: InputMaybe<Array<Scalars["AnyMediaScalarId"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCollabOwnershipInput = {
  targetUserId: Scalars["UserId"]["input"];
};

export type UpdateCollabUpdateInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  imageIds?: InputMaybe<Array<Scalars["CollabUpdateOrUploadImageId"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCollabWithMediaInput = {
  categoryId?: InputMaybe<Scalars["CategoryId"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  mediaIds?: InputMaybe<Array<Scalars["AnyMediaScalarId"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateContestInput = {
  categoryId?: InputMaybe<Scalars["CategoryId"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endOfVoteDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  goLiveDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  numberOfWinners?: InputMaybe<Scalars["Int"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  rewardType?: InputMaybe<ContestRewardType>;
  rewardValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFeatureAnnouncementInput = {
  externalLink?: InputMaybe<Scalars["URL"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  internalLinkPath?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMilestoneObjectiveInput = {
  isHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiredCount?: InputMaybe<Scalars["Int"]["input"]>;
  reward?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdatePreorderPaymentMethodInput = {
  paymentMethodId: Scalars["String"]["input"];
};

export type UpdateProfileInput = {
  bio?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  externalLink?: InputMaybe<Scalars["URL"]["input"]>;
  instagramLink?: InputMaybe<Scalars["URL"]["input"]>;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  pronouns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  twitterLink?: InputMaybe<Scalars["URL"]["input"]>;
  uploadVideoId?: InputMaybe<Scalars["NullableUploadVideoId"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePromoInput = {
  ctaLabel?: InputMaybe<Scalars["String"]["input"]>;
  ctaUrl?: InputMaybe<Scalars["URL"]["input"]>;
  endAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  imageUrl?: InputMaybe<Scalars["URL"]["input"]>;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateRoleInput = {
  roles: Array<GrantedRole>;
};

export type UpdateUserStateInput = {
  frontendState: Scalars["JSONObject"]["input"];
};

export type UploadCompleted = {
  __typename: "UploadCompleted";
  uploadVideoId: Scalars["UploadVideoId"]["output"];
};

export type UploadVideoInitialContainer = {
  __typename: "UploadVideoInitialContainer";
  uploadVideoId: Scalars["UploadVideoId"]["output"];
  url: Scalars["URL"]["output"];
};

export type UserFacets = {
  __typename: "UserFacets";
  isCreator: Array<IsCreatorFacetValue>;
};

export type UserState = {
  __typename: "UserState";
  frontendState: Scalars["JSONObject"]["output"];
  userId: Scalars["UserId"]["output"];
};

export type VariantsFilteringArgs = {
  collabIds?: Array<Scalars["CollabId"]["input"]>;
};

export enum VideoStatus {
  created = "created",
  processing = "processing",
  uploaded = "uploaded",
}

export type VoteCount = {
  __typename: "VoteCount";
  collabId: Scalars["CollabId"]["output"];
  count: Scalars["Int"]["output"];
};
