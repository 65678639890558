import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteCollab: Collab;
}

interface Variables {
  collabId: string;
}

interface DeleteCollabResult extends MutationResult<Response, "deleteCollab"> {
  deleteCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCollabMutation = gql`
  mutation DeleteCollab($collabId: CollabId!) {
    deleteCollab(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useDeleteCollab(): DeleteCollabResult {
  const [deleteCollab, { loading, error, data }] = useMutation<Response, Variables>(deleteCollabMutation, {
    update: (cache, result, options) => {
      cache.evict({ id: options.variables?.collabId });
    },
  });

  return { deleteCollab, loading, error, data: data?.deleteCollab };
}
