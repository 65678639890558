import { UserInfo } from "firebase/auth";

import { GrantedRole } from "@app/common/graphql/generated/schema.graphql";

export enum AuthContextAction {
  login,
  logout,
}

interface AuthContextLoggedInState {
  connected: true;
  loading: false;
  id: string;
  verified: boolean;
  email: string | null;
  token: string;
  providers: string[];
  roles: GrantedRole[];
  restored: boolean;
  isAdmin: boolean;
}

interface AuthContextLoggedOutState {
  connected: false;
  loading: false;
  id: null;
  verified: null;
  email: null;
  token: null;
  providers: string[];
  roles: GrantedRole[];
  restored: false;
  isAdmin: false;
}

interface AuthContextLoadingState {
  connected: false;
  loading: true;
  id: null;
  verified: null;
  email: null;
  token: null;
  providers: string[];
  roles: GrantedRole[];
  restored: false;
  isAdmin: false;
}

export type AuthContextStateInterface = AuthContextLoggedInState | AuthContextLoggedOutState | AuthContextLoadingState;

export interface FirebaseUserInterface {
  id: string;
  verified: boolean;
  email: string | null;
  token: string;
  providerData: UserInfo[];
}

interface LoginActionInterface {
  action: AuthContextAction.login;
  payload: FirebaseUserInterface;
}

interface LogoutActionInterface {
  action: AuthContextAction.logout;
}

export type ReducerActionInterface = LoginActionInterface | LogoutActionInterface;

export interface AuthContextInterface {
  logout: () => Promise<void>;
  setVerified: () => void;
  state: AuthContextStateInterface;
}
