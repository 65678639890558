import React, { FC } from "react";

import { LinearGradient, LinearGradientProps } from "expo-linear-gradient";

export const OnboardingGradient: FC<Omit<LinearGradientProps, "colors" | "locations" | "start" | "end">> = props => (
  <LinearGradient
    colors={["rgba(109, 33, 97, 1)", "rgba(0, 0, 0, 1)", "rgba(0, 0, 0, 1)", "rgba(16, 127, 199, 1)"]}
    locations={[0.03, 0.35, 0.65, 0.97]}
    start={{ x: 1, y: 0 }}
    end={{ x: 0, y: 1 }}
    {...props}
  />
);
