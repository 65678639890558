import React, { FC, useCallback, useMemo, useState } from "react";

import { Coins, XCircle } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import { StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { getMediaToBeUsedImageUrl } from "@app/common/types/image.type";
import { BottomSheetTextInput } from "@app/components/common/bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { isAndroidApp } from "@app/utils/device.util";

import { catalogProductImageSize, styles } from "./input-prompt.style";

interface ImageRowType {
  image?: string;
  onClear: () => void;
  name?: string;
}
interface Props {
  onPressGenerate: () => Promise<void>;
  withImage: boolean;
  onClear: () => void;
}

export const InputPrompt: FC<Props> = ({ onPressGenerate, withImage, onClear }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const { generation, setGeneration, isCurrentlyGenerating } = useStudioMiniContext();
  const { config } = useConfigContext();

  const prompt = generation.prompt ?? "";
  const is3dGeneration = generation.initialFlow === StudioFlowType.image3d;
  const tokensNeeded = is3dGeneration ? config?.tokensNeededForVideoGeneration : config?.tokensNeededForImageGeneration;

  const [isLoading, setIsLoading] = useState(false);
  const isDisabled = (!withImage && !prompt?.trim()) || (withImage && !generation.image);
  const canSubmit = !isDisabled && !isLoading && !isCurrentlyGenerating;

  const handleOnGenerate = (): void => {
    if (!canSubmit) return;
    setIsLoading(true);
    void onPressGenerate().finally(() => setIsLoading(false));
  };

  const handleClear = useCallback((): void => {
    setGeneration(prev => ({ ...prev, catalogProduct: undefined, image: undefined }));
    onClear();
  }, [onClear, setGeneration]);

  const handleTextChange = (text = ""): void => setGeneration(prev => ({ ...prev, prompt: text }));

  const imageProps = useMemo<ImageRowType | undefined>(() => {
    if (generation.catalogProduct) return { image: generation.catalogProduct.imageUrl, onClear: handleClear, name: generation.catalogProduct.name };
    if (generation.image) return { image: getMediaToBeUsedImageUrl(generation.image), onClear: handleClear };

    return undefined;
  }, [generation.catalogProduct, generation.image, handleClear]);

  const TextInputComponent = isAndroidApp ? TextInput : BottomSheetTextInput;

  return (
    <View>
      {imageProps && (
        <View style={styles.catalogProductContainer}>
          <Image source={imageProps.image} width={catalogProductImageSize} height={catalogProductImageSize} style={{ borderRadius: roundness }} />
          <Text variant="caption">{imageProps.name ?? t("studioMini.prompt.wizard.uploadedImage")}</Text>
          <IconButton style={styles.catalogProductButton} icon={XCircle} onPress={imageProps.onClear} />
        </View>
      )}
      {!is3dGeneration && (
        <TextInputComponent
          multiline
          numberOfLines={8}
          placeholder={t("studioMini.prompt.wizard.placeholder")}
          value={prompt}
          onChangeText={handleTextChange}
          onSubmitEditing={handleOnGenerate}
          blurOnSubmit
          required={!withImage}
          maxLength={1000}
          returnKeyType="send"
        />
      )}
      <Pressable
        onPress={handleOnGenerate}
        disabled={!canSubmit}
        style={({ pressed }) => [
          {
            backgroundColor: isDisabled ? colors.action.disabled : pressed ? colors.action.active : colors.onBackground,
          },
          styles.button,
          { borderRadius: roundness },
        ]}>
        {isLoading && <ActivityIndicator color={colors.onPrimary} size={18} />}
        <Text color="onPrimary" variant="buttonMedium">
          {t("studioMini.prompt.wizard.generate")}
        </Text>
        <View style={[styles.divider, { backgroundColor: colors.onPrimary }]} />
        <Text color="onPrimary" variant="buttonMedium">
          {tokensNeeded}
        </Text>
        <Coins weight="fill" color={colors.onPrimary} />
      </Pressable>
    </View>
  );
};
