/* eslint-disable complexity */
import React, { FC } from "react";

import { LinkSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Linking, View, ViewProps } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import InstagramIcon from "@app/assets/logos/instagram-logo.svg";
import TwitterIcon from "@app/assets/logos/twitter-logo.svg";
import userListAnimatedDark from "@app/assets/lotties/user-list-dark.json";
import userListAnimatedLight from "@app/assets/lotties/user-list-light.json";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useProfileNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";

import { ProfileAvatar } from "../profile-avatar/profile-avatar.component";

import { styles } from "./profile-header.style";

interface Props extends ViewProps {
  profile: Profile;
  isCurrentUser: boolean;
}

export const ProfileHeader: FC<Props> = ({ profile, isCurrentUser, style, ...viewProps }) => {
  const { t } = useTranslation();
  const navigation = useProfileNavigation();
  const { colors } = useTheme();

  const { updateState, state: userState } = useProfileContext();

  const isEmptyProfile =
    !profile.bio &&
    !profile.externalLink &&
    !profile.imageUrl &&
    !profile.pronouns.length &&
    !profile.twitterLink &&
    !profile.instagramLink &&
    !profile.occupation &&
    !profile.video;

  const handleOpenUrl = (url?: string): void => {
    if (!url) return;
    void Linking.openURL(url);
  };

  const handleEditProfile = (): void => navigation.navigate(Routes.editProfile);

  return (
    <View style={[styles.root, style]} {...viewProps}>
      <ListItem
        title={profile.displayName}
        description={profile.occupation}
        left={<ProfileAvatar imageUrl={profile.imageUrl} video={profile.video} name={profile.displayName} size={48} />}
        titleProps={{ variant: "h9", testID: "profile-displayName" }}
        descriptionProps={{ variant: "body2", color: "tertiary" }}
        right={
          isCurrentUser && (
            <Button mode={isEmptyProfile ? "contained" : "outlined"} onPress={handleEditProfile}>
              {isEmptyProfile ? t("profile.editProfile") : t("cta.edit")}
            </Button>
          )
        }
        contentContainerStyle={styles.nameOccImageContent}
      />

      {(!!profile.pronouns.length || profile.bio) && (
        <Text style={styles.bioPronouns}>
          {!!profile.pronouns.length && <Text color="tertiary">{`${profile.pronouns.join("/")} `}</Text>}
          <Text>{profile.bio}</Text>
        </Text>
      )}

      {(profile.externalLink || profile.twitterLink || profile.instagramLink) && (
        <View style={[styles.row, styles.centerLinks]}>
          {!!profile.externalLink && (
            <IconButton
              icon={({ color }) => <LinkSimple size={24} color={color} />}
              onPress={() => handleOpenUrl(profile.externalLink)}
              style={styles.linkButton}
            />
          )}
          {!!profile.twitterLink && (
            <IconButton
              icon={({ color }) => <TwitterIcon height={24} width={24} color={color} />}
              onPress={() => handleOpenUrl(profile.twitterLink)}
              style={styles.linkButton}
            />
          )}
          {!!profile.instagramLink && (
            <IconButton
              icon={() => <InstagramIcon height={24} width={24} />}
              onPress={() => handleOpenUrl(profile.instagramLink)}
              style={styles.linkButton}
            />
          )}
        </View>
      )}

      {isEmptyProfile && isCurrentUser && !userState?.[UserStateEnum.editProfileDismissed] && (
        <EmptyState
          animatedIcon={{ dark: userListAnimatedDark, light: userListAnimatedLight }}
          message={t("emptyState.profile.message")}
          cta={[{ label: t("emptyState.profile.cta"), onPress: handleEditProfile }]}
          onDismiss={() => updateState({ input: { [UserStateEnum.editProfileDismissed]: true } })}
          style={[styles.emptyState, { backgroundColor: colors.tertiaryContainer }]}
        />
      )}
    </View>
  );
};
