import React, { FC, useRef, useState } from "react";

import { Eye, EyeSlash } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Keyboard, TextInput as RNTextInput, View } from "react-native";
import { TextInput as RNPTextInput } from "react-native-paper";

import { BottomSheetTextInput } from "@app/components/common/bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { Button } from "@app/components/common/button/button.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";

import { styles } from "./choose-password.style";

interface Props {
  email?: string;
  password: string;
  setPassword: (value: string) => void;
  handleSubmit: () => void;
  loading: boolean;
  buttonLabel?: string;
  isNewAccount?: boolean;
  disableSubmit?: boolean;
  inBottomSheet?: boolean;
}

export const ChoosePassword: FC<Props> = ({
  email,
  password,
  setPassword,
  handleSubmit,
  loading,
  buttonLabel,
  isNewAccount = false,
  disableSubmit = false,
  inBottomSheet = false,
}) => {
  const { t } = useTranslation();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidePasswords, setHidePasswords] = useState(true);

  const confirmPasswordRef = useRef<RNTextInput>(null);

  const validPasswordRegexes = [/^.{8,}$/, /\d/, /[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]/];
  const isPasswordValid = validPasswordRegexes.every(regex => regex.test(password));
  const doPasswordsMatch = password === confirmPassword;
  const disableSubmitEditing = disableSubmit || !isPasswordValid || !doPasswordsMatch;

  const PasswordVisibilityIcon = hidePasswords ? Eye : EyeSlash;
  const TextInputComponent = inBottomSheet ? BottomSheetTextInput : TextInput;

  const wrappedHandleSubmit = (): void => {
    if (disableSubmitEditing) return;

    Keyboard.dismiss();
    handleSubmit();
  };

  return (
    <View style={styles.formView}>
      <View style={styles.inputView}>
        {email && <TextInput label={t("login.email.emailAddress")} value={email} disabled autoComplete="email" />}
        <TextInputComponent
          label={isNewAccount ? t("login.email.password") : t("login.email.newPassword")}
          helperText={t("login.email.passwordRules")}
          errorText={t("login.email.passwordRules")}
          value={password}
          onChangeText={setPassword}
          error={!!password && !isPasswordValid}
          secureTextEntry={hidePasswords}
          right={<RNPTextInput.Icon icon={props => <PasswordVisibilityIcon {...props} />} onPress={() => setHidePasswords(!hidePasswords)} />}
          selectTextOnFocus
          returnKeyType="next"
          onSubmitEditing={() => confirmPasswordRef.current?.focus()}
          autoComplete="new-password"
        />
        <TextInputComponent
          ref={confirmPasswordRef}
          label={isNewAccount ? t("login.email.confirmPassword") : t("login.email.confirmNewPassword")}
          errorText={t("login.email.mustMatch")}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
          error={isPasswordValid && !!confirmPassword && !doPasswordsMatch}
          secureTextEntry={hidePasswords}
          right={<RNPTextInput.Icon icon={props => <PasswordVisibilityIcon {...props} />} onPress={() => setHidePasswords(!hidePasswords)} />}
          selectTextOnFocus
          returnKeyType="next"
          onSubmitEditing={wrappedHandleSubmit}
          autoComplete="password"
        />
      </View>
      <Button mode="contained" size="large" loading={loading} disabled={disableSubmitEditing} onPress={wrappedHandleSubmit}>
        {buttonLabel ?? t("cta.next")}
      </Button>
    </View>
  );
};
