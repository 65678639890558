import React, { FC, useCallback, useMemo, useState } from "react";

import * as Clipboard from "expo-clipboard";
import { t } from "i18next";
import { Copy, DotsThree, Eye, Pencil, Trash } from "phosphor-react-native";
import { Dialog, IconButton, Menu, Portal, useTheme } from "react-native-paper";

import { AnyPromo, PromoType } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteGenericPromo } from "@app/hooks/api/promos/use-delete-generic-promo.hook";
import { isStandardPromo } from "@app/utils/feed.util";

import { PromoCard } from "../../products/promo-card/promo-card.component";

import { styles } from "./promo-list-item.style";

interface Props {
  promo: AnyPromo;
}

export const PromoListItem: FC<Props> = ({ promo }) => {
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  const { deleteGenericPromo } = useDeleteGenericPromo();

  const [menuVisible, setMenuVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);

  const isLive = useMemo(() => {
    const now = new Date();

    const afterStart = !("startAt" in promo) || !promo.startAt || new Date(promo.startAt) <= now;
    const beforeEnd = !("endAt" in promo) || !promo.endAt || new Date(promo.endAt) >= now;

    return afterStart && beforeEnd;
  }, [promo]);

  const subtitle = useMemo(() => {
    let promoSubtitle: string | undefined;

    if (isStandardPromo(promo)) {
      promoSubtitle = promo.collab?.name ?? promo.contest?.name;
    }

    promoSubtitle ??= promo.title ?? promo.promoId;

    return promoSubtitle;
  }, [promo]);

  const promoItemMenu = useCallback(
    (item: AnyPromo) => (
      <Menu
        style={styles.menu}
        contentStyle={{ backgroundColor: colors.background }}
        visible={menuVisible}
        anchor={<IconButton icon={props => <DotsThree {...props} />} onPress={() => setMenuVisible(true)} />}
        onDismiss={() => setMenuVisible(false)}>
        <Menu.Item
          leadingIcon={props => <Copy {...props} />}
          title={t("godMode.managePromos.copyPromoId")}
          onPress={() => {
            Clipboard.setStringAsync(item.promoId).catch(() => undefined);
            setMenuVisible(false);
          }}
        />
        <Menu.Item
          leadingIcon={props => <Eye {...props} />}
          title={t("godMode.managePromos.previewPromoCard")}
          onPress={() => {
            setPreviewVisible(true);
            setMenuVisible(false);
          }}
        />
        {item.promoType === PromoType.generic && (
          <>
            <Menu.Item
              leadingIcon={props => <Pencil {...props} />}
              title="Edit promo"
              onPress={() => {
                navigation.navigate(Routes.createPromo, { promoId: item.promoId });
                setMenuVisible(false);
              }}
            />
            <Menu.Item
              leadingIcon={props => <Trash {...props} />}
              title={t("godMode.managePromos.delete")}
              onPress={() => {
                Alert.alert(t("godMode.managePromos.areYouSure"), t("godMode.managePromos.cannotBeUndone"), [
                  { text: t("cta.cancel"), style: "cancel" },
                  {
                    text: t("cta.confirm"),
                    style: "destructive",
                    onPress: () =>
                      void deleteGenericPromo({
                        variables: { promoId: item.promoId },
                        onCompleted: () => showSuccessSnackbar({ message: t("godMode.managePromos.deleteSuccess") }),
                        onError: e => showErrorSnackbar({ message: e.message, error: e }),
                      }),
                  },
                ]);
                setMenuVisible(false);
              }}
            />
          </>
        )}
      </Menu>
    ),
    [colors.background, deleteGenericPromo, menuVisible, navigation, showErrorSnackbar, showSuccessSnackbar],
  );

  return (
    <>
      <ListItem
        title={promo.promoType}
        subtitle={subtitle}
        description={promo.promoType === PromoType.generic ? t(isLive ? "godMode.managePromos.active" : "godMode.managePromos.inactive") : undefined}
        imageProps={{ image: promo.imageUrl, size: 64, style: { borderRadius: roundness } }}
        titleProps={{ variant: "subtitle2" }}
        subtitleProps={{ variant: "h8" }}
        descriptionProps={{ color: isLive ? "success" : "common.gray" }}
        right={promoItemMenu(promo)}
        style={styles.listItem}
      />

      <Portal>
        <Dialog visible={previewVisible} onDismiss={() => setPreviewVisible(false)} style={styles.dialog}>
          <PromoCard promo={promo} feedIndex={0} preview onClose={() => setPreviewVisible(false)} onBack={() => undefined} />
        </Dialog>
      </Portal>
    </>
  );
};
