import { StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";

export const styles = StyleSheet.create({
  characterCount: {
    alignSelf: "flex-end",
  },
  fullWidth: {
    width: "100%",
  },
  noTextInputUnderline: {
    backgroundColor: transparent,
  },
  textInput: {
    backgroundColor: transparent,
  },
  textInputUnderline: {
    height: 0.5,
    marginHorizontal: 12,
  },
  textInputWrapper: {
    alignItems: "stretch",
    columnGap: 8,
    flexDirection: "row",
  },
  touchableWrapper: {
    flex: 1,
  },
});
