import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationArchiveCollabArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  archiveCollab: Collab;
}

interface ArchiveCollabResult extends MutationResult<Response, "archiveCollab"> {
  archiveCollab: (options?: MutationFunctionOptions<Response, MutationArchiveCollabArgs>) => Promise<FetchResult>;
}

const ArchiveCollabMutation = gql`
  mutation ArchiveCollab($collabId: CollabId!) {
    archiveCollab(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useArchiveCollab(): ArchiveCollabResult {
  const [archiveCollab, { loading, error, data }] = useMutation<Response, MutationArchiveCollabArgs>(ArchiveCollabMutation, {
    update: (cache, _, options) => {
      cache.evict({ id: options.variables?.collabId });
    },
  });

  return { archiveCollab, loading, error, data: data?.archiveCollab };
}
