import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { Pencil, TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem } from "react-native";
import { IconButton, IconButtonProps, useTheme } from "react-native-paper";

import DotsThreeVertical from "@app/assets/icons/dots-three-outline-vertical-duotone.svg";
import { CollabUpdateImage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProductUpdateContext } from "@app/context/product-update/product-update.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { DeleteUpdateConfirmationDialog } from "../delete-update-confirmation-dialog/delete-update-confirmation-dialog.component";

import { styles } from "./update-more-button.style";

interface Props extends Pick<IconButtonProps, "style" | "iconColor"> {
  updateId: string;
  title: string;
  content: string;
  images: CollabUpdateImage[];
  creatorId: string;
}

export const UpdateMoreButton: FC<Props> = ({ updateId, title, content, images, creatorId, iconColor, style }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation();

  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { profile } = useProfileContext();
  const {
    state: { isAdmin },
  } = useAuthContext();
  const { setInputs, setImages } = useProductUpdateContext();

  const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = useState(false);

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const handleDelete = useCallback((): void => {
    sheetRef?.current?.close();
    setDeleteConfirmationDialogVisible(true);
  }, []);

  const handleEdit = useCallback(() => {
    setInputs(prev => ({ ...prev, title, content }));
    setImages(images.map(i => ({ imageUrl: i.imageUrl, imageId: i.collabUpdateImageId })));

    navigation.navigate(Routes.productUpdateStack, { screen: Routes.createProductUpdate, params: { updateId } });
    sheetRef?.current?.close();
  }, [content, images, navigation, setImages, setInputs, title, updateId]);

  const options = useMemo<ListItemProps[]>(
    () => [
      ...conditionalItem<ListItemProps>(profile?.userId === creatorId || isAdmin, {
        title: t("cta.edit"),
        left: <Pencil color={colors.primary} style={styles.itemIcon} />,
        titleProps: { variant: "subtitle1" },
        onPress: handleEdit,
      }),
      ...conditionalItem<ListItemProps>(profile?.userId === creatorId || isAdmin, {
        title: t("cta.delete"),
        left: <TrashSimple color={colors.error} style={styles.itemIcon} />,
        titleProps: { color: "error", variant: "subtitle1" },
        onPress: handleDelete,
      }),
    ],
    [profile?.userId, creatorId, isAdmin, t, colors.primary, colors.error, handleEdit, handleDelete],
  );

  const renderOption = useCallback<ListRenderItem<ListItemProps>>(({ item }) => <ListItem {...item} />, []);

  const shouldShowButton = !!options.length;

  return (
    shouldShowButton && (
      <>
        <IconButton
          icon={({ color, size }) => <DotsThreeVertical color={color} width={size} height={size} />}
          style={style}
          onPress={handleOpenBottomSheet}
          iconColor={iconColor ?? colors.common.white}
        />

        <BottomSheet ref={sheetRef}>
          <FlatList
            data={options}
            renderItem={renderOption}
            keyExtractor={item => item.title as string}
            showsVerticalScrollIndicator={false}
            style={styles.listContainer}
          />
        </BottomSheet>

        <DeleteUpdateConfirmationDialog
          updateId={updateId}
          visible={deleteConfirmationDialogVisible}
          onDismiss={() => setDeleteConfirmationDialogVisible(false)}
        />
      </>
    )
  );
};
