import React, { FC, useCallback, useMemo } from "react";

import { NetworkStatus } from "@apollo/client";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { LinearGradient } from "expo-linear-gradient";
import { BookmarkSimple, Play } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { StudioMedia } from "@app/common/graphql/generated/schema.graphql";
import { getMediaToBeUsedId, MediaToBeUsed } from "@app/common/types/image.type";
import { Routes, useStudioNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Image } from "@app/components/common/image/image.component";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { useComponentSize } from "@app/hooks/utils/use-component-size.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isWeb } from "@app/utils/device.util";
import { getStudioMediaId, getStudioMediaUrl, isStudioImage, isStudioVideo } from "@app/utils/studio-media.util";

import { styles } from "./studio-image-list.style";

interface Props {
  images?: StudioMedia[];
  selectedImages?: MediaToBeUsed[];
  loading: boolean;
  networkStatus?: NetworkStatus;
  displayPrompt?: boolean;
  areVideosSelectable?: boolean;
  onClickImage: (image: StudioMedia) => void;
  onEndReached: () => void;
  refetch?: () => void;
  scrollEnabled?: boolean;
}

export const StudioImageList: FC<Props> = ({
  images,
  selectedImages,
  loading,
  networkStatus,
  displayPrompt,
  areVideosSelectable = true,
  onClickImage,
  onEndReached,
  refetch,
  scrollEnabled,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { navigate } = useStudioNavigation();

  const { itemWidth, itemGap, listPadding, nbColumns } = useResponsiveWidthListItem();
  const [listSize, handleListLayout] = useComponentSize({});

  const renderItem = useCallback<ListRenderItem<StudioMedia>>(
    ({ item }) => {
      const imageIndex = selectedImages?.findIndex(img => getMediaToBeUsedId(img) === getStudioMediaId(item));
      const isImageSelected = imageIndex != null && imageIndex > -1;
      const isVideoDisabled = isStudioVideo(item) && !areVideosSelectable;

      return (
        <TouchableOpacity onPress={() => onClickImage(item)} disabled={isVideoDisabled} style={[styles.imageWrapper, { width: itemWidth }]}>
          <Image
            key={getStudioMediaId(item)}
            source={getStudioMediaUrl(item)}
            width={itemWidth}
            style={styles.image}
            imageStyle={[...conditionalItem(isImageSelected, { borderColor: colors.secondary, borderWidth: 4 })]}
          />
          {isVideoDisabled && <View style={[{ backgroundColor: colors.backdrop }, styles.imageOverlay]} />}
          {isStudioVideo(item) && (
            <View style={styles.videoIndicator}>
              <Play size={32} color={isVideoDisabled ? colors.action.disabled : colors.common.white} weight="fill" />
            </View>
          )}
          {isImageSelected && (
            <View style={[{ backgroundColor: colors.secondary }, styles.selectedContainer]}>
              <Text color="onSecondary" textAlign="center" variant="subtitle1">
                {imageIndex + 1}
              </Text>
            </View>
          )}
          {displayPrompt && isStudioImage(item) && (
            <View style={styles.imageContentWrapper}>
              <View>
                <LinearGradient locations={[0, 1]} colors={["rgba(0,0,0,0)", "rgba(0,0,0,0.75)"]} style={styles.gradient} />
                <Text variant="caption" color="common.white" numberOfLines={2} style={styles.promptText}>
                  {item.prompt}
                </Text>
              </View>
            </View>
          )}
        </TouchableOpacity>
      );
    },
    [
      selectedImages,
      areVideosSelectable,
      itemWidth,
      colors.secondary,
      colors.backdrop,
      colors.action.disabled,
      colors.common.white,
      displayPrompt,
      onClickImage,
    ],
  );

  const loadingState = useMemo(
    () => (
      <View style={[styles.root, styles.skeletonsContainer, { gap: itemGap }]}>
        {Array.from(Array(10).keys()).map(key => (
          <Skeleton key={key} width={itemWidth} radius="square" height={itemWidth * imageRatio} />
        ))}
      </View>
    ),
    [itemGap, itemWidth],
  );

  const listEmpty = useMemo(
    () =>
      loading ? (
        loadingState
      ) : (
        <EmptyState
          icon={BookmarkSimple}
          message={t("emptyState.savedImages.message")}
          cta={[{ label: t("emptyState.savedImages.cta"), onPress: () => navigate(Routes.studioMiniGeneration) }]}
          style={{ height: listSize.height }}
        />
      ),
    [listSize.height, loading, loadingState, navigate, t],
  );

  if (!images?.length && isWeb) return listEmpty;

  return (
    <View style={styles.root} onLayout={handleListLayout}>
      <FlashList
        data={images}
        renderItem={renderItem}
        keyExtractor={item => getStudioMediaId(item)}
        extraData={selectedImages}
        estimatedItemSize={itemWidth}
        ListEmptyComponent={listEmpty}
        contentContainerStyle={{ padding: listPadding }}
        ItemSeparatorComponent={() => <View style={{ height: itemGap }} />}
        scrollEnabled={scrollEnabled ?? true}
        numColumns={nbColumns}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
        showsVerticalScrollIndicator={false}
        onRefresh={refetch}
        refreshing={networkStatus === NetworkStatus.refetch}
      />
    </View>
  );
};
