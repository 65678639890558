import React, { FC, Fragment, useState } from "react";

import { useTranslation } from "react-i18next";
import { Dialog, DialogProps, Portal, useTheme } from "react-native-paper";

import { CustomVariants } from "@app/common/style/fonts";
import { Text } from "@app/components/common/text/text.component";
import { useLoginContext } from "@app/context/auth/login/login.context";

import { Button } from "../button/button.component";

import { styles } from "./login-prompt.style";

interface TextProps {
  text: string;
  variant?: CustomVariants;
}

interface Props extends Omit<DialogProps, "visible" | "children"> {
  title?: TextProps;
  message: TextProps;
  visible?: boolean;
  onTopOfEverything?: boolean;
}

export const LoginPrompt: FC<Props> = ({
  title,
  message,
  visible: overrideVisible = false,
  dismissable = false,
  onTopOfEverything = true,
  onDismiss,
  style,
  ...props
}) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { openLogin } = useLoginContext();

  const defaultTitle: TextProps = { text: t("loginPrompt.defaultTitle"), variant: "h8" };
  const titleSelected = title ?? defaultTitle;

  const [visible, setVisible] = useState(true);

  const handleDismiss = (): void => {
    setVisible(false);
    if (onDismiss) onDismiss();
  };

  const Wrapper = onTopOfEverything ? Portal : Fragment;

  return (
    <Wrapper>
      <Dialog
        visible={visible || overrideVisible}
        dismissable={dismissable}
        onDismiss={handleDismiss}
        style={[styles.root, { backgroundColor: colors.tertiaryContainer, borderColor: colors.outline, borderRadius: roundness }, style]}
        {...props}>
        <Dialog.Content style={styles.content}>
          <Text variant={titleSelected.variant ?? "h8"} textAlign="center" color="onTertiaryContainer">
            {titleSelected.text}
          </Text>
          <Text variant={message.variant ?? "h6"} textAlign="center" color="onTertiaryContainer">
            {message.text}
          </Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button fullWidth mode="contained" size="large" onPress={() => openLogin()}>
            {t("cta.login")}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Wrapper>
  );
};
