import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    rowGap: 24,
  },
  root: {
    marginHorizontal: 20,
    marginVertical: 24,
  },
});
