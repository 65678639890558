import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { coreStudioAnimationFields } from "@app/common/graphql/fragments.graphql";
import { StudioAnimation, TemporaryStudioAnimationFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  temporaryStudioAnimation: StudioAnimation;
}

interface Variables {
  userId: string;
  where: TemporaryStudioAnimationFilteringArgs;
}

const temporaryStudioAnimationQuery = gql`
  query TemporaryStudioAnimation($userId: UserId!, $where: TemporaryStudioAnimationFilteringArgs!) {
    temporaryStudioAnimation(userId: $userId, where: $where) {
      ...CoreStudioAnimationFields
    }
  }
  ${coreStudioAnimationFields}
`;

export function useTemporaryStudioAnimation(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "temporaryStudioAnimation"> {
  const { loading, error, data, startPolling, stopPolling } = useQuery<Response, Variables>(temporaryStudioAnimationQuery, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  return { loading, error, data: data?.temporaryStudioAnimation, startPolling, stopPolling };
}
