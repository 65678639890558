import React, { FC, useState } from "react";

import { Plus, Minus } from "phosphor-react-native";
import { LayoutChangeEvent, View } from "react-native";
import { IconButton } from "react-native-paper";

import { CustomVariants, customFonts } from "@app/common/style/fonts";
import { Text } from "@app/components/common/text/text.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./expandable-text.style";

interface Props {
  title: string;
  text: string;
}

export const ExpandableText: FC<Props> = ({ title, text }) => {
  const { fontScale } = useWindowDimensions();

  const nbLinesPreview = 3;
  const titleVariant: CustomVariants = "body1";
  const textVariant: CustomVariants = "body2";

  const [expanded, setExpanded] = useState(true);
  const [expandable, setExpandable] = useState(false);

  const handleExpand = (): void => setExpanded(isExpanded => !isExpanded);

  const onLayout = (event: LayoutChangeEvent): void => {
    const { height } = event.nativeEvent.layout;
    const numberOfLines = Math.ceil(height / (customFonts[textVariant].lineHeight * fontScale));
    setExpandable(numberOfLines > nbLinesPreview);
  };

  return (
    <View style={styles.section}>
      <View style={styles.rowSpaceBetween}>
        <Text variant={titleVariant} textTransform="uppercase">
          {title}
        </Text>
        {expandable && (
          <IconButton
            icon={({ color }) => (expanded ? <Minus color={color} weight="thin" size={24} /> : <Plus color={color} weight="thin" size={24} />)}
            style={[
              styles.expandButton,
              { height: customFonts[titleVariant].lineHeight * fontScale, width: customFonts[titleVariant].lineHeight * fontScale },
            ]}
            onPress={handleExpand}
          />
        )}
      </View>

      {expandable ? (
        <Text variant={textVariant} color="tertiary" numberOfLines={expanded ? 0 : nbLinesPreview}>
          {text}
        </Text>
      ) : (
        <Text variant={textVariant} color="tertiary" onLayout={onLayout}>
          {text}
        </Text>
      )}
    </View>
  );
};
