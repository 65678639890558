import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  closeIcon: {
    margin: 0,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  textContainer: {
    paddingVertical: 8,
  },
});
