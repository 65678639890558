import { APP_ENV } from "@env";
import { BranchSubscriptionEvent } from "react-native-branch";

import { log } from "@app/utils/logger/logger.util";

export type BranchSubscribeCallback = (event: BranchSubscriptionEvent) => void;

export enum BranchCampaign {
  "sharing" = "sharing",
}

export const branchDeepViewName = "os_custom_deepview";

export interface GeneratedLinkData {
  title?: string;
  description?: string;
  imageUrls?: string[];
  twitterLink?: string;
  video?: string;
}

export enum BranchLinkPath {
  auth = "/auth",
  referral = "/referral",
}

export enum Channel {
  shopify = "shopify",
  unknown = "unknown",
}

export function getExistingBranchLink(path: BranchLinkPath, queryString?: string): string {
  return `https://offscriptmtl.${APP_ENV !== "production" ? "test-" : ""}app.link${path}${queryString ?? ""}`;
}

export const logBranch = log.extend("BRANCH");
