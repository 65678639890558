import { ApolloCache } from "@apollo/client";

import { PaginatedCollabs } from "@app/common/graphql/generated/schema.graphql";

interface Props {
  cache: ApolloCache<unknown>;
  collabId?: string;
  numberOfLikes?: number;
}

export function updateCacheAfterDislikeCollab({ cache, collabId, numberOfLikes }: Props): void {
  cache.modify({
    id: collabId,
    fields: {
      liked() {
        return false;
      },
      numberOfLikes() {
        return numberOfLikes ?? null;
      },
    },
  });
  cache.modify({
    fields: {
      likes(existingLikes: PaginatedCollabs, { readField }) {
        return {
          ...existingLikes,
          nodes: existingLikes.nodes?.filter(collab => readField("collabId", collab) !== collabId),
          totalCount: existingLikes.totalCount - 1,
        };
      },
    },
  });
}
