import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreStudioAnimationFields } from "@app/common/graphql/fragments.graphql";
import { StudioAnimation } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface StudioAnimationResponse {
  studioAnimation: StudioAnimation;
}

interface Variables {
  studioAnimationId: string;
}

const studioAnimationQuery = gql`
  query StudioAnimation($studioAnimationId: StudioAnimationId!) {
    studioAnimation(studioAnimationId: $studioAnimationId) {
      ...CoreStudioAnimationFields
    }
  }
  ${coreStudioAnimationFields}
`;

export function useStudioAnimation(
  options: QueryHookOptions<StudioAnimationResponse, Variables>,
): QueryResult<StudioAnimationResponse, "studioAnimation"> {
  const { data, loading, error } = useQuery<StudioAnimationResponse, Variables>(studioAnimationQuery, options);

  return { data: data?.studioAnimation, loading, error };
}
