import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  editBottomSheetContentContainer: {
    paddingTop: isWeb ? 16 : 0,
  },
  editIcon: {
    margin: 8,
  },
  listItemCommon: {
    borderWidth: 1,
    paddingVertical: 12,
  },
  listItemNonSelectable: {
    paddingLeft: 16,
  },
  listItemSelectable: {
    paddingLeft: 4,
    paddingRight: 16,
  },
  listItemUniqueSelected: {
    paddingHorizontal: 16,
  },
});
