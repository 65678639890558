import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";

import { ShareLink, ShareLinkInput } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";

export interface ShareLinkResponse {
  link: ShareLink;
}

interface Variables {
  input: ShareLinkInput;
}

export interface ShareLinkResult<TVariables extends OperationVariables> extends BaseQueryResult<ShareLinkResponse, "link"> {
  getLink: LazyQueryExecFunction<ShareLinkResponse, TVariables>;
}

const shareLinkQuery = gql`
  query ShareLink($input: ShareLinkInput!) {
    link: shareLink(input: $input) {
      url
    }
  }
`;

export function useShareLink(): ShareLinkResult<Variables> {
  const [getLink, { loading, error, data }] = useLazyQuery<ShareLinkResponse, Variables>(shareLinkQuery);

  return { getLink, loading, error, data: data?.link };
}
