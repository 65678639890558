import { GrantedRole } from "@app/common/graphql/generated/schema.graphql";
import { getRolesFromToken } from "@app/utils/token.util";

import { initialAuthContext } from "./auth.context";
import { AuthContextAction, AuthContextStateInterface, ReducerActionInterface } from "./auth.types";

export const reducer = (state: AuthContextStateInterface, action: ReducerActionInterface): AuthContextStateInterface => {
  switch (action.action) {
    case AuthContextAction.login: {
      const roles = getRolesFromToken(action.payload.token);

      return {
        ...state,
        connected: true,
        loading: false,
        id: action.payload.id,
        verified: action.payload.verified,
        email: action.payload.email,
        token: action.payload.token,
        providers: action.payload.providerData.map(data => data.providerId),
        roles,
        restored: state.loading,
        isAdmin: roles.includes(GrantedRole.admin),
      };
    }
    case AuthContextAction.logout:
      return { ...initialAuthContext, loading: false };
    default:
      return state;
  }
};
