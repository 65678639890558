import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Linking from "expo-linking";
import {
  getExpoPushTokenAsync,
  getPermissionsAsync,
  NotificationPermissionsStatus,
  PermissionStatus,
  requestPermissionsAsync,
  AndroidImportance,
  setNotificationChannelAsync,
} from "expo-notifications";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useRevokePushNotificationsToken } from "@app/hooks/api/use-revoke-push-notifications-token.hook";
import { useSetPushNotificationsToken } from "@app/hooks/api/use-set-push-notifications-token.hook";
import { isAndroidApp, isIosApp, isWeb } from "@app/utils/device.util";

import { useAuthContext } from "../../auth/auth.context";
import { useMixpanelContext } from "../../mixpanel/mixpanel.context";
import { useProfileContext } from "../../profile/profile.context";
import { useSnackbarContext } from "../../snackbar/snackbar.context";

import { PushNotificationPermissionContext, PushNotificationPermissionContextInterface } from "./push-notifications-permission.context";

export const PushNotificationPermissionContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { state } = useAuthContext();
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { trackInMixpanel } = useMixpanelContext();
  const { pushNotifsPermissionSeenOnce, setLocalStorage } = useLocalStorageContext();

  const { setPushNotificationsToken } = useSetPushNotificationsToken();
  const { revokePushNotificationsToken } = useRevokePushNotificationsToken();

  const [userPermissionStatus, setUserPermissionStatus] = useState<PermissionStatus | undefined>(undefined);
  const [canAskForPermission, setCanAskForPermission] = useState<boolean | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [currentUserId, setCurrentUserId] = useState<string | undefined>(undefined);
  const [currentAuthToken, setCurrentAuthToken] = useState<string | undefined>(undefined);

  const pushNotificationsAvailable = Device.isDevice && !isWeb;
  const canSendNotification = userPermissionStatus === "granted";

  const setPermissions = (permissions: NotificationPermissionsStatus): void => {
    setUserPermissionStatus(permissions.status);
    setCanAskForPermission(permissions.canAskAgain);
  };

  const setPermissionsScreenSeenOnce = useCallback(() => {
    if (!pushNotifsPermissionSeenOnce) {
      setLocalStorage(LocalStorageKeys.pushNotifsPermissionSeenOnce, true);
    }
  }, [pushNotifsPermissionSeenOnce, setLocalStorage]);

  const requestPermission = useCallback(async () => {
    if (canAskForPermission && !canSendNotification) {
      if (isAndroidApp) {
        await setNotificationChannelAsync("default", { name: "default", importance: AndroidImportance.DEFAULT });
      }

      requestPermissionsAsync()
        .then(permissions => {
          setPermissions(permissions);
          trackInMixpanel(TrackEvent.pushNotifPermissionStatusChanged, { permissionStatus: permissions.status });
        })
        .catch((error: Error) => showErrorSnackbar({ error }))
        .finally(setPermissionsScreenSeenOnce);
    } else {
      await Linking.openSettings();
    }
  }, [canAskForPermission, canSendNotification, setPermissionsScreenSeenOnce, showErrorSnackbar, trackInMixpanel]);

  const clearData = useCallback(() => {
    setCurrentUserId(undefined);
    setToken(undefined);
    setCurrentAuthToken(undefined);
  }, []);

  useEffect(() => {
    if (pushNotificationsAvailable) {
      getPermissionsAsync()
        .then(permissions => setPermissions(permissions))
        .catch(() => undefined);
    }
  }, [pushNotificationsAvailable]);

  useEffect(() => {
    setCurrentAuthToken(state.token ?? undefined);
  }, [state.token]);

  useEffect(() => {
    if (!token && userPermissionStatus === "granted" && profile?.userId) {
      const easConfig = Constants.expoConfig?.extra?.eas as { projectId: string } | undefined;
      getExpoPushTokenAsync({ projectId: easConfig?.projectId })
        .then(expoPushToken => {
          void setPushNotificationsToken({
            variables: { userId: profile.userId, input: { token: expoPushToken.data } },
            onCompleted: () => {
              setCurrentUserId(profile.userId);
              setToken(expoPushToken.data);
            },
          });
        })
        .catch(() => undefined);
    }
  }, [profile?.userId, setPushNotificationsToken, token, userPermissionStatus]);

  useEffect(() => {
    if (!profile?.userId && currentUserId && token && currentAuthToken && state.verified) {
      void revokePushNotificationsToken({
        variables: { userId: currentUserId, input: { token } },
        context: { headers: { authorization: `Bearer ${currentAuthToken}` } },
        onCompleted: clearData,
        onError: clearData,
      });
    }
  }, [clearData, currentAuthToken, currentUserId, profile?.userId, revokePushNotificationsToken, state.verified, token]);

  const shouldPromptForPermission = useMemo(() => {
    if (isIosApp) {
      return pushNotificationsAvailable && !pushNotifsPermissionSeenOnce && userPermissionStatus === "undetermined";
    }

    return pushNotificationsAvailable && !pushNotifsPermissionSeenOnce && userPermissionStatus === "denied";
  }, [pushNotificationsAvailable, pushNotifsPermissionSeenOnce, userPermissionStatus]);

  const pushNotificationsContextValue = useMemo<PushNotificationPermissionContextInterface>(
    () => ({
      shouldPromptForPermission,
      canRequestPermission: pushNotificationsAvailable,
      skipPrompt: setPermissionsScreenSeenOnce,
      requestPermission: () => void requestPermission(),
      canSendNotification,
    }),
    [shouldPromptForPermission, pushNotificationsAvailable, setPermissionsScreenSeenOnce, canSendNotification, requestPermission],
  );

  return <PushNotificationPermissionContext.Provider value={pushNotificationsContextValue}>{children}</PushNotificationPermissionContext.Provider>;
};
