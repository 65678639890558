import React, { Dispatch, FC, SetStateAction, JSX } from "react";

import { MinusCircle, PlusCircle } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { Chip } from "@app/components/common/chip/chip.component";
import { Text } from "@app/components/common/text/text.component";

import { DeleteCartItemButton } from "../../../cart-orders/cart-list/delete-cart-item-button/delete-cart-item-button.component";
import { AddToCartButton } from "../../add-to-cart-button/add-to-cart-button.component";

import { styles } from "./quantity-selector.style";

interface CommonProps {
  quantity: number;
  maxQuantity?: number;
  updateOnClick?: boolean;
  variantId?: string;
  collabId?: string;
  setQuantity: Dispatch<SetStateAction<number>>;
}

interface UpdateOnClickProps extends CommonProps {
  updateOnClick: true;
  cartItemId: string;
}

interface DontUpdateOnClickProps extends CommonProps {
  updateOnClick?: never;
  cartItemId?: never;
}

type Props = UpdateOnClickProps | DontUpdateOnClickProps;

export const QuantitySelector: FC<Props> = ({ cartItemId, quantity, maxQuantity, updateOnClick = false, variantId, collabId, setQuantity }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const handlePlusPress = (): void => setQuantity(prevQty => prevQty + 1);

  const handleMinusPress = (): void => setQuantity(prevQty => prevQty - 1);

  const updateCartDirectly = updateOnClick && !!collabId && !!variantId && !!cartItemId;

  const addToCartButton = (icon: "minus" | "plus"): JSX.Element => (
    <AddToCartButton
      type="icon"
      icon={(props => (icon === "minus" ? <MinusCircle weight="fill" {...props} /> : <PlusCircle weight="fill" {...props} />)) as IconSource}
      collabId={collabId as string}
      variantId={variantId as string}
      quantity={icon === "minus" ? -1 : 1}
      inventoryQuantity={maxQuantity}
      onPressStart={icon === "minus" ? handleMinusPress : handlePlusPress}
    />
  );

  return (
    <View style={[styles.row, styles.spaceBetween]}>
      <View style={styles.row}>
        <Text variant="h7">{t("productDetails.quantity")}</Text>
        {maxQuantity !== undefined && maxQuantity <= 5 && <Chip label={t("productDetails.itemsLeft", { count: maxQuantity })} color="error" />}
      </View>
      <View style={styles.row}>
        {updateCartDirectly && quantity <= 1 ? (
          <DeleteCartItemButton type="icon" cartItemId={cartItemId} onPressStart={handleMinusPress} />
        ) : updateCartDirectly && quantity > 1 ? (
          addToCartButton("minus")
        ) : (
          <IconButton
            icon={props => <MinusCircle weight="fill" {...props} />}
            iconColor={colors.action.active}
            size={24}
            disabled={quantity <= 1 && !updateOnClick}
            onPress={handleMinusPress}
          />
        )}
        <Text variant="buttonMedium">{quantity}</Text>
        {updateCartDirectly ? (
          addToCartButton("plus")
        ) : (
          <IconButton
            icon={props => <PlusCircle weight="fill" {...props} />}
            iconColor={colors.action.active}
            size={24}
            disabled={maxQuantity !== undefined && quantity >= maxQuantity}
            onPress={handlePlusPress}
          />
        )}
      </View>
    </View>
  );
};
