import { formatDistanceToNowStrict } from "date-fns";
import { TFunction } from "i18next";

export function formattedNumber(number: number): string {
  if (number < 1000) {
    return `${number}`;
  }

  const rounded = (number / 1000).toFixed(1);
  return `${rounded}k`;
}

export function formattedDistanceToNow(date: string, t: TFunction<"common", undefined>): string {
  const distance = formatDistanceToNowStrict(new Date(date));
  const distanceArray = distance.split(" ");
  const number = distanceArray[0];
  const unit = distanceArray[1].charAt(0);
  const shortDistance = `${number}${unit}`;

  return unit === "s" && parseInt(number) <= 10 ? t("comments.now") : shortDistance;
}
