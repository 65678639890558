import React, { FC, useCallback, useEffect, useRef } from "react";

import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Keyboard } from "react-native";
import { useTheme } from "react-native-paper";

import { StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { isImageToBeUsed } from "@app/common/types/image.type";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ScreenWrapper, ScreenWrapperProps } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { BottomSheetStudioGeneration } from "@app/components/studio-mini/bottom-sheet-studio-generation/bottom-sheet-studio-generation.component";
import { StudioGeneratedImages } from "@app/components/studio-mini/studio-generated-images/studio-generated-images.component";
import { StudioTokenButton, StudioTokenButtonRefProps } from "@app/components/studio-mini/studio-token-button/studio-token-button.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { GenerationTaskType, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useStartGeneratingAnimationFromImage } from "@app/hooks/api/studio/use-start-generating-animation-from-image.hook";
import { useStartGeneratingImagesFromImage } from "@app/hooks/api/studio/use-start-generating-images-from-image.hook";
import { useStartGeneratingImages } from "@app/hooks/api/studio/use-start-generating-images.hook";
import { useImageGenerationCredit } from "@app/hooks/api/use-image-generation-credits.hook";
import { isAndroidApp } from "@app/utils/device.util";

import { styles } from "./studio-generation.style";

export const StudioGenerationScreen: FC<NavigationProps<Routes.studioMiniGeneration>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { showErrorSnackbar } = useSnackbarContext();
  const { inputs, setGeneration, generation, setIsCurrentlyGenerating } = useStudioMiniContext();
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const studioTokenBottomSheetRef = useRef<StudioTokenButtonRefProps>(null);
  const studioBottomSheetRef = useRef<BottomSheetRefProps>(null);

  const userId = profile?.userId;

  const { startGeneratingImages, reset: resetGeneratingImages } = useStartGeneratingImages();
  const { startGeneratingImagesFromImage, reset: resetGeneratingImagesFromImage } = useStartGeneratingImagesFromImage();
  const { startGeneratingAnimationFromImage, reset: resetGeneratingAnimationFromImage } = useStartGeneratingAnimationFromImage();
  const { data: imageCredits, loading: loadingCredit } = useImageGenerationCredit();

  const creditLeft = !imageCredits ? "0" : imageCredits.unlimited ? "∞" : imageCredits.tokensLeft.toString();

  const headerRight = useCallback(() => <StudioTokenButton ref={studioTokenBottomSheetRef} />, []);

  useEffect(() => {
    navigation.setOptions({
      headerRight,
      headerStyle: { backgroundColor: colors.tertiaryContainer },
    });
  }, [colors.tertiaryContainer, headerRight, navigation]);

  const onCompleted = useCallback(
    (studioTask: StudioTask, taskType: GenerationTaskType.original | GenerationTaskType.imageToImage | GenerationTaskType.imageToAnimation) => {
      setGeneration(prev => ({
        ...prev,
        taskIds: { ...(prev.taskIds ?? {}), [studioTask.studioTaskId]: taskType },
      }));
    },
    [setGeneration],
  );

  const onError = useCallback(
    (error: ApolloError) => {
      showErrorSnackbar({ error, message: t("error.generic") });
      setTimeout(() => studioBottomSheetRef.current?.open(), 500);
      setIsCurrentlyGenerating(false);
    },
    [setIsCurrentlyGenerating, showErrorSnackbar, t],
  );

  const handleOnGenerateImages = useCallback(async (): Promise<void> => {
    Keyboard.dismiss();

    if (!userId) {
      openLogin();
      return;
    }

    if (creditLeft === "0") {
      studioTokenBottomSheetRef.current?.openStudioTokenBottomSheet();
      return;
    }

    if (loadingCredit) return;

    if (!generation.image && !generation.prompt) return;

    if (generation.image && !isImageToBeUsed(generation.image)) return;

    setTimeout(() => studioBottomSheetRef.current?.close(), 500);

    setIsCurrentlyGenerating(true);

    if (generation.image) {
      const imageId =
        generation.image.imageSource === "upload" ? { uploadImageId: generation.image.imageId } : { studioImageId: generation.image.imageId };
      if (generation.initialFlow === StudioFlowType.image) {
        await startGeneratingImagesFromImage({
          variables: { input: { ...imageId, prompt: generation.prompt }, userId },
          onCompleted: response => onCompleted(response.startGeneratingImagesFromImage, GenerationTaskType.imageToImage),
          onError,
        });
      } else if (generation.initialFlow === StudioFlowType.image3d) {
        await startGeneratingAnimationFromImage({
          variables: { input: imageId, userId },
          onCompleted: response => onCompleted(response.startGeneratingAnimationFromImage, GenerationTaskType.imageToAnimation),
          onError,
        });
      }
    } else {
      await startGeneratingImages({
        variables: {
          input: { prompt: generation.prompt ?? "", contestId: inputs.contest?.id, catalogProductId: generation.catalogProduct?.catalogProductId },
          userId,
        },
        onCompleted: response => onCompleted(response.startGeneratingImages, GenerationTaskType.original),
        onError,
      });
    }
  }, [
    userId,
    creditLeft,
    loadingCredit,
    generation.image,
    generation.prompt,
    generation.initialFlow,
    generation.catalogProduct?.catalogProductId,
    setIsCurrentlyGenerating,
    openLogin,
    startGeneratingImagesFromImage,
    onError,
    onCompleted,
    startGeneratingAnimationFromImage,
    startGeneratingImages,
    inputs.contest?.id,
  ]);

  const handleReset = useCallback((): void => {
    setGeneration(prev => ({ ...prev, taskIds: null }));
    resetGeneratingImages?.();
    resetGeneratingImagesFromImage?.();
    resetGeneratingAnimationFromImage?.();
    setIsCurrentlyGenerating(false);
    studioBottomSheetRef.current?.open();
  }, [resetGeneratingAnimationFromImage, resetGeneratingImages, resetGeneratingImagesFromImage, setGeneration, setIsCurrentlyGenerating]);

  const screenWrapperProps: ScreenWrapperProps = isAndroidApp ? { withKeyboardAvoidingView: true, withScrollView: true } : {};

  return (
    <ScreenWrapper {...screenWrapperProps}>
      <StudioGeneratedImages style={styles.root} onGenerate={handleOnGenerateImages} onReset={handleReset} />

      <BottomSheetStudioGeneration ref={studioBottomSheetRef} onPressGenerate={handleOnGenerateImages} />
    </ScreenWrapper>
  );
};
