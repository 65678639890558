import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { preorderFields } from "@app/common/graphql/fragments.graphql";
import { CollabCampaign, CreatePreorderInput, Preorder } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  createPreorder: Preorder;
}

interface Variables {
  input: CreatePreorderInput;
  userId: string;
}

interface CreatePreorderResult extends MutationResult<Response, "createPreorder"> {
  createPreorder: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createPreorderMutation = gql`
  mutation CreatePreorder($input: CreatePreorderInput!, $userId: UserId!) {
    createPreorder(input: $input, userId: $userId) {
      ...PreorderFields
    }
  }
  ${preorderFields}
`;

export function useCreatePreorder(collabId: string): CreatePreorderResult {
  const [createPreorder, { loading, error, data }] = useMutation<Response, Variables>(createPreorderMutation, {
    update(cache, _results, options) {
      cache.modify({
        id: collabId,
        fields: {
          campaign: (currentCampaign: CollabCampaign) => {
            return {
              ...currentCampaign,
              orderCount: currentCampaign.orderCount + (options.variables?.input.quantity ?? 1),
            };
          },
        },
      });
    },
  });

  return { createPreorder, loading, error, data: data?.createPreorder };
}
