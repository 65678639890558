import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { ViewProps } from "react-native";

import { AlertVariant, EmbeddedAlert } from "../embedded-alert/embedded-alert.component";

interface Props extends ViewProps {
  handleRefresh: () => void;
}

export const VideoRefreshAlert: FC<Props> = ({ handleRefresh }) => {
  const { t } = useTranslation();

  return (
    <EmbeddedAlert
      variant={AlertVariant.primary}
      text={t("video.currentlyBeingProcessed")}
      cta={{ label: t("cta.refresh"), onPress: handleRefresh }}
    />
  );
};
