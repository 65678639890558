import React, { Dispatch, FC, SetStateAction, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";

import { Collab, ProductVariant } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { QuantitySelector } from "@app/components/products/product-details/quantity-selector/quantity-selector.component";
import { VariantType } from "@app/components/products/product-details/variant-picker/variant-picker.component";
import { ProductPrice } from "@app/components/products/product-price/product-price.component";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { OpenVariantPickerButton } from "../../products/open-variant-picker-button/open-variant-picker-button.component";

import { styles } from "./cart-order-product-info.style";

interface Variant {
  variantName: VariantType | "quantity";
  value?: string | null;
}

interface Props {
  id: string;
  context: "order" | "cart";
  product?: Collab;
  variant?: ProductVariant;
  quantity: number;
  price?: string;
  compareAtPrice?: string;
  setQuantity?: Dispatch<SetStateAction<number>>;
}

export const CartOrderProductInfo: FC<Props> = ({ id, context, product, variant, quantity, price, compareAtPrice, setQuantity }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const navigation = useNavigation();

  const showQuantitySelector = !!setQuantity && !!variant && !!product;

  const handleViewDetails = (): void => (product?.handle ? navigation.navigate(Routes.product, { id: product?.handle }) : undefined);

  const variants: Variant[] = [
    ...conditionalItem<Variant>(!!variant?.size, { variantName: "size", value: variant?.size }),
    ...conditionalItem<Variant>(!!variant?.color, { variantName: "color", value: variant?.color }),
    ...conditionalItem<Variant>(!!variant?.material, { variantName: "material", value: variant?.material }),
    ...conditionalItem<Variant>(!!variant?.style, { variantName: "style", value: variant?.style }),
    ...conditionalItem<Variant>(!showQuantitySelector, { variantName: "quantity", value: `x${quantity}` }),
  ];

  const renderVariant = useCallback(
    ({ item }: { item: Variant }) =>
      item.value ? (
        <ListItem
          title={item.variantName}
          titleProps={{ variant: "subtitle1", textTransform: "capitalize" }}
          right={
            <Text
              variant="body2"
              color="tertiary"
              textTransform={item.variantName === "quantity" || item.variantName === "size" ? "none" : "capitalize"}>
              {item.value}
            </Text>
          }
        />
      ) : null,
    [],
  );

  return (
    <View style={styles.root}>
      <ListItem
        title={product?.name ?? ""}
        titleProps={{ variant: "body2", numberOfLines: 4 }}
        subtitle={t("preorder.creatorCaption", { name: product?.creator.displayName })}
        subtitleProps={{ variant: "caption", color: "tertiary" }}
        imageProps={{ image: product?.images[0]?.imageUrl, size: 64, style: { borderRadius: 0 } }}
        right={<ProductPrice price={price} compareAtPrice={compareAtPrice} commonTextProps={{ variant: context === "order" ? "body2" : "h8" }} />}
        contentContainerStyle={styles.productInfosRow}
        onPress={handleViewDetails}
      />

      {!variant && product && <OpenVariantPickerButton product={product} buyAction="assignVariant" cartItemId={id} />}

      {!!variants.length && (
        <FlatList
          data={variants}
          renderItem={renderVariant}
          keyExtractor={item => `order-line-item-variant-${item.variantName}-${id}`}
          scrollEnabled={false}
          style={[styles.variantContainer, { backgroundColor: colors.tertiaryContainer }]}
        />
      )}

      {showQuantitySelector && (
        <QuantitySelector
          cartItemId={id}
          quantity={quantity}
          setQuantity={setQuantity}
          maxQuantity={variant.inventoryQuantity}
          variantId={variant.variantId}
          collabId={product?.collabId}
          updateOnClick
        />
      )}
    </View>
  );
};
