import React, { FC } from "react";

import { Pencil } from "phosphor-react-native";
import { IconButton, useTheme } from "react-native-paper";

import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { useAuthContext } from "@app/context/auth/auth.context";
import { isAdmin } from "@app/utils/user.util";

import { styles } from "./edit-contest-fab.style";

interface Props {
  contestId: string;
}

export const EditContestFab: FC<Props> = ({ contestId }) => {
  const navigation = useNavigation();
  const { colors, roundness } = useTheme();
  const { state } = useAuthContext();

  return isAdmin(state) ? (
    <IconButton
      containerColor={colors.primary}
      style={[{ borderRadius: roundness, borderColor: colors.godMode }, styles.iconButton]}
      icon={props => <Pencil {...props} color={colors.godMode} size={32} />}
      onPress={() => navigation.navigate(Routes.createContest, { contestId })}
    />
  ) : null;
};
