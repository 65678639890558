import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreCartItemFields } from "@app/common/graphql/fragments.graphql";
import { CartItemWithIdentity, UpdateCartItemInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCartItem: CartItemWithIdentity;
}

interface Variables {
  cartItemId: string;
  input: UpdateCartItemInput;
}

interface UpdateCartItemResult extends MutationResult<Response, "updateCartItem"> {
  updateCartItem: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCartItemMutation = gql`
  mutation UpdateCartItem($cartItemId: CartItemId!, $input: UpdateCartItemInput!) {
    updateCartItem(cartItemId: $cartItemId, input: $input) {
      cartItem {
        ...CoreCartItemFields
      }
      identity {
        anonymousCartId
        userId
      }
    }
  }
  ${coreCartItemFields}
`;

export function useUpdateCartItem(): UpdateCartItemResult {
  const [updateCartItem, { loading, error, data }] = useMutation<Response, Variables>(updateCartItemMutation, {
    update(cache, result) {
      const cartItemUpdated = result.data?.updateCartItem.cartItem;
      if (!cartItemUpdated) return;

      const cartItemId = cartItemUpdated.cartItemId;

      cache.modify({
        id: cartItemId,
        fields: {
          quantity: () => cartItemUpdated?.quantity,
          variant: () => cartItemUpdated.variant ?? null,
        },
      });
    },
  });

  return { updateCartItem, loading, error, data: data?.updateCartItem };
}
