import React, { FC, useState } from "react";

import { AuthError, EmailAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";
import { View } from "react-native";

import { ChoosePassword } from "@app/components/login/choose-password/choose-password.component";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { LinkLoginProvidersProps } from "@app/context/auth/login/login.context";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";

import { styles } from "./create-account.style";

interface Props {
  email: string;
  onSuccess: () => void;
  openLinkProvidersPrompt: (props: LinkLoginProvidersProps) => void;
}

export const CreateAccount: FC<Props> = ({ email, onSuccess, openLinkProvidersPrompt }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { handleAuthError, handleAuthErrorWithAccountLinkingErrors } = useHandleAuthError();

  const handleNext = (): void => {
    setLoading(true);

    getAuthWhenReady()
      .then(auth =>
        createUserWithEmailAndPassword(auth, email, password)
          .then(() => {
            onSuccess();
            setLoading(false);
          })
          .catch((error: AuthError) =>
            handleAuthErrorWithAccountLinkingErrors(error, {
              email,
              pendingCredential: EmailAuthProvider.credential(email, password),
              loginAction: LoginAction.createAccountWithEmail,
              setLoading,
              currentSignInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
              openLinkProvidersPrompt,
            }),
          ),
      )
      .catch((error: AuthError) => {
        setLoading(false);
        handleAuthError(error, LoginAction.getAuth);
      });
  };

  return (
    <View style={styles.wrapperView}>
      <ChoosePassword
        isNewAccount
        email={email}
        password={password}
        setPassword={setPassword}
        handleSubmit={handleNext}
        loading={loading}
        inBottomSheet
      />
    </View>
  );
};
