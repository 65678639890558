import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  content: {
    rowGap: 36,
  },
  root: {
    borderWidth: 0.3,
    marginHorizontal: 20,
  },
});
