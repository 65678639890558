import { APP_ENV } from "@env";
import * as firebase from "firebase/app";

import { isAndroidApp, isIosApp } from "@app/utils/device.util";

const isProd = APP_ENV === "production";

export const googleConfig = {
  androidClientId: isProd
    ? "597893530348-4n9ef10eld5o1mfhqdv7fppk5agfior2.apps.googleusercontent.com"
    : "907261245549-52rcjjqjg1roc9s819pcl18u7bj1ndja.apps.googleusercontent.com",
  iosClientId: isProd
    ? "597893530348-k0u0qgqkrsj6j5477tat80mg7ihcp1qv.apps.googleusercontent.com"
    : "907261245549-0i7bv3jbs8lnjeds399clhphn3btq1j9.apps.googleusercontent.com",
  webClientId: isProd
    ? "597893530348-0p97hf8gar9svc039ks263ugepcdrub6.apps.googleusercontent.com"
    : "907261245549-f7t21snct799hik4edkju7cqc3qff529.apps.googleusercontent.com",
};

export const facebookConfig = {
  clientId: isProd ? "279694661069230" : "1369553560254393",
};

export const twitterConfig = {};

function getApiKey(): string {
  if (isProd) {
    if (isIosApp) return "AIzaSyAktNGNl6eLZCao-otxNa8SNN6WcQ0A_00";
    if (isAndroidApp) return "AIzaSyCcmylahAIEx1Bubzo3eZKi02SSr4KjJWM";
    return "AIzaSyCnEuYAseZC4li9yPBoW_mdc9psVwEy9tk";
  }
  if (isIosApp) return "AIzaSyDbdCWaBceligMyeqC-ZYsipqUrYG5fmkg";
  if (isAndroidApp) return "AIzaSyDCu2KGFpBSjc2c0hBMt0pZBehIz5Nul0w";
  return "AIzaSyDseGRW4fKcO_xiSkwk9NShKuXS4E0H4zk";
}

function getAppId(): string {
  if (isProd) {
    if (isIosApp) return "1:597893530348:ios:debdc9d0c76251805b5bc9";
    if (isAndroidApp) return "1:597893530348:web:6f63757ee5f555345b5bc9";
    return "1:597893530348:android:c8b0a8f81744ab7d5b5bc9";
  }
  if (isIosApp) return "1:907261245549:ios:388cf5b2ffd57b4df1a764";
  if (isAndroidApp) return "1:907261245549:android:0fcf1bf72a23714ff1a764";
  return "1:907261245549:web:0aef070e04515141f1a764";
}

// Initialize Firebase
export const firebaseConfig = isProd
  ? {
      apiKey: getApiKey(),
      authDomain: "bloom-backend-prod.firebaseapp.com",
      appId: getAppId(),
    }
  : {
      apiKey: getApiKey(),
      authDomain: "bloom-backend-dev.firebaseapp.com",
      appId: getAppId(),
    };

export const firebaseApp = firebase.initializeApp(firebaseConfig);
