import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { REACT_APP_STREAM_API_KEY, REACT_APP_STREAM_APP_ID } from "@env";
import { SubscribeCallback } from "faye";
import { RealTimeMessage, StreamClient, StreamFeed, connect } from "getstream";

import { ActivityVerb } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useNotificationsToken } from "@app/hooks/api/use-notifications-token.hook";
import { reportError } from "@app/utils/logger/logger.util";
import { isObjectWithProperties } from "@app/utils/object.util";

import { useAuthContext } from "../auth/auth.context";
import { useProfileContext } from "../profile/profile.context";

import { StreamContext, StreamContextInterface } from "./stream.context";

export const handledNotifTypes = [
  ActivityVerb.becomeCreator,
  ActivityVerb.becomeSellable,
  ActivityVerb.contestEndingSoon,
  ActivityVerb.contestGoLive,
  ActivityVerb.contestTopUser,
  ActivityVerb.deniedCollabToDevelopment,
  ActivityVerb.deniedCollabToUnderReview,
  ActivityVerb.fullLikesMilestone,
  ActivityVerb.halfLikesMilestone,
  ActivityVerb.like,
  ActivityVerb.promotedCollabToDevelopment,
  ActivityVerb.promotedCollabToUnderReview,
  ActivityVerb.promotedCollabToUnderReviewReady,
  ActivityVerb.underReviewEndingInTenDays,
  ActivityVerb.underReviewEndingInThreeDays,
  ActivityVerb.preorderPlaced,
  ActivityVerb.collabComment,
  ActivityVerb.collabUpdate,
  ActivityVerb.collabUpdateComment,
  ActivityVerb.paymentFailed,
  ActivityVerb.paymentIncoming,
  ActivityVerb.paymentSucceeded,
] as string[];

export const StreamContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { state } = useAuthContext();
  const { profile } = useProfileContext();

  const { getNotificationsToken } = useNotificationsToken();

  const [streamClient, setStreamClient] = useState<StreamClient | null>(null);
  const [notificationsFeed, setNotificationsFeed] = useState<StreamFeed | undefined>(undefined);
  const [userHasNewNotifs, setUserHasNewNotifs] = useState(false);

  const isHandledActivity = (activity: unknown): boolean => {
    return !!(isObjectWithProperties(activity, "verb") && typeof activity.verb === "string" && handledNotifTypes.includes(activity.verb));
  };

  const processNewNotifications: SubscribeCallback<RealTimeMessage> = useCallback((data): void => {
    if (data.new.length > 0 && data.new.some(activity => isHandledActivity(activity))) {
      setUserHasNewNotifs(true);
    }
  }, []);

  const subscribeToNotificationFeed = useCallback(
    async (feed: StreamFeed): Promise<void> => {
      try {
        await feed.subscribe(processNewNotifications);

        const firstNotif = await feed.get({ limit: 1 });
        if (firstNotif.unseen && firstNotif.results.length > 0 && isHandledActivity(firstNotif.results[0])) {
          setUserHasNewNotifs(true);
        }
      } catch (error) {
        reportError(error);
      }
    },
    [processNewNotifications],
  );

  const connectToStream = useCallback(
    (userId: string): void => {
      void getNotificationsToken({
        variables: { userId },
        onCompleted: data => {
          try {
            const client = connect(REACT_APP_STREAM_API_KEY, data?.notificationsToken.token, REACT_APP_STREAM_APP_ID);
            setStreamClient(client);

            const feed = client?.feed("notifications", userId);
            setNotificationsFeed(feed);

            void subscribeToNotificationFeed(feed);
          } catch (error) {
            setStreamClient(null);
            setNotificationsFeed(undefined);
            reportError(error);
          }
        },
      });
    },
    [getNotificationsToken, subscribeToNotificationFeed],
  );

  const disconnectFromStream = useCallback((): void => {
    if (notificationsFeed) {
      notificationsFeed.unsubscribe();
      setNotificationsFeed(undefined);
    }
    setStreamClient(null);
    setUserHasNewNotifs(false);
  }, [notificationsFeed]);

  useEffect(() => {
    if (!state.connected) {
      disconnectFromStream();
    }
  }, [disconnectFromStream, state.connected]);

  useEffect(() => {
    if (profile?.userId) {
      connectToStream(profile.userId);
    }
  }, [connectToStream, profile?.userId]);

  const connected = streamClient && notificationsFeed;

  const streamContextValue = useMemo<StreamContextInterface>(
    () => (connected ? { userHasNewNotifs, setNotifsAsSeen: () => setUserHasNewNotifs(false) } : {}),
    [connected, userHasNewNotifs],
  );

  return <StreamContext.Provider value={streamContextValue}>{children}</StreamContext.Provider>;
};
