import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { likeFields } from "@app/common/graphql/fragments.graphql";
import { Like, LikeCollabInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { updateCacheAfterLikeCollab } from "@app/utils/cache-updates/update-cache-after-like-collab.util";

interface Response {
  likeCollab: Like;
}

interface Variables {
  input: LikeCollabInput;
  userId: string;
}

interface LikeCollabResult extends MutationResult<Response, "likeCollab"> {
  likeCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const likeCollabMutation = gql`
  mutation LikeCollab($input: LikeCollabInput!, $userId: UserId!) {
    likeCollab(input: $input, userId: $userId) {
      ...LikeFields
    }
  }
  ${likeFields}
`;

export function useLikeCollab(): LikeCollabResult {
  const [likeCollab, { loading, error, data, client }] = useMutation<Response, Variables>(likeCollabMutation, {
    update(cache, results) {
      updateCacheAfterLikeCollab({
        cache,
        client,
        collabId: results.data?.likeCollab.collabId,
        numberOfLikes: results.data?.likeCollab.numberOfLikes,
      });
    },
  });

  return { likeCollab, loading, error, data: data?.likeCollab };
}
