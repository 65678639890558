import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { AddPaymentMethodDataForAnonymous } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  generateAddPaymentMethodDataForAnonymous: AddPaymentMethodDataForAnonymous;
}

interface Variables {
  userId: string;
}

interface GenerateAddPaymentMethodDataForAnonymousResult extends MutationResult<Response, "generateAddPaymentMethodDataForAnonymous"> {
  generateAddPaymentMethodDataForAnonymous: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const generateAddPaymentMethodDataForAnonymousMutation = gql`
  mutation GenerateAddPaymentMethodDataForAnonymous {
    generateAddPaymentMethodDataForAnonymous {
      anonymousCustomerId
      clientSecret
    }
  }
`;

export function useGenerateAddPaymentMethodDataForAnonymous(): GenerateAddPaymentMethodDataForAnonymousResult {
  const [generateAddPaymentMethodDataForAnonymous, { loading, error, data }] = useMutation<Response, Variables>(
    generateAddPaymentMethodDataForAnonymousMutation,
  );

  return { generateAddPaymentMethodDataForAnonymous, loading, error, data: data?.generateAddPaymentMethodDataForAnonymous };
}
