import React, { FC, useMemo } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useFrontendConfig } from "@app/hooks/api/use-frontend-config.hook";

import { useAuthContext } from "../auth/auth.context";

import { ConfigContext, ConfigContextInterface } from "./config.context";

export const ConfigContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { state } = useAuthContext();
  const { data, loading } = useFrontendConfig();

  const context = useMemo<ConfigContextInterface>(
    () => ({
      config: data?.frontendConfig,
      maxImagesPerCollab: state.isAdmin
        ? data?.frontendConfig.maximumNumberOfImagesPerCollabForAdmins
        : data?.frontendConfig.maximumNumberOfImagesPerCollab,
      tokenCosts: data?.tokenCosts,
      loading,
    }),
    [data?.frontendConfig, data?.tokenCosts, loading, state],
  );

  return <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>;
};
