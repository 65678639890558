import React, { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { ConceptsSortingMethod, ForSaleCollabsSortingMethod, UnderReviewCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { SearchStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { HomeSectionType } from "@app/components/home/home.type";

export const SearchScreen: FC<SearchStackScreenProps<Routes.search>> = ({ route, navigation }) => {
  const { t } = useTranslation();

  const currentTab = route.params?.browseOption;

  useEffect(() => {
    if (route.path?.startsWith("/search")) {
      switch (currentTab) {
        case BrowseOption.concepts:
          navigation.replace(Routes.root, {
            screen: Tabs.home,
            params: { screen: Routes.seeAllCollabs, params: { type: HomeSectionType.concepts, sortBy: ConceptsSortingMethod.trending } },
          });
          return;
        case BrowseOption.contests:
          navigation.replace(Routes.root, {
            screen: Tabs.home,
            params: { screen: Routes.contests },
          });
          return;
        case BrowseOption.sellables:
          navigation.replace(Routes.root, {
            screen: Tabs.home,
            params: {
              screen: Routes.seeAllCollabs,
              params: { type: HomeSectionType.forSaleCollabs, sortBy: ForSaleCollabsSortingMethod.bestselling },
            },
          });
          return;
        case BrowseOption.underReview:
          navigation.replace(Routes.root, {
            screen: Tabs.home,
            params: {
              screen: Routes.seeAllCollabs,
              params: { type: HomeSectionType.underReviewCollabs, sortBy: UnderReviewCollabsSortingMethod.trending },
            },
          });
          return;
        default:
          navigation.replace(Routes.root, {
            screen: Tabs.home,
            params: {
              screen: Routes.searchStack,
              params: { screen: Routes.search },
            },
          });
          return;
      }
    }
  }, [currentTab, navigation, route.path]);

  return (
    <ScreenWrapper>
      <EmptyState message={t("search.emptyLabel")} fullScreen />
    </ScreenWrapper>
  );
};
