import { useTranslation } from "react-i18next";

import { ActivityVerb, Notification, NotificationActor, NotificationObject } from "@app/common/graphql/generated/schema.graphql";
import { reportError } from "@app/utils/logger/logger.util";

import { getContestFromNotifiableEntity } from "./notification-props.util";

type NotificationPropsSubtitle = string | undefined;

// eslint-disable-next-line complexity
export const useNotificationPropsSubtitle = (notification: Notification): NotificationPropsSubtitle => {
  const { t } = useTranslation();

  const activity = notification.activities[0];
  const notifObject = activity.object;

  function getCollabDetails(entity: NotificationActor | NotificationObject, verb: ActivityVerb): string {
    switch (entity.__typename) {
      case "Collab":
        return `@${entity.creator.username}\n${entity.name}`;
      case "DeletedObject":
        return t("notifications.deletedCollab");
      default:
        reportError(new Error(`Received ${entity.__typename ?? ""} as object to display a collab but ${verb} not handled`));
        return "";
    }
  }

  switch (notification.verb) {
    case ActivityVerb.becomeSellable:
    case ActivityVerb.deniedCollabToDevelopment:
    case ActivityVerb.deniedCollabToUnderReview:
    case ActivityVerb.fullLikesMilestone:
    case ActivityVerb.halfLikesMilestone:
    case ActivityVerb.like:
    case ActivityVerb.promotedCollabToDevelopment:
    case ActivityVerb.promotedCollabToUnderReview:
    case ActivityVerb.promotedCollabToUnderReviewReady:
    case ActivityVerb.underReviewEndingInTenDays:
    case ActivityVerb.underReviewEndingInThreeDays:
      return getCollabDetails(notifObject, notification.verb);
    case ActivityVerb.paymentSucceeded:
      return t("notifications.paymentSucceededSubtitle");
    case ActivityVerb.paymentIncoming:
      return t("notifications.paymentIncomingSubtitle");
    case ActivityVerb.paymentFailed:
      return t("notifications.paymentFailedSubtitle");
    case ActivityVerb.contestEndingSoon:
    case ActivityVerb.contestGoLive:
    case ActivityVerb.contestTopUser: {
      const contest = getContestFromNotifiableEntity(notifObject, notification.verb);
      return contest?.name;
    }
    case ActivityVerb.collabUpdate:
      return t("notifications.collabUpdate", { count: notification.actorCount });
    case ActivityVerb.preorderPlaced:
      return t("notifications.preorderPlaced", { count: notification.actorCount });
    default:
      return undefined;
  }
};
