import React, { FC, useEffect, useState } from "react";

import { CheckCircle } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton, useTheme } from "react-native-paper";

import { MediaToBeUsed } from "@app/common/types/image.type";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioSavedImages } from "@app/components/studio-mini/studio-saved-images/studio-saved-images.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

export const SavedImagesScreen: FC<NavigationProps<Routes.studioMiniSaved>> = ({ navigation, route }) => {
  const theme = useTheme();
  const { colors } = theme;
  const { t } = useTranslation();

  const selectionMode = route.params?.selectionMode;

  const {
    setInputMedia: setMedia,
    inputs: { contest },
  } = useStudioMiniContext();

  const [isSelecting, setIsSelecting] = useState(selectionMode === "always");

  const handleUseImages = (selectedImages: MediaToBeUsed[]): void => {
    setMedia(prevImages => [...prevImages, ...selectedImages]);
    navigation.navigate(Routes.studioMiniCreateCollab);
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        selectionMode === "allowed" && (
          <IconButton
            icon={({ color, ...props }) => (
              <CheckCircle weight={isSelecting ? "fill" : "thin"} color={isSelecting ? colors.secondary : color} {...props} />
            )}
            onPress={() => {
              setIsSelecting(prev => !prev);
            }}
          />
        ),
    });
  }, [colors.secondary, contest, isSelecting, navigation, selectionMode, t]);

  return (
    <ScreenWrapper>
      <StudioSavedImages
        selectionMode={selectionMode}
        isSelecting={isSelecting}
        allowMultipleSelection
        onSelectImage={media =>
          navigation.navigate(
            Routes.studioMiniSavedDetails,
            media.__typename === "StudioImage" ? { imageId: media.imageId } : { animationId: media.studioAnimationId },
          )
        }
        onUseImages={handleUseImages}
      />
    </ScreenWrapper>
  );
};
