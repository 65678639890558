import React, { FC } from "react";

import { View } from "react-native";

import { EmailType } from "@app/common/enums/email-type.enum";
import { EnterPasswordFields } from "@app/components/login/enter-password-fields/enter-password-fields.component";

import { styles } from "./enter-password.style";

interface Props {
  email: string;
  onCheckEmail: (emailType: EmailType) => void;
}

export const EnterPassword: FC<Props> = ({ email, onCheckEmail }) => {
  return (
    <View style={styles.wrapperView}>
      <EnterPasswordFields
        email={email}
        onVerifyEmail={() => onCheckEmail(EmailType.verifyEmail)}
        onForgotPassword={() => onCheckEmail(EmailType.resetPassword)}
      />
    </View>
  );
};
