import React, { FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";

import { CollabWithMedia } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { darkTheme } from "@app/common/style/theme";
import { Routes, useFeedNavigation } from "@app/common/types/navigation.type";
import { Chip } from "@app/components/common/chip/chip.component";
import { Text } from "@app/components/common/text/text.component";
import { useFeedContext } from "@app/context/feed/feed.context";
import { useComponentSize } from "@app/hooks/utils/use-component-size.hook";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { CreatorNameWithAvatar } from "../../../profile/creator-name-with-avatar/creator-name-with-avatar.component";
import { GoalsProgress } from "../../goals-progress/goals-progress.component";
import { goalsProgressHeight } from "../../goals-progress/goals-progress.style";

import { padding, paddingBottom, styles } from "./product-card-details.style";

interface Props {
  product: CollabWithMedia;
  feedIndex: number;
  maxImageHeight: number;
  setImageHeight: (height: number) => void;
}

export const ProductCardDetails: FC<Props> = ({ product, feedIndex, maxImageHeight, setImageHeight }) => {
  const navigation = useFeedNavigation();
  const { t } = useTranslation();
  const { fontScale, width: windowWidth, height: windowHeight } = useWindowDimensions();
  const { cardHeight } = useFeedEntityCardHeight();
  const {
    feed: { currentIndex },
  } = useFeedContext();

  const isCurrentCard = feedIndex === currentIndex;

  const { name, creator, description, contest } = product;

  const rowGap = padding;
  const creatorNameHeight = 36;
  const defaultItemNameHeight = customFonts.h9.lineHeight * fontScale;
  const [itemNameSize, handleItemNameLayout] = useComponentSize({ defaultSize: { height: defaultItemNameHeight } });
  const [numberOfDescriptionLinesShown, setNumberOfDescriptionLinesShown] = useState<number | undefined>(undefined);

  const handleLayout = useCallback((): void => {
    const minContainerHeight = padding + 3 * rowGap + goalsProgressHeight + creatorNameHeight + itemNameSize.height + paddingBottom;
    if (cardHeight - maxImageHeight < minContainerHeight) {
      setImageHeight(cardHeight - minContainerHeight);
      setNumberOfDescriptionLinesShown(0);
    } else {
      const descriptionHeight = cardHeight - maxImageHeight - minContainerHeight;
      setNumberOfDescriptionLinesShown(Math.floor(descriptionHeight / (customFonts.body2.lineHeight * fontScale)));
    }
  }, [cardHeight, fontScale, itemNameSize.height, maxImageHeight, rowGap, setImageHeight]);

  useEffect(() => {
    handleLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight]);

  const handleOpenDetails = (): void => {
    navigation.navigate(Routes.productFromFeed, { index: feedIndex });
  };

  return (
    <View style={[styles.detailsContainer, { rowGap }]} onLayout={handleLayout}>
      <GoalsProgress
        liked={product.liked}
        numberOfLikes={product.numberOfLikes}
        campaign={product.campaign}
        collabProgress={product.progress}
        showPopper={isCurrentCard}
        theme={darkTheme}
      />
      <CreatorNameWithAvatar
        profile={creator}
        imageSize={creatorNameHeight}
        textProps={{ variant: "h9", color: "common.white" }}
        width={windowWidth - padding * 2}
        style={styles.creatorRowContainer}
      />

      <Pressable onPress={handleOpenDetails} style={{ rowGap }}>
        <View style={styles.namesContainer} onLayout={handleItemNameLayout}>
          <Text variant="h9" color="common.white">
            {name}
          </Text>
          {contest && <Chip label={t("contest.label", { name: contest.name })} color="primary" style={styles.contestName} theme={darkTheme} />}
        </View>
        {numberOfDescriptionLinesShown !== undefined && numberOfDescriptionLinesShown > 0 && (
          <Text variant="body2" color="tertiary" numberOfLines={numberOfDescriptionLinesShown} theme={darkTheme}>
            {description}
          </Text>
        )}
      </Pressable>
    </View>
  );
};
