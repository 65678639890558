import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { formatSeconds } from "@app/utils/duration.util";

import { styles } from "./upload-pitch-video.style";

export const UploadPitchVideo: FC = () => {
  const { t } = useTranslation();
  const { config } = useConfigContext();

  const maximumVideoDurationInSeconds = config?.maximumVideoDurationForCampaignInSeconds;
  const maximumVideoDuration = maximumVideoDurationInSeconds ? formatSeconds(maximumVideoDurationInSeconds) : undefined;

  return (
    <View style={styles.root}>
      <Text variant="body1">{t("startCampaign.uploadVideo.explication")}</Text>
      <Text variant="caption">{t("startCampaign.uploadVideo.requirements", { maximumVideoDuration })}</Text>
    </View>
  );
};
