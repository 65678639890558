import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { PushNotificationsToken, PushNotificationsTokenInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  revokePushNotificationsToken: PushNotificationsToken;
}

interface Variables {
  input: PushNotificationsTokenInput;
  userId: string;
}

interface RevokePushNotificationsTokenResult extends MutationResult<Response, "revokePushNotificationsToken"> {
  revokePushNotificationsToken: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const revokePushNotificationsTokenMutation = gql`
  mutation RevokePushNotificationsToken($input: PushNotificationsTokenInput!, $userId: UserId!) {
    revokePushNotificationsToken(input: $input, userId: $userId) {
      token
      userId
    }
  }
`;

export function useRevokePushNotificationsToken(): RevokePushNotificationsTokenResult {
  const [revokePushNotificationsToken, { loading, error, data }] = useMutation<Response, Variables>(revokePushNotificationsTokenMutation);

  return { revokePushNotificationsToken, loading, error, data: data?.revokePushNotificationsToken };
}
