import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { orderFields } from "@app/common/graphql/fragments.graphql";
import { UpdatePreorderPaymentMethodInput, Order } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  updatePreorderPaymentMethod: Order;
}

interface Variables {
  input: UpdatePreorderPaymentMethodInput;
  orderId: string;
}

interface UpdatePreorderPaymentMethodResult extends MutationResult<Response, "updatePreorderPaymentMethod"> {
  updatePreorderPaymentMethod: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updatePreorderPaymentMethodMutation = gql`
  mutation UpdatePreorderPaymentMethod($input: UpdatePreorderPaymentMethodInput!, $orderId: OrderId!) {
    updatePreorderPaymentMethod(input: $input, orderId: $orderId) {
      ...OrderFields
    }
  }
  ${orderFields}
`;

export function useUpdatePreorderPaymentMethod(): UpdatePreorderPaymentMethodResult {
  const [updatePreorderPaymentMethod, { loading, error, data }] = useMutation<Response, Variables>(updatePreorderPaymentMethodMutation);

  return { updatePreorderPaymentMethod, loading, error, data: data?.updatePreorderPaymentMethod };
}
