import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  profile: Profile;
}

export const featuredCreatorQuery = gql`
  query FeaturedCreator {
    profile: featuredCreator {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useFeaturedCreator(options?: QueryHookOptions<Response>): QueryResult<Response, "profile"> {
  const { data, loading, error } = useQuery<Response>(featuredCreatorQuery, options);

  return { data: data?.profile, loading, error };
}
