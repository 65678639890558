import { isWeb } from "./device.util";

export function isLikelyInEmbeddedWebview(): boolean {
  if (!isWeb) return false;

  const userAgent = window.navigator.userAgent.toLowerCase();

  const isNotSafariOrWrappedSafari = !/safari/.test(userAgent);

  const isNotFirefox = !/firefox/.test(userAgent);

  const isInstagram = /instagram/.test(userAgent);

  const isFacebook = /(fban)|(fbav)/.test(userAgent);

  return (isNotSafariOrWrappedSafari && isNotFirefox) || isInstagram || isFacebook;
}
