import { GITHUB_SHA } from "@env";
import * as Application from "expo-application";
import Constants from "expo-constants";

import { isWeb } from "./device.util";

export const getAppScheme = (): string => Constants.expoConfig?.slug.replace("-", "") ?? "";

export const getAppVersion = (): string => {
  if (isWeb) {
    const hash = GITHUB_SHA?.slice(0, 7);
    return [Constants.expoConfig?.version, hash].filter(Boolean).join("-");
  }
  return [Application.nativeApplicationVersion, Application.nativeBuildVersion].filter(Boolean).join("-");
};
