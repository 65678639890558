import { JSX } from "react";

import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import type { ScopeContext } from "@sentry/types";

import { AlertCta } from "@app/common/types/alert-cta.interface";

export enum SnackbarType {
  error = "error",
  noInternet = "noInternet",
  success = "success",
}

export interface GenericSnackbarOptions {
  icon?: JSX.Element;
  refetch?: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<unknown>>;
  message?: string;
  error?: unknown;
  context?: Partial<ScopeContext>;
  duration?: number;
  cta?: AlertCta;
}

export type SuccessSnackbarOptions = Pick<GenericSnackbarOptions, "message">;

export interface SnackbarInterface {
  type: SnackbarType;
  options?: GenericSnackbarOptions;
}
