import { DarkTheme as NavigationDarkTheme, DefaultTheme as NavigationDefaultTheme, Theme } from "@react-navigation/native";

export const navigationLightTheme: Theme = {
  ...NavigationDefaultTheme,
  dark: false,
  colors: {
    ...NavigationDefaultTheme.colors,
    primary: "rgba(0,0,0,1)",
    background: "rgba(255,255,255,1)",
    card: "rgba(255,255,255,1)",
    text: "rgba(0,0,0,1)",
    border: "rgba(255,255,255,1)",
  },
};

export const navigationDarkTheme: Theme = {
  ...NavigationDarkTheme,
  dark: true,
  colors: {
    ...NavigationDarkTheme.colors,
    primary: "rgba(255,255,255,1)",
    background: "rgba(0,0,0,1)",
    card: "rgba(0,0,0,1)",
    text: "rgba(255,255,255,1)",
    border: "rgba(0,0,0,1)",
  },
};
