import React, { FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Routes, navigationRef } from "@app/common/types/navigation.type";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Banner } from "@app/components/common/banner/banner.component";
import { Button } from "@app/components/common/button/button.component";
import { OpenStoreButtons } from "@app/components/common/open-store-buttons/open-store-buttons.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfile } from "@app/hooks/api/use-profile.hook";
import { GeneratedLinkData } from "@app/hooks/utils/deeplink/branch.common";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { headerHeight } from "@app/router/router.style";
import { isDesktop, isIos, isWeb } from "@app/utils/device.util";
import { getReferrerShareId } from "@app/utils/share/share.util";

import { styles } from "./share-banner.style";

interface Props {
  hidden: boolean;
  ogData?: GeneratedLinkData;
  isLikelyEmbedded: boolean;
  onOpenInAppPress: () => void;
}

export const ShareBanner: FC<Props> = ({ hidden, isLikelyEmbedded, onOpenInAppPress }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const [bannerVisible, setBannerVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const shareId = getReferrerShareId(!isWeb && !isIos);
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);

  const shouldDisplay =
    (!isLikelyEmbedded || shareId) &&
    [Routes.product, Routes.profile, Routes.contest, Routes.contestEntries].some(routeName => routeName === currentRoute);

  const { data: referrer } = useProfile({
    variables: { where: { shareId } },
    skip: !shareId,
    onCompleted: () => setBannerVisible(true),
  });

  const handleOnRouteChange = useCallback(() => {
    const route = navigationRef.current?.getCurrentRoute()?.name;

    setCurrentRoute(route ?? null);
  }, []);

  useEffect(() => {
    if (isReady) () => navigationRef.current?.removeListener("state", handleOnRouteChange);

    const isNavReady = navigationRef.current?.isReady();

    if (isNavReady) {
      handleOnRouteChange();
    }
    navigationRef.current?.addListener("state", handleOnRouteChange);

    return () => {
      navigationRef.current?.removeListener("state", handleOnRouteChange);
    };
  }, [handleOnRouteChange, isReady]);

  if (hidden || !referrer || !shareId || !shouldDisplay) return null;

  return (
    <View onLayout={() => setIsReady(true)}>
      {isReady && (
        <Banner visible={bannerVisible} setVisible={setBannerVisible} style={[styles.banner, { height: headerHeight }]}>
          <View style={[styles.root, { backgroundColor: colors.background, width: windowWidth }]}>
            <View style={styles.avatar}>
              <AvatarImage image={referrer.imageUrl} fallbackText={referrer.username.charAt(0)} size={32} />
              <Text variant="h8">{t("share.joinMe")}</Text>
            </View>
            {isDesktop ? (
              <OpenStoreButtons />
            ) : (
              <Button mode="contained" size="small" onPress={onOpenInAppPress}>
                {t("share.openApp")}
              </Button>
            )}
          </View>
        </Banner>
      )}
    </View>
  );
};
