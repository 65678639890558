import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentContainer: {
    columnGap: 0,
  },
  factsContainer: {
    rowGap: 8,
  },
  listItem: {
    padding: 12,
    width: "auto",
  },
  listItemContentContainer: {
    alignItems: "flex-start",
    rowGap: 4,
  },
  root: {
    rowGap: 24,
  },
  statusBadge: {
    borderRadius: 100,
    height: 8,
    width: 8,
  },
  statusContainer: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
  textContainer: {
    paddingLeft: 8,
    rowGap: 4,
  },
});
