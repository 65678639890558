import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { CardPaymentMethod, DeletedPaymentMethod } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  deletePaymentMethod: DeletedPaymentMethod;
}

interface Variables {
  paymentMethodId: string;
}

interface DeletePaymentMethodResult extends MutationResult<Response, "deletePaymentMethod"> {
  deletePaymentMethod: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deletePaymentMethodMutation = gql`
  mutation DeletePaymentMethod($paymentMethodId: String!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId) {
      paymentMethodId
    }
  }
`;

export function useDeletePaymentMethod(): DeletePaymentMethodResult {
  const [deletePaymentMethod, { loading, error, data }] = useMutation<Response, Variables>(deletePaymentMethodMutation, {
    update: (cache, _result, options) => {
      cache.modify({
        fields: {
          paymentMethods: (existingPaymentMethods: CardPaymentMethod[], { readField }) => {
            return existingPaymentMethods.filter(paymentMethod => options.variables?.paymentMethodId !== readField("paymentMethodId", paymentMethod));
          },
        },
      });
    },
  });

  return { deletePaymentMethod, loading, error, data: data?.deletePaymentMethod };
}
