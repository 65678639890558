import React, { FC } from "react";

import { Barricade } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ViewProps } from "react-native";

import { FullScreenError } from "@app/components/common/full-screen-error/full-screen-error.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";

export const Maintenance: FC<ViewProps> = props => {
  const { t } = useTranslation();

  return (
    <ScreenWrapper safeAreaEdges={["left", "right"]} {...props}>
      <FullScreenError title={t("error.maintenance.title")} message={t("error.maintenance.message")} image={Barricade} />
    </ScreenWrapper>
  );
};
