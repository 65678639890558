import React, { ReactNode, JSX } from "react";

import * as Linking from "expo-linking";
import { Gift, GooglePlayLogo, IconProps, Info, MagicWand, ThumbsUp, Trophy } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Theme, useTheme } from "react-native-paper";

import VerifiedBadge from "@app/assets/icons/verified-badge.svg";
import defaultProductImageDark from "@app/assets/images/default-image-dark.png";
import defaultProductImageLight from "@app/assets/images/default-image-light.png";
import AppStoreLogo from "@app/assets/logos/app-store-logo.svg";
import { links } from "@app/common/constants/links.const";
import { AnyPromo, Badge, ContestRewardType, PromoType } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useFeedNavigation } from "@app/common/types/navigation.type";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { GenericCardProps } from "@app/components/common/generic-card/generic-card.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";
import { useConfigContext } from "@app/context/config/config.context";
import { isIos } from "@app/utils/device.util";

import { useOpenStudio } from "./use-open-studio.hook";

type PromoProps = GenericCardProps;

function getMainCtaButton(
  label: string,
  onPress: () => void,
  preview?: boolean,
  icon?: (iconProps: IconProps) => ReactNode,
  theme?: Theme,
): JSX.Element {
  return (
    <Button icon={icon} onPress={preview ? undefined : onPress} mode="contained" size="large" theme={theme}>
      {label}
    </Button>
  );
}

// eslint-disable-next-line complexity
export function usePromoProps(promo: AnyPromo, preview?: boolean): PromoProps {
  const { t } = useTranslation();
  const { dark } = useTheme();
  const navigation = useFeedNavigation();
  const { openImageGeneration } = useOpenStudio();
  const { tokenCosts } = useConfigContext();

  const defaultImage = dark ? defaultProductImageDark : defaultProductImageLight;

  switch (promo.promoType) {
    case PromoType.leaveReview:
      return {
        image: ThumbsUp,
        title: t("promos.leaveReview.title"),
        subtitle: t("promos.leaveReview.subtitle"),
        content: t("promos.leaveReview.text"),
        cta: getMainCtaButton(
          t("promos.leaveReview.ctaLabel"),
          () => void Linking.openURL(isIos ? links.external.appStoreReview : links.external.playStoreReview),
          preview,
          props => (isIos ? <AppStoreLogo {...props} width={24} height={24} /> : <GooglePlayLogo {...props} size={24} weight="fill" />),
        ),
      };
    case PromoType.newArrival: {
      return {
        image: "collab" in promo && promo.collab?.images[0] ? promo.collab.images[0].imageUrl : defaultImage,
        title: t("promos.newArrival.title"),
        subtitle: t("promos.newArrival.subtitle"),
        content: (
          <Box rowGap={4}>
            <Text variant="body2">{"collab" in promo && promo.collab ? promo.collab.name : ""}</Text>
            <ListItem
              title={
                <CreatorName
                  name={"collab" in promo && promo.collab ? promo.collab.creator.displayName : ""}
                  badge={"collab" in promo && promo.collab ? promo.collab.creator.badge : Badge.none}
                  textProps={{ variant: "h8", color: "onBackground" }}
                />
              }
              imageProps={{
                image: "collab" in promo ? promo.collab?.creator.imageUrl : undefined,
                size: 40,
                fallbackText: "collab" in promo ? promo.collab?.creator.displayName.charAt(0) : undefined,
              }}
              onPress={() => navigation.navigate(Routes.profile, { id: "collab" in promo ? promo.collab?.creator.username : undefined })}
            />
          </Box>
        ),
        cta: getMainCtaButton(
          t("promos.newArrival.ctaLabel"),
          () => navigation.navigate(Routes.product, { id: "collab" in promo && promo.collab ? promo.collab.handle : "" }),
          preview,
          props => <Info {...props} weight="fill" />,
        ),
      };
    }
    case PromoType.newContest: {
      const contestIdOrHandle = "contest" in promo && promo.contest ? promo.contest.handle ?? promo.contest.contestId : "";

      return {
        image: "contest" in promo && promo.contest?.imageUrl ? promo.contest.imageUrl : defaultImage,
        title: t("promos.newContest.title"),
        subtitle: "contest" in promo && promo.contest ? promo.contest.name : "",
        content: (
          <Box flexDirection="row" alignItems="center" columnGap={4}>
            {"contest" in promo && promo.contest?.rewardType === ContestRewardType.becomeCreator && <VerifiedBadge width={16} height={16} />}
            {"contest" in promo && promo.contest && (
              <Text variant="body2">{t(`promos.newContest.text.${promo.contest.rewardType}`, { rewardValue: promo.contest.rewardValue })}</Text>
            )}
          </Box>
        ),
        cta: getMainCtaButton(
          t("promos.newContest.mainCtaLabel"),
          () => navigation.navigate(Routes.contest, { id: contestIdOrHandle }),
          preview,
          props => <Info {...props} weight="fill" />,
        ),
        secondaryCta: (
          <Button
            icon={props => <Trophy {...props} weight="fill" />}
            onPress={preview ? undefined : () => navigation.navigate(Routes.contestEntries, { id: contestIdOrHandle })}
            mode="outlined"
            size="large">
            {t("promos.newContest.secondaryCtaLabel")}
          </Button>
        ),
      };
    }
    case PromoType.tokenGift:
      return {
        image: Gift,
        title: t("promos.tokenGift.title"),
        subtitle: t("promos.tokenGift.subtitle", { tokens: tokenCosts?.tokensPerDay }),
        content: t("promos.tokenGift.text"),
        cta: getMainCtaButton(t("promos.tokenGift.ctaLabel"), openImageGeneration, preview, props => <MagicWand {...props} weight="fill" />),
      };
    case PromoType.generic:
    default: {
      return {
        image: promo.imageUrl ?? defaultImage,
        title: promo.title,
        subtitle: promo.subtitle,
        content: promo.text,
        cta: getMainCtaButton(promo.ctaLabel, () => void Linking.openURL(promo.ctaUrl), preview),
      };
    }
  }
}
