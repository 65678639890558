import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  addButtonContainer: {
    paddingVertical: 4,
  },
  root: {
    flex: 1,
    rowGap: 16,
  },
  row: {
    alignItems: "flex-start",
    columnGap: 8,
    flexDirection: "row",
  },
});
