type PositionType = Record<string, number>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrToHash = <T extends Record<string, any>>(arr: T[], id: keyof T): PositionType => {
  return arr.reduce<PositionType>((acc, item, index) => {
    acc[item[id]] = index + 1;
    return acc;
  }, {});
};

export const clamp = (value: number, min: number, max: number): number => {
  "worklet";

  return Math.max(min, Math.min(value, max));
};

export function moveObject(object: PositionType, from: number, to: number): PositionType {
  "worklet";

  const newObject: PositionType = Object.assign({}, object);

  for (const id in object) {
    if (object[id] === from) {
      newObject[id] = to;
    }

    if (object[id] === to) {
      newObject[id] = from;
    }
  }

  return newObject;
}
