import React, { FC } from "react";

import { Play } from "phosphor-react-native";
import { ImageBackground, TouchableOpacity } from "react-native";
import { useTheme } from "react-native-paper";

import { Routes, useHomeNavigation } from "@app/common/types/navigation.type";

import { styles } from "./video-list-item.style";

interface Props {
  videoUrl?: string;
  thumbnailUrl?: string;
  cloudflareId?: string;
  width: number;
}

export const VideoListItem: FC<Props> = ({ videoUrl, thumbnailUrl, cloudflareId, width }) => {
  const navigation = useHomeNavigation();
  const { colors } = useTheme();

  // TODO: use our image component instead of ImageBackground
  return (
    <TouchableOpacity style={{ width }} onPress={() => navigation.navigate(Routes.video, { videoUrl, posterUrl: thumbnailUrl, cloudflareId })}>
      <ImageBackground source={{ uri: thumbnailUrl }} style={styles.imageThumbnail}>
        <Play size={32} color={colors.common.white} weight="fill" />
      </ImageBackground>
    </TouchableOpacity>
  );
};
