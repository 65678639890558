import React, { FC, useCallback, useEffect } from "react";

import { BookmarkSimple } from "phosphor-react-native";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { WithTimingConfig, cancelAnimation, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import { imageRatio } from "@app/common/constants/image.const";
import { StudioImage, StudioImageStatus } from "@app/common/graphql/generated/schema.graphql";
import { lightTheme } from "@app/common/style/theme";
import { StudioImageWithIndex } from "@app/common/types/studio.type";
import { Image } from "@app/components/common/image/image.component";
import { ZoomOnImageButton } from "@app/components/common/zoom-on-image-button/zoom-on-image-button.component";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { imageScreenPadding, imageWidthSeparation } from "../studio-prompt-set/studio-prompt-set.style";

import { styles } from "./studio-image-prompt.style";

const animationConfig: WithTimingConfig = { duration: 350 };

interface Props {
  item: StudioImage;
  onPress: (image: StudioImageWithIndex) => void;
  isSelected: boolean;
  itemSelectedCount: number;
  indexImage: number;
}

export const StudioImagePrompt: FC<Props> = ({ onPress, isSelected, item, itemSelectedCount, indexImage }) => {
  const { colors } = useTheme();
  const { itemWidth } = useResponsiveWidthListItem({ itemGap: imageWidthSeparation, listPadding: imageScreenPadding });

  const imageWidth = useSharedValue(itemWidth);
  const imageHeight = useSharedValue(itemWidth * imageRatio);
  const overlayOpacity = useSharedValue(0);

  const handleCancel = useCallback(() => {
    cancelAnimation(imageWidth);
    cancelAnimation(imageHeight);
    cancelAnimation(overlayOpacity);
  }, [imageHeight, imageWidth, overlayOpacity]);

  const handleOnPress = (): void => {
    onPress({ ...item, index: indexImage });
  };

  const handleGrowItem = useCallback(
    (opacityValue = 0): void => {
      overlayOpacity.value = withTiming(opacityValue, animationConfig);
    },
    [overlayOpacity],
  );

  const handleRegularItem = useCallback(
    (opacityValue = 0): void => {
      overlayOpacity.value = withTiming(opacityValue, animationConfig);
    },
    [overlayOpacity],
  );

  const handleSmallItem = useCallback(
    (opacityValue = 1): void => {
      overlayOpacity.value = withTiming(opacityValue, animationConfig);
    },
    [overlayOpacity],
  );

  useEffect(() => {
    if (isSelected) {
      if (itemSelectedCount === 1) {
        handleGrowItem(0);
      } else {
        handleRegularItem(0);
      }
    } else {
      if (itemSelectedCount === 0) {
        handleRegularItem(0);
      } else if (itemSelectedCount === 1) {
        handleSmallItem(1);
      } else {
        handleRegularItem(1);
      }
    }

    return handleCancel;
  }, [handleCancel, handleGrowItem, handleRegularItem, handleSmallItem, isSelected, itemSelectedCount]);

  const animatedStyle = useAnimatedStyle(() => ({
    width: imageWidth.value,
    height: imageHeight.value,
  }));

  const overlayAnimatedStyle = useAnimatedStyle(() => ({
    opacity: overlayOpacity.value,
    backgroundColor: lightTheme.colors.onSecondaryContainer,
  }));

  return (
    <Animated.View style={animatedStyle}>
      <TouchableOpacity onPress={handleOnPress} style={styles.image} disabled={item.isNsfw}>
        <Image
          source={item.imageUrl}
          disabledResponsiveImageUrl
          style={[styles.image, ...conditionalItem(isSelected, { borderColor: colors.secondary, borderWidth: 2 })]}
        />

        <Animated.View style={[overlayAnimatedStyle, styles.image, StyleSheet.absoluteFill]} />
        {item.status === StudioImageStatus.permanent && <BookmarkSimple weight="fill" color={colors.common.white} style={styles.imageIcon} />}
        {!item.isNsfw && <ZoomOnImageButton style={styles.zoomIcon} imageUrl={item.imageUrl} size={24} />}
      </TouchableOpacity>
    </Animated.View>
  );
};
