import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { MutationPromoteCollabsToConceptOnContestEndArgs, PromotedCollabs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  promoteCollabsToConceptOnContestEnd: PromotedCollabs;
}

interface PromoteCollabsToConceptOnContestEndResult extends MutationResult<Response, "promoteCollabsToConceptOnContestEnd"> {
  promoteCollabsToConceptOnContestEnd: (
    options?: MutationFunctionOptions<Response, MutationPromoteCollabsToConceptOnContestEndArgs>,
  ) => Promise<FetchResult>;
}

const promoteCollabsToConceptOnContestEndMutation = gql`
  mutation PromoteCollabsToConceptOnContestEnd($contestId: ContestId!) {
    promoteCollabsToConceptOnContestEnd(contestId: $contestId) {
      promotedCollabs
    }
  }
`;

export function usePromoteCollabsToConceptOnContestEnd(): PromoteCollabsToConceptOnContestEndResult {
  const [promoteCollabsToConceptOnContestEnd, { loading, error, data }] = useMutation<Response, MutationPromoteCollabsToConceptOnContestEndArgs>(
    promoteCollabsToConceptOnContestEndMutation,
  );

  return { promoteCollabsToConceptOnContestEnd, loading, error, data: data?.promoteCollabsToConceptOnContestEnd };
}
