import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { NewsletterSubscription } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  subscribeToNewsletter: NewsletterSubscription;
}

interface Variables {
  userId: string;
}

interface SubscribeToNewsletterResult extends MutationResult<Response, "subscribeToNewsletter"> {
  subscribeToNewsletter: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const subscribeToNewsletterMutation = gql`
  mutation SubscribeToNewsletterInput($userId: UserId!) {
    subscribeToNewsletter(userId: $userId) {
      email
      userId
    }
  }
`;

export function useSubscribeToNewsletter(): SubscribeToNewsletterResult {
  const [subscribeToNewsletter, { loading, error, data }] = useMutation<Response, Variables>(subscribeToNewsletterMutation);

  return { subscribeToNewsletter, loading, error, data: data?.subscribeToNewsletter };
}
