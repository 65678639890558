import { StyleSheet } from "react-native";

import { supercoinsGradient } from "@app/common/style/common.style";

export const styles = StyleSheet.create({
  root: {
    borderColor: supercoinsGradient[0],
    borderWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  supercoinImage: {
    height: 16,
    width: 16,
  },
});
