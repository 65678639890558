import React, { FC, useState } from "react";

import { EmailAuthProvider, reauthenticateWithCredential, updatePassword, AuthErrorCodes, AuthError } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { ChoosePassword } from "@app/components/login/choose-password/choose-password.component";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";

import { styles } from "./change-password.style";

export const ChangePasswordScreen: FC<ProfileStackScreenProps<Routes.changePassword>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { handleAuthError } = useHandleAuthError();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [invalidOldPassword, setInvalidOldPassword] = useState(false);

  const handleChangeTextOldPassword = (value: string): void => {
    setInvalidOldPassword(false);
    setOldPassword(value);
  };

  const handleConfirm = (): void => {
    setLoading(true);

    getAuthWhenReady()
      .then(auth => {
        const user = auth.currentUser;

        if (!user || !user.email) {
          showErrorSnackbar();
          navigation.goBack();
          return;
        }

        const credential = EmailAuthProvider.credential(user.email, oldPassword);

        reauthenticateWithCredential(user, credential)
          .then(() => {
            updatePassword(user, password)
              .then(() => {
                showSuccessSnackbar();
                navigation.goBack();
                setLoading(false);
              })
              .catch((error: AuthError) => {
                setLoading(false);
                handleAuthError(error, LoginAction.changePassword);
              });
          })
          .catch((e: AuthError) => {
            setLoading(false);

            if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
              setInvalidOldPassword(true);
            } else {
              handleAuthError(e, LoginAction.reauthenticateWithCredential);
            }
          });
      })
      .catch((error: AuthError) => {
        setLoading(false);
        handleAuthError(error, LoginAction.getAuth);
      });
  };

  return (
    <ScreenWrapper style={styles.root}>
      <View style={styles.container}>
        <TextInput
          label={t("login.email.oldPassword")}
          value={oldPassword}
          onChangeText={handleChangeTextOldPassword}
          secureTextEntry={true}
          error={invalidOldPassword}
          errorText={t("login.email.invalidPassword")}
          autoComplete="password"
          selectTextOnFocus
          returnKeyType="next"
        />
        <ChoosePassword
          password={password}
          setPassword={setPassword}
          handleSubmit={handleConfirm}
          loading={loading}
          buttonLabel={t("cta.confirm")}
          disableSubmit={!oldPassword}
        />
      </View>
    </ScreenWrapper>
  );
};
