import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bottomIosHandle: {
    flex: 0,
  },
  root: {
    paddingBottom: 20,
  },
});
