import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  border: {
    borderWidth: 1,
  },
  contentContainer: {
    alignItems: "flex-start",
    columnGap: 8,
    flex: 1,
    rowGap: 8,
  },
  progressContainer: {
    rowGap: 2,
    width: "100%",
  },
  progressLabelContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  recurringContainer: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
  root: {
    padding: 16,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  rowSpaceBetween: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  supercoinImage: {
    height: 16,
    width: 16,
  },
  supercoinsReward: {
    borderRadius: 16,
    borderWidth: 1,
    columnGap: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});
