import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { contestFields } from "@app/common/graphql/fragments.graphql";
import { Contest, CreateContestInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  createContest: Contest;
}

interface Variables {
  input: CreateContestInput;
}

interface CreateContestResult extends MutationResult<Response, "createContest"> {
  createContest: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createContestMutation = gql`
  mutation CreateContest($input: CreateContestInput!) {
    createContest(input: $input) {
      ...ContestFields
    }
  }
  ${contestFields}
`;

export function useCreateContest(): CreateContestResult {
  const [createContest, { loading, error, data }] = useMutation<Response, Variables>(createContestMutation);

  return { createContest, loading, error, data: data?.createContest };
}
