import { StyleSheet } from "react-native";

export const commonStyles = StyleSheet.create({
  sideIconButton: {
    borderRadius: 16,
    height: 48,
    margin: 0,
    width: 48,
  },
});

export const instagramGradient = ["#F8D849", "#EE8131", "#EA336B", "#C128BF", "#6E3BF1"];

export const supercoinsGradient = ["rgba(255,167,38,0.6)", "rgba(255,167,38,0.5)"];
