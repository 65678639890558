import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    flexDirection: "row-reverse",
  },
  root: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
});
