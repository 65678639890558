import { StyleSheet } from "react-native";

import { maxDesktopWidth } from "@app/router/router.style";

export const bottomSheetHorizontalPadding = 20;

export const styles = StyleSheet.create({
  background: {
    borderRadius: 0,
  },
  container: {
    flex: 1,
  },
  contentBottomPadding: {
    paddingBottom: 32,
  },
  contentContainer: {
    paddingHorizontal: bottomSheetHorizontalPadding,
  },
  desktopContainer: {
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: maxDesktopWidth,
    right: 0,
  },
  footerContainer: {
    paddingBottom: 32,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  footerContainerWeb: {
    marginTop: 16,
  },
  handleContainer: {
    alignSelf: "center",
    position: "absolute",
  },
  handleIndicator: {
    height: 4,
    width: 67,
  },
  header: {
    alignItems: "center",
    columnGap: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  headerContainer: {
    justifyContent: "center",
    padding: 8,
    rowGap: 16,
    width: undefined,
  },
  leftHeader: {
    left: 8,
    position: "absolute",
  },
  rightHeader: {
    position: "absolute",
    right: 8,
  },
  subHeader: {
    paddingHorizontal: 12,
  },
  title: {
    paddingVertical: 12,
  },
});
