import { useCallback } from "react";

import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { isWeb } from "@app/utils/device.util";

interface NavigateToWebviewUtils {
  navigateToWebview: (url: string, title?: string) => void;
}

export function useNavigateToWebview(): NavigateToWebviewUtils {
  const navigation = useNavigation();

  const navigateToWebview = useCallback(
    (url: string, title?: string) => {
      if (isWeb) {
        setTimeout(() => window.open(url, "_blank")); // Safari blocks window.open in async threads
      } else {
        navigation.navigate(Routes.webview, { url, title });
      }
    },
    [navigation],
  );

  return { navigateToWebview };
}
