import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
    marginTop: 24,
    rowGap: 16,
    width: "100%",
  },
  item: {
    width: "50%",
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
});
