import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { CatalogProduct, Category } from "@app/common/graphql/generated/schema.graphql";
import { MediaToBeUsed } from "@app/common/types/image.type";
import { mockContextFn } from "@app/utils/context.util";

export interface Suggestion {
  id: string;
  name: string;
  image?: string;
  handle?: string;
  passwordRequired?: boolean;
  category?: Category;
}

export interface StudioInputs {
  collabId?: string;
  /**
   * null or undefined is very different in this case. null means non existent vs undefined would mean in progress to select one
   */
  contest?: Suggestion | null;
  productName?: string;
  productCategory?: Suggestion | null;
  productDescription?: string;
  media: MediaToBeUsed[];
}

export interface StudioMiniUpdatableKey {
  productName?: string;
  productCategory?: Suggestion | null;
  productDescription?: string;
}

export enum GenerationTaskType {
  imageToAnimation = "imageToAnimation",
  imageToImage = "imageToImage",
  original = "original",
  pose = "pose",
  variation = "variation",
}

export interface GenerationInputs {
  taskIds: Record<string, GenerationTaskType> | null;
  catalogProduct?: CatalogProduct;
  image?: MediaToBeUsed;
  prompt?: string;
  initialFlow?: StudioFlowType;
}

export const defaultInputsValue: StudioInputs = {
  media: [],
  contest: null,
  productCategory: null,
};

export const defaultGenerationInputsValue: GenerationInputs = {
  taskIds: null,
  prompt: "",
};

export interface StudioMiniContextType {
  generation: GenerationInputs;
  inputs: StudioInputs;
  resetGeneration: () => void;
  resetInputs: () => void;
  setGeneration: Dispatch<SetStateAction<GenerationInputs>>;
  setInputs: Dispatch<SetStateAction<StudioInputs>>;
  setInputMedia: Dispatch<SetStateAction<MediaToBeUsed[]>>;
  setGenerationImage: Dispatch<SetStateAction<MediaToBeUsed | undefined>>;
  isCurrentlyGenerating: boolean;
  setIsCurrentlyGenerating: (newValue: boolean) => void;
  isCurrentlyGeneratingDescription: boolean;
  setIsCurrentlyGeneratingDescription: (newValue: boolean) => void;
}

export const StudioMiniContext = createContext<StudioMiniContextType>({
  generation: defaultGenerationInputsValue,
  inputs: defaultInputsValue,
  resetGeneration: () => mockContextFn("StudioMiniContext", "resetGeneration"),
  resetInputs: () => mockContextFn("StudioMiniContext", "resetInputs"),
  setGeneration: () => mockContextFn("StudioMiniContext", "setGeneration"),
  setInputs: () => mockContextFn("StudioMiniContext", "setInputs"),
  setInputMedia: () => mockContextFn("StudioMiniContext", "setInputImages"),
  setGenerationImage: () => mockContextFn("StudioMiniContext", "setGenerationImage"),
  isCurrentlyGenerating: false,
  setIsCurrentlyGenerating: () => mockContextFn("StudioMiniContext", "setIsCurrentlyGenerating"),
  isCurrentlyGeneratingDescription: false,
  setIsCurrentlyGeneratingDescription: () => mockContextFn("StudioMiniContext", "setIsCurrentlyGeneratingDescription"),
});

export const useStudioMiniContext = (): StudioMiniContextType => useContext(StudioMiniContext);
