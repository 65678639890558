import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { REACT_APP_GOOGLE_MAPS_API_KEY } from "@env";
import { AddressElement, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-paper";

import { SetShippingAddressInput, ShippingAddress } from "@app/common/graphql/generated/schema.graphql";
import { AlertCta } from "@app/common/types/alert-cta.interface";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component.web";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSetShippingAddress } from "@app/hooks/api/preorder/use-set-shipping-address.hook";
import { useErrorMessage } from "@app/hooks/utils/use-error-message.hook";
import {
  getContextShippingAddressFromAddressSheet,
  getShippingAddressInputFromAddressSheet,
} from "@app/utils/address-converter/address-converter.util.web";

import { styles } from "./address-sheet.style";

interface Props {
  visible: boolean;
  closeSheet: () => void;
  setShippingAddress?: (address: ShippingAddress) => void;
  sheetTitle: string;
  allowedCountries: string[];
}

export const AddressSheet: FC<Props> = ({ visible, closeSheet, setShippingAddress: setShippingAddressInput, sheetTitle, allowedCountries }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { getShippingAddressErrorMessage } = useErrorMessage();
  const sheetRef = useRef<BottomSheetRefProps>(null);

  useEffect(() => {
    if (visible) {
      sheetRef.current?.open();
    } else {
      sheetRef.current?.close();
    }
  }, [visible]);

  const [loadingAddressElement, setLoadingAddressElement] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("error.generic"));
  const [errorCta, setErrorCta] = useState<AlertCta | undefined>();

  const { setShippingAddress, loading: loadingSetShippingAddress } = useSetShippingAddress();
  const elements = useElements();

  const wrappedCloseSheet = useCallback(() => {
    const addressComponent = elements?.getElement("address");
    closeSheet();
    addressComponent?.clear();
    setLoadingSubmit(false);
    setIsAddressComplete(false);
  }, [closeSheet, elements]);

  const showErrorMessage = useCallback(
    (message?: string, cta?: AlertCta) => {
      setErrorMessage(message ?? t("error.generic"));
      setErrorCta(cta);
      setErrorVisible(true);
    },
    [t],
  );

  const handleSubmitAddress = useCallback(async () => {
    setErrorVisible(false);
    setLoadingSubmit(true);

    const addressComponent = elements?.getElement("address");
    if (!addressComponent) {
      setLoadingSubmit(false);
      showErrorMessage();
      return;
    }

    const { complete, value: newAddress } = await addressComponent.getValue();

    if (!complete) {
      setLoadingSubmit(false);
      showErrorMessage(t("preorder.error.missingRequiredField"));
      return;
    }

    const input: SetShippingAddressInput = getShippingAddressInputFromAddressSheet(newAddress);

    if (profile?.userId) {
      await setShippingAddress({
        variables: { userId: profile.userId, input },
        onCompleted: data => {
          setShippingAddressInput?.(data.setShippingAddress);
          wrappedCloseSheet();
        },
        onError: error => {
          setLoadingSubmit(false);

          const { message, cta } = getShippingAddressErrorMessage(error);
          showErrorMessage(message, cta);
        },
      });
    } else {
      const contextShippingAddress = getContextShippingAddressFromAddressSheet(newAddress);
      setShippingAddressInput?.(contextShippingAddress);
      wrappedCloseSheet();
    }
  }, [
    elements,
    profile?.userId,
    showErrorMessage,
    t,
    setShippingAddress,
    setShippingAddressInput,
    wrappedCloseSheet,
    getShippingAddressErrorMessage,
  ]);

  return (
    <BottomSheet
      ref={sheetRef}
      onDismiss={wrappedCloseSheet}
      scrollable
      title={sheetTitle}
      scrollViewProps={{ contentContainerStyle: styles.contentContainer }}
      footer={
        <Button
          mode="contained"
          size="large"
          disabled={!isAddressComplete}
          loading={loadingSubmit || loadingSetShippingAddress}
          containerStyle={styles.buttonContainer}
          onPress={() => void handleSubmitAddress()}>
          {t("cta.saveAddress")}
        </Button>
      }>
      {loadingAddressElement && <ActivityIndicator size="large" />}

      <AddressElement
        options={{
          mode: "shipping",
          autocomplete: { mode: "google_maps_api", apiKey: REACT_APP_GOOGLE_MAPS_API_KEY },
          allowedCountries,
        }}
        onReady={() => setLoadingAddressElement(false)}
        onChange={event => setIsAddressComplete(event.complete)}
      />

      {errorVisible && <EmbeddedAlert variant={AlertVariant.error} text={errorMessage} cta={errorCta} />}
    </BottomSheet>
  );
};
