import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import { Button } from "../button/button.component";
import { ChipList } from "../chip-list/chip-list.component";
import { TextInput, TextInputProps } from "../text-input/text-input.component";

import { styles } from "./text-input-multiple-values.style";

export interface TextInputMultipleValueProps {
  values?: string[];
  maxValues?: number;
  inputProps?: TextInputProps;
  resetValue: () => void;
  setValues: (values: string[]) => void;
}

export const TextInputMultipleValue: FC<TextInputMultipleValueProps> = ({ values = [], maxValues, inputProps, resetValue, setValues }) => {
  const { t } = useTranslation();

  const handleAdd = (): void => {
    const newValue = inputProps?.value?.trim();

    if (newValue) {
      const alreadyExist = values.find(v => v.toLowerCase() === newValue.toLowerCase());
      if (!alreadyExist) setValues([...values, newValue]);
      resetValue();
    }
  };

  const handleDelete = (valueToDelete: string): void => {
    const newValues = values.filter(v => v !== valueToDelete);
    setValues(newValues);
  };

  return (
    <View style={styles.root}>
      <View style={styles.row}>
        <TextInput
          rightContent={
            <Button
              mode="outlined"
              size="large"
              disabled={maxValues === values.length}
              containerStyle={styles.addButtonContainer}
              onPress={handleAdd}>
              {t("cta.add")}
            </Button>
          }
          {...inputProps}
        />
      </View>

      <Divider />

      <ChipList chips={values} onDelete={handleDelete} />
    </View>
  );
};
