import React, { FC, useEffect } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { ArrowLeft, ArrowRight } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { StartCampaignButton } from "@app/components/campaign/start-campaign-button/start-campaign-button.component";
import { StartCampaignInfo } from "@app/components/campaign/start-campaign-info/start-campaign-info.component";
import { Button } from "@app/components/common/button/button.component";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useCollabWithMedia } from "@app/hooks/api/products/use-collab-with-media.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

import { styles } from "./start-campaign.style";

export const StartCampaignScreen: FC<NavigationProps<Routes.startCampaign>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();
  const { colors, dark } = useTheme();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const fullStartCampaignFlowEnabled = isFeatureEnabled(FeatureFlag.fullStartCampaignFlow);
  const collabHandle = route.params?.collabHandle;
  const { data: collab, loading: loadingCollab } = useCollabWithMedia({ variables: { handle: collabHandle ?? "" } });

  const backgroundColorIconButton = "rgba(0,0,0,0.25)";
  const gradients = dark
    ? ["rgba(0,0,0,0.0)", "rgba(0,0,0,0.0)", "rgba(0,0,0,1)"]
    : ["rgba(255,255,255,0.0)", "rgba(255,255,255,0.0)", "rgba(255,255,255,1)"];

  const openCreatorHandbookLink = (): void => {
    navigateToWebview(links.external.creatorHandbook);
  };

  useEffect(() => {
    const cannotStartCampaign = collab && !collab.progress.canStartCampaign;
    const collabNotFound = !collab && !loadingCollab;
    if (cannotStartCampaign || collabNotFound) {
      navigation.goBack();
    }
  }, [collab, loadingCollab, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <IconButton
          icon={ArrowLeft}
          iconColor={colors.common.white}
          onPress={() => navigation.goBack()}
          style={{ backgroundColor: backgroundColorIconButton }}
        />
      ),
    });
  }, [colors.common.white, navigation]);

  return (
    <ScreenWrapper
      safeAreaEdges={["right", "left", "bottom"]}
      withScrollView
      staticContent={
        collab &&
        (fullStartCampaignFlowEnabled ? (
          <Button
            mode="contained"
            size="large"
            icon={ArrowRight}
            contentStyle={styles.buttonContent}
            containerStyle={styles.buttonContainer}
            onPress={() => navigation.navigate(Routes.startCampaignFlow, { collabHandle })}>
            {t("startCampaign.label")}
          </Button>
        ) : (
          <StartCampaignButton
            mode="contained"
            size="large"
            collabId={collab.collabId}
            creatorId={collab.creator.userId}
            progress={collab.progress}
            containerStyle={styles.buttonContainer}
          />
        ))
      }>
      <View>
        <Image source={collab?.thumbnailImage.imageUrl} style={styles.imageContainer} />
        <LinearGradient locations={[0, 0.67, 1]} colors={gradients} style={styles.linearGradient} />
      </View>

      <View style={styles.textContainer}>
        <Text variant="title" textAlign="center">
          {t("startCampaign.dialog.title.canStartCampaign")}
        </Text>

        {collab && (
          <StartCampaignInfo
            price={collab.formattedPrice}
            compareAtPrice={collab.formattedCompareAtPrice}
            orderCountNeeded={collab.campaign?.orderCountNeeded}
          />
        )}

        {!fullStartCampaignFlowEnabled && (
          <Trans
            i18nKey="startCampaign.dialog.explanation.canStartCampaign.viewCreatorHandbook"
            parent={({ children }: ChildrenProp) => (
              <Text variant="caption" textAlign="center">
                {children}
              </Text>
            )}
            components={{
              chLink: (
                <Text variant="caption" textAlign="center" textDecorationLine="underline" onPress={openCreatorHandbookLink}>
                  <></>
                </Text>
              ),
            }}
          />
        )}
        <Text variant="h6" textAlign="center">
          {t("startCampaign.dialog.explanation.canStartCampaign.ready")}
        </Text>
      </View>
    </ScreenWrapper>
  );
};
