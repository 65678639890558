import React, { FC } from "react";

import * as Clipboard from "expo-clipboard";
import { Copy, MagicWand } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useStudioNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { Button } from "@app/components/common/button/button.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

import { styles } from "./studio-image-main-action-buttons.style";

interface Props {
  image?: StudioImage;
  loading: boolean;
}

export const StudioImageMainActionButtons: FC<Props> = ({ image, loading }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useStudioNavigation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { setGeneration, generation } = useStudioMiniContext();

  const handleCopyPrompt = (): void => {
    if (!image?.prompt) return;
    Clipboard.setStringAsync(image.prompt)
      .then(() => showSuccessSnackbar({ message: t("studioMini.saved.promptCopied") }))
      .catch(() => showErrorSnackbar({ message: t("error.generic") }));
  };

  const handleUsePrompt = (): void => {
    if (!image?.prompt) return;

    if (generation.taskIds) {
      Alert.alert(t("error.warning"), t("studioMini.warningMessage"), [
        { text: t("cta.cancel"), style: "cancel" },
        {
          style: "destructive",
          text: t("cta.yes"),
          onPress: () => {
            setGeneration(prev => ({ ...prev, prompt: image.prompt, taskIds: null }));
            navigation.navigate(Routes.studioMiniGeneration);
          },
        },
      ]);
    } else {
      setGeneration(prev => ({ ...prev, prompt: image.prompt, taskIds: null }));
      navigation.navigate(Routes.studioMiniGeneration);
    }
  };

  return (
    <View style={[{ backgroundColor: colors.tertiaryContainer }, styles.root]}>
      <Button fullWidth icon={MagicWand} mode="contained" onPress={handleUsePrompt} size="large" loading={loading}>
        {t("studioMini.saved.usePrompt")}
      </Button>
      <Button fullWidth icon={Copy} mode="outlined" onPress={handleCopyPrompt} size="large" loading={loading}>
        {t("studioMini.saved.copyPrompt")}
      </Button>
    </View>
  );
};
