import React, { FC, useCallback, useState } from "react";

import * as Clipboard from "expo-clipboard";
import {
  AuthCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  UserCredential,
  linkWithCredential,
  AuthError,
} from "firebase/auth";
import { ArrowRight, CopySimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButtonProps, useTheme } from "react-native-paper";

import AppleIcon from "@app/assets/logos/apple-logo.svg";
import FacebookIcon from "@app/assets/logos/facebook-logo.svg";
import GoogleIcon from "@app/assets/logos/google-logo.svg";
import { EmailType } from "@app/common/enums/email-type.enum";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { NestedBottomSheet } from "@app/components/common/bottom-sheet/nested-bottom-sheet/nested-bottom-sheet.component";
import { Button } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Text } from "@app/components/common/text/text.component";
import { AppleButton } from "@app/components/login/apple-button/apple-button.component";
import { EnterPasswordFields } from "@app/components/login/enter-password-fields/enter-password-fields.component";
import { FacebookButton } from "@app/components/login/facebook-button/facebook-button.component";
import { GoogleButton } from "@app/components/login/google-button/google-button.component";
import { useBranchContext } from "@app/context/branch/branch.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";
import { isAndroid, isIos, isWeb } from "@app/utils/device.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { styles as loginStyles } from "../login/login.style";
import { LoginStep } from "../login-bottom-sheet/login-bottom-sheet.component";

import { styles } from "./link-login-providers-prompt.style";

interface Props {
  sheetRef: React.RefObject<BottomSheetRefProps>;
  email: string;
  newSignInMethod: string;
  existingSignInMethods: string[];
  pendingCredential?: AuthCredential;
  setStep: (newStep: LoginStep) => void;
  setEmail: (newEmail: string) => void;
  setEmailType: (newEmailType: EmailType) => void;
}

export const LinkLoginProvidersPrompt: FC<Props> = ({
  sheetRef,
  email,
  newSignInMethod,
  existingSignInMethods,
  pendingCredential,
  setStep,
  setEmail,
  setEmailType,
}) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { handleAuthError } = useHandleAuthError();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { generateDeepLink } = useBranchContext();

  const [nestedContentVisible, setNestedContentVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const isEmbedded = isLikelyInEmbeddedWebview();

  const hasAppleAccount = newSignInMethod !== "apple.com" && existingSignInMethods.includes("apple.com");
  const hasGoogleAccount =
    newSignInMethod !== GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD && existingSignInMethods.includes(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD);
  const hasFacebookAccount =
    newSignInMethod !== FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD && existingSignInMethods.includes(FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD);
  const hadEmailAccount =
    newSignInMethod !== EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD &&
    existingSignInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD);

  const handleLinkAccount = useCallback(
    (existingCredential: UserCredential): void => {
      sheetRef.current?.close();

      if (!pendingCredential) return;

      linkWithCredential(existingCredential.user, pendingCredential)
        .then((newUserCredential: UserCredential) => {
          if (!newUserCredential.user.emailVerified) {
            setEmail(newUserCredential.user.email ?? "");
            setEmailType(EmailType.verifyEmail);
            setStep(LoginStep.checkYourEmail);
          }
        })
        .catch((error: AuthError) => {
          handleAuthError(error, LoginAction.linkWithCredential);
        });
    },
    [handleAuthError, pendingCredential, setEmail, setEmailType, setStep, sheetRef],
  );

  const handleOpenInExternalBrowser = (): void => {
    if (!isWeb) return;

    const currentUrl = window.location.href;
    window.open(`intent:${currentUrl}#Intent;end`, "_blank");
  };

  const handleCopyLink = (): void => {
    if (!isWeb) return;
    setLoading(true);

    // TODO: Check if we could also pass the location search
    generateDeepLink(window.location.pathname)
      .then(Clipboard.setStringAsync)
      .then(() => showSuccessSnackbar({ message: t("success.linkCopied") }))
      .catch((error: Error) => showErrorSnackbar({ error }))
      .finally(() => setLoading(false));
  };

  const commonSSOLoginProviderProps: Pick<IconButtonProps, "style"> = {
    style: [loginStyles.ssoShareButton, { backgroundColor: colors.primary, borderRadius: roundness }],
  };

  return (
    <NestedBottomSheet
      ref={sheetRef}
      title={t("login.linkingProviders.accountExists")}
      nestedContentVisible={nestedContentVisible}
      hideNestedContent={() => setNestedContentVisible(false)}
      nestedContent={{
        title: t("login.email.signInButton"),
        content: (
          <EnterPasswordFields
            email={email}
            buttonLabel={t("login.linkingProviders.signInAndLink")}
            onSignInSuccess={handleLinkAccount}
            onVerifyEmail={() => undefined}
          />
        ),
      }}>
      {isEmbedded ? (
        <View style={styles.embeddedContainer}>
          <View style={styles.ssoIconContainer}>
            {hasAppleAccount && <AppleIcon height={24} width={24} color={colors.primary} />}
            {hasGoogleAccount && <GoogleIcon height={24} width={24} />}
            {hasFacebookAccount && <FacebookIcon height={24} width={24} />}
          </View>
          <EmbeddedAlert
            variant={AlertVariant.info}
            text={t("inAppBrowser.alertLink", { context: isIos ? "ios" : isAndroid ? "android" : "" })}
            style={styles.embeddedAlert}
          />

          {isAndroid && (
            <Button
              mode="contained"
              size="large"
              icon={ArrowRight}
              onPress={handleOpenInExternalBrowser}
              contentStyle={styles.reversedButton}
              fullWidth>
              {t("inAppBrowser.cta", { context: "android" })}
            </Button>
          )}
          {isIos && (
            <Button mode="outlined" size="large" icon={CopySimple} loading={loading} onPress={handleCopyLink} fullWidth>
              {t("inAppBrowser.cta", { context: "ios" })}
            </Button>
          )}
        </View>
      ) : (
        <View style={styles.buttonContainer}>
          <Text variant="subtitle2" textAlign="center">
            {t("login.linkingProviders.signInAgain")}
          </Text>

          <View style={styles.iconButtonsContainer}>
            {hasAppleAccount && <AppleButton {...commonSSOLoginProviderProps} onSignInSuccess={handleLinkAccount} />}

            {hasGoogleAccount && <GoogleButton {...commonSSOLoginProviderProps} onSignInSuccess={handleLinkAccount} />}

            {hasFacebookAccount && (
              <FacebookButton
                {...commonSSOLoginProviderProps}
                onSignInSuccess={handleLinkAccount}
                goToCheckEmail={(newEmail: string) => {
                  setEmail(newEmail);
                  setEmailType(EmailType.verifyEmail);
                  setStep(LoginStep.checkYourEmail);
                  sheetRef.current?.close();
                }}
              />
            )}
          </View>

          {hadEmailAccount && (
            <Button fullWidth size="large" mode="contained" onPress={() => setNestedContentVisible(true)}>
              {t("login.email.signInButton")}
            </Button>
          )}
        </View>
      )}
    </NestedBottomSheet>
  );
};
