import { MD3Type } from "react-native-paper/lib/typescript/types";

export type CustomVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "h7"
  | "h8"
  | "h9"
  | "title"
  | "body1"
  | "body2"
  | "subtitle1"
  | "subtitle2"
  | "buttonLarge"
  | "buttonMedium"
  | "buttonSmall"
  | "caption"
  | "overline"
  | "avatarLetter"
  | "inputLabel"
  | "helperText"
  | "inputText"
  | "tooltip"
  | "badgeLabel";

export const customFonts: Record<CustomVariants, MD3Type> = {
  h1: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 96,
    letterSpacing: -1.5,
    lineHeight: 112,
  },
  h2: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 60,
    letterSpacing: -0.5,
    lineHeight: 72,
  },
  h3: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 48,
    letterSpacing: 0,
    lineHeight: 56,
  },
  h4: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 34,
    letterSpacing: 0.25,
    lineHeight: 40,
  },
  h5: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 24,
    letterSpacing: 0,
    lineHeight: 32,
  },
  h6: {
    fontFamily: "PPNeueMontreal_700Bold",
    fontWeight: "700",
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: 32,
  },
  h7: {
    fontFamily: "PPNeueMontreal_600SemiBold",
    fontWeight: "600",
    fontSize: 18,
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  h8: {
    fontFamily: "PPNeueMontreal_600SemiBold",
    fontWeight: "600",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: 20,
  },
  h9: {
    fontFamily: "PPNeueMontreal_600SemiBold",
    fontWeight: "600",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 16,
  },
  title: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 28,
    letterSpacing: -0.75,
    lineHeight: 32,
  },
  body1: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  body2: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 20,
  },
  subtitle1: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  subtitle2: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: 24,
  },
  buttonLarge: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 15,
    letterSpacing: 0.46,
    lineHeight: 24,
  },
  buttonMedium: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: 24,
  },
  buttonSmall: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 13,
    letterSpacing: 0.46,
    lineHeight: 24,
  },
  caption: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: 16,
  },
  overline: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: 24,
  },
  avatarLetter: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 20,
    letterSpacing: 0.14,
    lineHeight: 20,
  },
  inputLabel: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: 16,
  },
  helperText: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: 20,
  },
  inputText: {
    fontFamily: "PPNeueMontreal_400Regular",
    fontWeight: "400",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  tooltip: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: 16,
  },
  badgeLabel: {
    fontFamily: "PPNeueMontreal_500Medium",
    fontWeight: "500",
    fontSize: 12,
    letterSpacing: 0.14,
    lineHeight: 20,
  },
};
