import React, { FC } from "react";

import { FallbackComponentProps } from "react-native-error-boundary";

import { ErrorFallbackProps } from "./error-fallback.component";

export function withSentryId(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: FC<ErrorFallbackProps>,
  sentryId: string,
): FC<FallbackComponentProps> {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithSentryId: FC<FallbackComponentProps> = props => <WrappedComponent {...props} sentryId={sentryId} />;

  ComponentWithSentryId.displayName = `withSentryId(${displayName})`;

  return ComponentWithSentryId;
}
