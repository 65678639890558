import { FetchResult, gql } from "@apollo/client";
import type { MutationHookOptions } from "@apollo/client/react/types/types";

import { genericPromoFields, standardPromoFieldsWithoutCollab } from "@app/common/graphql/fragments.graphql";
import { AnyPromo } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { useMutationWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { AnyPromoFromHook, mapPromoToHookType, mapPromoToRealType } from "@app/utils/promo.util";

interface InternalResponse {
  markPromoAsSeen: AnyPromoFromHook;
}

interface Response {
  markPromoAsSeen: AnyPromo;
}

interface Variables {
  promoId: string;
  userId: string;
}

interface MarkPromoAsSeenResult extends MutationResult<Response, "markPromoAsSeen"> {
  markPromoAsSeen: (options?: MutationHookOptions<Response, Variables>) => Promise<FetchResult>;
}

export const markPromoAsSeenMutation = gql`
  mutation MarkPromoAsSeen($promoId: PromoId!, $userId: UserId!) {
    markPromoAsSeen: newMarkPromoAsSeen(promoId: $promoId, userId: $userId) {
      ... on GenericPromo {
        ...GenericPromoFields
      }
      ... on StandardPromo {
        ...StandardPromoWithoutCollabFields
      }
    }
  }
  ${genericPromoFields}
  ${standardPromoFieldsWithoutCollab}
`;

export function useMarkPromoAsSeen(): MarkPromoAsSeenResult {
  const [markPromoAsSeen, { loading, error, data }] = useMutationWithTransformation<Response, InternalResponse, Variables, "markPromoAsSeen">(
    markPromoAsSeenMutation,
    "markPromoAsSeen",
    mapPromoToHookType,
  );

  return { markPromoAsSeen, loading, error, data: data ? mapPromoToRealType(data.markPromoAsSeen) : undefined };
}
