import React, { FC, useCallback, useMemo } from "react";

import { Book, ClockCountdown, Lightbulb, MegaphoneSimple, ShareNetwork } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { useTheme } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Checkbox } from "@app/components/common/checkbox/checkbox.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./start-campaign-facts.style";

interface Props {
  factsRead: boolean;
  setFactsRead: (read: boolean) => void;
}

export const StartCampaignFacts: FC<Props> = ({ factsRead, setFactsRead }) => {
  const { t } = useTranslation("common", { keyPrefix: "startCampaign" });
  const { colors, roundness } = useTheme();
  const { navigateToWebview } = useNavigateToWebview();

  const openCreatorHandbookLink = useCallback((): void => {
    navigateToWebview(links.external.creatorHandbook);
  }, [navigateToWebview]);

  const startCampaignInfos = useMemo(
    () => [
      {
        title: t("facts.0"),
        icon: <ShareNetwork size={18} color={colors.tertiary} />,
      },
      {
        title: t("facts.1"),
        icon: <ClockCountdown size={18} color={colors.tertiary} />,
      },
      {
        title: t("facts.2"),
        icon: <MegaphoneSimple size={18} color={colors.tertiary} />,
      },
      {
        title: t("facts.3"),
        icon: <Lightbulb size={18} color={colors.tertiary} />,
      },
      {
        title: (
          <Trans
            i18nKey="startCampaign.facts.creatorHandbook"
            parent={({ children }: ChildrenProp) => (
              <Text variant="body2" textAlign="center">
                {children}
              </Text>
            )}
            components={{
              chLink: (
                <Text variant="body2" textAlign="center" textDecorationLine="underline" onPress={openCreatorHandbookLink}>
                  <></>
                </Text>
              ),
            }}
          />
        ),
        icon: <Book size={18} color={colors.tertiary} />,
      },
    ],
    [colors.tertiary, openCreatorHandbookLink, t],
  );

  return (
    <View style={styles.root}>
      <FlatList
        data={startCampaignInfos}
        renderItem={({ item }) => (
          <ListItem
            title={item.title}
            titleProps={{ variant: "body2", numberOfLines: 0 }}
            left={item.icon}
            contentContainerStyle={styles.listItemContentContainer}
            style={[{ backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, styles.listItem]}
          />
        )}
        scrollEnabled={isWeb}
        contentContainerStyle={styles.factsContainer}
      />
      <ListItem
        left={<Checkbox status={factsRead ? "checked" : "unchecked"} onPress={() => setFactsRead(!factsRead)} />}
        title={t("facts.checkbox")}
        titleProps={{ variant: "body2", numberOfLines: 0 }}
      />
    </View>
  );
};
