import React, { FC, useMemo } from "react";

import LottieView from "lottie-react-native";
import { FlagCheckered } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import { useTheme } from "react-native-paper";

import ShareIcon from "@app/assets/icons/share-fill.svg";
import checkboxDarkAnimation from "@app/assets/lotties/checkbox-dark.json";
import checkboxLightAnimation from "@app/assets/lotties/checkbox-light.json";
import { maxContestSubmissionsAllowed } from "@app/common/constants/contests.const";
import { links } from "@app/common/constants/links.const";
import { campaignDurationInDays } from "@app/common/constants/products.const";
import { CollabSuccessType } from "@app/common/enums/collab-success-type.enum";
import { CollabImage, ContestRewardType } from "@app/common/graphql/generated/schema.graphql";
import { transparent } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Button } from "@app/components/common/button/button.component";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { ShareToInstagramStoryButton } from "@app/components/common/share-to-instagram-story-button/share-to-instagram-story-button.component";
import { StepItemProps } from "@app/components/common/stepper/step-item/step-item.component";
import { Stepper } from "@app/components/common/stepper/stepper.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

import { styles } from "./collab-success.style";

interface Props {
  successType: CollabSuccessType;
  collabId: string;
  handle?: string;
  image?: CollabImage;
  contestReward?: {
    type: ContestRewardType;
    value?: string;
  };
}

export const CollabSuccess: FC<Props> = ({ successType, collabId, contestReward, handle, image }) => {
  const { t } = useTranslation();
  const { dark } = useTheme();

  const { profile } = useProfileContext();
  const { config } = useConfigContext();
  const { navigateToWebview } = useNavigateToWebview();

  const checkboxAnimation = dark ? checkboxDarkAnimation : checkboxLightAnimation;
  const likeCountObjective = config?.likeCountObjective;
  const contestEntrySubmitted = successType === CollabSuccessType.contestEntrySubmitted;
  const campaignStarted = successType === CollabSuccessType.campaignStarted;

  const translationKey = useMemo((): "startCampaign.success" | "studioMini.conceptSubmitted" | "studioMini.contestEntrySubmitted" => {
    switch (successType) {
      case CollabSuccessType.campaignStarted:
        return "startCampaign.success";
      case CollabSuccessType.conceptSubmitted:
        return "studioMini.conceptSubmitted";
      case CollabSuccessType.contestEntrySubmitted:
        return "studioMini.contestEntrySubmitted";
      default:
        return "startCampaign.success";
    }
  }, [successType]);

  const steps: StepItemProps[] = [
    { isChecked: true, label: t(`${translationKey}.steps.0`) },
    {
      isChecked: campaignStarted,
      label: t(`${translationKey}.steps.1`, { likeCountObjective }),
      icon: !campaignStarted ? () => <LottieView source={checkboxAnimation} autoPlay loop style={styles.animatedCheckbox} /> : undefined,
    },
    {
      isChecked: false,
      label: t(`${translationKey}.steps.2`, { campaignDurationInDays }),
      icon: contestEntrySubmitted
        ? FlagCheckered
        : campaignStarted
        ? () => <LottieView source={checkboxAnimation} autoPlay loop style={styles.animatedCheckbox} />
        : undefined,
    },
    ...(translationKey !== "studioMini.contestEntrySubmitted"
      ? [{ isChecked: false, label: t(`${translationKey}.steps.3`), icon: FlagCheckered }]
      : []),
  ];

  return (
    <View style={[styles.root, { backgroundColor: transparent }]}>
      <ScrollView contentContainerStyle={styles.mainContent}>
        <View style={styles.textHeader}>
          <Text variant="h3" textAlign="center">
            {t(`${translationKey}.title`)}
          </Text>
          <Text variant="h7" textAlign="center">
            {t(`${translationKey}.subtitle`, {
              likeCountObjective,
              prize:
                contestEntrySubmitted && contestReward
                  ? t(`enum.contestRewardType.${contestReward.type}`, { value: contestReward.value })
                  : undefined,
            })}
          </Text>
        </View>

        <Stepper steps={steps} />

        <Trans
          i18nKey={`${translationKey}.caption`}
          context={contestEntrySubmitted ? "contest" : undefined}
          values={{ maxContestSubmissionsAllowed, likeCountObjective }}
          parent={({ children }: ChildrenProp) => (
            <Text variant="body2" color="tertiary" textAlign="center">
              {children}
            </Text>
          )}
          components={{
            chLink: (
              <Text variant="body2" color="tertiary" textDecorationLine="underline" onPress={() => navigateToWebview(links.external.creatorHandbook)}>
                <></>
              </Text>
            ),
          }}
        />
      </ScrollView>

      <View style={[styles.buttonWrapper, styles.buttonContainer]}>
        <ShareButton
          entityId={collabId}
          prettyId={handle}
          entityType="collab"
          image={image}
          buttonComponent={({ onPress }) => (
            <Button
              fullWidth
              mode="contained"
              size="large"
              icon={({ size, ...iconProps }) => <ShareIcon width={size} height={size} {...iconProps} />}
              onPress={onPress}>
              {t(`${translationKey}.cta`)}
            </Button>
          )}
        />
        <ShareToInstagramStoryButton entityId={collabId} handle={handle} image={image} shareId={profile?.shareId} />
      </View>
    </View>
  );
};
