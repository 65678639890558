import { LazyQueryExecFunction, gql, useLazyQuery } from "@apollo/client";

import { commentFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedComments } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  comments: PaginatedComments;
}

interface Variables {
  first?: number;
  offset?: number;
  collabUpdateId: string;
}

interface CollabUpdateCommentsResult extends QueryResult<Response, "comments"> {
  getCollabUpdateComments: LazyQueryExecFunction<Response, Variables>;
}

const collabUpdateCommentsQuery = gql`
  query CollabUpdateComments($collabUpdateId: CollabUpdateId!, $first: Int, $offset: Int) {
    comments: collabUpdateComments(collabUpdateId: $collabUpdateId, first: $first, offset: $offset) {
      nodes {
        ...CommentFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${commentFields}
`;

export function useCollabUpdateComments(): CollabUpdateCommentsResult {
  const [getCollabUpdateComments, { loading, error, data, networkStatus, fetchMore }] = useLazyQuery<Response, Variables>(collabUpdateCommentsQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return { getCollabUpdateComments, loading, error, data: data?.comments, networkStatus, fetchMore };
}
