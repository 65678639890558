import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabUpdateFields } from "@app/common/graphql/fragments.graphql";
import { CollabUpdate, UpdateCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCollabUpdate: CollabUpdate;
}

interface Variables {
  input: UpdateCollabUpdateInput;
  collabUpdateId: string;
}

interface UpdateCollabResult extends MutationResult<Response, "updateCollabUpdate"> {
  updateCollabUpdate: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCollabMutation = gql`
  mutation UpdateCollabUpdate($collabUpdateId: CollabUpdateId!, $input: UpdateCollabUpdateInput!) {
    updateCollabUpdate(collabUpdateId: $collabUpdateId, input: $input) {
      ...CollabUpdateFields
    }
  }
  ${collabUpdateFields}
`;

export function useUpdateCollabUpdate(): UpdateCollabResult {
  const [updateCollabUpdate, { loading, error, data }] = useMutation<Response, Variables>(updateCollabMutation);

  return { updateCollabUpdate, loading, error, data: data?.updateCollabUpdate };
}
