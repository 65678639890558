import React, { FC, useCallback, useRef, useState } from "react";

import { DotsThreeVertical, PencilSimpleLine, Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { ShippingAddress as ApiShippingAddress } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { TextProps } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { removeFalsyValues } from "@app/utils/array.util";

import { AddressSheet } from "../address-sheet/address-sheet.component";

import { styles } from "./shipping-address.style";

interface Props {
  shippingAddress?: ApiShippingAddress;
  setShippingAddress?: (address: ApiShippingAddress) => void;
  onReservePage?: boolean;
  disabled?: boolean;
}

export const ShippingAddress: FC<Props> = ({ shippingAddress, setShippingAddress, onReservePage, disabled }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { config, loading: loadingConfig } = useConfigContext();

  const textProps: Omit<TextProps, "children"> = { variant: "body2", color: onReservePage ? "onSecondary" : "onBackground" };

  const moreMenuSheetRef = useRef<BottomSheetRefProps>(null);
  const [addressSheetVisible, setAddressSheetVisible] = useState(false);
  const [addressSheetTitle, setAddressSheetTitle] = useState(t("preorder.addressSheetTitleAdd"));

  const openAddressSheet = useCallback((sheetTitle: string) => {
    setAddressSheetTitle(sheetTitle);
    setAddressSheetVisible(true);
    moreMenuSheetRef.current?.close();
  }, []);

  const subtitleItems = removeFalsyValues([shippingAddress?.line1, shippingAddress?.line2]);
  const descriptionItems = removeFalsyValues([shippingAddress?.city, shippingAddress?.state, shippingAddress?.postalCode, shippingAddress?.country]);

  return (
    <>
      {shippingAddress ? (
        <ListItem
          title={shippingAddress.name}
          titleProps={textProps}
          subtitle={subtitleItems.length > 0 ? subtitleItems.join(" ") : undefined}
          subtitleProps={textProps}
          description={descriptionItems.length > 0 ? descriptionItems.join(", ") : undefined}
          descriptionProps={textProps}
          right={disabled ? null : <DotsThreeVertical color={onReservePage ? colors.onSecondary : colors.onBackground} size={32} />}
          onPress={disabled ? undefined : () => moreMenuSheetRef.current?.open()}
          style={[
            {
              backgroundColor: onReservePage ? colors.secondary : undefined,
              borderColor: onReservePage ? colors.secondary : colors.outline,
              borderRadius: roundness,
            },
            styles.customerData,
          ]}
        />
      ) : (
        <Button icon={Plus} mode="contained" size="large" onPress={() => openAddressSheet(t("preorder.addressSheetTitleAdd"))}>
          {t("preorder.addShippingAddress")}
        </Button>
      )}

      <BottomSheet ref={moreMenuSheetRef} contentContainerStyle={styles.editBottomSheetContentContainer}>
        {loadingConfig ? (
          <ActivityIndicator size="small" />
        ) : (
          <ListItem
            title={t("cta.edit")}
            titleProps={{ variant: "subtitle1" }}
            subtitle={onReservePage ? t("preorder.reservePageShippingAddressDisclaimer") : t("preorder.shippingAddressDisclaimer")}
            subtitleProps={{ variant: "caption" }}
            left={<PencilSimpleLine size={24} color={colors.onBackground} style={styles.editIcon} />}
            onPress={() => openAddressSheet(t("preorder.addressSheetTitleEdit"))}
          />
        )}
      </BottomSheet>

      {config && (
        <AddressSheet
          visible={addressSheetVisible}
          closeSheet={() => {
            setAddressSheetVisible(false);
            moreMenuSheetRef.current?.close();
          }}
          setShippingAddress={setShippingAddress}
          sheetTitle={addressSheetTitle}
          allowedCountries={config.allowedShippingCountries}
        />
      )}
    </>
  );
};
