import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentSheet: {
    marginTop: 16,
    rowGap: 16,
  },
  hiddenView: {
    display: "none",
  },
  productInfoContent: {
    rowGap: 8,
  },
});
