import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { paymentMethodFields } from "@app/common/graphql/fragments.graphql";
import { CardPaymentMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  paymentMethods: CardPaymentMethod[];
}

interface PaymentMethodsVariables {
  userId?: string;
}

export const paymentMethodsQuery = gql`
  query PaymentMethods($userId: UserId!) {
    paymentMethods(userId: $userId) {
      ...PaymentMethodFields
    }
  }
  ${paymentMethodFields}
`;

export function usePaymentMethods(options: QueryHookOptions<Response, PaymentMethodsVariables>): QueryResult<Response, "paymentMethods"> {
  const { data, loading, error, refetch } = useQuery<Response, PaymentMethodsVariables>(paymentMethodsQuery, {
    skip: !options.variables?.userId,
    ...options,
  });

  return { data: data?.paymentMethods, loading, error, refetch };
}
