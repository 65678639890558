import { CampaignVideo, StudioAnimation } from "../graphql/generated/schema.graphql";

export interface ImageToBeUsed {
  imageId: string;
  imageUrl: string;
  imageName?: string | null;
  index?: number;
  imageSource?: "upload" | "saved";
}

export interface AnimationToBeUsed extends Omit<StudioAnimation, "studioAnimationStatus" | "videoStatus" | "userId"> {
  videoName?: string | null;
  index?: number;
  videoSource?: "upload" | "saved";
}

export interface CampaignVideoToBeUsed extends CampaignVideo {
  videoName?: string | null;
  index?: number;
  videoSource?: "upload" | "saved";
}

export type MediaToBeUsed = ImageToBeUsed | AnimationToBeUsed | CampaignVideoToBeUsed;

export function isImageToBeUsed(media?: MediaToBeUsed): media is ImageToBeUsed {
  return !!media && "imageId" in media;
}

export function isAnimationToBeUsed(media: MediaToBeUsed): media is AnimationToBeUsed {
  return "studioAnimationId" in media;
}

export function isCampaignVideoToBeUsed(media: MediaToBeUsed): media is CampaignVideoToBeUsed {
  return "campaignVideoId" in media;
}

export function getMediaToBeUsedId(media: MediaToBeUsed): string {
  if (isImageToBeUsed(media)) return media.imageId;
  if (isAnimationToBeUsed(media)) return media.studioAnimationId;
  return media.campaignVideoId;
}

export function getMediaToBeUsedImageUrl(media: MediaToBeUsed): string | undefined {
  if (isImageToBeUsed(media)) return media.imageUrl;
  return media.thumbnailUrl;
}
