import React, { FC, useCallback, useMemo, useState } from "react";

import { Bell, EnvelopeSimple, Notification } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem } from "react-native";
import { ActivityIndicator, Divider, Switch, useTheme } from "react-native-paper";

import { PushNotificationsUserSettings } from "@app/common/graphql/generated/schema.graphql";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { usePushNotificationPermissionContext } from "@app/context/push-notifications/permission/push-notifications-permission.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { usePushNotificationUserSettings } from "@app/hooks/api/profile/use-push-notification-settings.hook";
import { useSetPushNotificationSettings } from "@app/hooks/api/profile/use-set-push-notification-settings.hook";
import { useSubscribeToNewsletter } from "@app/hooks/api/use-subscribe-to-newsletter.hook";

import { styles } from "./notification-settings.style";

interface Option {
  title: string;
  description?: string;
  pushKey?: keyof PushNotificationsUserSettings;
  email?: boolean;
}

export const NotificationSettingsScreen: FC<ProfileStackScreenProps<Routes.notificationSettings>> = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { canRequestPermission, requestPermission, canSendNotification } = usePushNotificationPermissionContext();

  const { subscribeToNewsletter, loading: subscribeLoading } = useSubscribeToNewsletter();
  const { data, loading } = usePushNotificationUserSettings({ variables: { userId: profile?.userId ?? "" } });
  const { setPushNotificationSettings } = useSetPushNotificationSettings();

  const [subscribedSuccessful, setSubscribeSuccessful] = useState(false);

  const handlePushNotificationChange = useCallback(
    (key?: keyof PushNotificationsUserSettings): void => {
      if (!profile?.userId || !key || !data) return;
      void setPushNotificationSettings({ variables: { userId: profile?.userId, input: { [key]: !data[key] } } });
    },
    [data, profile?.userId, setPushNotificationSettings],
  );

  const handleSubscribeToNewsletter = useCallback(() => {
    if (!profile) return;

    void subscribeToNewsletter({
      variables: { userId: profile.userId },
      onCompleted: () => setSubscribeSuccessful(true),
      onError: () => showErrorSnackbar(),
    });
  }, [profile, showErrorSnackbar, subscribeToNewsletter]);

  const options = useMemo<Option[]>(
    () => [
      {
        title: t("settings.notifications.settings.appUpdates.title"),
        description: t("settings.notifications.settings.appUpdates.description"),
        email: true,
      },
      {
        title: t("settings.notifications.settings.collabProgress.title"),
        description: t("settings.notifications.settings.collabProgress.description"),
        pushKey: "collabProgress",
      },
      {
        title: t("settings.notifications.settings.contestUpdates.title"),
        description: t("settings.notifications.settings.contestUpdates.description"),
        pushKey: "contestUpdates",
      },
      {
        title: t("settings.notifications.settings.likes.title"),
        description: t("settings.notifications.settings.likes.description"),
        pushKey: "likes",
      },
      {
        title: t("settings.notifications.settings.privateMessages.title"),
        description: t("settings.notifications.settings.privateMessages.description"),
        pushKey: "privateMessages",
      },
      {
        title: t("settings.notifications.settings.promotions.title"),
        pushKey: "promotions",
      },
      {
        title: t("settings.notifications.settings.productUpdates.title"),
        description: t("settings.notifications.settings.productUpdates.description"),
        pushKey: "productUpdates",
      },
      {
        title: t("settings.notifications.settings.commentsAndReplies.title"),
        description: t("settings.notifications.settings.commentsAndReplies.description"),
        pushKey: "commentsAndReplies",
      },
      {
        title: t("settings.notifications.settings.accountUpdates.title"),
        pushKey: "accountUpdates",
      },
    ],
    [t],
  );

  const commonListProps = useMemo<Pick<ListItemProps, "titleProps" | "descriptionProps">>(
    () => ({
      titleProps: { variant: "body1" },
      descriptionProps: { variant: "helperText", color: "tertiary" },
    }),
    [],
  );

  const renderItem = useCallback<ListRenderItem<Option>>(
    ({ item: { title, pushKey, description, email } }) => (
      <Box style={styles.rowGap}>
        <Divider />
        <ListItem title={title} description={description} {...commonListProps} />
        {canSendNotification && pushKey && (
          <ListItem
            title={t("settings.notifications.push")}
            left={<Notification />}
            right={
              loading ? (
                <ActivityIndicator />
              ) : (
                <Switch color={colors.tertiary} value={!!data?.[pushKey]} onValueChange={() => handlePushNotificationChange(pushKey)} />
              )
            }
          />
        )}
        {email && (
          <ListItem
            title={t("settings.notifications.email")}
            left={<EnvelopeSimple />}
            right={
              <Button fullWidth mode="outlined" onPress={handleSubscribeToNewsletter} loading={subscribeLoading} disabled={subscribedSuccessful}>
                {subscribedSuccessful ? t("settings.notifications.emailCtaSuccess") : t("settings.notifications.emailCta")}
              </Button>
            }
          />
        )}
      </Box>
    ),
    [
      canSendNotification,
      colors.tertiary,
      commonListProps,
      data,
      handlePushNotificationChange,
      handleSubscribeToNewsletter,
      loading,
      subscribeLoading,
      subscribedSuccessful,
      t,
    ],
  );

  return (
    <ScreenWrapper contentContainerStyle={styles.root} withScrollView>
      <Box rowGap={32}>
        <Box style={styles.rowGap}>
          <Text variant="body2">{t("settings.notifications.pushDescription")}</Text>
          {!canSendNotification && canRequestPermission && (
            <>
              <Text variant="body2">{t("settings.notifications.pushDescriptionCta")}</Text>
              <Button fullWidth mode="outlined" size="large" onPress={requestPermission} icon={Bell}>
                {t("settings.notifications.pushCta")}
              </Button>
            </>
          )}
          {data && (
            <FlatList
              data={options.filter(o => (canSendNotification && o.pushKey) || o.email)}
              renderItem={renderItem}
              keyExtractor={item => item.title}
              contentContainerStyle={styles.rowGap}
              scrollEnabled={false}
            />
          )}
        </Box>
      </Box>
    </ScreenWrapper>
  );
};
