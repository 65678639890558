import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  linkButton: {
    alignSelf: "flex-start",
  },
  linksContainer: {
    rowGap: 8,
  },
  root: {
    padding: 16,
    rowGap: 16,
  },
});
