import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { TrackEvent } from "@app/common/enums/track-events.enum";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { useInAppPurchasesContext } from "@app/context/in-app-purchases/in-app-purchases.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useSupersellerCheckout } from "@app/hooks/api/subscriptions/use-superseller-checkout.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { isWeb } from "@app/utils/device.util";

export const SupersellerSubscribeButton: FC<Omit<ButtonProps, "children">> = buttonProps => {
  const { navigateToWebview } = useNavigateToWebview();
  const { t } = useTranslation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { profile } = useProfileContext();
  const { trackInMixpanel } = useMixpanelContext();

  const { buySupersellerPlan, supersellerPrice, userHasPaidForSupersellerPlan, loading: loadingInAppPurchase } = useInAppPurchasesContext();
  const { supersellerCheckout, loading: loadingCheckoutUrl } = useSupersellerCheckout();

  const isSubscribed = userHasPaidForSupersellerPlan || profile?.superseller;

  const handleSubscribeToSupersellerPlan = useCallback(() => {
    if (!profile?.userId) return;

    trackInMixpanel(TrackEvent.supersellerSubscribe);

    if (isWeb) {
      void supersellerCheckout({
        variables: { userId: profile.userId },
        onCompleted: data => {
          navigateToWebview(data.supersellerCheckout.checkoutUrl, t("cta.checkout"));
        },
        onError: error => {
          let message: string | undefined = undefined;
          if (isErrorCode(error, "USER_IS_ALREADY_SUPERSELLER")) {
            message = t("error.alreadySuperseller");
          }

          showErrorSnackbar({ message, error });
        },
      });
    } else {
      void buySupersellerPlan(() => {
        showSuccessSnackbar({ message: t("superseller.purchaseSuccess") });
      });
    }
  }, [buySupersellerPlan, navigateToWebview, showErrorSnackbar, showSuccessSnackbar, supersellerCheckout, t, trackInMixpanel, profile?.userId]);

  return (
    <Button
      size="large"
      mode="contained"
      fullWidth
      onPress={handleSubscribeToSupersellerPlan}
      {...buttonProps}
      loading={buttonProps.loading || loadingCheckoutUrl || loadingInAppPurchase || !supersellerPrice}
      disabled={buttonProps.disabled || isSubscribed}>
      {isSubscribed
        ? t("superseller.subscribed")
        : supersellerPrice
        ? t("superseller.subscribeWithPrice", { price: supersellerPrice })
        : t("superseller.subscribe")}
    </Button>
  );
};
