import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { catalogProductFields } from "@app/common/graphql/fragments.graphql";
import { CatalogProduct, UpdateCatalogProductInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCatalogProduct: CatalogProduct;
}

interface Variables {
  catalogProductId: string;
  input: UpdateCatalogProductInput;
}

interface UpdateCatalogProductResult extends MutationResult<Response, "updateCatalogProduct"> {
  updateCatalogProduct: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCatalogProductMutation = gql`
  mutation UpdateCatalogProduct($catalogProductId: CatalogProductId!, $input: UpdateCatalogProductInput!) {
    updateCatalogProduct(catalogProductId: $catalogProductId, input: $input) {
      ...CatalogProductFields
    }
  }
  ${catalogProductFields}
`;

export function useUpdateCatalogProduct(): UpdateCatalogProductResult {
  const [updateCatalogProduct, { loading, error, data }] = useMutation<Response, Variables>(updateCatalogProductMutation);

  return { updateCatalogProduct, loading, error, data: data?.updateCatalogProduct };
}
