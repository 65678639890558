import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  customerData: {
    borderWidth: 1,
    padding: 16,
  },
  editBottomSheetContentContainer: {
    paddingTop: isWeb ? 16 : 0,
  },
  editIcon: {
    margin: 8,
  },
});
