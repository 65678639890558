import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationPromoteCollabToConceptArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  promoteCollabToConcept: Collab;
}

interface PromoteCollabToConceptResult extends MutationResult<Response, "promoteCollabToConcept"> {
  promoteCollabToConcept: (options?: MutationFunctionOptions<Response, MutationPromoteCollabToConceptArgs>) => Promise<FetchResult>;
}

const promoteCollabToConceptMutation = gql`
  mutation PromoteCollabToConcept($collabId: CollabId!) {
    promoteCollabToConcept(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function usePromoteCollabToConcept(): PromoteCollabToConceptResult {
  const [promoteCollabToConcept, { loading, error, data }] = useMutation<Response, MutationPromoteCollabToConceptArgs>(
    promoteCollabToConceptMutation,
  );

  return { promoteCollabToConcept, loading, error, data: data?.promoteCollabToConcept };
}
