import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bottomIosHandle: {
    flex: 0,
  },
  buttonContainer: {
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    rowGap: 16,
  },
  completedText: {
    bottom: 20,
    flexGrow: 1,
    justifyContent: "center",
    padding: 20,
    rowGap: 16,
  },
  fullScreen: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  loading: {
    justifyContent: "center",
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
});
