import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationMoveCollabToDevelopmentDeniedArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  moveCollabToDevelopmentDenied: Collab;
}

interface MoveCollabToDevelopmentDeniedResult extends MutationResult<Response, "moveCollabToDevelopmentDenied"> {
  moveCollabToDevelopmentDenied: (options?: MutationFunctionOptions<Response, MutationMoveCollabToDevelopmentDeniedArgs>) => Promise<FetchResult>;
}

const moveCollabToDevelopmentDeniedMutation = gql`
  mutation MoveCollabToDevelopmentDenied($collabId: CollabId!) {
    moveCollabToDevelopmentDenied(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useMoveCollabToDevelopmentDenied(): MoveCollabToDevelopmentDeniedResult {
  const [moveCollabToDevelopmentDenied, { loading, error, data }] = useMutation<Response, MutationMoveCollabToDevelopmentDeniedArgs>(
    moveCollabToDevelopmentDeniedMutation,
  );

  return { moveCollabToDevelopmentDenied, loading, error, data: data?.moveCollabToDevelopmentDenied };
}
