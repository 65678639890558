import React, { FC, useCallback, useMemo, useState } from "react";

import { FlashList, ListRenderItem } from "@shopify/flash-list";
import partition from "lodash.partition";
import { BellSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import bellAnimatedDark from "@app/assets/lotties/bell-dark.json";
import bellAnimatedLight from "@app/assets/lotties/bell-light.json";
import { Notification, PaginatedNotifications } from "@app/common/graphql/generated/schema.graphql";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Text } from "@app/components/common/text/text.component";
import { NotificationListItem } from "@app/components/notifications/notification-list-item/notification-list-item.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStreamContext } from "@app/context/stream/stream.context";
import { handledNotifTypes } from "@app/context/stream/stream.provider";
import { useNotifications } from "@app/hooks/api/use-notifications.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { styles } from "./notification-list.style";

interface Props {
  userId: string;
}

export const NotificationList: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { setNotifsAsSeen } = useStreamContext();

  const { data, error, loading, fetchMore, refetch } = useNotifications({
    variables: { userId },
    onCompleted: () => setNotifsAsSeen?.(),
    onError: e => showErrorSnackbar({ error: e, refetch }),
  });

  const [isFetchingMore, setFetchingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [seen, unseen] = partition(data?.nodes.filter(notif => handledNotifTypes.includes(notif.verb)), notif => notif.isSeen);

  const { fetchMoreItems } = useFetchMoreItems("notifications", fetchMore);

  const fetchMoreNotifications = useCallback(
    async (notifications?: PaginatedNotifications) => {
      if (fetchMore && !refreshing && !error && notifications?.pageInfo.hasNextPage && notifications.pageInfo.next) {
        const result = await fetchMoreItems({ after: notifications?.pageInfo.next });

        if (result?.data.notifications.nodes.length === 0) {
          await fetchMoreNotifications(result.data.notifications);
        }
      }
    },
    [error, fetchMore, fetchMoreItems, refreshing],
  );

  const handleFetchMore = useCallback(async (): Promise<void> => {
    setFetchingMore(true);
    await fetchMoreNotifications(data);
    setFetchingMore(false);
  }, [data, fetchMoreNotifications]);

  const handleRefreshing = useCallback((): void => {
    if (!refetch) return;

    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
        if (data?.nodes.length && data?.nodes.length < 10) {
          void handleFetchMore();
        }
      })
      .catch((e: Error) => {
        setRefreshing(false);
        showErrorSnackbar({ error: e });
      });
  }, [data?.nodes.length, handleFetchMore, refetch, showErrorSnackbar]);

  const listData: (Notification | string)[] = useMemo(
    () => [
      ...conditionalItem(unseen.length > 0, t("notifications.new")),
      ...unseen,
      ...conditionalItem(seen.length > 0, t("notifications.earlier")),
      ...seen,
    ],
    [seen, t, unseen],
  );

  const renderItem = useCallback<ListRenderItem<Notification | string>>(
    ({ item, index }) => {
      if (typeof item === "string") {
        return (
          <View style={[styles.header, ...conditionalItem(unseen.length > 0 && index > 0, styles.headerMarginTop)]}>
            <Text variant="subtitle1">{item}</Text>
          </View>
        );
      } else {
        return <NotificationListItem notification={item} />;
      }
    },
    [unseen.length],
  );

  return (
    <FlashList
      data={listData}
      keyExtractor={(item, index) => `notification-item-${typeof item === "string" ? item : item.notificationId}-${index}`}
      renderItem={renderItem}
      getItemType={item => (typeof item === "string" ? "sectionHeader" : "row")}
      estimatedItemSize={135}
      ListEmptyComponent={
        loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <EmptyState
            icon={BellSimple}
            animatedIcon={{ dark: bellAnimatedDark, light: bellAnimatedLight }}
            message={t("emptyState.notifications.message")}
          />
        )
      }
      ListFooterComponent={isFetchingMore ? <ActivityIndicator size="small" /> : undefined}
      onEndReached={() => void handleFetchMore()}
      onEndReachedThreshold={0.3}
      onRefresh={handleRefreshing}
      refreshing={refreshing}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.root}
    />
  );
};
