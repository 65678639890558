import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bioPronouns: {
    gap: 8,
  },
  bioPronounsContainer: {
    padding: 8,
  },
  expandableButton: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
  root: {
    flex: 1,
    rowGap: 8,
  },
});
