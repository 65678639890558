/* eslint-disable max-lines-per-function */
import React, { PropsWithChildren, forwardRef, memo, useCallback, useImperativeHandle, useMemo } from "react";

import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  ReduceMotion,
  cancelAnimation,
  interpolate,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { SpringConfig } from "react-native-reanimated/lib/typescript/reanimated2/animation/springUtils";

import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { SwipeableCardOverlay } from "./swipeable-card-overlay.component";
import type { SwipeableCardProps, SwiperCardRefProps } from "./swiper.types";

const SwipeableCard = forwardRef<SwiperCardRefProps, PropsWithChildren<SwipeableCardProps>>(
  (
    {
      activeIndex,
      cardHeight,
      cardStyle,
      children,
      disableLeftSwipe,
      disableRightSwipe,
      disableTopSwipe,
      globalTranslationX,
      horizontalThreshold,
      index,
      inputOverlayLabelLeftOpacityRange,
      inputOverlayLabelRightOpacityRange,
      inputOverlayLabelTopOpacityRange,
      onSwiped,
      onSwipedLeft,
      onSwipedRight,
      onSwipedTop,
      onSwipeEnd,
      onSwipeStart,
      outputOverlayLabelLeftOpacityRange,
      outputOverlayLabelRightOpacityRange,
      outputOverlayLabelTopOpacityRange,
      overlayBackgroundColors,
      overlayLabels,
      position,
      rotateInputRange,
      rotateOutputRange,
      stackScale,
      swipeAnimationDuration,
      swipeDisabled,
      verticalThreshold,
    },
    ref,
  ) => {
    const { width, height } = useWindowDimensions();

    const translationX = useSharedValue(0);
    const translationY = useSharedValue(0);

    const currentActiveIndex = useSharedValue(Math.floor(activeIndex.value));
    const nextActiveIndex = useSharedValue(Math.floor(activeIndex.value));
    const runHorizontalSwipeAnimation = useSharedValue(0);
    const runVerticalSwipeAnimation = useSharedValue(0);

    const maxCardTranslationX = width * 1.5;
    const maxCardTranslationY = height * 1.5;
    const translateXRange = useMemo(() => [-horizontalThreshold, 0, horizontalThreshold], [horizontalThreshold]);
    const translateYRange = useMemo(() => [-verticalThreshold, 0, verticalThreshold], [verticalThreshold]);

    const swipeAnimationConfig: SpringConfig = useMemo(
      () => ({ duration: swipeAnimationDuration, reduceMotion: ReduceMotion.Never }),
      [swipeAnimationDuration],
    );

    const swipeRight = useCallback(() => (runHorizontalSwipeAnimation.value = 1), [runHorizontalSwipeAnimation]);

    const swipeLeft = useCallback(() => (runHorizontalSwipeAnimation.value = -1), [runHorizontalSwipeAnimation]);

    const swipeTop = useCallback(() => (runVerticalSwipeAnimation.value = 1), [runVerticalSwipeAnimation]);

    const swipeBack = useCallback(
      (cb?: (index: number) => void) => {
        cancelAnimation(translationX);
        cancelAnimation(translationY);
        translationX.value = withSpring(0);
        translationY.value = withSpring(0);
        runHorizontalSwipeAnimation.value = 0;
        runVerticalSwipeAnimation.value = 0;
        if (cb) runOnJS(cb)(index);
      },
      [index, runHorizontalSwipeAnimation, runVerticalSwipeAnimation, translationX, translationY],
    );

    const press = useCallback((cb?: (index: number) => void) => cb?.(index), [index]);

    useImperativeHandle(
      ref,
      () => {
        return {
          swipeLeft,
          swipeRight,
          swipeBack,
          swipeTop,
          press,
        };
      },
      [swipeLeft, swipeRight, swipeBack, swipeTop, press],
    );

    const inputRangeX = useMemo(() => translateXRange ?? [], [translateXRange]);
    const inputRangeY = useMemo(() => translateYRange ?? [], [translateYRange]);

    useAnimatedReaction(
      () => runHorizontalSwipeAnimation.value,
      (current, previous) => {
        if (current === previous || current === 0) return;

        runOnJS(current > 0 ? onSwipedRight : onSwipedLeft)(index);
        translationX.value = withTiming(maxCardTranslationX * current, swipeAnimationConfig, (finished?: boolean) => {
          if (finished) {
            activeIndex.value++;
          }
        });
      },
      [],
    );

    useAnimatedReaction(
      () => runVerticalSwipeAnimation.value,
      (current, previous) => {
        if (current === previous || current === 0) return;

        if (current > 0) runOnJS(onSwipedTop)(index);
        translationY.value = withTiming(maxCardTranslationY * current, swipeAnimationConfig, (finished?: boolean) => {
          if (finished) {
            activeIndex.value++;
          }
        });
      },
      [],
    );

    const gesture = Gesture.Pan()
      .enabled(!swipeDisabled)
      .onBegin(() => {
        currentActiveIndex.value = Math.floor(activeIndex.value);

        if (onSwipeStart) runOnJS(onSwipeStart)();
      })
      .onUpdate(event => {
        if (activeIndex.value !== index) return;

        globalTranslationX.value = event.translationX;

        translationX.value = event.translationX;
        translationY.value = event.translationY;

        if (height / 3 < Math.abs(event.translationY)) {
          nextActiveIndex.value = interpolate(
            translationY.value,
            inputRangeY,
            [currentActiveIndex.value + 1, currentActiveIndex.value, currentActiveIndex.value + 1],
            "clamp",
          );
          return;
        }

        nextActiveIndex.value = interpolate(
          translationX.value,
          inputRangeX,
          [currentActiveIndex.value + 1, currentActiveIndex.value, currentActiveIndex.value + 1],
          "clamp",
        );
      })
      .onFinalize(event => {
        if (activeIndex.value !== index) return;

        if (onSwipeEnd) runOnJS(onSwipeEnd)();

        if (nextActiveIndex.value === activeIndex.value + 1) {
          const sign = Math.sign(event.translationX);
          const signPositionY = Number.isInteger(
            interpolate(
              translationY.value,
              inputRangeY,
              [currentActiveIndex.value + 1, currentActiveIndex.value, currentActiveIndex.value + 1],
              "clamp",
            ),
          );

          if (signPositionY) {
            if (onSwiped) runOnJS(onSwiped)("up");
            if (!disableTopSwipe) {
              runVerticalSwipeAnimation.value = 1;
              return;
            }
          }

          if (!signPositionY) {
            if (sign === 1) {
              if (onSwiped) runOnJS(onSwiped)("right");
              if (!disableRightSwipe) {
                runHorizontalSwipeAnimation.value = sign;
                return;
              }
            }
            if (sign === -1) {
              if (onSwiped) runOnJS(onSwiped)("left");
              if (!disableLeftSwipe) {
                runHorizontalSwipeAnimation.value = sign;
                return;
              }
            }
          }
        }

        translationX.value = withSpring(0);
        translationY.value = withSpring(0);
        runHorizontalSwipeAnimation.value = 0;
        runVerticalSwipeAnimation.value = 0;
      });

    const animatedCardStyle = useAnimatedStyle(() => {
      const scale = withTiming(activeIndex.value === index ? 1 : (100 - stackScale * position) * 0.01);
      const rotateX = interpolate(translationX.value, rotateInputRange ?? [], rotateOutputRange ?? [], "clamp");

      return {
        position: "absolute",
        zIndex: position * -1,
        transform: [
          { rotate: `${rotateX}rad` },
          { scale },
          {
            translateX: translationX.value,
          },
          {
            translateY: translationY.value,
          },
        ],
      };
    });

    return (
      <GestureDetector gesture={gesture}>
        <Animated.View style={[cardStyle, animatedCardStyle]}>
          {(overlayLabels?.left || overlayBackgroundColors?.left) && (
            <SwipeableCardOverlay
              opacityValue={translationX}
              inputRange={inputOverlayLabelLeftOpacityRange}
              outputRangeLabel={outputOverlayLabelLeftOpacityRange}
              labelComponent={overlayLabels?.left}
              backgroundColor={overlayBackgroundColors?.left}
              backgroundStyle={{ height: cardHeight }}
            />
          )}
          {(overlayLabels?.right || overlayBackgroundColors?.right) && (
            <SwipeableCardOverlay
              opacityValue={translationX}
              inputRange={inputOverlayLabelRightOpacityRange}
              outputRangeLabel={outputOverlayLabelRightOpacityRange}
              labelComponent={overlayLabels?.right}
              backgroundColor={overlayBackgroundColors?.right}
              backgroundStyle={{ height: cardHeight }}
            />
          )}
          {(overlayLabels?.up || overlayBackgroundColors?.up) && (
            <SwipeableCardOverlay
              opacityValue={translationY}
              inputRange={inputOverlayLabelTopOpacityRange}
              outputRangeLabel={outputOverlayLabelTopOpacityRange}
              labelComponent={overlayLabels?.up}
              backgroundColor={overlayBackgroundColors?.up}
              backgroundStyle={{ height: cardHeight }}
            />
          )}

          {children}
        </Animated.View>
      </GestureDetector>
    );
  },
);

SwipeableCard.displayName = "SwipeableCard";

export default memo(SwipeableCard);
