import React, { FC, ReactNode } from "react";

import { Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { ImagePickerItem } from "@app/components/common/images-picker/image-picker-item/image-picker-item.component";
import { Text } from "@app/components/common/text/text.component";
import { pickImages } from "@app/utils/image-picker.util";
import { reportError } from "@app/utils/logger/logger.util";

import { styles } from "./image-picker.style";

interface Props extends ViewProps {
  image?: string;
  setImage: (image?: string) => void;
  aspectRatio?: number;
  disabled?: boolean;
  legend?: ReactNode;
}

export const ImagePicker: FC<Props> = ({ image, setImage, aspectRatio = imageRatio, disabled, legend, ...viewProps }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const handleAddImage = async (): Promise<void> => {
    try {
      const imageResult = await pickImages({ allowsMultipleSelection: false, allowsEditing: aspectRatio === 1 });

      if (imageResult.error || !imageResult.assets?.length) return;
      const { assets } = imageResult;
      setImage(assets[0].uri);
    } catch (e) {
      reportError(e as Error);
    }
  };

  return (
    <View {...viewProps}>
      {legend}
      {image ? (
        <View style={[styles.editImageContainer, { aspectRatio }]}>
          <ImagePickerItem imageUrl={image} type="image" onRemove={() => setImage(undefined)} aspectRatio={aspectRatio} />
        </View>
      ) : (
        <TouchableOpacity disabled={disabled} style={styles.editImageContainer} onPress={() => void handleAddImage()}>
          <View style={[styles.addImageContainer, { borderColor: colors.outline, aspectRatio }]}>
            <Plus color={colors.outline} />
            <Text variant="labelSmall" color={disabled ? "onSurfaceDisabled" : undefined}>
              {t("studioMini.addImage")}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};
