import React, { FC, useState } from "react";

import { PaperPlaneTilt } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Switch, useTheme } from "react-native-paper";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useFeedback } from "@app/hooks/api/use-send-feedback.hook";
import { isEmailValid } from "@app/utils/user.util";

import { styles } from "./feedback.style";

export const FeedbackScreen: FC<NavigationProps<Routes.feedback>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { profile } = useProfileContext();

  const [feedback, setFeedback] = useState("");
  const [reachOut, setReachOut] = useState(false);
  const [email, setEmail] = useState("");

  const submitDisabled = !feedback.trim() || (reachOut && !isEmailValid(email));

  const { sendFeedback, loading } = useFeedback();

  const handleOnPress = (): void => {
    if (submitDisabled) return;

    void sendFeedback({
      variables: { message: feedback.trim(), email: profile || !reachOut ? undefined : email },
      onCompleted: () => {
        showSuccessSnackbar({ message: t("feedback.success") });
        navigation.goBack();
      },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <ScreenWrapper withScrollView withKeyboardAvoidingView contentContainerStyle={styles.root}>
      <View style={styles.section}>
        <Text variant="h5">{t("feedback.title")}</Text>
        <Text variant="helperText" color="tertiary">
          {t("feedback.description")}
        </Text>
      </View>

      <View style={styles.section}>
        <TextInput multiline numberOfLines={5} value={feedback} onChangeText={setFeedback} label={t("feedback.yourFeedback")} />
        {!profile && (
          <ListItem
            left={<Switch color={colors.secondary} value={reachOut} onValueChange={setReachOut} />}
            title={t("feedback.reachOut")}
            titleProps={{ variant: "body1", onPress: () => setReachOut(!reachOut) }}
          />
        )}
        {!profile && reachOut && (
          <TextInput inputMode="email" autoCapitalize="none" value={email} onChangeText={setEmail} label={t("feedback.yourEmail")} />
        )}
        <Button
          mode="contained"
          size="large"
          onPress={handleOnPress}
          icon={props => <PaperPlaneTilt weight="fill" {...props} />}
          disabled={submitDisabled}
          loading={loading}>
          {t("cta.submit")}
        </Button>
      </View>
    </ScreenWrapper>
  );
};
