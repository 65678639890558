import React, { FC } from "react";

import { View } from "react-native";
import { ActivityIndicator, Avatar, useTheme } from "react-native-paper";
import { AvatarImageSource } from "react-native-paper/lib/typescript/components/Avatar/AvatarImage";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { transparent } from "@app/common/style/theme";

import { Image, ImageProps } from "../image/image.component";

import { styles } from "./avatar-image.style";

export type AvatarImageType = IconSource | AvatarImageSource;

export interface AvatarImageProps extends Omit<ImageProps, "source"> {
  image?: AvatarImageType;
  loading?: boolean;
  fallbackText: string;
  size?: number;
}

export const AvatarImage: FC<AvatarImageProps> = ({ image, loading, fallbackText, size, style, ...props }) => {
  const { colors } = useTheme();

  if (loading)
    return (
      <View style={[{ width: size, height: size }, styles.loadingContainer, style]}>
        <ActivityIndicator animating size="small" />
      </View>
    );

  if (image) {
    return typeof image === "string" ? (
      <Avatar.Image
        source={({ size: imageSize }) => <Image source={image} width={imageSize} height={imageSize} style={style} {...props} />}
        size={size}
        style={[styles.container, style]}
      />
    ) : (
      <Avatar.Icon icon={image} size={size} color={colors.onBackground} style={[styles.container, { backgroundColor: transparent }, style]} />
    );
  }

  return <Avatar.Text label={fallbackText} size={size} style={style} />;
};
