import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  animatedBorder: {
    height: 2,
  },
  container: {
    alignItems: "center",
  },
  tab: {
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 12,
  },
  tabs: {
    flexDirection: "row",
  },
});
