import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { DeletedCollabs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  deleteCollabsForUser: DeletedCollabs;
}

interface Variables {
  userId: string;
}

interface DeleteCollabsForUserResult extends MutationResult<Response, "deleteCollabsForUser"> {
  deleteCollabsForUser: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCollabsForUserMutation = gql`
  mutation DeleteCollabsForUser($userId: UserId!) {
    deleteCollabsForUser(userId: $userId) {
      deletedCollabs
    }
  }
`;

export function useDeleteCollabsForUser(): DeleteCollabsForUserResult {
  const [deleteCollabsForUser, { loading, error, data }] = useMutation<Response, Variables>(deleteCollabsForUserMutation);

  return { deleteCollabsForUser, loading, error, data: data?.deleteCollabsForUser };
}
