import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  webInput: {
    height: "100%",
    opacity: 0,
    position: "absolute",
    width: "100%",
  },
  webLabel: {
    alignItems: "center",
    display: "flex",
    position: "relative",
  },
});
