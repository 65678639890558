import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Check } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TextInput as RNTextInput } from "react-native";
import { IconButton } from "react-native-paper";

import { CreateCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { ProductUpdateStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useProductUpdateInputProps } from "@app/hooks/utils/use-product-update-input-props.hook";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./edit-product-update-field.style";

const editableFields: (keyof Omit<CreateCollabUpdateInput, "imageIds">)[] = ["title", "content"];

export const EditProductUpdateFieldScreen: FC<ProductUpdateStackScreenProps<Routes.editProductUpdateField>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const inputRef = useRef<RNTextInput>(null);

  const fieldName = route.params.fieldName;
  const { commonInputProps } = useProductUpdateInputProps();
  const inputProps = commonInputProps[fieldName];

  const [value, setValue] = useState(inputProps.value);

  useEffect(() => {
    if (!fieldName || !editableFields.includes(fieldName)) {
      navigation.replace(Routes.studioMiniCreateCollab);
    }
  }, [fieldName, navigation]);

  const handleSubmit = useCallback(() => {
    if (!value) return;

    inputProps?.setValue(value.trim());
    navigation.goBack();
  }, [inputProps, navigation, value]);

  const submitButton = useCallback(() => <IconButton icon={Check} disabled={!value} onPress={handleSubmit} />, [handleSubmit, value]);

  useEffect(() => {
    navigation.setOptions({
      title: t(`productUpdates.fields.${fieldName}.label`),
      headerRight: submitButton,
    });
  }, [fieldName, navigation, submitButton, t]);

  useEffect(() => {
    if (isWeb) return undefined;

    const unsubscribe = navigation.addListener("transitionEnd", () => {
      inputRef.current?.focus();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <ScreenWrapper withScrollView withKeyboardAvoidingView contentContainerStyle={styles.root}>
      <TextInput ref={inputRef} autoFocus={isWeb} {...inputProps.inputProps} value={value} onChangeText={text => setValue(text)} />
    </ScreenWrapper>
  );
};
