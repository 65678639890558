import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { TextInput } from "@app/components/common/text-input/text-input.component";
import { usePreorderContext } from "@app/context/preorder/preorder.context";
import { isEmailValid } from "@app/utils/user.util";

import { styles } from "./enter-email-for-preorder.style";

export const EnterEmailForPreorder: FC = () => {
  const { t } = useTranslation();
  const {
    input: { email },
    setInput,
  } = usePreorderContext();

  const [confirmEmail, setConfirmEmail] = useState("");

  const setEmail = (newEmail: string): void => setInput(prev => ({ ...prev, email: newEmail }));

  return (
    <View style={styles.root}>
      <TextInput
        label={t("preorder.emailAddress")}
        value={email}
        onChangeText={setEmail}
        error={email ? !isEmailValid(email) : false}
        errorText={t("error.invalidFormat")}
        autoCapitalize="none"
        inputMode="email"
        autoComplete="email"
        required
      />
      <TextInput
        label={t("preorder.confirmEmailAddress")}
        value={confirmEmail}
        onChangeText={setConfirmEmail}
        error={confirmEmail ? confirmEmail !== email : false}
        errorText={t("preorder.error.emailsMustMatch")}
        autoCapitalize="none"
        inputMode="email"
        autoComplete="email"
        required
      />
    </View>
  );
};
