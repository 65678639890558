import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreStudioAnimationFields, coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { DeleteStudioMediaInput, PaginatedStudioMedia } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteStudioMedia: PaginatedStudioMedia;
}

interface Variables {
  where: DeleteStudioMediaInput;
}

interface DeleteStudioMediaResult extends MutationResult<Response, "deleteStudioMedia"> {
  deleteStudioMedia: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteStudioMediaMutation = gql`
  mutation DeleteStudioMedia($where: DeleteStudioMediaInput!) {
    deleteStudioMedia(where: $where) {
      nodes {
        ... on StudioImage {
          ...CoreStudioImageFields
        }
        ... on StudioAnimation {
          ...CoreStudioAnimationFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
  ${coreStudioAnimationFields}
`;

export function useDeleteStudioMedia(): DeleteStudioMediaResult {
  const [deleteStudioMedia, { loading, error, data }] = useMutation<Response, Variables>(deleteStudioMediaMutation, {
    update: (cache, result, options) => {
      cache.modify({
        fields: {
          studioMedia(existingStudioMedia: PaginatedStudioMedia, { readField }) {
            return {
              ...existingStudioMedia,
              nodes: existingStudioMedia.nodes?.filter(
                entity =>
                  !options.variables?.where.mediaIds.includes(
                    readField("__typename", entity) === "StudioAnimation"
                      ? (readField("studioAnimationId", entity) as string)
                      : (readField("imageId", entity) as string),
                  ),
              ),
              totalCount: existingStudioMedia.totalCount - (result.data?.deleteStudioMedia.nodes?.length ?? 0),
              __typename: existingStudioMedia.__typename,
            };
          },
        },
      });
    },
  });

  return { deleteStudioMedia, loading, error, data: data?.deleteStudioMedia };
}
