import React, { PropsWithChildren, useMemo, FC } from "react";

import { StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";

import { styles } from "./product-image-tab.style";

interface Props {
  isActive: boolean;
  hasMultipleImages: boolean;
}

export const ProductImageTab: FC<PropsWithChildren<Props>> = ({ isActive, hasMultipleImages }) => {
  const { colors } = useTheme();

  const animatedWidthStyle = useAnimatedStyle(
    () => ({
      width: withTiming(isActive ? 33 : 12),
    }),
    [isActive],
  );

  const colorStyle = useMemo((): StyleProp<ViewStyle> => {
    if (!hasMultipleImages) return { opacity: 0 };
    if (!isActive) return { borderColor: "rgba(255,255,255,0.3)", backgroundColor: colors.common.gray };

    return { borderColor: "rgba(0,0,0,0.3)", backgroundColor: colors.common.white };
  }, [colors.common.gray, colors.common.white, hasMultipleImages, isActive]);

  return <Animated.View style={[styles.imageTab, colorStyle, animatedWidthStyle]} />;
};
