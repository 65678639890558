/* eslint-disable react-native/no-raw-text */
import React, { FC } from "react";

import { APP_ENV, APPLE_APP_ID } from "@env";
import { Helmet } from "react-helmet";

import { HeadProps } from "./head.type";

export const Head: FC<HeadProps> = ({ meta }) => {
  return (
    <Helmet>
      <meta name="apple-itunes-app" content={`app-id=${APPLE_APP_ID}, app-argument=${window.location.href ?? ""}`} />
      <meta property="fb:app_id" content="279694661069230" />
      <meta property="og:url" content={window.location.href} />
      {meta?.map(({ content, property, ...rest }) => <meta key={content} property={property} content={content} {...rest} />)}
      <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "G-${
        APP_ENV === "production" ? "ZYXCRX1QYF" : "YB5WXJM6X4"
      }");`}</script>
    </Helmet>
  );
};
