import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, CreateContestCollabInput, PaginatedCollabs, PaginatedContestResults } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

import { contestSubmissionCountQuery } from "./contests/use-contest-submission-count.hook";

interface Response {
  createContestCollab: Collab;
}

interface Variables {
  input: CreateContestCollabInput;
}

interface CreateContestCollabResult extends MutationResult<Response, "createContestCollab"> {
  createContestCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createContestCollabMutation = gql`
  mutation CreateContestCollab($input: CreateContestCollabInput!) {
    createContestCollab(input: $input) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useCreateContestCollab(): CreateContestCollabResult {
  const [createContestCollab, { loading, error, data }] = useMutation<Response, Variables>(createContestCollabMutation, {
    refetchQueries: [contestSubmissionCountQuery],
    update: (cache, result, options) => {
      cache.modify({
        fields: {
          collabsForUser: (existingCollabs: PaginatedCollabs, { toReference, storeFieldName }) => {
            if (!storeFieldName.includes(options.variables?.input.creatorId ?? "")) return existingCollabs;

            return {
              ...existingCollabs,
              nodes: [toReference(result.data?.createContestCollab.collabId as string), ...(existingCollabs.nodes ?? [])],
              totalCount: existingCollabs.totalCount + 1,
              __typename: existingCollabs.__typename,
            };
          },
        },
      });
      cache.modify({
        fields: {
          collabsInContest: (existingCollabs: PaginatedContestResults, { toReference, storeFieldName }) => {
            if (!storeFieldName.includes(options.variables?.input.contestId ?? "")) return existingCollabs;

            return {
              ...existingCollabs,
              nodes: [
                {
                  contest: toReference(options.variables?.input.contestId as string),
                  collab: toReference(result.data?.createContestCollab.collabId as string),
                  __typename: "ContestResult",
                },
                ...(existingCollabs.nodes ?? []),
              ],
              totalCount: existingCollabs.totalCount + 1,
              __typename: existingCollabs.__typename,
            };
          },
        },
      });
    },
  });

  return { createContestCollab, loading, error, data: data?.createContestCollab };
}
