import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  fullScreen: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
});
