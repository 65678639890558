import React, { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-paper";

import HandHeart from "@app/assets/icons/hand-heart-fill.svg";
import { bottomTabsHeight } from "@app/common/style/theme";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { GenericCard } from "@app/components/common/generic-card/generic-card.component";
import { ShareReferralLinkButton } from "@app/components/profile/share-referral-link-button/share-referral-link-button.component";
import { SharingRewardAlert } from "@app/components/supercoins/sharing-reward-alert/sharing-reward-alert.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { headerHeight } from "@app/router/router.style";

import { styles } from "./refer-a-friend.style";

export const ReferAFriendScreen: FC<ProfileStackScreenProps<Routes.referAFriend>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { profile, loading: loadingProfile } = useProfileContext();
  const { height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    if (!loadingProfile && !profile) {
      navigation.navigate(Routes.profile);
    }
  }, [loadingProfile, navigation, profile]);

  useEffect(() => {
    navigation.setOptions({
      headerTransparent: true,
    });
  }, [navigation]);

  return profile ? (
    <GenericCard
      content={<SharingRewardAlert />}
      cta={<ShareReferralLinkButton shareId={profile.shareId} />}
      image={({ size, color }) => <HandHeart width={size} height={size} color={color} />}
      title={t("referral.title")}
      height={windowHeight - bottomTabsHeight}
      containerStyle={[styles.root, { paddingTop: headerHeight }]}
    />
  ) : (
    <ActivityIndicator />
  );
};
