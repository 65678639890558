import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  advantagesList: {
    rowGap: 8,
  },
  buttonContainer: {
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    rowGap: 8,
    width: "100%",
  },
  contentContainer: {
    padding: 20,
    rowGap: 40,
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
    rowGap: 4,
  },
  openStoreButton: {
    flex: 1,
  },
  priceContainer: {
    alignItems: "center",
  },
  root: {
    flex: 1,
  },
  textFooter: {
    alignSelf: "center",
  },
});
