import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  formView: {
    rowGap: 24,
  },
  inputView: {
    rowGap: 16,
  },
  wrapperView: {
    rowGap: 40,
    width: "100%",
  },
});
