import { StyleSheet } from "react-native";

const textShadowColor = "rgba(0,0,0,0.3)";

export const styles = StyleSheet.create({
  iconWithCountContainer: {
    alignItems: "center",
  },
  root: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textShadow: {
    textShadowColor,
    textShadowOffset: { width: 0.5, height: 0.5 },
    textShadowRadius: 0,
  },
});
