import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    alignSelf: "center",
    columnGap: 4,
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 20,
    width: "100%",
  },
});
