import { ImageRequireSource, PixelRatio } from "react-native";
import { useTheme } from "react-native-paper";

import { useConfigContext } from "@app/context/config/config.context";

const widthBreakpoints = [200, 750, 1440, 1960, 3000];

interface ResponsiveImageUtils {
  getResponsiveImageUrl: (imageUrl?: string) => string | undefined;
  getPreviewImageUrl: (imageUrl?: string) => string | undefined;
  defaultImage: ImageRequireSource;
}

function getOptimalWidth(width?: number): number | undefined {
  if (!width) return undefined;

  const widthForLayoutSize = PixelRatio.getPixelSizeForLayoutSize(width);
  return widthBreakpoints.find(w => w > widthForLayoutSize);
}

export const previewWidth = 25;

export function useImage(props?: { width?: number }): ResponsiveImageUtils {
  const { dark } = useTheme();
  const { config } = useConfigContext();

  const proxyUrl = config?.imageResizeProxy;
  const proxiedCdnImageEnable = !!proxyUrl;
  const optimalWidth = getOptimalWidth(props?.width);

  const getResponsiveImageUrl = (imageUrl?: string): string | undefined => {
    if (!imageUrl) return undefined;

    return proxiedCdnImageEnable ? `${proxyUrl}/width=${optimalWidth}/${imageUrl}` : imageUrl;
  };

  const getPreviewImageUrl = (imageUrl?: string): string | undefined => {
    if (!imageUrl) return undefined;

    return proxiedCdnImageEnable ? `${proxyUrl}/width=${previewWidth},blur=5/${imageUrl}` : imageUrl;
  };

  const defaultImage = (
    dark ? require("@app/assets/images/default-image-dark.png") : require("@app/assets/images/default-image-light.png")
  ) as ImageRequireSource;

  return { getResponsiveImageUrl, getPreviewImageUrl, defaultImage };
}
