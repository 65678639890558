import React, { FC, useCallback } from "react";

import LottieView from "lottie-react-native";
import { useTranslation } from "react-i18next";

import swipeOnboardingAnimation from "@app/assets/lotties/swipe-onboarding.json";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";

import { GenericOnboardingCard } from "../generic-onboarding-card/generic-onboarding-card.component";

import { styles } from "./swipe-onboarding-card.style";

export const SwipeOnboardingCard: FC = () => {
  const { t } = useTranslation();

  const { updateState } = useProfileContext();

  const handleCloseSwipeOnboarding = useCallback(() => {
    updateState({ input: { [UserStateEnum.swipeOnboardingCompleted]: true } });
  }, [updateState]);

  return (
    <GenericOnboardingCard
      title={t("onboarding.swipe.title")}
      subtitle={t("onboarding.swipe.subtitle")}
      icon={<LottieView source={swipeOnboardingAnimation} autoPlay loop style={styles.swipeAnimation} />}
      onClose={handleCloseSwipeOnboarding}
    />
  );
};
