import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteProfile: Profile;
}

interface Variables {
  userId: string;
}

interface DeleteProfileResult extends MutationResult<Response, "deleteProfile"> {
  deleteProfile: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteProfileMutation = gql`
  mutation DeleteProfile($userId: UserId!) {
    deleteProfile(userId: $userId) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useDeleteProfile(): DeleteProfileResult {
  const [deleteProfile, { loading, error, data, reset }] = useMutation<Response, Variables>(deleteProfileMutation, {
    update: (cache, _result, options) => {
      cache.evict({ id: options.variables?.userId });
    },
  });

  return { deleteProfile, loading, error, data: data?.deleteProfile, reset };
}
