import React, { ForwardedRef, JSX, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { NetworkStatus, QueryHookOptions } from "@apollo/client";
import { ContentStyle, FlashList, FlashListProps, ListRenderItem } from "@shopify/flash-list";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import useDeepCompareEffect from "use-deep-compare-effect";

import { nbProductsPerFetch } from "@app/common/constants/products.const";
import { Collab, ContestResult } from "@app/common/graphql/generated/schema.graphql";
import { PaginatedResponse, QueryResult } from "@app/common/types/apollo-result.type";
import { Enum } from "@app/common/types/enum.type";
import { SortingMethod } from "@app/common/types/sorting.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { CollabsInContestVariables } from "@app/hooks/api/contests/use-collabs-in-contest.hook";
import { LikesVariables } from "@app/hooks/api/likes/use-likes.hook";
import { BrowseCollabsVariables } from "@app/hooks/api/use-browse-collabs.hook";
import { CollabsResponse, CollabsVariables } from "@app/hooks/api/use-collabs.hook";
import { SearchCollabsVariables } from "@app/hooks/api/use-search-collabs.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";
import { isWeb } from "@app/utils/device.util";

import { ProductListEmpty } from "./product-list-empty/product-list-empty.component";
import { ProductListFilterButtons } from "./product-list-filter-buttons/product-list-filter-buttons.component";
import ProductListItem, { ContestEntryProps, ProductListItemProps } from "./product-list-item/product-list-item.component";
import { styles } from "./product-list.style";
import { FilterProps, SortProps } from "./product-list.types";

type Variables = CollabsVariables | LikesVariables | SearchCollabsVariables | BrowseCollabsVariables | CollabsInContestVariables;

type ListItemProps = (
  item: Collab,
  width: number,
  contestEntryProps?: ContestEntryProps,
) => Omit<ProductListItemProps, "product" | "width" | "contestEntryProps">;

export interface ProductListRefProps {
  data?: PaginatedResponse<"collabs", ContestResult | Collab>["collabs"];
  fetchMore: () => void;
}

interface Props<
  TVars extends Variables,
  TSortingMethod extends SortingMethod,
  SortingEnum extends Enum<SortingEnum>,
  TResponse extends PaginatedResponse<keyof TResponse, ContestResult | Collab> = CollabsResponse,
> extends Omit<FlashListProps<Collab | ContestResult>, "renderItem" | "data"> {
  useProducts: (options: QueryHookOptions<TResponse, TVars>) => QueryResult<TResponse, keyof TResponse>;
  variables: TVars;
  title?: string;
  emptyState?: JSX.Element;
  filterProps?: FilterProps;
  sortProps?: SortProps<TSortingMethod, SortingEnum>;
  listItemProps?: ListItemProps;
  contentContainerStyle?: ContentStyle;
  forceLoading?: boolean;
  useBatchAsOffset?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent?: JSX.Element;
}

const ProductListInner = <
  TVars extends Variables,
  TSortingMethod extends SortingMethod,
  SortingEnum extends Enum<SortingEnum>,
  TResponse extends PaginatedResponse<keyof TResponse, ContestResult | Collab> = CollabsResponse,
>(
  {
    useProducts,
    variables,
    title,
    emptyState,
    filterProps,
    sortProps,
    listItemProps,
    contentContainerStyle,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ListFooterComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ListHeaderComponent,
    forceLoading = false,
    useBatchAsOffset = false,
    ...flatListProps
  }: Props<TVars, TSortingMethod, SortingEnum, TResponse>,
  ref: ForwardedRef<ProductListRefProps>,
): JSX.Element => {
  const { showErrorSnackbar } = useSnackbarContext();

  const listRef = useRef<FlashList<ContestResult | Collab>>(null);

  const { itemWidth, listPadding, nbColumns } = useResponsiveWidthListItem();

  const [refreshing, setRefreshing] = useState(false);
  const [sortingMethod, setSortingMethod] = useState("sortBy" in variables ? variables.sortBy : sortProps?.defaultSort);
  const [filteringArgs, setFilteringArgs] = useState("where" in variables ? variables.where : undefined);

  const { data, loading, networkStatus, fetchMore, refetch } = useProducts({
    variables: {
      first: nbProductsPerFetch,
      ...variables,
      ...(sortingMethod ? { sortBy: sortingMethod } : {}),
      ...(filteringArgs ? { where: filteringArgs } : {}),
    },
    onError: error => showErrorSnackbar({ refetch, error }),
  });

  const { fetchMoreItems, resetOffset } = useFetchMoreItems(
    "collabs" as keyof TResponse,
    fetchMore,
    useBatchAsOffset ? nbProductsPerFetch : undefined,
  );

  useDeepCompareEffect(() => {
    if (!useBatchAsOffset) return;

    listRef.current?.scrollToOffset({ offset: 0, animated: false });
    resetOffset();
  }, [resetOffset, variables, sortingMethod, filteringArgs]);

  const products = data?.nodes;
  const isSearch = "query" in variables && !!variables.query;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const sortButtonVisible = !!sortProps?.sortButtonVisible;
  const filterButtonVisible = !!filterProps?.filterButtonVisible;
  const emptyStateVisible = !products?.length && !isSearch && !loading;
  const estimatedUnderImageContentHeight = 40;

  useEffect(() => {
    if ("where" in variables && !filterButtonVisible) {
      setFilteringArgs(variables.where);
    }
  }, [filterButtonVisible, variables]);

  const handleFetchMore = useCallback((): void => {
    if (fetchMore && !isFetchingMore && products && data?.pageInfo.hasNextPage) {
      void fetchMoreItems(!useBatchAsOffset ? { offset: products?.length } : {});
    }
  }, [data?.pageInfo.hasNextPage, fetchMore, fetchMoreItems, isFetchingMore, products, useBatchAsOffset]);

  useImperativeHandle(ref, () => ({ data, fetchMore: handleFetchMore }), [data, handleFetchMore]);

  const handleRefreshing = useCallback(async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    if (useBatchAsOffset) {
      resetOffset();
    }
    await refetch();
    setRefreshing(false);
  }, [refetch, resetOffset, useBatchAsOffset]);

  const renderProduct = useCallback<ListRenderItem<Collab | ContestResult>>(
    ({ item }) => {
      const product = "collab" in item ? item.collab : item;
      const contestEntryProps = "collab" in item ? { winner: item.winner, contestStatus: item.contest.status } : undefined;

      return (
        <View style={styles.productWrapper}>
          <ProductListItem
            product={product}
            width={itemWidth}
            contestEntryProps={contestEntryProps}
            {...listItemProps?.(product, itemWidth, contestEntryProps)}
          />
        </View>
      );
    },
    [itemWidth, listItemProps],
  );

  const commonFlashListProps: Omit<FlashListProps<Collab | ContestResult>, "renderItem" | "data"> = useMemo(
    () => ({
      numColumns: nbColumns,
      contentContainerStyle: { padding: listPadding, ...contentContainerStyle },
      showsVerticalScrollIndicator: false,
      estimatedItemSize: itemWidth + estimatedUnderImageContentHeight,
      getItemType: () => undefined,
    }),
    [contentContainerStyle, itemWidth, listPadding, nbColumns],
  );

  const listEmpty = useMemo(
    () => (
      <ProductListEmpty
        loading={loading || forceLoading}
        loadingFlashListProps={commonFlashListProps}
        noProducts={emptyStateVisible}
        emptyStateWhenNoProducts={emptyState}
        noProductsAfterFiltering={!!filteringArgs || isSearch}
        itemWidth={itemWidth}
      />
    ),
    [commonFlashListProps, emptyState, emptyStateVisible, filteringArgs, forceLoading, isSearch, itemWidth, loading],
  );

  const listHeader = useMemo(
    () =>
      sortButtonVisible || filterButtonVisible ? (
        <ProductListFilterButtons
          title={title}
          sortProps={sortProps}
          filterProps={filterProps}
          sortingMethod={sortingMethod}
          setSortingMethod={setSortingMethod}
          filteringArgs={filteringArgs}
          setFilteringArgs={setFilteringArgs}
          ListHeaderComponent={ListHeaderComponent}
        />
      ) : undefined,
    [ListHeaderComponent, filterButtonVisible, filterProps, filteringArgs, sortButtonVisible, sortProps, sortingMethod, title],
  );

  return (
    <FlashList
      ref={listRef}
      data={products}
      renderItem={renderProduct}
      keyExtractor={item => ("collab" in item ? item.collab.collabId : item.collabId)}
      ListHeaderComponent={(emptyStateVisible ? undefined : listHeader) ?? ListHeaderComponent}
      ListFooterComponent={isFetchingMore ? <ActivityIndicator size="small" /> : ListFooterComponent}
      ListEmptyComponent={listEmpty}
      scrollEnabled={isWeb}
      onEndReached={handleFetchMore}
      onEndReachedThreshold={0.1}
      refreshing={refreshing}
      onRefresh={() => void handleRefreshing()}
      {...commonFlashListProps}
      {...flatListProps}
    />
  );
};

export const ProductList = forwardRef(ProductListInner);
