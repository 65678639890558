import React, { useCallback, FC } from "react";

import { ChatCenteredDots } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatListProps, ListRenderItem } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { Comment } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";

import CommentListItem from "../comment-list-item/comment-list-item.component";

import { styles } from "./comment-list.style";

interface Props extends Omit<FlatListProps<Comment>, "data" | "renderItem"> {
  comments?: Comment[];
  parentId: string;
  closeBottomSheet: () => void;
}

export const CommentList: FC<Props> = ({ comments, parentId, closeBottomSheet, ...props }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const handleCreatorPress = useCallback(
    (creatorUsername: string): void => {
      closeBottomSheet();
      navigation.navigate(Routes.profile, { id: creatorUsername });
    },
    [closeBottomSheet, navigation],
  );

  const renderComment = useCallback<ListRenderItem<Comment>>(
    ({ item }: { item: Comment }) => (
      <CommentListItem comment={item} parentId={parentId} onCreatorPress={() => handleCreatorPress(item.user.username)} />
    ),
    [parentId, handleCreatorPress],
  );

  return (
    <FlatList
      data={comments}
      ListEmptyComponent={<EmptyState icon={ChatCenteredDots} message={t("emptyState.comments.message")} fullScreen />}
      keyExtractor={item => item.commentId}
      showsVerticalScrollIndicator={false}
      renderItem={renderComment}
      numColumns={1}
      scrollEnabled={false}
      contentContainerStyle={styles.root}
      {...props}
    />
  );
};
