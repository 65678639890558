import { useTranslation } from "react-i18next";

import { campaignDurationInDays } from "@app/common/constants/products.const";
import { ActivityVerb, Notification, NotificationActor, NotificationObject } from "@app/common/graphql/generated/schema.graphql";
import { useConfigContext } from "@app/context/config/config.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { reportError } from "@app/utils/logger/logger.util";
import { assertNever } from "@app/utils/runtime.util";

type NotificationPropsTitle = string;

// eslint-disable-next-line complexity
export const useNotificationPropsTitle = (notification: Notification): NotificationPropsTitle => {
  const { t } = useTranslation();

  const { config } = useConfigContext();
  const { profile } = useProfileContext();

  const activity = notification.activities[0];
  const notifObject = activity.object;
  const notifActor = activity.actor;

  const likeCountObjective = config?.likeCountObjective;

  function getTitleForPromotedCollab(
    entity: NotificationActor | NotificationObject,
    promotionType: ActivityVerb.promotedCollabToDevelopment | ActivityVerb.promotedCollabToUnderReview,
  ): string {
    if (entity.__typename !== "Collab" || !profile) return "";

    const isCurrentUser = profile.userId === entity.creator.userId;

    switch (promotionType) {
      case ActivityVerb.promotedCollabToDevelopment:
        return isCurrentUser ? t("notifications.ownedConceptInDevelopment") : t("notifications.likedConceptPromotedInDevelopment");
      case ActivityVerb.promotedCollabToUnderReview:
        return isCurrentUser
          ? t("notifications.ownedConceptPromotedUnderReview", { orderCountNeeded: entity.campaign?.orderCountNeeded, campaignDurationInDays })
          : t("notifications.likedConceptPromotedUnderReview");

      default:
        return "";
    }
  }

  function getCollabName(entity: NotificationActor | NotificationObject, verb: ActivityVerb): string {
    switch (entity.__typename) {
      case "Collab":
        return entity.name;
      case "DeletedObject":
        return t("notifications.deletedCollab");
      default:
        reportError(new Error(`Received ${entity.__typename ?? ""} as object when collab was expected for notification verb ${verb}`));
        return "";
    }
  }

  function getVerbSubtitle(actorCount: number, actor: NotificationActor, entity: NotificationObject, verb: ActivityVerb): string {
    switch (verb) {
      case ActivityVerb.collabComment: {
        if (actor.__typename !== "Profile" || entity.__typename !== "Collab") {
          reportError(
            new Error(`Received ${entity.__typename ?? ""} as object when collab & Profile for actor were expected for notification verb ${verb}`),
          );
          return t("notifications.collabComment");
        }

        return t("notifications.collabComment", { count: actorCount, collabName: entity.name, username: actor.username });
      }
      case ActivityVerb.collabUpdateComment: {
        if (actor.__typename !== "Profile") {
          reportError(new Error(`Received ${entity.__typename ?? ""} as object when collab was expected for notification verb ${verb}`));
          return t("notifications.collabComment");
        }

        return t("notifications.collabUpdateComment", { count: actorCount, username: actor.username });
      }
      default: {
        reportError(new Error(`Not handled notification, received ${entity.__typename ?? ""} & notification verb ${verb}`));
        return t("notifications.collabComment");
      }
    }
  }

  switch (notification.verb) {
    case ActivityVerb.deniedCollabToDevelopment:
      return t("notifications.deniedUnderReview");
    case ActivityVerb.deniedCollabToUnderReview:
      return t("notifications.deniedInDevelopment");
    case ActivityVerb.fullLikesMilestone:
      return t("notifications.fullLikesMilestone", { count: likeCountObjective });
    case ActivityVerb.halfLikesMilestone:
      return t("notifications.halfLikesMilestone", { count: Math.floor(likeCountObjective! / 2) });
    case ActivityVerb.like:
      return t("notifications.like", { count: notification.actorCount });
    case ActivityVerb.promotedCollabToDevelopment:
    case ActivityVerb.promotedCollabToUnderReview:
      return getTitleForPromotedCollab(notifObject, notification.verb);
    case ActivityVerb.promotedCollabToUnderReviewReady:
      return t("notifications.ownedConceptPromotedUnderReviewReady");
    case ActivityVerb.underReviewEndingInTenDays:
      return t("notifications.underReviewEndingSoon", { count: 10, name: getCollabName(notifObject, notification.verb) });
    case ActivityVerb.underReviewEndingInThreeDays:
      return t("notifications.underReviewEndingSoon", { count: 3, name: getCollabName(notifObject, notification.verb) });
    case ActivityVerb.paymentFailed:
    case ActivityVerb.paymentSucceeded:
    case ActivityVerb.paymentIncoming:
    case ActivityVerb.becomeSellable:
    case ActivityVerb.becomeCreator:
    case ActivityVerb.contestEndingSoon:
    case ActivityVerb.contestGoLive:
    case ActivityVerb.contestTopUser:
      return t(`notifications.${notification.verb}`);
    case ActivityVerb.collabUpdate:
    case ActivityVerb.preorderPlaced:
      return getCollabName(notifObject, notification.verb);
    case ActivityVerb.collabUpdateComment:
    case ActivityVerb.collabComment:
      return getVerbSubtitle(notification.actorCount, notifActor, notifObject, notification.verb);
    default:
      return assertNever(notification.verb);
  }
};
