import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const imageWidth = 91;
export const imageHeight = imageWidth * imageRatio;
export const verticalGap = 24;
export const itemSize = verticalGap + imageWidth;
const marginVertical = 24;

export const styles = StyleSheet.create({
  addContainer: {
    borderWidth: 0.5,
  },
  addImageText: {
    marginTop: 4,
  },
  center: {
    alignSelf: "center",
  },
  fullWidth: {
    width: "100%",
  },
  root: {
    marginVertical,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  rowItem: {
    alignItems: "center",
    aspectRatio: imageRatio,
    justifyContent: "center",
    width: imageWidth,
  },
  scrollViewContainer: {
    columnGap: verticalGap,
    paddingVertical: marginVertical,
  },
});
