import { log } from "./logger/logger.util";

export const mockContextPromise = <T>(contextName: string, functionName: string): Promise<T> =>
  new Promise<T>((_resolve, reject) => {
    log.debug(`${contextName}:[${functionName}] is not yet accessible`);
    reject(new Error("Not ready [mockContextPromise]"));
  });

export const mockContextFn = (contextName: string, functionName: string): void => {
  log.debug(`${contextName}:[${functionName}] is not yet accessible`);
};
