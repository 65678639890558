import { REACT_APP_BLOOM_WEBSITE } from "@env";
import * as Linking from "expo-linking";

import { links } from "@app/common/constants/links.const";

import { isAndroid, isWeb } from "../device.util";
import { isCollabId, isCollabUpdateId, isContestId, isUserId } from "../id.util";

export function getShareUrl(entityId: string, prettyId?: string, entityType?: string, shareId?: string, index?: number): string {
  const id = prettyId ?? entityId;

  const completeUrl = new URL(REACT_APP_BLOOM_WEBSITE);

  if (entityType === "contestEntries") completeUrl.pathname = `/contest/${id}/entries`;
  else if (isCollabUpdateId(entityId) || entityType === "collabUpdate") completeUrl.pathname = index ? `/update/${id}/${index}` : `/update/${id}`;
  else if (isCollabId(entityId) || entityType === "collab") completeUrl.pathname = `/p/${id}`;
  else if (isUserId(entityId) || entityType === "profile") completeUrl.pathname = `/u/${id}`;
  else if (isContestId(entityId) || entityType === "contest") completeUrl.pathname = `/contest/${id}`;

  completeUrl.searchParams.set("utm_medium", isWeb ? "web" : "app");
  if (shareId) {
    completeUrl.searchParams.set("utm_campaign", shareId);
  }

  return completeUrl.toString();
}

export const openAppStore = async (): Promise<void> => {
  if (!isWeb) return;

  await Linking.openURL(isAndroid ? links.external.playStore : links.external.appStore);
};
