import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  actionButtonsContainer: {
    alignSelf: "center",
    bottom: 16,
    position: "absolute",
  },
  root: {
    flex: 1,
  },
});
