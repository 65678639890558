import { logger, consoleTransport } from "react-native-logs";

import { conditionalItem } from "../conditional-item-in-array.util";
import { crashlytics, crashlyticsTransport } from "../crashlytics/crashlytics.util";
import { isWeb } from "../device.util";
import { logToSentry, ScopeContext } from "../log-sentry.util";

import { LogLevel } from "./logger.type";

const defaultConfig = {
  levels: {
    debug: LogLevel.debug,
    info: LogLevel.info,
    warn: LogLevel.warn,
    error: LogLevel.error,
  },
  severity: __DEV__ ? "debug" : "info",
  transport: [...conditionalItem(__DEV__, consoleTransport), ...conditionalItem(!isWeb, crashlyticsTransport)],
  transportOptions: {
    colors: {
      debug: "green",
      info: "blueBright",
      warn: "yellowBright",
      error: "redBright",
    },
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  fixedExtLvlLength: false,
  enabled: true,
};

export const log = logger.createLogger<"debug" | "info" | "warn" | "error">(defaultConfig);

export const reportError = (error: Error | string, captureContext?: ScopeContext): void => {
  const typedError = typeof error === "string" ? new Error(error) : error;

  try {
    const sentryReportId = logToSentry(typedError, captureContext);
    log.error(typedError, { captureContext, sentryReportId });
    crashlytics.captureException(typedError);
  } catch (e) {
    logToSentry(e, captureContext);
  }
};
