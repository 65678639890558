import React, { FC, ReactNode } from "react";

import LottieView, { AnimationObject } from "lottie-react-native";
import { IconProps, X } from "phosphor-react-native";
import { View, ViewProps } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { Text } from "@app/components/common/text/text.component";

import { Button } from "../button/button.component";

import { styles } from "./empty-state.style";

interface Props extends ViewProps {
  icon?: (props: IconProps) => ReactNode;
  animatedIcon?: { dark: AnimationObject; light: AnimationObject };
  message: ReactNode;
  cta?: {
    label: string;
    onPress: () => void;
    loading?: boolean;
  }[];
  fullScreen?: boolean;
  onDismiss?: () => void;
}

export const EmptyState: FC<Props> = ({ icon, animatedIcon, message, cta, onDismiss, fullScreen = true, style, ...props }) => {
  const { dark, colors } = useTheme();

  const iconSize = 24;
  const animatedIconSource = dark ? animatedIcon?.dark : animatedIcon?.light;
  const fullScreenStyle = fullScreen ? styles.fullScreen : {};

  return (
    <View style={[styles.root, fullScreenStyle, style]} {...props}>
      {!!onDismiss && <IconButton icon={iconProps => <X {...iconProps} size={16} />} style={styles.dismissButton} onPress={onDismiss} />}

      <View>
        {animatedIconSource ? (
          <LottieView autoPlay loop={false} style={{ width: iconSize, height: iconSize }} source={animatedIconSource} />
        ) : icon ? (
          icon({ weight: "fill", color: colors.onBackground, size: iconSize })
        ) : null}
      </View>
      <Text variant="body2" color="tertiary" textAlign="center">
        {message}
      </Text>
      {cta?.map(action => (
        <Button fullWidth mode="outlined" loading={action.loading} onPress={action.onPress} key={action.label}>
          {action.label}
        </Button>
      ))}
    </View>
  );
};
