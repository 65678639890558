import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { DownloadImageLink } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  generateCollabImageDownloadLink: DownloadImageLink;
}

interface Variables {
  collabImageId: string;
}

interface GenerateCollabImageDownloadLinkResult extends MutationResult<Response, "generateCollabImageDownloadLink"> {
  generateCollabImageDownloadLink: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult<Response>>;
}

const generateImageLinkMutation = gql`
  mutation GenerateCollabImageDownloadLink($collabImageId: CollabImageId!) {
    generateCollabImageDownloadLink(collabImageId: $collabImageId) {
      imageUrl
    }
  }
`;

export function useGenerateCollabImageDownloadLink(): GenerateCollabImageDownloadLinkResult {
  const [generateCollabImageDownloadLink, { loading, error, data, reset }] = useMutation<Response, Variables>(generateImageLinkMutation);

  return { generateCollabImageDownloadLink, loading, error, data: data?.generateCollabImageDownloadLink, reset };
}
