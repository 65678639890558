import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { cartFields } from "@app/common/graphql/fragments.graphql";
import { Cart } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  cart: Cart;
}

interface CartVariables {
  anonymousCartId?: string;
  userId?: string;
}

const cartQuery = gql`
  query Cart($anonymousCartId: AnonymousCartId, $userId: UserId) {
    cart(anonymousCartId: $anonymousCartId, userId: $userId) {
      ...CartFields
    }
  }
  ${cartFields}
`;

export function useCart(options: QueryHookOptions<Response, CartVariables>): QueryResult<Response, "cart"> {
  const { data, loading, error, refetch } = useQuery<Response, CartVariables>(cartQuery, {
    skip: !options.variables?.anonymousCartId && !options.variables?.userId,
    ...options,
  });

  return { data: data?.cart, loading, error, refetch };
}
