import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { Checkout } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  supersellerCheckout: Checkout;
}

interface Variables {
  userId: string;
}

interface SupersellerCheckoutResult extends MutationResult<Response, "supersellerCheckout"> {
  supersellerCheckout: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const supersellerCheckoutMutation = gql`
  mutation SupersellerCheckout($userId: UserId!) {
    supersellerCheckout(userId: $userId) {
      checkoutUrl
    }
  }
`;

export function useSupersellerCheckout(): SupersellerCheckoutResult {
  const [supersellerCheckout, { loading, error, data }] = useMutation<Response, Variables>(supersellerCheckoutMutation);

  return { supersellerCheckout, loading, error, data: data?.supersellerCheckout };
}
