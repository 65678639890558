import { Contest } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { Suggestion, defaultInputsValue, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { getProductMediaId, isImage } from "@app/utils/product-media.util";

import { useLazyCollabWithMedia } from "../api/products/use-collab-with-media.hook";

import { useCachedContest } from "./use-cached-contest.hook";

interface SourceImageUtilities {
  openCreateStudioCollab: () => void;
  openEditStudio: (collabId: string) => void;
  openImageGeneration: () => void;
  openStudio: () => void;
}

interface SourceImageParams {
  userId?: string;
  contestId?: string;
  collabId?: string;
}

export function useOpenStudio({ contestId }: SourceImageParams = {}): SourceImageUtilities {
  const navigation = useNavigation();

  const { setInputs } = useStudioMiniContext();

  const { getCachedContest } = useCachedContest();
  const { getCollab } = useLazyCollabWithMedia();

  const contestToStudioContest = (contest: Contest | null): null | Suggestion =>
    contest
      ? {
          id: contest.contestId,
          name: contest.name,
          image: contest.imageUrl,
          handle: contest.handle,
          passwordRequired: contest.passwordRequired,
          category: contest.category,
        }
      : null;

  const cachedContest = getCachedContest(contestId);
  const contest = contestToStudioContest(cachedContest);

  const openImageGeneration = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.studioMiniGeneration);
  };

  const openStudio = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.root, { screen: Tabs.studio, params: { screen: Routes.studio } });
  };

  const openCreateStudioCollab = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.studioMiniCreateCollab);
  };

  const openEditStudio = (collabId: string): void => {
    void getCollab({
      variables: { collabId },
      onCompleted: data => {
        if (!data) return;
        const collab = data.collab;
        const attachedContest = getCachedContest(collab.contest?.contestId);

        setInputs({
          collabId,
          productName: collab.name,
          productDescription: collab.description,
          productCategory: { id: collab.category.categoryId, name: collab.category.name },
          media: collab.media.map(media =>
            isImage(media)
              ? { imageId: media.collabImageId, imageUrl: media.imageUrl }
              : {
                  ...media,
                  studioAnimationId: getProductMediaId(media) ?? "",
                  cloudflareId: media.cloudflareId,
                  status: media.status,
                  __typename: "StudioAnimation",
                },
          ),
          contest: contestToStudioContest(attachedContest),
        });
        navigation.navigate(Routes.studioMiniCreateCollab);
      },
    });
  };

  return {
    openCreateStudioCollab,
    openEditStudio,
    openImageGeneration,
    openStudio,
  };
}
