import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabUpdateFields } from "@app/common/graphql/fragments.graphql";
import { CollabUpdate, CreateCollabUpdateInput, PaginatedUpdates } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  createCollabUpdate: CollabUpdate;
}

interface Variables {
  collabId: string;
  input: CreateCollabUpdateInput;
  userId: string;
}

interface CreateCollabUpdateResult extends MutationResult<Response, "createCollabUpdate"> {
  createCollabUpdate: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createCollabUpdateMutation = gql`
  mutation CreateCollabUpdate($collabId: CollabId!, $input: CreateCollabUpdateInput!, $userId: UserId!) {
    createCollabUpdate(collabId: $collabId, input: $input, userId: $userId) {
      ...CollabUpdateFields
    }
  }
  ${collabUpdateFields}
`;

export function useCreateCollabUpdate(): CreateCollabUpdateResult {
  const [createCollabUpdate, { loading, error, data }] = useMutation<Response, Variables>(createCollabUpdateMutation, {
    update(cache, results, options) {
      const collabId = options.variables?.collabId ?? "";

      cache.modify({
        fields: {
          collabUpdates: (existingUpdates: PaginatedUpdates, { storeFieldName, readField, toReference }) => {
            const newUpdateId = results.data?.createCollabUpdate.collabUpdateId;

            if (!storeFieldName.includes(collabId)) return existingUpdates;

            const alreadyExist = existingUpdates.nodes?.findIndex(update => readField("collabUpdateId", update) === newUpdateId) !== -1;
            if (!newUpdateId || alreadyExist) return existingUpdates;

            return {
              ...existingUpdates,
              nodes: storeFieldName.includes('"first":1') ? [toReference(newUpdateId)] : [toReference(newUpdateId), ...(existingUpdates.nodes ?? [])],
              totalCount: existingUpdates.totalCount + 1,
            };
          },
        },
      });
    },
  });

  return { createCollabUpdate, loading, error, data: data?.createCollabUpdate };
}
