import { StyleSheet } from "react-native";

const paddingVertical = 8;
export const buttonHeight = 48 + 2 * paddingVertical;

const rowGap = 16;

export const styles = StyleSheet.create({
  animatedCheckbox: {
    height: 40,
    width: 40,
  },
  buttonContainer: {
    rowGap: 8,
  },
  buttonWrapper: {
    marginTop: "auto",
    paddingHorizontal: 16,
    paddingVertical,
  },
  mainContent: {
    flex: 1,
    justifyContent: "space-between",
    padding: 20,
    rowGap,
  },
  root: {
    flex: 1,
    rowGap,
  },
  textHeader: {
    rowGap,
  },
});
