import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile, UpdateProfileInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateProfile: Profile;
}

interface Variables {
  input: UpdateProfileInput;
  userId: string;
}

interface UpdateProfileResult extends MutationResult<Response, "updateProfile"> {
  updateProfile: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const updateProfileMutation = gql`
  mutation UpdateProfile($input: UpdateProfileInput!, $userId: UserId!) {
    updateProfile(input: $input, userId: $userId) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useUpdateProfile(): UpdateProfileResult {
  const [updateProfile, { loading, error, data }] = useMutation<Response, Variables>(updateProfileMutation);

  return { updateProfile, loading, error, data: data?.updateProfile };
}
