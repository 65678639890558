import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    rowGap: 24,
  },
  textWrapper: {
    justifyContent: "center",
  },
});
