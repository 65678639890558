import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { StartGeneratingDescriptionInput, StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  startGeneratingDescription: StudioTask;
}

interface Variables {
  input: StartGeneratingDescriptionInput;
  userId: string;
}

interface StartGeneratingDescriptionResult extends MutationResult<Response, "startGeneratingDescription"> {
  startGeneratingDescription: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startGeneratingDescriptionMutation = gql`
  mutation StartGeneratingDescription($input: StartGeneratingDescriptionInput!, $userId: UserId!) {
    startGeneratingDescription(input: $input, userId: $userId) {
      studioTaskId
    }
  }
`;

export function useStartGeneratingDescription(): StartGeneratingDescriptionResult {
  const [startGeneratingDescription, { loading, error, data, reset }] = useMutation<Response, Variables>(startGeneratingDescriptionMutation);

  return { startGeneratingDescription, loading, error, data: data?.startGeneratingDescription, reset };
}
