import React, { FC, useCallback } from "react";

import { APP_ENV } from "@env";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { useSharedValue } from "react-native-reanimated";

import { productStackMargin } from "@app/common/constants/products.const";
import { FeedEntity } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme, transparent } from "@app/common/style/theme";
import { Routes, useFeedNavigation } from "@app/common/types/navigation.type";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { FeedProps } from "@app/context/feed/feed.context";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { getFeedEntityId, isCollab, isPromo } from "@app/utils/feed.util";
import { reportError } from "@app/utils/logger/logger.util";

import { NoProductCard } from "../no-product-card/no-product-card.component";
import { ProductCard } from "../product-card/product-card.component";
import { ProductCardActionButtons } from "../product-card-action-buttons/product-card-action-buttons.component";
import { PromoCard } from "../promo-card/promo-card.component";

import { ProductStackOverlayLabel } from "./product-stack-overlay-label/product-stack-overlay-label.component";
import { styles } from "./product-stack.style";
import { Swiper } from "./swiper/swiper.component";
import { SwipeDirection } from "./swiper/swiper.types";

export interface ProductStackProps {
  products: FeedEntity[];
  feedProps: FeedProps;
  connected: boolean;
}

export const ProductStack: FC<ProductStackProps> = ({
  products,
  feedProps: { swiperRef, currentIndex, setCurrentIndex, onViewedPromo, swipeLeftProps, swipeRightProps, noCardsLeft, setNoCardsLeft, resetFeed },
  connected,
}) => {
  const navigation = useFeedNavigation();
  const { colors } = useTheme();
  const { width } = useWindowDimensions();
  const { openLogin } = useLoginContext();
  const { cardHeight } = useFeedEntityCardHeight();

  const cardPositionX = useSharedValue<number>(0);

  const handleBack = useCallback(() => {
    swiperRef?.current?.swipeBack();
  }, [swiperRef]);

  const handleSwiped = useCallback(
    (direction: SwipeDirection) => {
      if (!connected && direction === "right") {
        openLogin();
      }
    },
    [connected, openLogin],
  );

  const handleOpenDetails = useCallback(() => {
    swiperRef?.current?.press(currentCardIndex => {
      navigation.navigate(Routes.productFromFeed, { index: currentCardIndex });
    });
  }, [navigation, swiperRef]);

  const handleSwipedRight = useCallback(
    (index: number) => {
      const entity = products[index];
      if (isCollab(entity)) {
        if (!connected) {
          openLogin();
          return;
        }

        swipeRightProps.onSwipeCollab(entity.collabId);
      } else if (isPromo(entity)) {
        onViewedPromo(entity.promoId, entity.promoType);
      }
    },
    [connected, onViewedPromo, openLogin, products, swipeRightProps],
  );

  const handleSwipedLeft = useCallback(
    (index: number) => {
      const entity = products[index];
      if (isCollab(entity)) {
        swipeLeftProps.onSwipeCollab(entity.collabId);
      } else if (isPromo(entity)) {
        onViewedPromo(entity.promoId, entity.promoType);
      }
    },
    [onViewedPromo, products, swipeLeftProps],
  );

  const handleSwipeEnd = useCallback(() => (cardPositionX.value = 0), [cardPositionX]);

  const handleSwipedAll = useCallback(() => {
    setNoCardsLeft(true);
    if (connected && APP_ENV === "production") {
      reportError(new Error("[Feed]: end of feed displayed too soon"), { extra: { numberOfCardsSeen: products.length } });
    }
  }, [connected, products.length, setNoCardsLeft]);

  const renderCard = useCallback(
    (card: FeedEntity, cardIndex: number) => {
      if (isCollab(card)) return <ProductCard product={card} feedIndex={cardIndex} />;
      if (isPromo(card)) return <PromoCard promo={card} feedIndex={cardIndex} onClose={() => swiperRef?.current?.swipeRight()} onBack={handleBack} />;
      return <></>;
    },
    [handleBack, swiperRef],
  );

  const overlayLabels = isCollab(products[currentIndex])
    ? {
        right: () => <ProductStackOverlayLabel label={swipeRightProps.overlayLabel} color={colors.secondary} position="right" />,
        left: () => <ProductStackOverlayLabel label={swipeLeftProps.overlayLabel} color={colors.error} position="left" />,
        up: undefined,
      }
    : undefined;

  const overlayBackgroundColors = isCollab(products[currentIndex])
    ? {
        right: darkTheme.colors.secondary,
        left: darkTheme.colors.error,
        up: undefined,
      }
    : undefined;

  return (
    <View style={styles.root}>
      {!!products.length && !noCardsLeft ? (
        <>
          <Swiper
            ref={swiperRef}
            cards={products}
            keyExtractor={cardData => `product-card-${getFeedEntityId(cardData) ?? ""}`}
            renderCard={renderCard}
            globalTranslationX={cardPositionX}
            onSwipeEnd={handleSwipeEnd}
            onSwipedRight={handleSwipedRight}
            onSwipedLeft={handleSwipedLeft}
            onSwipedAll={handleSwipedAll}
            onSwiped={handleSwiped}
            onCardIndexChange={setCurrentIndex}
            overlayLabels={overlayLabels}
            overlayBackgroundColors={overlayBackgroundColors}
            disableTopSwipe
            disableRightSwipe={!connected}
            showSecondCard
            stackSize={4}
            cardHeight={cardHeight}
            cardBackgroundColor={colors.background}
            stackBackgroundColor={transparent}
            cardVerticalMargin={productStackMargin}
            cardHorizontalMargin={productStackMargin}
            swipeAnimationDuration={350}
            horizontalThreshold={width / 5}
            stackScale={1.2}
          />

          {isCollab(products[currentIndex]) && (
            <View style={styles.actionButtonsContainer}>
              <ProductCardActionButtons
                backDisabled={currentIndex === 0}
                cardPositionX={cardPositionX}
                swipeRightProps={{
                  onSwipe: () => swiperRef?.current?.swipeRight(),
                  content: swipeRightProps.content,
                }}
                swipeLeftProps={{
                  onSwipe: () => swiperRef?.current?.swipeLeft(),
                  content: swipeLeftProps.content,
                }}
                onBack={handleBack}
                onDetails={handleOpenDetails}
              />
            </View>
          )}
        </>
      ) : (
        <NoProductCard resetFeed={resetFeed} />
      )}
    </View>
  );
};
