import React, { JSX } from "react";

import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Enum } from "@app/common/types/enum.type";
import { FilteringArgs } from "@app/common/types/filtering.type";
import { SortingMethod } from "@app/common/types/sorting.type";
import { FilterButton } from "@app/components/common/filter-button/filter-button.component";
import { SortButton } from "@app/components/common/sort-button/sort-button.component";
import { Text } from "@app/components/common/text/text.component";

import { FilterProps, SortProps } from "../product-list.types";

import { styles } from "./product-list-filter-buttons.style";

interface Props<TSortingMethod extends SortingMethod, SortingEnum extends Enum<SortingEnum>, TFilteringArgs extends FilteringArgs> {
  title?: string;
  sortProps?: SortProps<TSortingMethod, SortingEnum>;
  filterProps?: FilterProps;
  sortingMethod?: TSortingMethod;
  setSortingMethod: (sort: TSortingMethod) => void;
  filteringArgs?: TFilteringArgs;
  setFilteringArgs: (args?: TFilteringArgs) => void;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent?: JSX.Element;
}

export const ProductListFilterButtons = <
  TSortingMethod extends SortingMethod,
  SortingEnum extends Enum<SortingEnum>,
  TFilteringArgs extends FilteringArgs,
>({
  title,
  sortProps,
  filterProps,
  sortingMethod,
  setSortingMethod,
  filteringArgs,
  setFilteringArgs,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent,
}: Props<TSortingMethod, SortingEnum, TFilteringArgs>): JSX.Element | null => {
  const { colors } = useTheme();

  const sortButtonVisible = !!sortProps?.sortButtonVisible;
  const filterButtonVisible = !!filterProps?.filterButtonVisible;

  const filterSortButtons = (
    <View style={[styles.header, { backgroundColor: colors.background }]}>
      {filterButtonVisible && (
        <FilterButton
          filters={filterProps.filters}
          filteringArgs={filteringArgs}
          setFilteringArgs={setFilteringArgs}
          style={styles.filterButtonContainer}
        />
      )}
      {title && (
        <Text variant="h8" textAlign="center" style={styles.title}>
          {title}
        </Text>
      )}
      {sortButtonVisible && (
        <SortButton
          sortingOptions={Object.keys(sortProps.sortingMethodEnum)}
          sortingMethod={sortingMethod}
          excludedSort={sortProps.excludedSort}
          setSortingMethod={setSortingMethod}
          style={styles.sortButtonContainer}
        />
      )}
    </View>
  );

  return ListHeaderComponent ? (
    <View style={{ backgroundColor: colors.background }}>
      {ListHeaderComponent}
      {filterSortButtons}
    </View>
  ) : (
    filterSortButtons
  );
};
