import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { contestFields } from "@app/common/graphql/fragments.graphql";
import { Contest } from "@app/common/graphql/generated/schema.graphql";

export function useCachedContest(): { getCachedContest: (contestId?: string) => Contest | null } {
  const client = useApolloClient();

  const getCachedContest = useCallback(
    (contestId?: string): Contest | null => {
      if (!contestId) return null;

      return client.readFragment<Contest>({
        id: contestId,
        fragment: contestFields,
        fragmentName: "ContestFields",
      });
    },
    [client],
  );

  return { getCachedContest };
}
