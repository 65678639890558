import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabUpdateFields } from "@app/common/graphql/fragments.graphql";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteCollabUpdate: Collab;
}

interface Variables {
  collabUpdateId: string;
}

interface DeleteCollabUpdateResult extends MutationResult<Response, "deleteCollabUpdate"> {
  deleteUpdate: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteCollabUpdateMutation = gql`
  mutation DeleteCollabUpdate($collabUpdateId: CollabUpdateId!) {
    deleteCollabUpdate(collabUpdateId: $collabUpdateId) {
      ...CollabUpdateFields
    }
  }
  ${collabUpdateFields}
`;

export function useDeleteCollabUpdate(): DeleteCollabUpdateResult {
  const [deleteUpdate, { loading, error, data }] = useMutation<Response, Variables>(deleteCollabUpdateMutation, {
    update: (cache, _result, options) => {
      cache.evict({ id: options.variables?.collabUpdateId });
    },
  });

  return { deleteUpdate, loading, error, data: data?.deleteCollabUpdate };
}
