import React, { FC } from "react";

import { View } from "react-native";

import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";

import { OnboardingStep } from "./onboarding-step/onboarding-step.component";
import { styles } from "./onboarding-stepper.style";

interface Props {
  hasMultipleSteps: boolean;
}

export const OnboardingStepper: FC<Props> = ({ hasMultipleSteps }) => {
  const { numberOfStepsToShow, currentStepIndex } = useOnboardingContext();

  if (!hasMultipleSteps) return null;

  return (
    <View style={styles.root}>
      {Array.from(Array(numberOfStepsToShow).keys()).map((_, index) => (
        <OnboardingStep key={`tab-onboarding-step-${index}`} isActive={index === currentStepIndex} hasMultipleSteps={hasMultipleSteps} />
      ))}
    </View>
  );
};
