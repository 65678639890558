import { StyleSheet } from "react-native";

import { iconSize } from "../stepper.style";

export const styles = StyleSheet.create({
  iconWrapper: {
    alignItems: "center",
    height: iconSize,
    justifyContent: "center",
    padding: 8,
    width: iconSize,
  },
  label: {
    width: 500,
  },
  root: {
    alignItems: "center",
  },
});
