import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  actionButtonsContainer: {
    alignSelf: "center",
    position: "absolute",
  },
  bottomIosHandle: {
    flex: 0,
  },
  buttonsContainer: {
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 8,
    rowGap: 8,
  },
  marginBottom: {
    marginBottom: isWeb ? 80 : undefined,
    paddingBottom: !isWeb ? 60 : undefined,
  },
  root: {
    paddingBottom: 20,
  },
});
