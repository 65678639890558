import React, { FC } from "react";

import { IconButton, IconButtonProps, useTheme } from "react-native-paper";

import ArrowsOut from "@app/assets/icons/arrows-out-duotone.svg";
import { Routes, useNavigation } from "@app/common/types/navigation.type";

interface Props extends Omit<IconButtonProps, "icon"> {
  videoUrl?: string;
  thumbnailUrl?: string;
  cloudflareId?: string;
}

export const ZoomOnVideoButton: FC<Props> = ({ videoUrl, thumbnailUrl, cloudflareId, ...props }) => {
  const { colors } = useTheme();
  const navigation = useNavigation();

  return (
    videoUrl && (
      <IconButton
        icon={({ size, ...iconProps }) => <ArrowsOut {...iconProps} width={size} height={size} />}
        iconColor={colors.common.white}
        size={24}
        onPress={() => navigation.navigate(Routes.video, { videoUrl, posterUrl: thumbnailUrl, cloudflareId })}
        {...props}
      />
    )
  );
};
