import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { DeletedLikes } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  deleteAllLikes: DeletedLikes;
}

interface Variables {
  userId: string;
}

interface DeleteAllLikesResult extends MutationResult<Response, "deleteAllLikes"> {
  deleteAllLikes: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteAllLikesMutation = gql`
  mutation DeleteAllLikes($userId: UserId!) {
    deleteAllLikes(userId: $userId) {
      deletedLikes
    }
  }
`;

export function useDeleteAllLikes(): DeleteAllLikesResult {
  const [deleteAllLikes, { loading, error, data }] = useMutation<Response, Variables>(deleteAllLikesMutation);

  return { deleteAllLikes, loading, error, data: data?.deleteAllLikes };
}
