import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  iconButton: {
    alignSelf: "flex-end",
    borderWidth: 1,
    bottom: 20,
    height: 60,
    position: "absolute",
    right: 20,
    width: 60,
  },
});
