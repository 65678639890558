import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreProfileFields, userFacetsFields } from "@app/common/graphql/fragments.graphql";
import { SearchUsersFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

import { ProfilesResponse } from "./use-browse-users.hook";

export interface SearchUsersVariables {
  first?: number;
  offset?: number;
  query: string;
  where?: SearchUsersFilteringArgs;
}

const searchUsersQuery = gql`
  query SearchUsers($first: Int, $offset: Int, $query: String!, $where: SearchUsersFilteringArgs) {
    profiles: searchUsers(first: $first, offset: $offset, query: $query, where: $where) {
      facets {
        ...UserFacetsFields
      }
      nodes {
        ...CoreProfileFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${userFacetsFields}
  ${coreProfileFields}
`;

export function useSearchUsers(options: QueryHookOptions<ProfilesResponse, SearchUsersVariables>): QueryResult<ProfilesResponse, "profiles"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<ProfilesResponse, SearchUsersVariables>(searchUsersQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.profiles, loading, error, networkStatus, fetchMore, refetch };
}
