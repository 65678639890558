import { StyleSheet } from "react-native";

export const paddingHorizontal = 20;

export const styles = StyleSheet.create({
  desription: {
    paddingHorizontal: 36,
  },
  footer: {
    paddingHorizontal,
  },
  footerContent: {
    flex: 1,
    rowGap: 24,
  },
  root: {
    paddingBottom: 16,
  },
});
