import React, { FC, ReactNode } from "react";

import { CaretRight, ChatTeardropText, CubeFocus, IconProps, ImageSquare, TShirt } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { styles } from "./studio-select-flow.style";

interface Item {
  icon: (iconProps: IconProps) => ReactNode;
  onPress: () => void;
  text: string;
}

interface Props {
  onSelectFlow: (flow: StudioFlowType) => void;
  hideCatalogFlow: boolean;
}

export const StudioSelectFlow: FC<Props> = ({ onSelectFlow, hideCatalogFlow }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const wrapWithLoginFlow = (callback: () => void): (() => void) => {
    if (!profile) {
      return () => openLogin();
    } else {
      return callback;
    }
  };

  const options: Item[] = [
    {
      icon: ChatTeardropText,
      onPress: wrapWithLoginFlow(() => onSelectFlow(StudioFlowType.blank)),
      text: t("studioMini.prompt.wizard.describeIdea"),
    },
    {
      icon: ImageSquare,
      onPress: wrapWithLoginFlow(() => onSelectFlow(StudioFlowType.image)),
      text: t("studioMini.prompt.wizard.startFromImage"),
    },
    {
      icon: CubeFocus,
      onPress: wrapWithLoginFlow(() => onSelectFlow(StudioFlowType.image3d)),
      text: t("studioMini.prompt.wizard.makeImage3d"),
    },
    ...conditionalItem(!hideCatalogFlow, {
      icon: TShirt,
      onPress: wrapWithLoginFlow(() => onSelectFlow(StudioFlowType.catalog)),
      text: t("studioMini.prompt.wizard.pickItem"),
    }),
  ];

  return (
    <View style={styles.contentContainer}>
      <Text variant="body2" textAlign="center" style={styles.textContainer}>
        {t("studioMini.prompt.wizard.howToStart")}
      </Text>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {options.map(({ text, onPress: onPressListItem, icon: Icon }) => (
        <ListItem
          key={text}
          title={text}
          left={<Icon color={colors.onBackground} />}
          onPress={onPressListItem}
          right={<CaretRight color={colors.onBackground} />}
          style={[styles.listContainer, { borderColor: colors.outline, borderRadius: roundness }]}
        />
      ))}
    </View>
  );
};
