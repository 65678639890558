import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  chip: {
    alignSelf: "flex-start",
  },
  chipPadding: {
    paddingVertical: 4,
  },
  imageContainer: {
    aspectRatio: 1,
    width: "100%",
  },
  linearGradient: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  rewardText: {
    flexShrink: 1,
  },
  root: {
    alignItems: "flex-end",
    columnGap: 8,
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    padding: 20,
    position: "absolute",
    width: "100%",
  },
  row: {
    alignItems: "flex-start",
    columnGap: 4,
    flexDirection: "row",
    flexShrink: 1,
  },
  spacing: {
    flexShrink: 1,
    gap: 4,
  },
});
