import { createContext, useContext } from "react";

import { Dict, Mixpanel as MixpanelWeb } from "mixpanel-browser";
import { Mixpanel, MixpanelProperties } from "mixpanel-react-native";

import { TrackEvent } from "@app/common/enums/track-events.enum";

export interface MixpanelContextInterface {
  mixpanel?: Mixpanel | MixpanelWeb | null;
  trackInMixpanel: (eventName: TrackEvent, properties?: MixpanelProperties | Dict) => void;
}

export const MixpanelContext = createContext<MixpanelContextInterface>({
  trackInMixpanel: (_eventName: TrackEvent, _properties?: MixpanelProperties) => undefined,
});

export const useMixpanelContext = (): MixpanelContextInterface => useContext(MixpanelContext);
