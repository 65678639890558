import React, { FC, useCallback, useEffect } from "react";

import { SquaresFour } from "phosphor-react-native";
import { View, Pressable } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from "react-native-reanimated";

import HeartStraight from "@app/assets/icons/heart-straight-with-eye-slash-fill.svg";
import CurrencyCircleDollar from "@app/assets/icons/rewards-with-eye-slash-fill.svg";
import { ProfileTab } from "@app/common/enums/profile-tab.enum";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./profile-tabs.style";

interface Props {
  currentTab: ProfileTab;
  scrollToTab: (tab: ProfileTab) => void;
}

export const ProfileTabs: FC<Props> = ({ currentTab, scrollToTab }) => {
  const { colors } = useTheme();
  const { width } = useWindowDimensions();

  const iconSize = 24;

  const tabs = [
    { key: ProfileTab.creations, icon: (iconProps: { color: string }) => <SquaresFour {...iconProps} weight="fill" size={iconSize} /> },
    { key: ProfileTab.likes, icon: (iconProps: { color: string }) => <HeartStraight {...iconProps} width={iconSize} height={iconSize} /> },
    {
      key: ProfileTab.supercoins,
      icon: (iconProps: { color: string }) => <CurrencyCircleDollar {...iconProps} width={iconSize} height={iconSize} />,
    },
  ];

  const tabWidth = (width - 40) / tabs.length;

  const computeOffset = useCallback((tab: ProfileTab) => tabWidth * (tab - 1), [tabWidth]);
  const initalOffset = computeOffset(ProfileTab.creations);
  const offset = useSharedValue(initalOffset);

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [{ translateX: offset.value }],
  }));

  const handlePressTab = useCallback(
    (tab: ProfileTab): void => {
      const newOffset = computeOffset(tab);

      offset.value = withTiming(newOffset);
    },
    [computeOffset, offset],
  );

  useEffect(() => {
    handlePressTab(currentTab);
  }, [currentTab, handlePressTab]);

  return (
    <View style={[{ backgroundColor: colors.background }, styles.container]}>
      <View style={styles.tabs}>
        {tabs.map(tab => (
          <Pressable
            key={tab.key}
            style={[styles.tab, { width: tabWidth }]}
            onPress={() => {
              handlePressTab(tab.key);
              scrollToTab(tab.key);
            }}>
            {tab.icon({ color: currentTab === tab.key ? colors.onBackground : colors.action.active })}
          </Pressable>
        ))}
      </View>
      <Animated.View style={[styles.animatedBorder, { backgroundColor: colors.onBackground, width: tabWidth }, animatedStyles]} />
    </View>
  );
};
