import { useTranslation } from "react-i18next";

import { CreateCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { TextInputProps } from "@app/components/common/text-input/text-input.component";
import { useProductUpdateContext } from "@app/context/product-update/product-update.context";

type MapInputProps = Record<
  keyof Omit<CreateCollabUpdateInput, "imageIds">,
  { inputProps: TextInputProps; value: string; setValue: (value: string) => void }
>;

interface ProfileInputPropsUtils {
  commonInputProps: MapInputProps;
}

export function useProductUpdateInputProps(): ProfileInputPropsUtils {
  const { t } = useTranslation();
  const {
    inputs: { title, content },
    setInputs,
  } = useProductUpdateContext();

  const commonInputProps: MapInputProps = {
    title: {
      inputProps: {
        label: t("productUpdates.fields.title.label"),
        includeCharacterCount: true,
        maxLength: 100,
        required: true,
      },
      value: title,
      setValue: (value: string) => setInputs(prev => ({ ...prev, title: value })),
    },
    content: {
      inputProps: {
        label: t("productUpdates.fields.content.label"),
        placeholder: t("productUpdates.fields.content.placeholder"),
        includeCharacterCount: true,
        maxLength: 10000,
        multiline: true,
        numberOfLines: 10,
        required: true,
      },
      value: content,
      setValue: (value: string) => setInputs(prev => ({ ...prev, content: value })),
    },
  };

  return { commonInputProps };
}
