import { createContext, useContext } from "react";

import { AuthContextInterface, AuthContextStateInterface } from "./auth.types";

export const initialAuthContext: AuthContextStateInterface = {
  connected: false,
  email: null,
  id: null,
  isAdmin: false,
  loading: true,
  providers: [],
  restored: false,
  roles: [],
  token: null,
  verified: null,
};

export const AuthContext = createContext<AuthContextInterface>({
  logout: () => Promise.resolve(),
  setVerified: () => undefined,
  state: { ...initialAuthContext },
});

export const useAuthContext = (): AuthContextInterface => useContext(AuthContext);
