import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  button: {
    height: 48,
    width: 48,
  },
  buttonContainer: {
    alignItems: "center",
  },
  buttonOverlay: {
    bottom: -8,
    position: "absolute",
    right: -8,
  },
  content: {
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 20,
    rowGap: 16,
  },
  root: {
    marginVertical: isWeb ? 0 : 16,
  },
  titleContainer: {
    justifyContent: "center",
    padding: 8,
    rowGap: 16,
  },
});
