import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { CommentLike, LikeCommentInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  likeComment: CommentLike;
}

interface Variables {
  input: LikeCommentInput;
  userId: string;
}

interface LikeCommentResult extends MutationResult<Response, "likeComment"> {
  likeComment: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const likeCommentMutation = gql`
  mutation LikeComment($input: LikeCommentInput!, $userId: UserId!) {
    likeComment(input: $input, userId: $userId) {
      commentId
      userId
    }
  }
`;

export function useLikeComment(): LikeCommentResult {
  const [likeComment, { loading, error, data }] = useMutation<Response, Variables>(likeCommentMutation, {
    update: (cache, _results, options) => {
      cache.modify({
        id: options.variables?.input.commentId,
        fields: {
          liked: () => true,
          likeCount: (existingCount: number) => existingCount + 1,
        },
      });
    },
  });

  return { likeComment, loading, error, data: data?.likeComment };
}
