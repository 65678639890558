import React, { FC, useCallback, useEffect, useRef, useState, JSX } from "react";

import { ArrowDown, CaretDown, Info } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, View } from "react-native";
import { TextInput as RNPTextInput, ActivityIndicator, useTheme, RadioButton } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { Stage, StageDetails } from "@app/common/graphql/generated/schema.graphql";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDetailedProgress } from "@app/hooks/api/god-mode/use-detailed-progress.hook";
import { useCollabTransition } from "@app/hooks/utils/use-collab-transition.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./update-collab-stage.style";

export const UpdateCollabStageScreen: FC<NavigationProps<Routes.updateCollabStage>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { navigateToWebview } = useNavigateToWebview();

  const collabId = route.params?.collabId;

  const {
    data: detailedProgress,
    loading,
    refetch,
  } = useDetailedProgress({
    variables: { collabId },
    onError: error => {
      showErrorSnackbar({ error, refetch });
    },
  });

  const [newStage, setNewStage] = useState<StageDetails | undefined>();

  const { getInputProps, isReadyToSubmit, updateStage, isLoading } = useCollabTransition(newStage?.detailedStage, collabId);

  const handleSelectStage = (value?: StageDetails) => () => {
    setNewStage(value);
    sheetRef?.current?.close();
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button disabled={loading || !newStage || !isReadyToSubmit} onPress={updateStage} loading={loading || isLoading}>
          {t("cta.apply")}
        </Button>
      ),
    });
  }, [isLoading, isReadyToSubmit, loading, navigation, newStage, t, updateStage]);

  const feedInfo = useCallback(
    (title: string, stage: Stage) => (
      <ListItem
        title={title}
        titleProps={{ color: "onPrimary", variant: "subtitle1" }}
        subtitle={t(`enum.stage.${stage}`)}
        subtitleProps={{ color: "onPrimary", variant: "body2" }}
        left={<Info color={colors.onPrimary} size={19} />}
        contentContainerStyle={styles.feedInfoContent}
        style={[{ backgroundColor: colors.primary, borderRadius: roundness }, styles.feedInfo]}
      />
    ),
    [colors.onPrimary, colors.primary, roundness, t],
  );

  const renderDetailedStageOption = useCallback<ListRenderItem<StageDetails>>(
    ({ item }) => {
      const texts: { label: string; description?: string } = t(`godMode.updateStage.detailedStage.${item.detailedStage}`, {
        returnObjects: true,
      });

      return (
        <ListItem
          title={texts.label}
          titleProps={{ variant: "subtitle1" }}
          subtitle={texts.description}
          subtitleProps={{ variant: "body2", color: "tertiary" }}
          left={<RadioButton.Android value={item.detailedStage} status={item.detailedStage === newStage?.detailedStage ? "checked" : "unchecked"} />}
          onPress={handleSelectStage(item)}
          style={styles.stageOption}
        />
      );
    },
    [newStage?.detailedStage, t],
  );

  return (
    <ScreenWrapper withScrollView withKeyboardAvoidingView={!!getInputProps().length} contentContainerStyle={styles.root}>
      <Text variant="helperText" textDecorationLine="underline" onPress={() => navigateToWebview(links.external.collabTransitionsDoc)}>
        {t("godMode.updateStage.referToThisDocumentation")}
      </Text>

      {loading && !detailedProgress && <ActivityIndicator size="large" />}

      {detailedProgress && (
        <View style={styles.form}>
          <TextInput
            label={t("godMode.updateStage.currentStage")}
            value={t(`godMode.updateStage.detailedStage.${detailedProgress.detailedCurrentStage.detailedStage}.label`)}
            disabled
            editable={false}
          />
          {feedInfo(t("godMode.updateStage.currentFeed"), detailedProgress.detailedCurrentStage.stage)}

          <View style={styles.arrowDownContainer}>
            <ArrowDown color={colors.onBackground} size={24} />
          </View>

          <TextInput
            label={t("godMode.updateStage.newStage")}
            right={<RNPTextInput.Icon icon={({ color }) => <CaretDown color={color} />} />}
            value={newStage ? t(`godMode.updateStage.detailedStage.${newStage.detailedStage}.label`) : ""}
            required
            editable={false}
            onPressIn={() => sheetRef?.current?.open()}
          />

          {newStage && (
            <>
              {feedInfo(t("godMode.updateStage.newFeed"), newStage.stage)}
              <FlatList
                data={getInputProps()}
                renderItem={({ item }) => {
                  if ("label" in item) {
                    return <TextInput {...item} />;
                  }

                  return item as JSX.Element;
                }}
                scrollEnabled={isWeb}
                ItemSeparatorComponent={() => <View style={styles.inputSeparator} />}
              />
            </>
          )}
        </View>
      )}

      <BottomSheet ref={sheetRef}>
        <FlatList
          data={detailedProgress?.detailedAllowedTransitions}
          renderItem={renderDetailedStageOption}
          ListEmptyComponent={<Text>{t("godMode.noSuggestion")}</Text>}
        />
      </BottomSheet>
    </ScreenWrapper>
  );
};
