import React, { FC } from "react";

import { BlurView } from "expo-blur";
import { WifiSlash } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useAppStateContext } from "@app/context/app-state/app-state.context";
import { useAuthContext } from "@app/context/auth/auth.context";

import { styles } from "./no-internet.style";

export const NoInternet: FC = () => {
  const { isInternetWorking } = useAppStateContext();
  const { logout, state } = useAuthContext();
  const { dark, colors } = useTheme();
  const { t } = useTranslation();

  const shouldDisplayNetworkUnavailable = !isInternetWorking && state.connected;

  if (!shouldDisplayNetworkUnavailable) return null;

  return (
    <BlurView tint={dark ? "dark" : "light"} intensity={100} style={styles.root}>
      <SafeAreaView edges={["left", "bottom", "right", "top"]} style={styles.content}>
        <WifiSlash color={colors.onBackground} weight="fill" size={50} />
        <Text variant="title">{t("error.noInternet")}</Text>
        <ActivityIndicator size="small" />
        <Text variant="body2" textAlign="center">
          {t("noInternet.description")}
        </Text>

        <View>
          <Button mode="outlined" onPress={() => void logout()}>
            {t("cta.logout")}
          </Button>
        </View>
      </SafeAreaView>
    </BlurView>
  );
};
