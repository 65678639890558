import React, { FC, ReactElement, ReactNode, ReactPortal, useState } from "react";

import RNErrorBoundary from "react-native-error-boundary";

import { crashlytics } from "@app/utils/crashlytics/crashlytics.util";
import { logToSentry } from "@app/utils/log-sentry.util";
import { log } from "@app/utils/logger/logger.util";

import { withSentryId } from "./error-boundary.util";
import { ErrorFallback } from "./error-fallback.component";

interface Props {
  children: ReactElement | Iterable<ReactNode> | ReactPortal;
}

export const ErrorBoundary: FC<Props> = ({ children }) => {
  const [sentryId, setSentryId] = useState("");

  const handleError = (error: Error, stacktrace: string): void => {
    const sentryReportId = logToSentry(error, { level: "fatal", extra: { stacktrace } });
    log.error(error, { sentryReportId, stacktrace });
    setSentryId(sentryReportId);
    crashlytics.captureException(error);
  };

  return (
    <RNErrorBoundary onError={handleError} FallbackComponent={withSentryId(ErrorFallback, sentryId)}>
      {children}
    </RNErrorBoundary>
  );
};
