import React, { FC } from "react";

import { Image } from "expo-image";
import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import supercoin from "@app/assets/images/gold-dollar-coins-straight.png";
import { MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { supercoinsGradient } from "@app/common/style/common.style";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { useSmallestMilestoneObjective } from "@app/hooks/api/supercoins/use-smallest-milestone-objective.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./sharing-reward-alert.style";

export const SharingRewardAlert: FC = () => {
  const { t } = useTranslation();
  const { roundness } = useTheme();

  const { data: firstReferralMilestone } = useSmallestMilestoneObjective({
    variables: { where: { milestoneType: MilestoneType.referralMilestone } },
  });

  return (
    <LinearGradient colors={supercoinsGradient} locations={[0, 1]} style={[styles.root, { borderRadius: roundness }]}>
      <ListItem
        title={t("supercoins.onShare", { reward: formatPriceUSD(firstReferralMilestone?.reward, { withoutUSD: true }) })}
        titleProps={{ variant: "caption", color: "common.white", numberOfLines: 0 }}
        right={<Image source={supercoin} style={styles.supercoinImage} />}
      />
    </LinearGradient>
  );
};
