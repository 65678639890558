import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  deleteButton: {
    position: "absolute",
    right: -20,
    top: -20,
  },
  videoIndicator: {
    left: 0,
    padding: 4,
    position: "absolute",
    top: 0,
  },
});
