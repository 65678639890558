import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { CartItem } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { LikeButton } from "@app/components/products/like-button/like-button.component";
import { formatPriceUSD } from "@app/utils/price.util";

import { CartOrderProductInfo } from "../../cart-order-product-info/cart-order-product-info.component";
import { DeleteCartItemButton } from "../delete-cart-item-button/delete-cart-item-button.component";

import { styles } from "./cart-list-item.style";

interface Props {
  cartItem: CartItem;
}

export const CartListItem: FC<Props> = ({ cartItem }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { collab, variant } = cartItem;
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const price = variant?.price ?? collab.price;
  const compareAtPrice = variant?.compareAtPrice ?? collab.compareAtPrice;

  useEffect(() => {
    setQuantity(cartItem.quantity);
  }, [cartItem.quantity]);

  const handleViewDetails = (): void => navigation.navigate(Routes.product, { id: cartItem.collab.handle });

  return (
    <View style={styles.root}>
      <CartOrderProductInfo
        id={cartItem.cartItemId}
        context="cart"
        product={collab}
        variant={variant}
        quantity={quantity}
        price={price != null ? formatPriceUSD(price * quantity) ?? undefined : undefined}
        compareAtPrice={compareAtPrice ? formatPriceUSD(compareAtPrice * quantity) ?? undefined : undefined}
        setQuantity={setQuantity}
      />

      <Button mode="outlined" fullWidth onPress={handleViewDetails}>
        {t("cart.viewProductDetails")}
      </Button>
      <LikeButton collabId={collab.collabId} liked={collab.liked} loading={false} disabled={false} mode={collab.liked ? "contained" : "outlined"} />
      <DeleteCartItemButton cartItemId={cartItem.cartItemId} />
    </View>
  );
};
