import { createContext, useContext } from "react";

import { CartItem, CartIdentity } from "@app/common/graphql/generated/schema.graphql";

export interface CartContextType {
  cartItems?: CartItem[];
  cartIdentity?: Omit<CartIdentity, "__typename">;
  loading: boolean;
  loadingCartIdentity: boolean;
  refetch: () => Promise<void>;
}

export const CartContext = createContext<CartContextType>({ refetch: () => Promise.resolve(undefined), loading: false, loadingCartIdentity: false });

export const useCartContext = (): CartContextType => useContext(CartContext);
