/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { SignOut, DotsThreeVertical, GearSix, FlagBanner, Star, SketchLogo, ChatDots, Question, Sparkle, TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton, useTheme } from "react-native-paper";

import VerifiedBadge from "@app/assets/icons/verified-badge.svg";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { CreatorStatus, Profile } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useProfileNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import {
  NestedBottomSheetWithList,
  ListItemProps,
} from "@app/components/common/bottom-sheet/nested-bottom-sheet-with-list/nested-bottom-sheet-with-list.component";
import { ReportDialog } from "@app/components/common/report-dialog/report-dialog.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useSetCreatorStatus } from "@app/hooks/api/god-mode/use-set-creator-status.hook";
import { useSetFeaturedCreator } from "@app/hooks/api/god-mode/use-set-featured-creator.hook";
import { featuredCreatorQuery } from "@app/hooks/api/profile/use-featured-creator.hook";
import { useContactUs } from "@app/hooks/utils/use-contact-us.hook";
import { useDeleteUser } from "@app/hooks/utils/use-delete-user.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { SuperplanAlert } from "../../superplans/superplan-alert/superplan-alert.component";

import { styles } from "./profile-more-button.style";

interface Props {
  profile?: Profile;
  isCurrentUser: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const ProfileMoreButton: FC<Props> = ({ profile, isCurrentUser }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const navigation = useProfileNavigation();
  const { colors } = useTheme();
  const { state, logout } = useAuthContext();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const { contactUs } = useContactUs();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const [indexNestedViewVisible, setIndexNestedViewVisible] = useState<number | undefined>(undefined);
  const [reportDialogVisible, setReportDialogVisible] = useState(false);

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const handleCloseBottomSheet = useCallback((): void => {
    sheetRef?.current?.close();
  }, []);

  const handleLearnMoreAboutSuperplans = (tab: SuperplanType | undefined): void => {
    handleCloseBottomSheet();
    navigation.navigate(Routes.superplans, { tab });
  };

  const creatorStatus = profile?.creatorStatus;
  const userId = profile?.userId;
  const isAdmin = state.isAdmin;
  const { setCreatorStatus } = useSetCreatorStatus();
  const { setFeaturedCreator } = useSetFeaturedCreator();
  const { deleteUser } = useDeleteUser();

  const handlePressWrapper = useCallback(
    (onPress: () => void) => {
      handleCloseBottomSheet();
      onPress();
    },
    [handleCloseBottomSheet],
  );

  const handleToggleCreatorStatus = useCallback((): void => {
    handleCloseBottomSheet();
    if (!userId || !creatorStatus) return;

    const newCreatorStatus = creatorStatus === CreatorStatus.creator ? CreatorStatus.user : CreatorStatus.creator;
    void setCreatorStatus({ variables: { input: { creatorStatus: newCreatorStatus }, userId }, onError: error => showErrorSnackbar(error) });
  }, [creatorStatus, handleCloseBottomSheet, setCreatorStatus, showErrorSnackbar, userId]);

  const handleSetFeaturedCreator = useCallback((): void => {
    handleCloseBottomSheet();
    if (!userId) return;

    if (creatorStatus !== CreatorStatus.creator) {
      showErrorSnackbar({ message: t("godMode.setFeatured.errorNotACreator") });
      return;
    }

    Alert.alert(t("error.warning"), t("godMode.setFeatured.confirmationCreator"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () => {
          void setFeaturedCreator({
            variables: { userId },
            onCompleted: () => {
              showSuccessSnackbar();
              void client.refetchQueries({ include: [featuredCreatorQuery] });
            },
            onError: error => showErrorSnackbar(error),
          });
        },
      },
    ]);
  }, [client, creatorStatus, handleCloseBottomSheet, setFeaturedCreator, showErrorSnackbar, showSuccessSnackbar, t, userId]);

  const handleDeleteUser = useCallback((): void => {
    sheetRef?.current?.close();
    deleteUser(userId);
  }, [deleteUser, userId]);

  const handleHelp = useCallback(() => {
    sheetRef?.current?.close();

    contactUs();
  }, [contactUs]);

  const handleLogout = useCallback(() => {
    logout()
      .then(() => sheetRef.current?.close())
      .catch(() => undefined);
  }, [logout]);

  const options = useMemo<ListItemProps[]>(
    () => [
      ...conditionalItem<ListItemProps>(isAdmin, {
        title: t("godMode.setFeatured.creator"),
        left: props => <Star {...props} size={24} color={colors.godMode} />,
        onPress: handleSetFeaturedCreator,
        titleStyle: { color: colors.godMode },
      }),
      ...conditionalItem<ListItemProps>(isAdmin, {
        title: t("godMode.toggleCreatorStatus"),
        left: props => <VerifiedBadge {...props} width={24} height={24} />,
        onPress: handleToggleCreatorStatus,
        titleStyle: { color: colors.godMode },
      }),
      ...conditionalItem<ListItemProps>(isAdmin && !isCurrentUser, {
        title: t("godMode.deleteUser.label"),
        left: props => <TrashSimple {...props} size={24} color={colors.godMode} />,
        onPress: handleDeleteUser,
        titleStyle: { color: colors.godMode },
      }),
      ...conditionalItem<ListItemProps>((isAdmin || __DEV__) && isCurrentUser, {
        left: props => <Sparkle {...props} color={colors.godMode} />,
        title: t("godMode.title"),
        onPress: () => handlePressWrapper(() => navigation.navigate(Routes.godMode)),
        titleStyle: { color: colors.godMode },
      }),
      ...conditionalItem<ListItemProps>(isCurrentUser && !!profile, {
        title: t("referral.settingsLabel"),
        left: props => <SketchLogo {...props} color={colors.onBackground} />,
        onPress: () => handlePressWrapper(() => navigation.navigate(Routes.referAFriend)),
      }),
      ...conditionalItem<ListItemProps>(isCurrentUser, {
        title: t("settings.feedback.label"),
        left: props => <ChatDots {...props} color={colors.onBackground} />,
        onPress: () => handlePressWrapper(() => navigation.navigate(Routes.feedback)),
      }),
      ...conditionalItem<ListItemProps>(isCurrentUser, {
        title: t("profile.more.accountSettings"),
        left: props => <GearSix {...props} color={colors.onBackground} />,
        onPress: () => handlePressWrapper(() => navigation.navigate(Routes.settings)),
      }),
      ...conditionalItem<ListItemProps>(isCurrentUser, {
        title: t("settings.help.label"),
        left: props => <Question {...props} color={colors.onBackground} />,
        onPress: handleHelp,
      }),
      ...conditionalItem<ListItemProps>(isCurrentUser && state.connected && state.verified, {
        title: t("cta.logout"),
        left: props => <SignOut {...props} color={colors.error} weight="thin" />,
        titleStyle: { color: colors.error },
        onPress: handleLogout,
      }),
      ...conditionalItem<ListItemProps>(!isCurrentUser, {
        title: t("cta.report"),
        left: props => <FlagBanner {...props} color={colors.error} weight="thin" />,
        titleStyle: { color: colors.error },
        onPress: () => handlePressWrapper(() => setReportDialogVisible(true)),
      }),
    ],
    [
      colors.error,
      colors.godMode,
      colors.onBackground,
      handleDeleteUser,
      handleHelp,
      handleLogout,
      handlePressWrapper,
      handleSetFeaturedCreator,
      handleToggleCreatorStatus,
      isAdmin,
      isCurrentUser,
      navigation,
      profile,
      state.connected,
      state.verified,
      t,
    ],
  );

  return (
    <>
      {!!options.length && (
        <IconButton
          icon={({ color, size }) => <DotsThreeVertical color={color} size={size} weight="bold" />}
          style={styles.iconButton}
          onPress={handleOpenBottomSheet}
          aria-label="more-actions"
        />
      )}

      <NestedBottomSheetWithList
        ref={sheetRef}
        list={options}
        subHeader={
          isCurrentUser && <SuperplanAlert onLearnMorePress={handleLearnMoreAboutSuperplans} origin={ViewSuperplanScreenOrigin.profileMoreMenu} />
        }
        visibleNestedIndex={indexNestedViewVisible}
        setVisibleNestedIndex={setIndexNestedViewVisible}
        contentContainerStyle={indexNestedViewVisible === undefined && styles.listContainer}
      />

      {userId && (
        <ReportDialog entityId={userId} entityType="profile" visible={reportDialogVisible} onDismiss={() => setReportDialogVisible(false)} />
      )}
    </>
  );
};
