import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "@app/components/common/alert/alert.component";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUpdateContest } from "@app/hooks/api/contests/use-update-contest.hook";

interface Props {
  contestId: string;
  passwordRequired?: boolean;
}

export const ManageContestPasswordButtons: FC<Props> = ({ contestId, passwordRequired }) => {
  const { t } = useTranslation();
  const { showSuccessSnackbar } = useSnackbarContext();

  const { updateContest, loading: loadingUpdate } = useUpdateContest();

  const handleSubmit = (newPassword: string | null, successMessage: string): void => {
    void updateContest({
      variables: { contestId, input: { password: newPassword } },
      onCompleted: () => showSuccessSnackbar({ message: successMessage }),
    });
  };

  const handleDeletePassword = (): void => {
    Alert.alert(t("error.warning"), t("godMode.manageContestPassword.deletePassword.confirmation"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () => {
          handleSubmit(null, t("godMode.manageContestPassword.deletePassword.success"));
        },
      },
    ]);
  };

  const handleAddPassword = (): void => {
    Alert.prompt(t("godMode.manageContestPassword.enterPassword"), t("godMode.manageContestPassword.addPassword.explanation"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.submit"),
        onPress: (newPassword?: string) => {
          if (!newPassword) return;

          handleSubmit(newPassword, t("godMode.manageContestPassword.addPassword.success"));
        },
      },
    ]);
  };

  const handleUpdatePassword = (): void => {
    Alert.prompt(t("godMode.manageContestPassword.enterPassword"), t("godMode.manageContestPassword.addPassword.explanation"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.submit"),
        onPress: (newPassword?: string) => {
          if (!newPassword) return;

          Alert.alert(t("error.warning"), t("godMode.manageContestPassword.updatePassword.confirmation"), [
            { text: t("cta.cancel"), style: "cancel" },
            {
              style: "destructive",
              text: t("cta.yes"),
              onPress: () => {
                handleSubmit(newPassword, t("godMode.manageContestPassword.updatePassword.success"));
              },
            },
          ]);
        },
      },
    ]);
  };

  return passwordRequired ? (
    <Box rowGap={8}>
      <Button fullWidth mode="outlined" size="small" onPress={handleUpdatePassword} loading={loadingUpdate}>
        {t("godMode.manageContestPassword.updatePassword.label")}
      </Button>
      <Button color="error" fullWidth mode="outlined" size="small" onPress={handleDeletePassword} loading={loadingUpdate}>
        {t("godMode.manageContestPassword.deletePassword.label")}
      </Button>
    </Box>
  ) : (
    <Button fullWidth mode="outlined" size="small" onPress={handleAddPassword} loading={loadingUpdate}>
      {t("godMode.manageContestPassword.addPassword.label")}
    </Button>
  );
};
