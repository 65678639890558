import React, { useEffect, useMemo, useRef } from "react";

import { NODE_ENV, REACT_APP_MIXPANEL_TOKEN } from "@env";
import { getCookie } from "cookies-next";
import mixpanel, { Dict } from "mixpanel-browser";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { defaultMixPanelValues } from "./default-values.const";
import { MixpanelContext, MixpanelContextInterface } from "./mixpanel.context";

export const MixpanelContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const sessionCookieKey = "connect.sid";

  const instance = useRef(mixpanel.init(REACT_APP_MIXPANEL_TOKEN, { debug: NODE_ENV !== "production" }, "offScript"));

  const trackInMixpanel = (eventName: TrackEvent, properties?: Dict): void => {
    const sessionId = getCookie(sessionCookieKey)?.toString();

    instance?.current.track(eventName, {
      ...properties,
      ...defaultMixPanelValues,
      sessionId: sessionId?.replace(/^s(:|%3A)/, "").split(".")[0],
    });
  };

  useEffect(() => {
    trackInMixpanel(TrackEvent.initialPageLoad);
  }, []);

  const context = useMemo<MixpanelContextInterface>(() => ({ mixpanel: instance.current, trackInMixpanel }), [instance]);

  return <MixpanelContext.Provider value={context}>{children}</MixpanelContext.Provider>;
};
