import React, { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { StyleProp, ViewStyle } from "react-native";
import { Theme, useTheme } from "react-native-paper";
import Animated, { AnimatedStyle } from "react-native-reanimated";

import { Stage } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { ColorKey, getColor } from "@app/utils/color-theme.util";
import { computePriceArrays } from "@app/utils/price.util";

import { styles } from "./product-price.style";

interface CommonProps {
  price?: string | [string | undefined, string | undefined];
  compareAtPrice?: string;
  stage?: Stage;
  commonTextProps?: Omit<TextProps, "children">;
  theme?: Theme;
}

interface PropsWithBackground extends CommonProps {
  backgroundColor: ColorKey;
  containerStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
}

interface PropsWithoutBackground extends CommonProps {
  backgroundColor?: never;
  containerStyle?: never;
}

type Props = PropsWithBackground | PropsWithoutBackground;

export const ProductPrice: FC<Props> = ({
  price: singleOrRangePrice,
  compareAtPrice,
  stage,
  commonTextProps,
  backgroundColor,
  containerStyle,
  theme: overriddenTheme,
}) => {
  const { t } = useTranslation();
  const { colors: currentColors } = useTheme();
  const colors = overriddenTheme?.colors ?? currentColors;

  const { floorPriceArray, ceilingPriceArray, priceArray, isPriceRange } = computePriceArrays(singleOrRangePrice);
  const compareAtPriceArray = compareAtPrice?.split(".");

  const prices = compareAtPrice ? (
    <Trans
      i18nKey="products.priceAndCompareAtPrice"
      parent={({ children }: ChildrenProp) => (
        <Text {...commonTextProps} theme={overriddenTheme}>
          {children}
        </Text>
      )}
      values={{
        integer: priceArray?.[0],
        decimal: priceArray?.[1],
        compareAtInteger: compareAtPriceArray?.[0],
        compareAtDecimal: compareAtPriceArray?.[1],
      }}
      components={{
        lineThrough: (
          <Text {...commonTextProps} textDecorationLine="line-through" theme={overriddenTheme}>
            <></>
          </Text>
        ),
        red: (
          <Text {...commonTextProps} color="error" theme={overriddenTheme}>
            <></>
          </Text>
        ),
      }}
    />
  ) : (
    <Text {...commonTextProps} theme={overriddenTheme}>
      {isPriceRange
        ? t("products.priceRange", {
            floorInteger: floorPriceArray?.[0],
            floorDecimal: floorPriceArray?.[1],
            ceilingInteger: ceilingPriceArray?.[0],
            ceilingDecimal: ceilingPriceArray?.[1],
          })
        : t("products.price", { integer: priceArray?.[0], decimal: priceArray?.[1] })}
    </Text>
  );

  if (singleOrRangePrice == null || stage === Stage.concept) return null;

  return backgroundColor ? (
    <Animated.View style={[styles.chip, { backgroundColor: getColor(colors, backgroundColor) }, containerStyle]}>{prices}</Animated.View>
  ) : (
    prices
  );
};
