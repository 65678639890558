/* eslint-disable complexity */
import React, { useCallback, useRef, forwardRef, useImperativeHandle, useState } from "react";

import Sheet, { SheetRef } from "react-modal-sheet";
import { ScrollView, View } from "react-native";
import { useTheme } from "react-native-paper";

import { isDesktop } from "@app/utils/device.util";

import { HeaderContent } from "./bottom-sheet.common";
import { styles } from "./bottom-sheet.style";
import { BottomSheetRefProps, BottomSheetProps } from "./bottom-sheet.types";

export const BottomSheet = forwardRef<BottomSheetRefProps, BottomSheetProps>(
  (
    {
      animateOnMount,
      backdropComponent,
      backgroundColor,
      children,
      contentContainerStyle,
      enablePanDownToClose,
      footer,
      isModal,
      leftHeader,
      onDismiss,
      rightHeader,
      scrollable,
      scrollViewProps,
      snapPoints,
      style,
      subHeader,
      title,
      titleStyle,
      headerStyle,
    },
    ref,
  ) => {
    const { colors } = useTheme();
    const webSheetRef = useRef<SheetRef>(null);

    const [webSheetOpen, setWebSheetOpen] = useState(animateOnMount ?? false);

    const handleOpen = useCallback((): void => {
      setWebSheetOpen(true);
    }, []);

    const handleClose = useCallback((): void => {
      onDismiss?.();
      setWebSheetOpen(false);
    }, [onDismiss]);

    useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose, isOpen: webSheetOpen }), [handleOpen, handleClose, webSheetOpen]);

    const noBackdrop = backdropComponent === null;
    const hasSnapPoints = Array.isArray(snapPoints) && snapPoints.length === 1;
    const snapPointsParsed: number[] | undefined = hasSnapPoints
      ? snapPoints.map(value => (typeof value === "string" ? parseInt(value.replace("%", "")) / 100 : 1))
      : undefined;
    const zIndexStyle =
      style && "zIndex" in style && typeof style.zIndex === "number" ? { zIndex: style.zIndex } : isModal === false ? { zIndex: 100 } : undefined;

    return (
      <Sheet
        ref={webSheetRef}
        isOpen={webSheetOpen}
        initialSnap={hasSnapPoints ? 0 : 1}
        snapPoints={snapPointsParsed}
        disableDrag={enablePanDownToClose === false}
        onClose={handleClose}
        style={zIndexStyle}
        disableScrollLocking
        detent={hasSnapPoints ? "full-height" : "content-height"}>
        <Sheet.Container
          style={{ backgroundColor: backgroundColor ?? colors.tertiaryContainer, ...(isDesktop ? styles.desktopContainer : undefined) }}>
          {(title || leftHeader || rightHeader || subHeader) && (
            <Sheet.Header style={styles.headerContainer}>
              <HeaderContent
                title={title}
                leftHeader={leftHeader}
                rightHeader={rightHeader}
                subHeader={subHeader}
                titleStyle={titleStyle}
                headerStyle={headerStyle}
              />
            </Sheet.Header>
          )}
          <Sheet.Content style={{ paddingBottom: webSheetRef.current?.y }}>
            {scrollable ? (
              <>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  {...scrollViewProps}
                  style={[styles.contentContainer, footer ? {} : styles.contentBottomPadding, contentContainerStyle, scrollViewProps?.style]}>
                  {children}
                </ScrollView>
                {footer && <View style={[styles.footerContainerWeb, styles.contentBottomPadding]}>{footer}</View>}
              </>
            ) : (
              <View style={[styles.contentContainer, styles.contentBottomPadding, contentContainerStyle]}>
                {children}
                {footer && <View style={styles.footerContainerWeb}>{footer}</View>}
              </View>
            )}
          </Sheet.Content>
        </Sheet.Container>
        {noBackdrop ? (
          <></>
        ) : (
          <Sheet.Backdrop style={{ backgroundColor: colors.backdrop }} onTap={enablePanDownToClose === false ? undefined : handleClose} />
        )}
      </Sheet>
    );
  },
);

BottomSheet.displayName = "BottomSheet";
