import { NODE_ENV } from "@env";
import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import "intl-pluralrules";

import { log } from "@app/utils/logger/logger.util";

import enCommon from "./en/common";

export const defaultNS = "common";

export const resources = {
  en: {
    common: enCommon,
  },
};

const mapExposantNumber: Record<string, string> = {
  "0": "⁰",
  "1": "¹",
  "2": "²",
  "3": "³",
  "4": "⁴",
  "5": "⁵",
  "6": "⁶",
  "7": "⁷",
  "8": "⁸",
  "9": "⁹",
};

function convertToExposant(number?: string): string {
  const numberArray = number?.split("");
  let exposant = "";
  numberArray?.forEach(n => (exposant += mapExposantNumber[n]));
  return exposant;
}

export const i18n = use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    ns: ["common"],
    debug: NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value: string, format) {
        if (format === "lowercase") return value.toLowerCase();
        if (format === "sup") return convertToExposant(value);
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
    defaultNS,
    resources,
  })
  .catch(e => {
    log.error(e);
  });
