import { LazyQueryExecFunction, gql, useLazyQuery } from "@apollo/client";

import { commentFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedComments } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  comments: PaginatedComments;
}

interface Variables {
  first?: number;
  offset?: number;
  collabId: string;
}

interface CommentsResult extends QueryResult<Response, "comments"> {
  getComments: LazyQueryExecFunction<Response, Variables>;
}

const commentsQuery = gql`
  query Comments($collabId: CollabId!, $first: Int, $offset: Int) {
    comments(collabId: $collabId, first: $first, offset: $offset) {
      nodes {
        ...CommentFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${commentFields}
`;

export function useComments(): CommentsResult {
  const [getComments, { loading, error, data, networkStatus, fetchMore }] = useLazyQuery<Response, Variables>(commentsQuery, {
    notifyOnNetworkStatusChange: true,
  });

  return { getComments, loading, error, data: data?.comments, networkStatus, fetchMore };
}
