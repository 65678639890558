import { StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";

export const styles = StyleSheet.create({
  background: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  contentContainerTabs: {
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  fullScreen: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  loading: {
    justifyContent: "center",
  },
  loadingText: {
    padding: 24,
  },
  root: {
    rowGap: 8,
  },
  tab: {
    alignItems: "center",
    borderWidth: 1,
    columnGap: 8,
    flexDirection: "row",
    overflow: "hidden",
    paddingHorizontal: 15,
    paddingVertical: 7,
  },
  tabContainer: {
    minHeight: 40,
    padding: 0,
    width: "auto",
  },
  tabs: {
    backgroundColor: transparent,
    elevation: 0,
    shadowColor: transparent,
  },
});
