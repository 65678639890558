import React, { ForwardedRef, JSX, forwardRef, useCallback, useImperativeHandle, useMemo } from "react";

import { NetworkStatus } from "@apollo/client";
import { FlashList, FlashListProps, ListRenderItem } from "@shopify/flash-list";
import { Image } from "expo-image";
import { ArrowsClockwise } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import supercoin from "@app/assets/images/gold-dollar-coins.png";
import { MilestoneProgress } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useMilestonesProgress } from "@app/hooks/api/supercoins/use-milestones-progress.hook";
import { useSupercoinBalance } from "@app/hooks/api/supercoins/use-supercoin-balance.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { isWeb } from "@app/utils/device.util";
import { handledMilestoneTypes } from "@app/utils/milestone-type.util";

import { ReferralRewardAlert } from "../referral-reward-alert/referral-reward-alert.component";
import { SupercoinsBanner } from "../supercoins-banner/supercoins-banner.component";

import { SupercoinRewardListItem } from "./supercoin-reward-list-item/supercoin-reward-list-item.component";
import { estimatedItemHeight, styles } from "./supercoin-reward-list.style";

export interface SupercoinRewardListRefProps {
  fetchMore: () => void;
}

interface Props {}

const SupercoinRewardListInner = (_props: Props, ref: ForwardedRef<SupercoinRewardListRefProps>): JSX.Element => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { profile } = useProfileContext();

  const {
    data: supercoinBalance,
    loading: loadingSupercoinBalance,
    refetch: refetchSupercoinBalance,
  } = useSupercoinBalance({ variables: { userId: profile?.userId } });
  const {
    data: milestonesProgressData,
    loading: loadingMilestonesProgress,
    refetch: refetchMilestonesProgress,
    networkStatus,
    fetchMore,
  } = useMilestonesProgress({ variables: { userId: profile?.userId, where: { milestoneTypes: handledMilestoneTypes, includeRecurring: true } } });

  const milestonesProgress = milestonesProgressData?.nodes;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const { fetchMoreItems } = useFetchMoreItems("milestonesProgress", fetchMore);

  const handleFetchMore = useCallback((): void => {
    if (fetchMore && !isFetchingMore && milestonesProgress && milestonesProgressData?.pageInfo.hasNextPage) {
      void fetchMoreItems({ offset: milestonesProgress?.length });
    }
  }, [fetchMore, fetchMoreItems, isFetchingMore, milestonesProgress, milestonesProgressData?.pageInfo.hasNextPage]);

  useImperativeHandle(ref, () => ({ fetchMore: handleFetchMore }), [handleFetchMore]);

  const handleRefresh = useCallback(() => {
    void refetchMilestonesProgress?.();
    void refetchSupercoinBalance?.();
  }, [refetchMilestonesProgress, refetchSupercoinBalance]);

  const header = (
    <View style={styles.header}>
      <SupercoinsBanner />
      <View style={styles.headerSupercoinsBalance}>
        <View style={styles.supercoinsBalanceContainer}>
          <View style={styles.supercoinsBalanceCount}>
            <Image source={supercoin} style={styles.supercoinImage} />
            {loadingSupercoinBalance ? (
              <ActivityIndicator size={customFonts.title.lineHeight} color={colors.onBackground} />
            ) : (
              <Text variant="title">{supercoinBalance?.formattedAmount}</Text>
            )}
          </View>

          <Text variant="body2" color="tertiary">
            {t("supercoins.balance")}
          </Text>
        </View>
        <IconButton icon={ArrowsClockwise} iconColor={colors.action.active} size={24} onPress={handleRefresh} />
      </View>
      <ReferralRewardAlert />
    </View>
  );

  const commonFlashListProps: Omit<FlashListProps<MilestoneProgress>, "renderItem" | "data"> = useMemo(
    () => ({
      numColumns: 1,
      contentContainerStyle: styles.listContentContainer,
      showsVerticalScrollIndicator: false,
      estimatedItemSize: estimatedItemHeight,
      scrollEnabled: isWeb,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ItemSeparatorComponent: () => <View style={styles.rowGap} />,
    }),
    [],
  );

  const loadingState = (
    <FlashList
      data={new Array(6).fill(0)}
      keyExtractor={(_item, index) => `skeleton-reward-${index}`}
      renderItem={() => <Skeleton height={estimatedItemHeight} width="100%" radius={roundness} />}
      {...commonFlashListProps}
    />
  );

  const renderItem = useCallback<ListRenderItem<MilestoneProgress>>(({ item }) => <SupercoinRewardListItem reward={item} />, []);

  return (
    <FlashList
      data={milestonesProgress}
      renderItem={renderItem}
      keyExtractor={(item, index) => `milestone-progress-${item.milestoneType}-${item.milestoneObjectiveId}-${item.achievementCount}-${index}`}
      ListHeaderComponent={header}
      ListFooterComponent={isFetchingMore ? <ActivityIndicator size="small" /> : undefined}
      ListEmptyComponent={loadingMilestonesProgress ? loadingState : undefined}
      {...commonFlashListProps}
    />
  );
};

export const SupercoinRewardList = forwardRef(SupercoinRewardListInner);
