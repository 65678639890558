import { gql, useQuery } from "@apollo/client";

import { nbProductsPerFetch } from "@app/common/constants/products.const";
import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioImages } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  studioImages: PaginatedStudioImages;
}

interface Variables {
  first?: number;
  offset?: number;
}

const studioExploreImagesQuery = gql`
  query ExploreImages($first: Int, $offset: Int) {
    studioImages: exploreImages(first: $first, offset: $offset) {
      nodes {
        ...CoreStudioImageFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
`;

export function useStudioExploreImages(): QueryResult<Response, "studioImages"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(studioExploreImagesQuery, {
    variables: { first: nbProductsPerFetch },
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.studioImages, loading, error, networkStatus, fetchMore, refetch };
}
