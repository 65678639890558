import React, { FC } from "react";

import { Image } from "expo-image";
import { ArrowsClockwise } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import * as Progress from "react-native-progress";

import supercoin from "@app/assets/images/gold-dollar-coins-straight.png";
import { MilestoneProgress, MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { formattedNumber } from "@app/utils/format.util";
import { milestoneTypeIconMap, milestoneTypeIconSize } from "@app/utils/milestone-type.util";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./supercoin-reward-list-item.style";

interface Props {
  reward: MilestoneProgress;
}

const maxProgressValue = 100;

export const SupercoinRewardListItem: FC<Props> = ({ reward }) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();

  const {
    requiredCount: countObjective,
    currentCount,
    reward: rewardCount,
    milestoneType,
    progression,
    isRecurring,
    achievementCount,
    isHidden,
  } = reward;
  const isCompleted = progression === maxProgressValue;

  const progressValue = Math.min(progression / maxProgressValue, 1);
  const progressBarBgColor = colors.common.gray.toString().replace(",1)", ",0.6)");

  const titleWithReward = (
    <View style={styles.rowSpaceBetween}>
      <Trans
        i18nKey={`enum.milestoneType.${milestoneType}`}
        count={countObjective}
        context={milestoneType === MilestoneType.collabLikeMilestone && isRecurring ? "recurring" : undefined}
        parent={({ children }: ChildrenProp) => (
          <Text variant="caption" textTransform="uppercase">
            {children}
          </Text>
        )}
        components={{
          bold: (
            <Text variant="h9" color={isCompleted ? "success" : "warning"}>
              <></>
            </Text>
          ),
        }}
      />
      <View style={[styles.row, styles.supercoinsReward, { borderColor: colors.outline, backgroundColor: isCompleted ? colors.success : undefined }]}>
        <Text variant="caption" color={isCompleted ? "onSuccess" : undefined}>
          {formatPriceUSD(rewardCount, { withoutUSD: true })}
        </Text>
        <Image source={supercoin} style={styles.supercoinImage} />
      </View>
    </View>
  );

  const progressBar = (
    <View style={styles.progressContainer}>
      <View style={styles.progressLabelContainer}>
        <Text variant="caption">{isCompleted ? t("supercoins.completed") : `${formattedNumber(currentCount)}/${countObjective}`}</Text>
        {isRecurring && (
          <View style={styles.recurringContainer}>
            <Text variant="caption">{isCompleted ? `x${achievementCount ?? 1}` : t("supercoins.recurring")}</Text>
            {!isCompleted && <ArrowsClockwise size={16} color={colors.onBackground} />}
          </View>
        )}
      </View>

      <Progress.Bar
        progress={progressValue}
        color={isCompleted ? colors.success : colors.primary}
        unfilledColor={progressBarBgColor}
        borderRadius={20}
        borderWidth={0}
        width={null}
        height={2}
      />
    </View>
  );

  const hiddenStyle = [styles.border, { borderColor: colors.godMode }];

  return (
    <ListItem
      title={titleWithReward}
      imageProps={{ image: milestoneTypeIconMap[reward.milestoneType], size: milestoneTypeIconSize }}
      description={progressBar}
      contentContainerStyle={styles.contentContainer}
      style={[styles.root, { backgroundColor: colors.tertiaryContainer, borderRadius: roundness }, ...conditionalItem(isHidden, hiddenStyle)]}
    />
  );
};
