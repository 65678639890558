import * as Browser from "@sentry/browser";
import * as Sentry from "@sentry/react-native";

import { isWeb } from "./device.util";

type SentryParamsTypes = Parameters<typeof Sentry.captureException>;
export type ScopeContext = SentryParamsTypes[1];

export function logToSentry(error: unknown, captureContext?: ScopeContext): string {
  if (isWeb) {
    return Browser.captureException(error, captureContext);
  } else {
    return Sentry.captureException(error, captureContext);
  }
}

export function sendFeedbackToSentry(sentryId: string, feedback: Omit<Sentry.UserFeedback, "event_id">): void {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Sentry.captureUserFeedback({ event_id: sentryId, ...feedback });
}
