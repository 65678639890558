import {
  AnyPromo,
  CollabWithMedia,
  GenericPromo,
  PaginatedAnyPromo,
  PaginatedFeedEntitiesWithPromo,
  PromoType,
  StandardPromo,
} from "@app/common/graphql/generated/schema.graphql";

export interface GenericPromoFromHook extends GenericPromo {
  genericSubtitle: GenericPromo["subtitle"];
  genericText: GenericPromo["text"];
  genericTitle: GenericPromo["title"];
}

interface StandardPromoFromHook extends StandardPromo {
  standardSubtitle: StandardPromo["subtitle"];
  standardText: StandardPromo["text"];
  standardTitle: StandardPromo["title"];
}

export type AnyPromoFromHook = GenericPromoFromHook | StandardPromoFromHook;

export type PaginatedAnyPromoFromHook = Omit<PaginatedAnyPromo, "nodes"> & {
  nodes?: AnyPromoFromHook[];
};

export type FeedCollabOrPromoFromHook = CollabWithMedia | AnyPromoFromHook;

export type PaginatedFeedEntitiesWithPromoFromHook = Omit<PaginatedFeedEntitiesWithPromo, "nodes"> & {
  nodes?: FeedCollabOrPromoFromHook[];
};

function isGenericPromo(promo: AnyPromo): promo is GenericPromoFromHook | GenericPromo {
  return promo.promoType === PromoType.generic;
}

export function mapGenericPromoToRealType(promo: GenericPromoFromHook): GenericPromo {
  return { ...promo, subtitle: promo.genericSubtitle, text: promo.genericText, title: promo.genericTitle };
}

function mapStandardPromoToRealType(promo: StandardPromoFromHook): StandardPromo {
  return { ...promo, subtitle: promo.standardSubtitle, text: promo.standardText, title: promo.standardTitle };
}

export function mapPromoToRealType(promo: AnyPromoFromHook): AnyPromo {
  if (isGenericPromo(promo)) {
    return mapGenericPromoToRealType(promo);
  }

  return mapStandardPromoToRealType(promo);
}

export function mapGenericPromoToHookType(promo: GenericPromo): GenericPromoFromHook {
  return { ...promo, genericSubtitle: promo.subtitle, genericText: promo.text, genericTitle: promo.title };
}

function mapStandardPromoToHookType(promo: StandardPromo): StandardPromoFromHook {
  return { ...promo, standardSubtitle: promo.subtitle, standardText: promo.text, standardTitle: promo.title };
}

export function mapPromoToHookType(promo: AnyPromo): AnyPromoFromHook {
  if (isGenericPromo(promo)) {
    return mapGenericPromoToHookType(promo);
  }

  return mapStandardPromoToHookType(promo);
}
