import { StyleSheet } from "react-native";

const paddingHorizontal = 12;

export const styles = StyleSheet.create({
  filterButtonContainer: {
    left: paddingHorizontal,
    position: "absolute",
  },
  header: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    height: 60,
    justifyContent: "center",
    paddingHorizontal,
    paddingVertical: 8,
    position: "relative",
  },
  sortButtonContainer: {
    position: "absolute",
    right: paddingHorizontal,
  },
  title: {
    paddingVertical: 12,
  },
});
