import { productStackMargin } from "@app/common/constants/products.const";
import { bottomTabsHeight } from "@app/common/style/theme";
import { headerHeight } from "@app/router/router.style";

import { useWindowDimensions } from "./use-window-dimensions.hook";

export function useFeedEntityCardHeight(): { cardHeight: number } {
  const { height: windowHeight } = useWindowDimensions();

  const height = windowHeight - headerHeight - bottomTabsHeight - 2 * productStackMargin;

  return { cardHeight: height };
}
