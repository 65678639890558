import React, { FC, useCallback, useRef } from "react";

import { NetworkStatus } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { NativeScrollEvent, NativeSyntheticEvent, View } from "react-native";
import { ActivityIndicator, IconButton, IconButtonProps, useTheme } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import ChatCenteredDots from "@app/assets/icons/chat-centered-dots-duotone.svg";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useComments } from "@app/hooks/api/comments/use-comments.hook";
import { useCollabUpdateComments } from "@app/hooks/api/updates/use-collab-update-comments.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { formattedNumber } from "@app/utils/format.util";
import { isCollabId } from "@app/utils/id.util";

import { CommentInput } from "../comment-input/comment-input.component";
import { CommentList } from "../comment-list/comment-list.component";

import { styles } from "./comments-button.style";

interface Props extends Omit<IconButtonProps, "icon" | "onPress"> {
  parentId: string;
  numberOfComments: string;
  icon?: IconSource;
}

export const CommentsButton: FC<Props> = ({ parentId, numberOfComments, icon, style, ...props }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { top } = useSafeAreaInsets();
  const { openLogin } = useLoginContext();

  const sheetRef = useRef<BottomSheetRefProps>(null);
  const isFetchingMoreRef = useRef<boolean>(false);

  const {
    getComments,
    data: collabComments,
    loading: collabCommentsLoading,
    networkStatus: collabCommentsNetworkStatus,
    fetchMore: collabCommentsFetchMore,
  } = useComments();
  const {
    getCollabUpdateComments,
    data: updateComments,
    loading: updateCommentsLoading,
    networkStatus: updateCommentsNetworkStatus,
    fetchMore: updateCommentsFetchMore,
  } = useCollabUpdateComments();

  const isCollabComments = isCollabId(parentId);
  const data = isCollabComments ? collabComments : updateComments;
  const loadingComments = isCollabComments ? collabCommentsLoading : updateCommentsLoading;
  const networkStatus = isCollabComments ? collabCommentsNetworkStatus : updateCommentsNetworkStatus;
  const fetchMore = isCollabComments ? collabCommentsFetchMore : updateCommentsFetchMore;

  const comments = data?.nodes;
  const noComments = !loadingComments && (!comments || data?.totalCount === 0);
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const { fetchMoreItems } = useFetchMoreItems("comments", fetchMore);

  const handleCommentsPress = (): void => {
    if (loadingComments) return;

    if (isCollabComments) {
      void getComments({
        variables: { collabId: parentId },
        onCompleted: () => sheetRef.current?.open(),
      });
    } else {
      void getCollabUpdateComments({
        variables: { collabUpdateId: parentId },
        onCompleted: () => sheetRef.current?.open(),
      });
    }
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent): boolean => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const scrollHandler = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (isCloseToBottom(nativeEvent) && isFetchingMoreRef.current === false) {
        isFetchingMoreRef.current = true;
        if (!isFetchingMore && comments && data?.pageInfo.hasNextPage) {
          void fetchMoreItems({ offset: comments.length }).then(() => (isFetchingMoreRef.current = false));
        }
      }
    },
    [comments, data?.pageInfo.hasNextPage, fetchMoreItems, isFetchingMore],
  );

  const handleCloseBottomSheet = (): void => {
    isFetchingMoreRef.current = false;
    sheetRef.current?.close();
  };

  return (
    <>
      <IconButton
        icon={
          loadingComments
            ? iconProps => <ActivityIndicator {...iconProps} color={colors.common.gray} />
            : icon ??
              (iconProps => (
                <View style={styles.buttonIconContainer}>
                  <ChatCenteredDots {...iconProps} height={iconProps.size} width={iconProps.size} />
                  {!!numberOfComments && numberOfComments !== "0" && (
                    <Text variant="caption" style={[{ color: iconProps.color }, styles.textShadow]}>
                      {numberOfComments}
                    </Text>
                  )}
                </View>
              ))
        }
        iconColor={colors.common.white}
        onPress={handleCommentsPress}
        style={[styles.iconButton, style]}
        {...props}
      />

      <BottomSheet
        ref={sheetRef}
        scrollable
        snapPoints={["80%"]}
        topInset={top}
        enableDynamicSizing={false}
        maxDynamicContentSize={undefined}
        onDismiss={() => (isFetchingMoreRef.current = false)}
        title={noComments ? t("comments.label") : t("comments.commentsWithCount", { commentCount: formattedNumber(data?.totalCount ?? 0) })}
        footer={<CommentInput parentId={parentId} openLogin={openLogin} closeBottomSheet={handleCloseBottomSheet} />}
        scrollViewProps={{
          scrollEnabled: true,
          onScroll: scrollHandler,
          scrollEventThrottle: 400,
          onContentSizeChange: () => undefined,
          contentContainerStyle: noComments ? { flex: 1 } : {},
        }}>
        <CommentList
          comments={comments}
          parentId={parentId}
          closeBottomSheet={handleCloseBottomSheet}
          ListFooterComponent={isFetchingMore ? <ActivityIndicator size="small" /> : undefined}
        />
      </BottomSheet>
    </>
  );
};
