import React, { FC, PropsWithChildren } from "react";

import { View } from "react-native";
import Animated, { Easing, FadeOut } from "react-native-reanimated";

import { Box } from "../box/box.component";
import { Text } from "../text/text.component";

import { styles } from "./bottom-sheet.style";
import { BottomSheetProps } from "./bottom-sheet.types";

type HeaderContentProps = Pick<BottomSheetProps, "title" | "leftHeader" | "rightHeader" | "subHeader" | "titleStyle" | "headerStyle">;

export const HeaderContent: FC<HeaderContentProps> = ({ title, leftHeader, rightHeader, subHeader, titleStyle, headerStyle }) => (
  <>
    {(!!title || !!leftHeader || !!rightHeader) && (
      <View style={[styles.header, headerStyle]}>
        {!!leftHeader && <View style={styles.leftHeader}>{leftHeader}</View>}
        {!!title && (
          <Text variant="h8" textAlign="center" style={[styles.title, titleStyle]}>
            {title}
          </Text>
        )}
        {!!rightHeader && <View style={styles.rightHeader}>{rightHeader}</View>}
      </View>
    )}
    {!!subHeader && <View style={styles.subHeader}>{subHeader}</View>}
  </>
);

export const NestedAnimatedView: FC<PropsWithChildren<{ isVisible: boolean; width: number }>> = ({ children, isVisible, width }) => {
  return (
    <Box style={{ width }}>{isVisible ? <Animated.View exiting={FadeOut.duration(500).easing(Easing.linear)}>{children}</Animated.View> : null}</Box>
  );
};
