import React, { FC } from "react";

import { ArrowRight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { Button } from "@app/components/common/button/button.component";
import { useCartContext } from "@app/context/cart/cart.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCartCheckout } from "@app/hooks/api/use-cart-checkout.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./checkout-button.style";

export const CheckoutButton: FC = () => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { navigateToWebview } = useNavigateToWebview();

  const { cartItems, cartIdentity, loadingCartIdentity } = useCartContext();
  const { checkout, loading: loadingCheckout } = useCartCheckout();

  const totalPrice = cartItems?.length
    ? cartItems.map(item => (item.variant?.price ?? item.collab.price ?? 0) * item.quantity).reduce((a, b) => a + b)
    : undefined;

  const handleCheckout = (): void => {
    if (!cartIdentity) return;

    void checkout({
      variables: cartIdentity,
      onCompleted: data => navigateToWebview(data.cartCheckout.checkoutUrl, t("cta.checkout")),
      onError: error => {
        const hasInvalidCartItems = isErrorCode(error, "MISSING_VARIANT") || isErrorCode(error, "SHOPIFY_CREATE_CART_ERROR");
        if (hasInvalidCartItems) {
          showErrorSnackbar({ message: t("error.invalidCartItems") });
        } else {
          showErrorSnackbar({ error });
        }
      },
    });
  };

  return (
    <Button
      mode="contained"
      size="large"
      fullWidth
      icon={ArrowRight}
      loading={loadingCheckout || loadingCartIdentity}
      onPress={handleCheckout}
      containerStyle={styles.root}
      contentStyle={styles.content}>
      {t("cta.checkoutWithPrice", { price: formatPriceUSD(totalPrice) })}
    </Button>
  );
};
