import React, { forwardRef, memo, useRef, useState } from "react";

import { Video, VideoProps } from "expo-av";
import { Platform } from "react-native";

/**
 * On iOS, the video does not always reliable load.
 * So we are setting a timer when the load starts, and then if the video is
 * not ready after 1 second, we are reloading it.
 *
 * https://github.com/expo/expo/issues/20483#issuecomment-1449749768
 */
const FixedVideo = memo(
  forwardRef(({ onLoadStart, onReadyForDisplay, ...props }: VideoProps, ref: React.ForwardedRef<Video>) => {
    const [key, setKey] = useState(`fixed-ios-video-${Date.now()}`);
    const isVideoReadyRef = useRef(key);

    return (
      <Video
        key={key}
        ref={ref}
        {...props}
        onLoadStart={() => {
          isVideoReadyRef.current = key;
          setTimeout(() => {
            if (isVideoReadyRef.current === key) {
              setKey(`fixed-ios-video-${Date.now()}`);
            }
          }, 1000);
          onLoadStart?.();
        }}
        onReadyForDisplay={event => {
          isVideoReadyRef.current = "";
          onReadyForDisplay?.(event);
        }}
      />
    );
  }),
);

export const VideoExpo = Platform.OS === "ios" ? FixedVideo : Video;
