import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useBranchContext } from "@app/context/branch/branch.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { openAppStore } from "@app/utils/share/share.common.util";

import { styles } from "./download-app.style";

interface Props {
  onSignIn: () => void;
}

export const DownloadApp: FC<Props> = ({ onSignIn }) => {
  const { t } = useTranslation();
  const { trackInMixpanel } = useMixpanelContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { openDeepLink } = useBranchContext();

  const handleOpenInAppPress = (): void => {
    openDeepLink(window.location.pathname)
      .then(() => trackInMixpanel(TrackEvent.openAppFromWeb))
      .catch(e =>
        showErrorSnackbar({
          error: e as Error,
          message: t("error.redirection"),
          cta: { label: t("cta.openStore"), onPress: () => void openAppStore() },
        }),
      );
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text variant="subtitle1" style={styles.item}>
          {t("downloadPrompt.app")}
        </Text>

        <View style={styles.item}>
          <Button mode="contained" size="large" onPress={handleOpenInAppPress}>
            {t("cta.open")}
          </Button>
        </View>
      </View>

      <View style={styles.row}>
        <Text variant="subtitle1" style={styles.item}>
          {t("downloadPrompt.stayInBrowser")}
        </Text>
        <View style={styles.item}>
          <Button mode="outlined" size="large" onPress={onSignIn} testID="login-stay-in-browser-button">
            {t("cta.continue")}
          </Button>
        </View>
      </View>
    </View>
  );
};
