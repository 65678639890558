import { gql, useLazyQuery } from "@apollo/client";

import { ReferralLinkInput } from "@app/common/graphql/generated/schema.graphql";

import { ShareLinkResponse, ShareLinkResult } from "./use-share-link.hook";

interface Variables {
  input: ReferralLinkInput;
  userId: string;
}

const referralLinkQuery = gql`
  query ReferralLink($input: ReferralLinkInput!, $userId: UserId!) {
    link: referralLink(input: $input, userId: $userId) {
      url
    }
  }
`;

export function useReferralLink(): ShareLinkResult<Variables> {
  const [getLink, { loading, error, data }] = useLazyQuery<ShareLinkResponse, Variables>(referralLinkQuery);

  return { getLink, loading, error, data: data?.link };
}
