import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentContainer: {
    rowGap: 8,
  },
  listContainer: {
    borderWidth: 1,
    padding: 8,
  },
  textContainer: {
    marginBottom: 16,
  },
});
