import React, { ReactNode } from "react";

import UnknownCardIcon from "@app/assets/icons/unknown-card.svg";
import AmexLogo from "@app/assets/logos/amex-logo.svg";
import CartesBancairesLogo from "@app/assets/logos/cartes-bancaires-logo.svg";
import DinersClubLogo from "@app/assets/logos/diners-club-logo.svg";
import DiscoverLogo from "@app/assets/logos/discover-logo.svg";
import EftposLogo from "@app/assets/logos/eftpos-logo.svg";
import InteracLogo from "@app/assets/logos/interac-logo.svg";
import JcbLogo from "@app/assets/logos/jcb-logo.svg";
import MastercardLogo from "@app/assets/logos/mastercard-logo.svg";
import UnionPayLogo from "@app/assets/logos/union-pay-logo.svg";
import VisaLogo from "@app/assets/logos/visa-logo.svg";

export const cardPaymentBrandIconMap: Record<string, ReactNode> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  amex: <AmexLogo />,
  american_express: <AmexLogo />,
  cartes_bancaires: <CartesBancairesLogo />,
  diners: <DinersClubLogo />,
  diners_club: <DinersClubLogo />,
  discover: <DiscoverLogo />,
  eftpos_au: <EftposLogo />,
  eftpos_australia: <EftposLogo />,
  interac: <InteracLogo />,
  jcb: <JcbLogo />,
  mastercard: <MastercardLogo />,
  unionpay: <UnionPayLogo />,
  union_pay: <UnionPayLogo />,
  visa: <VisaLogo />,
  other: <UnknownCardIcon />,
  unknown: <UnknownCardIcon />,
  /* eslint-enable @typescript-eslint/naming-convention */
};
