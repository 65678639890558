import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  requestDeleteProfile: Profile;
}

interface Variables {
  userId: string;
}

interface RequestDeleteProfileResult extends MutationResult<Response, "requestDeleteProfile"> {
  requestDeleteProfile: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const requestDeleteProfileMutation = gql`
  mutation RequestDeleteProfile($userId: UserId!) {
    requestDeleteProfile(userId: $userId) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useRequestDeleteProfile(): RequestDeleteProfileResult {
  const [requestDeleteProfile, { loading, error, data, reset }] = useMutation<Response, Variables>(requestDeleteProfileMutation);

  return { requestDeleteProfile, loading, error, data: data?.requestDeleteProfile, reset };
}
