import { compare } from "compare-versions";
import Constants from "expo-constants";

export enum FeatureFlag {
  alwaysOn = "alwaysOn",
  enableAndroidDeeplink = "enableAndroidDeeplink",
  enableHealthCheckWebsocket = "enableHealthCheckWebsocket",
  fullStartCampaignFlow = "fullStartCampaignFlow",
  inAppBrowserScreen = "inAppBrowserScreen",
  infiniteStudioTokens = "infiniteStudioTokens", // all users have infinite tokens
  maintenance = "maintenance", // show maintenance
  minVersionSupported = "minVersionSupported", // force update to new version available
  newFacebookLogin = "newFacebookLogin",
  startCampaignFromStudio = "startCampaignFromStudio",
  studioPosesEnabled = "studioPosesEnabled",
  videoOnProfile = "videoOnProfile",
}

export const featureFlagMap = (map: Record<string, boolean | string>): Record<FeatureFlag, boolean> => ({
  [FeatureFlag.alwaysOn]: !!map[FeatureFlag.alwaysOn],
  [FeatureFlag.enableAndroidDeeplink]: !!map[FeatureFlag.enableAndroidDeeplink],
  [FeatureFlag.enableHealthCheckWebsocket]: !!map[FeatureFlag.enableHealthCheckWebsocket],
  [FeatureFlag.fullStartCampaignFlow]: !!map[FeatureFlag.fullStartCampaignFlow],
  [FeatureFlag.inAppBrowserScreen]: !!map[FeatureFlag.inAppBrowserScreen],
  [FeatureFlag.infiniteStudioTokens]: !!map[FeatureFlag.infiniteStudioTokens],
  [FeatureFlag.maintenance]: !!map[FeatureFlag.maintenance],
  [FeatureFlag.minVersionSupported]:
    compare((map[FeatureFlag.minVersionSupported] as string) ?? "1.0.0", Constants.expoConfig?.version ?? "1.0.0", "<=") ?? false,
  [FeatureFlag.newFacebookLogin]: !!map[FeatureFlag.newFacebookLogin],
  [FeatureFlag.startCampaignFromStudio]: !!map[FeatureFlag.startCampaignFromStudio],
  [FeatureFlag.studioPosesEnabled]: !!map[FeatureFlag.studioPosesEnabled],
  [FeatureFlag.videoOnProfile]: !!map[FeatureFlag.videoOnProfile],
});
