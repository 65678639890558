import React, { FC, useEffect, useState, JSX, ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { Keyboard, KeyboardEventListener, Platform, KeyboardEvent } from "react-native";
import { Dialog, DialogProps, Portal, useTheme } from "react-native-paper";

import { ColorVariant } from "@app/common/style/theme";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { ColorKey } from "@app/utils/color-theme.util";

import { Button } from "../button/button.component";

import { styles } from "./confirmation-dialog.style";

interface Props extends Omit<DialogProps, "children"> {
  title: string;
  message: string | ((textProps: Omit<TextProps, "children">) => ReactNode);
  additionalContent?: JSX.Element;
  confirmProps: {
    label: string;
    color?: ColorVariant;
    loading?: boolean;
    onPress: () => void;
  };
  cancelProps?: {
    label: string;
  };
}

export const ConfirmationDialog: FC<Props> = ({
  title,
  message,
  additionalContent,
  confirmProps,
  cancelProps,
  dismissable = false,
  onDismiss,
  style,
  ...props
}) => {
  const { t } = useTranslation();
  const { height: screenHeight } = useWindowDimensions();
  const { colors, roundness } = useTheme();

  // Allow dialog to move up when the keyboard is open so that it won't hide the dialog
  const [dialogBottom, setDialogBottom] = useState(0);

  useEffect(() => {
    const onKeyboardChange: KeyboardEventListener = (event: KeyboardEvent): void => {
      if (!event.startCoordinates) return;

      if (event.endCoordinates.screenY < event.startCoordinates.screenY) setDialogBottom(event.endCoordinates.height / 2);
      else setDialogBottom(0);
    };

    if (Platform.OS === "ios") {
      const subscription = Keyboard.addListener("keyboardWillChangeFrame", onKeyboardChange);
      return () => subscription.remove();
    }

    const subscriptions = [Keyboard.addListener("keyboardDidHide", onKeyboardChange), Keyboard.addListener("keyboardDidShow", onKeyboardChange)];
    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  const messageProps: Omit<TextProps, "children"> = { variant: "body1", textAlign: "center", color: "onTertiaryContainer" as ColorKey };

  return (
    <Portal>
      <Dialog
        dismissable={dismissable}
        onDismiss={onDismiss}
        style={[
          styles.root,
          { bottom: dialogBottom, backgroundColor: colors.tertiaryContainer, borderColor: colors.outline, borderRadius: roundness },
          style,
        ]}
        {...props}>
        <Dialog.Title style={styles.title}>
          <Text variant="h6" textAlign="center" color="onTertiaryContainer">
            {title}
          </Text>
        </Dialog.Title>

        <Dialog.Content style={[styles.content, { maxHeight: screenHeight * 0.5 }]}>
          {typeof message === "string" ? <Text {...messageProps}>{message}</Text> : message(messageProps)}
          {additionalContent}
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            mode={confirmProps.color === "primary" ? "outlined" : "contained"}
            size="large"
            onPress={onDismiss}
            containerStyle={styles.buttonContainer}>
            {cancelProps?.label ?? t("cta.cancel")}
          </Button>
          <Button
            mode="contained"
            size="large"
            color={confirmProps.color ?? "error"}
            loading={confirmProps.loading}
            onPress={confirmProps.onPress}
            containerStyle={styles.buttonContainer}>
            {confirmProps.label}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
