import { AlertButton, AlertOptions, Alert as RNAlert } from "react-native";

const alert = (title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions): void => {
  const fullMessage = message ? `${title}\n${message}` : title;

  if (!buttons?.length || buttons.length === 1) {
    window.alert(fullMessage);

    buttons?.[0].onPress?.();
  } else if (buttons?.length <= 2) {
    const result = window.confirm(fullMessage);

    const cancelButtonIndex = buttons.findIndex(b => b.style === "cancel");
    if (result) {
      const actionButton = buttons[cancelButtonIndex === 0 ? 1 : 0];
      actionButton.onPress?.();
    } else {
      const cancelButton = buttons[cancelButtonIndex];
      cancelButton.onPress?.();
    }
  } else {
    RNAlert.alert(title, message, buttons, options);
  }
};

const prompt = (title: string, message?: string | undefined, buttons?: AlertButton[] | undefined): void => {
  const fullMessage = message ? `${title}\n${message}` : title;

  if (buttons && buttons?.length <= 2) {
    const result = window.prompt(fullMessage);
    const cancelButtonIndex = buttons.findIndex(b => b.style === "cancel");
    if (result) {
      const actionButton = buttons[cancelButtonIndex === 0 ? 1 : 0];
      actionButton.onPress?.(result);
    } else {
      const cancelButton = buttons[cancelButtonIndex];
      cancelButton.onPress?.();
    }
  }
};

export const Alert = {
  alert,
  prompt,
};
