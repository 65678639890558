import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  rowGap: {
    rowGap: 16,
  },
});
