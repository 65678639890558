import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { shippingAddressFields } from "@app/common/graphql/fragments.graphql";
import { SetShippingAddressInput, ShippingAddress } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  setShippingAddress: ShippingAddress;
}

interface Variables {
  input: SetShippingAddressInput;
  userId: string;
}

interface SetShippingAddressResult extends MutationResult<Response, "setShippingAddress"> {
  setShippingAddress: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const setShippingAddressMutation = gql`
  mutation SetShippingAddress($input: SetShippingAddressInput!, $userId: UserId!) {
    setShippingAddress(input: $input, userId: $userId) {
      ...ShippingAddressFields
    }
  }
  ${shippingAddressFields}
`;

export function useSetShippingAddress(): SetShippingAddressResult {
  const [setShippingAddress, { loading, error, data }] = useMutation<Response, Variables>(setShippingAddressMutation, {
    update: (cache, result) => {
      cache.modify({
        fields: {
          shippingAddress: (existingShippingAddress: ShippingAddress) => {
            return result.data?.setShippingAddress ?? existingShippingAddress;
          },
        },
      });
    },
  });

  return { setShippingAddress, loading, error, data: data?.setShippingAddress };
}
