import { createContext, useContext } from "react";

import { GenericSnackbarOptions, SuccessSnackbarOptions } from "./snackbar.types";

export interface SnackbarContextInterface {
  showErrorSnackbar: (options?: GenericSnackbarOptions) => void;
  showSuccessSnackbar: (options?: SuccessSnackbarOptions) => void;
}

export const SnackbarContext = createContext<SnackbarContextInterface>({
  showErrorSnackbar: (_options?: GenericSnackbarOptions) => undefined,
  showSuccessSnackbar: (_options?: SuccessSnackbarOptions) => undefined,
});

export const useSnackbarContext = (): SnackbarContextInterface => useContext(SnackbarContext);
