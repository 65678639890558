import { QueryHookOptions, gql } from "@apollo/client";

import { genericPromoFields, standardPromoFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedAnyPromo } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useQueryWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { mapPromoToRealType, PaginatedAnyPromoFromHook } from "@app/utils/promo.util";

interface InternalResponse {
  promos: PaginatedAnyPromoFromHook;
}

export interface PromosResponse {
  promos: PaginatedAnyPromo;
}

export interface Variables {
  first?: number;
  offset?: number;
}

const promosQuery = gql`
  query Promos($first: Int, $offset: Int) {
    promos: newPromos(first: $first, offset: $offset) {
      nodes {
        ... on GenericPromo {
          ...GenericPromoFields
        }
        ... on StandardPromo {
          ...StandardPromoFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${genericPromoFields}
  ${standardPromoFields}
`;

export function usePromos(options: QueryHookOptions<PromosResponse, Variables>): QueryResult<PromosResponse, "promos"> {
  const transformation = (promos: PaginatedAnyPromoFromHook): PaginatedAnyPromo => ({
    ...promos,
    nodes: promos.nodes?.map(mapPromoToRealType),
  });

  const { data, loading, error, networkStatus, fetchMore, refetch } = useQueryWithTransformation<PromosResponse, InternalResponse, Variables>(
    promosQuery,
    "promos",
    transformation,
    {
      ...options,
    },
  );

  const promos = data ? { ...data.promos, nodes: data.promos.nodes?.map(node => mapPromoToRealType(node)) } : undefined;

  return { data: promos, loading, error, networkStatus, fetchMore, refetch };
}
