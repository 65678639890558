import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  buttonContent: {
    justifyContent: "center",
  },
  container: {
    flex: 1,
  },
  root: {
    paddingHorizontal: 20,
  },
  stepTabsContainer: {
    alignSelf: "center",
    columnGap: 4,
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 20,
    width: "100%",
  },
  textContainer: {
    rowGap: 16,
  },
});
