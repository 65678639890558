import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  content: {
    flexDirection: "row-reverse",
  },
  root: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
});
