import React, { FC, useCallback, useMemo, useState } from "react";

import uniq from "lodash.uniq";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { MediaToBeUsed } from "@app/common/types/image.type";

import { StudioMiniContext, StudioMiniContextType, defaultGenerationInputsValue, defaultInputsValue } from "./studio-mini.context";

export const StudioMiniProvider: FC<ChildrenProp> = ({ children }) => {
  const [inputs, setInputs] = useState(defaultInputsValue);
  const [generation, setGeneration] = useState(defaultGenerationInputsValue);
  const [isCurrentlyGenerating, setIsCurrentlyGenerating] = useState(false);
  const [isCurrentlyGeneratingDescription, setIsCurrentlyGeneratingDescription] = useState(false);

  const setInputMedia = useCallback((callback: ((prevImages: MediaToBeUsed[]) => MediaToBeUsed[]) | MediaToBeUsed[]): void => {
    if (typeof callback === "function") {
      setInputs(prev => {
        return { ...prev, media: uniq(callback(prev.media)) };
      });
    } else {
      setInputs(prev => ({ ...prev, media: callback }));
    }
  }, []);

  const setGenerationImage = useCallback((callback: ((prevImage?: MediaToBeUsed) => MediaToBeUsed | undefined) | MediaToBeUsed | undefined): void => {
    if (typeof callback === "function") {
      setGeneration(prev => {
        return { ...prev, image: callback(prev.image) };
      });
    } else {
      setGeneration(prev => ({ ...prev, image: callback }));
    }
  }, []);

  const resetInputs = useCallback(() => setInputs(defaultInputsValue), []);
  const resetGeneration = useCallback(() => {
    setGeneration(defaultGenerationInputsValue);
    setIsCurrentlyGenerating(false);
  }, []);

  const context = useMemo<StudioMiniContextType>(
    () => ({
      inputs,
      setInputs,
      setInputMedia,
      generation,
      setGeneration,
      resetInputs,
      resetGeneration,
      setGenerationImage,
      isCurrentlyGenerating,
      setIsCurrentlyGenerating,
      isCurrentlyGeneratingDescription,
      setIsCurrentlyGeneratingDescription,
    }),
    [inputs, setInputMedia, generation, resetInputs, resetGeneration, setGenerationImage, isCurrentlyGenerating, isCurrentlyGeneratingDescription],
  );

  return <StudioMiniContext.Provider value={context}>{children}</StudioMiniContext.Provider>;
};
