import { StyleSheet } from "react-native";

const padding = 20;

export const styles = StyleSheet.create({
  categoryName: {
    flexGrow: 1,
  },
  categoryRow: {
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  root: {
    flex: 1,
  },
  section: {
    padding,
    rowGap: 16,
  },
  updatesEmptyState: {
    paddingHorizontal: padding,
  },
});
