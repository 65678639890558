import React from "react";

import groupBy from "lodash.groupby";
import { useTranslation } from "react-i18next";

import VerifiedBadge from "@app/assets/icons/verified-badge.svg";
import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { FilterCategory } from "@app/common/enums/filter-category.enum";
import { SearchCollabsFilteringArgs, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Enum } from "@app/common/types/enum.type";
import { Filter, FilterOption, FilteringArgs } from "@app/common/types/filtering.type";
import { AvatarImageType } from "@app/components/common/avatar-image/avatar-image.component";
import { resources } from "@app/locales/i18n";
import { getTitleCase } from "@app/utils/convert-to-title-case.util";

import { Facets, FacetsTypename, mapStageImage } from "./use-filtering-args.const";

type FilterCategoryWithEnumAsOptions = FilterCategory.stage;

interface FilteringArgsUtils {
  getFilteringArgs: <TFilteringArgs extends FilteringArgs>(filtersSelected: FilterOption[]) => TFilteringArgs;
  getBrowseFilteringArgs: (browseOption: BrowseOption) => SearchCollabsFilteringArgs | undefined;
  getSearchFiltersFromFacets: (facetsTypename: FacetsTypename, facets?: Facets, excludedFilterCategory?: FilterCategory[]) => Filter[];
}

export function useFilteringArgs(): FilteringArgsUtils {
  const { t } = useTranslation();

  const getFilterLabel = (keyValue: string, enumName: keyof typeof resources.en.common.enum): string => {
    return t(`enum.${enumName}.${keyValue}` as keyof (typeof resources.en.common.enum)[typeof enumName], {
      defaultValue: getTitleCase(keyValue),
    });
  };

  const getFilterOptionsFromEnum = <E extends Enum<E>>(
    enumType: E,
    category: FilterCategoryWithEnumAsOptions,
    exclude: string[] = [],
  ): FilterOption[] => {
    const mapEnumCategory: { [key in FilterCategoryWithEnumAsOptions]: keyof typeof resources.en.common.enum } = {
      [FilterCategory.stage]: "stage",
    };
    const enumName = mapEnumCategory[category];

    const filterOptions: FilterOption[] = [];

    Object.keys(enumType).forEach(key => {
      if (exclude.includes(key)) {
        return;
      }

      let icon: AvatarImageType | undefined;
      if (key in Stage) {
        icon = mapStageImage[key as Stage];
      }

      filterOptions.push({
        option: key,
        category,
        label: getFilterLabel(key, enumName),
        icon,
      });
    });

    return filterOptions;
  };

  const getFilteringArgs = <TFilteringArgs extends FilteringArgs>(filtersSelected: FilterOption[]): TFilteringArgs => {
    const filtersGrouped: { [key in FilterCategory]?: FilterOption[] } = groupBy(filtersSelected, "category");
    const filteringArgs = {
      [FilterCategory.stage]: filtersGrouped[FilterCategory.stage]?.map(f => f.option as Stage),
      [FilterCategory.creator]: filtersGrouped[FilterCategory.creator]?.map(f => f.option),
      [FilterCategory.category]: filtersGrouped[FilterCategory.category]?.map(f => f.option),
      [FilterCategory.isCreator]:
        !!filtersGrouped[FilterCategory.isCreator] && filtersGrouped[FilterCategory.isCreator].length > 0 ? true : undefined,
    };

    Object.keys(filteringArgs).forEach(key => {
      const filterKey = key as FilterCategory;
      if (filteringArgs[filterKey] === undefined) {
        delete filteringArgs[filterKey];
      }
    });

    return filteringArgs as unknown as TFilteringArgs;
  };

  const getBrowseFilteringArgs = (browseOption: BrowseOption): SearchCollabsFilteringArgs | undefined => {
    switch (browseOption) {
      case BrowseOption.concepts:
        return { [FilterCategory.stage]: [Stage.concept] };
      case BrowseOption.underReview:
        return { [FilterCategory.stage]: [Stage.underReview] };
      case BrowseOption.sellables:
        return { [FilterCategory.stage]: [Stage.forSale] };
      default:
        return undefined;
    }
  };

  const getSearchFiltersFromFacets = (facetsTypename: FacetsTypename, facets?: Facets, excludedFilterCategory: FilterCategory[] = []): Filter[] => {
    function removeExcludedFilters(filters: Filter[]): Filter[] {
      return filters.filter(filter => !excludedFilterCategory.includes(filter.category));
    }

    const categoryFilter: Filter = { category: FilterCategory.category, options: [] };
    const creatorFilter: Filter = { category: FilterCategory.creator, options: [] };
    const stageFilter: Filter = { category: FilterCategory.stage, options: [] };
    const isCreatorFilter: Filter = { category: FilterCategory.isCreator, options: [] };

    if (!facets) {
      const filters = facetsTypename === FacetsTypename.collab ? [categoryFilter, creatorFilter, stageFilter] : [isCreatorFilter];
      return removeExcludedFilters(filters);
    }

    if (facets.__typename === FacetsTypename.collab) {
      facets.categories?.forEach(({ category }) => {
        categoryFilter.options.push({
          option: category.categoryId,
          category: FilterCategory.category,
          label: category.name,
          icon: category.imageUrl,
        });
      });

      facets.creators?.forEach(({ creator }) => {
        creatorFilter.options.push({
          option: creator.userId,
          category: FilterCategory.creator,
          label: `@${creator.username}`,
          subLabel: creator.displayName,
          description: creator.occupation,
          icon: creator.imageUrl,
        });
      });

      const excludedStageOption = Object.keys(Stage).filter(stage => !facets.stages.find(facet => facet.stage === stage));
      stageFilter.options = getFilterOptionsFromEnum(Stage, FilterCategory.stage, excludedStageOption);

      return removeExcludedFilters([categoryFilter, creatorFilter, stageFilter]);
    } else {
      const isCreatorFacetValue = facets.isCreator.find(facetValue => facetValue.isCreator === true);

      if (isCreatorFacetValue) {
        isCreatorFilter.options.push({
          option: isCreatorFacetValue.isCreator.toString(),
          category: FilterCategory.isCreator,
          label: t("enum.filterCategory.isCreator"),
          icon: () => <VerifiedBadge width={16} height={16} />,
        });
      }

      return [isCreatorFilter];
    }
  };

  return {
    getFilteringArgs,
    getBrowseFilteringArgs,
    getSearchFiltersFromFacets,
  };
}
