import { StyleSheet } from "react-native";

const emptyStateBorderRadius = 8;

export const styles = StyleSheet.create({
  bioPronouns: {
    gap: 8,
  },
  centerLinks: {
    justifyContent: "center",
  },
  emptyState: {
    borderRadius: emptyStateBorderRadius,
    paddingHorizontal: 32,
    paddingVertical: 16,
    width: "100%",
  },
  instagramGradient: {
    borderRadius: emptyStateBorderRadius,
  },
  linkButton: {
    height: 48,
    margin: 0,
    width: 48,
  },
  linkInstagramWrapper: {
    margin: 1,
  },
  nameOccImageContent: {
    columnGap: 8,
  },
  root: {
    marginBottom: 16,
    marginHorizontal: 8,
    rowGap: 16,
  },
  row: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
    width: "100%",
  },
  verifiedBadge: {
    position: "absolute",
    right: -5,
    top: -5,
  },
});
