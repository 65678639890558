import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { paymentMethodFields } from "@app/common/graphql/fragments.graphql";
import { CardPaymentMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  paymentMethodForAnonymous: CardPaymentMethod;
}

interface Variables {
  anonymousCustomerId?: string;
}

export const paymentMethodForAnonymousQuery = gql`
  query PaymentMethodForAnonymous($anonymousCustomerId: AnonymousCustomerId!) {
    paymentMethodForAnonymous(anonymousCustomerId: $anonymousCustomerId) {
      ...PaymentMethodFields
    }
  }
  ${paymentMethodFields}
`;

export function usePaymentMethodForAnonymous(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "paymentMethodForAnonymous"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(paymentMethodForAnonymousQuery, {
    skip: !options.variables?.anonymousCustomerId,
    ...options,
  });

  return { data: data?.paymentMethodForAnonymous, loading, error, refetch };
}
