import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, CreateStudioCollabInput, PaginatedCollabs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  createStudioCollab: Collab;
}

interface Variables {
  input: CreateStudioCollabInput;
}

interface CreateStudioCollabResult extends MutationResult<Response, "createStudioCollab"> {
  createStudioCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const createStudioCollabMutation = gql`
  mutation CreateStudioCollab($input: CreateStudioCollabInput!) {
    createStudioCollab(input: $input) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useCreateStudioCollab(): CreateStudioCollabResult {
  const [createStudioCollab, { loading, error, data }] = useMutation<Response, Variables>(createStudioCollabMutation, {
    update: (cache, result, options) => {
      cache.modify({
        fields: {
          collabsForUser: (existingCollabs: PaginatedCollabs, { toReference, storeFieldName }) => {
            if (!storeFieldName.includes(options.variables?.input.creatorId ?? "")) return existingCollabs;

            return {
              ...existingCollabs,
              nodes: [toReference(result.data?.createStudioCollab.collabId as string), ...(existingCollabs.nodes ?? [])],
              totalCount: existingCollabs.totalCount + 1,
              __typename: existingCollabs.__typename,
            };
          },
        },
      });
    },
  });

  return { createStudioCollab, loading, error, data: data?.createStudioCollab };
}
