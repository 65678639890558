import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { SetImageInExploreInput, StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setImageShownInExplore: StudioImage;
}

interface Variables {
  imageId: string;
  input: SetImageInExploreInput;
}

interface SetImageShownInExploreResult extends MutationResult<Response, "setImageShownInExplore"> {
  setImageShownInExplore: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const setImageShownInExploreMutation = gql`
  mutation SetImageShownInExplore($imageId: StudioImageId!, $input: SetImageInExploreInput!) {
    setImageShownInExplore(imageId: $imageId, input: $input) {
      ...CoreStudioImageFields
    }
  }
  ${coreStudioImageFields}
`;

export function useSetImageShownInExplore(): SetImageShownInExploreResult {
  const [setImageShownInExplore, { loading, error, data, reset }] = useMutation<Response, Variables>(setImageShownInExploreMutation);

  return { setImageShownInExplore, loading, error, data: data?.setImageShownInExplore, reset };
}
