import React, { FC } from "react";

import { formatDate } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";

import FinancialQuote from "@app/assets/icons/financial-quote.svg";
import { Progress } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useQuoteRequestStatus } from "@app/hooks/api/products/use-quote-request-status.hook";
import { useRequestQuoteForCollab } from "@app/hooks/api/products/use-request-quote-for-collab.hook";
import { accessRoleSuperplanMap } from "@app/utils/access-role.util";
import { getTitleCase } from "@app/utils/convert-to-title-case.util";

import { styles } from "./request-a-quote-button.style";

interface Props extends Omit<ButtonProps, "children"> {
  collabId: string;
  progress: Progress;
}

export const RequestAQuoteButton: FC<Props> = ({ collabId, progress, containerStyle, ...buttonProps }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const navigation = useNavigation();

  const { featureAccesses } = useProfileContext();

  const { requestQuoteForCollab, loading: loadingRequestQuote } = useRequestQuoteForCollab();
  const { data: quoteRequestStatus, loading: loadingQuoteRequestStatus } = useQuoteRequestStatus({
    variables: { collabId },
    skip: !progress.canRequestQuote || featureAccesses?.moreQuoteRequests.allowed,
  });

  const requiredRoleForMoreQuotes = featureAccesses?.moreQuoteRequests.requiredRole;
  const requiredSuperplanForMoreQuotes = requiredRoleForMoreQuotes ? accessRoleSuperplanMap[requiredRoleForMoreQuotes] : undefined;
  const loading = loadingRequestQuote || loadingQuoteRequestStatus;
  const hasRequestedMaxQuotes = quoteRequestStatus?.canRequestQuote === false;

  const handlePress = (): void => {
    if (!progress.canRequestQuote) return;

    void requestQuoteForCollab({
      variables: { collabId },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <View style={[containerStyle, styles.root]}>
      <Button
        {...buttonProps}
        loading={loading}
        disabled={progress.waitingForQuote || hasRequestedMaxQuotes}
        icon={FinancialQuote}
        onPress={handlePress}>
        {t(`startCampaign.${progress.canRequestQuote || hasRequestedMaxQuotes ? "askForAQuote" : "label"}`)}
      </Button>
      {hasRequestedMaxQuotes && (
        <Trans
          i18nKey="startCampaign.quoteRequestLimit"
          values={{
            quoteRequestsPerWeek: quoteRequestStatus?.quoteRequestsPerWeek,
            nextQuoteRequestAvailableAt: quoteRequestStatus?.nextQuoteRequestAvailableAt
              ? formatDate(new Date(quoteRequestStatus?.nextQuoteRequestAvailableAt), t("dateFormat.date"))
              : "",
            requiredSuperplanForMoreQuotes: getTitleCase(requiredSuperplanForMoreQuotes),
          }}
          parent={({ children }: ChildrenProp) => (
            <Text variant="helperText" color="tertiary">
              {children}
            </Text>
          )}
          components={{
            sLink: (
              <Text
                variant="helperText"
                color="tertiary"
                textDecorationLine="underline"
                onPress={() => navigation.navigate(Routes.superplans, { tab: requiredSuperplanForMoreQuotes })}>
                <></>
              </Text>
            ),
          }}
        />
      )}
    </View>
  );
};
