import { gql, QueryHookOptions } from "@apollo/client";

import { genericPromoFields, standardPromoFields } from "@app/common/graphql/fragments.graphql";
import { AnyPromo } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useQueryWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { AnyPromoFromHook, mapPromoToRealType } from "@app/utils/promo.util";

interface InternalResponse {
  promo: AnyPromoFromHook;
}

interface Response {
  promo: AnyPromo;
}

interface Variables {
  promoId?: string;
}

const promoQuery = gql`
  query Promo($promoId: PromoId!) {
    promo: newPromo(promoId: $promoId) {
      ... on GenericPromo {
        ...GenericPromoFields
      }
      ... on StandardPromo {
        ...StandardPromoFields
      }
    }
  }
  ${genericPromoFields}
  ${standardPromoFields}
`;

export function usePromo(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "promo"> {
  const { data, loading, error } = useQueryWithTransformation<Response, InternalResponse, Variables>(promoQuery, "promo", mapPromoToRealType, {
    skip: !options.variables?.promoId,
    ...options,
  });

  return { data: data ? mapPromoToRealType(data.promo) : undefined, loading, error };
}
