import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { GenerateImagesInput, StudioTask } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

import { imageGenerationCreditQuery } from "../use-image-generation-credits.hook";

interface Response {
  startGeneratingImages: StudioTask;
}

interface Variables {
  input: GenerateImagesInput;
  userId: string;
}

interface StartGeneratingImagesResult extends MutationResult<Response, "startGeneratingImages"> {
  startGeneratingImages: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const startGeneratingImagesMutation = gql`
  mutation StartGeneratingImages($input: GenerateImagesInput!, $userId: UserId!) {
    startGeneratingImages(input: $input, userId: $userId) {
      studioTaskId
    }
  }
`;

export function useStartGeneratingImages(): StartGeneratingImagesResult {
  const [startGeneratingImages, { loading, error, data, reset, client }] = useMutation<Response, Variables>(startGeneratingImagesMutation, {
    update: () => {
      void client.refetchQueries({ include: [imageGenerationCreditQuery] });
    },
  });

  return { startGeneratingImages, loading, error, data: data?.startGeneratingImages, reset };
}
