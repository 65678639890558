import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreCategoryFieldsWithCount } from "@app/common/graphql/fragments.graphql";
import { PaginatedCategoriesWithCount } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface Response {
  categories: PaginatedCategoriesWithCount;
}

export interface Variables {
  first?: number;
  offset?: number;
}

const categoriesQuery = gql`
  query Categories($first: Int, $offset: Int) {
    categories(first: $first, offset: $offset) {
      nodes {
        ...CoreCategoryFieldsWithCount
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreCategoryFieldsWithCount}
`;

export function useCategories(options?: QueryHookOptions<Response, Variables>): QueryResult<Response, "categories"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<Response, Variables>(categoriesQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.categories, loading, error, networkStatus, fetchMore, refetch };
}
