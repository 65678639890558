import React, { FC, useCallback, useMemo } from "react";

import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { ImagePickerAsset } from "expo-image-picker";
import { BookmarkSimple, CaretRight, UploadSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { ActivityIndicator, List } from "react-native-paper";

import { imageRatioText } from "@app/common/constants/image.const";
import { MediaToBeUsed } from "@app/common/types/image.type";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useUploadNewImage } from "@app/hooks/utils/use-upload-new-image.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";
import { pickImage, pickImages } from "@app/utils/image-picker.util";
import { reportError } from "@app/utils/logger/logger.util";

import { paddingHorizontal, styles } from "./studio-select-image-source.style";

const maxWidthOrHeight = 1024;

interface Props {
  allowMultipleSelection: boolean;
  setMedia: React.Dispatch<React.SetStateAction<MediaToBeUsed[]>>;
  onImageSelected: () => void;
  onNavigateToSaved: () => void;
  withHelperFooter?: boolean;
}

export const StudioSelectImageSource: FC<Props> = ({
  allowMultipleSelection = true,
  setMedia,
  onImageSelected,
  onNavigateToSaved,
  withHelperFooter = false,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const { profile } = useProfileContext();
  const { maxImagesPerCollab } = useConfigContext();

  const { uploadNewImage, loading } = useUploadNewImage();

  const resizeImage = async (image: ImagePickerAsset): Promise<string> => {
    let targetSize, anchorX, anchorY;

    if (image.width === image.height) {
      targetSize = image.width;

      anchorX = 0;
      anchorY = 0;
    } else if (image.width > image.height) {
      targetSize = image.height;

      anchorX = (image.width - image.height) / 2;
      anchorY = 0;
    } else {
      targetSize = image.width;

      anchorX = 0;
      anchorY = (image.height - image.width) / 2;
    }

    try {
      const manipResult = await manipulateAsync(
        image.uri,
        [
          { crop: { width: targetSize, height: targetSize, originX: anchorX, originY: anchorY } },
          { resize: { height: maxWidthOrHeight, width: maxWidthOrHeight } },
        ],
        {
          compress: 1,
          format: SaveFormat.JPEG,
        },
      );

      return manipResult.uri;
    } catch (e) {
      reportError(e as Error);
      return image.uri;
    }
  };

  const handlePickImages = useCallback(async () => {
    // TODO(Kait): support possibility to pick video too
    try {
      if (allowMultipleSelection) {
        const imagesResult = await pickImages({ selectionLimit: 0 });
        if (imagesResult.error || !imagesResult.assets?.length) return;

        await Promise.all(
          imagesResult.assets.map(a =>
            uploadNewImage({
              media: {
                imageUrl: a.uri,
                imageName: a.fileName,
                imageId: "",
              },
              userId: profile?.userId,
              setMedia,
            }),
          ),
        );
      } else {
        const imagesResult = await pickImage();
        if (imagesResult.error || !imagesResult.asset) return;
        const imageUri = isWeb ? await resizeImage(imagesResult.asset) : imagesResult.asset.uri;

        await uploadNewImage({
          media: {
            imageUrl: imageUri,
            imageName: imagesResult.asset.fileName,
            imageId: "",
          },
          userId: profile?.userId,
          setMedia,
        });
      }
    } catch (e) {
      reportError(e as Error);
    }

    onImageSelected();
  }, [allowMultipleSelection, onImageSelected, profile?.userId, setMedia, uploadNewImage]);

  const options = useMemo<{ icon: FC; title: string; description?: string; onPress: () => void; right: FC<{ color: string }> }[]>(
    () => [
      {
        icon: props => <BookmarkSimple weight="fill" {...props} />,
        title: t("studioMini.imageSource.savedShots"),
        onPress: onNavigateToSaved,
        right: CaretRight,
      },
      {
        icon: props => <UploadSimple weight="fill" {...props} />,
        title: t("studioMini.imageSource.uploadImages"),
        onPress: () => void handlePickImages(),
        right: ({ color, ...props }) => (loading ? <ActivityIndicator animating color={color} /> : <CaretRight color={color} {...props} />),
      },
    ],
    [handlePickImages, loading, onNavigateToSaved, t],
  );

  return (
    <FlatList
      data={options}
      keyExtractor={option => option.title}
      renderItem={({ item }) => (
        <List.Item
          left={({ style, color }) => <View style={style}>{item.icon({ color })}</View>}
          title={item.title}
          onPress={item.onPress}
          right={({ color, style }) => <View style={style}>{item.right({ color })}</View>}
          disabled={loading}
        />
      )}
      ListFooterComponentStyle={withHelperFooter && styles.footer}
      ListFooterComponent={
        withHelperFooter
          ? () => (
              <View style={styles.footerContent}>
                <Text variant="caption" color="tertiary" style={styles.desription}>
                  {t("studioMini.imageSource.uploadDescription", { imageRatio: imageRatioText, maxImages: maxImagesPerCollab })}
                </Text>
                <Image
                  source={require("@app/assets/images/preview-image-on-feed-vs-card.png") as number}
                  resizeMode="contain"
                  style={{ width: width - paddingHorizontal * 2, height: width }}
                />
              </View>
            )
          : null
      }
      contentContainerStyle={styles.root}
    />
  );
};
