import { StyleSheet } from "react-native";

const textShadowColor = "rgba(0,0,0,0.3)";

export const styles = StyleSheet.create({
  buttonIconContainer: {
    alignItems: "center",
  },
  iconButton: {
    margin: 0,
  },
  textShadow: {
    textShadowColor,
    textShadowOffset: { width: 0.5, height: 0.5 },
    textShadowRadius: 0,
  },
});
