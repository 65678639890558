import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  chip: {
    alignSelf: "flex-start",
    borderRadius: 2,
  },
  root: {
    rowGap: 4,
    width: "100%",
  },
});
