import { useCallback, useState } from "react";

import { useLinkTo } from "@react-navigation/native";
import { SessionData } from "branch-sdk";
import { BranchSubscriptionEvent } from "react-native-branch";

import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { isWeb } from "@app/utils/device.util";

import { setUpBranch as setUpBranchInternal } from "./branch";
import { BranchCampaign, BranchLinkPath, Channel, logBranch } from "./branch.common";

interface BranchEventHandlerUtils {
  setupBranch: (callback: () => void) => () => void;
  channel: Channel | undefined;
}

export function useBranchEventHandler(): BranchEventHandlerUtils {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const [channel, setChannel] = useState<Channel | undefined>();

  const { setReferrerId } = useProfileContext();
  const { setHideLoginScreen } = useOnboardingContext();

  const getParams = (data: BranchSubscriptionEvent | SessionData): Record<string, unknown> | undefined => {
    if (isWeb) {
      return (data as SessionData).data_parsed;
    } else {
      return (data as BranchSubscriptionEvent).params;
    }
  };

  const handleIncomingDeepLink = useCallback(
    // eslint-disable-next-line complexity
    (data: BranchSubscriptionEvent | SessionData): void => {
      logBranch.info("opening deep link", data);

      if ("error" in data && !!data.error) return;

      const params = getParams(data);
      if (!params) return;

      if (params["~channel"] && params["~channel"] === "shopify") {
        setChannel(Channel.shopify);
      } else {
        setChannel(Channel.unknown);
      }
      logBranch.info("Link params", params);
      if (params.$canonical_identifier) {
        const canonicalIdentifier = params.$canonical_identifier;
        if (typeof canonicalIdentifier !== "string") return;

        switch (canonicalIdentifier) {
          case BranchLinkPath.auth:
            setHideLoginScreen(true);
            navigation.navigate(Routes.handleAuthAction, {
              mode: typeof params.mode === "string" ? params.mode : undefined,
              oobCode: typeof params.oobCode === "string" ? params.oobCode : undefined,
            });
            break;
          case BranchLinkPath.referral: {
            const referrerId = params.referrerId;
            if (typeof referrerId === "string" && referrerId !== "") {
              logBranch.info("Setting ReferrerId ", referrerId);
              setReferrerId(referrerId);
            }
            if (params.path && typeof params.path === "string") {
              logBranch.info("Redirecting referral to path", params.path);
              linkTo(params.path);
            }

            break;
          }
          default: {
            const feature = params["~feature"];
            const campaign = params["~campaign"];

            if (feature && feature === BranchCampaign.sharing) {
              logBranch.debug("Feature match", feature);
              if (campaign && typeof campaign === "string") {
                logBranch.debug("campaign match", campaign);
                setReferrerId(campaign);
              }
            }

            logBranch.info("default branch redirect", canonicalIdentifier);
            linkTo(canonicalIdentifier);
            break;
          }
        }
      }
    },
    [linkTo, navigation, setChannel, setHideLoginScreen, setReferrerId],
  );

  const setupBranch = useCallback(
    (callback: () => void): (() => void) => {
      return setUpBranchInternal(handleIncomingDeepLink, callback);
    },
    [handleIncomingDeepLink],
  );

  return { setupBranch, channel };
}
