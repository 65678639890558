import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreStudioAnimationFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioMedia, SaveTemporaryAnimationInput, StudioAnimation } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  saveTemporaryAnimation: StudioAnimation;
}

interface Variables {
  input: SaveTemporaryAnimationInput;
}

interface SaveTemporaryMediaResult extends MutationResult<Response, "saveTemporaryAnimation"> {
  saveTemporaryAnimation: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const saveTemporaryImagesMutation = gql`
  mutation SaveTemporaryAnimation($input: SaveTemporaryAnimationInput!) {
    saveTemporaryAnimation(input: $input) {
      ...CoreStudioAnimationFields
    }
  }
  ${coreStudioAnimationFields}
`;

export function useSaveTemporaryAnimation(): SaveTemporaryMediaResult {
  const [saveTemporaryAnimation, { loading, error, data }] = useMutation<Response, Variables>(saveTemporaryImagesMutation, {
    update: (cache, results) => {
      cache.modify({
        fields: {
          studioMedia(existingStudioMedia: PaginatedStudioMedia, { readField, toReference }) {
            const newMediaId = results.data?.saveTemporaryAnimation.studioAnimationId;

            const isNewMediaAlreadyAdded = existingStudioMedia.nodes?.findIndex(media => readField("StudioAnimationId", media) === newMediaId) !== -1;

            if (isNewMediaAlreadyAdded) return existingStudioMedia;

            return {
              ...existingStudioMedia,
              nodes: [toReference(`studioMedia-${newMediaId}`), ...(existingStudioMedia.nodes ?? [])],
              totalCount: existingStudioMedia.totalCount + 1,
            };
          },
        },
      });
    },
  });

  return { saveTemporaryAnimation, loading, error, data: data?.saveTemporaryAnimation };
}
