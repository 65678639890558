import React, { FC, useEffect } from "react";

import { useHeaderHeight } from "@react-navigation/elements";
import { X } from "phosphor-react-native";
import { IconButton, useTheme } from "react-native-paper";
import { Zoom } from "react-native-reanimated-zoom";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

export const ImageScreen: FC<NavigationProps<Routes.image>> = ({ navigation, route }) => {
  const imageUrl = route.params?.imageUrl;
  const { colors } = useTheme();
  const { width, height: windowHeight } = useWindowDimensions();
  const headerHeight = useHeaderHeight();
  const { bottom } = useSafeAreaInsets();

  const imageHeight = windowHeight - headerHeight - bottom;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon={X} onPress={() => navigation.goBack()} />,
    });
  }, [navigation]);

  return (
    <ScreenWrapper>
      <Zoom>
        <Image
          source={imageUrl}
          width={width}
          height={imageHeight}
          resizeMode="contain"
          style={{
            backgroundColor: colors.background,
          }}
        />
      </Zoom>
    </ScreenWrapper>
  );
};
