import React, { FC, useState } from "react";

import { DatePickerModal, TimePickerModal, en, registerTranslation } from "react-native-paper-dates";

import { Chip } from "../chip/chip.component";

import { DateTimePickerProps } from "./date-time-picker.common";

registerTranslation("en", en);

export const DateTimePicker: FC<DateTimePickerProps> = ({ date, setDate }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  return (
    <>
      <Chip label={date.toDateString()} onPress={() => setDatePickerOpen(true)} />
      <Chip label={date.toTimeString()} onPress={() => setTimePickerOpen(true)} />

      <DatePickerModal
        locale="en"
        mode="single"
        visible={datePickerOpen}
        onDismiss={() => setDatePickerOpen(false)}
        date={date}
        onConfirm={({ date: newDate }) => {
          setDatePickerOpen(false);
          if (newDate) {
            const newValue = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), date.getHours(), date.getMinutes());
            setDate(newValue);
          } else {
            setDate(null);
          }
        }}
      />

      <TimePickerModal
        locale="en"
        visible={timePickerOpen}
        onDismiss={() => setTimePickerOpen(false)}
        hours={date.getHours()}
        minutes={date.getMinutes()}
        onConfirm={({ hours, minutes }) => {
          setTimePickerOpen(false);
          const newValue = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
          setDate(newValue);
        }}
      />
    </>
  );
};
