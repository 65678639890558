import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { useNavigation } from "@app/common/types/navigation.type";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteCollabUpdate } from "@app/hooks/api/updates/use-delete-collab-update.hook";

interface Props {
  updateId: string;
  visible: boolean;
  onDismiss: () => void;
}

export const DeleteUpdateConfirmationDialog: FC<Props> = ({ updateId, visible, onDismiss }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { showErrorSnackbar } = useSnackbarContext();

  const { deleteUpdate, loading: deleteUpdateLoading } = useDeleteCollabUpdate();

  const handleDelete = (): void => {
    void deleteUpdate({
      variables: { collabUpdateId: updateId },
      onCompleted: () => navigation.goBack(),
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <ConfirmationDialog
      visible={visible}
      title={t("confirmation.deleteCollabUpdate.title")}
      message={t("confirmation.deleteCollabUpdate.message")}
      confirmProps={{
        label: t("cta.delete"),
        loading: deleteUpdateLoading,
        onPress: handleDelete,
      }}
      onDismiss={onDismiss}
    />
  );
};
