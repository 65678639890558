/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import { Ticket, Warning } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { isErrorCode, isErrorMessage } from "@app/common/apollo/apollo.utils";
import { maxContestSubmissionsAllowed } from "@app/common/constants/contests.const";
import { CollabSuccessType } from "@app/common/enums/collab-success-type.enum";
import { getMediaToBeUsedId } from "@app/common/types/image.type";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { CreateCollabForm } from "@app/components/studio-mini/create-collab-form/create-collab-form.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useCreateContestCollab } from "@app/hooks/api/use-create-contest-collab.hook";
import { useCreateStudioCollab } from "@app/hooks/api/use-create-studio-collab.hook";
import { useUpdateCollabWithMedia } from "@app/hooks/api/use-update-collab.hook";

import { styles } from "./create-studio-collab.style";

export const CreateStudioCollabScreen: FC<NavigationProps<Routes.studioMiniCreateCollab>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  const { maxImagesPerCollab } = useConfigContext();
  const { contestPasswords, setLocalStorage } = useLocalStorageContext();
  const { profile } = useProfileContext();
  const {
    inputs: { contest, productName, productCategory, productDescription, media, collabId },
    resetInputs,
  } = useStudioMiniContext();

  const isEditing = !!collabId;

  const [isContest, setIsContest] = useState(!!contest);
  const [termsChecked, setTermsChecked] = useState(isEditing);

  const { createStudioCollab, loading: loadingCreateStudioCollab } = useCreateStudioCollab();
  const { createContestCollab, loading: loadingCreateContestCollab } = useCreateContestCollab();
  const { updateCollab, loading: loadingUpdateCollab } = useUpdateCollabWithMedia();

  const contestCategory = contest?.category;
  const contestPassword = contest?.id ? contestPasswords[contest?.id] : undefined;
  const userId = profile?.userId;
  const loading = loadingCreateStudioCollab || loadingCreateContestCollab || loadingUpdateCollab;
  const categoryId = contestCategory?.categoryId ?? productCategory?.id;
  const isSubmissionReady = productName && categoryId && productDescription && media.length && termsChecked;
  const isReady = isContest ? !!(contest && isSubmissionReady) : !!isSubmissionReady;

  const handleSuccess = useCallback(
    (newCollabId: string): void => {
      resetInputs();
      navigation.navigate(Routes.collabSuccess, {
        collabId: newCollabId,
        type: isContest ? CollabSuccessType.contestEntrySubmitted : CollabSuccessType.conceptSubmitted,
      });
    },
    [isContest, navigation, resetInputs],
  );

  const handleCancel = useCallback(() => {
    resetInputs();

    navigation.goBack();
  }, [navigation, resetInputs]);

  const handleCollabError = useCallback(
    (error: ApolloError): void => {
      const maxImagesError =
        isErrorCode(error, "TOO_MANY_COLLAB_IMAGES") ||
        isErrorMessage(error, `input.mediaIds must contain no more than ${maxImagesPerCollab} elements`);
      const animationCannotBeFirstError = isErrorCode(error, "ANIMATION_CANNOT_BE_FIRST_MEDIA");
      const tooManyAnimationVideosError = isErrorCode(error, "TOO_MANY_ANIMATION_VIDEOS");
      const shouldIgnoreErrorForSentry = maxImagesError || animationCannotBeFirstError || tooManyAnimationVideosError;

      let message = t("error.generic");
      if (maxImagesError) message = t("error.maxImages", { maxImages: maxImagesPerCollab });
      else if (animationCannotBeFirstError) message = t("error.video.firstAsImage");
      else if (tooManyAnimationVideosError) message = t("error.video.tooManyVideos");

      const icon = maxImagesError ? <Warning weight="thin" color={colors.onPrimary} /> : undefined;

      showErrorSnackbar({ message, icon, error: shouldIgnoreErrorForSentry ? undefined : error });
    },
    [colors.onPrimary, maxImagesPerCollab, showErrorSnackbar, t],
  );

  const handleContestCollabError = useCallback(
    (error: ApolloError, contestId: string): void => {
      const maxSubmissionsReachedError = isErrorCode(error, "MAXIMUM_OF_SUBMISSION_REACHED");
      const unauthorizedError = isErrorCode(error, "UNAUTHORIZED");
      const forbiddenError = isErrorCode(error, "FORBIDDEN");
      const maxImagesError = isErrorMessage(error, `input.mediaIds must contain no more than ${maxImagesPerCollab} elements`);
      const animationCannotBeFirstError = isErrorCode(error, "ANIMATION_CANNOT_BE_FIRST_MEDIA");
      const tooManyAnimationVideosError = isErrorCode(error, "TOO_MANY_ANIMATION_VIDEOS");
      const shouldIgnoreErrorForSentry =
        maxSubmissionsReachedError ||
        unauthorizedError ||
        forbiddenError ||
        maxImagesError ||
        animationCannotBeFirstError ||
        tooManyAnimationVideosError;

      if (unauthorizedError || forbiddenError) {
        delete contestPasswords[contestId];
        setLocalStorage(LocalStorageKeys.contestPasswords, contestPasswords);
      }

      let message = t("error.generic");
      if (maxSubmissionsReachedError) message = t("error.maxContestSubmissionsReached", { max: maxContestSubmissionsAllowed });
      else if (maxImagesError) message = t("error.maxImages", { maxImages: maxImagesPerCollab });
      else if (animationCannotBeFirstError) message = t("error.video.firstAsImage");
      else if (tooManyAnimationVideosError) message = t("error.video.tooManyVideos");

      let icon;
      if (maxSubmissionsReachedError) icon = <Ticket weight="thin" color={colors.onPrimary} />;
      else if (maxImagesError) icon = <Warning weight="thin" color={colors.onPrimary} />;

      showErrorSnackbar({ message, icon, error: shouldIgnoreErrorForSentry ? undefined : error });
    },
    [colors.onPrimary, contestPasswords, maxImagesPerCollab, setLocalStorage, showErrorSnackbar, t],
  );

  const handleCreateContestCollab = useCallback(
    (contestId: string, name: string, creatorId: string, collabCategoryId: string, description: string): void => {
      void createContestCollab({
        variables: {
          input: {
            contestId,
            name,
            creatorId,
            categoryId: collabCategoryId,
            description,
            mediaIds: media.map(i => getMediaToBeUsedId(i)).filter(Boolean),
          },
        },
        context: {
          headers: {
            ...(contest?.passwordRequired && contestPassword ? { "Bloom-Contest-Password": contestPassword } : {}),
          },
        },
        onCompleted: data => handleSuccess(data.createContestCollab.collabId),
        onError: error => handleContestCollabError(error, contestId),
      });
    },
    [contest?.passwordRequired, contestPassword, createContestCollab, handleContestCollabError, handleSuccess, media],
  );

  const handleCreateCollab = useCallback(
    (name: string, creatorId: string, collabCategoryId: string, description: string): void => {
      void createStudioCollab({
        variables: {
          input: {
            name,
            creatorId,
            categoryId: collabCategoryId,
            description,
            mediaIds: media.map(i => getMediaToBeUsedId(i) ?? "").filter(Boolean),
          },
        },
        onCompleted: data => handleSuccess(data.createStudioCollab.collabId),
        onError: handleCollabError,
      });
    },
    [createStudioCollab, handleCollabError, handleSuccess, media],
  );

  const handleUpdateCollab = useCallback(
    (name: string, collabCategoryId: string, description: string) => {
      if (!collabId) return;

      void updateCollab({
        variables: {
          collabId,
          input: { name, description, categoryId: collabCategoryId, mediaIds: media.map(i => getMediaToBeUsedId(i) ?? "").filter(Boolean) },
        },
        onCompleted: () => {
          showSuccessSnackbar({ message: t("studioMini.create.updated") });
          handleCancel();
        },
        onError: handleCollabError,
      });
    },
    [handleCancel, handleCollabError, media, collabId, showSuccessSnackbar, t, updateCollab],
  );

  const handleSubmit = useCallback(() => {
    if (!userId) return;
    if (!isSubmissionReady) return;

    if (isEditing) {
      handleUpdateCollab(productName, categoryId, productDescription);
    } else if (isContest && contest) {
      handleCreateContestCollab(contest.id, productName, userId, categoryId, productDescription);
    } else {
      handleCreateCollab(productName, userId, categoryId, productDescription);
    }
  }, [
    categoryId,
    contest,
    handleCreateCollab,
    handleCreateContestCollab,
    handleUpdateCollab,
    isContest,
    isEditing,
    isSubmissionReady,
    productDescription,
    productName,
    userId,
  ]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button disabled={!isReady || loading} onPress={handleSubmit} loading={loading}>
          {isEditing ? t("cta.update") : t("cta.post")}
        </Button>
      ),
      headerLeft: () => <Button onPress={handleCancel}>{t("cta.cancel")}</Button>,
    });
  }, [handleCancel, handleSubmit, isEditing, isReady, loading, navigation, t]);

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <CreateCollabForm
        isEditing={isEditing}
        isContest={isContest}
        setIsContest={setIsContest}
        contestCategory={contestCategory}
        termsChecked={termsChecked}
        setTermsChecked={setTermsChecked}
      />
    </ScreenWrapper>
  );
};
