import React, { FC, useCallback } from "react";

import { UploadSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { CollabMediaContent } from "@app/common/graphql/generated/schema.graphql";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUploadVideo } from "@app/hooks/api/upload-video/use-upload-video.hook";
import { useUpdateCollabWithMedia } from "@app/hooks/api/use-update-collab.hook";
import { isImage } from "@app/utils/product-media.util";

import { styles } from "./upload-pitch-video-button.style";

interface Props extends Omit<ButtonProps, "children" | "onPress"> {
  collabId: string | undefined;
  existingMedia: CollabMediaContent[] | undefined;
  onCompleted: () => void;
}

export const UploadPitchVideoButton: FC<Props> = ({ collabId, existingMedia, onCompleted, ...buttonProps }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { config } = useConfigContext();

  const { uploadVideo, loading: uploadVideoLoading } = useUploadVideo();
  const { updateCollab, loading: updateCollabLoading } = useUpdateCollabWithMedia();

  const loading = !collabId || !existingMedia || uploadVideoLoading || updateCollabLoading;
  const maximumVideoDurationInSeconds = config?.maximumVideoDurationForCampaignInSeconds ?? 300;

  const handleUploadVideoCompleted = useCallback(
    (uploadVideoId: string) => {
      if (!collabId || !existingMedia) return;

      const existingImages = existingMedia.filter(isImage);
      const existingImageIds = existingImages.map(image => image.collabImageId);

      void updateCollab({
        variables: { collabId, input: { mediaIds: [...existingImageIds, uploadVideoId] } },
        onCompleted,
        onError: error => showErrorSnackbar({ error }),
      });
    },
    [collabId, existingMedia, onCompleted, showErrorSnackbar, updateCollab],
  );

  return (
    <View style={styles.root}>
      <Button
        size="large"
        mode="contained"
        icon={UploadSimple}
        {...buttonProps}
        loading={loading || buttonProps.loading}
        onPress={() => void uploadVideo({ maximumVideoDurationInSeconds, onCompleted: handleUploadVideoCompleted })}>
        {t("startCampaign.uploadVideo.label")}
      </Button>
      <Text variant="caption" color="tertiary" textAlign="center">
        {t("startCampaign.uploadVideo.advantage")}
      </Text>
    </View>
  );
};
