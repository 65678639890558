import { useTranslation } from "react-i18next";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { useNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";

import { useDeleteCollabsForUser } from "../api/god-mode/use-delete-collabs-for-user.hook";
import { useDeleteProfile } from "../api/god-mode/use-delete-profile.hook";

export function useDeleteUser(): { deleteUser: (userId?: string) => void } {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  const { deleteProfile } = useDeleteProfile();
  const { deleteCollabsForUser } = useDeleteCollabsForUser();

  const deleteUser = (userId?: string): void => {
    if (!userId) return;

    Alert.alert(t("error.warning"), t("godMode.deleteUser.confirmation"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        style: "destructive",
        text: t("cta.yes"),
        onPress: () => {
          void deleteProfile({
            variables: { userId },
            onCompleted: () => {
              showSuccessSnackbar({ message: t("godMode.deleteUser.success") });
              navigation.popToTop();
            },
            onError: error => {
              const userHasLinkedCollabs = isErrorCode(error, "USER_HAS_LINKED_COLLABS");
              if (userHasLinkedCollabs) {
                Alert.alert(t("error.warning"), t("godMode.deleteCollabsForUser.confirmation"), [
                  { text: t("cta.cancel"), style: "cancel" },
                  {
                    style: "destructive",
                    text: t("cta.yes"),
                    onPress: () => {
                      void deleteCollabsForUser({
                        variables: { userId },
                        onCompleted: data => {
                          void deleteProfile({
                            variables: { userId },
                            onCompleted: () => {
                              showSuccessSnackbar({
                                message: t("godMode.deleteCollabsForUser.success", { deletedCollabs: data.deleteCollabsForUser.deletedCollabs }),
                              });
                              navigation.popToTop();
                            },
                            onError: deleteProfileError => showErrorSnackbar({ error: deleteProfileError }),
                          });
                        },
                        onError: deleteCollabsForUserError => showErrorSnackbar({ error: deleteCollabsForUserError }),
                      });
                    },
                  },
                ]);
              } else {
                showErrorSnackbar({ error });
              }
            },
          });
        },
      },
    ]);
  };

  return { deleteUser };
}
