import React, { FC } from "react";

import { TextStyle } from "react-native";
import { TextProps as RNTextProps, Theme, customText, useTheme } from "react-native-paper";

import { CustomVariants } from "@app/common/style/fonts";
import { ColorKey, getColor } from "@app/utils/color-theme.util";

type TextStyleProps = Pick<
  TextStyle,
  "fontFamily" | "fontSize" | "fontStyle" | "fontWeight" | "letterSpacing" | "lineHeight" | "textAlign" | "textTransform" | "textDecorationLine"
>;

export interface TextProps extends Omit<RNTextProps<CustomVariants>, "theme">, TextStyleProps {
  color?: ColorKey;
  theme?: Theme;
}

const CustomText = customText<CustomVariants>();

export const Text: FC<TextProps> = ({ color, style, theme: overriddenTheme, children, ...props }) => {
  const { colors: currentColors } = useTheme();
  const colors = overriddenTheme?.colors ?? currentColors;

  const { fontFamily, fontSize, fontStyle, fontWeight, letterSpacing, lineHeight, textAlign, textTransform, textDecorationLine, ...rest } = props;
  const textStyleProps = { fontFamily, fontSize, fontStyle, fontWeight, letterSpacing, lineHeight, textAlign, textTransform, textDecorationLine };

  Object.keys(textStyleProps).forEach((key: string) => {
    if (textStyleProps[key as keyof TextStyleProps] === undefined) {
      delete textStyleProps[key as keyof TextStyleProps];
    }
  });

  const textColor = getColor(colors, color);

  return (
    <CustomText style={[textColor ? { color: textColor } : {}, textStyleProps, style]} theme={overriddenTheme} {...rest}>
      {children}
    </CustomText>
  );
};
