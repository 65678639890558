import { StyleSheet } from "react-native";

const listRowGap = 8;

export const estimatedItemHeight = 115;

export const styles = StyleSheet.create({
  header: {
    marginBottom: listRowGap,
  },
  headerSupercoinsBalance: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  listContentContainer: {
    paddingHorizontal: 1,
  },
  rowGap: {
    height: listRowGap,
  },
  supercoinImage: {
    height: 32,
    width: 32,
  },
  supercoinsBalanceContainer: {
    rowGap: 4,
  },
  supercoinsBalanceCount: {
    alignItems: "center",
    borderRadius: 16,
    columnGap: 4,
    flexDirection: "row",
  },
});
