import React, { FC } from "react";

import { TrashSimple, X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton, IconButtonProps as RNIconButtonProps } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { Button, ButtonProps as RNButtonProps } from "@app/components/common/button/button.component";
import { useCartContext } from "@app/context/cart/cart.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteCartItem } from "@app/hooks/api/use-delete-cart-item.hook";

interface TextButtonProps extends Omit<RNButtonProps, "theme" | "hitSlop" | "delayLongPress"> {
  type?: "text";
}

interface IconButtonProps extends Omit<RNIconButtonProps, "mode" | "size" | "theme" | "style"> {
  type?: "icon";
  icon: IconSource;
}

type ButtonProps = TextButtonProps | IconButtonProps;

interface Props extends Omit<ButtonProps, "children"> {
  cartItemId: string;
  onPressStart?: () => void;
}

export const DeleteCartItemButton: FC<Props> = ({ cartItemId, type = "text", icon, onPressStart, ...props }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { deleteCartItem, loading: loadingDelete } = useDeleteCartItem();
  const { cartItems, cartIdentity, refetch } = useCartContext();
  const { setLocalStorage } = useLocalStorageContext();

  const handleRemoveFromCart = (): void => {
    if (!cartIdentity) return;

    onPressStart?.();
    void deleteCartItem({
      variables: { cartItemId },
      onCompleted: () => {
        if ((cartItems ?? [])?.length <= 1) {
          setLocalStorage(LocalStorageKeys.anonymousCartId, undefined);
          void refetch?.();
        }
      },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return type === "text" ? (
    <Button mode="outlined" icon={X} fullWidth onPress={handleRemoveFromCart} loading={loadingDelete} {...props}>
      {t("cta.remove")}
    </Button>
  ) : (
    <IconButton
      icon={icon ?? (iconProps => <TrashSimple weight="fill" {...iconProps} />)}
      onPress={!loadingDelete ? handleRemoveFromCart : undefined}
      {...props}
    />
  );
};
