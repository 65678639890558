import { StyleSheet } from "react-native";

const margin = 30;

export const styles = StyleSheet.create({
  label: {
    padding: 8,
    textTransform: "uppercase",
  },
  labelWrapper: {
    borderWidth: 4,
  },
  leftLabelWrapper: {
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginRight: margin,
    marginTop: margin,
  },
  rightLabelWrapper: {
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: margin,
    marginTop: margin,
  },
  textShadow: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
  topLabelWrapper: {
    alignSelf: "center",
    position: "absolute",
    top: margin,
  },
});
