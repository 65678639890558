import { StyleSheet } from "react-native";

import { productStackMargin } from "@app/common/constants/products.const";

export const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: "auto",
    paddingVertical: 12,
  },
  cardContent: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    paddingTop: 72,
    rowGap: 8,
  },
  gradient: {
    borderRadius: 8,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  root: {
    flex: 1,
    margin: productStackMargin,
    paddingHorizontal: 20,
  },
});
