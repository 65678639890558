import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  feedRoot: {
    flex: 1,
    justifyContent: "center",
  },
  notifButtonBadge: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  supercoinImage: {
    height: 24,
    width: 24,
  },
  supercoinsButton: {
    borderRadius: 16,
    columnGap: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});
