import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CaretDown } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, Pressable, View } from "react-native";
import { useTheme, TextInput as RNTextInput, RadioButton } from "react-native-paper";

import { ProductOption, ProductVariant } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { getTitleCase } from "@app/utils/convert-to-title-case.util";
import { isWeb } from "@app/utils/device.util";

import { QuantitySelector } from "../quantity-selector/quantity-selector.component";

import { styles } from "./variant-picker.style";

export type VariantType = keyof Pick<ProductVariant, "color" | "size" | "material" | "style">;

interface Option {
  name: string;
  soldOut?: boolean;
}

interface Props {
  variants?: ProductVariant[];
  options?: ProductOption[];
  quantity: number;
  variant?: ProductVariant;
  setQuantity: Dispatch<SetStateAction<number>>;
  setVariant: (variant: ProductVariant) => void;
  setBottomSheetOpen?: (open: boolean) => void;
  sizeGuideUrl?: string;
}

export const VariantPicker: FC<Props> = ({ variants, options, quantity, variant, setQuantity, setVariant, setBottomSheetOpen, sizeGuideUrl }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { navigateToWebview } = useNavigateToWebview();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const maxQuantity = variant?.inventoryQuantity;
  const [currentVariantType, setCurrentVariantType] = useState<VariantType | undefined>(undefined);
  const [optionsSelected, setOptionsSelected] = useState<{ [key in VariantType]: string | null }>({
    color: variant?.color ?? null,
    size: variant?.size ?? null,
    material: variant?.material ?? null,
    style: variant?.style ?? null,
  });

  useEffect(() => {
    if (!variants?.length) return;

    const noVariants = !options?.length;
    if (noVariants) {
      setVariant(variants[0]);
    }
  }, [options, setVariant, variants]);

  const handleSelectVariant = useCallback(
    (option: string) => {
      const newOptionsSelected = { ...optionsSelected, [currentVariantType as string]: option };
      setOptionsSelected(newOptionsSelected);

      const allVariantsSelected =
        Object.entries(newOptionsSelected).findIndex(([type, value]) => options?.findIndex(o => o.name === type) !== -1 && !value) === -1;
      if (allVariantsSelected) {
        const variantSelected = variants?.find(
          v =>
            v.color === newOptionsSelected.color &&
            v.size === newOptionsSelected.size &&
            v.material === newOptionsSelected.material &&
            v.style === newOptionsSelected.style,
        );
        if (variantSelected) {
          setVariant(variantSelected);
          if (quantity > variantSelected.inventoryQuantity) {
            setQuantity(Math.max(1, variantSelected.inventoryQuantity));
          }
        }
      }

      setCurrentVariantType(undefined);
      setBottomSheetOpen?.(false);
      sheetRef?.current?.close();
    },
    [currentVariantType, options, optionsSelected, quantity, setBottomSheetOpen, setQuantity, setVariant, variants],
  );

  const handleSelectVariantPicker = useCallback(
    (variantType: VariantType) => {
      setCurrentVariantType(variantType);
      setBottomSheetOpen?.(true);
      sheetRef?.current?.open();
    },
    [setBottomSheetOpen],
  );

  const variantOptionsList = useMemo((): Option[] => {
    const optionsPerVariant: Option[] = [];
    const values = options?.find(option => option.name === currentVariantType)?.values;
    values?.forEach(option => {
      const selections = { ...optionsSelected, [currentVariantType as string]: option };
      const variantSelected = variants?.find(
        v => v.color === selections.color && v.size === selections.size && v.material === selections.material && v.style === selections.style,
      );
      const soldOut = variantSelected && variantSelected.inventoryQuantity <= 0;

      optionsPerVariant.push({ name: option, soldOut });
    });

    return optionsPerVariant;
  }, [currentVariantType, options, optionsSelected, variants]);

  const renderVariantOption = useCallback(
    ({ item }: { item: Option }) => (
      <Pressable onPress={() => handleSelectVariant(item.name)} style={[styles.row, styles.radioButtonContainer]}>
        <RadioButton.Android
          value={item.name}
          status={currentVariantType && optionsSelected[currentVariantType] === item.name ? "checked" : "unchecked"}
        />
        <Text
          variant="body1"
          textTransform={currentVariantType !== "size" ? "capitalize" : undefined}
          color={item.soldOut ? "action.disabled" : undefined}>
          {`${item.name}${item.soldOut ? ` -  ${t("cart.unavailable")}` : ""}`}
        </Text>
      </Pressable>
    ),
    [currentVariantType, handleSelectVariant, optionsSelected, t],
  );

  const renderVariantPicker = useCallback(
    ({ item }: { item: ProductOption }) =>
      item.values.length ? (
        <View style={styles.variantPicker}>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text variant="h7" textTransform="capitalize">
              {item.name}
            </Text>
            {item.name === "size" && sizeGuideUrl && (
              <Text variant="body2" color="tertiary" onPress={() => navigateToWebview(sizeGuideUrl, t("productDetails.sizeGuide"))}>
                {t("productDetails.sizeGuide")}
              </Text>
            )}
          </View>
          <TextInput
            value={
              optionsSelected[item.name as VariantType]
                ? item.name !== "size"
                  ? getTitleCase(optionsSelected[item.name as VariantType] as string)
                  : (optionsSelected[item.name as VariantType] as string)
                : `${t("productDetails.pickA")} ${item.name}`
            }
            textColor={!optionsSelected[item.name as VariantType] ? colors.tertiary : colors.primary}
            right={
              <RNTextInput.Icon icon={CaretDown} color={colors.action.active} onPress={() => handleSelectVariantPicker(item.name as VariantType)} />
            }
            editable={false}
            onPressIn={() => handleSelectVariantPicker(item.name as VariantType)}
            underlineStyle={styles.underlineVariantPickerInput}
            style={[styles.variantPickerInput, { backgroundColor: setBottomSheetOpen ? colors.tertiaryContainer : colors.background }]}
          />
        </View>
      ) : null,
    [colors, handleSelectVariantPicker, navigateToWebview, optionsSelected, setBottomSheetOpen, sizeGuideUrl, t],
  );

  return (
    <>
      <View style={styles.root}>
        <FlatList data={options} renderItem={renderVariantPicker} keyExtractor={item => `variant-type-picker-${item.name}`} scrollEnabled={isWeb} />
        <QuantitySelector quantity={quantity} maxQuantity={maxQuantity} setQuantity={setQuantity} />
      </View>

      <BottomSheet
        ref={sheetRef}
        title={`${t("productDetails.pickA")} ${currentVariantType as string}`}
        scrollable
        onDismiss={() => setBottomSheetOpen?.(false)}>
        <RadioButton.Group onValueChange={newValue => handleSelectVariant(newValue)} value={optionsSelected[currentVariantType as VariantType] ?? ""}>
          <FlatList data={variantOptionsList} renderItem={renderVariantOption} scrollEnabled={false} />
        </RadioButton.Group>
      </BottomSheet>
    </>
  );
};
