import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  closeIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 3,
  },
  iconButton: {
    margin: 0,
  },
  image: {
    left: 0,
    position: "absolute",
    top: 0,
  },
  leftIcon: {
    left: 20,
    position: "absolute",
    top: 20,
    zIndex: 3,
  },
});
