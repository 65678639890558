import React, { FC } from "react";

import { ArrowUDownLeft, Info, IconProps } from "phosphor-react-native";
import { StyleProp, View, ViewStyle } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import { SharedValue, useDerivedValue } from "react-native-reanimated";

import { darkTheme } from "@app/common/style/theme";
import { Text } from "@app/components/common/text/text.component";

import { styles } from "./product-card-action-buttons.style";
import { ProductCardMainButton } from "./product-card-main-button/product-card-main-button.component";

export interface SwipeButtonProps {
  onSwipe: () => void;
  content: FC<IconProps> | string;
  style?: StyleProp<ViewStyle>;
}

interface Props {
  backDisabled?: boolean;
  cardPositionX?: SharedValue<number>;
  swipeRightProps?: SwipeButtonProps;
  swipeLeftProps?: SwipeButtonProps;
  onBack?: () => void;
  onDetails?: () => void;
}

export const ProductCardActionButtons: FC<Props> = ({ backDisabled, cardPositionX, swipeRightProps, swipeLeftProps, onBack, onDetails }) => {
  const { colors, roundness } = useTheme();

  const disabledProps = { style: { opacity: 0 }, disabled: true };
  const secondaryIconSize = 32;
  const mainIconSize = 40;
  const commonButtonStyle = { theme: darkTheme, style: [styles.buttons, { borderRadius: roundness }] };

  const isSwipingLeft = useDerivedValue(() => (cardPositionX?.value ?? 0) < 0);
  const isSwipingRight = useDerivedValue(() => (cardPositionX?.value ?? 0) > 0);

  const SwipeLeftIcon = swipeLeftProps?.content && typeof swipeLeftProps?.content !== "string" ? swipeLeftProps?.content : undefined;
  const swipeLeftLabel = swipeLeftProps?.content && typeof swipeLeftProps?.content === "string" ? swipeLeftProps?.content : undefined;

  const SwipeRightAnimatedIcon = swipeRightProps?.content && typeof swipeRightProps?.content !== "string" ? swipeRightProps?.content : undefined;
  const swipeRightAnimatedText = swipeRightProps?.content && typeof swipeRightProps.content === "string" ? swipeRightProps.content : undefined;

  return (
    <View style={styles.row}>
      <IconButton
        testID="feed-back-button"
        mode="contained"
        icon={() => <ArrowUDownLeft color={backDisabled ? colors.common.gray : colors.common.white} weight="thin" />}
        size={secondaryIconSize}
        disabled={backDisabled}
        onPress={onBack}
        {...(onBack ? commonButtonStyle : { ...disabledProps, style: [commonButtonStyle.style, disabledProps.style] })}
      />
      <ProductCardMainButton
        testID="feed-dislike-button"
        isActive={isSwipingLeft}
        bgColorRange={[colors.common.gray, darkTheme.colors.error]}
        icon={() =>
          SwipeLeftIcon ? (
            <SwipeLeftIcon color={colors.common.white} weight="thin" size={24} />
          ) : (
            <Text variant="body1" color="common.white" textTransform="uppercase">
              {swipeLeftLabel}
            </Text>
          )
        }
        size={mainIconSize}
        onPress={swipeLeftProps?.onSwipe}
        {...(swipeLeftProps?.onSwipe
          ? { ...commonButtonStyle, style: [commonButtonStyle.style, swipeLeftProps?.style] }
          : { ...disabledProps, style: [commonButtonStyle.style, disabledProps.style, swipeLeftProps?.style] })}
      />
      <ProductCardMainButton
        testID="feed-like-button"
        isActive={isSwipingRight}
        bgColorRange={[colors.common.white, darkTheme.colors.secondary]}
        contentColorRange={[colors.common.black, darkTheme.colors.onSecondary]}
        animatedIcon={SwipeRightAnimatedIcon}
        animatedText={swipeRightAnimatedText}
        size={mainIconSize}
        onPress={swipeRightProps?.onSwipe}
        {...(swipeRightProps?.onSwipe
          ? { ...commonButtonStyle, style: [commonButtonStyle.style, swipeRightProps?.style] }
          : { ...disabledProps, style: [commonButtonStyle.style, disabledProps.style, swipeRightProps?.style] })}
      />
      <IconButton
        testID="feed-info-button"
        mode="contained"
        icon={() => <Info color={colors.common.white} weight="thin" />}
        size={secondaryIconSize}
        onPress={onDetails}
        {...(onDetails ? commonButtonStyle : { ...disabledProps, style: [commonButtonStyle.style, disabledProps.style] })}
      />
    </View>
  );
};
