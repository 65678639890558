import { StyleSheet } from "react-native";

import { isWeb } from "@app/utils/device.util";

export const bottomSheetRowHeight = 40;

export const styles = StyleSheet.create({
  bottomSheetRow: {
    height: bottomSheetRowHeight,
  },
  editImageContainer: {
    padding: 16,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 24,
    rowGap: 16,
  },
  tableRow: {
    paddingTop: isWeb ? 20 : 0,
    rowGap: 8,
  },
});
