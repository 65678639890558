import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    alignItems: "stretch",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
});

export default styles;
