import React, { FC, useMemo, useState } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { PopperContext } from "./popper.context";
import { PopperContextInterface } from "./popper.type";

export const PopperContextProvider: FC<ChildrenProp> = ({ children }) => {
  const goalsPopperState = useState(false);
  const studioPopperState = useState(false);

  const contextValue = useMemo<PopperContextInterface>(
    () => ({
      goalsPopperState,
      studioPopperState,
    }),
    [goalsPopperState, studioPopperState],
  );

  return <PopperContext.Provider value={contextValue}>{children}</PopperContext.Provider>;
};
