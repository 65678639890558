import { useCallback, useState } from "react";

import { LayoutChangeEvent } from "react-native";

import { useWindowDimensions } from "./use-window-dimensions.hook";

interface Size {
  width: number;
  height: number;
}

interface ComponentSizeProps {
  defaultSize?: Partial<Size>;
}

export function useComponentSize(props?: ComponentSizeProps): [Size, (event: LayoutChangeEvent) => void] {
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const [size, setSize] = useState<Size>({ width: props?.defaultSize?.width ?? screenWidth, height: props?.defaultSize?.height ?? screenHeight });

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { width, height } = event.nativeEvent.layout;
      const newHeightIsNotDefaultHeight = props?.defaultSize?.height && height !== props.defaultSize.height;
      const newWidthIsNotDefaultWidth = props?.defaultSize?.width && width !== props.defaultSize.width;
      if ((newHeightIsNotDefaultHeight || newWidthIsNotDefaultWidth || !props?.defaultSize) && height > 0 && width > 0) {
        setSize({ width, height });
      }
    },
    [props?.defaultSize],
  );

  return [size, onLayout];
}
