import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  innerContainer: {
    columnGap: 12,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  snackbar: {
    marginBottom: 72,
    paddingRight: 8,
    paddingVertical: 0,
  },
  snackbarWrapper: {
    alignItems: "center",
    // So the snackbar is above bottom sheet
    zIndex: 9999999,
  },
});
