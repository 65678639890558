import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
  },
  root: {
    // Needed to remove warning when empty state
    // ref https://shopify.github.io/flash-list/docs/known-issues/#1-flashlists-rendered-size-is-not-usable-warning
    minHeight: 2,
  },
  skeletonsWrapper: {
    marginBottom: 8,
    rowGap: 4,
  },
});
