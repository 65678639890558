import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    marginBottom: 16,
  },
  headerMarginTop: {
    marginTop: 36,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
});
