import React, { FC, useCallback, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { WarningOctagon } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Button } from "@app/components/common/button/button.component";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCancelPreorder } from "@app/hooks/api/preorder/use-cancel-preorder.hook";
import { supercoinBalanceQuery } from "@app/hooks/api/supercoins/use-supercoin-balance.hook";

interface Props {
  orderId: string;
  productName: string;
  price: string;
  isCampaignSuccessful: boolean;
}

export const CancelPreorderButton: FC<Props> = ({ orderId, productName, price, isCampaignSuccessful }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const client = useApolloClient();

  const [reason, setReason] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);

  const { cancelPreorder, loading } = useCancelPreorder();

  const handleDismiss = (): void => {
    setDialogVisible(false);
    setReason("");
  };

  const handleCancel = useCallback(() => {
    const formattedReason = reason.trim();
    void cancelPreorder({
      variables: { orderId, input: formattedReason ? { reason: formattedReason } : undefined },
      onCompleted: () => {
        handleDismiss();
        void client.refetchQueries({ include: [supercoinBalanceQuery] });
      },
      onError: error => {
        handleDismiss();
        showErrorSnackbar({ error });
      },
    });
  }, [cancelPreorder, client, orderId, reason, showErrorSnackbar]);

  return (
    <>
      <Button color="error" onPress={() => setDialogVisible(true)} loading={loading}>
        {t("preorder.cancelPreorder.label")}
      </Button>

      <ConfirmationDialog
        visible={dialogVisible}
        title={t("preorder.cancelPreorder.title")}
        message={textProps => (
          <Trans
            i18nKey="preorder.cancelPreorder.message"
            values={{ price, productName }}
            parent={({ children }: ChildrenProp) => <Text {...textProps}>{children}</Text>}
            components={{
              b: (
                <Text {...textProps} variant="h8">
                  <></>
                </Text>
              ),
            }}
          />
        )}
        additionalContent={
          <>
            <Text variant="body1" color="tertiary" textAlign="center">
              {t("supercoins.cancelPurchaseDisclaimer")}
            </Text>
            <TextInput
              label={t("confirmation.report.inputHelper")}
              defaultValue={reason}
              onChangeText={setReason}
              multiline
              numberOfLines={4}
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="done"
              blurOnSubmit
            />
            {isCampaignSuccessful && (
              <EmbeddedAlert
                variant={AlertVariant.error}
                icon={props => <WarningOctagon color={colors.error} {...props} />}
                text={t("preorder.cancelPreorder.disclaimer")}
              />
            )}
          </>
        }
        confirmProps={{
          label: t("preorder.cancelPreorder.confirmCta"),
          loading,
          onPress: handleCancel,
        }}
        cancelProps={{ label: t("preorder.cancelPreorder.cancelCta") }}
        onDismiss={handleDismiss}
      />
    </>
  );
};
