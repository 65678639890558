export function isCollabId(id: string): boolean {
  return /^bl_coll_.+$/.test(id);
}

export function isUserId(id: string): boolean {
  return /^bl_us_.+$/.test(id);
}

export function isContestId(id: string): boolean {
  return /^bl_contest_.+$/.test(id);
}

export function isCollabUpdateId(id: string): boolean {
  return /^bl_collup_.+$/.test(id);
}

export function isOrderId(id: string): boolean {
  return /^bl_or_.+$/.test(id);
}
