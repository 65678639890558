import React, { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { HelperText, Switch, useTheme } from "react-native-paper";

import { maxContestSubmissionsAllowed } from "@app/common/constants/contests.const";
import { links } from "@app/common/constants/links.const";
import { Category } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { Checkbox } from "@app/components/common/checkbox/checkbox.component";
import { ImagesPicker } from "@app/components/common/images-picker/images-picker.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useContestSubmissionCount } from "@app/hooks/api/contests/use-contest-submission-count.hook";
import { CollabFieldValidation } from "@app/hooks/utils/use-create-collab-input-validation.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

import { styles } from "./create-collab-form.style";

interface Props {
  contestCategory: Category | undefined;
  isContest: boolean;
  isEditing: boolean;
  setIsContest: React.Dispatch<React.SetStateAction<boolean>>;
  setTermsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  termsChecked: boolean;
}

export const CreateCollabForm: FC<Props> = ({ contestCategory, isContest, isEditing, setIsContest, setTermsChecked, termsChecked }) => {
  const { colors } = useTheme();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();

  const { profile } = useProfileContext();
  const {
    inputs: { contest, productName, productCategory, productDescription, media },
    setInputMedia,
    setInputs,
  } = useStudioMiniContext();

  const userId = profile?.userId;

  const { data: contestSubmissionCount } = useContestSubmissionCount({
    variables: { contestId: contest?.id ?? "", userId: userId ?? "" },
    skip: !contest?.id || !userId || isEditing,
  });

  const handleEditField = (fieldName: keyof CollabFieldValidation) => () => {
    navigation.navigate(Routes.studioMiniEditField, { fieldName });
  };

  const handleOnSwitchToContext = (value?: boolean): void => {
    const newValue = value ?? !isContest;
    setIsContest(newValue);

    setInputs(prev => ({ ...prev, contest: !newValue ? null : undefined }));
  };

  return (
    <>
      <ImagesPicker
        style={styles.rowGap}
        media={media}
        setMedia={setInputMedia}
        onPressAddImage={() => navigation.navigate(Routes.studioMiniSelectImageSource)}
      />
      <View style={styles.rowGap}>
        <View style={styles.centeredRow}>
          <Switch color={colors.secondary} value={isContest} onValueChange={handleOnSwitchToContext} disabled={isEditing} />
          <Button mode="text" onPress={() => handleOnSwitchToContext()} rippleColor="transparent" disabled={isEditing}>
            {t("studioMini.fields.contest.toggle")}
          </Button>
        </View>
        {isContest && (
          <View>
            <TextInput label={t("studioMini.fields.contest.label")} onPressIn={handleEditField("contest")} editable={false} value={contest?.name} />
            {contestSubmissionCount?.count ? (
              <HelperText type={contestSubmissionCount.count === maxContestSubmissionsAllowed ? "error" : "info"}>
                {t("studioMini.fields.contest.participation", { submission: `${contestSubmissionCount.count}/${maxContestSubmissionsAllowed}` })}
              </HelperText>
            ) : null}
          </View>
        )}
        <TextInput label={t("studioMini.fields.productName.label")} onPressIn={handleEditField("productName")} editable={false} value={productName} />
        <TextInput
          label={t("studioMini.fields.productCategory.label")}
          onPressIn={!contestCategory ? handleEditField("productCategory") : undefined}
          editable={false}
          disabled={!!contestCategory}
          value={contestCategory?.name ?? productCategory?.name ?? ""}
        />
        <TextInput
          label={t("studioMini.fields.productDescription.label")}
          multiline
          onPressIn={handleEditField("productDescription")}
          editable={false}
          value={productDescription}
        />
        <ListItem
          left={<Checkbox status={termsChecked ? "checked" : "unchecked"} onPress={() => setTermsChecked(!termsChecked)} />}
          title={
            <Trans
              i18nKey="studioMini.fields.terms.label"
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                glink: (
                  <Text
                    variant="body1"
                    textDecorationLine="underline"
                    onPress={() => navigateToWebview(links.external.termsOfUse, t("settings.about.termsOfUse"))}>
                    <></>
                  </Text>
                ),
              }}
            />
          }
          onPress={() => setTermsChecked(!termsChecked)}
          titleProps={{ numberOfLines: 0 }}
        />
      </View>
    </>
  );
};
