import React, { FC, useCallback, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import { IconButton, IconButtonProps } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import ShareIcon from "@app/assets/icons/share-thin.svg";
import { CollabImage, TargetSocialMedia } from "@app/common/graphql/generated/schema.graphql";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useShareLink } from "@app/hooks/api/use-share-link.hook";
import { useTrackSharing } from "@app/hooks/api/use-track-sharing.hook";
import { getShareUrl } from "@app/utils/share/share.common.util";

import { BottomSheetRefProps } from "../bottom-sheet/bottom-sheet.types";
import { ShareBottomSheet } from "../share-bottom-sheet/share-bottom-sheet.component";

interface CommonProps extends Omit<IconButtonProps, "icon"> {
  title?: string;
  entityId: string;
  prettyId?: string;
  image?: CollabImage;
  icon?: IconSource;
  buttonComponent?: FC<{ onPress: () => void }>;
}

interface ShareCollabUpdateProps extends CommonProps {
  entityType: "collabUpdate";
  index?: number;
}

interface ShareProps extends CommonProps {
  entityType?: "profile" | "contestEntries" | "contest" | "collab";
  index?: never;
}

type Props = ShareProps | ShareCollabUpdateProps;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ShareButton: FC<Props> = ({ title, entityId, prettyId, entityType, index, image, buttonComponent: Button, icon, ...props }) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const handleOnShare = useCallback((): void => {
    sheetRef.current?.open();
  }, []);

  const message = useMemo(() => t("share.message"), [t]);
  const url = useMemo(
    (): string => getShareUrl(entityId, prettyId, entityType, profile?.shareId, index),
    [entityId, entityType, index, profile?.shareId, prettyId],
  );

  const buildShareLinkVariables = useCallback((targetSocialMedia: TargetSocialMedia) => ({ input: { targetSocialMedia, entityId } }), [entityId]);

  const buildTrackSharingVariables = useCallback((destination: string) => ({ input: { destination, entityId } }), [entityId]);

  return (
    <>
      {Button ? (
        <Button onPress={handleOnShare} />
      ) : (
        <IconButton icon={icon ?? (({ color }) => <ShareIcon color={color} width={24} height={24} />)} onPress={handleOnShare} {...props} />
      )}

      <ShareBottomSheet
        sheetRef={sheetRef}
        url={url}
        message={message}
        useSocialShareLink={useShareLink}
        useTrackSharing={useTrackSharing}
        buildShareLinkVariables={buildShareLinkVariables}
        buildTrackSharingVariables={buildTrackSharingVariables}
        title={title}
        image={image}
      />
    </>
  );
};
