import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bulletPointContainer: {
    alignItems: "flex-start",
  },
  bulletPointsContainer: {
    rowGap: 4,
  },
  disclaimerContentContainer: {
    padding: 16,
    rowGap: 16,
  },
});
