import { useWindowDimensions } from "./use-window-dimensions.hook";

interface Variables {
  nbColumns?: number;
  itemGap?: number;
  listPadding?: number;
}

interface Response {
  itemWidth: number;
  nbColumns: number;
  itemGap: number;
  listPadding: number;
}

export const useResponsiveWidthListItem = ({ nbColumns = 2, itemGap = 1, listPadding = 1 }: Variables = {}): Response => {
  const { width: screenWidth } = useWindowDimensions();

  const screenWidthWithoutPadding = screenWidth - 2 * listPadding - itemGap * (nbColumns - 1);
  const itemWidth = screenWidthWithoutPadding / nbColumns;

  return { itemWidth, nbColumns, itemGap, listPadding };
};
