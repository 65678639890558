type State = [boolean, React.Dispatch<React.SetStateAction<boolean>>];
const defaultState: State = [false, () => undefined];

export interface PopperContextInterface {
  goalsPopperState: State;
  studioPopperState: State;
}

export const initialContextValue: PopperContextInterface = {
  goalsPopperState: defaultState,
  studioPopperState: defaultState,
};
