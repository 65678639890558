import React, { FC, useCallback, useMemo, useState } from "react";

import uniq from "lodash.uniq";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { ImageToBeUsed } from "@app/common/types/image.type";

import { ProductUpdateContext, ProductUpdateContextInterface, defaultInputsValue } from "./product-update.context";

export const ProductUpdateProvider: FC<ChildrenProp> = ({ children }) => {
  const [inputs, setInputs] = useState(defaultInputsValue);

  const setImages = useCallback((callback: ((prevImages: ImageToBeUsed[]) => ImageToBeUsed[]) | ImageToBeUsed[]): void => {
    if (typeof callback === "function") {
      setInputs(prev => ({ ...prev, images: uniq(callback(prev.images)) }));
    } else {
      setInputs(prev => ({ ...prev, images: callback }));
    }
  }, []);

  const resetInputs = useCallback(() => setInputs(defaultInputsValue), []);

  const context = useMemo<ProductUpdateContextInterface>(() => ({ inputs, setInputs, setImages, resetInputs }), [inputs, resetInputs, setImages]);

  return <ProductUpdateContext.Provider value={context}>{children}</ProductUpdateContext.Provider>;
};
