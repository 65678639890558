import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import {
  CollabSortingMethod,
  CollabsForUserFilteringArgs,
  PaginatedCollabs,
  PaginatedCollabsWithFacets,
} from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface CollabsResponse {
  collabs: PaginatedCollabs | PaginatedCollabsWithFacets;
}

export interface CollabsVariables {
  first?: number;
  offset?: number;
  sortBy?: CollabSortingMethod;
  where: CollabsForUserFilteringArgs;
}

const collabsQuery = gql`
  query CollabsForUser($first: Int, $offset: Int, $sortBy: CollabSortingMethod, $where: CollabsForUserFilteringArgs!) {
    collabs: collabsForUser(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
`;

export function useCollabs(options: QueryHookOptions<CollabsResponse, CollabsVariables>): QueryResult<CollabsResponse, "collabs"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<CollabsResponse, CollabsVariables>(collabsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !options.variables?.where.creatorId,
    ...options,
  });

  return { data: data?.collabs, loading, error, networkStatus, fetchMore, refetch };
}
