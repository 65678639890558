import { Colors } from "react-native-paper";

import { ColorVariant } from "@app/common/style/theme";

export type ColorKey =
  | keyof Omit<Colors, "elevation" | "common" | "action">
  | "success"
  | "onSuccess"
  | "warning"
  | "onWarning"
  | "common.white"
  | "common.black"
  | "common.gray"
  | "action.active"
  | "action.selected"
  | "action.disabled";

export function getColor(colors: Colors, colorKey?: ColorKey): string | undefined {
  switch (colorKey) {
    case "common.white":
      return colors.common.white;
    case "common.black":
      return colors.common.black;
    case "common.gray":
      return colors.common.gray;
    case "action.active":
      return colors.action.active;
    case "action.disabled":
      return colors.action.disabled;
    case "action.selected":
      return colors.action.selected;
    default:
      return colorKey ? colors[colorKey] : undefined;
  }
}

export function getOnColor(colors: Colors, color?: ColorVariant): string | undefined {
  return color ? (colors[`on${color.charAt(0).toUpperCase() + color.slice(1)}` as keyof Colors] as string) : undefined;
}
