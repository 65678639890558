import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  handleContainer: {
    alignSelf: "center",
    position: "absolute",
  },
  noPaddingContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  topRadius: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});
