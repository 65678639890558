import React, { FC, useState } from "react";

import { ArrowClockwise, Bug, PaperPlaneTilt } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { FallbackComponentProps } from "react-native-error-boundary";
import { useTheme } from "react-native-paper";

import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { sendFeedbackToSentry } from "@app/utils/log-sentry.util";

import { Button } from "../button/button.component";
import { ScreenWrapper } from "../screen-wrapper/screen-wrapper.component";
import { Text } from "../text/text.component";
import { TextInput } from "../text-input/text-input.component";

import { styles } from "./error-boundary.style";

export interface ErrorFallbackProps extends FallbackComponentProps {
  sentryId: string;
}

export const ErrorFallback: FC<ErrorFallbackProps> = ({ resetError, sentryId }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { state } = useAuthContext();
  const [comments, setComments] = useState("");

  const handleSendReport = (): void => {
    sendFeedbackToSentry(sentryId, { comments, name: profile?.username ?? "Anonymous", email: state.email ?? "johndoe@ligthouse.world" });
    resetError();
  };

  return (
    <ScreenWrapper
      withScrollView
      withKeyboardAvoidingView
      contentContainerStyle={styles.root}
      staticContent={
        <View style={styles.bottomContainer}>
          <Button icon={PaperPlaneTilt} mode="contained" size="large" fullWidth onPress={handleSendReport} disabled={!comments.trim()}>
            {t("errorBoundary.sendAndReload")}
          </Button>

          <Button icon={ArrowClockwise} mode="contained" size="large" fullWidth onPress={resetError}>
            {t("errorBoundary.reload")}
          </Button>
        </View>
      }>
      <View style={styles.headerContainer}>
        <Bug size={48} weight="fill" color={colors.onBackground} />
        <Text variant="title" textAlign="center">
          {t("errorBoundary.foundABug")}
        </Text>
        <View style={styles.contentContainer}>
          <Text variant="h6" textAlign="center">
            {t("errorBoundary.sorry")}
          </Text>
          <Text variant="body2" textAlign="center">
            {t("errorBoundary.helpUs")}
          </Text>
        </View>
        <TextInput multiline onChangeText={setComments} label={t("errorBoundary.tellUsWhatHappened")} numberOfLines={4} style={styles.input} />
      </View>
    </ScreenWrapper>
  );
};
