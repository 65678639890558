import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { pushNotificationSettingsFields } from "@app/common/graphql/fragments.graphql";
import { PushNotificationsUserSettings } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  pushNotificationSettings: PushNotificationsUserSettings;
}

interface Variables {
  userId: string;
}

const pushNotificationSettingsQuery = gql`
  query PushNotificationSettings($userId: UserId!) {
    pushNotificationSettings(userId: $userId) {
      ...PushNotificationSettingsFields
    }
  }
  ${pushNotificationSettingsFields}
`;

export function usePushNotificationUserSettings(options?: QueryHookOptions<Response, Variables>): QueryResult<Response, "pushNotificationSettings"> {
  const { data, loading } = useQuery<Response, Variables>(pushNotificationSettingsQuery, {
    ...options,
    skip: !options?.variables?.userId,
  });

  return { data: data?.pushNotificationSettings, loading };
}
