import React, { FC, useCallback, useEffect } from "react";

import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { ProductUpdateStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { CreateUpdateForm } from "@app/components/updates/create-update-form/create-update-form.component";
import { useProductUpdateContext } from "@app/context/product-update/product-update.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCreateCollabUpdate } from "@app/hooks/api/updates/use-create-collab-update.hook";
import { useUpdateCollabUpdate } from "@app/hooks/api/updates/use-update-collab-update.hook";

import { styles } from "./create-product-update.style";

export const CreateProductUpdateScreen: FC<ProductUpdateStackScreenProps<Routes.createProductUpdate>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { profile } = useProfileContext();

  const {
    inputs: { title, content, images },
    resetInputs,
  } = useProductUpdateContext();
  const { createCollabUpdate, loading: loadingCreateCollabUpdate } = useCreateCollabUpdate();
  const { updateCollabUpdate, loading: loadingUpdateCollabUpdate } = useUpdateCollabUpdate();

  const collabId = route.params?.collabId;
  const updateId = route.params?.updateId;

  const isEditing = !!updateId;
  const userId = profile?.userId;
  const loading = loadingCreateCollabUpdate || loadingUpdateCollabUpdate;
  const isReady = !!title && !!content;

  const handleOnSuccess = useCallback(
    (newUpdateId: string): void => {
      resetInputs();
      if (isEditing) {
        navigation.goBack();
      } else {
        navigation.replace(Routes.productUpdate, { updateId: newUpdateId });
      }
    },
    [isEditing, navigation, resetInputs],
  );

  const handleCancel = useCallback(() => {
    resetInputs();

    navigation.goBack();
  }, [navigation, resetInputs]);

  const handleOnError = useCallback(
    (error: ApolloError): void => {
      showErrorSnackbar({ error });
    },
    [showErrorSnackbar],
  );

  const handleCreateCollabUpdate = useCallback((): void => {
    if (!userId || !collabId) return;

    void createCollabUpdate({
      variables: {
        input: {
          title,
          content,
          imageIds: images.map(i => i.imageId),
        },
        collabId,
        userId,
      },
      onCompleted: data => handleOnSuccess(data.createCollabUpdate.collabUpdateId),
      onError: handleOnError,
    });
  }, [collabId, content, createCollabUpdate, handleOnError, handleOnSuccess, images, title, userId]);

  const handleUpdateCollabUpdate = useCallback(() => {
    if (!updateId) return;

    void updateCollabUpdate({
      variables: { collabUpdateId: updateId, input: { title, content, imageIds: images.map(i => i.imageId) } },
      onCompleted: data => handleOnSuccess(data.updateCollabUpdate.collabUpdateId),
      onError: handleOnError,
    });
  }, [updateId, updateCollabUpdate, title, content, images, handleOnError, handleOnSuccess]);

  const handleSubmit = useCallback(() => {
    if (!isReady) return;

    if (isEditing) {
      handleUpdateCollabUpdate();
    } else {
      handleCreateCollabUpdate();
    }
  }, [handleCreateCollabUpdate, handleUpdateCollabUpdate, isEditing, isReady]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button disabled={!isReady || loading} onPress={handleSubmit} loading={loading}>
          {isEditing ? t("cta.update") : t("cta.post")}
        </Button>
      ),
      headerLeft: () => <Button onPress={handleCancel}>{t("cta.cancel")}</Button>,
    });
  }, [handleCancel, handleSubmit, isEditing, isReady, loading, navigation, t]);

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <CreateUpdateForm />
    </ScreenWrapper>
  );
};
