import React, { FC } from "react";

import { ResizeMode } from "expo-av";
import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { CampaignVideo, VideoStatus } from "@app/common/graphql/generated/schema.graphql";
import { Video } from "@app/components/common/video/video.component";
import { VideoRefreshAlert } from "@app/components/common/video-refresh-alert/video-refresh-alert.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./pitch-video-preview.style";

interface Props {
  pitchVideo: CampaignVideo | undefined;
  refreshPitchVideo: () => void;
}

export const PitchVideoPreview: FC<Props> = ({ pitchVideo, refreshPitchVideo }) => {
  const { colors } = useTheme();
  const { width: widthScreen } = useWindowDimensions();

  const videoSize = widthScreen - 40; // paddingHorizontal = 40 = 20 * 2
  const videoProcessing = pitchVideo?.status === VideoStatus.processing || pitchVideo?.status === VideoStatus.created;
  const uri = pitchVideo?.videoManifestUrl ?? pitchVideo?.videoUrl;
  const posterUri = pitchVideo?.thumbnailUrl;
  const cloudflareId = pitchVideo?.cloudflareId;

  const canDisplayVideo = !!uri;

  if (!pitchVideo) return null;

  if (videoProcessing || !canDisplayVideo) {
    return (
      <View style={styles.processingContainer}>
        <View style={[styles.video, styles.loadingContainer, { borderColor: colors.surfaceDisabled }]}>
          <ActivityIndicator size="large" color={colors.tertiary} />
        </View>

        {videoProcessing && <VideoRefreshAlert handleRefresh={refreshPitchVideo} />}
      </View>
    );
  }

  return (
    <Video
      source={{ uri }}
      posterSource={{ uri: posterUri }}
      cloudflareId={cloudflareId}
      useNativeControls
      shouldPlay
      displayLoadingIndicator
      width={videoSize}
      height={videoSize}
      resizeMode={ResizeMode.CONTAIN}
      style={{ backgroundColor: colors.background }}
    />
  );
};
