import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonWrapper: {
    alignItems: "center",
    flexDirection: "row",
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: "center",
    paddingHorizontal: 4,
  },
  gradient: {
    bottom: 0,
    position: "absolute",
    top: 0,
    width: 12,
  },
  leftGradient: {
    left: 0,
  },
  rightGradient: {
    right: 0,
  },
});
