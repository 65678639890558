import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "flex-start",
    columnGap: 8,
    flex: 1,
    flexShrink: 0,
    rowGap: 4,
  },
  leftIcon: {
    width: 24,
  },
  rightIcon: {
    borderRadius: 0,
  },
  root: {
    paddingVertical: 12,
  },
  timeContainer: {
    columnGap: 4,
    flexDirection: "row",
  },
});
