import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { Stage } from "@app/common/graphql/generated/schema.graphql";

const mapStageBrowseOption: { [key in Stage]?: BrowseOption } = {
  [Stage.concept]: BrowseOption.concepts,
  [Stage.underReview]: BrowseOption.underReview,
  [Stage.forSale]: BrowseOption.sellables,
};

export function getBrowseOptionFromStage(stage: Stage): BrowseOption {
  return mapStageBrowseOption[stage] ?? BrowseOption.sellables;
}

export function getStageFromBrowseOption(browseOption: BrowseOption): Stage {
  return (Object.keys(mapStageBrowseOption) as Array<Stage>).find(key => mapStageBrowseOption[key] === browseOption) ?? Stage.concept;
}
