import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { likeFields } from "@app/common/graphql/fragments.graphql";
import { DislikeCollabInput, Like } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { updateCacheAfterDislikeCollab } from "@app/utils/cache-updates/update-cache-after-dislike-collab.util";

interface Response {
  dislikeCollab: Like;
}

interface Variables {
  input: DislikeCollabInput;
  userId: string;
}

interface DislikeCollabResult extends MutationResult<Response, "dislikeCollab"> {
  dislikeCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const dislikeCollabMutation = gql`
  mutation DislikeCollab($input: DislikeCollabInput!, $userId: UserId!) {
    dislikeCollab(input: $input, userId: $userId) {
      ...LikeFields
    }
  }
  ${likeFields}
`;

export function useDislikeCollab(): DislikeCollabResult {
  const [dislikeCollab, { loading, error, data }] = useMutation<Response, Variables>(dislikeCollabMutation, {
    update(cache, results) {
      updateCacheAfterDislikeCollab({
        cache,
        collabId: results.data?.dislikeCollab.collabId,
        numberOfLikes: results.data?.dislikeCollab.numberOfLikes,
      });
    },
  });

  return { dislikeCollab, loading, error, data: data?.dislikeCollab };
}
