import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  addImageContainer: {
    alignItems: "center",
    borderRadius: 4,
    borderStyle: "dashed",
    borderWidth: 1,
    justifyContent: "center",
    rowGap: 4,
    width: 91,
  },
  deleteImageButton: {
    position: "absolute",
    right: -20,
    top: -20,
  },
  editImageContainer: {
    alignSelf: "center",
    borderRadius: 4,
    width: 91,
  },
});
