import { StyleSheet } from "react-native";

import { goalsProgressHeight } from "../goals-progress.style";

export const styles = StyleSheet.create({
  popperWrapper: {
    flex: 1,
    height: goalsProgressHeight,
  },
});
