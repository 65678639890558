import { StyleSheet } from "react-native";

const padding = 8;

export const styles = StyleSheet.create({
  bottomIosHandle: {
    flex: 0,
  },
  buttonContainer: {
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: padding,
  },
  buttonsWrapper: {
    rowGap: padding,
  },
  campaignInfoContainer: {
    alignItems: "flex-start",
    columnGap: 12,
    paddingHorizontal: 16,
    paddingVertical: 16,
    rowGap: 8,
  },
  campaignInfoTextContainer: {
    rowGap: 4,
  },
  root: {
    paddingBottom: 20,
  },
});
