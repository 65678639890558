import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    height: 48,
    margin: 0,
  },
  buttonContent: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  buttonsContainer: {
    flexDirection: "row",
    gap: 8,
    width: "100%",
  },
  copyButton: {
    flex: 1,
  },
  instagramButton: {
    flex: 5,
  },
  root: {
    rowGap: 8,
  },
});
