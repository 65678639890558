import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { OnboardingNavigation } from "@app/common/types/navigation/screen-navigation.type";
import { OnboardingStackParamList, Routes } from "@app/common/types/navigation.type";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { usePushNotificationPermissionContext } from "@app/context/push-notifications/permission/push-notifications-permission.context";
import { isWeb } from "@app/utils/device.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { OnboardingContext } from "./onboarding.context";
import { OnboardingStepState, OnboardingContextInterface } from "./onboarding.type";

export const OnboardingContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { state } = useAuthContext();
  const { shouldPromptForPermission: shouldPromptForPushNotifications } = usePushNotificationPermissionContext();
  const { state: userState, loading: loadingProfile, updateState } = useProfileContext();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [hideLoginScreen, setHideLoginScreen] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const loading = loadingProfile || forceLoading;

  const connectedAndVerified = state.connected && state.verified;
  const showLoginScreen = !isLikelyInEmbeddedWebview() && !connectedAndVerified && !isWeb && !hideLoginScreen;
  const showNotificationPermissionsScreen = connectedAndVerified && shouldPromptForPushNotifications;
  const showUserInfoScreen = connectedAndVerified && !loadingProfile && !userState?.[UserStateEnum.userInfoCompleted];

  const onboardingStepsState: OnboardingStepState[] = useMemo(
    () => [
      { route: Routes.userInfo, show: showUserInfoScreen },
      { route: Routes.notificationsPermission, show: showNotificationPermissionsScreen },
    ],
    [showNotificationPermissionsScreen, showUserInfoScreen],
  );

  const [showOnboarding, setShowOnboarding] = useState(onboardingStepsState.some(step => step.show === true));

  useEffect(() => {
    const showOnboardingValue = onboardingStepsState.some(step => step.show === true);
    if (!loading && showOnboardingValue === true) {
      setShowOnboarding(true);
    }
    // Should not recompute when onboarding steps state are changing, only if its loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const initialStep = useMemo(() => {
    if (loading) return undefined;

    return onboardingStepsState.find(step => step.show === true)?.route;
    // Should not recompute when onboarding steps state are changing, only if its loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const numberOfStepsToShow = useMemo(() => {
    const stepsToShow = onboardingStepsState.filter(step => step.show === true);
    return stepsToShow.length;
    // Should not recompute when onboarding steps state are changing, only if its loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onOnboardingStepCompleted = useCallback(
    (currentStep: keyof OnboardingStackParamList, navigation: OnboardingNavigation): void => {
      const stepsToShow = onboardingStepsState.filter(step => step.show === true);
      const currentIndex = stepsToShow.findIndex(step => step.route === currentStep);

      const stepsToShowLeft = stepsToShow.length === 1 || stepsToShow.length === currentIndex + 1 ? [] : stepsToShow.slice(-(currentIndex + 1));
      const isLastStep = !stepsToShowLeft.length;

      if (isLastStep) {
        setShowOnboarding(false);
      } else {
        setCurrentStepIndex(currentIndex + 1);
        const nextStepRoute = stepsToShow[currentIndex + 1].route;
        navigation.navigate(nextStepRoute);
      }
    },
    // Should not recompute when onboarding steps state are changing, only if its loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  const refreshOnboardingStepsState = useCallback(() => {
    setForceLoading(true);
    setCurrentStepIndex(0);

    updateState({
      input: { [UserStateEnum.userInfoCompleted]: false },
      onCompleted: () => {
        setForceLoading(false);
      },
      onError: () => {
        setForceLoading(false);
      },
    });
  }, [updateState]);

  const contextValue = useMemo<OnboardingContextInterface>(
    () => ({
      onboardingStepsState,
      showOnboarding,
      initialStep,
      showLoginScreen,
      numberOfStepsToShow,
      currentStepIndex,
      onOnboardingStepCompleted,
      refreshOnboardingStepsState,
      setHideLoginScreen,
    }),
    [
      onboardingStepsState,
      showOnboarding,
      initialStep,
      showLoginScreen,
      numberOfStepsToShow,
      currentStepIndex,
      onOnboardingStepCompleted,
      refreshOnboardingStepsState,
    ],
  );

  return <OnboardingContext.Provider value={contextValue}>{children}</OnboardingContext.Provider>;
};
