import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { ProductUpdateStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { ProductUpdateProvider } from "@app/context/product-update/product-update.provider";
import { CreateProductUpdateScreen } from "@app/screens/product-updates/create-product-update/create-product-update.screen";
import { EditProductUpdateFieldScreen } from "@app/screens/product-updates/edit-product-update-field/edit-product-update-field.screen";
import { ProductUpdateScreen } from "@app/screens/product-updates/product-update/product-update.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<ProductUpdateStackParamList>();

export const ProductUpdateStack: FC<NavigationProps<Routes.productUpdateStack>> = () => {
  return (
    <ProductUpdateProvider>
      <Stack.Navigator screenOptions={{ ...commonScreenOptions(), presentation: "card" }}>
        <Stack.Screen name={Routes.productUpdate} component={ProductUpdateScreen} getId={({ params }) => params.updateId} options={{ title: "" }} />
        <Stack.Screen name={Routes.createProductUpdate} component={CreateProductUpdateScreen} options={{ title: "" }} />
        <Stack.Screen name={Routes.editProductUpdateField} component={EditProductUpdateFieldScreen} />
      </Stack.Navigator>
    </ProductUpdateProvider>
  );
};
