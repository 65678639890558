import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    rowGap: 24,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  section: {
    rowGap: 8,
  },
});
