import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { likeFields } from "@app/common/graphql/fragments.graphql";
import { Like, LikeCollabInFeedInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { updateCacheAfterLikeCollab } from "@app/utils/cache-updates/update-cache-after-like-collab.util";

interface Response {
  likeCollabInFeed: Like;
}

interface Variables {
  input: LikeCollabInFeedInput;
  userId: string;
}

interface LikeCollabInFeedResult extends MutationResult<Response, "likeCollabInFeed"> {
  likeCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const likeCollabInFeedMutation = gql`
  mutation LikeCollabInFeed($input: LikeCollabInFeedInput!, $userId: UserId!) {
    likeCollabInFeed(input: $input, userId: $userId) {
      ...LikeFields
    }
  }
  ${likeFields}
`;

export function useLikeCollabInFeed(): LikeCollabInFeedResult {
  const [likeCollab, { loading, error, data, client }] = useMutation<Response, Variables>(likeCollabInFeedMutation, {
    update(cache, results) {
      updateCacheAfterLikeCollab({
        cache,
        client,
        collabId: results.data?.likeCollabInFeed.collabId,
        numberOfLikes: results.data?.likeCollabInFeed.numberOfLikes,
      });
    },
  });

  return { likeCollab, loading, error, data: data?.likeCollabInFeed };
}
