import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface StudioImageResponse {
  exploreImage: StudioImage;
}

interface Variables {
  studioImageId: string;
}

const studioExploreImageQuery = gql`
  query ExploreImage($studioImageId: StudioImageId!) {
    exploreImage(studioImageId: $studioImageId) {
      ...CoreStudioImageFields
    }
  }
  ${coreStudioImageFields}
`;

export function useStudioExploreImage(options: QueryHookOptions<StudioImageResponse, Variables>): QueryResult<StudioImageResponse, "exploreImage"> {
  const { data, loading, error } = useQuery<StudioImageResponse, Variables>(studioExploreImageQuery, options);

  return { data: data?.exploreImage, loading, error };
}
