import { createContext, useContext } from "react";

import { AppStateInterface } from "./app-state.types";

export const AppStateContext = createContext<AppStateInterface>({
  isAppOnForeground: true,
  isInternetWorking: true,
});

export const useAppStateContext = (): AppStateInterface => useContext(AppStateContext);
