import { createContext, useContext } from "react";

export interface InAppPurchasesContextInterface {
  supercreatorPrice: string | undefined;
  buySupercreatorPlan: (onCompleted: () => void) => Promise<void>;
  userHasActiveSupercreatorSubscription: boolean;
  supersellerPrice: string | undefined;
  buySupersellerPlan: (onCompleted: () => void) => Promise<void>;
  userHasPaidForSupersellerPlan: boolean;
  manageSubscriptionsUrl: string | null | undefined; // null = no active subscriptions to manage, undefined = not ready yet
  loading: boolean;
}

export const InAppPurchasesContext = createContext<InAppPurchasesContextInterface>({
  supercreatorPrice: undefined,
  buySupercreatorPlan: () => Promise.resolve(undefined),
  userHasActiveSupercreatorSubscription: false,
  supersellerPrice: undefined,
  buySupersellerPlan: () => Promise.resolve(undefined),
  userHasPaidForSupersellerPlan: false,
  manageSubscriptionsUrl: undefined,
  loading: false,
});

export const useInAppPurchasesContext = (): InAppPurchasesContextInterface => useContext(InAppPurchasesContext);
