import { createContext, useContext } from "react";

import { ColorSchemeContextInterface } from "./color-scheme.types";

export const ColorSchemeContext = createContext<ColorSchemeContextInterface>({
  colorScheme: "light",
  colorSchemeSetting: "system",
  setColorSchemeSetting: () => undefined,
});

export const useColorSchemeContext = (): ColorSchemeContextInterface => useContext(ColorSchemeContext);
