import { Dispatch, RefObject, SetStateAction, createContext, useContext } from "react";

import { PromoType } from "@app/common/graphql/generated/schema.graphql";
import { SwipeButtonProps } from "@app/components/products/product-card-action-buttons/product-card-action-buttons.component";
import { SwiperCardRefProps } from "@app/components/products/product-stack/swiper/swiper.types";
import { FeedResult } from "@app/hooks/api/use-feed-with-media.hook";

export interface SwipeCollabProps extends Pick<SwipeButtonProps, "content" | "style"> {
  onSwipeCollab: (collabId: string) => void;
  overlayLabel: string;
}

export interface ResetFeedOptions {
  skipRefetch: boolean;
}

export interface FeedProps {
  swiperRef: RefObject<SwiperCardRefProps> | null;
  swipeRightProps: SwipeCollabProps;
  swipeLeftProps: SwipeCollabProps;
  feedResult: FeedResult;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  currentIndexImageMap: Map<string, number>;
  setCurrentIndexImageMap?: Dispatch<SetStateAction<Map<string, number>>>;
  currentContestCollabIndex: number;
  noCardsLeft: boolean;
  setNoCardsLeft: (value: boolean) => void;
  setCurrentContestCollabIndex: (index: number) => void;
  onViewedPromo: (promoId: string, promoType: PromoType) => void;
  resetFeed: (resetOptions?: ResetFeedOptions) => void;
}

const initialIndividualFeedValue: FeedProps = {
  swiperRef: null,
  swipeRightProps: { onSwipeCollab: (_collabId: string) => undefined, overlayLabel: "", content: "" },
  swipeLeftProps: { onSwipeCollab: (_collabId: string) => undefined, overlayLabel: "", content: "" },
  feedResult: { loading: false },
  currentIndex: 0,
  setCurrentIndex: (_index: number) => undefined,
  currentIndexImageMap: new Map(),
  currentContestCollabIndex: 0,
  setCurrentContestCollabIndex: (_index: number) => undefined,
  noCardsLeft: false,
  setNoCardsLeft: (_value: boolean) => undefined,
  onViewedPromo: (_promoId: string, _promoType: PromoType) => undefined,
  resetFeed: () => undefined,
};

export interface FeedContextInterface {
  feed: FeedProps;
}

export const FeedContext = createContext<FeedContextInterface>({
  feed: initialIndividualFeedValue,
});

export const useFeedContext = (): FeedContextInterface => useContext(FeedContext);
