import React, { FC, useCallback, useEffect, useState } from "react";

import { FloppyDisk } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { CreatePromoInput } from "@app/common/graphql/generated/schema.graphql";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { Box } from "@app/components/common/box/box.component";
import { Button } from "@app/components/common/button/button.component";
import { OptionalDatePicker } from "@app/components/common/date-time-picker/optional-date-picker/optional-date-picker.component";
import { ImagePicker } from "@app/components/common/image-picker/image-picker.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput, TextInputProps } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCreateGenericPromo } from "@app/hooks/api/promos/use-create-generic-promo.hook";
import { usePromo } from "@app/hooks/api/promos/use-promo.hook";
import { useUpdateGenericPromo } from "@app/hooks/api/promos/use-update-generic-promo.hook";
import { useUploadGenericPromoImage } from "@app/hooks/api/use-upload-generic-promo-image.hook";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { styles } from "./create-promo.style";

export const CreatePromoScreen: FC<NavigationProps<Routes.createPromo>> = ({ navigation, route }) => {
  const promoId = route.params?.promoId;
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { colors } = useTheme();

  const [input, setInput] = useState<Partial<CreatePromoInput>>({});
  const updateInput = (key: keyof CreatePromoInput) => (value?: string) => setInput(prev => ({ ...prev, [key]: value }));
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);

  const [imageToUpload, setImageToUpload] = useState<string | undefined>(undefined);

  const { createGenericPromo, loading: loadingCreate } = useCreateGenericPromo();
  const { updateGenericPromo, loading: loadingUpdate } = useUpdateGenericPromo();
  const { uploadImage, loading: loadingUploadImage } = useUploadGenericPromoImage();
  const { data: promo, loading: loadingPromo } = usePromo({
    variables: { promoId },
    onCompleted: response => {
      setInput({ ...response.promo });
      "startAt" in response.promo && response.promo.startAt && setStartAt(new Date(response.promo.startAt));
      "endAt" in response.promo && response.promo.endAt && setEndAt(new Date(response.promo.endAt));
    },
    onError: error => {
      showErrorSnackbar({ message: error.message });
      navigation.goBack();
    },
  });

  const isUpdating = !!promoId;
  const isLoading = loadingCreate || loadingUpdate || loadingUploadImage;

  useEffect(() => {
    if (isUpdating) {
      navigation.setOptions({
        title: t("godMode.managePromos.update"),
      });
    }
  }, [isUpdating, navigation, t]);

  const handleSuccess = useCallback(
    (message: string): void => Alert.alert(t("godMode.success"), message, [{ text: t("cta.ok"), onPress: () => navigation.goBack() }]),
    [navigation, t],
  );

  const handleUploadImage = useCallback(
    (id: string, successMessage: string, errorMessage: string): void => {
      if (!imageToUpload) return;

      generateImageToFormData(imageToUpload)
        .then(form =>
          uploadImage({ variables: { input: form, promoId: id } })
            .then(() => {
              handleSuccess(successMessage);
            })
            .catch(() => showErrorSnackbar({ message: errorMessage })),
        )
        .catch(() => showErrorSnackbar({ message: errorMessage }));
    },
    [handleSuccess, imageToUpload, showErrorSnackbar, uploadImage],
  );

  const handleCreatePromo = useCallback((): void => {
    const { title, subtitle, text, ctaLabel, ctaUrl, imageUrl } = input;

    if (!title || !subtitle || !text || !ctaLabel || !ctaUrl) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageUrl && !imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.managePromos.imageRequired"));
      return;
    }

    void createGenericPromo({
      variables: {
        input: {
          ctaLabel,
          ctaUrl,
          imageUrl: imageToUpload ? undefined : imageUrl,
          title,
          subtitle,
          text,
          startAt: startAt?.toISOString(),
          endAt: endAt?.toISOString(),
        },
      },
      onCompleted: data => {
        if (imageToUpload) {
          handleUploadImage(data.createGenericPromo.promoId, t("godMode.managePromos.created"), t("godMode.managePromos.createdButImageFailed"));
        } else {
          handleSuccess(t("godMode.managePromos.created"));
        }
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [input, createGenericPromo, imageToUpload, startAt, endAt, t, handleUploadImage, handleSuccess, showErrorSnackbar]);

  const handleUpdatePromo = useCallback((): void => {
    if (!promo) return;

    const { title, subtitle, text, ctaLabel, ctaUrl, imageUrl } = input;

    if (!title || !subtitle || !text || !ctaUrl) {
      Alert.alert(t("error.warning"), t("error.missingRequiredFields"));
      return;
    }

    if (!imageUrl && !imageToUpload) {
      Alert.alert(t("error.warning"), t("godMode.managePromos.imageRequired"));
      return;
    }

    void updateGenericPromo({
      variables: {
        input: {
          ctaLabel,
          ctaUrl,
          imageUrl: imageToUpload ? undefined : imageUrl ?? null,
          title,
          subtitle,
          text,
          startAt: startAt?.toISOString() ?? null,
          endAt: endAt?.toISOString() ?? null,
        },
        promoId: promo.promoId,
      },
      onCompleted: data => {
        if (imageToUpload) {
          handleUploadImage(data.updateGenericPromo.promoId, t("godMode.managePromos.updated"), t("godMode.managePromos.updatedButImageFailed"));
        } else {
          handleSuccess(t("godMode.managePromos.updated"));
        }
      },
      onError: e => showErrorSnackbar({ message: e.message }),
    });
  }, [promo, input, startAt, endAt, updateGenericPromo, imageToUpload, t, handleUploadImage, handleSuccess, showErrorSnackbar]);

  const commonProps = (name: keyof CreatePromoInput): Partial<TextInputProps> => ({
    label: name,
    loading: !!promoId && loadingPromo,
    value: input[name] as string | undefined,
  });

  const urlProps: Partial<TextInputProps> = {
    keyboardType: "url",
    autoCapitalize: "none",
    autoCorrect: false,
  };

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <TextInput {...commonProps("title")} required onChangeText={updateInput("title")} />
      <TextInput {...commonProps("subtitle")} required onChangeText={updateInput("subtitle")} numberOfLines={2} />
      <TextInput {...commonProps("text")} required onChangeText={updateInput("text")} multiline numberOfLines={3} />
      <TextInput {...commonProps("ctaLabel")} required onChangeText={updateInput("ctaLabel")} />
      <TextInput {...commonProps("ctaUrl")} {...urlProps} required onChangeText={updateInput("ctaUrl")} />

      <Box rowGap={8} paddingVertical={16}>
        <OptionalDatePicker fieldLabel="startAt" value={startAt} setValue={setStartAt} />
        <OptionalDatePicker fieldLabel="endAt" value={endAt} setValue={setEndAt} />
      </Box>

      <ImagePicker
        image={imageToUpload}
        setImage={setImageToUpload}
        aspectRatio={1}
        disabled={!!input.imageUrl}
        legend={
          <Box rowGap={8}>
            <Text variant="h9">{t("godMode.managePromos.image")}</Text>
            <TextInput
              {...commonProps("imageUrl")}
              {...urlProps}
              onChangeText={updateInput("imageUrl")}
              disabled={!!imageToUpload}
              textColor={imageToUpload ? colors.surfaceDisabled : colors.onBackground}
            />
            <Text>{t("godMode.managePromos.orUploadImage")}</Text>
          </Box>
        }
        style={styles.editImageContainer}
      />

      <Button
        icon={FloppyDisk}
        loading={isLoading}
        fullWidth
        mode="contained"
        size="large"
        onPress={isUpdating ? handleUpdatePromo : handleCreatePromo}>
        {isUpdating ? t("godMode.managePromos.update") : t("godMode.managePromos.create")}
      </Button>
    </ScreenWrapper>
  );
};
