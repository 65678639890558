import React, { FC, useState } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableHighlight } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

import HandHeart from "@app/assets/icons/hand-heart-thin.svg";
import { MilestoneType } from "@app/common/graphql/generated/schema.graphql";
import { supercoinsGradient } from "@app/common/style/common.style";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { ShareReferralLinkButton } from "@app/components/profile/share-referral-link-button/share-referral-link-button.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSmallestMilestoneObjective } from "@app/hooks/api/supercoins/use-smallest-milestone-objective.hook";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./referral-reward-alert.style";

export const ReferralRewardAlert: FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { profile, state: userState, updateState } = useProfileContext();
  const { data: firstReferralMilestone } = useSmallestMilestoneObjective({
    variables: { where: { milestoneType: MilestoneType.referralMilestone } },
  });

  const [visible, setVisible] = useState(!userState?.[UserStateEnum.referralRewardAlertDismissed]);

  const opacity = useSharedValue(1);

  const handleDismiss = (): void => {
    opacity.value = withTiming(0, { duration: 250 }, finished => {
      if (finished) {
        runOnJS(setVisible)(false);
      }
    });
    updateState({ input: { [UserStateEnum.referralRewardAlertDismissed]: true } });
  };

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  if (!visible) return null;

  return (
    <Animated.View style={animatedStyle}>
      <LinearGradient colors={supercoinsGradient} locations={[0, 1]} style={styles.root}>
        <ListItem
          title={t("supercoins.onReferral", { reward: formatPriceUSD(firstReferralMilestone?.reward, { withoutUSD: true }) })}
          titleProps={{ variant: "body2", numberOfLines: 0 }}
          description={
            profile?.shareId && (
              <ShareReferralLinkButton
                shareId={profile.shareId}
                buttonComponent={({ onPress, label }) => (
                  <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={onPress}>
                    <Text variant="body2" style={{ color: colors.warning160p }}>
                      {label}
                    </Text>
                  </TouchableHighlight>
                )}
              />
            )
          }
          left={<HandHeart color={colors.onBackground} width={24} height={24} />}
          right={<IconButton icon={X} iconColor={colors.onBackground} size={24} onPress={handleDismiss} style={styles.iconButton} />}
          contentContainerStyle={styles.contentContainer}
        />
      </LinearGradient>
    </Animated.View>
  );
};
