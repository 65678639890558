import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    rowGap: 8,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: 8,
    marginVertical: 4,
  },
  searchBar: {
    flexGrow: 1,
  },
});
