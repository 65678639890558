import { StyleSheet } from "react-native";

export const imageWidthSeparation = 16;
export const imageScreenPadding = 20;

export const styles = StyleSheet.create({
  bottomSheetContainer: {
    paddingHorizontal: 0,
  },
  handleContainer: {
    alignSelf: "center",
    position: "absolute",
  },
  listColumnContainer: {
    columnGap: imageWidthSeparation,
  },
  listContainer: {
    paddingBottom: imageScreenPadding,
    rowGap: imageWidthSeparation,
  },
  navigationButtonPlaceholder: {
    height: 40,
    width: 40,
  },
  navigationContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    width: "100%",
  },
  pagePadding: {
    paddingHorizontal: imageScreenPadding,
  },
});
