import React, { FC, useCallback } from "react";

import { IconProps } from "phosphor-react-native";
import { View, StyleSheet } from "react-native";
import { IconButton, IconButtonProps, Surface, useTheme } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import Animated, { SharedValue, useAnimatedProps, useAnimatedReaction, useAnimatedStyle, useSharedValue } from "react-native-reanimated";

import { customFonts } from "@app/common/style/fonts";
import { transparent } from "@app/common/style/theme";

import { styles } from "./product-card-main-button.style";

interface Props extends Omit<IconButtonProps, "icon"> {
  isActive: SharedValue<boolean>;
  bgColorRange: [string, string];
  contentColorRange?: [string, string];
  icon?: IconSource;
  animatedIcon?: FC<IconProps>;
  animatedText?: string;
}

export const ProductCardMainButton: FC<Props> = ({
  isActive,
  bgColorRange,
  contentColorRange,
  icon,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  animatedIcon: AnimatedIcon,
  animatedText,
  ...props
}) => {
  const { roundness } = useTheme();

  const progress = useSharedValue(0);

  const animatedBgColorStyle = useAnimatedStyle(() => ({
    backgroundColor: bgColorRange[progress.value],
  }));

  const animatedIconStyle = useAnimatedProps(() => ({
    opacity: progress.value,
  }));

  const animatedTextColorStyle = useAnimatedStyle(() => ({
    color: contentColorRange ? contentColorRange[progress.value] : undefined,
  }));

  const handlePressIn = useCallback(() => (isActive.value = true), [isActive]);

  const handlePressOut = useCallback(() => (isActive.value = false), [isActive]);

  useAnimatedReaction(
    () => isActive.value,
    (currentIsActive, previous) => {
      if (currentIsActive === previous) return;

      if (currentIsActive) {
        progress.value = 1;
      } else {
        progress.value = 0;
      }
    },
  );

  const buttonIcon = animatedText
    ? () => <Animated.Text style={[customFonts.body1, styles.text, animatedTextColorStyle]}>{animatedText}</Animated.Text>
    : AnimatedIcon
    ? () => (
        <View>
          <AnimatedIcon weight="fill" size={24} color={contentColorRange?.[0]} />
          <Animated.View style={[StyleSheet.absoluteFillObject, animatedIconStyle]}>
            <AnimatedIcon weight="fill" size={24} color={contentColorRange?.[1]} />
          </Animated.View>
        </View>
      )
    : icon ?? (() => undefined);

  return (
    <Surface elevation={3} style={{ borderRadius: roundness }}>
      <Animated.View style={[{ borderRadius: roundness }, animatedBgColorStyle]}>
        <IconButton containerColor={transparent} onPressIn={handlePressIn} onPressOut={handlePressOut} icon={buttonIcon} {...props} />
      </Animated.View>
    </Surface>
  );
};
