export enum UserStateEnum {
  autogenerateDescriptionPopperDismissed = "autogenerateDescriptionPopperDismissed",
  editProfileDismissed = "editProfileDismissed",
  goalPoppersDismissed = "goalPoppersDismissed",
  referralRewardAlertDismissed = "supercoinsReferralAlertDismissed",
  startCreatingPopperDismissed = "startCreatingPopperDismissed",
  studioVariationsPopperDismissed = "studioVariationsPopperDismissed",
  supercoinsBannerDismissed = "supercoinsBannerDismissed",
  swipeOnboardingCompleted = "swipeOnboardingCompleted",
  userInfoCompleted = "userInfoCompleted",
  userReviewAskedCount = "userReviewAskedCount",
}

export interface UserState {
  [UserStateEnum.autogenerateDescriptionPopperDismissed]?: boolean;
  [UserStateEnum.editProfileDismissed]?: boolean;
  [UserStateEnum.goalPoppersDismissed]?: boolean;
  [UserStateEnum.referralRewardAlertDismissed]?: boolean;
  [UserStateEnum.startCreatingPopperDismissed]?: boolean;
  [UserStateEnum.studioVariationsPopperDismissed]?: boolean;
  [UserStateEnum.supercoinsBannerDismissed]?: boolean;
  [UserStateEnum.swipeOnboardingCompleted]?: boolean;
  [UserStateEnum.userInfoCompleted]?: boolean;
  [UserStateEnum.userReviewAskedCount]?: number;
}
