import { t } from "i18next";
import { formatCurrency } from "react-native-format-currency";

export function formatPriceUSD(price?: number, options?: { withoutUSD: boolean }): string | null {
  if (price == null) return null;

  const priceWith2DecimalPlaces = Math.round(price * 100) / 100;
  const currencyFormatted = formatCurrency({ amount: priceWith2DecimalPlaces, code: "USD" })[0];
  const array = currencyFormatted?.split(".");
  const decimalFormatted = array?.[1]?.length === 1 ? `${array?.[1]}0` : array?.[1];
  const currencyAtTheEnd = options?.withoutUSD ? "" : " USD";

  return `${t("products.price", { integer: array?.[0], decimal: decimalFormatted })}${currencyAtTheEnd}`;
}

export function computePriceArrays(singleOrRangePrice?: string | [string | undefined, string | undefined]): {
  floorPriceArray?: string[];
  ceilingPriceArray?: string[];
  priceArray?: string[];
  isPriceRange: boolean;
} {
  const price =
    singleOrRangePrice &&
    Array.isArray(singleOrRangePrice) &&
    singleOrRangePrice[0] &&
    singleOrRangePrice[1] &&
    singleOrRangePrice[0] === singleOrRangePrice[1]
      ? singleOrRangePrice[0] ?? singleOrRangePrice[1]
      : singleOrRangePrice;

  const isPriceRange = !!price && Array.isArray(price);

  const floorPriceArray = isPriceRange ? price?.[0]?.split(".") : undefined;
  const ceilingPriceArray = isPriceRange ? price?.[1]?.split(".") : undefined;
  const priceArray = !isPriceRange ? price?.split(".") : undefined;

  return { floorPriceArray, ceilingPriceArray, priceArray, isPriceRange };
}
