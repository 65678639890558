import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabCampaignFields } from "@app/common/graphql/fragments.graphql";
import { CollabCampaign, UpdateCollabCampaignInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCollabCampaign: CollabCampaign;
}

interface Variables {
  input: UpdateCollabCampaignInput;
  collabId: string;
}

interface UpdateCollabResult extends MutationResult<Response, "updateCollabCampaign"> {
  updateCollabCampaign: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCollabCampaignMutation = gql`
  mutation UpdateCollabCampaign($collabId: CollabId!, $input: UpdateCollabCampaignInput!) {
    updateCollabCampaign(collabId: $collabId, input: $input) {
      ...CollabCampaignFields
    }
  }
  ${collabCampaignFields}
`;

export function useUpdateCollabCampaign(): UpdateCollabResult {
  const [updateCollabCampaign, { loading, error, data }] = useMutation<Response, Variables>(updateCollabCampaignMutation, {
    update(cache, results, options) {
      cache.modify({
        id: options.variables?.collabId,
        fields: {
          campaign: () => results.data?.updateCollabCampaign ?? null,
        },
      });
    },
  });

  return { updateCollabCampaign, loading, error, data: data?.updateCollabCampaign };
}
