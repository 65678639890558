import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  getReactNativePersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";

import { isWeb } from "@app/utils/device.util";

import { firebaseApp } from "./firebase.common";

const auth = initializeAuth(firebaseApp, {
  persistence: isWeb ? [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence] : getReactNativePersistence(AsyncStorage),
});

export async function getAuthWhenReady(): Promise<Auth> {
  await auth.authStateReady();
  return auth;
}
