import React, { ReactNode, useCallback, JSX, forwardRef, useMemo } from "react";

import { CaretRight } from "phosphor-react-native";
import { FlatList } from "react-native";
import { ListItemProps as RNListItemProps, List } from "react-native-paper";

import { BottomSheetProps, BottomSheetRefProps } from "../bottom-sheet.types";
import { NestedBottomSheet, NestedContentProps } from "../nested-bottom-sheet/nested-bottom-sheet.component";

export interface ListItemProps extends RNListItemProps {
  title: string | JSX.Element;
  nestedView?: NestedContentProps;
  key?: string;
}

interface Props extends Omit<BottomSheetProps, "children"> {
  list: ListItemProps[];
  title?: string;
  footer?: ReactNode;
  visibleNestedIndex?: number;
  setVisibleNestedIndex: (index?: number) => void;
  onNestedViewDismiss?: () => void;
  onDismiss?: () => void;
}

export const NestedBottomSheetWithList = forwardRef<BottomSheetRefProps, Props>(
  ({ list, title, footer, visibleNestedIndex, setVisibleNestedIndex, onDismiss, onNestedViewDismiss, ...bottomSheetProps }: Props, ref) => {
    const showNestedContent = useCallback(
      (index: number) => {
        setVisibleNestedIndex(index);
      },
      [setVisibleNestedIndex],
    );

    const renderListItem = useCallback(
      ({ item, index }: { item: ListItemProps; index: number }): JSX.Element => {
        const { nestedView, ...itemListProps } = item;

        const nestedProps: Omit<RNListItemProps, "title"> = nestedView
          ? {
              right: props => <CaretRight {...props} />,
              onPress: e => {
                if (itemListProps.onPress) itemListProps.onPress(e);
                showNestedContent(index);
              },
            }
          : {};

        return <List.Item {...itemListProps} {...nestedProps} />;
      },
      [showNestedContent],
    );

    const generateKey = (item: ListItemProps): string | undefined => (typeof item.title === "string" ? item.title : item.key);

    const nestedContent = useMemo<NestedContentProps>(() => {
      if (visibleNestedIndex === undefined) return { title: "", content: <></> };

      return list[visibleNestedIndex].nestedView ?? { title: "", content: <></> };
    }, [visibleNestedIndex, list]);

    return (
      <NestedBottomSheet
        ref={ref}
        title={visibleNestedIndex !== undefined ? list[visibleNestedIndex].nestedView?.title : title}
        footer={visibleNestedIndex !== undefined ? footer : undefined}
        nestedContentVisible={visibleNestedIndex !== undefined}
        hideNestedContent={() => setVisibleNestedIndex(undefined)}
        nestedContent={nestedContent}
        onDismiss={onDismiss}
        onNestedViewDismiss={onNestedViewDismiss}
        {...bottomSheetProps}>
        <FlatList
          data={list}
          renderItem={renderListItem}
          keyExtractor={(item, index) => `bottom-sheet-list-item-${generateKey(item) ?? index}`}
          scrollEnabled={false}
        />
      </NestedBottomSheet>
    );
  },
);

NestedBottomSheetWithList.displayName = "NestedBottomSheetWithList";
