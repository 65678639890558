import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { Feedback } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  sendFeedback: Feedback;
}

interface Variables {
  message: string;
  email?: string;
}

interface FeedbackResult extends MutationResult<Response, "sendFeedback"> {
  sendFeedback: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const sendFeedbackMutation = gql`
  mutation Feedback($email: String, $message: String!) {
    sendFeedback(email: $email, message: $message) {
      message
    }
  }
`;

export function useFeedback(): FeedbackResult {
  const [sendFeedback, { loading, error, data }] = useMutation<Response, Variables>(sendFeedbackMutation);

  return { sendFeedback, loading, error, data: data?.sendFeedback };
}
