import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadGenericPromoImage: {
    imageUrl: string;
  };
}

interface Variables {
  input: FormData;
  promoId: string;
}

interface UploadGenericPromoImageResult extends MutationResult<Response, "uploadGenericPromoImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadGenericPromoImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadGenericPromoImage($input: UploadImageInput!, $promoId: PromoId!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadGenericPromoImage(input: $input, promoId: $promoId)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/promo/generic/{args.promoId}/image", type: "File") {
      imageUrl
    }
  }
`;

export function useUploadGenericPromoImage(): UploadGenericPromoImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadGenericPromoImageMutation, {
    context: { clientName: ClientName.bloomRest },
    update(cache, results, options) {
      cache.modify({
        id: options.variables?.promoId as string,
        fields: {
          imageUrl: () => results.data?.uploadGenericPromoImage?.imageUrl,
        },
      });
    },
  });

  return { uploadImage, loading, error, data: data?.uploadGenericPromoImage };
}
