import React, { FC } from "react";

import { BlurView } from "expo-blur";
import { StyleProp, ViewStyle } from "react-native";
import Animated, { AnimatedStyle } from "react-native-reanimated";

import { Text } from "@app/components/common/text/text.component";

import { styles } from "./product-stack-overlay-label.style";

type OverlayLabelPosition = "top" | "right" | "left";

interface Props {
  label: string;
  color: string;
  position: OverlayLabelPosition;
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
}

export const ProductStackOverlayLabel: FC<Props> = ({ label, color, position, style }) => {
  const textShadowStyle = [styles.textShadow, { textShadowColor: "rgba(0,0,0,0.1)", backgroundColor: "rgba(0,0,0,0.1)" }];

  const styleMap: Record<OverlayLabelPosition, StyleProp<ViewStyle>> = {
    top: styles.topLabelWrapper,
    right: styles.rightLabelWrapper,
    left: styles.leftLabelWrapper,
  };

  return (
    <Animated.View style={[styleMap[position], style]}>
      <BlurView style={[{ borderColor: color }, styles.labelWrapper]}>
        <Text variant="h5" style={[textShadowStyle, { color }, styles.label]}>
          {label}
        </Text>
      </BlurView>
    </Animated.View>
  );
};
