import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadProfileImage: {
    imageUrl: string;
  };
}

interface Variables {
  input: FormData;
  userId: string;
}

interface UploadProfileImageResult extends MutationResult<Response, "uploadProfileImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadProfileImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadProfileImage($input: UploadImageInput!, $userId: String!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadProfileImage(input: $input, userId: $userId)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/profile/{args.userId}/image", type: "File") {
      imageUrl
    }
  }
`;

export function useUploadProfileImage(): UploadProfileImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadProfileImageMutation, {
    context: { clientName: ClientName.bloomRest },
    update(cache, results, options) {
      cache.modify({
        id: options.variables?.userId as string,
        fields: {
          imageUrl: () => results.data?.uploadProfileImage?.imageUrl,
        },
      });
    },
  });

  return { uploadImage, loading, error, data: data?.uploadProfileImage };
}
