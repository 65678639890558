export enum PaymentMethodListMode {
  nonSelectable = "nonSelectable",
  selectable = "selectable",
  uniqueSelected = "uniqueSelected",
}

type SelectableProps<T> = T & {
  mode: PaymentMethodListMode.selectable;
  selectedPaymentMethodId: string;
  setSelectedPaymentMethodId: (paymentMethodId: string) => void;
  setAnonymousCustomerId?: never;
};

type NonSelectableProps<T> = T & {
  mode: PaymentMethodListMode.nonSelectable;
  selectedPaymentMethodId?: never;
  setSelectedPaymentMethodId?: never;
  setAnonymousCustomerId?: never;
};

type UniqueSelectedProps<T> = T & {
  mode: PaymentMethodListMode.uniqueSelected;
  setAnonymousCustomerId: (id: string) => void;
  selectedPaymentMethodId?: never;
  setSelectedPaymentMethodId?: never;
};

export type PaymentMethodListModeProps<T> = SelectableProps<T> | NonSelectableProps<T> | UniqueSelectedProps<T>;
