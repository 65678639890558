import React, { FC, PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import { Popper } from "@app/components/common/popper/popper.component";
import { useConfigContext } from "@app/context/config/config.context";
import { usePopperContext } from "@app/context/popper/popper.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { isDesktop } from "@app/utils/device.util";

import { styles } from "./goals-progress-popper.style";

interface Props {
  visible?: boolean;
}

export const GoalsProgressPopper: FC<PropsWithChildren<Props>> = ({ visible, children }) => {
  const { t } = useTranslation();
  const { profile, loading: loadingProfile, state: userState, updateState } = useProfileContext();
  const {
    goalsPopperState: [goalsPopperOpen, setGoalsPopperOpen],
  } = usePopperContext();
  const { config } = useConfigContext();
  const likeCountObjective = config?.likeCountObjective ?? 1;

  const isPopperDismissed = userState?.[UserStateEnum.goalPoppersDismissed] === true || (!loadingProfile && !profile?.userId);

  const childrenWrapperBackgroundColor = "rgba(255,255,255,0.1)";

  if (isPopperDismissed || !visible) return children;

  return (
    <Popper
      isOpen={goalsPopperOpen}
      setIsOpen={setGoalsPopperOpen}
      message={t("onboarding.popper.goals", { likeCountObjective })}
      onClose={() => updateState({ input: { [UserStateEnum.goalPoppersDismissed]: true } })}
      placement="top"
      childrenWrapperStyle={[styles.popperWrapper, { backgroundColor: childrenWrapperBackgroundColor }]}
      childContentSpacing={0}
      maxWidth={!isDesktop ? "100%" : undefined}>
      {children}
    </Popper>
  );
};
