import { StatusBar, ScaledSize as TrueScaledSize, useWindowDimensions as useTrueWindowDimensions } from "react-native";

import { maxDesktopWidth } from "@app/router/router.style";
import { isAndroidApp, isDesktop } from "@app/utils/device.util";

interface ScaledSize extends TrueScaledSize {
  trueWidth: number;
}

export function useWindowDimensions(): ScaledSize {
  const trueDimensions = useTrueWindowDimensions();

  const height = isAndroidApp && StatusBar.currentHeight ? trueDimensions.height + StatusBar.currentHeight : trueDimensions.height;
  const width = isDesktop ? Math.min(trueDimensions.width, maxDesktopWidth) : trueDimensions.width;

  return { ...trueDimensions, height, width, trueWidth: trueDimensions.width };
}
