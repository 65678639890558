import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
  },
  chip: {
    alignSelf: "flex-start",
    borderRadius: 2,
  },
  content: {
    rowGap: 8,
  },
  image: {
    aspectRatio: imageRatio,
  },
  participateButton: {
    flexDirection: "row-reverse",
  },
  rewardContainer: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
    marginTop: "auto",
  },
  root: {
    alignItems: "flex-start",
  },
  subtitle: {
    overflow: "hidden",
    paddingHorizontal: 7,
  },
  textContainer: {
    paddingVertical: 8,
  },
});
