import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { PushNotificationsToken, PushNotificationsTokenInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  setPushNotificationsToken: PushNotificationsToken;
}

interface Variables {
  input: PushNotificationsTokenInput;
  userId: string;
}

interface SetPushNotificationsTokenResult extends MutationResult<Response, "setPushNotificationsToken"> {
  setPushNotificationsToken: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

export const setPushNotificationsTokenMutation = gql`
  mutation SetPushNotificationsToken($input: PushNotificationsTokenInput!, $userId: UserId!) {
    setPushNotificationsToken(input: $input, userId: $userId) {
      token
      userId
    }
  }
`;

export function useSetPushNotificationsToken(): SetPushNotificationsTokenResult {
  const [setPushNotificationsToken, { loading, error, data }] = useMutation<Response, Variables>(setPushNotificationsTokenMutation);

  return { setPushNotificationsToken, loading, error, data: data?.setPushNotificationsToken };
}
