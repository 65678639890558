import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { CollabUpdateLike, LikeCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  likeCollabUpdate: CollabUpdateLike;
}

interface Variables {
  input: LikeCollabUpdateInput;
  userId: string;
}

interface LikeCollabUpdateResult extends MutationResult<Response, "likeCollabUpdate"> {
  likeCollabUpdate: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const likeCollabUpdateMutation = gql`
  mutation LikeCollabUpdate($input: LikeCollabUpdateInput!, $userId: UserId!) {
    likeCollabUpdate(input: $input, userId: $userId) {
      collabUpdateId
      userId
    }
  }
`;

export function useLikeCollabUpdate(): LikeCollabUpdateResult {
  const [likeCollabUpdate, { loading, error, data }] = useMutation<Response, Variables>(likeCollabUpdateMutation, {
    update: (cache, _results, options) => {
      cache.modify({
        id: options.variables?.input.collabUpdateId,
        fields: {
          liked: () => true,
          likeCount: (existingCount: number) => existingCount + 1,
        },
      });
    },
  });

  return { likeCollabUpdate, loading, error, data: data?.likeCollabUpdate };
}
