import React, { Dispatch, SetStateAction } from "react";

import { X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { DimensionValue, StatusBar, View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import Tooltip, { TooltipProps } from "react-native-walkthrough-tooltip";

import { isAndroidApp } from "@app/utils/device.util";

import { Button } from "../button/button.component";
import { Text } from "../text/text.component";

import { styles } from "./popper.style";

export interface PopperProps extends Omit<TooltipProps, "content"> {
  message: string;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  stepProps?: { currentStep: number; totalSteps: number };
  maxWidth?: DimensionValue;
}

export const Popper: React.FC<PopperProps> = ({ isOpen, setIsOpen, message, onClose, stepProps, maxWidth = 255, ...props }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const handleClose = (): void => {
    onClose?.();
    setIsOpen?.(false);
  };

  return (
    <Tooltip
      isVisible={isOpen}
      placement="top"
      disableShadow
      onClose={handleClose}
      childContentSpacing={20}
      contentStyle={[{ backgroundColor: colors.secondary, borderRadius: roundness }, styles.contentContainer]}
      topAdjustment={isAndroidApp && StatusBar.currentHeight ? -StatusBar.currentHeight : 0}
      content={
        <View style={[{ maxWidth }, styles.content]}>
          <View style={styles.rowContent}>
            <Text variant="subtitle1" color="onSecondary" style={styles.text}>
              {message}
            </Text>
            <IconButton
              icon={iconProps => <X {...iconProps} color={colors.onSecondary} size={20} />}
              onPress={handleClose}
              style={styles.iconButton}
            />
          </View>

          <View style={styles.rowStep}>
            <Text variant="tooltip" color="onSecondary" style={styles.text}>
              {stepProps ? `${stepProps.currentStep + 1}/${stepProps.totalSteps}` : ""}
            </Text>

            <Button
              size="small"
              mode="outlined"
              textColor={colors.common.white}
              style={{ borderColor: colors.common.white }}
              labelStyle={styles.nextButton}
              compact
              onPress={handleClose}>
              {stepProps && stepProps.currentStep + 1 < stepProps.totalSteps ? t("cta.next") : t("cta.gotIt")}
            </Button>
          </View>
        </View>
      }
      {...props}
    />
  );
};
