import React, { FC } from "react";

import { MediaToBeUsed } from "@app/common/types/image.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioSavedImages } from "@app/components/studio-mini/studio-saved-images/studio-saved-images.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

interface Props {
  onUseImage: () => void;
}

export const StudioSavedImagesStep: FC<Props> = ({ onUseImage }) => {
  const { setGenerationImage } = useStudioMiniContext();

  const handleUseImages = (selectedImage: MediaToBeUsed): void => {
    setGenerationImage({ ...selectedImage, imageSource: "saved" });
    onUseImage();
  };

  return (
    <ScreenWrapper>
      <StudioSavedImages
        selectionMode="off"
        isSelecting={false}
        allowMultipleSelection={false}
        areVideosSelectable={false}
        onSelectImage={handleUseImages}
      />
    </ScreenWrapper>
  );
};
