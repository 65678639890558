import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadContestImage: {
    imageUrl: string;
  };
}

interface Variables {
  input: FormData;
  contestId: string;
}

interface UploadContestImageResult extends MutationResult<Response, "uploadContestImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadContestImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadContestImage($contestId: ContestId!, $input: UploadImageInput!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadContestImage(contestId: $contestId, input: $input)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/contest/{args.contestId}/image", type: "File") {
      imageUrl
    }
  }
`;

export function useUploadContestImage(): UploadContestImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadContestImageMutation, {
    context: { clientName: ClientName.bloomRest },
    update(cache, results, options) {
      cache.modify({
        id: options.variables?.contestId as string,
        fields: {
          imageUrl: () => results.data?.uploadContestImage?.imageUrl,
        },
      });
    },
  });

  return { uploadImage, loading, error, data: data?.uploadContestImage };
}
