import React, { ReactNode, useCallback, useRef, forwardRef, useEffect } from "react";

import { ArrowLeft } from "phosphor-react-native";
import { Animated, Keyboard } from "react-native";
import { IconButton } from "react-native-paper";

import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { BottomSheet } from "../bottom-sheet.component";
import { BottomSheetProps, BottomSheetRefProps } from "../bottom-sheet.types";

import { styles } from "./nested-bottom-sheet.style";

export interface NestedContentProps extends Pick<BottomSheetProps, "rightHeader" | "title" | "footer" | "subHeader"> {
  content: ReactNode;
}

interface Props extends BottomSheetProps {
  nestedContentVisible: boolean;
  hideNestedContent: () => void;
  onDismiss?: () => void;
  onNestedViewDismiss?: () => void;
  nestedContent: NestedContentProps;
}

export const NestedBottomSheet = forwardRef<BottomSheetRefProps, Props>(
  (
    {
      title,
      rightHeader,
      footer,
      subHeader,
      nestedContentVisible,
      hideNestedContent,
      onDismiss,
      onNestedViewDismiss,
      nestedContent,
      children,
      ...bottomSheetProps
    }: Props,
    ref,
  ) => {
    const { width } = useWindowDimensions();
    const mainListAnim = useRef(new Animated.Value(0)).current;
    const nestedViewAnim = useRef(new Animated.Value(0)).current;

    const slideSections = useCallback(
      (direction: "left" | "right"): void => {
        const animConfig = { toValue: direction === "left" ? -width : 0, duration: 300, useNativeDriver: true };

        Animated.parallel([Animated.timing(mainListAnim, animConfig), Animated.timing(nestedViewAnim, animConfig)]).start();
      },
      [nestedViewAnim, mainListAnim, width],
    );

    const hideNestedView = useCallback((): void => {
      onNestedViewDismiss?.();
      hideNestedContent();
      slideSections("right");
      Keyboard.dismiss();
    }, [hideNestedContent, onNestedViewDismiss, slideSections]);

    useEffect(() => {
      if (nestedContentVisible) {
        slideSections("left");
      }
    }, [nestedContentVisible, slideSections]);

    const backButton = <IconButton icon={({ color, size }) => <ArrowLeft color={color} size={size} weight="thin" />} onPress={hideNestedView} />;

    const mainViewStyle = [nestedContentVisible ? styles.hiddenView : {}, { transform: [{ translateX: mainListAnim }] }];
    const nestedViewStyle = [
      nestedContentVisible !== undefined ? {} : styles.hiddenView,
      { left: width, transform: [{ translateX: nestedViewAnim }] },
    ];

    return (
      <BottomSheet
        ref={ref}
        title={nestedContentVisible ? nestedContent.title : title}
        rightHeader={nestedContentVisible ? nestedContent.rightHeader ?? rightHeader : rightHeader}
        leftHeader={nestedContentVisible ? backButton : undefined}
        subHeader={nestedContentVisible ? nestedContent.subHeader ?? subHeader : subHeader}
        footer={nestedContentVisible ? nestedContent.footer ?? footer : footer}
        onDismiss={() => {
          onDismiss?.();
          hideNestedView();
        }}
        {...bottomSheetProps}>
        <Animated.View style={mainViewStyle}>{children}</Animated.View>

        <Animated.View style={nestedViewStyle}>{nestedContentVisible && nestedContent.content}</Animated.View>
      </BottomSheet>
    );
  },
);

NestedBottomSheet.displayName = "NestedBottomSheet";
