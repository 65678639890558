import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { REACT_APP_MIXPANEL_TOKEN } from "@env";

import { platformHeaderValue } from "@app/context/mixpanel/default-values.const";
import { getAppVersion } from "@app/utils/app.util";
import { geUserAccessToken } from "@app/utils/auth.util";
import { getCookie } from "@app/utils/cookies/cookies.util";
import { isWeb } from "@app/utils/device.util";
import { log } from "@app/utils/logger/logger.util";
import { isTokenEmailVerified } from "@app/utils/token.util";

import { ClientName } from "../enums/client-name.enum";
import { navigationRef } from "../types/navigation.type";

interface MixpanelCookie {
  /* eslint-disable @typescript-eslint/naming-convention */
  distinct_id: string;
  $device_id: string;
  $initial_referrer: string;
  $initial_referring_domain: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export enum BloomHeader {
  authorization = "authorization",
  distinctId = "Bloom-Distinct-Id",
  location = "Bloom-Location",
  platform = "Bloom-Platform",
  referer = "Bloom-Referer",
  retryCount = "Bloom-Retry-Count",
  version = "Bloom-Version",
}

export const setMixpanelInContext = setContext(async context => {
  const mixpanelCookieKey = `mp_${REACT_APP_MIXPANEL_TOKEN}_mixpanel`;
  const mixpanelCookie = await getCookie<MixpanelCookie>(mixpanelCookieKey);

  return { ...context, mixpanelCookie };
});

export const setTokenInContext = setContext(async context => {
  const authToken = await geUserAccessToken();

  if (!authToken) {
    log.debug(`[ApolloAuthLink]: Could not generate token for current user.`);

    return context;
  }
  const verified = isTokenEmailVerified(authToken);

  return { ...context, authToken: verified ? authToken : undefined };
});

export const authLink = new ApolloLink((operation, forward) => {
  const { authToken, mixpanelCookie, clientName } = operation.getContext();

  if (clientName === ClientName.externalFileUploadRest) {
    return forward(operation);
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...(authToken ? { [BloomHeader.authorization]: `Bearer ${authToken as string}` } : {}),
      [BloomHeader.platform]: platformHeaderValue,
      [BloomHeader.location]: isWeb ? window.location.pathname : navigationRef.current?.getCurrentRoute()?.name,
      [BloomHeader.version]: getAppVersion(),
      [BloomHeader.retryCount]: "0",
      ...(mixpanelCookie ? { [BloomHeader.distinctId]: (mixpanelCookie as MixpanelCookie).distinct_id } : {}),
      ...(mixpanelCookie ? { [BloomHeader.referer]: (mixpanelCookie as MixpanelCookie).$initial_referrer } : {}),

      ...headers,
    },
  }));

  return forward(operation);
});
