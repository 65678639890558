import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { AddPaymentMethodData } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  generateAddPaymentMethodData: AddPaymentMethodData;
}

interface Variables {
  userId: string;
}

interface GenerateAddPaymentMethodDataResult extends MutationResult<Response, "generateAddPaymentMethodData"> {
  generateAddPaymentMethodData: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const generateAddPaymentMethodDataMutation = gql`
  mutation GenerateAddPaymentMethodData($userId: UserId!) {
    generateAddPaymentMethodData(userId: $userId) {
      clientSecret
    }
  }
`;

export function useGenerateAddPaymentMethodData(): GenerateAddPaymentMethodDataResult {
  const [generateAddPaymentMethodData, { loading, error, data }] = useMutation<Response, Variables>(generateAddPaymentMethodDataMutation);

  return { generateAddPaymentMethodData, loading, error, data: data?.generateAddPaymentMethodData };
}
