/* eslint-disable complexity */
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";

import { ArrowFatLineUp, CaretRight, MagicWand, Ticket } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { FlatList } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { isNotFound } from "@app/common/apollo/apollo.utils";
import { maxContestSubmissionsAllowed } from "@app/common/constants/contests.const";
import { links } from "@app/common/constants/links.const";
import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { Box } from "@app/components/common/box/box.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { ShareMore } from "@app/components/common/share-more/share-more.component";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { ContestListItem } from "@app/components/contests/contest-list-item/contest-list-item.component";
import { ContestPasswordValidationDialog } from "@app/components/contests/contest-password-validation-dialog/contest-password-validation-dialog.component";
import { EditContestFab } from "@app/components/god-mode/edit-contest-fab/edit-contest-fab.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useContestSubmissionCount } from "@app/hooks/api/contests/use-contest-submission-count.hook";
import { useContest } from "@app/hooks/api/contests/use-contest.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { useOpenStudio } from "@app/hooks/utils/use-open-studio.hook";
import { ColorKey } from "@app/utils/color-theme.util";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isIosApp } from "@app/utils/device.util";
import { isContestId } from "@app/utils/id.util";

import { styles } from "./contest.style";

type PressFunction = () => void;

enum ContestEntryPoint {
  post = "post",
  studio = "studio",
}

interface SelectImageSourceButton {
  label: string;
  mode: "contained" | "outlined";
  icon: ReactNode;
  loading?: boolean;
  onPress: PressFunction;
}

export const ContestScreen: FC<NavigationProps<Routes.contest>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();
  const { colors, roundness } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();

  const { contestPasswords } = useLocalStorageContext();
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();
  const { id: contestIdOrHandle } = route.params;

  const [enterPasswordDialogVisible, setEnterPasswordDialogVisible] = useState(false);
  const [contestEntryPoint, setContestEntryPoint] = useState<ContestEntryPoint | undefined>();

  const {
    data: contest,
    loading: loadingContest,
    refetch,
  } = useContest({
    variables: contestIdOrHandle ? (isContestId(contestIdOrHandle) ? { contestId: contestIdOrHandle } : { handle: contestIdOrHandle }) : undefined,
    onError: error => {
      if (isNotFound(error)) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch, error });
    },
  });

  const contestId = isContestId(contestIdOrHandle) ? contestIdOrHandle : contest?.contestId;
  const liveContest = contest?.status === ContestStatus.inProgress;
  const isHidden = contest?.isHidden;

  const { openImageGeneration, openCreateStudioCollab } = useOpenStudio({ contestId });
  const { data: submissionCount, loading: loadingSubmissionCount } = useContestSubmissionCount({
    variables: { contestId: contestId ?? "", userId: profile?.userId ?? "" },
    skip: !profile?.userId || !contestId || !liveContest || isHidden,
  });

  const headerRight = useCallback(() => {
    return contest ? <ShareButton title={contest.name} entityId={contest.contestId} prettyId={contest.handle} entityType="contest" /> : <></>;
  }, [contest]);

  useEffect(() => {
    navigation.setOptions({ headerRight });
  }, [navigation, headerRight]);

  const textColor = (mode: "contained" | "outlined", asString = false): string =>
    mode === "contained" ? (asString ? "onPrimary" : colors.onPrimary) : asString ? "primary" : colors.primary;

  const enterContestOnPressWrapper = (onPress: PressFunction, entryPoint: ContestEntryPoint): void => {
    setContestEntryPoint(entryPoint);

    if (!profile?.userId) {
      openLogin();
      return;
    }

    if (submissionCount && submissionCount.count >= maxContestSubmissionsAllowed) {
      showErrorSnackbar({
        message: t("error.maxContestSubmissionsReached", { max: maxContestSubmissionsAllowed }),
        icon: <Ticket color={colors.onPrimary} weight="thin" />,
      });
      return;
    }

    if (contest?.passwordRequired && !contestPasswords[contest.contestId]) {
      setEnterPasswordDialogVisible(true);
      return;
    }

    onPress();
  };

  const selectImageSourceButtons: SelectImageSourceButton[] = [
    ...conditionalItem<SelectImageSourceButton>(liveContest && !isHidden, {
      label: t("contest.imageSource.useStudio"),
      mode: "contained",
      icon: <MagicWand weight="fill" color={colors.onPrimary} />,
      loading: loadingContest || loadingSubmissionCount,
      onPress: () => enterContestOnPressWrapper(openImageGeneration, ContestEntryPoint.studio),
    }),
    ...conditionalItem<SelectImageSourceButton>(liveContest && !isHidden, {
      label: t("contest.postEntry"),
      mode: "outlined",
      icon: <Ticket weight="fill" color={colors.primary} />,
      loading: loadingContest || loadingSubmissionCount,
      onPress: () => enterContestOnPressWrapper(openCreateStudioCollab, ContestEntryPoint.post),
    }),
    {
      label: t("contest.viewEntries"),
      mode: "outlined",
      icon: <ArrowFatLineUp weight="fill" color={colors.primary} />,
      onPress: () => navigation.navigate(Routes.contestEntries, { id: contestIdOrHandle }),
    },
  ];
  const sectionTitleProps: Omit<TextProps, "children"> = { variant: "overline", textTransform: "uppercase", color: "tertiary" };

  return (
    <ScreenWrapper
      withScrollView
      contentContainerStyle={styles.root}
      staticContent={contestId ? <EditContestFab contestId={contestId} /> : undefined}>
      {loadingContest && !contest && <ActivityIndicator size="large" />}

      {contest && (
        <Box flex={1} rowGap={16}>
          <ContestListItem contest={contest} pointerEvents="none" style={styles.header} textContainerStyle={styles.headerTextContainer} />

          <Box style={styles.section}>
            <Text {...sectionTitleProps}>{t("contest.description")}</Text>
            <Text variant="body2">{contest.description}</Text>
            <Text {...sectionTitleProps}>{t("contest.prize")}</Text>
            <Text variant="body2">
              · <Trans i18nKey={`enum.contestRewardType.${contest.rewardType}`} values={{ value: contest.rewardValue }} />
            </Text>
            <Text {...sectionTitleProps}>{t("contest.disclaimer.label")}</Text>
            <Trans
              i18nKey="contest.disclaimer.text"
              parent={({ children }: ChildrenProp) => <Text variant="body2">{children}</Text>}
              values={{ appleInc: isIosApp ? "Apple Inc. or " : "" }}
              components={{
                tclink: (
                  <Text
                    variant="body2"
                    textDecorationLine="underline"
                    onPress={() => navigateToWebview(links.external.termsOfUse, t("settings.about.termsOfUse"))}>
                    <></>
                  </Text>
                ),
              }}
            />
          </Box>

          <Box marginTop="auto" style={styles.section}>
            <FlatList
              data={selectImageSourceButtons}
              keyExtractor={item => item.label}
              scrollEnabled={false}
              contentContainerStyle={styles.buttonsContainer}
              renderItem={({ item }) => (
                <ListItem
                  title={item.label}
                  titleProps={{ variant: "body1", color: textColor(item.mode, true) as ColorKey }}
                  left={item.icon}
                  right={item.loading ? <ActivityIndicator color={textColor(item.mode)} /> : <CaretRight size={28} color={textColor(item.mode)} />}
                  onPress={!item.loading ? item.onPress : undefined}
                  contentContainerStyle={styles.buttonContent}
                  style={[
                    styles.button,
                    {
                      borderColor: textColor(item.mode),
                      borderRadius: roundness,
                      backgroundColor: item.mode === "contained" ? colors.primary : undefined,
                    },
                  ]}
                />
              )}
            />
          </Box>
        </Box>
      )}
      <ShareMore />

      {contestId && (
        <ContestPasswordValidationDialog
          visible={enterPasswordDialogVisible}
          contestId={contestId}
          onDismiss={() => setEnterPasswordDialogVisible(false)}
          onValidPassword={
            contestEntryPoint === ContestEntryPoint.studio
              ? openImageGeneration
              : contestEntryPoint === ContestEntryPoint.post
              ? openCreateStudioCollab
              : undefined
          }
        />
      )}
    </ScreenWrapper>
  );
};
