import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    rowGap: 8,
  },
  embeddedContainer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    rowGap: 24,
  },
  footer: {
    marginTop: 20,
  },
  fullWidth: {
    width: "100%",
  },
  mainLoginProvider: {
    columnGap: 8,
    flexDirection: "row",
  },
  orContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  orDivider: {
    flex: 1,
    maxWidth: 120,
  },
  orText: {
    marginHorizontal: 8,
  },
  reversedButton: {
    flexDirection: "row-reverse",
  },
  ssoShareButton: {
    flex: 1,
    height: 48,
    margin: 0,
  },
});
