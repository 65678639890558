import { StyleSheet } from "react-native";

import { customFonts } from "@app/common/style/fonts";

export const styles = StyleSheet.create({
  text: {
    ...customFonts.badgeLabel,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    paddingHorizontal: 7,
    paddingVertical: 0,
  },
});
