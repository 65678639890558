import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
  },
  emptyState: {
    borderRadius: 8,
    overflow: "hidden",
    paddingHorizontal: 32,
    paddingVertical: 16,
    width: "100%",
  },
  emptyStateContainer: {
    marginHorizontal: 8,
    marginTop: 4,
  },
  root: {
    paddingVertical: 12,
  },
});
