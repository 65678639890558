import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { PreorderStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { NavigationProps, Routes } from "@app/common/types/navigation.type";
import { PreorderProvider } from "@app/context/preorder/preorder.provider";
import { PreorderScreen } from "@app/screens/preorder/preorder.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<PreorderStackParamList>();

export const PreorderStack: FC<NavigationProps<Routes.preorderStack>> = () => {
  return (
    <PreorderProvider>
      <Stack.Navigator screenOptions={{ ...commonScreenOptions() }}>
        <Stack.Screen name={Routes.preorder} component={PreorderScreen} />
      </Stack.Navigator>
    </PreorderProvider>
  );
};
