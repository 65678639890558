import { gql, QueryHookOptions, useQuery } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioImages, TemporaryStudioImagesFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  temporaryStudioImages: PaginatedStudioImages;
}

interface Variables {
  first?: number;
  offset?: number;
  where: TemporaryStudioImagesFilteringArgs;
}

const temporaryStudioImagesQuery = gql`
  query TemporaryStudioImages($first: Int, $offset: Int, $where: TemporaryStudioImagesFilteringArgs!) {
    temporaryStudioImages(first: $first, offset: $offset, where: $where) {
      nodes {
        ...CoreStudioImageFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
`;

export function useTemporaryStudioImages(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "temporaryStudioImages"> {
  const { loading, error, data, startPolling, stopPolling } = useQuery<Response, Variables>(temporaryStudioImagesQuery, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  return { loading, error, data: data?.temporaryStudioImages, startPolling, stopPolling };
}
