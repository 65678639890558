import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { DotsThree, FlagBanner, TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { ReportDialog } from "@app/components/common/report-dialog/report-dialog.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteComment } from "@app/hooks/api/comments/use-delete-comment.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isAdmin } from "@app/utils/user.util";

import { styles } from "./comment-more-button.style";

interface Props {
  commentId: string;
  commentCreatorId: string;
  parentId: string;
}

export const CommentMoreButton: FC<Props> = ({ commentId, commentCreatorId, parentId }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { profile } = useProfileContext();
  const { state } = useAuthContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const [reportVisible, setReportVisible] = useState<boolean>(false);

  const { deleteComment, loading: loadingDeleteComment } = useDeleteComment(parentId);

  const handleOpenBottomSheet = (): void => {
    sheetRef?.current?.open();
  };

  const handleDelete = useCallback((): void => {
    sheetRef?.current?.close();
    void deleteComment({
      variables: { commentId },
      onError: error => showErrorSnackbar({ error }),
    });
  }, [commentId, deleteComment, showErrorSnackbar]);

  const handleClickReport = useCallback(() => {
    sheetRef?.current?.close();
    setReportVisible(true);
  }, []);

  const options = useMemo<ListItemProps[]>(
    () => [
      ...conditionalItem<ListItemProps>(profile?.userId === commentCreatorId || isAdmin(state), {
        title: t("cta.delete"),
        left: loadingDeleteComment ? (
          <ActivityIndicator color={colors.onBackground} size="small" />
        ) : (
          <TrashSimple color={colors.error} style={styles.itemIcon} />
        ),
        titleProps: { color: "error", variant: "subtitle1" },
        onPress: handleDelete,
      }),
      ...conditionalItem<ListItemProps>(profile?.userId !== commentCreatorId, {
        title: t("cta.report"),
        left: <FlagBanner color={colors.error} style={styles.itemIcon} />,
        titleProps: { color: "error", variant: "subtitle1" },
        onPress: handleClickReport,
      }),
    ],
    [colors.error, colors.onBackground, commentCreatorId, handleDelete, handleClickReport, loadingDeleteComment, profile?.userId, state, t],
  );

  const renderOption = useCallback<ListRenderItem<ListItemProps>>(({ item }) => <ListItem {...item} />, []);

  const shouldShowButton = !!options.length;

  if (!shouldShowButton) return null;

  return (
    <>
      <IconButton
        icon={({ color, size }) => <DotsThree color={color} size={size} />}
        style={styles.iconButton}
        onPress={handleOpenBottomSheet}
        iconColor={colors.onBackground}
      />

      <BottomSheet ref={sheetRef} stackBehavior="push">
        <FlatList
          data={options}
          renderItem={renderOption}
          keyExtractor={item => item.title as string}
          showsVerticalScrollIndicator={false}
          style={styles.listContainer}
        />
      </BottomSheet>
      <ReportDialog entityId={commentId} entityType="comment" visible={!!reportVisible} onDismiss={() => setReportVisible(false)} />
    </>
  );
};
