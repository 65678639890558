import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Text } from "@app/components/common/text/text.component";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./order-total-price-details.style";

interface Props {
  totalPrice: number;
  salesTaxPrice: number;
  shippingPrice: number;
  subtotalPrice: number;
  rewards: number;
}

export const OrderTotalPriceDetails: FC<Props> = ({ totalPrice, salesTaxPrice, shippingPrice, subtotalPrice, rewards }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <View style={[styles.root, { backgroundColor: colors.tertiaryContainer }]}>
      <View style={styles.row}>
        <Text variant="subtitle2" style={styles.text}>
          {t("order.subtotal")}
        </Text>
        <Text variant="body2" color="tertiary">
          {formatPriceUSD(subtotalPrice)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text variant="subtitle2" style={styles.text}>
          {t("order.shipping")}
        </Text>
        <Text variant="body2" color="tertiary">
          {shippingPrice === 0 ? t("preorder.free") : formatPriceUSD(shippingPrice)}
        </Text>
      </View>
      {salesTaxPrice !== 0 && (
        <View style={styles.row}>
          <Text variant="subtitle2" style={styles.text}>
            {t("order.tax")}
          </Text>
          <Text variant="body2" color="tertiary">
            {formatPriceUSD(salesTaxPrice)}
          </Text>
        </View>
      )}
      <View style={styles.row}>
        <Text variant="subtitle2" style={styles.text}>
          {t("supercoins.rewards")}
        </Text>
        <Text variant="body2" color="tertiary">
          {`-${formatPriceUSD(rewards * -1)}`}
        </Text>
      </View>
      <View style={styles.row}>
        <Text variant="subtitle2" style={styles.text}>
          {t("order.orderTotal")}
        </Text>
        <Text variant="h9">{formatPriceUSD(totalPrice)}</Text>
      </View>
    </View>
  );
};
