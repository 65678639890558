import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, MutationSoftDeleteCollabArgs } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  softDeleteCollab: Collab;
}

interface SoftDeleteCollabResult extends MutationResult<Response, "softDeleteCollab"> {
  softDeleteCollab: (options?: MutationFunctionOptions<Response, MutationSoftDeleteCollabArgs>) => Promise<FetchResult>;
}

const softDeleteCollabMutation = gql`
  mutation SoftDeleteCollab($collabId: CollabId!) {
    softDeleteCollab(collabId: $collabId) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useSoftDeleteCollab(): SoftDeleteCollabResult {
  const [softDeleteCollab, { loading, error, data }] = useMutation<Response, MutationSoftDeleteCollabArgs>(softDeleteCollabMutation, {
    update: (cache, _, options) => {
      cache.evict({ id: options.variables?.collabId });
    },
  });

  return { softDeleteCollab, loading, error, data: data?.softDeleteCollab };
}
