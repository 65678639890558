import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { Checkout } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  cartCheckout: Checkout;
}

interface Variables {
  anonymousCartId?: string;
  userId?: string;
}

interface CartCheckoutResult extends MutationResult<Response, "cartCheckout"> {
  checkout: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const cartCheckoutMutation = gql`
  mutation CartCheckout($anonymousCartId: AnonymousCartId, $userId: UserId) {
    cartCheckout(anonymousCartId: $anonymousCartId, userId: $userId) {
      checkoutUrl
    }
  }
`;

export function useCartCheckout(): CartCheckoutResult {
  const [checkout, { loading, error, data }] = useMutation<Response, Variables>(cartCheckoutMutation);

  return { checkout, loading, error, data: data?.cartCheckout };
}
