import React, { FC, useMemo } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { InAppPurchasesContext, InAppPurchasesContextInterface } from "./in-app-purchases.context";

export const InAppPurchasesProvider: FC<ChildrenProp> = ({ children }) => {
  const context = useMemo<InAppPurchasesContextInterface>(
    () => ({
      supercreatorPrice: undefined,
      buySupercreatorPlan: () => Promise.resolve(undefined),
      userHasActiveSupercreatorSubscription: false,
      supersellerPrice: undefined,
      buySupersellerPlan: () => Promise.resolve(undefined),
      userHasPaidForSupersellerPlan: false,
      manageSubscriptionsUrl: null,
      loading: false,
    }),
    [],
  );

  return <InAppPurchasesContext.Provider value={context}>{children}</InAppPurchasesContext.Provider>;
};
