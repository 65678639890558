import React from "react";

import { Tote, Lightbulb, Trophy } from "phosphor-react-native";

import HandCoins from "@app/assets/icons/hand-coins-thin.svg";
import { CollabFacets, Stage, UserFacets } from "@app/common/graphql/generated/schema.graphql";
import { AvatarImageType } from "@app/components/common/avatar-image/avatar-image.component";

export const mapStageImage: { [key in Stage]?: AvatarImageType } = {
  [Stage.contest]: ({ color }: { color: string }) => <Trophy color={color} weight="thin" size={24} />,
  [Stage.concept]: ({ color }: { color: string }) => <Lightbulb color={color} weight="thin" size={24} />,
  [Stage.underReview]: ({ color }: { color: string }) => <HandCoins color={color} width={24} height={24} />,
  [Stage.forSale]: ({ color }: { color: string }) => <Tote color={color} weight="thin" size={24} />,
};

export enum FacetsTypename {
  collab = "CollabFacets",
  user = "UserFacets",
}

export type Facets = CollabFacets | UserFacets;
