import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { catalogProductFields } from "@app/common/graphql/fragments.graphql";
import { CatalogProduct } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface Response {
  catalogProduct: CatalogProduct;
}

export interface Variables {
  catalogProductId: string;
}

const catalogProductQuery = gql`
  query CatalogProduct($catalogProductId: CatalogProductId!) {
    catalogProduct(catalogProductId: $catalogProductId) {
      ...CatalogProductFields
    }
  }
  ${catalogProductFields}
`;

export function useCatalogProduct(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "catalogProduct"> {
  const { data, loading, error } = useQuery<Response, Variables>(catalogProductQuery, options);

  return { data: data?.catalogProduct, loading, error };
}
