import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { ImageToBeUsed } from "@app/common/types/image.type";

export interface ProductUpdateInputs {
  content: string;
  title: string;
  images: ImageToBeUsed[];
}

export const defaultInputsValue: ProductUpdateInputs = {
  images: [],
  title: "",
  content: "",
};

export interface ProductUpdateContextInterface {
  inputs: ProductUpdateInputs;
  resetInputs: () => void;
  setImages: Dispatch<SetStateAction<ImageToBeUsed[]>>;
  setInputs: Dispatch<SetStateAction<ProductUpdateInputs>>;
}

export const ProductUpdateContext = createContext<ProductUpdateContextInterface>({
  inputs: defaultInputsValue,
  resetInputs: () => undefined,
  setImages: () => undefined,
  setInputs: () => undefined,
});

export const useProductUpdateContext = (): ProductUpdateContextInterface => useContext(ProductUpdateContext);
