import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    borderWidth: 1,
    columnGap: 8,
    flexDirection: "row",
    height: 40,
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: 8,
  },
  divider: {
    height: "60%",
    marginVertical: 16,
    width: 1,
  },
  superplanAlertWrapper: {
    marginTop: 8,
  },
});
