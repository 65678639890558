import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  editImageContainer: {
    paddingBottom: 16,
    rowGap: 8,
  },
  root: {
    paddingHorizontal: 20,
    paddingVertical: 24,
    rowGap: 16,
  },
});
