import { ApolloLink, HttpLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { REACT_APP_BLOOM_BASE_API_URL, REACT_APP_BLOOM_WEBSITE } from "@env";
import { RestLink } from "apollo-link-rest";
import { createClient } from "graphql-ws";

import { ClientName } from "../enums/client-name.enum";

import { authLink } from "./apollo-auth-link";

const graphQlUrl = `${REACT_APP_BLOOM_BASE_API_URL}/graphql`;

const bloomGraphQlLink = new HttpLink({
  credentials: "include",
  uri: graphQlUrl,
});

const commonRestSerializers: RestLink.Serializers = {
  fileEncode: (formData: FormData, headers: Headers) => {
    headers.set("apollo-require-preflight", "false");

    return { body: formData, headers };
  },
};

const bloomRestLink = new RestLink({
  uri: REACT_APP_BLOOM_BASE_API_URL,
  bodySerializers: commonRestSerializers,
});

const externalFileUploadRestLink = new RestLink({
  bodySerializers: commonRestSerializers,
  endpoints: { dynamic: " " },
  customFetch: async (uri, config) => {
    const cleanUri = typeof uri === "string" ? uri.trim() : uri;
    return await fetch(cleanUri, {
      ...config,
      headers: { Origin: REACT_APP_BLOOM_WEBSITE, "Content-Type": "multipart/form-data", ...config.headers },
    });
  },
});

const bloomWebSocketLink = new GraphQLWsLink(createClient({ url: graphQlUrl.replace("http", "ws") }));

export const bloomApiLink = authLink.concat(
  ApolloLink.split(
    operation => {
      const definition = getMainDefinition(operation.query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    bloomWebSocketLink,
    ApolloLink.split(
      operation => operation.getContext().clientName === ClientName.externalFileUploadRest,
      externalFileUploadRestLink,
      ApolloLink.split(operation => operation.getContext().clientName === ClientName.bloomRest, bloomRestLink, bloomGraphQlLink),
    ),
  ),
);
