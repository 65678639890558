import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
  },
  defaultImage: {
    flex: 1,
    height: undefined,
    resizeMode: "contain",
    width: undefined,
  },
  overlayImage: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
});
