/* eslint-disable complexity */
import React, { JSX, useMemo, useState } from "react";

import { ApolloError } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";

import { links } from "@app/common/constants/links.const";
import { DetailedStage } from "@app/common/graphql/generated/schema.graphql";
import { useNavigation } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInputProps } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";

import { useArchiveCollab } from "../api/god-mode/use-archive-collab.hook";
import { useDenyCollab } from "../api/god-mode/use-deny-collab.hook";
import { useMoveCollabToDevelopmentDecision } from "../api/god-mode/use-move-collab-to-development-decision.hook";
import { useMoveCollabToDevelopmentDenied } from "../api/god-mode/use-move-collab-to-development-denied.hook";
import { useMoveCollabToDevelopmentFailed } from "../api/god-mode/use-move-collab-to-development-failed.hook";
import { useMoveCollabToUnderReviewDenied } from "../api/god-mode/use-move-collab-to-under-review-denied.hook";
import { usePromoteCollabToConcept } from "../api/god-mode/use-promote-collab-to-concept.hook";
import { usePromoteCollabToForSale } from "../api/god-mode/use-promote-collab-to-for-sale.hook";
import { usePromoteCollabToInDevelopment } from "../api/god-mode/use-promote-collab-to-in-development.hook";
import { usePromoteCollabToUnderReviewReady } from "../api/god-mode/use-promote-collab-to-under-review-ready.hook";
import { useSoftDeleteCollab } from "../api/god-mode/use-soft-delete-collab.hook";
import { useStartCampaign } from "../api/products/use-start-campaign.hook";

import { useNavigateToWebview } from "./use-navigate-to-webview.hook";

interface CollabTransitionUtilities {
  getInputProps: () => (TextInputProps | JSX.Element)[];
  isReadyToSubmit: boolean;
  isLoading: boolean;
  updateStage: () => void;
}

export function useCollabTransition(detailedStage?: DetailedStage, collabId?: string): CollabTransitionUtilities {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();
  const navigation = useNavigation();
  const { navigateToWebview } = useNavigateToWebview();

  const { archiveCollab, loading: loadingArchive } = useArchiveCollab();
  const { softDeleteCollab, loading: loadingSoftDelete } = useSoftDeleteCollab();
  const { promoteCollabToConcept, loading: loadingPromoteToConcept } = usePromoteCollabToConcept();
  const { denyCollab, loading: loadingDeny } = useDenyCollab();
  const { moveCollabToDevelopmentDecision, loading: loadingMoveToDevelopmentDecision } = useMoveCollabToDevelopmentDecision();
  const { moveCollabToDevelopmentDenied, loading: loadingMoveToDevelopmentDenied } = useMoveCollabToDevelopmentDenied();
  const { moveCollabToDevelopmentFailed, loading: loadingMoveToDevelopmentFailed } = useMoveCollabToDevelopmentFailed();
  const { moveCollabToUnderReviewDenied, loading: loadingMoveToUnderReviewDenied } = useMoveCollabToUnderReviewDenied();
  const { promoteCollabToInDevelopment, loading: loadingPromoteToInDevelopment } = usePromoteCollabToInDevelopment();
  const { promoteCollabToForSale, loading: loadingPromoteToForSale } = usePromoteCollabToForSale();
  const { promoteCollabToUnderReviewReady, loading: loadingPromoteToUnderReviewReady } = usePromoteCollabToUnderReviewReady();
  const { startCampaign, loading: loadingStartCampaign } = useStartCampaign();

  const [externalProductId, setExternalProductId] = useState<string>();
  const [orderCountNeeded, setOrderCountNeeded] = useState<string>();

  const externalProductIdInput = {
    label: t("godMode.updateStage.fields.externalProductId.label"),
    helperText: (
      <Trans
        i18nKey="godMode.updateStage.fields.externalProductId.helper"
        components={{
          slink: (
            <Text textDecorationLine="underline" onPress={() => navigateToWebview(links.external.adminShopify)}>
              <></>
            </Text>
          ),
        }}
      />
    ),
    errorText: t("godMode.updateStage.fields.externalProductId.error"),
    value: externalProductId,
    onChangeText: setExternalProductId,
    required: true,
  };

  const getInputProps = (): (TextInputProps | JSX.Element)[] => {
    switch (detailedStage) {
      case DetailedStage.forSale:
        return [externalProductIdInput];
      case DetailedStage.underReviewReady:
        return [
          {
            label: t("godMode.updateStage.fields.orderCountNeeded.label"),
            value: orderCountNeeded,
            onChangeText: setOrderCountNeeded,
            required: true,
            keyboardType: "numeric",
          },
          externalProductIdInput,
        ];
      default:
        return [];
    }
  };

  const isReadyToSubmit = useMemo((): boolean => {
    switch (detailedStage) {
      case DetailedStage.forSale:
        return !!externalProductId;
      case DetailedStage.underReviewReady:
        return !!externalProductId && !!orderCountNeeded;
      default:
        return true;
    }
  }, [detailedStage, externalProductId, orderCountNeeded]);

  const isLoading =
    loadingArchive ||
    loadingSoftDelete ||
    loadingPromoteToConcept ||
    loadingDeny ||
    loadingMoveToDevelopmentDecision ||
    loadingMoveToDevelopmentDenied ||
    loadingMoveToDevelopmentFailed ||
    loadingMoveToUnderReviewDenied ||
    loadingPromoteToInDevelopment ||
    loadingPromoteToForSale ||
    loadingPromoteToUnderReviewReady ||
    loadingStartCampaign;

  const onError = (error: ApolloError): void => showErrorSnackbar({ message: error.message });
  const onCompleted = (): void =>
    Alert.alert(t("godMode.success"), t("godMode.updateStage.editSuccessful"), [{ text: t("cta.ok"), onPress: () => navigation.goBack() }]);

  const updateStage = (): void => {
    if (!collabId || isLoading || !isReadyToSubmit) return;

    const commonOptions = { variables: { collabId }, onCompleted, onError };

    switch (detailedStage) {
      case DetailedStage.archived:
        void archiveCollab(commonOptions);
        break;
      case DetailedStage.concept:
        void promoteCollabToConcept(commonOptions);
        break;
      case DetailedStage.denied:
        void denyCollab(commonOptions);
        break;
      case DetailedStage.development:
        void promoteCollabToInDevelopment(commonOptions);
        break;
      case DetailedStage.developmentDecision:
        void moveCollabToDevelopmentDecision(commonOptions);
        break;
      case DetailedStage.developmentDenied:
        void moveCollabToDevelopmentDenied(commonOptions);
        break;
      case DetailedStage.developmentFailed:
        void moveCollabToDevelopmentFailed(commonOptions);
        break;
      case DetailedStage.softDeleted:
        void softDeleteCollab(commonOptions);
        break;
      case DetailedStage.underReviewDenied:
        void moveCollabToUnderReviewDenied(commonOptions);
        break;
      case DetailedStage.underReviewReady:
        void promoteCollabToUnderReviewReady({
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            input: {
              externalProductId: externalProductId ?? "",
              orderCountNeeded: orderCountNeeded ? parseInt(orderCountNeeded) : 1,
            },
          },
        });
        break;
      case DetailedStage.forSale:
        void promoteCollabToForSale({
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            input: {
              externalProductId: externalProductId ?? "",
            },
          },
        });
        break;
      case DetailedStage.underReview:
        void startCampaign(commonOptions);
        break;
      default:
        break;
    }
  };

  return { getInputProps, isReadyToSubmit, updateStage, isLoading };
}
