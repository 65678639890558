import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  noBackgroundButton: {
    borderRadius: 0,
  },
  promptButton: {
    alignItems: "center",
  },
  promptContent: {
    rowGap: 16,
  },
});
