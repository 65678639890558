import { createContext, useContext } from "react";

import { LocalStorageInterface, LocalStorageKeys, defaultLocalStorageValues } from "./local-storage.type";

export interface LocalStorageContextInterface extends LocalStorageInterface {
  loading: boolean;
  setLocalStorage: <Key extends LocalStorageKeys>(key: Key, value: LocalStorageInterface[Key]) => void;
  clearAllLocalStorageValues: () => void;
}

export const LocalStorageContext = createContext<LocalStorageContextInterface>({
  ...defaultLocalStorageValues,
  loading: true,
  setLocalStorage: () => undefined,
  clearAllLocalStorageValues: () => undefined,
});

export const useLocalStorageContext = (): LocalStorageContextInterface => useContext(LocalStorageContext);
