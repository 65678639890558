import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { DownloadImageLink, SaveTemporaryImageInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  generateStudioImageDownloadLink: DownloadImageLink;
}

interface Variables {
  input: SaveTemporaryImageInput;
}

interface GenerateStudioImageDownloadLinkResult extends MutationResult<Response, "generateStudioImageDownloadLink"> {
  generateStudioImageDownloadLink: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const generateImageLinkMutation = gql`
  mutation GenerateStudioImageDownloadLink($input: SaveTemporaryImageInput!) {
    generateStudioImageDownloadLink(input: $input) {
      imageUrl
    }
  }
`;

export function useGenerateStudioImageDownloadLink(): GenerateStudioImageDownloadLinkResult {
  const [generateStudioImageDownloadLink, { loading, error, data, reset }] = useMutation<Response, Variables>(generateImageLinkMutation);

  return { generateStudioImageDownloadLink, loading, error, data: data?.generateStudioImageDownloadLink, reset };
}
