import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
  },
  content: {
    rowGap: 16,
  },
  root: {
    borderWidth: 0.3,
    marginHorizontal: 20,
  },
  title: {
    textAlign: "center",
  },
});
