import React, { FC, useMemo, useState, useCallback, JSX } from "react";

import { ApolloError } from "@apollo/client";
import { APP_ENV } from "@env";
import { useNetInfo } from "@react-native-community/netinfo";
import { WifiSlash, CheckCircle, X, WarningCircle } from "phosphor-react-native";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Keyboard } from "react-native";
import { Snackbar, useTheme } from "react-native-paper";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";
import { reportError } from "@app/utils/logger/logger.util";
import { isObjectWithProperties } from "@app/utils/object.util";

import { SnackbarContext, SnackbarContextInterface } from "./snackbar.context";
import { styles } from "./snackbar.style";
import { SnackbarInterface, GenericSnackbarOptions, SnackbarType, SuccessSnackbarOptions } from "./snackbar.types";

export function portalWrapper(element: JSX.Element | null): JSX.Element | null {
  if (isWeb) return createPortal(element, document.body);

  return element;
}

export const SnackbarContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { colors } = useTheme();
  const netInfo = useNetInfo();
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const [visible, setVisible] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarInterface>({ type: SnackbarType.noInternet });

  const width = windowWidth - 24 * 2; // subtract margins;

  const getUserFriendlyMessageForError = useCallback((message?: string, error?: unknown): string | undefined => {
    if (message) return message;
    if (!error || typeof error !== "object") return undefined;

    const isProd = APP_ENV === "production";

    if (error && "graphQLErrors" in error) {
      const apolloError = error as ApolloError;

      const errorCode = apolloError.graphQLErrors?.[0]?.extensions.code;
      if (!isProd && errorCode) {
        return errorCode as string;
      }
      return undefined;
    }

    if (isProd) return undefined;
    if (isObjectWithProperties(error, "message") && typeof error.message === "string") return error.message;
    return undefined;
  }, []);

  const showErrorSnackbar = useCallback(
    (options?: GenericSnackbarOptions): void => {
      if (netInfo.isConnected) {
        setSnackbar({
          type: SnackbarType.error,
          options: { ...options, message: getUserFriendlyMessageForError(options?.message, options?.error) },
        });

        const error = options?.error;
        if (error) {
          reportError(error, { ...options.context, extra: { ...(options.context?.extra ?? {}), error } });
        }
      } else {
        setSnackbar({ type: SnackbarType.noInternet });
      }

      Keyboard.dismiss();
      setVisible(true);
    },
    [getUserFriendlyMessageForError, netInfo.isConnected],
  );

  const showSuccessSnackbar = useCallback((options?: SuccessSnackbarOptions) => {
    setSnackbar({ type: SnackbarType.success, options });
    Keyboard.dismiss();
    setVisible(true);
  }, []);

  const snackbarContextValue = useMemo<SnackbarContextInterface>(
    () => ({ showErrorSnackbar, showSuccessSnackbar }),
    [showErrorSnackbar, showSuccessSnackbar],
  );

  const iconMap: Record<SnackbarType, JSX.Element> = {
    [SnackbarType.error]: <WarningCircle color={colors.onPrimary} weight="thin" />,
    [SnackbarType.noInternet]: <WifiSlash color={colors.onPrimary} weight="thin" />,
    [SnackbarType.success]: <CheckCircle color={colors.onPrimary} weight="fill" />,
  };

  const fallbackContentMap: Record<SnackbarType, string> = {
    [SnackbarType.error]: t("error.generic"),
    [SnackbarType.noInternet]: t("error.noInternet"),
    [SnackbarType.success]: t("success.generic"),
  };

  const content = snackbar.options?.message ? snackbar.options?.message : fallbackContentMap[snackbar.type];

  return (
    <>
      <SnackbarContext.Provider value={snackbarContextValue}>{children}</SnackbarContext.Provider>

      {portalWrapper(
        <Snackbar
          visible={visible}
          onDismiss={() => setVisible(false)}
          duration={snackbar.options?.duration ?? 4000}
          action={
            snackbar.options?.refetch
              ? {
                  label: t("cta.refresh"),
                  onPress: () => snackbar.options?.refetch && void snackbar.options.refetch(),
                  textColor: colors.onPrimary,
                }
              : undefined
          }
          icon={iconProps => <X {...iconProps} color={colors.onPrimary} weight="thin" size={24} />}
          onIconPress={!snackbar.options?.refetch ? () => setVisible(false) : undefined}
          wrapperStyle={styles.snackbarWrapper}
          style={[styles.snackbar, { backgroundColor: colors.primary, width }]}>
          <EmbeddedAlert
            variant={AlertVariant.primary}
            text={content}
            cta={snackbar.options?.cta}
            icon={() => snackbar.options?.icon ?? iconMap[snackbar.type]}
            style={styles.innerContainer}
          />
        </Snackbar>,
      )}
    </>
  );
};
