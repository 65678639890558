import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  image: {
    height: "100%",
    width: "100%",
  },
  imageIcon: {
    left: 8,
    position: "absolute",
    top: 8,
  },
  zoomIcon: {
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
});
