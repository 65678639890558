import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useReport } from "@app/hooks/api/use-report.hook";

type EntityType = "collab" | "profile" | "comment";
interface Props {
  entityId: string;
  entityType: EntityType;
  visible: boolean;
  onDismiss: () => void;
}

export const ReportDialog: FC<Props> = ({ entityId, entityType, visible, onDismiss }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  const [reason, setReason] = useState("");

  const { report, loading } = useReport();

  const getTitle = (): string => {
    switch (entityType) {
      case "collab":
        return t("confirmation.report.titleCollab");
      case "profile":
        return t("confirmation.report.titleUser");
      case "comment":
        return t("confirmation.report.titleComment");
      default:
        return t("cta.report");
    }
  };

  const getSuccessMessage = (): string => {
    switch (entityType) {
      case "collab":
        return t("confirmation.report.successCollab");
      case "profile":
        return t("confirmation.report.successUser");
      case "comment":
        return t("confirmation.report.successComment");
      default:
        return "";
    }
  };

  const handleReport = (): void => {
    const formattedReason = reason.trim();
    void report({
      variables: { entityId, input: formattedReason ? { reason: formattedReason } : undefined },
      onCompleted: () => {
        onDismiss();
        showSuccessSnackbar({ message: getSuccessMessage() });
      },
      onError: () => showErrorSnackbar(),
    });
  };

  const handleDismiss = (): void => {
    onDismiss();
    setReason("");
  };

  return (
    <ConfirmationDialog
      visible={visible}
      title={getTitle()}
      message={t("confirmation.report.message")}
      additionalContent={
        <TextInput
          label={t("confirmation.report.inputHelper")}
          defaultValue={reason}
          onChangeText={setReason}
          multiline
          numberOfLines={4}
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="done"
          blurOnSubmit
        />
      }
      confirmProps={{
        label: t("cta.report"),
        loading,
        onPress: handleReport,
      }}
      onDismiss={handleDismiss}
    />
  );
};
