import { QueryHookOptions, gql } from "@apollo/client";

import { collabWithMediaFields, genericPromoFields, standardPromoInFeedFields } from "@app/common/graphql/fragments.graphql";
import { FeedEntity, PaginatedFeedEntitiesWithPromo } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useQueryWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { isPromo } from "@app/utils/feed.util";
import { mapPromoToRealType, PaginatedFeedEntitiesWithPromoFromHook } from "@app/utils/promo.util";

interface InternalResponse {
  feed: PaginatedFeedEntitiesWithPromoFromHook;
}

export interface FeedResponse {
  feed: PaginatedFeedEntitiesWithPromo;
}

interface Variables {
  after?: string;
  first?: number;
  userId?: string;
}

export type FeedResult = QueryResult<FeedResponse, "feed">;

export const feedWithMediaQuery = gql`
  query FeedWithMedia($after: FeedEntityId, $first: Int, $userId: UserId) {
    feed: feedWithMedia(after: $after, first: $first, userId: $userId) {
      nodes {
        ... on CollabWithMedia {
          ...CollabWithMediaFields
        }
        ... on GenericPromo {
          ...GenericPromoFields
        }
        ... on StandardPromo {
          ...StandardPromoInFeedFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabWithMediaFields}
  ${genericPromoFields}
  ${standardPromoInFeedFields}
`;

export function useFeedWithMedia(options: QueryHookOptions<FeedResponse, Variables>): FeedResult {
  const transformationPerNode = (node: PaginatedFeedEntitiesWithPromoFromHook): FeedEntity => (isPromo(node) ? mapPromoToRealType(node) : node);
  const transformation = (feed: PaginatedFeedEntitiesWithPromoFromHook): PaginatedFeedEntitiesWithPromo => ({
    ...feed,
    nodes: feed.nodes?.map(transformationPerNode),
  });

  const { loading, error, data, networkStatus, fetchMore, refetch } = useQueryWithTransformation<FeedResponse, InternalResponse, Variables>(
    feedWithMediaQuery,
    "feed",
    transformation,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );

  const feed = data
    ? {
        ...data.feed,
        nodes: data.feed.nodes?.map(entity => {
          if (isPromo(entity)) return mapPromoToRealType(entity);

          return entity;
        }),
      }
    : undefined;

  return { loading, error, data: feed, networkStatus, fetchMore, refetch };
}
