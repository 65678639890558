import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { userStateFields } from "@app/common/graphql/fragments.graphql";
import { UpdateUserStateInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";
import { UserState } from "@app/context/profile/user-state.enum";

export interface Response {
  updateUserState: {
    frontendState: UserState;
  };
}

interface Variables {
  input: UpdateUserStateInput;
  userId: string;
}

interface UpdateUserStateResult extends MutationResult<Response, "updateUserState"> {
  updateUserState: (options?: MutationFunctionOptions<Response, Partial<Variables>>) => Promise<FetchResult>;
}

const updateUserStateMutation = gql`
  mutation UpdateUserState($input: UpdateUserStateInput!, $userId: UserId!) {
    updateUserState(input: $input, userId: $userId) {
      ...UserStateFields
    }
  }
  ${userStateFields}
`;

/**
 * Make sure to check if profile is defined or the mutation will fail
 */
export function useUpdateUserState(): UpdateUserStateResult {
  const [updateUserState, { loading, error, data }] = useMutation<Response, Partial<Variables>>(updateUserStateMutation);

  return { updateUserState, loading, error, data: data?.updateUserState };
}
