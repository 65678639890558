import React, { FC, PropsWithChildren } from "react";

import uniq from "lodash.uniq";
import { XCircle } from "phosphor-react-native";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { Chip, ChipProps } from "../chip/chip.component";

import { styles } from "./chip-list.style";

interface Props extends Omit<ChipProps, "label"> {
  chips: string[];
  gap?: { row: number; column: number };
  onDelete?: (chip: string) => void;
}

export const ChipList: FC<PropsWithChildren<Props>> = ({ chips, gap = { row: 8, column: 8 }, onDelete, style, textStyle, ...chipProps }) => {
  const { colors } = useTheme();

  const marginStyle = { ...(!chipProps.icon ? { marginLeft: 8 } : {}), ...(!onDelete ? { marginRight: 8 } : {}) };

  return chips.length ? (
    <View style={[styles.root, { rowGap: gap.row, columnGap: gap.column }]}>
      {uniq(chips).map(chip => (
        <Chip
          key={`chip-${chip}`}
          label={chip}
          closeIcon={({ color }) => <XCircle weight="fill" color={color} />}
          onClose={onDelete ? () => onDelete(chip) : undefined}
          textColor="primary"
          textStyle={[styles.chipText, marginStyle, textStyle]}
          style={[styles.chip, { backgroundColor: colors.action.selected }, style]}
          {...chipProps}
        />
      ))}
    </View>
  ) : null;
};
