import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { FrontendConfig, TokenCosts } from "@app/common/graphql/generated/schema.graphql";
import { BaseResult } from "@app/common/types/apollo-result.type";

interface Response {
  frontendConfig: FrontendConfig;
  tokenCosts: TokenCosts;
}

export const frontendConfigQuery = gql`
  query FrontendConfig {
    frontendConfig {
      allowedShippingCountries
      imageResizeProxy
      likeCountObjective
      maximumNumberOfImagesPerCollab
      maximumNumberOfImagesPerCollabForAdmins
      maximumVideoDurationForProfileInSeconds
      maximumVideoDurationInSeconds
      offScriptPercentageOfRevenue
      tokensNeededForDescriptionGeneration
      tokensNeededForImageGeneration
      tokensNeededForVideoGeneration
    }
    tokenCosts {
      tokensPerDay
    }
  }
`;

export function useFrontendConfig(options?: QueryHookOptions<Response>): BaseResult<Response> {
  return useQuery<Response>(frontendConfigQuery, options);
}
