import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface StudioImageResponse {
  studioImage: StudioImage;
}

interface Variables {
  imageId: string;
}

export const studioImageQuery = gql`
  query StudioImage($imageId: StudioImageId!) {
    studioImage(imageId: $imageId) {
      ...CoreStudioImageFields
    }
  }
  ${coreStudioImageFields}
`;

export function useStudioImage(options: QueryHookOptions<StudioImageResponse, Variables>): QueryResult<StudioImageResponse, "studioImage"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<StudioImageResponse, Variables>(studioImageQuery, options);

  return { data: data?.studioImage, loading, error, networkStatus, fetchMore, refetch };
}
