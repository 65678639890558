import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  emptyStateFullScreen: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  headerContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingHorizontal: 20,
    rowGap: 16,
  },
  root: {
    flexGrow: 1,
    paddingVertical: 16,
    rowGap: 8,
  },
  seeMoreWithIcon: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
  },
});
