import React, { FC, useState } from "react";

import { ArrowLeft, Coins } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import Animated, { Easing, SlideInDown, SlideOutDown } from "react-native-reanimated";

import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

import { styles } from "./studio-main-actions.style";

interface Props {
  onReset: () => void;
  onPressGenerate: () => Promise<void>;
  style?: StyleProp<ViewStyle>;
}

export const StudioMainActions: FC<Props> = ({ style, onReset, onPressGenerate }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { isCurrentlyGenerating } = useStudioMiniContext();

  const handleOnPressGenerate = (): void => {
    setIsLoading(true);
    void onPressGenerate().finally(() => setIsLoading(false));
  };

  const disableButton = isLoading || isCurrentlyGenerating;

  return (
    <Animated.View
      entering={SlideInDown.duration(500).easing(Easing.out(Easing.exp))}
      exiting={SlideOutDown.duration(2000).easing(Easing.ease)}
      style={style}>
      <View style={styles.promptActionsContainer}>
        <Text>{t("studioMini.prompt.selectImage")}</Text>
        <Text>{t("studioMini.prompt.or")}</Text>
        <Button mode="outlined" fullWidth onPress={handleOnPressGenerate} contentStyle={styles.button} icon={Coins} loading={disableButton}>
          {t("studioMini.prompt.remix")}
        </Button>
        <Button icon={ArrowLeft} mode="outlined" fullWidth onPress={onReset}>
          {t("studioMini.prompt.tryAnotherPrompt")}
        </Button>
      </View>
    </Animated.View>
  );
};
