import { ApolloQueryResult } from "@apollo/client";

import { FetchMoreArgs, FetchVariables } from "@app/common/types/fetch-more-args.type";
import { getFeedEntityId } from "@app/utils/feed.util";

import { FeedResponse } from "../api/use-feed-with-media.hook";

interface FetchMoreItemsUtils {
  fetchMoreFeedEntity: (fetchVars: FetchVariables) => void;
}

export function useFetchMoreEntityFeed(
  fetchMore?: (args: FetchMoreArgs<FeedResponse>) => Promise<ApolloQueryResult<FeedResponse>>,
): FetchMoreItemsUtils {
  const fetchMoreFeedEntity = (fetchVars: FetchVariables): void => {
    if (!fetchMore) return;

    void fetchMore({
      variables: fetchVars,
      updateQuery: (previousResult: FeedResponse, options: { fetchMoreResult: FeedResponse }) => {
        const existingNodes = previousResult?.feed.nodes ?? [];
        const newNodes = options.fetchMoreResult.feed.nodes ?? [];

        const newNodesFiltered = newNodes.filter(
          newEntity => existingNodes.findIndex(existingEntity => getFeedEntityId(existingEntity) === getFeedEntityId(newEntity)) === -1,
        );

        return {
          feed: {
            ...options.fetchMoreResult.feed,
            nodes: [...existingNodes, ...newNodesFiltered],
          },
        };
      },
    });
  };

  return { fetchMoreFeedEntity };
}
