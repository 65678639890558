import React, { FC, useMemo, useState } from "react";

import { ChildrenProp } from "@app/common/types/children-prop.interface";

import { SearchContext, SearchContextInterface } from "./search.context";

export const SearchContextProvider: FC<ChildrenProp> = ({ children }) => {
  const [query, setQuery] = useState("");

  const context = useMemo<SearchContextInterface>(() => ({ query, setQuery }), [query, setQuery]);

  return <SearchContext.Provider value={context}>{children}</SearchContext.Provider>;
};
